import { useAppSelector } from '@hooks/redux.hooks';
import { selectContext } from '@redux/settings/settings.selectors';
import { RootState } from '@redux/store';
import { getMapIdFromContext } from '@utils/map/map.utils';

import { MapIdEnum } from '@/types/map.types';

export function useSelectorWithMapIdFromContext<T>(
  // eslint-disable-next-line
  selector: (state: RootState, mapId: MapIdEnum, params?: any) => T,
  // eslint-disable-next-line
  params?: any,
): T {
  const currentContext = useAppSelector(selectContext);
  return useAppSelector((state) => selector(state, getMapIdFromContext(currentContext), params));
}
