/* eslint-disable react/no-unescaped-entities */
import { HStack, Text } from '@chakra-ui/layout';
import { toPrecision } from '@utils/math.utils';
import { toDD, toDms } from '@utils/validation/coordinates.utils';
import { useState } from 'react';

import CustomNumberInput from './CustomNumberInput';

type Props = {
  color?: string;
  coordinate: number;
  longOrLat: 'long' | 'lat';
  onChange: (coordinate: number) => void;
};

export default function DMSCoordinateInput({ color = 'neutral.black', coordinate, onChange, longOrLat }: Props) {
  const [d, m, s, nsew] = toDms(coordinate, longOrLat);
  const [secondsAsString, setSecondAsString] = useState<string>(toPrecision(s, 3).toString());
  const max = longOrLat === 'long' ? 180 : 90;

  if (Number(secondsAsString) !== toPrecision(s, 3)) {
    setSecondAsString(toPrecision(s, 3).toString());
  }

  function onDegreeChange(_: string, degrees: number): void {
    if (isNaN(degrees)) {
      onChange(toDD(0, m, s, nsew));
    } else if (degrees >= max) {
      onChange(toDD(max, 0, 0, nsew));
    } else {
      onChange(toDD(degrees, m, s, nsew));
    }
  }

  function onMinuteChange(_: string, minutes: number): void {
    if (isNaN(minutes)) {
      onChange(toDD(d, 0, s, nsew));
    } else {
      onChange(toDD(d, minutes > 59 ? 59 : minutes, s, nsew));
    }
  }

  function onSecondChange(seconds: string, secondsAsNumber: number): void {
    if (isNaN(secondsAsNumber)) {
      onChange(toDD(d, m, 0, nsew));
    } else if (Number(secondsAsString) !== toPrecision(secondsAsNumber, 3)) {
      onChange(toDD(d, m, secondsAsNumber > 59.999 ? 59.999 : secondsAsNumber, nsew));
    } else {
      setSecondAsString(seconds);
    }
  }

  return (
    <HStack>
      <CustomNumberInput value={d} width="50px" onChange={onDegreeChange} max={max} min={0} />
      <Text color={color}>°</Text>
      <CustomNumberInput value={m} width="50px" onChange={onMinuteChange} max={59} min={0} />
      <Text color={color}>'</Text>
      <CustomNumberInput value={secondsAsString} width="70px" onChange={onSecondChange} max={60} min={0} />
      <Text color={color}>" {nsew}</Text>
    </HStack>
  );
}
