import './replay.scss';

import { Stack } from '@chakra-ui/layout';
import ReplayTimeline from '@components/replay/ReplayTimeline';
import { ReplayTimelineProvider } from '@components/replay/ReplayTimelineContext';
import TimelineControls from '@components/replay/TimelineControls';
import { useAppSelector } from '@hooks/redux.hooks';
import { useAuth } from '@hooks/useAuth';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { selectReplayStartTime } from '@redux/replay/replay.selectors';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetReplayStreamQuery } from '@services/replay/replay.api';
import { addMinutes } from 'date-fns';
import { useState } from 'react';

import { MapIdEnum } from '@/types/map.types';
import { ReplayTimeScaleEnum } from '@/types/replay/replay.types';

function ReplayTimelineWrapper() {
  const { token } = useAuth();
  const { activeSite } = useAppSelector(selectActiveUser);
  const replayStartTime = useAppSelector(selectReplayStartTime);

  const replayStartTimeDate = replayStartTime && new Date(replayStartTime);

  const [initialRange] = useState({
    from: replayStartTimeDate || addMinutes(Date.now(), -10),
    to: replayStartTimeDate ? addMinutes(replayStartTimeDate, 10) : new Date(),
  });

  useGetReplayStreamQuery(
    activeSite && token ? { site: activeSite.code, token: token, time: initialRange.from.toISOString() } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <ReplayTimelineProvider
      mapId={MapIdEnum.REPLAY}
      initialRange={initialRange}
      initialTimeScale={ReplayTimeScaleEnum.MINUTE}
    >
      <Stack height="100%" width="100%" backgroundColor="neutral.800" borderTop="2px" borderColor="neutral.700" gap={0}>
        <TimelineControls />
        <ReplayTimeline />
      </Stack>
    </ReplayTimelineProvider>
  );
}

export default ReplayTimelineWrapper;
