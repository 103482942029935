import { ReactComponent as TensionIcon } from '@assets/icons/40x40/ic-tension-40.svg';
import { Icon } from '@chakra-ui/icon';
import { Text, VStack } from '@chakra-ui/layout';
import { Button, Tooltip } from '@chakra-ui/react';
import { usePostEquipmentCommandMutation } from '@services/c2/c2.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { EquipmentCommandTypeEnum, WebRelayActuatorStateEnum } from '@/types/c2/c2.types';
import { EquipmentConnectionStatusEnum, MonitoringEquipment, WebRelayStatus } from '@/types/sensor/status.types';

function toggleActuatorState(state: WebRelayActuatorStateEnum): WebRelayActuatorStateEnum {
  return state === WebRelayActuatorStateEnum.ON ? WebRelayActuatorStateEnum.OFF : WebRelayActuatorStateEnum.ON;
}

type WebRelayButtonProps = {
  monitoringWebRelay: MonitoringEquipment;
};

function WebRelayButton({ monitoringWebRelay }: Readonly<WebRelayButtonProps>) {
  const { formatMessage } = useIntl();
  const [postEquipmentCommand] = usePostEquipmentCommandMutation();

  const isActive = monitoringWebRelay.configuration.active;
  const status = 'actuatorState' in monitoringWebRelay ? monitoringWebRelay : null;
  const isConnected = status?.connectionStatus === EquipmentConnectionStatusEnum.CONNECTED;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevActuatorState, setPrevActuatorState] = useState<WebRelayActuatorStateEnum | undefined>(
    status?.actuatorState,
  );
  if (prevActuatorState !== status?.actuatorState) {
    setPrevActuatorState(status?.actuatorState);
    setIsLoading(false);
  }

  function toggleWebRelayState(status: WebRelayStatus) {
    setIsLoading(true);
    postEquipmentCommand({
      site: status.configuration.site,
      appCode: status.configuration.appCode,
      command: {
        type: EquipmentCommandTypeEnum.SET_WR_ACTUATOR_STATE,
        equipmentCode: status.configuration.code,
        actuatorState: toggleActuatorState(status.actuatorState),
      },
    })
      .unwrap()
      .then(() => {
        createToast(
          formatMessage(
            { id: 'sensors.maintenance.webRelay.toast' },
            { actuatorState: status.actuatorState, success: true },
          ),
          ToastStatusEnum.SUCCESS,
        );
      })
      .catch(() => {
        setIsLoading(false);
        createToast(
          formatMessage(
            { id: 'sensors.maintenance.webRelay.toast' },
            { actuatorState: status.actuatorState, success: false },
          ),
          ToastStatusEnum.ERROR,
        );
      });
  }

  function getTooltipContent() {
    if (isActive) {
      if (isConnected) {
        return undefined;
      }
      return formatMessage(
        { id: 'sensors.maintenance.webRelay.info.disconnected' },
        { name: monitoringWebRelay.configuration.name },
      );
    }
    return formatMessage(
      { id: 'sensors.maintenance.webRelay.info.inactive' },
      { name: monitoringWebRelay.configuration.name },
    );
  }

  return (
    <Tooltip label={getTooltipContent()}>
      <Button
        isDisabled={!isActive || !isConnected}
        isLoading={isLoading}
        height="48px"
        variant="formButtonPrimary"
        whiteSpace="wrap"
        onClick={() => status && toggleWebRelayState(status)}
      >
        <FormattedMessage id={'sensors.maintenance.webRelay.label'} values={{ actuatorState: status?.actuatorState }} />
      </Button>
    </Tooltip>
  );
}

type WebRelayMaintenanceProps = {
  monitoringWebRelays: MonitoringEquipment[];
  gap?: number;
  padding?: number;
  iconColor?: string;
};

function WebRelayMaintenance({
  monitoringWebRelays,
  padding = 4,
  gap = 3,
  iconColor = 'neutral.white',
}: Readonly<WebRelayMaintenanceProps>) {
  if (!monitoringWebRelays.length) {
    return null;
  }
  return monitoringWebRelays.map((monitoringWebRelay) => (
    <VStack
      padding={padding}
      gap={gap}
      key={monitoringWebRelay.configuration.code}
      width="100%"
      backgroundColor="neutral.800"
      userSelect="none"
    >
      <Icon as={TensionIcon} width="40px" height="40px" color={iconColor} />
      <Text whiteSpace="pre-line" align="center">
        <FormattedMessage
          id="sensors.maintenance.webRelay.description"
          values={{ name: monitoringWebRelay.configuration.name }}
        />
      </Text>
      <WebRelayButton key={monitoringWebRelay.configuration.code} monitoringWebRelay={monitoringWebRelay} />
    </VStack>
  ));
}

export default WebRelayMaintenance;
