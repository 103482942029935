export const fonts = {
  space: `'Space Mono', monospace`,
  roboto: `'Roboto Mono Variable', monospace`,
};

export const fontWeights = {
  light: 100,
  regular: 400,
  medium: 500,
};
