import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectLadCameraFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import { ladCameraAzimuthLayer, ladCameraSectorLayer, ladCameraSectorOutlineLayer } from './ladCamera.layer';

function LadCameraLayer() {
  const ladCameraFeatures = useSelectorWithReplayMode(selectLadCameraFeatures);
  if (!isFeatureCollection(ladCameraFeatures)) return null;

  return (
    <Source id={SourceNameEnum.LAD_CAMERAS} type="geojson" data={ladCameraFeatures} generateId>
      <Layer key={LayerNameEnum.LAD_CAMERA_SECTORS} {...ladCameraSectorLayer} />
      <Layer key={LayerNameEnum.LAD_CAMERA_SECTOR_OUTLINES} {...ladCameraSectorOutlineLayer} />
      <Layer key={LayerNameEnum.LAD_CAMERA_AZIMUTHS} {...ladCameraAzimuthLayer} />
    </Source>
  );
}

export default memo(LadCameraLayer);
