import { ReactComponent as InfoIcon } from '@assets/icons/16x16/ic-info-16.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, Icon, Text } from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useAddMarksMutation } from '@services/c2/track.api';
import { createErrorMessage } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { MarkEnum } from '@/types/commons/commons.types';
import { ShareHoloviewPopupData } from '@/types/map.types';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
} & ShareHoloviewPopupData;

export default function ShareTargetOnHoloviewPopup({ site, trackIds, top, left, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [addMarks, { isLoading, isError, error }] = useAddMarksMutation();

  return (
    <DraggableControl
      label={formatMessage({ id: 'shareOnHoloview.title' })}
      width={483}
      height={340}
      top={top}
      left={left}
      onClose={onClose}
    >
      <VStack backgroundColor="neutral.700" width="100%" height="100%" padding={4} gap={4} alignItems="start">
        <Text width="100%" size="lg" whiteSpace="pre-line">
          <FormattedMessage id="shareOnHoloview.description" />
        </Text>
        <HStack gap={2}>
          <Icon as={InfoIcon} color="neutral.white" />
          <Text fontSize="16px">
            <FormattedMessage id="global.unrecoverableAction" />
          </Text>
        </HStack>
        <Box height="40px">
          <ErrorsContainer errors={{}} touched={{}} apiError={isError ? createErrorMessage(error) : undefined} />
        </Box>
        <HStack width="100%" gap={2} justifyContent="center">
          <Box borderWidth="2px" borderColor="cyber.700">
            <Button variant="formButtonSecondary" height="48px" fontSize="18px" fontWeight="regular" onClick={onClose}>
              <FormattedMessage id="global.cancel" />
            </Button>
          </Box>
          <Box borderWidth="2px" borderColor="cyber.700">
            <Button
              variant="formButtonPrimary"
              height="48px"
              fontSize="18px"
              fontWeight="regular"
              isDisabled={isLoading || isError}
              isLoading={isLoading}
              onClick={() =>
                addMarks({
                  site: site,
                  trackIds: trackIds,
                  marks: [MarkEnum.CRISIS],
                })
                  .unwrap()
                  .then(() => {
                    createToast(formatMessage({ id: 'shareOnHoloview.successToast' }), ToastStatusEnum.SUCCESS);
                    onClose();
                  })
              }
            >
              <FormattedMessage id="shareOnHoloview.confirm" />
            </Button>
          </Box>
        </HStack>
      </VStack>
    </DraggableControl>
  );
}
