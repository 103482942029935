import { MarkEnum, TrackLight } from '@/types/commons/commons.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';

import { C2_URL, c2Api } from './c2.api';

export const trackApi = c2Api.injectEndpoints({
  endpoints: (builder) => ({
    setManualTargetIdentification: builder.mutation<
      TrackLight,
      { site: string; identification: IdentificationEnum | null; tracks: TrackLight[] }
    >({
      query: ({ site, identification, tracks }) => ({
        url: `${C2_URL}/track/${site}/identification`,
        method: 'PATCH',
        params: {
          identificationEnum: identification ?? '',
        },
        body: tracks,
      }),
    }),
    setManualTargetClassification: builder.mutation<
      TrackLight,
      { site: string; classificationEnum: ClassificationEnum | null; tracks: TrackLight[] }
    >({
      query: ({ site, classificationEnum, tracks }) => ({
        url: `${C2_URL}/track/${site}/classification`,
        method: 'PATCH',
        params: {
          classificationEnum: classificationEnum ?? '',
        },
        body: tracks,
      }),
    }),
    addMarks: builder.mutation<void, { site: string; trackIds: TrackLight[]; marks: MarkEnum[] }>({
      query: ({ site, trackIds, marks }) => {
        return {
          url: `${C2_URL}/track/${site}/marks`,
          method: 'PATCH',
          params: {
            marks: marks,
          },
          body: trackIds,
        };
      },
    }),
  }),
});

export const {
  useSetManualTargetIdentificationMutation,
  useSetManualTargetClassificationMutation,
  useAddMarksMutation,
} = trackApi;
