import { userLogout } from '@redux/authent/authent.reducer';
import { createEntityAdapter, createSlice, current, PayloadAction, Update } from '@reduxjs/toolkit';
import { ViewState } from 'react-map-gl';

import { Coordinates } from '@/types/commons/commons.types';
import { zoneCategoryToTypeFormMapper, ZoneFormTypeEnum, ZoneToEditType } from '@/types/config/config.types';
import { Dimension, MapIdEnum, PopupControlType, SensorUniqueCodes } from '@/types/map.types';

export enum SelectedFeatureTypeEnum {
  SENSOR = 'SENSOR',
  TARGET = 'TARGET',
}

export type SelectedFeatureKeyType =
  | { type: SelectedFeatureTypeEnum.SENSOR; selectedSensorUniqueCodes: SensorUniqueCodes }
  | { type: SelectedFeatureTypeEnum.TARGET; selectedTargetId: string };

export type DisplayedPerimeterLinksCodes =
  | {
      automatonUniqueCode: string;
      cabinetUniqueCode?: never;
      sectionName?: never;
    }
  | {
      automatonUniqueCode: string;
      cabinetUniqueCode: string;
      sectionName?: never;
    }
  | {
      automatonUniqueCode: string;
      cabinetUniqueCode: string;
      sectionName: string;
    };

export type MapStateType = {
  mapId: string;
  view: ViewState;
  popupControl: PopupControlType;
  selectedFeatureKey: SelectedFeatureKeyType | null;
  selectedDate: Date | null;
  displayedPerimeterLinksCodes: DisplayedPerimeterLinksCodes | null;
  menuNavigation: string[];
  drawInfo: {
    zoneType: ZoneFormTypeEnum | undefined;
    polygonPoints: Coordinates[];
    isDrawnOnto: boolean;
    editedZone: ZoneToEditType | undefined;
  };
};

export const EMPTY_POPUP_CONTROL: PopupControlType = {
  open: false,
  position: { x: 0, y: 0 },
  data: null,
  type: null,
};

export const LIVE: MapStateType = {
  mapId: MapIdEnum.LIVE,
  popupControl: { data: null, position: { x: 0, y: 0 }, open: false, type: null },
  view: {
    longitude: 2.360508,
    latitude: 48.742108,
    zoom: 13, // [0, 22]
    bearing: 0,
    pitch: 0, // [0, 85]
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  selectedFeatureKey: null,
  selectedDate: null,
  displayedPerimeterLinksCodes: null,
  menuNavigation: [],
  drawInfo: {
    zoneType: undefined,
    polygonPoints: [],
    isDrawnOnto: false,
    editedZone: undefined,
  },
  // style: { width: 1000, height: 600 },}]
};

export const REPLAY: MapStateType = {
  mapId: MapIdEnum.REPLAY,
  popupControl: { position: { x: 0, y: 0 }, data: null, open: false, type: null },
  view: {
    longitude: 2.360508,
    latitude: 48.742108,
    zoom: 13, // [0, 22]
    bearing: 0,
    pitch: 0, // [0, 85]
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  selectedFeatureKey: null,
  selectedDate: null,
  displayedPerimeterLinksCodes: null,
  menuNavigation: [],
  drawInfo: {
    zoneType: undefined,
    polygonPoints: [],
    isDrawnOnto: false,
    editedZone: undefined,
  },
};

export const LAD: MapStateType = {
  mapId: MapIdEnum.LAD,
  popupControl: { position: { x: 0, y: 0 }, data: null, open: false, type: null },
  view: {
    longitude: 2.360508,
    latitude: 48.742108,
    zoom: 13, // [0, 22]
    bearing: 0,
    pitch: 0, // [0, 85]
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  selectedFeatureKey: null,
  selectedDate: null,
  displayedPerimeterLinksCodes: null,
  menuNavigation: [],
  drawInfo: {
    zoneType: undefined,
    polygonPoints: [],
    isDrawnOnto: false,
    editedZone: undefined,
  },
};

export const MAP_INITIAL_STATE = {
  ids: [LIVE.mapId, REPLAY.mapId, LAD.mapId],
  entities: {
    [LIVE.mapId]: LIVE,
    [REPLAY.mapId]: REPLAY,
    [LAD.mapId]: LAD,
  },
};

export const mapsAdapter = createEntityAdapter<MapStateType, string>({
  selectId: (map) => map.mapId,
  sortComparer: (a, b) => a.mapId.localeCompare(b.mapId),
});

const maps = createSlice({
  name: 'maps',
  initialState: MAP_INITIAL_STATE,
  reducers: {
    addMap: (state, action: PayloadAction<MapStateType>) => mapsAdapter.addOne(state, action.payload),
    addMany: (state, action: PayloadAction<MapStateType[]>) => mapsAdapter.addMany(state, action.payload),
    updateMap: (state, action: PayloadAction<Update<MapStateType, string>>) =>
      mapsAdapter.updateOne(state, action.payload),
    upsertMap: (state, action: PayloadAction<MapStateType>) => mapsAdapter.upsertOne(state, action.payload),
    deleteMap: (state, action: PayloadAction<string>) => mapsAdapter.removeOne(state, action.payload),
    toggleDimensionByMapId: (
      state,
      action: PayloadAction<{
        mapId: MapIdEnum;
        currentDimension: Dimension;
      }>,
    ) => {
      const { mapId, currentDimension } = action.payload;
      const newPitch = currentDimension === Dimension['3D'] ? 0 : 60;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: { view: { ...state.entities[mapId]!.view, pitch: newPitch } },
      });
    },
    updateZoomByMapId: (state, action: PayloadAction<{ mapId: MapIdEnum; zoom: number }>) => {
      const { mapId, zoom } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: { view: { ...state.entities[mapId]!.view, zoom: zoom } },
      });
    },
    centerMapByMapId: (state, action: PayloadAction<{ mapId: MapIdEnum; position: Coordinates }>) => {
      const { mapId, position } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: {
          view: { ...state.entities[mapId]!.view, latitude: position.latitude, longitude: position.longitude },
        },
      });
    },
    updateBearingByMapId: (state, action: PayloadAction<{ mapId: MapIdEnum; bearing: number }>) => {
      const { mapId, bearing } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: { view: { ...state.entities[mapId]!.view, bearing: bearing } },
      });
    },
    updatePopupControlByMapId: (state, action: PayloadAction<{ mapId: MapIdEnum; popupControl: PopupControlType }>) => {
      const { mapId, popupControl } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: { popupControl: popupControl },
      });
    },
    closePopupByMapId: (state, action: PayloadAction<MapIdEnum>) => {
      mapsAdapter.updateOne(state, {
        id: action.payload,
        changes: { popupControl: EMPTY_POPUP_CONTROL },
      });
    },
    updateSelectedFeatureKeyByMapId: (
      state,
      action: PayloadAction<{ mapId: MapIdEnum; selectedFeatureKey: SelectedFeatureKeyType | null }>,
    ) => {
      const { mapId, selectedFeatureKey } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: { selectedFeatureKey: selectedFeatureKey, selectedDate: selectedFeatureKey ? new Date() : null },
      });
    },
    deselectFeatureByMapId: (state, action: PayloadAction<MapIdEnum>) => {
      mapsAdapter.updateOne(state, {
        id: action.payload,
        changes: { selectedFeatureKey: null, selectedDate: null },
      });
    },
    updateDisplayedPerimeterLinksCodesByMapId: (
      state,
      action: PayloadAction<{
        mapId: MapIdEnum;
        displayedPerimeterLinksCodes: DisplayedPerimeterLinksCodes | null;
      }>,
    ) => {
      const { mapId, displayedPerimeterLinksCodes } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: { displayedPerimeterLinksCodes },
      });
    },
    hidePerimeterLinksByMapId: (state, action: PayloadAction<MapIdEnum>) => {
      mapsAdapter.updateOne(state, {
        id: action.payload,
        changes: { displayedPerimeterLinksCodes: null },
      });
    },
    switchDrawModeByMapId: (state, action: PayloadAction<{ mapId: MapIdEnum; isDrawable?: boolean }>) => {
      const { mapId, isDrawable } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: {
          drawInfo: {
            zoneType: undefined,
            polygonPoints: [],
            isDrawnOnto: isDrawable ?? !current(state).entities[mapId].drawInfo.isDrawnOnto,
            editedZone: undefined,
          },
        },
      });
    },
    updateDrawZoneTypeSelected: (
      state,
      action: PayloadAction<{ mapId: MapIdEnum; zoneType: ZoneFormTypeEnum | undefined }>,
    ) => {
      const { mapId, zoneType } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: {
          drawInfo: {
            ...state.entities[mapId].drawInfo,
            zoneType: zoneType,
          },
        },
      });
    },
    updateDrawPolygonsData: (state, action: PayloadAction<{ mapId: MapIdEnum; points: Coordinates[] }>) => {
      const { mapId, points } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: {
          drawInfo: {
            ...state.entities[mapId].drawInfo,
            polygonPoints: points,
          },
        },
      });
    },
    updateEditPolygonsData: (
      state,
      action: PayloadAction<{
        mapId: MapIdEnum;
        zone: ZoneToEditType;
      }>,
    ) => {
      const { mapId, zone } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: {
          drawInfo: {
            ...state.entities[mapId].drawInfo,
            polygonPoints: zone.polygon,
            editedZone: zone,
            zoneType: zoneCategoryToTypeFormMapper(zone.type, 'level' in zone ? zone.level : undefined),
          },
        },
      });
    },
    updateMenuNavigationStatus: (state, action: PayloadAction<{ mapId: MapIdEnum; selectedOptions: string[] }>) => {
      const { mapId, selectedOptions } = action.payload;
      mapsAdapter.updateOne(state, {
        id: mapId,
        changes: {
          menuNavigation: selectedOptions,
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => MAP_INITIAL_STATE);
  },
});

export const {
  addMap,
  addMany,
  updateMap,
  upsertMap,
  deleteMap,
  toggleDimensionByMapId,
  updatePopupControlByMapId,
  updateZoomByMapId,
  updateBearingByMapId,
  closePopupByMapId,
  updateSelectedFeatureKeyByMapId,
  deselectFeatureByMapId,
  updateDisplayedPerimeterLinksCodesByMapId,
  hidePerimeterLinksByMapId,
  centerMapByMapId,
  switchDrawModeByMapId,
  updateDrawZoneTypeSelected,
  updateDrawPolygonsData,
  updateEditPolygonsData,
  updateMenuNavigationStatus,
} = maps.actions;

export default maps.reducer;
