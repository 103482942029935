import { ReactComponent as DroneIcon } from '@assets/components/gauge/drone.svg';
import { ReactComponent as SelectedDroneIcon } from '@assets/components/gauge/selected-drone.svg';
import { Box, Icon } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectSelectedTarget, selectSpatialDrones } from '@redux/situation/situation.selectors';
import bearing from '@turf/bearing';
import { bearingToAzimuth } from '@turf/helpers';
import { getMapIdFromReplayMode, getTargetColor } from '@utils/map/map.utils';
import { getPositionOnCircle } from '@utils/sensors/camera/gauge.utils';

import { Coordinates } from '@/types/commons/commons.types';

type Props = {
  replayMode: boolean;
  cameraPosition: Coordinates;
};

export default function AzimuthCompassTargets({ replayMode, cameraPosition }: Readonly<Props>) {
  const targets = useAppSelector((state) => selectSpatialDrones(state, replayMode));
  const currentContext = useAppSelector(selectContext);
  const selectedTarget = useAppSelector((state) =>
    selectSelectedTarget(state, getMapIdFromReplayMode(replayMode, currentContext)),
  );

  return (
    <Box position="absolute" width="100%" height="100%" top={0}>
      {targets
        .filter((target) => !!target.lastPosition.location?.position)
        .map((target) => {
          const targetCoordinates = target.lastPosition.location!.position!;
          const targetPosition = [targetCoordinates.longitude, targetCoordinates.latitude];
          const angle = +bearingToAzimuth(
            bearing([cameraPosition.longitude, cameraPosition.latitude], targetPosition),
          ).toFixed(1);
          const position = getPositionOnCircle(angle - 90, 90, { x: 90, y: 90 }, 0);
          const isSelected = selectedTarget?.id === target.id;
          return (
            <Icon
              as={selectedTarget?.id === target.id ? SelectedDroneIcon : DroneIcon}
              color={getTargetColor(target)}
              key={target.id}
              width={isSelected ? '54px' : '24px'}
              height="24px"
              position="absolute"
              transform="translate(-12px, -12px)"
              top={`${position.y}px`}
              left={`${position.x}px`}
            />
          );
        })}
    </Box>
  );
}
