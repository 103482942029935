import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontSize: '18px',
    fontFamily: 'roboto',
    fontWeight: 'regular',
    lineHeight: '24px',
    color: 'neutral.white',
    marginLeft: 1,
    _disabled: {
      opacity: 1,
      cursor: 'default',
    },
  },
  control: {
    borderRadius: 0,
    border: '2px solid',
    borderColor: 'neutral.300',
    background: 'transparent !important',
    color: 'neutral.white',
    height: '24px',
    width: '24px',
    _focusVisible: {
      boxShadow: 'none !important',
    },
    _disabled: {
      cursor: 'default',
      color: 'neutral.500 !important',
      borderColor: 'neutral.500 !important',
    },
    _hover: {
      borderColor: 'sky.500',
      color: 'sky.500',
    },
    _checked: {
      background: 'cyber.600 !important',
      borderColor: 'cyber.500',
      _hover: {
        borderColor: 'sky.500',
      },
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
});
