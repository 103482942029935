import { ReactComponent as AirportIcon } from '@assets/icons/24x24/ic-airport-24.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/24x24/ic-search-24.svg';
import { HStack } from '@chakra-ui/layout';
import { Icon, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import DropSelect from '@components/common/menu/DropSelect';
import { Dispatch } from 'react';
import { useIntl } from 'react-intl';

import { Platform } from '@/types/config/config.types';

type Props = {
  platformFilters: { platform: Platform; active: boolean }[];
  setState: Dispatch<{ platform: Platform; active: boolean }[]>;
};

function Header({ platformFilters, setState }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  return (
    <HStack justifyContent="start" width="100%" gap={0.25}>
      <DropSelect
        icon={AirportIcon}
        values={
          new Set(
            platformFilters
              .filter((platformFilter) => platformFilter.active)
              .map((platformFilter) => platformFilter.platform.code),
          )
        }
        options={[...platformFilters].map((platformFilter) => ({
          value: platformFilter.platform.code,
          label: platformFilter.platform.name,
        }))}
        onChange={(values) =>
          setState(
            platformFilters.map((platformFilter) => ({
              platform: platformFilter.platform,
              active: values.has(platformFilter.platform.code),
            })),
          )
        }
      />
      <CockpitCustomScrollbar>
        <HStack gap={0.25}>
          {platformFilters
            .filter((platformFilter) => platformFilter.active)
            .map((platformFilter) => (
              <Text
                paddingX={2}
                key={platformFilter.platform.code}
                paddingY="11px"
                backgroundColor="neutral.black"
                flexShrink={0}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {platformFilter.platform.name}
              </Text>
            ))}
        </HStack>
      </CockpitCustomScrollbar>
      <InputGroup marginLeft="auto" width="291px" flexShrink={0}>
        <Input
          placeholder={formatMessage({ id: 'components.monitoring.header.search' })}
          height="40px"
          variant="transparent"
          isDisabled
        ></Input>
        <InputRightElement>
          <Icon as={SearchIcon} width="24px" height="24px" color="neutral.300" />
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
}

export default Header;
