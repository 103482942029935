import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Button, ButtonGroup, TabPanel, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser, selectGlobalScreenConfigurationCode } from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import { usePatchContextMutation } from '@services/config/setting.api';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContextEnum } from '@/types/config/screenConfiguration.types';

import GridLayout from './GridLayout';

export default function ScreenConfiguration() {
  const currentContext = useAppSelector(selectContext);
  const user = useAppSelector(selectActiveUser);
  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);

  const globalScreenConfiguration =
    useAppSelector((state) =>
      GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
        state,
        currentGlobalScreenConfigurationCode,
      ),
    ) ?? null;

  const [selectedContext, setSelectedContext] = useState<string>(currentContext);

  const [patchContext, { isLoading: isLoadingContext }] = usePatchContextMutation();

  return (
    <TabPanel height="100%" backgroundColor="white" padding={3}>
      <VStack height="100%" gap="20px" alignItems="start" overflow="hidden" overflowY="auto">
        <Text fontWeight="medium" fontSize="16px" color="neutral.black">
          <FormattedMessage id={`screenConfiguration.subtitle`} />
        </Text>
        {globalScreenConfiguration === null ? (
          <Center width="100%" height="100%">
            <Text color="neutral.black">
              <FormattedMessage id="screenConfiguration.noConfiguration" />
            </Text>
          </Center>
        ) : (
          <VStack width="100%" alignItems="start" justifyContent="center" gap="20px">
            <HStack flexWrap="wrap" flexShrink={0} gap="20px">
              <ButtonGroup>
                {Object.values(ContextEnum)
                  .filter((context) =>
                    globalScreenConfiguration.screenConfigurations.some(
                      (sc) => sc.context === context && sc.gridLayoutCode,
                    ),
                  )
                  .map((context) => (
                    <Button
                      key={context}
                      isActive={context === selectedContext}
                      isDisabled={context === selectedContext}
                      onClick={() => setSelectedContext(context)}
                    >
                      <FormattedMessage id={`screenConfiguration.context.${context}`} />
                    </Button>
                  ))}
              </ButtonGroup>
            </HStack>
          </VStack>
        )}
        {globalScreenConfiguration && (
          <HStack width="100%" gap={1} justifyContent="center" alignItems="flex-start" flexGrow={1}>
            {globalScreenConfiguration.screenConfigurations
              .filter((config) => config.context === selectedContext)
              .toSorted((config1, config2) => Number(config1.code) - Number(config2.code))
              .map((screenConfig) => (
                <GridLayout gridLayoutCode={screenConfig.gridLayoutCode} />
              ))}
          </HStack>
        )}
        {globalScreenConfiguration && (
          <Button
            isDisabled={
              currentContext === selectedContext ||
              !globalScreenConfiguration.screenConfigurations.some(
                (sc) => sc.context === selectedContext && sc.gridLayoutCode,
              )
            }
            alignSelf="center"
            isLoading={isLoadingContext}
            onClick={() => {
              if (selectedContext !== currentContext) {
                patchContext({ login: user.login, context: selectedContext });
              }
            }}
          >
            <FormattedMessage id="screenConfiguration.applyScreens" />
          </Button>
        )}
      </VStack>
    </TabPanel>
  );
}
