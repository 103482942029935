import { Position } from '@turf/helpers';
import { useReducer } from 'react';

import { Coordinates } from '@/types/commons/commons.types';

interface State {
  mousePosition: Coordinates | null;
  isClickEnabled: boolean;
  measurePoints: Position[];
}

type Action =
  | { type: 'SET_MOUSE_POSITION'; payload: Coordinates | null }
  | { type: 'SET_IS_CLICK_ENABLED'; payload: boolean }
  | { type: 'SET_MEASURE_POINTS'; payload: Position[] };

const initialState: State = {
  mousePosition: null,
  isClickEnabled: false,
  measurePoints: [],
};

function measureReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_MOUSE_POSITION':
      return { ...state, mousePosition: action.payload };
    case 'SET_IS_CLICK_ENABLED':
      return { ...state, isClickEnabled: action.payload };
    case 'SET_MEASURE_POINTS':
      return { ...state, measurePoints: action.payload };
    default:
      return state;
  }
}

export const useMapMeasureState = () => {
  const [state, dispatch] = useReducer(measureReducer, initialState);

  const setMousePosition = (position: Coordinates | null) => {
    dispatch({ type: 'SET_MOUSE_POSITION', payload: position });
  };

  const setIsClickEnabled = (isClickEnabled: boolean) => {
    dispatch({ type: 'SET_IS_CLICK_ENABLED', payload: isClickEnabled });
  };

  const setMeasurePoints = (points: Position[]) => {
    dispatch({ type: 'SET_MEASURE_POINTS', payload: points });
  };

  return {
    state,
    setMousePosition,
    setIsClickEnabled,
    setMeasurePoints,
  };
};
