import './gauge.scss';

import { ReactComponent as ArrowDown } from '@assets/icons/cameras/gauge-arrow-down.svg';
import { Box, Center, HStack } from '@chakra-ui/layout';
import { Text, useSize } from '@chakra-ui/react';
import { formatDegree } from '@utils/sensors/camera/gauge.utils';
import { memo, useMemo, useRef } from 'react';

import { Coordinates } from '@/types/commons/commons.types';

import VerticalGraduation from './VerticalGraduation';
import VerticalTargets from './VerticalTargets';

interface OwnProps {
  fov: number;
  tilt: number;
  min: number;
  max: number;
  cameraPosition: Coordinates;
  cameraAltitude: number;
  replayMode: boolean;
}

function VerticalGauge({ fov, tilt, min, max, cameraPosition, cameraAltitude, replayMode }: Readonly<OwnProps>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useSize(containerRef);

  const multiplierPrecision = 10;
  const numberOfInterval = 71;

  //Calculate the position of both fov brackets
  const fovHeight = useMemo(() => {
    if (dimensions && fov > 0) {
      return (dimensions.height / (multiplierPrecision * numberOfInterval)) * ((fov * multiplierPrecision) / 2);
    }
    return null;
  }, [dimensions, fov]);

  return (
    <Box
      width="175px"
      position="absolute"
      height="25%"
      left="26%"
      top="50%"
      transform="translateY(-50%)"
      className="video-control-gauge vertical"
      ref={containerRef}
      pointerEvents="none"
    >
      {fovHeight !== null && (
        <>
          <Text
            position="absolute"
            zIndex={1}
            top="50%"
            lineHeight="4px"
            color="#979797"
            left="51%"
            transform={`rotate(90deg) translateX(calc(-${fovHeight}px - 50%))`}
            variant="gauge"
            fontSize="20px"
            fontWeight="700"
          >
            [
          </Text>
          <Text
            position="absolute"
            zIndex={1}
            top="50%"
            lineHeight="4px"
            color="#979797"
            left="51%"
            transform={`rotate(90deg) translateX(calc(${fovHeight}px))`}
            variant="gauge"
            fontSize="20px"
            fontWeight="700"
          >
            ]
          </Text>
        </>
      )}
      {dimensions && (
        <>
          <VerticalGraduation
            numberOfInterval={numberOfInterval}
            multiplierPrecision={multiplierPrecision}
            height={dimensions.height}
            tilt={tilt}
            max={max}
            min={min}
          />
          <VerticalTargets
            numberOfInterval={numberOfInterval}
            height={dimensions.height}
            tilt={tilt}
            cameraPosition={cameraPosition}
            cameraAltitude={cameraAltitude}
            replayMode={replayMode}
          />
        </>
      )}
      <Box position="absolute" top="50%" left="-5%" transform="translateY(-50%)" zIndex={4}>
        <HStack gap={0}>
          <Center width="68px" height="32px" backgroundColor="white" border="2px solid black">
            <Center backgroundColor="black" width="60px" height="24px">
              <Text lineHeight="16px" size="sm">
                {formatDegree(tilt)}
              </Text>
            </Center>
          </Center>
          <ArrowDown style={{ transform: 'rotate(-90deg)', zIndex: 4, marginLeft: '2px' }} />
        </HStack>
      </Box>
    </Box>
  );
}

export default memo(VerticalGauge);
