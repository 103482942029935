export type AnyDate = string | number | Date;

export enum DateTimeEnum {
  DATE = 'date',
  TIME = 'time',
  DATE_TIME = 'datetime-local',
  TIME_DATE = 'timedate',
  DATE_TIME_SECONDS = 'datetime-seconds',
  DATE_TIMELINE = 'date-timeline',
  DATE_TIME_SECONDS_ALTERNATE = 'datetime-seconds-alernate',
  DATE_TIME_SECONDS_PERIM_FORMAT = 'datetime-seconds-perim-format',
}

export type TimeReference = 'days' | 'hours' | 'minutes' | 'seconds';
