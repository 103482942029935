import { ReactComponent as CheckedIcon } from '@assets/components/checkbox/checked.svg';
import { ReactComponent as IndeterminateIcon } from '@assets/components/checkbox/indeterminate.svg';
import { Checkbox, CheckboxProps, Spinner } from '@chakra-ui/react';

function CustomIcon({
  isIndeterminate = false,
  isChecked = false,
  isLoading = false,
}: Readonly<{ isChecked?: boolean; isIndeterminate?: boolean; isLoading?: boolean }>) {
  if (isLoading) {
    return <Spinner width="16px" height="16px" color="neutral.white" />;
  }
  const icon = isIndeterminate ? <IndeterminateIcon /> : <CheckedIcon />;
  return isChecked ? icon : null;
}

export default function CustomCheckbox({ isLoading, ...props }: Readonly<CheckboxProps & { isLoading?: boolean }>) {
  return (
    <Checkbox
      {...props}
      icon={<CustomIcon isLoading={isLoading} isChecked={props.isChecked} isIndeterminate={props.isIndeterminate} />}
    />
  );
}
