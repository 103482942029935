import { ReactComponent as AutomatonSegmentHealthy } from '@assets/icons/70x70/sensor/icSymb-tro-gn-L.svg';
import { ReactComponent as AutomatonSegmentSleep } from '@assets/icons/70x70/sensor/icSymb-tro-gy-L.svg';
import { ReactComponent as AutomatonSegmentHtDetection } from '@assets/icons/70x70/sensor/icSymb-tro-ht-detection-gn-L.svg';
import { ReactComponent as AutomatonSegmentDegraded } from '@assets/icons/70x70/sensor/icSymb-tro-or-L.svg';
import { ReactComponent as AutomatonSegmentEffraction } from '@assets/icons/70x70/sensor/icSymb-tro-rd-L.svg';
import { ReactComponent as AutomatonSegmentDisconnected } from '@assets/icons/70x70/sensor/icSymb-tro-wh-L.svg';
import { ReactComponent as AutomatonSegmentMaintenance } from '@assets/icons/70x70/sensor/icSymb-tro-work-or-L.svg';
import { Center, Text, VStack } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';

import { AutomatonSectionStatusEnum, SensorStatusEnum } from '@/types/sensor/status.types';

type AutomatonSegmentStateIconProps = {
  label?: string;
  status?: AutomatonSectionStatusEnum;
};

const iconComponents = {
  [SensorStatusEnum.DISCONNECTED]: AutomatonSegmentDisconnected,
  maintenance: AutomatonSegmentMaintenance,
  [SensorStatusEnum.SLEEP]: AutomatonSegmentSleep,
  [SensorStatusEnum.EFFRACTION]: AutomatonSegmentEffraction,
  [SensorStatusEnum.DEGRADED]: AutomatonSegmentDegraded,
  [SensorStatusEnum.HT_DETECTION]: AutomatonSegmentHtDetection,
  [SensorStatusEnum.HEALTHY]: AutomatonSegmentHealthy,
};
const DefaultStateIcon = iconComponents[SensorStatusEnum.DISCONNECTED];

function AutomatonSectionIcon({ status, label }: Readonly<AutomatonSegmentStateIconProps>) {
  const IconComponent = status ? iconComponents[status] : DefaultStateIcon;

  return (
    <VStack alignItems="center" gap={0} cursor="default">
      <Center>
        <IconComponent />
      </Center>
      {label && (
        <Tooltip isDisabled={label.length < 15} label={label}>
          <Text
            backgroundColor="neutral.black"
            position="relative"
            bottom={0}
            textOverflow="ellipsis"
            textAlign="center"
            noOfLines={1}
            width="125px"
          >
            {label}
          </Text>
        </Tooltip>
      )}
    </VStack>
  );
}

export default AutomatonSectionIcon;
