import { Box } from '@chakra-ui/layout';
import { HStack, Text } from '@chakra-ui/react';
import Player from '@components/common/video/Player';
import { getNimbleStreamUrl } from '@utils/sensors/camera/camera.utils';
import { Dispatch, useEffect } from 'react';

import { SubCameraConfiguration } from '@/types/sensor/configuration.types';

import { ControlState } from './CameraControl';

type Props = {
  state: ControlState;
  setState: Dispatch<Partial<ControlState>>;
  replayMode: boolean;
  uniqueKey: string;
};

export default function PipContainer({ state, setState, replayMode, uniqueKey }: Readonly<Props>) {
  function handleSwapFlux(flux: SubCameraConfiguration, index: number) {
    const newPipFlux = state.displayedFlux
      ? state.pipFlux.toSpliced(index, 1, state.displayedFlux)
      : state.pipFlux.filter((sc) => sc.code !== flux.code);
    setState({ displayedFlux: flux, pipFlux: newPipFlux });
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (state.pip) {
      timeout = setTimeout(() => {
        setState({ pip: false });
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [state.pipFlux, state.displayedFlux, setState, state.pip]);

  if (!state.pip) {
    return null;
  }

  return (
    <HStack
      backgroundColor="neutral.black"
      left="50%"
      transform="translateX(-50%)"
      position="absolute"
      border="2px solid"
      borderColor="neutral.black"
      bottom={12}
      zIndex={1}
      gap={0.25}
    >
      {state.pipFlux.map((flux, index) => {
        return (
          <Box
            position="relative"
            cursor="pointer"
            width="396px"
            aspectRatio="16/9"
            key={`pip-player-container-${flux.code}`}
            onClick={() => handleSwapFlux(flux, index)}
          >
            <Player
              id={`pip-player-${uniqueKey}-${flux.code}`}
              src={getNimbleStreamUrl(flux, replayMode)}
              replayMode={replayMode}
              type={flux.protocol ?? flux.sourceProtocol}
            />
            <Text
              position="absolute"
              bottom={0}
              left={0}
              backgroundColor="neutral.800-op70"
              paddingY={0.25}
              paddingX={1}
            >
              {flux.name}
            </Text>
          </Box>
        );
      })}
    </HStack>
  );
}
