import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import SensorIcon from '@components/map/infos/sensors/SensorIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSensorByCode } from '@redux/sensors/sensors.selectors';
import { selectMonitoringSensorBySensorUniqueCode } from '@redux/situation/monitoring.selectors';
import { getSensorIconKey } from '@utils/map/sensor.utils';
import { FormattedMessage } from 'react-intl';

import { AlertLevelEnum, SensorAlert } from '@/types/data/data.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';

import AlertTimeToast from './AlertTimeToast';

interface OwnProps {
  alert: SensorAlert;
  currentDate: Date;
}

export default function SensorAlertToast({ alert, currentDate }: Readonly<OwnProps>) {
  const sensorStatus = useAppSelector((state) =>
    selectMonitoringSensorBySensorUniqueCode(state, false, alert.identifier),
  );
  const sensorConfiguration = useAppSelector((state) => selectSensorByCode(state, alert.identifier));

  return (
    <>
      <HStack
        gap={2}
        width="100%"
        padding={2}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 4 : 3}
        backgroundColor="neutral.white"
      >
        <SensorIcon
          sensorIconName={getSensorIconKey(
            alert.sensorType,
            sensorStatus?.configuration.active,
            sensorStatus?.configuration.maintenance,
            SensorStatusEnum.DEGRADED,
          )}
          size="md"
        />
        <VStack alignItems="start" gap={0.5}>
          <Text size="md" color="neutral.black">
            <FormattedMessage id="notification.sensorFailed" />
          </Text>
          <AlertTimeToast startTime={alert.startTime} endTime={alert.endTime} currentDate={currentDate} />
        </VStack>
      </HStack>
      <VStack
        width="100%"
        backgroundColor="neutral.100"
        alignItems="start"
        paddingY={2}
        paddingRight={3}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 5 : 4}
      >
        <Text fontSize="12px" fontWeight="medium" color="neutral.600">
          {sensorConfiguration?.name ?? alert.identifier.split('_').at(-1)}
        </Text>
      </VStack>
    </>
  );
}
