import { selectActiveUser } from '@redux/authent/authent.selectors';
import { addMessage, updateNewMessageEntry } from '@redux/chat/chat.reducer';
import { RootState } from '@redux/store';
import { createListenerMiddleware } from '@reduxjs/toolkit';

export const chatListenerMiddleware = createListenerMiddleware();

chatListenerMiddleware.startListening({
  actionCreator: addMessage,
  effect: async (action, listenerApi) => {
    const activeUser = selectActiveUser(listenerApi.getState() as RootState);
    if (action.payload.author?.id !== activeUser.id) {
      listenerApi.dispatch(updateNewMessageEntry(true));
    }
  },
});
