import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { selectSettingsState } from '@redux/settings/settings.selectors';
import { usePatchAutomatedLadMutation } from '@services/config/setting.api';
import { FormattedMessage } from 'react-intl';

export default function PerimeterSettings() {
  const { login } = useAppSelector(selectActiveUser);
  const settings = useAppSelector(selectSettingsState);

  const [updateAutomatedLad, { isLoading: isPatchAutomationSettingLoading }] = usePatchAutomatedLadMutation();

  const handleLadAutomation = () => {
    updateAutomatedLad({
      login: login,
      automatedLad: !settings.automatedLad,
    });
  };

  return (
    <VStack width="100%" backgroundColor="neutral.800" gap={4} padding={4} alignItems="start">
      <Text fontSize="22px">
        <FormattedMessage id="components.userSettings.perimeter.title" />
      </Text>
      <HStack width="100%" gap={2}>
        <CustomSwitch
          isDisabled={isPatchAutomationSettingLoading}
          isChecked={settings.automatedLad}
          onClick={handleLadAutomation}
        />
        <Text size="md">
          <FormattedMessage id="components.userSettings.perimeter.automatedLad" />
        </Text>
      </HStack>
    </VStack>
  );
}
