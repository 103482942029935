import { PointOfInterest } from '@/types/config/pointOfInterest.types';

import { CONFIG_URL, configApi } from './config.api';

export const pointOfInterestApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    createPointsOfInterest: builder.mutation<PointOfInterest, Partial<PointOfInterest>>({
      query: (poi) => ({
        url: `${CONFIG_URL}/points-of-interest`,
        method: 'POST',
        body: poi,
      }),
    }),
    updatePointsOfInterest: builder.mutation<PointOfInterest, { id: number; poi: PointOfInterest }>({
      query: ({ id, poi }) => ({
        url: `${CONFIG_URL}/points-of-interest/${id}`,
        method: 'PUT',
        body: poi,
      }),
    }),
    deletePointOfInterest: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/points-of-interest/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useUpdatePointsOfInterestMutation, useCreatePointsOfInterestMutation } = pointOfInterestApi;
