import { ReactComponent as InfoEmergencyIcon } from '@assets/icons/40x40/ic-info-emergency-40.svg';
import { Icon } from '@chakra-ui/icon';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Button, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type Props = {
  isVisible: boolean;
  onClick: () => void;
};

export default function ZoneApiError({ isVisible, onClick }: Readonly<Props>) {
  if (!isVisible) {
    return null;
  }
  return (
    <Center width="100%" height="100%" backgroundColor="neutral.700" position="absolute" zIndex={2}>
      <VStack width="100%" gap={4}>
        <HStack gap={2} alignItems="start">
          <Icon as={InfoEmergencyIcon} color="neutral.white" width="40px" height="40px" />
          <VStack gap={3} width="350px" alignItems="start">
            <Text size="md">
              <FormattedMessage id="zone.errorTitle" />
            </Text>
            <Text whiteSpace="pre-line" fontSize="16px">
              <FormattedMessage id="zone.errorSubTitle" />
            </Text>
          </VStack>
        </HStack>
        <Button type="button" onClick={onClick} variant="formButtonPrimary" width="120px">
          <FormattedMessage id="global.back" />
        </Button>
      </VStack>
    </Center>
  );
}
