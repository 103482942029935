import { Icon } from '@chakra-ui/icon';
import { Box } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectSelectedTarget, selectSpatialDrones } from '@redux/situation/situation.selectors';
import distance from '@turf/distance';
import { getMapIdFromReplayMode, getTargetColor } from '@utils/map/map.utils';
import {
  filterTargetsKeepVerticalClosest,
  formatDegree,
  TargetInfo,
  verticalTargetOffset,
} from '@utils/sensors/camera/gauge.utils';
import { getDroneIcon, getTargetPriority } from '@utils/target.utils';
import { inRange } from 'lodash';
import { useMemo } from 'react';

import { Target } from '@/types/c2/c2.types';
import { Coordinates } from '@/types/commons/commons.types';
import { SpatialLocation } from '@/types/sensor/sensor.types';

type Props = {
  height: number;
  tilt: number;
  numberOfInterval: number;
  cameraPosition: Coordinates;
  cameraAltitude: number;
  replayMode: boolean;
};

export default function VerticalTargets({
  height,
  tilt,
  numberOfInterval,
  cameraPosition,
  cameraAltitude,
  replayMode,
}: Readonly<Props>) {
  const targets = useAppSelector((state) => selectSpatialDrones(state, replayMode));
  const currentContext = useAppSelector(selectContext);
  const selectedTarget = useAppSelector((state) =>
    selectSelectedTarget(state, getMapIdFromReplayMode(replayMode, currentContext)),
  );

  //Contain the list of target we need to display on the gauge,
  //only keeping those in range and the first above and bellow the gauge if they exist
  const filteredTargetsWithInfos: TargetInfo[] = useMemo(() => {
    const minVisibleAngle = Math.max(tilt - Math.floor(numberOfInterval / 2), -90);
    const maxVisibleAngle = Math.min(tilt + Math.floor(numberOfInterval / 2), 90);
    const targetsWithInfos = targets
      .filter(
        (target) => target.lastPosition.location?.height !== null && target.lastPosition.location?.height !== undefined,
      )
      .map((target: Target<SpatialLocation>) => {
        const targetPosition = target.lastPosition!.location!.position!;
        const targetDistance = distance(
          [cameraPosition.longitude, cameraPosition.latitude],
          [targetPosition.longitude, targetPosition.latitude],
          { units: 'meters' },
        );
        const targetAltitude = target.lastPosition!.location!.altitude!;
        const angle = +(Math.atan((targetAltitude - cameraAltitude) / targetDistance) * (180 / Math.PI)).toFixed(1);
        return {
          target,
          angle,
          isInRange: inRange(angle, minVisibleAngle, maxVisibleAngle),
        };
      });
    return filterTargetsKeepVerticalClosest(targetsWithInfos, tilt, selectedTarget?.id).sort(
      (a, b) => b.angle - a.angle,
    );
  }, [
    cameraAltitude,
    cameraPosition.latitude,
    cameraPosition.longitude,
    numberOfInterval,
    selectedTarget?.id,
    targets,
    tilt,
  ]);

  return (
    <Box position="relative" height="100%" width="100%">
      {filteredTargetsWithInfos.map((targetWithInfos) => {
        const color = getTargetColor(targetWithInfos.target);
        const isSelected = selectedTarget?.id === targetWithInfos.target.id;
        return (
          <Box
            key={targetWithInfos.target.id}
            position="absolute"
            top="50%"
            left="43%"
            transform={`translateY(calc(-50% - ${verticalTargetOffset(
              height,
              tilt,
              numberOfInterval,
              targetWithInfos,
            )}px))`}
            backgroundColor={color}
            zIndex={isSelected ? 10 : getTargetPriority(targetWithInfos.target) + 2}
          >
            <Icon
              as={getDroneIcon(targetWithInfos.target)}
              position="absolute"
              transform="translate(50%, -50%)"
              width="22px"
              height="22px"
            />
            {isSelected && (
              <Box
                position="absolute"
                transform={`translate(calc(50% + 2px), -50%) rotate(45deg)`}
                border="1px solid"
                borderColor={color}
                width="20px"
                height="20px"
              />
            )}
            {(!targetWithInfos.isInRange || isSelected) && (
              <Text color={color} position="absolute" variant="gauge" size="xs" transform="translateY(-50%)" right={0}>
                {formatDegree(targetWithInfos.angle)}
              </Text>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
