import { Center, Grid, HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';

import { MonitoringState } from '../list/MonitoringList';

type Props<T> = {
  values: Set<T[keyof T]>;
  stateKey: keyof MonitoringState;
  title: string;
  setState: Dispatch<Partial<MonitoringState>>;
  defaultFilters: Set<T[keyof T]>;
};

function CategoryFilter<T extends { [key: string]: string }>({
  values,
  stateKey,
  title,
  setState,
  defaultFilters,
}: Readonly<Props<T>>) {
  const allOn = [...defaultFilters].every((item) => values.has(item));
  const isEmpty = values.size === 0;

  const nbOfRow = Math.ceil(defaultFilters.size / 3);

  return (
    <VStack gap={2} padding={2} alignItems="start" width="100%" backgroundColor="neutral.800">
      <Text fontWeight="medium" color="neutral.300">
        {title}
      </Text>
      <HStack gap={1}>
        <Text
          paddingX={1}
          paddingY="1px"
          border="2px solid"
          borderColor={allOn ? 'neutral.600' : 'cyber.500'}
          color={allOn ? 'neutral.500' : 'neutral.white'}
          cursor={allOn ? 'default' : 'pointer'}
          noOfLines={1}
          onClick={() =>
            !allOn &&
            setState({
              [stateKey]: new Set(defaultFilters),
            })
          }
        >
          <FormattedMessage id="global.all" />
        </Text>
        <Text
          paddingX={1}
          paddingY="1px"
          border="2px solid"
          borderColor={isEmpty ? 'neutral.600' : 'cyber.500'}
          color={isEmpty ? 'neutral.500' : 'neutral.white'}
          cursor={isEmpty ? 'default' : 'pointer'}
          noOfLines={1}
          onClick={() => {
            !isEmpty &&
              setState({
                [stateKey]: new Set(),
              });
          }}
        >
          <FormattedMessage id="global.none" />
        </Text>
      </HStack>
      <Grid templateColumns="repeat(3, 1fr)" gap={0} width="100%">
        {[...defaultFilters].map((item, index) => {
          const rowNumber = Math.floor(index / 3) + 1;
          const isLastRow = rowNumber === nbOfRow;
          const marginLeft = `-${(index % 3) * 5}px`;
          const marginTop = `-${Math.floor(index / 3) * 2 - (isLastRow ? 2 : 0)}px`;

          return (
            <Center
              width="85px"
              height="44px"
              border="2px solid"
              borderColor="cyber.500"
              marginLeft={marginLeft}
              marginTop={marginTop}
              key={item}
              backgroundColor={values.has(item) ? 'cyber.600' : 'neutral.800'}
              cursor="pointer"
              onClick={() => {
                const newSet = new Set(values);
                newSet.has(item) ? newSet.delete(item) : newSet.add(item);
                setState({
                  [stateKey]: newSet,
                });
              }}
            >
              <Text>{item}</Text>
            </Center>
          );
        })}
      </Grid>
    </VStack>
  );
}

export default CategoryFilter;
