import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSensorAndSubSensorStatuses } from '@redux/situation/monitoring.selectors';
import { getSectionStatus } from '@utils/map/automatonSection.utils';
import {
  CriticalityCategory,
  criticalityOrder,
  sensorCategoryFromSensorStatus,
} from '@utils/sensors/configuration.constants';
import { groupBy } from 'lodash';
import { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import { SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { AutomatonSegmentStatus, SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import { configurationWithSensorUniqueCodes, SortEnum } from './MonitoringList';
import SensorCard from './SensorCard';

type Props = {
  filteredConfigurations: configurationWithSensorUniqueCodes[];
  setSelectedSensorCode: Dispatch<SensorUniqueCodes>;
};

function CriticalityList({ filteredConfigurations, setSelectedSensorCode }: Readonly<Props>) {
  const sensorStatuses = useAppSelector(selectSensorAndSubSensorStatuses);
  const groupedByStatusCriticality = groupBy(filteredConfigurations, (sensor) => {
    if (!sensor.configuration.active) {
      return CriticalityCategory.SLEEP_INACTIVE;
    } else if (sensor.configuration.maintenance) {
      return CriticalityCategory.MAINTENANCE;
    } else {
      const statusInfo = sensorStatuses.find(
        (status) => status.uniqueCode === sensor.sensorUniqueCodes.sensorUniqueCode,
      );
      if (!statusInfo) {
        return CriticalityCategory.DEGRADED;
      }
      const status =
        sensor.configuration.type === SubSensorTypeEnum.AUTOMATON_SEGMENT
          ? getSectionStatus(
              Object.values(statusInfo.status as AutomatonSegmentStatus)
                .filter(Boolean)
                .map((segment) => segment.status),
            )
          : (statusInfo.status as SubSensorStatus | SensorStatus).status;
      return sensorCategoryFromSensorStatus[status];
    }
  });

  return (
    <VStack width="100%" alignItems="start" gap={2}>
      {Object.entries(groupedByStatusCriticality)
        .sort(
          ([key], [key2]) =>
            criticalityOrder.indexOf(key as CriticalityCategory) -
            criticalityOrder.indexOf(key2 as CriticalityCategory),
        )
        .map(([category, sensors], index) => (
          <VStack
            width="100%"
            height="100%"
            alignItems="start"
            gap={2}
            paddingBottom="18px"
            key={category}
            marginBottom={index === Object.entries(groupedByStatusCriticality).length - 1 ? 6 : 0}
          >
            <Text fontWeight="medium" color="neutral.300">
              <FormattedMessage id={`components.monitoring.criticality.${category}`} />
            </Text>
            <HStack gap={2} flexWrap="wrap">
              {sensors.map((sensor) => (
                <SensorCard
                  sensorConfiguration={sensor.configuration}
                  type={SortEnum.CRITICALITY}
                  key={sensor.sensorUniqueCodes.sensorUniqueCode}
                  sensorUniqueCodes={sensor.sensorUniqueCodes}
                  onClick={
                    sensor.configuration.type !== SubSensorTypeEnum.AUTOMATON_SEGMENT
                      ? () => setSelectedSensorCode(sensor.sensorUniqueCodes)
                      : undefined
                  }
                />
              ))}
            </HStack>
          </VStack>
        ))}
    </VStack>
  );
}

export default CriticalityList;
