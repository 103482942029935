import { ReactComponent as BoussoleNordIcon } from '@assets/icons/40x40/mapMenu/ic-boussoleNord-40.svg';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import { useMapContext } from '@components/map/MapContext';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateBearingByMapId } from '@redux/maps/maps.reducer';
import { memo } from 'react';
import { useIntl } from 'react-intl';

function NavigationControl() {
  const { mapId } = useMapContext();
  const updateBearing = useWithDispatch(updateBearingByMapId);
  const { formatMessage } = useIntl();

  return (
    <ButtonBar
      label={formatMessage({ id: 'controls.toolMenu.north' })}
      tooltipPlacement="left"
      icon={BoussoleNordIcon}
      orientation="bottom-left"
      onClick={() => updateBearing({ mapId: mapId, bearing: 0 })}
    />
  );
}

export default memo(NavigationControl);
