import { ReactComponent as PerimeterAlertIcon } from '@assets/icons/54x54/sensor/icSymb-tro-or-M.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { AlertLevelEnum, SegmentFailureAlert } from '@/types/data/data.types';

import AlertTimeToast from './AlertTimeToast';

interface OwnProps {
  alert: SegmentFailureAlert;
  currentDate: Date;
}

export default function SegmentFailureAlertToast({ alert, currentDate }: Readonly<OwnProps>) {
  return (
    <>
      <HStack
        gap={2}
        width="100%"
        padding={2}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 4 : 3}
        backgroundColor="neutral.white"
      >
        <Icon as={PerimeterAlertIcon} boxSize="54px" />
        <VStack alignItems="start" gap={0.5}>
          <Text fontSize="md" color="neutral.black">
            <FormattedMessage id="notification.segmentFailed" />
          </Text>
          <AlertTimeToast startTime={alert.startTime} endTime={alert.endTime} currentDate={currentDate} />
        </VStack>
      </HStack>
      <VStack
        width="100%"
        backgroundColor="neutral.100"
        alignItems="start"
        paddingY={2}
        paddingRight={3}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 5 : 4}
      >
        <Text fontSize="12px" fontWeight="medium" color="neutral.600">
          {alert.identifier}
        </Text>
        <Text fontSize="12px" fontWeight="medium" color="neutral.600">
          {alert.cabinetName}
        </Text>
        <Text fontSize="12px" fontWeight="medium" color="neutral.600">
          {alert.segmentName}
        </Text>
      </VStack>
    </>
  );
}
