import { normalize, schema } from 'normalizr';

export function codeExtractor<T extends { code: string }>(object: T): string {
  return object.code;
}

export function idExtractor<T extends { id: number }>(object: T): string {
  return object.id.toString();
}

export function normalizeOne<T>(value: T, schema: schema.Entity<T>): unknown | undefined {
  return normalize(value, schema).entities[schema.key]?.[schema.getId(value, null, '')];
}
