import { useEffect, useState } from 'react';

function useCurrentDateTime() {
  const [currentDateTime, setCurrentDateTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setCurrentDateTime]);

  return currentDateTime;
}

export default useCurrentDateTime;
