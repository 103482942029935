import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { selectTargetFeatures } from '@redux/situation/situation.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import { targetLayer, targetLinkLayer, targetTrajectory } from './target.layer';

function TargetLayer() {
  const targetsFeatures = useSelectorWithMapId(selectTargetFeatures);

  if (!isFeatureCollection(targetsFeatures)) return null;

  return (
    <Source id={SourceNameEnum.TARGETS} type="geojson" data={targetsFeatures} generateId>
      <Layer key={LayerNameEnum.TARGET_LINKS} {...targetLinkLayer} />
      <Layer key={LayerNameEnum.TARGET_TRAJECTORIES} {...targetTrajectory} />
      <Layer key={LayerNameEnum.TARGETS} {...targetLayer} />
    </Source>
  );
}

export default memo(TargetLayer);
