import { HStack } from '@chakra-ui/layout';
import { Tag, TagLabel } from '@chakra-ui/react';
import {
  ALL_OPTION,
  areOptionsEqualByValue,
  CustomOption,
  isOptionInListByValue,
  removeOptionFromListByValue,
} from '@utils/components/customChipList.utils';
import { memo } from 'react';
import { useIntl } from 'react-intl';

interface CustomChipListProps {
  availableOptions: CustomOption[];
  selectedOptions?: CustomOption[];
  displayOptionAll?: boolean;
  path?: string;
  isDisabled?: boolean;
  onChange?: (value: string[]) => void;
  distinctValues?: string[];
  size?: 'md' | 'sm';
  variant?: 'filled';
}

const CustomChipList = ({
  availableOptions,
  displayOptionAll = false,
  selectedOptions = [],
  path,
  isDisabled = false,
  onChange,
  distinctValues,
  size = 'sm',
  variant,
}: Readonly<CustomChipListProps>) => {
  const { formatMessage } = useIntl();
  const all = ALL_OPTION;
  type optionType = CustomOption | typeof all;
  const optionList: optionType[] = displayOptionAll ? [all, ...availableOptions] : availableOptions;

  function uncheckOptionAll(selectedOptionsList: CustomOption[]) {
    if (displayOptionAll && isOptionInListByValue(all, selectedOptionsList)) {
      selectedOptionsList = removeOptionFromListByValue(all, selectedOptionsList);
    }
    return selectedOptionsList;
  }

  const handleTagClick = (clickedOption: optionType) => {
    let newSelectedOptions: optionType[];
    if (displayOptionAll && areOptionsEqualByValue(clickedOption, all)) {
      //Si on clique sur le bouton all
      newSelectedOptions = [all];
    } else if (isOptionInListByValue(clickedOption, selectedOptions)) {
      //Si la case était déjà cochée
      newSelectedOptions = removeOptionFromListByValue(clickedOption, selectedOptions);
      newSelectedOptions = newSelectedOptions.length === 0 && displayOptionAll ? [all] : newSelectedOptions;
    } else {
      // Si la case n'était pas cochée
      newSelectedOptions = uncheckOptionAll(selectedOptions);
      newSelectedOptions.push(clickedOption);
    }

    if (distinctValues && distinctValues.includes(clickedOption.value)) {
      // remove other unique options
      newSelectedOptions = [clickedOption, ...selectedOptions.filter((o) => !distinctValues.includes(o.value))];
      newSelectedOptions = uncheckOptionAll(newSelectedOptions);
    }
    onChange?.(newSelectedOptions.map((option) => option.value));
  };

  const getFilterLabel = (option: CustomOption) => {
    if (areOptionsEqualByValue(option, all)) {
      return formatMessage({ id: `filters.all` });
    } else if (path) {
      return formatMessage({ id: `${path}.${option.label}` });
    } else {
      return option.label.replace('_', ' ');
    }
  };

  const backgroundColor = variant === 'filled' ? 'cyber.700' : 'transparent';
  const marginLeft = size === 'sm' ? '-1px' : '-2px';

  const { normalOptions, distinctOptions } = Object.values(optionList).reduce(
    ({ normalOptions, distinctOptions }, curr) => {
      if (distinctValues?.includes(curr.value)) {
        return { normalOptions, distinctOptions: distinctOptions.concat(curr) };
      } else {
        return { distinctOptions, normalOptions: normalOptions.concat(curr) };
      }
    },
    {
      normalOptions: [] as CustomOption[],
      distinctOptions: [] as CustomOption[],
    },
  );

  function displayTag(option: CustomOption, isDistinct = false, index?: number) {
    return (
      <Tag
        onClick={() => !isDisabled && handleTagClick(option)}
        key={option.value}
        cursor={
          isDisabled || (selectedOptions.some((selectedOption) => selectedOption.value === option.value) && isDistinct)
            ? 'default'
            : 'pointer'
        }
        borderRadius="none"
        userSelect="none"
        backgroundColor={isOptionInListByValue(option, selectedOptions) && !isDisabled ? 'cyber.600' : backgroundColor}
        borderColor={isDisabled ? 'neutral.600' : 'cyber.500'}
        marginLeft={isDistinct && index !== 0 ? marginLeft : 0}
        gap={1}
        size={size}
      >
        <TagLabel
          fontFamily="roboto"
          fontWeight="regular"
          fontSize="14px"
          color={isDisabled ? 'neutral.500' : 'neutral.white'}
          textTransform="capitalize"
          gap={1}
        >
          {getFilterLabel(option)}
        </TagLabel>
        {option.count !== undefined && (
          <TagLabel
            fontFamily="roboto"
            fontWeight="regular"
            fontSize="14px"
            color={isDisabled ? 'neutral.500' : 'neutral.white'}
            textTransform="capitalize"
          >
            {`(${option.count})`}
          </TagLabel>
        )}
      </Tag>
    );
  }

  return (
    <HStack columnGap={3} rowGap={1} flexWrap="wrap">
      {normalOptions.length > 0 && (
        <HStack gap={1} flexWrap="wrap">
          {normalOptions.map((tag, index) => displayTag(tag, false, index))}
        </HStack>
      )}
      {distinctOptions.length > 0 && (
        <HStack gap={0}>{distinctOptions.map((tag, index) => displayTag(tag, true, index))}</HStack>
      )}
    </HStack>
  );
};

export default memo(CustomChipList);
