import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapFiltersAllChecked } from '@utils/map/filters/filters.constants';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { UserSettings } from '@/types/config/settings.types';

export type SettingsState = UserSettings;

export const SETTINGS_INITIAL_STATE: SettingsState = {
  id: NaN,
  language: 'fr',

  mapBackground: 'satellite',
  coordinatesUnit: CoordinatesUnitEnum.DECIMAL_DEGREES,
  context: ContextEnum.LIVE,
  axisInvertedY: false,
  linearJoystickCoefficient: 0.4,
  zoomJoystickCameraSensitivity: 1,
  automatedLad: false,
  mapFilters: mapFiltersAllChecked,

  createdBy: null,
  creationTime: null,
  modifiedBy: null,
  modificationTime: null,
};

const settings = createSlice({
  name: 'settings',
  initialState: SETTINGS_INITIAL_STATE,
  reducers: {
    updateSettings(_, action: PayloadAction<SettingsState>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => SETTINGS_INITIAL_STATE);
  },
});

export const { updateSettings } = settings.actions;
export default settings.reducer;
