import { appIntl } from '@components/locale/IntlGlobalProvider';
import {
  addDays,
  addHours,
  addMinutes,
  addWeeks,
  formatDuration as formatDurationFns,
  intervalToDuration,
  subDays,
  subHours,
  subMinutes,
  subWeeks,
} from 'date-fns';
import format from 'date-fns/format';

import { AnyDate, DateTimeEnum, TimeReference } from '@/types/dateTime.types';
import { ReplayTimeScaleEnum, ReplayTimeScaleToTimelineTimeAxis } from '@/types/replay/replay.types';

export function timeDiff(date1: AnyDate, date2?: AnyDate | null): number {
  const d1 = new Date(date1).getTime();
  const d2 = date2 ? new Date(date2).getTime() : Date.now();
  return d1 - d2;
}

export function timeDuration(date1: AnyDate, date2?: AnyDate | null): number {
  return Math.abs(timeDiff(date1, date2));
}

export function compareDay(date1: AnyDate, date2: AnyDate): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDay() === d2.getDay();
}

export function sortDateDesc(date1: AnyDate, date2: AnyDate): number {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  return d2 - d1;
}

export function millisToDateTimeString(millis: number): string {
  const day = Math.floor(millis / (1000 * 60 * 60 * 24));
  const hr = Math.floor(millis / (1000 * 60 * 60)) % 24;
  const min = Math.floor(millis / (1000 * 60)) % 60;
  const sec = Math.floor(millis / 1000) % 60;

  const days = day < 10 ? `0${day}` : day;
  const hours = hr < 10 ? `0${hr}` : hr;
  const minutes = min < 10 ? `0${min}` : min;
  const seconds = sec < 10 ? `0${sec}` : sec;

  return `${days}:${hours}:${minutes}:${seconds}`;
}

export function minutesToTime(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return [hours, remainingMinutes];
}

export function formatTimeTwoDigits(value: string) {
  return value.length === 1 ? `0${value}` : value;
}

export function getFormat(type: DateTimeEnum) {
  switch (type) {
    case DateTimeEnum.DATE_TIME_SECONDS:
      return "dd/MM/yyyy - HH'h'mm'm'ss";
    case DateTimeEnum.DATE_TIME:
      return "dd/MM/yyyy - HH'h'mm";
    case DateTimeEnum.TIME_DATE:
      return 'HH:mm:ss - dd/MM/yyyy';
    case DateTimeEnum.TIME:
      return 'HH:mm';
    case DateTimeEnum.DATE_TIMELINE:
      return 'DD/MM';
    case DateTimeEnum.DATE_TIME_SECONDS_ALTERNATE:
      return "yyyy-MM-dd_HH'h'mm'm'ss";
    case DateTimeEnum.DATE_TIME_SECONDS_PERIM_FORMAT:
      return "yyyy-MM-dd'T'HH:mm:ss";
    case DateTimeEnum.DATE:
    default:
      return 'dd/MM/yyyy';
  }
}

export function formatDate(date: AnyDate, type: DateTimeEnum) {
  return format(new Date(date), getFormat(type));
}

export function formatDuration(
  start: AnyDate,
  end: AnyDate,
  format: TimeReference[] = ['days', 'hours', 'minutes', 'seconds'],
) {
  const formatDistance = {
    xDays: appIntl().formatMessage({ id: 'common.format.xDays' }),
    xHours: appIntl().formatMessage({ id: 'common.format.xHours' }),
    xMinutes: appIntl().formatMessage({ id: 'common.format.xMinutes' }),
    xSeconds: appIntl().formatMessage({ id: 'common.format.xSeconds' }),
  };

  if (timeDuration(start, end) < 1000) {
    return '0 s';
  }

  return formatDurationFns(intervalToDuration({ start: new Date(start), end: new Date(end) }), {
    format,
    locale: {
      formatDistance: (token: keyof typeof formatDistance, count: string) =>
        formatDistance[token].replace('{{count}}', count),
    },
  });
}

export function customAddSubForDate(date: Date, action: 'add' | 'sub', timeScale: ReplayTimeScaleEnum, factor: number) {
  const { scale, step } = ReplayTimeScaleToTimelineTimeAxis[timeScale];
  const amount = step * factor;
  let addSubFunction: (date: Date, amount: number) => Date;
  switch (scale) {
    case 'minute':
      addSubFunction = action === 'add' ? addMinutes : subMinutes;
      break;
    case 'hour':
      addSubFunction = action === 'add' ? addHours : subHours;
      break;
    case 'day':
      addSubFunction = action === 'add' ? addDays : subDays;
      break;
    case 'week':
      addSubFunction = action === 'add' ? addWeeks : subWeeks;
      break;
  }
  return addSubFunction(date, amount);
}

export function convertDuration(duration: number, srcReference: TimeReference, targetReference: TimeReference) {
  const getTimeFactorToSeconds = (reference: TimeReference) => {
    switch (reference) {
      case 'days':
        return 60 * 60 * 24;
      case 'hours': {
        return 60 * 60;
      }
      case 'minutes': {
        return 60;
      }
      case 'seconds': {
        return 1;
      }
    }
  };

  const durationInSeconds = Math.floor(duration * getTimeFactorToSeconds(srcReference));
  return Math.round((durationInSeconds / getTimeFactorToSeconds(targetReference)) * 100) / 100;
}
