import { Text } from '@chakra-ui/react';
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl';

interface FormattedDateRangeProps {
  startDate: Date;
  endDate: Date;
}

export default function FormattedDateRange({ startDate, endDate }: Readonly<FormattedDateRangeProps>) {
  return (
    <FormattedDateTimeRange from={startDate} to={endDate}>
      {(formattedDateRanges: string) => {
        const dates = formattedDateRanges.split('–');
        return (
          <Text size="lg">
            <FormattedMessage id="global.from" /> {dates[0]} <FormattedMessage id="global.to" /> {dates[1]}
          </Text>
        );
      }}
    </FormattedDateTimeRange>
  );
}
