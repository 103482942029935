import { isSensorWithSubSensors } from '@utils/map/sensor.utils';
import { isValidCoordinates } from '@utils/validation/coordinates.utils';

import { SensorUniqueCodes, SubSensorInfo } from '@/types/map.types';
import {
  ArdronisAntenna,
  AutomatonCabinet,
  AutomatonSection,
  AutomatonSegment,
  AutomatonSegmentFenceTypeEnum,
  EquipmentConfiguration,
  GetSensorConfiguration,
  GetSubSensorConfiguration,
  PositionSubSensor,
  SenidAntenna,
  SensorConfiguration,
  SENSORS_WITH_SUBSENSOR,
  SensorTypeEnum,
  SensorWithSubSensor,
  SkyEyeAntenna,
  SpexerRadar,
  SubSensorConfiguration,
  SubSensorTypeEnum,
  UniqueKey,
} from '@/types/sensor/configuration.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import {
  AbstractSensorWithSubSensorsStatus,
  AutomatonCabinetStatus,
  AutomatonNodeStateEnum,
  AutomatonSegmentStatus,
  AutomatonSensorStatus,
  GetSensorStatus,
  GetSubSensorStatus,
  MonitoringEquipment,
  MonitoringSensor,
  SensorStatus,
  SensorStatusEnum,
  SubSensorStatus,
  SubSensorStatusTypeEnum,
  TechnicalDefectEnum,
} from '@/types/sensor/status.types';

import {
  LadSensorCategoryEnum,
  PerimSensorCategoryEnum,
  SensorColor,
  sensorColorFromSensorStatus,
} from './configuration.constants';

export function isSensorStatus<SensorType extends SensorTypeEnum>(
  monitoringSensor: MonitoringSensor<SensorType>,
): monitoringSensor is GetSensorStatus<SensorType> {
  return 'status' in monitoringSensor;
}

export function isAutomaton(
  monitoringSensor: MonitoringSensor,
): monitoringSensor is MonitoringSensor<SensorTypeEnum.AUTOMATON> {
  return monitoringSensor.configuration.type === SensorTypeEnum.AUTOMATON;
}

export function isActiveAutomaton(monitoringSensor: MonitoringSensor): monitoringSensor is AutomatonSensorStatus {
  return isSensorStatus(monitoringSensor) && isAutomaton(monitoringSensor);
}

export function isMonitoringSensor(
  monitoringSensor:
    | SensorConfiguration
    | PerimeterCamera
    | EquipmentConfiguration
    | PositionSubSensor
    | SubSensorStatus
    | SubSensorConfiguration
    | AutomatonSegment
    | MonitoringSensor
    | MonitoringEquipment,
): monitoringSensor is MonitoringSensor | MonitoringEquipment {
  return 'configuration' in monitoringSensor;
}

export function getSensorUniqueCode(
  sensor:
    | SensorConfiguration
    | PerimeterCamera
    | EquipmentConfiguration
    | PositionSubSensor
    | SubSensorStatus
    | SubSensorConfiguration
    | AutomatonSegment
    | MonitoringSensor
    | MonitoringEquipment,
): string {
  return isMonitoringSensor(sensor)
    ? `${sensor.configuration.appCode}_${sensor.configuration.code}`
    : `${sensor.appCode}_${sensor.code}`;
}

export function getPerimeterCameraUniqueKey(perimeterCamera: PerimeterCamera): UniqueKey {
  return { appCode: perimeterCamera.appCode, code: perimeterCamera.code, site: perimeterCamera.site };
}

export function getSubSensorStatusIfExists<SensorType extends SensorWithSubSensor>(
  parent: MonitoringSensor<SensorType>,
  subSensor: GetSubSensorConfiguration<SensorType>,
) {
  if (isSensorStatus(parent) && subSensor.active) {
    return parent.subSensorStatuses[subSensor.subSensorId];
  }
}

export function getSensorSubSensorStatusLabelId(status: SensorStatusEnum | null, maintenance: boolean) {
  if (!status) {
    return 'sensors.inactive';
  }
  if (maintenance) {
    return 'sensors.maintenance.title';
  }
  return `sensors.status.${status}`;
}

export function isNotInMaintenance(config: { maintenance: boolean }) {
  return !config.maintenance;
}

export function isRadar(sensor: SensorConfiguration) {
  return sensor.type === SensorTypeEnum.SPEXER || sensor.type === SensorTypeEnum.XENTA;
}

export function getSubSensorStatusesFromParent(sensorStatus: SensorStatus): SubSensorStatus[] {
  if ((SENSORS_WITH_SUBSENSOR as readonly SensorTypeEnum[]).includes(sensorStatus.type)) {
    return Object.values(
      (sensorStatus as AbstractSensorWithSubSensorsStatus<SensorConfiguration>).subSensorStatuses,
    ) as SubSensorStatus[];
  }
  return [];
}

export function getSubSensorConfigsFromParent<Config extends GetSensorConfiguration<SensorWithSubSensor>>(
  parentConfiguration: Config,
): GetSubSensorConfiguration<Config['type']>[] {
  switch (parentConfiguration.type) {
    case SensorTypeEnum.ARDRONIS:
      return parentConfiguration.antennas.map(
        (a) => ({ ...a, type: SubSensorTypeEnum.ARDRONIS_ANTENNA }) as ArdronisAntenna,
      ) as GetSubSensorConfiguration<Config['type']>[];
    case SensorTypeEnum.AUTOMATON:
      return parentConfiguration.cabinets.map(
        (c) => ({ ...c, type: SubSensorTypeEnum.AUTOMATON_CABINET }) as AutomatonCabinet,
      ) as GetSubSensorConfiguration<Config['type']>[];
    case SensorTypeEnum.SENID:
      return parentConfiguration.antennas.map(
        (a) => ({ ...a, type: SubSensorTypeEnum.SENID_ANTENNA }) as SenidAntenna,
      ) as GetSubSensorConfiguration<Config['type']>[];
    case SensorTypeEnum.SKY_EYE:
      return parentConfiguration.antennas.map(
        (a) => ({ ...a, type: SubSensorTypeEnum.SKY_EYE_ANTENNA }) as SkyEyeAntenna,
      ) as GetSubSensorConfiguration<Config['type']>[];
    case SensorTypeEnum.SPEXER:
      return parentConfiguration.radars.map(
        (r) => ({ ...r, type: SubSensorTypeEnum.SPEXER_RADAR }) as SpexerRadar,
      ) as GetSubSensorConfiguration<Config['type']>[];
    default:
      return [] as never;
  }
}

export function getSubsensorInfos<Sensor extends MonitoringSensor<SensorWithSubSensor>>(
  parentMonitoringSensor: Sensor,
): SubSensorInfo<Sensor['configuration']['type']>[] {
  const parentSensorConfig = parentMonitoringSensor.configuration;
  return getSubSensorConfigsFromParent(parentSensorConfig).map((subSensorConfig) => {
    const hasSubSensorStatus = isSensorStatus(parentMonitoringSensor) && subSensorConfig.active;
    const subSensorStatus = hasSubSensorStatus
      ? parentMonitoringSensor.subSensorStatuses[subSensorConfig.subSensorId]
      : null;
    return {
      parentSensorConfig,
      subSensorConfig,
      subSensorStatus,
    } as SubSensorInfo<Sensor['configuration']['type']>;
  });
}

export function hasSubSensorsWithCoordinates(sensorConfiguration: SensorConfiguration) {
  return (
    ((sensorConfiguration.type === SensorTypeEnum.ARDRONIS ||
      sensorConfiguration.type === SensorTypeEnum.SENID ||
      sensorConfiguration.type === SensorTypeEnum.SKY_EYE) &&
      sensorConfiguration.antennas.some((a) => isValidCoordinates(a.position))) ||
    (sensorConfiguration.type === SensorTypeEnum.SPEXER &&
      sensorConfiguration.radars.some((a) => isValidCoordinates(a.position)))
  );
}

export function isHostConfiguration(sensor: SensorConfiguration) {
  return 'host' in sensor;
}

export function isPortConfiguration(sensor: SensorConfiguration) {
  return 'port' in sensor;
}

export function isSubSensorUniqueCodes(uniqueCodes: SensorUniqueCodes) {
  return 'parentUniqueCode' in uniqueCodes;
}

export function isSegmentRepulsionOn(segmentStatus: AutomatonSegmentStatus) {
  return (
    (!!segmentStatus.lowerFenceStatusAndState &&
      !segmentStatus.lowerFenceStatusAndState.states[AutomatonNodeStateEnum.BT_DETECTION]) ||
    (!!segmentStatus.upperFenceStatusAndState &&
      !segmentStatus.upperFenceStatusAndState.states[AutomatonNodeStateEnum.BT_DETECTION])
  );
}

export function getCabinetStatuses(automatonStatus: AutomatonSensorStatus | null): AutomatonCabinetStatus[] {
  return !!automatonStatus
    ? Object.values(automatonStatus.subSensorStatuses)
        .filter(Boolean)
        .map((cabinetStatus) => cabinetStatus as AutomatonCabinetStatus)
    : [];
}

export function getCabinetSegmentStatuses(cabinetStatus: AutomatonCabinetStatus | null): AutomatonSegmentStatus[] {
  if (!cabinetStatus) return [];
  return Object.values(cabinetStatus.segmentStatuses)
    .filter(Boolean)
    .map((segmentStatus) => segmentStatus as AutomatonSegmentStatus)
    .filter(
      (segmentStatus) =>
        segmentStatus.lowerFenceStatusAndState?.status !== SensorStatusEnum.SLEEP ||
        segmentStatus.upperFenceStatusAndState?.status !== SensorStatusEnum.SLEEP,
    );
}

export function getAutomatonSegmentStatuses(automatonStatus: AutomatonSensorStatus | null) {
  const cabinetStatuses: AutomatonCabinetStatus[] = getCabinetStatuses(automatonStatus);
  return cabinetStatuses.flatMap((cabinetStatus) => getCabinetSegmentStatuses(cabinetStatus));
}

export type DefenceState = 'detection' | 'repulsion' | 'none';

export function getDefenceState(segmentStatuses: AutomatonSegmentStatus[]): DefenceState {
  if (!segmentStatuses.length) {
    return 'none';
  }
  if (segmentStatuses.every((segmentStatus) => !isSegmentRepulsionOn(segmentStatus))) {
    return 'detection';
  }
  if (segmentStatuses.every((segmentStatus) => isSegmentRepulsionOn(segmentStatus))) {
    return 'repulsion';
  }
  return 'none';
}

export type PowerState = 'asleep' | 'awake' | 'none';

export function getPowerState(cabinetStatuses: AutomatonCabinetStatus[]): PowerState {
  if (!cabinetStatuses.length) {
    return 'none';
  }
  if (cabinetStatuses.every((cabinetStatus) => cabinetStatus.status === SensorStatusEnum.SLEEP)) {
    return 'asleep';
  }
  if (cabinetStatuses.every((cabinetStatus) => cabinetStatus.status !== SensorStatusEnum.SLEEP)) {
    return 'awake';
  }
  return 'none';
}

function checkAutomatonAllCabinetsSleepStatus(automatonStatus: AutomatonSensorStatus, isAsleep: boolean): boolean {
  const cabinetsStatuses = getCabinetStatuses(automatonStatus);

  return cabinetsStatuses.some((cabinetsStatus) => isAsleep === (cabinetsStatus.status === SensorStatusEnum.SLEEP));
}

export function automatonHasOneCabinetPoweredOff(automatonStatus: AutomatonSensorStatus): boolean {
  return checkAutomatonAllCabinetsSleepStatus(automatonStatus, true);
}

export function automatonHasOneCabinetPoweredOn(automatonStatus: AutomatonSensorStatus): boolean {
  return checkAutomatonAllCabinetsSleepStatus(automatonStatus, false);
}

export function toAutomatonSection(segments: AutomatonSegment[]) {
  const lowerSegment = segments.find((s) => s.fenceType === AutomatonSegmentFenceTypeEnum.LOWER);
  const upperSegment = segments.find((s) => s.fenceType === AutomatonSegmentFenceTypeEnum.UPPER);

  let section: AutomatonSection;
  if (lowerSegment && upperSegment) {
    section = { lowerSegment, upperSegment };
  } else if (lowerSegment) {
    section = { lowerSegment };
  } else {
    section = { upperSegment: upperSegment as AutomatonSegment };
  }
  return section;
}

export function isCircuitBreakerEnabled(status: AutomatonCabinetStatus) {
  return (
    status.cabinetStates.TECHNICAL_DEFECT?.some((defect) => defect === TechnicalDefectEnum.CIRCUIT_BREAKER) ?? false
  );
}

export function isEmergencyStopEnabled(status: AutomatonCabinetStatus) {
  return (
    status.cabinetStates.TECHNICAL_DEFECT?.some((defect) => defect === TechnicalDefectEnum.EMERGENCY_STOP) ?? false
  );
}

export function getSensorColor(
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration | AutomatonSegment,
  status?: SensorStatusEnum | null,
): SensorColor {
  if (!sensorConfiguration.active) {
    return SensorColor.WHITE;
  } else if (sensorConfiguration.maintenance) {
    return SensorColor.ORANGE;
  } else if (status) {
    return sensorColorFromSensorStatus[status];
  } else return SensorColor.PURPLE;
}

export function isLadCategory(
  category: LadSensorCategoryEnum | PerimSensorCategoryEnum,
): category is LadSensorCategoryEnum {
  return Object.values(LadSensorCategoryEnum).includes(category as LadSensorCategoryEnum);
}

export function isPerimCategory(
  category: LadSensorCategoryEnum | PerimSensorCategoryEnum,
): category is PerimSensorCategoryEnum {
  return Object.values(PerimSensorCategoryEnum).includes(category as PerimSensorCategoryEnum);
}

export function isCabinetConfiguration(
  sensor: SubSensorConfiguration,
): sensor is GetSubSensorConfiguration<SensorTypeEnum.AUTOMATON> {
  return sensor.type === SubSensorTypeEnum.AUTOMATON_CABINET;
}

export function isCabinetStatus(status: SubSensorStatus): status is GetSubSensorStatus<SensorTypeEnum.AUTOMATON> {
  return status.type === SubSensorStatusTypeEnum.AUTOMATON_CABINET;
}

export function getSectionUniqueCode(status: AutomatonSegmentStatus): string {
  if (status.upperFenceStatusAndState) {
    return `${status.upperFenceStatusAndState.appCode}_${status.upperFenceStatusAndState.code.split('-').slice(0, -1).join('-')}`;
  }
  if (status.lowerFenceStatusAndState) {
    return `${status.lowerFenceStatusAndState.appCode}_${status.lowerFenceStatusAndState.code.split('-').slice(0, -1).join('-')}`;
  }
  return '';
}

export function getSubSensorAndSensorConfiguration(configuration: SensorConfiguration): {
  configuration: SensorConfiguration | SubSensorConfiguration | AutomatonSegment;
  sensorUniqueCodes: SensorUniqueCodes;
}[] {
  const config = {
    configuration,
    sensorUniqueCodes: {
      sensorUniqueCode: getSensorUniqueCode(configuration),
    },
  };
  if (isSensorWithSubSensors(configuration)) {
    return [
      config,
      ...getSubSensorConfigsFromParent(configuration).flatMap((subSensor) => {
        const subConfig = {
          configuration: subSensor,
          sensorUniqueCodes: {
            sensorUniqueCode: getSensorUniqueCode(subSensor),
            parentUniqueCode: getSensorUniqueCode(configuration),
          },
        };
        if (isCabinetConfiguration(subSensor)) {
          return [
            subConfig,
            ...subSensor.segments.map((segmentConfig) => ({
              configuration: { ...segmentConfig, type: SubSensorTypeEnum.AUTOMATON_SEGMENT } as AutomatonSegment,
              sensorUniqueCodes: {
                sensorUniqueCode: getSensorUniqueCode(segmentConfig),
                parentUniqueCode: getSensorUniqueCode(subSensor),
              },
            })),
          ];
        }
        return [subConfig];
      }),
    ];
  }
  return [config];
}
