import { ReactComponent as ServerIcon } from '@assets/icons/24x24/ic-server-24.svg';
import { ReactComponent as SoftwareIcon } from '@assets/icons/40x40/ic-software-40.svg';
import DataSheetCard from '@components/common/layout/DataSheetCard';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole } from '@redux/authent/authent.selectors';
import { usePostHolodroneCommandMutation } from '@services/c2/c2.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { HolodroneCommandEnum } from '@/types/c2/c2.types';
import { HolodroneConfiguration } from '@/types/sensor/configuration.types';

import { MaintenanceVariant } from './SpecificMaintenance';

type Props = {
  holodroneConfiguration: HolodroneConfiguration;
  variant: MaintenanceVariant;
};

export default function HolodroneMaintenanceActions({ holodroneConfiguration, variant }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const canDoCommands = useAppSelector((state) => hasRole(state, RoleEnum.SENSOR_COMMAND));

  const [postHolodroneCommand] = usePostHolodroneCommandMutation();
  const [loadingButton, setLoadingButton] = useState<string | null>(null); // État pour suivre quel bouton est en cours de chargement

  if (!canDoCommands) {
    return null;
  }
  const site = holodroneConfiguration.site;
  const appCode = holodroneConfiguration.appCode;
  const sensorCode = holodroneConfiguration.code;

  const handleCommand = async (commandType: HolodroneCommandEnum) => {
    setLoadingButton(commandType);
    postHolodroneCommand({
      site,
      appCode,
      command: { type: commandType, sensorCode },
    })
      .unwrap()
      .catch(() =>
        createToast(
          formatMessage({ id: 'sensors.maintenance.holodrone.error' }, { name: holodroneConfiguration.name }),
          ToastStatusEnum.ERROR,
        ),
      )
      .then(() => setLoadingButton(null));
  };

  return (
    <>
      <DataSheetCard
        variant={variant}
        icon={ServerIcon}
        description={formatMessage({ id: 'sensors.maintenance.holodrone.rebootDescription' })}
        buttonLabel={formatMessage({ id: 'sensors.maintenance.holodrone.reboot' })}
        isLoading={loadingButton === HolodroneCommandEnum.RebootHolodrone}
        isDisabled={loadingButton !== null}
        onClick={() => handleCommand(HolodroneCommandEnum.RebootHolodrone)}
      />
      <DataSheetCard
        variant={variant}
        icon={SoftwareIcon}
        description={formatMessage({ id: 'sensors.maintenance.holodrone.restartDescription' })}
        buttonLabel={formatMessage({ id: 'sensors.maintenance.holodrone.restart' })}
        isLoading={loadingButton === HolodroneCommandEnum.RestartHolodrone}
        isDisabled={loadingButton !== null}
        onClick={() => handleCommand(HolodroneCommandEnum.RestartHolodrone)}
      />
    </>
  );
}
