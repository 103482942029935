import { SensorIconKeys } from '@assets/icons/70x70/sensor';
import { IconProps } from '@chakra-ui/icon';
import { Center, VStack } from '@chakra-ui/layout';
import { Icon, Text } from '@chakra-ui/react';
import { useDimensions } from '@hooks/useDimensions';
import { getSensorIconByKey } from '@utils/map/sensor.utils';
import { memo } from 'react';

interface Props extends IconProps {
  sensorIconName: SensorIconKeys;
  size?: 'sm' | 'md' | 'lg';

  label?: string;
}

function getHeight(size: 'sm' | 'lg' | 'md'): string {
  switch (size) {
    case 'sm':
      return '48px';
    case 'md':
      return '54px';
    case 'lg':
      return '70px';
  }
}

function SensorIcon({ label, size = 'lg', sensorIconName }: Readonly<Props>) {
  const [dimensions, iconRef] = useDimensions();

  return (
    <VStack alignItems="center" position="relative" gap={0}>
      <Center ref={iconRef}>
        <Icon as={getSensorIconByKey(sensorIconName, size)} width="auto" height={getHeight(size)} />
      </Center>
      {label && (
        <Text
          backgroundColor="neutral.black"
          padding={0.25}
          width={dimensions?.width}
          textOverflow="ellipsis"
          wordBreak="break-all"
          noOfLines={1}
          textAlign="center"
        >
          {label}
        </Text>
      )}
    </VStack>
  );
}

export default memo(SensorIcon);
