import { imzMarginColor, nfzColor, PolygonWithOutlineColor, zoneColor } from '@utils/map/map.constants';
import { computeZoneMargin, getNoFlyZoneCriticality, getProjectionOf3dSolid } from '@utils/map/zone.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { Coordinates } from '@/types/commons/commons.types';
import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  Zone,
  ZoneCategoryEnum,
} from '@/types/config/config.types';
import { FilterTriggerEnum, filterTriggerToFilterKey, FilterTypeEnum, MapFilters } from '@/types/filters.types';
import { FeatureTypeEnum } from '@/types/map.types';

function toZoneFeature(
  zone: Zone,
  type: FeatureTypeEnum,
  color: PolygonWithOutlineColor,
  filterTrigger?: keyof MapFilters,
): Feature {
  const pointsList: Coordinates[] = 'solidPolygon' in zone ? getProjectionOf3dSolid(zone.solidPolygon) : zone.polygon;
  return {
    type: 'Feature',
    properties: {
      id: zone.id,
      featureType: type,
      active: zone.active,
      value: zone,
      fillColor: color.fill,
      outlineColor: color.outline,
      filterTrigger: filterTrigger,
    },
    geometry: {
      type: 'Polygon',
      coordinates: [pointsList.map((coordinates) => toLonLatArray(coordinates, false)!)],
    },
  } as Feature;
}

export const toNIZFeatures = (nizs: NoInitZone[]): Feature[] =>
  nizs.map((niz) =>
    toZoneFeature(
      niz,
      FeatureTypeEnum.NO_INIT_ZONE,
      zoneColor[ZoneCategoryEnum.NIZ]!,
      filterTriggerToFilterKey[FilterTypeEnum.ZONE][FeatureTypeEnum.NO_INIT_ZONE],
    ),
  );

export const toNFZFeatures = (nfzs: NoFlyZone[]): Feature[] =>
  nfzs
    .toSorted((nfz1, nfz2) => getNoFlyZoneCriticality(nfz1) - getNoFlyZoneCriticality(nfz2))
    .map((nfz) =>
      toZoneFeature(
        nfz,
        FeatureTypeEnum.NO_FLY_ZONE,
        nfzColor[nfz.level],
        filterTriggerToFilterKey[FilterTypeEnum.ZONE][nfz.level],
      ),
    );

export const toNFZ3dFeatures = (nfzs: NoFlyZone3d[]): Feature[] =>
  nfzs
    .toSorted((nfz1, nfz2) => getNoFlyZoneCriticality(nfz1) - getNoFlyZoneCriticality(nfz2))
    .map((nfz) =>
      toZoneFeature(
        nfz,
        FeatureTypeEnum.NO_FLY_ZONE_3D,
        nfzColor[nfz.level],
        filterTriggerToFilterKey[FilterTypeEnum.ZONE][nfz.level],
      ),
    );

export const toIMZFeatures = (imzs: InitializationMaskingZone[]): Feature[] =>
  imzs.map((imz) =>
    toZoneFeature(
      imz,
      FeatureTypeEnum.INITIALIZATION_MASKING_ZONE,
      zoneColor[ZoneCategoryEnum.IMZ]!,
      filterTriggerToFilterKey[FilterTypeEnum.ZONE][FilterTriggerEnum.ZONE_INIT_MASKING_ZONE],
    ),
  );

export const toIMZMarginFeatures = (imzs: InitializationMaskingZone[]): Feature[] =>
  imzs.map(
    (imz) =>
      ({
        type: 'Feature',
        properties: {
          id: imz.id,
          featureType: FeatureTypeEnum.INITIALIZATION_MASKING_ZONE_MARGIN,
          active: imz.active,
          value: imz,
          fillColor: imzMarginColor.fill,
          outlineColor: imzMarginColor.outline,
          filterTrigger: filterTriggerToFilterKey[FilterTypeEnum.ZONE][FilterTriggerEnum.ZONE_INIT_MASKING_ZONE],
        },
        geometry: {
          type: 'Polygon',
          coordinates: computeZoneMargin(imz),
        },
      }) as Feature,
  );
