import { ReactComponent as Icon2D } from '@assets/icons/40x40/mapMenu/ic-2d-40.svg';
import { ReactComponent as Icon3D } from '@assets/icons/40x40/mapMenu/ic-3d-40.svg';
import { VStack } from '@chakra-ui/react';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { toggleDimensionByMapId } from '@redux/maps/maps.reducer';
import { selectDimensionByMapId } from '@redux/maps/maps.selectors';
import { memo } from 'react';

import { Dimension, MapIdEnum } from '@/types/map.types';

export type DimensionControlProps = {
  mapId: MapIdEnum;
};

function DimensionControl({ mapId }: Readonly<DimensionControlProps>) {
  const dimension = useAppSelector((state) => selectDimensionByMapId(state, mapId));
  const toggleDimension = useWithDispatch(toggleDimensionByMapId);
  const is2DActive = dimension === Dimension['2D'];

  return (
    <VStack marginTop="auto" gap={0.25} width="100%">
      <ButtonBar
        icon={Icon2D}
        label="2D"
        size="flat"
        orientation="bottom-left"
        tooltipPlacement="left"
        isActive={is2DActive}
        isDisabled={is2DActive}
        onClick={() => !is2DActive && toggleDimension({ mapId: mapId, currentDimension: dimension })}
      />
      <ButtonBar
        icon={Icon3D}
        size="flat"
        label="3D"
        orientation="bottom-left"
        tooltipPlacement="left"
        isActive={!is2DActive}
        isDisabled={!is2DActive}
        onClick={() => is2DActive && toggleDimension({ mapId: mapId, currentDimension: dimension })}
      />
    </VStack>
  );
}

export default memo(DimensionControl);
