import { ReactComponent as PerimeterAlertIcon } from '@assets/icons/70x70/sensor/effraction-L.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { getAlertColor, getAlertStatusMessageId } from '@utils/data/notification.utils';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertLevelEnum, PerimeterEffractionAlert } from '@/types/data/data.types';

import AlertTime from './AlertTime';

type Props = {
  alert: PerimeterEffractionAlert;
  confirmMode: boolean;
};

export default function PerimeterAlertCard({ alert, confirmMode }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const lightColor = getAlertColor(alert.level);
  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(alert.marks)),
    isEqual,
  ).join(', ');

  return (
    <>
      <Icon key={alert.id} as={PerimeterAlertIcon} boxSize="70px" />
      <VStack gap={0} height="100%" width="100%" paddingRight={2}>
        <HStack gap={2} height="50%" width="100%">
          <Text fontSize="18px" flexShrink={0} width="350px">
            <FormattedMessage id="notification.perimeterIntrusion" />
          </Text>
          <AlertTime startTime={alert.startTime} endTime={alert.endTime} compact={confirmMode} />
        </HStack>
        <HStack gap={1} height="50%" fontWeight="medium" width="100%" fontSize="12px">
          <VStack width="198px" alignItems="start" gap="6px">
            <Text
              backgroundColor={lightColor}
              color={alert.level === AlertLevelEnum.CRITICAL ? 'neutral.white' : 'neutral.black'}
              paddingX={0.5}
              paddingBottom="1px"
              fontSize="12px"
              fontWeight="medium"
            >
              <FormattedMessage id={getAlertStatusMessageId(alert)} />
            </Text>
            <HStack gap={2}>
              <Text fontSize="12px" fontWeight="medium" height="15px">
                {alert.cabinetName}
              </Text>
              <Text fontSize="12px" fontWeight="medium" height="15px">
                {alert.segmentName}
              </Text>
            </HStack>
          </VStack>
          <VStack width="184px" alignItems="start" gap="6px">
            <Text fontSize="12px" fontWeight="medium" height="15px">
              {alert.identifier}
            </Text>
            <Text fontSize="12px" fontWeight="medium" height="15px">
              {
                //TODO missing information, remove height="15px" when done
              }
            </Text>
          </VStack>
          <VStack maxWidth="184px" alignItems="start" gap="6px" flexShrink={1}>
            <Text
              paddingX={0.5}
              backgroundColor="alertHi.900"
              paddingBottom="1px"
              fontSize="12px"
              fontWeight="medium"
              noOfLines={1}
            >
              {platformsNames || formatMessage({ id: 'components.alert.noPlatform' })}
            </Text>
            <Text fontSize="12px" fontWeight="medium" height="15px">
              {
                //TODO missing information, remove height="15px" when done
              }
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}
