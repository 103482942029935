import { ReactComponent as PlayerBackward } from '@assets/components/button/icPlayer-backward.svg';
import { ReactComponent as PlayerForward } from '@assets/components/button/icPlayer-forward.svg';
import { ReactComponent as PlayerPause } from '@assets/components/button/icPlayer-pause.svg';
import { ReactComponent as PlayerPlay } from '@assets/components/button/icPlayer-play.svg';
import { ReactComponent as SpeedX1 } from '@assets/components/button/icPlayer-speed-x1.svg';
import { ReactComponent as SpeedX2 } from '@assets/components/button/icPlayer-speed-x2.svg';
import { ReactComponent as SpeedX4 } from '@assets/components/button/icPlayer-speed-x4.svg';
import { ReactComponent as SpeedX6 } from '@assets/components/button/icPlayer-speed-x6.svg';
import { ReactComponent as PlayerStop } from '@assets/components/button/icPlayer-stop.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack, VStack } from '@chakra-ui/layout';
import { IconButton, Text, Tooltip } from '@chakra-ui/react';
import CustomSelect from '@components/common/inputs/CustomStyledSelect';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveRoundConfigurations } from '@redux/config/config.selectors';
import { selectSensorOrSubSensorStatusByUniqueCodes } from '@redux/situation/monitoring.selectors';
import { selectRounds } from '@redux/situation/situation.selectors';
import {
  useGotToSectionMutation,
  useToggleRoundDirectionMutation,
  useUpdateRoundModeMutation,
  useUpdateRoundSpeedMutation,
  useUpdateRoundStatusMutation,
} from '@services/c2/round.api';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoundConfiguration, RoundModeEnum, RoundStatusEnum } from '@/types/config/round.types';
import { SensorUniqueCodes } from '@/types/map.types';
import { SensorStatus } from '@/types/sensor/status.types';

type Props = {
  sensorUniqueCodes: SensorUniqueCodes;
};

export default function ({ sensorUniqueCodes }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const sensorStatus: SensorStatus = useAppSelector((state) =>
    selectSensorOrSubSensorStatusByUniqueCodes(state, false, sensorUniqueCodes),
  ) as SensorStatus;
  const { code: cameraCode, site, appCode } = sensorStatus.configuration;
  const roundConfigurations = useAppSelector(selectActiveRoundConfigurations).filter(
    (roundConfig) => roundConfig.sensorCode === cameraCode,
  );
  const onGoingRound = useAppSelector(selectRounds).find((round) => round.sensorCode === cameraCode) ?? null;

  const [updateRoundStatus] = useUpdateRoundStatusMutation();
  const [updateRoundSpeed] = useUpdateRoundSpeedMutation();
  const [updateRoundMode] = useUpdateRoundModeMutation();
  const [toggleRoundDirection] = useToggleRoundDirectionMutation();
  const [goToSection] = useGotToSectionMutation();

  const [selectedRoundConfiguration, setSelectedRoundConfiguration] = useState<RoundConfiguration | null>(
    roundConfigurations.find((config) => config.code === onGoingRound?.code) ?? null,
  );
  const [selectedSectionRank, setSelectedSectionRank] = useState<number | null>();
  const roundConfigurationOptions = roundConfigurations.map((roundConfiguration) => ({
    label: roundConfiguration.code,
    value: roundConfiguration.id.toString(),
  }));

  const isCritical = onGoingRound?.mode === RoundModeEnum.CRITICAL;
  const isOngoing = onGoingRound?.status === RoundStatusEnum.ONGOING;
  const isPaused = onGoingRound?.status === RoundStatusEnum.PAUSED;

  const roundSectionOptions =
    selectedRoundConfiguration?.sections
      .toSorted((s1, s2) => s1.rank - s2.rank)
      .map((section) => ({
        label: `Section ${section.rank}`,
        value: section.rank.toString(),
      })) ?? [];

  const isDisabled = selectedRoundConfiguration === null;

  function increaseSpeed(): number {
    const speed = onGoingRound?.speedMultiplier;
    if (!speed) {
      return 0;
    }
    switch (speed) {
      case 1:
        return 2;
      case 2:
        return 4;
      case 4:
        return 6;
      case 6:
      default:
        return 1;
    }
  }

  function onStopClick() {
    if (!isDisabled) {
      updateRoundStatus({
        id: selectedRoundConfiguration.id,
        site: site,
        appCode: appCode,
        status: RoundStatusEnum.STOPPED,
      });
    }
  }

  function onSelectedRoundChange(roundId: string) {
    const newSelectedRound =
      roundConfigurations.find((roundConfiguration) => roundConfiguration.id === Number(roundId)) ?? null;
    setSelectedRoundConfiguration(newSelectedRound);
    onStopClick();
  }

  function onSelectedSectionChange(sectionRank: number) {
    if (!isDisabled) {
      setSelectedSectionRank(sectionRank);
      goToSection({
        id: selectedRoundConfiguration.id,
        site: site,
        appCode: appCode,
        rank: sectionRank,
      });
    }
  }

  function toggleRoundMode() {
    if (!isDisabled) {
      updateRoundMode({
        id: selectedRoundConfiguration.id,
        site: site,
        appCode: appCode,
        mode: isCritical ? RoundModeEnum.NORMAL : RoundModeEnum.CRITICAL,
      });
    }
  }

  function togglePlayPauseRound() {
    if (!isDisabled) {
      updateRoundStatus({
        id: selectedRoundConfiguration.id,
        site: site,
        appCode: appCode,
        status: isOngoing ? RoundStatusEnum.PAUSED : RoundStatusEnum.ONGOING,
      });
    }
  }

  function handleUpdateSpeed() {
    if (!isDisabled) {
      updateRoundSpeed({
        id: selectedRoundConfiguration.id,
        site: site,
        appCode: appCode,
        speedMultiplier: increaseSpeed(),
      });
    }
  }

  function handleReverseRound() {
    if (!isDisabled) {
      toggleRoundDirection({
        id: selectedRoundConfiguration.id,
        site: site,
        appCode: appCode,
      });
    }
  }

  function getSpeedIcon(speed: number) {
    switch (speed) {
      case 1:
        return SpeedX1;
      case 2:
        return SpeedX2;
      case 4:
        return SpeedX4;
      case 6:
        return SpeedX6;
      default:
        return undefined;
    }
  }
  return (
    <VStack width="100%" gap={0} userSelect="none">
      <VStack width="100%" padding={4} backgroundColor="neutral.700" gap={3} alignItems="flex-start">
        <Text fontSize="24px" fontWeight="medium">
          {cameraCode}
        </Text>
        <HStack gap={4}>
          <VStack width="200px" gap={1} alignItems="flex-start">
            <Text color="neutral.300" size="md">
              <FormattedMessage id="rounds.walkway" />
            </Text>
            <CustomSelect
              width={200}
              isClearable={false}
              menuPortalTarget={document.body}
              value={selectedRoundConfiguration?.id.toString() ?? ''}
              options={roundConfigurationOptions}
              onChange={onSelectedRoundChange}
            />
          </VStack>
          <VStack width="200px" gap={1} alignItems="flex-start">
            <Text color="neutral.300" size="md">
              <FormattedMessage id="rounds.section" />
            </Text>
            <CustomSelect
              key={selectedRoundConfiguration?.code}
              width={200}
              menuPortalTarget={document.body}
              isClearable={false}
              value={selectedSectionRank?.toString() ?? ''}
              options={roundSectionOptions}
              onChange={(rankStr) => onSelectedSectionChange(Number.parseInt(rankStr))}
            />
          </VStack>
        </HStack>
        <VStack gap={1} alignItems="flex-start">
          <Text color="neutral.300" size="md">
            <FormattedMessage id="rounds.mode" />
          </Text>
          <HStack gap={2}>
            <Text
              size="lg"
              color={isCritical ? 'neutral.300' : 'neutral.white'}
              fontWeight={isCritical ? 'regular' : 'medium'}
            >
              <FormattedMessage id="rounds.normal" />
            </Text>
            <CustomSwitch isDisabled={isDisabled} isChecked={isCritical} onClick={toggleRoundMode} />
            <Text
              size="lg"
              color={isCritical ? 'neutral.white' : 'neutral.300'}
              fontWeight={isCritical ? 'medium' : 'regular'}
            >
              <FormattedMessage id="rounds.critical" />
            </Text>
          </HStack>
        </VStack>
      </VStack>
      <HStack width="100%" padding={4} gap={0.25} backgroundColor="neutral.900">
        <Icon as={getSpeedIcon(onGoingRound?.speedMultiplier ?? 1)} width="40px" height="40px" marginRight={3} />
        <Tooltip label={formatMessage({ id: 'rounds.reverse' })}>
          <IconButton
            onClick={handleReverseRound}
            isDisabled={isDisabled}
            icon={<PlayerBackward />}
            aria-label="backward"
            variant="player"
          />
        </Tooltip>
        <Tooltip label={formatMessage({ id: !isPaused ? 'rounds.pause' : 'rounds.play' })}>
          <IconButton
            isDisabled={isDisabled}
            icon={onGoingRound == null || isPaused ? <PlayerPlay /> : <PlayerPause />}
            aria-label="backward"
            variant="player"
            onClick={togglePlayPauseRound}
            isActive={onGoingRound !== null && !isPaused}
          />
        </Tooltip>
        <Tooltip label={formatMessage({ id: 'rounds.stop' })}>
          <IconButton
            isDisabled={isDisabled}
            icon={<PlayerStop />}
            aria-label="backward"
            variant="player"
            onClick={onStopClick}
          />
        </Tooltip>
        <Tooltip label={formatMessage({ id: 'rounds.increaseSpeed' })}>
          <IconButton
            isDisabled={isDisabled}
            icon={<PlayerForward />}
            aria-label="backward"
            variant="player"
            onClick={handleUpdateSpeed}
          />
        </Tooltip>
      </HStack>
    </VStack>
  );
}
