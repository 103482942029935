import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  lineHeight: '125%',
  fontSize: '14px',
  fontWeight: 'regular',
  color: 'neutral.white',
  fontFamily: 'roboto',
});

const gauge = defineStyle({
  textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black',
  color: 'white',
});

const space = defineStyle({
  fontFamily: 'space',
  fontFeatureSettings: "'liga' off",
});

const mapLabel = defineStyle({
  textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
  color: 'black',
});

const sizes = defineStyle({
  xs: {
    fontSize: '14px',
    fontWeight: 'medium',
  },
  sm: {
    fontSize: '16px',
    fontWeight: '600',
  },
  md: {
    fontSize: '16px',
    fontWeight: 'medium',
  },
  lg: {
    fontSize: '18px',
    fontWeight: 'medium',
  },
});

export const textTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: { gauge, mapLabel, space },
});
