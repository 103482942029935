import { createEntityAdapter } from '@reduxjs/toolkit';
import { idComparator, stringPropertyComparator } from '@utils/common.utils';

import { Period, ReplayEvent } from '@/types/replay/replay.types';

export const replayEventsAdapter = createEntityAdapter<ReplayEvent, string>({
  selectId: (replayEvent) => replayEvent.code,
  sortComparer: stringPropertyComparator('startDate'),
});

export const replayManualPeriodsAdapter = createEntityAdapter<Period, number>({
  selectId: (replayManualPeriod) => replayManualPeriod.id,
  sortComparer: idComparator,
});
