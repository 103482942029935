import { ReactComponent as PhoneIcon } from '@assets/icons/40x40/ic-phoneNegative-40.svg';
import { HStack, Icon, TabPanel, Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole } from '@redux/authent/authent.selectors';
import { FormattedMessage } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { SensorUniqueCodes } from '@/types/map.types';
import { SensorTypeEnum, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import AutomatonCommands from './AutomatonCommands';
import CabinetCommands from './CabinetCommands';
import XentaCommands from './XentaCommands';

type SpecificCommandsProps = {
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorUniqueCodes: SensorUniqueCodes;
  type: SensorTypeEnum | SubSensorTypeEnum;
};

function SpecificCommands({ sensorStatus, sensorUniqueCodes, type }: Readonly<SpecificCommandsProps>) {
  switch (type) {
    case SensorTypeEnum.AUTOMATON: {
      if (sensorStatus === null || sensorStatus.type === SensorTypeEnum.AUTOMATON) {
        return <AutomatonCommands automatonStatus={sensorStatus} />;
      }
      return null;
    }
    case SubSensorTypeEnum.AUTOMATON_CABINET:
      return <CabinetCommands sensorUniqueCodes={sensorUniqueCodes} />;
    case SensorTypeEnum.XENTA: {
      if (sensorStatus === null || sensorStatus.type === SensorTypeEnum.XENTA) {
        return <XentaCommands xentaStatus={sensorStatus} />;
      }
      return null;
    }
    default:
      return null;
  }
}

type CommandsProps = {
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorUniqueCodes: SensorUniqueCodes;
  type: SubSensorTypeEnum | SensorTypeEnum;
};

export default function Commands({ sensorStatus, sensorUniqueCodes, type }: Readonly<CommandsProps>) {
  const specificCommands = SpecificCommands({ sensorStatus, sensorUniqueCodes, type });

  const canDoCommand = useAppSelector((state) => hasRole(state, RoleEnum.SENSOR_COMMAND));

  if (specificCommands === null || !canDoCommand) {
    return null;
  }
  return (
    <TabPanel>
      <VStack padding={2} gap={2} backgroundColor="neutral.900" width="100%">
        {specificCommands}
        <HStack backgroundColor="alertHi.900" paddingX={3} paddingY={2} width="100%">
          <Text whiteSpace="pre-line" fontSize="16px" userSelect="none">
            <FormattedMessage id="sensors.emergency" />
          </Text>
          <Text variant="space" width="100%" textAlign="center" fontSize="24px">
            <FormattedMessage id="global.adminNumber" />
          </Text>
          <Icon as={PhoneIcon} color="alertHi.500" width="40px" height="40px" />
        </HStack>
      </VStack>
    </TabPanel>
  );
}
