import { VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { FilterOption } from '@/types/filters.types';

import FiltersMenuItem from './FiltersMenuItem';

type Props = { filterCategory: FilterOption[]; label: string; color?: string };

export default function FilterCategory({ filterCategory, label, color }: Readonly<Props>) {
  return (
    <VStack gap={3} width="320px" height="100%">
      <Text fontWeight="regular" fontSize="20px" width="100%">
        <FormattedMessage id={label} />
      </Text>
      <VStack gap={2} width="100%">
        {filterCategory.map((filterOption) => (
          <FiltersMenuItem key={filterOption.key} filterOption={filterOption} color={color} />
        ))}
      </VStack>
    </VStack>
  );
}
