import { appIntl } from '@components/locale/IntlGlobalProvider';
import { RootState } from '@redux/store';
import { jwtDecode } from 'jwt-decode';
import { capitalize, PropertyName } from 'lodash';

import { Token } from '@/types/authent/login.types';
import {
  FirstConnectionExceptionPayload,
  Mark,
  MarkEnum,
  StatusPayload,
  SyriusExceptionPayload,
} from '@/types/commons/commons.types';

export const EPSILON = 1e-4;

export function updateItemInArrayByCode<T extends { code: string }>(array: T[], item: T): T[] {
  const index = array.findIndex((obj) => obj.code === item.code);
  const arrayCopy = [...array];
  if (index !== -1) {
    arrayCopy[index] = item;
    return arrayCopy;
  } else {
    return arrayCopy.concat(item);
  }
}

export function propertyComparator<P extends PropertyName, T, O extends { [key in P]: T }>(
  property: P,
  compare: (a: T, b: T) => number,
) {
  return (objA: O, objB: O) => compare(objA[property], objB[property]);
}

export function stringPropertyComparator<P extends PropertyName, T extends { [key in P]: string }>(property: P) {
  return (objA: T, objB: T) => objA[property].localeCompare(objB[property]);
}

export function idComparator<T extends { id: number }>(objectA: T, objectB: T) {
  return objectA.id - objectB.id;
}

export function isFirstConnectionExceptionPayload(obj: unknown): obj is FirstConnectionExceptionPayload {
  if (
    typeof obj === 'object' &&
    obj !== null &&
    'status' in obj &&
    typeof obj.status === 'number' &&
    'data' in obj &&
    typeof obj.data === 'object' &&
    obj.data !== null
  ) {
    const data = obj.data;
    return (
      'first_name' in data &&
      'name' in data &&
      'email' in data &&
      'login' in data &&
      'user_id' in data &&
      'user_state' in data &&
      'code' in data
    );
  }
  return false;
}

export function isSyriusExceptionPayload(obj: unknown): obj is SyriusExceptionPayload {
  if (typeof obj === 'object' && obj !== null && 'data' in obj) {
    const data = obj.data;
    return typeof data === 'object' && data !== null && 'code' in data && 'message' in data && 'params' in data;
  }
  return false;
}

export function isStatusPayload(obj: unknown): obj is StatusPayload {
  return typeof obj === 'object' && obj !== null && 'status' in obj && typeof obj.status === 'string';
}

export function createErrorMessage(payload: unknown): string {
  const formatMessage = appIntl().formatMessage;
  if (isSyriusExceptionPayload(payload)) {
    const paramsAsTab: string[] = payload.data.params.flatMap((param, index) => {
      const label = param.i18nKey
        ? formatMessage({
            id: `error.params.${index === 0 ? 'first.' : ''}${param.i18nKey}`,
            defaultMessage: param.i18nKey,
          })
        : null;
      return label ? [label, param.value] : [param.value];
    });
    const params = { ...paramsAsTab } as { [p: number]: string };
    return capitalize(formatMessage({ id: `error.${payload.data.code}` }, params));
  } else if (isStatusPayload(payload)) {
    return formatMessage({ id: `error.${payload.status}`, defaultMessage: 'error.common.unknown_error' });
  }
  return formatMessage({ id: 'error.common.unknown_error' });
}

export function decodeToken(token: string | null): Token | null {
  if (!token) {
    return null;
  }
  try {
    return jwtDecode(token);
  } catch (e) {
    console.error('Invalid token');
    return null;
  }
}

export function containsSubstring(value: string, value2: string, length = 3): boolean {
  if (value.length < length || value2.length < length) {
    return false;
  }

  for (let i = 0; i <= value2.length - length; i++) {
    const substring = value2.substring(i, i + length);
    if (value.includes(substring)) {
      return true;
    }
  }

  return false;
}

export function toLowercaseValueRecord<T extends string>(record: Record<T, string>): Record<T, Lowercase<string>> {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => [key, (value as string).toLowerCase() as Lowercase<string>]),
  ) as Record<T, Lowercase<string>>;
}

export function calculateLinesNeeded(message: string, maxCharsPerLine: number): number {
  if (maxCharsPerLine <= 0 || message.length === 0) {
    return 0;
  }
  let lines = 0;
  let currentLineLength = 0;

  for (const word of message.split(' ')) {
    const wordLength = word.length;

    if (wordLength > maxCharsPerLine) {
      if (currentLineLength > 0) {
        lines++;
        currentLineLength = 0;
      }

      const fullLinesForWord = Math.floor(wordLength / maxCharsPerLine);
      lines += fullLinesForWord;

      currentLineLength = wordLength % maxCharsPerLine;
    } else if (currentLineLength + wordLength + (currentLineLength > 0 ? 1 : 0) <= maxCharsPerLine) {
      currentLineLength += wordLength + (currentLineLength > 0 ? 1 : 0);
    } else {
      lines++;
      currentLineLength = wordLength;
    }
  }

  if (currentLineLength > 0) {
    lines++;
  }

  return lines;
}

export function getPlatformsCodesFromMarks(marks: Mark[]): string[] {
  return Array.from(
    new Set(
      marks.filter((mark) => mark.type === MarkEnum.PLATFORM && mark.context !== null).map((mark) => mark.context!),
    ),
  );
}

export function selectParameter<P>(_: RootState, parameter: P) {
  return parameter;
}

export function selectParameterWithReplayMode<P>(_: RootState, __: boolean, parameter: P) {
  return parameter;
}

export function isSetEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
  if (setA.size !== setB.size) {
    return false;
  }
  return Array.from(setA).every((element) => setB.has(element));
}
