import { RootState } from '@redux/store';
import {
  dronesAdapter,
  flightPlansAdapter,
  flyRoutesAdapter,
  flySectionsAdapter,
  flyZonesAdapter,
  pilotsAdapter,
} from '@redux/utm/utm.adapters';
import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';

import { Drone, NormalizedDrone } from '@/types/utm/drone.types';

import { droneSchema } from './utm.schema';

const { selectAll: selectAllPilots, selectEntities: selectPilotEntities } = pilotsAdapter.getSelectors(
  (state: RootState) => state.utm.pilots,
);

export const PilotSelectors = {
  selectAllPilots,
};

const { selectAll: selectAllDrones, selectEntities: selectDroneEntities } = dronesAdapter.getSelectors(
  (state: RootState) => state.utm.drones,
);

const selectDroneByCode = dronesAdapter.getSelectors((state: RootState) => state.utm.drones).selectById as (
  state: RootState,
  id: string,
) => NormalizedDrone | undefined;

export const DroneSelectors = {
  selectAllDrones,
  selectDroneByCode,
};

const { selectAll: selectAllFlightPlans, selectEntities: selectFlightPlanEntities } = flightPlansAdapter.getSelectors(
  (state: RootState) => state.utm.flightPlans,
);

export const FlightPlanSelectors = {
  selectAllFlightPlans,
};

const { selectAll: selectAllFlyZones, selectEntities: selectFlyZoneEntities } = flyZonesAdapter.getSelectors(
  (state: RootState) => state.utm.flyZones,
);

export const FlyZoneSelectors = {
  selectAllFlyZones,
};

const { selectAll: selectAllFlySections, selectEntities: selectFlySectionEntities } = flySectionsAdapter.getSelectors(
  (state: RootState) => state.utm.flySections,
);

export const FlySectionSelectors = {
  selectAllFlySections,
};

const { selectAll: selectAllFlyRoutes, selectEntities: selectFlyRouteEntities } = flyRoutesAdapter.getSelectors(
  (state: RootState) => state.utm.flyRoutes,
);

export const FlyRouteSelectors = {
  selectAllFlyRoutes,
};

const selectEntities = createSelector(
  [
    selectPilotEntities,
    selectDroneEntities,
    selectFlightPlanEntities,
    selectFlyZoneEntities,
    selectFlySectionEntities,
    selectFlyRouteEntities,
  ],
  (pilots, drones, flightPlans, flyZones, flySections, flyRoutes) => ({
    pilots,
    drones,
    flightPlans,
    flyZones,
    flySections,
    flyRoutes,
  }),
);

export const selectDenormalizedDrones = createSelector([selectEntities, selectAllDrones], (entities, drones): Drone[] =>
  drones.map((drone) => denormalize(drone.code, droneSchema, entities)),
);
