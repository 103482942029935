import { flightPlanColor } from '@utils/map/map.constants';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { FeatureTypeEnum } from '@/types/map.types';
import { FlightPlan } from '@/types/utm/flightPlan.types';

export const toFlightPlanFillFeatures = (flightPlans: FlightPlan[]): Feature[] =>
  flightPlans.map((flightPlan) => {
    return {
      type: 'Feature',
      properties: {
        featureType: FeatureTypeEnum.FLIGHT_PLAN,
        value: flightPlan,
        fillColor: flightPlanColor[flightPlan.status].fill,
        outlineColor: flightPlanColor[flightPlan.status].outline,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [flightPlan.polygon.map((coordinates) => toLonLatArray(coordinates, false)!)],
      },
    } as Feature;
  });
