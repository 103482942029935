import { Tab, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectTargetById } from '@redux/situation/situation.selectors';
import { getTargetIdentification, isDrone } from '@utils/target.utils';
import { capitalize } from 'lodash';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { PopupPositionData, TargetInfoData } from '@/types/map.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import DraggableControl from '../../controls/DraggableControl';
import TargetDescription from './TargetDescription';
import TargetLadInfo from './TargetLadInfo';
import TargetUtmInfos from './TargetUtmInfos';

export default function TargetInfo({ targetId, top, left, onClose }: Readonly<TargetInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement | null>(null);

  const target: Target<TargetLocation> | undefined = useSelectorWithReplayMode(selectTargetById, targetId);

  if (!target) {
    onClose();
    return null;
  }

  const isDroneTarget = isDrone(target);

  return (
    <DraggableControl
      top={top}
      left={left}
      offsetY={-200}
      key={target.id}
      width={489}
      label={formatMessage(
        { id: 'targetInfos.targetInfo' },
        {
          identification: capitalize(formatMessage({ id: `target.identification.${getTargetIdentification(target)}` })),
        },
      )}
      onClose={onClose}
    >
      <Tabs width="100%" height="max-content" variant="styledTab" ref={ref}>
        <TabList>
          <Tab>
            <Text size="md">
              <FormattedMessage id="targetInfos.tab.description" />
            </Text>
          </Tab>
          <Tab>
            <Text size="md">
              <FormattedMessage id="targetInfos.tab.ladInfos" />
            </Text>
          </Tab>
          {isDroneTarget && (
            <Tab>
              <Text size="md">
                <FormattedMessage id="targetInfos.tab.utmInfos" />
              </Text>
            </Tab>
          )}
        </TabList>

        <TabPanels height="100%">
          <TargetDescription target={target} />
          <TargetLadInfo target={target} position={{ x: left, y: top }} />
          {isDroneTarget && <TargetUtmInfos />}
        </TabPanels>
      </Tabs>
    </DraggableControl>
  );
}
