import { InitializationMaskingZone } from '@/types/config/config.types';

import { CONFIG_URL, configApi } from './config.api';

export const imzApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    exportInitializationMaskingZone: builder.mutation<Blob, string>({
      query: (site) => ({
        url: `${CONFIG_URL}/masking-zones/`.concat(site, '/downloadFile'),
        method: 'GET',
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    createInitializationMaskingZone: builder.mutation<InitializationMaskingZone, Partial<InitializationMaskingZone>>({
      query: (imz) => ({
        url: `${CONFIG_URL}/masking-zones`,
        method: 'POST',
        body: imz,
      }),
    }),
    updateInitializationMaskingZone: builder.mutation<
      InitializationMaskingZone,
      { id: number; imz: InitializationMaskingZone }
    >({
      query: ({ id, imz }) => ({
        url: `${CONFIG_URL}/masking-zones/${id}`,
        method: 'PUT',
        body: imz,
      }),
    }),

    deleteInitializationMaskingZone: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/masking-zones/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateInitializationMaskingZoneMutation,
  useUpdateInitializationMaskingZoneMutation,
  useDeleteInitializationMaskingZoneMutation,
} = imzApi;
