import { HStack, VStack } from '@chakra-ui/layout';
import { Button, Card, CardBody, Text } from '@chakra-ui/react';
import FormattedDateRange from '@components/common/FormattedDateRange';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import NotificationCard from '@components/map/popup/NotificationCard';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { useGetNotificationsQuery, useTotalOfNotificationsQuery } from '@services/data/notification.api';
import { sortDateDesc } from '@utils/date.utils';
import { addYears } from 'date-fns';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IdentificationNotification, NotificationCriteria, NotificationType } from '@/types/data/data.types';
import { HistoryPopupData } from '@/types/map.types';

import DraggableControl from '../controls/DraggableControl';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
} & HistoryPopupData;

function HistoryPopup({ top, left, droneId, openingTime, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const { activeSite } = useAppSelector(selectActiveUser);

  const { data: detections } = useGetNotificationsQuery(
    {
      site: activeSite!.code,
      from: addYears(openingTime!, -1).toISOString(),
      identifier: droneId,
      types: [NotificationType.DRONE_IDENTIFICATION],
    } as NotificationCriteria,
    { skip: !activeSite || openingTime === null },
  );

  const { data: totalOfDetections } = useTotalOfNotificationsQuery(
    {
      site: activeSite!.code,
      identifier: droneId,
      types: [NotificationType.DRONE_IDENTIFICATION],
    } as NotificationCriteria,
    { skip: !activeSite || openingTime === null },
  );

  return (
    <DraggableControl
      top={top}
      left={left}
      label={formatMessage({ id: 'targetInfos.history' })}
      height={825}
      width={818}
      onClose={onClose}
    >
      <Card variant="unstyled" borderRadius={0} width="100%" height="100%" gap={1}>
        <CardBody backgroundColor="neutral.900">
          <HStack justifyContent="space-between" padding={4} backgroundColor="neutral.700">
            <VStack alignItems="start" spacing={1}>
              <Text color="neutral.300" size="md">
                <FormattedMessage id="replay.search.chipIdentifier" />
              </Text>
              <Text size="lg">{droneId}</Text>
            </VStack>
            <VStack alignItems="start" spacing={1}>
              <Text color="neutral.300" size="md">
                <FormattedMessage id="replay.search.status" />
              </Text>
              <Text size="lg">Inconnu</Text>
            </VStack>
            <VStack alignItems="start" spacing={1}>
              <Text color="neutral.300" size="md">
                <FormattedMessage id="replay.search.time" />
              </Text>
              {openingTime && <FormattedDateRange startDate={addYears(openingTime, -1)} endDate={openingTime} />}
            </VStack>
          </HStack>
          <HStack justifyContent="space-between" alignItems="center" gap={1} padding={4} backgroundColor="neutral.800">
            <Text fontSize="20px" noOfLines={1} flexShrink={0}>
              <FormattedMessage
                id="replay.search.detectionFound"
                values={{ detections: detections?.length, total: totalOfDetections }}
              />
            </Text>
            <Button variant="formButtonPrimary" size="s" height="52px" width={305} textTransform="none" isDisabled>
              <FormattedMessage id="replay.search.displayInLadReplay" />
            </Button>
          </HStack>
          <MapCustomScrollbar height={594} marginTop={0.25}>
            <VStack padding={0.5} spacing={0.5} backgroundColor="neutral.900">
              {detections
                ?.toSorted((d1, d2) => sortDateDesc(d1.startTime, d2.startTime))
                .map((detection) => (
                  <NotificationCard key={detection.id} detection={detection as IdentificationNotification} />
                ))}
            </VStack>
          </MapCustomScrollbar>
        </CardBody>
      </Card>
    </DraggableControl>
  );
}

function arePropsEqual(oldProps: Readonly<Props>, newProps: Readonly<Props>) {
  return oldProps.droneId === newProps.droneId;
}

export default memo(HistoryPopup, arePropsEqual);
