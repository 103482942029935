import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RawSituation, TacticalSituation } from '@/types/c2/c2.types';
import { ConfigConfiguration } from '@/types/config/config.types';
import { Round } from '@/types/config/round.types';
import { FlightPlan } from '@/types/utm/flightPlan.types';

export type SituationState = {
  version: number;
  site: string;
  time: string;
  tacticalSituation: TacticalSituation | null;
  rawSituation: RawSituation | null;
  configuration: ConfigConfiguration | null;
  flightPlans: FlightPlan[];
  rounds: Round[];
};

export const SITUATION_INITIAL_STATE: SituationState = {
  version: -1,
  site: '',
  time: new Date().toISOString(),
  tacticalSituation: null,
  rawSituation: null,
  configuration: null,
  flightPlans: [],
  rounds: [],
};

const situation = createSlice({
  name: 'situation',
  initialState: SITUATION_INITIAL_STATE,
  reducers: {
    updateSituation(state, action: PayloadAction<SituationState>) {
      if (state.version < action.payload.version) {
        return action.payload;
      }
    },
    invalidateVersion(state) {
      state.version = SITUATION_INITIAL_STATE.version;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => {
      return { ...SITUATION_INITIAL_STATE };
    });
  },
});

export const { updateSituation, invalidateVersion } = situation.actions;
export default situation.reducer;
