import './gauge.scss';

import { ReactComponent as ArrowDown } from '@assets/icons/cameras/gauge-arrow-down.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/cameras/gauge-arrow-left.svg';
import { ReactComponent as ArrowLeft } from '@assets/icons/cameras/gauge-arrow-right.svg';
import { Box, Center, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, useSize } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectDrones, selectSelectedTarget } from '@redux/situation/situation.selectors';
import bearing from '@turf/bearing';
import { bearingToAzimuth } from '@turf/helpers';
import { getMapIdFromReplayMode, getTargetColor } from '@utils/map/map.utils';
import { getScaledValue, modulo } from '@utils/math.utils';
import {
  closestCardinal,
  computeHorizontalStepValues,
  findClosestHorizontalTargets,
  getHorizontalFovStyle,
  isValueInRange,
  TargetInfo,
} from '@utils/sensors/camera/gauge.utils';
import { getDroneIcon, getTargetPriority } from '@utils/target.utils';
import classNames from 'classnames';
import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Coordinates } from '@/types/commons/commons.types';

interface OwnProps {
  fov: number;
  pan: number;
  cameraPosition: Coordinates;
  replayMode: boolean;
}

function HorizontalGauge({ fov, pan, cameraPosition, replayMode }: Readonly<OwnProps>) {
  const gaugeRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useSize(gaugeRef);
  const targets = useAppSelector((state) => selectDrones(state, replayMode));
  const currentContext = useAppSelector(selectContext);
  const selectedTarget = useAppSelector((state) =>
    selectSelectedTarget(state, getMapIdFromReplayMode(replayMode, currentContext)),
  );

  const [fovStyle, setFovStyle] = useState<{
    width: number;
    left: number;
  }>({ width: 0, left: 0 });

  const { closestLeftCardinal, closestRightCardinal } = closestCardinal(pan);

  //Calculate the angle of the target compared to the camera position
  //also tell if the target is in the visible gauge range or not
  const targetsWithInfo: TargetInfo[] = useMemo(() => {
    const leftVisibleAngle = modulo(pan - 89.5, 360);
    const rightVisibleAngle = modulo(pan + 89.5, 360);
    return targets
      .filter((target) => target.lastPosition.location?.position !== undefined)
      .map((target) => {
        const targetCoordinates = target.lastPosition!.location!.position!;
        const targetPosition = [targetCoordinates.longitude, targetCoordinates.latitude];
        const angle = +bearingToAzimuth(
          bearing([cameraPosition.longitude, cameraPosition.latitude], targetPosition),
        ).toFixed(1);
        return {
          target,
          angle,
          isInRange: isValueInRange(angle, leftVisibleAngle, rightVisibleAngle),
        };
      });
  }, [cameraPosition.latitude, cameraPosition.longitude, pan, targets]);

  const { closestLeftTarget, closestRightTarget } = useMemo(() => {
    return findClosestHorizontalTargets(targetsWithInfo, pan, selectedTarget?.id);
  }, [pan, selectedTarget?.id, targetsWithInfo]);

  //Calculate the position of both fov brackets
  //Also move the gauge when the pan change
  //and simulate an infinite scrolling by shifting the gauge 360° (half its width) when we reach a threshold left or right
  useLayoutEffect(() => {
    if (containerRef.current && gaugeRef.current) {
      setFovStyle(getHorizontalFovStyle(pan, fov, gaugeRef.current));
      containerRef.current.scrollLeft =
        getScaledValue(-180, 540, pan, gaugeRef.current.clientWidth) - containerRef.current.offsetWidth / 2;
      if (
        containerRef.current.scrollLeft + containerRef.current.offsetWidth / 2 <
        containerRef.current.scrollWidth / 4
      ) {
        containerRef.current.scrollLeft += containerRef.current.scrollWidth / 2;
      } else if (
        containerRef.current.scrollLeft + containerRef.current.offsetWidth / 2 >
        containerRef.current.scrollWidth * (3 / 4)
      ) {
        containerRef.current.scrollLeft -= containerRef.current.scrollWidth / 2;
      }
    }
  }, [pan, fov, dimensions]);

  //Transform the function return into a list of divs
  const computedSteps = useMemo(() => {
    return computeHorizontalStepValues(
      -180,
      540,
      targetsWithInfo.filter((target) => target.isInRange),
    )
      .reverse()
      .map(({ smallBar, bigBar, size, index, label, margin, targets }) => (
        <Box
          className={classNames('step', {
            'step-label': bigBar,
            'step-half': smallBar,
          })}
          style={{
            width: `${size}%`,
            marginRight: margin && dimensions ? `${dimensions.width / (720 / margin)}px` : 0,
          }}
          key={`slider-li-${index}`}
        >
          {label && targets.length === 0 && (
            <>
              <Text position="absolute" variant="gauge" size="sm" top="28px" marginLeft="5px">
                {`${index % 360}°`}
              </Text>
              <Text position="absolute" variant="gauge" size="sm" top="65px">
                <FormattedMessage id={`cameras.gauge.${label}`} />
              </Text>
            </>
          )}
          {dimensions &&
            targets.map((targetWithInfos) => {
              const color = getTargetColor(targetWithInfos.target);
              const isSelected = selectedTarget?.id === targetWithInfos.target.id;
              return (
                <Box
                  key={targetWithInfos.target.id}
                  position="absolute"
                  left={`${(dimensions.width / 720) * modulo(targetWithInfos.angle, 1)}px`}
                  transform="translateX(-50%)"
                  height="100%"
                  zIndex={isSelected ? 10 : getTargetPriority(targetWithInfos.target) + 2}
                >
                  <Icon
                    as={getDroneIcon(targetWithInfos.target)}
                    position="absolute"
                    top="55%"
                    transform="translateX(-50%)"
                    width="22px"
                    height="22px"
                  />
                  {isSelected && (
                    <>
                      <Text
                        color={color}
                        position="absolute"
                        variant="gauge"
                        transform="translateX(-50%)"
                        size="sm"
                        top="30px"
                      >
                        {targetWithInfos.angle}°
                      </Text>
                      <Box
                        position="absolute"
                        top="calc(55% + 1px)"
                        transform={`translateX(-50%) rotate(45deg)`}
                        border="1px solid"
                        borderColor={color}
                        width="20px"
                        height="20px"
                      />
                    </>
                  )}
                </Box>
              );
            })}
        </Box>
      ));
  }, [targetsWithInfo, dimensions, selectedTarget?.id]);

  return (
    <HStack
      width="25%"
      position="absolute"
      height="120px"
      left="50%"
      top="25%"
      userSelect="none"
      transform="translate(-50%, -100%)"
      gap={0.5}
      alignItems="flex-end"
      pointerEvents="none"
    >
      <HStack gap={0} justifyContent="center" alignItems="center" width="28px" marginBottom="11px" position="relative">
        {closestLeftTarget && (
          <VStack position="absolute" zIndex={2} bottom={5} right="10px" width="40px">
            <Text color={getTargetColor(closestLeftTarget.target)} variant="gauge" size="xs">
              {closestLeftTarget.angle}°
            </Text>
            <Icon as={getDroneIcon(closestLeftTarget.target)} width="22px" height="22px" />
            {closestLeftTarget.target.id === selectedTarget?.id && (
              <Box
                position="absolute"
                bottom="1px"
                transform="rotate(45deg)"
                border="1px solid"
                borderColor={getTargetColor(closestLeftTarget.target)}
                width="20px"
                height="20px"
              />
            )}
          </VStack>
        )}
        <ArrowRight />
        <Text variant="gauge" size="sm">
          <FormattedMessage id={`cameras.gauge.${closestLeftCardinal}`} />
        </Text>
      </HStack>
      <Box className="video-control-gauge horizontal" ref={containerRef}>
        {fov > 0 && (
          <>
            <Text
              position="absolute"
              zIndex={2}
              variant="gauge"
              fontSize="20px"
              fontWeight="700"
              top="45px"
              transform="translateX(-50%)"
              left={`calc(50% - ${fovStyle.width / 2}px)`}
              color="#979797"
            >
              [
            </Text>
            <Text
              position="absolute"
              zIndex={2}
              top="45px"
              transform="translateX(-50%)"
              left={`calc(50% + ${fovStyle.width / 2}px)`}
              color="#979797"
              variant="gauge"
              fontSize="20px"
              fontWeight="700"
            >
              ]
            </Text>
          </>
        )}
        <Box className="gauge" ref={gaugeRef}>
          <Box className="steps">{computedSteps}</Box>
        </Box>
        <Box position="absolute" left="50%" transform="translateX(-50%)">
          <VStack gap={0}>
            <Center width="72px" height="32px" backgroundColor="white" border="2px solid black">
              <Center backgroundColor="black" width="64px" height="24px">
                <Text lineHeight="16px" size="sm">
                  {pan}°
                </Text>
              </Center>
            </Center>
            <ArrowDown style={{ marginTop: '-9px', marginRight: '1px', height: '40px' }} />
          </VStack>
        </Box>
      </Box>
      <HStack gap={0} justifyContent="center" alignItems="center" width="28px" marginBottom="11px" position="relative">
        {closestRightTarget && (
          <VStack position="absolute" zIndex={2} bottom={5} left="10px" width="40px">
            <Text color={getTargetColor(closestRightTarget.target)} variant="gauge" size="xs">
              {closestRightTarget.angle}°
            </Text>
            <Icon as={getDroneIcon(closestRightTarget.target)} width="22px" height="22px" />
            {closestRightTarget.target.id === selectedTarget?.id && (
              <Box
                position="absolute"
                bottom="1px"
                transform="rotate(45deg)"
                border={`1px solid ${getTargetColor(closestRightTarget.target)}`}
                width="20px"
                height="20px"
              />
            )}
          </VStack>
        )}
        <Text variant="gauge" size="sm">
          <FormattedMessage id={`cameras.gauge.${closestRightCardinal}`} />
        </Text>
        <ArrowLeft />
      </HStack>
    </HStack>
  );
}

export default memo(HorizontalGauge);
