import { sensorIconLabelLayer, sensorIconLayer } from '@components/map/layers/sensors/sensor.layer';
import { SHOW_PERIMETER_SENSOR_MIN_ZOOM } from '@utils/map/mapZoom.constants';
import { LineLayer, SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const perimeterSensorIconLayer: SymbolLayer = {
  ...sensorIconLayer,
  id: LayerNameEnum.PERIMETER_SENSOR_ICONS,
  source: SourceNameEnum.PERIMETER_SENSORS,
  minzoom: SHOW_PERIMETER_SENSOR_MIN_ZOOM,
};

export const perimeterSensorIconLabelLayer: SymbolLayer = {
  ...sensorIconLabelLayer,
  id: LayerNameEnum.PERIMETER_SENSOR_LABELS,
  source: SourceNameEnum.PERIMETER_SENSORS,
  minzoom: SHOW_PERIMETER_SENSOR_MIN_ZOOM,
};

export const perimeterLinkLayer: LineLayer = {
  id: LayerNameEnum.PERIMETER_LINKS,
  type: 'line',
  source: SourceNameEnum.PERIMETER_SENSORS,
  interactive: false,
  filter: ['==', 'featureType', FeatureTypeEnum.PERIMETER_LINK],
  minzoom: SHOW_PERIMETER_SENSOR_MIN_ZOOM,
  paint: {
    'line-color': 'white',
    'line-width': 2,
  },
};

export const perimeterLinkOutlineLayer: LineLayer = {
  ...perimeterLinkLayer,
  id: LayerNameEnum.PERIMETER_LINK_OUTLINES,
  paint: {
    'line-color': 'black',
    'line-width': 4,
  },
};
