import { SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const eventLayer: SymbolLayer = {
  id: LayerNameEnum.EVENTS,
  type: 'symbol',
  source: SourceNameEnum.EVENTS,
  filter: ['==', 'featureType', FeatureTypeEnum.EVENT_ICON],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-pitch-alignment': 'viewport',
    'icon-rotation-alignment': 'viewport',
    'symbol-z-order': 'source',
  },
  paint: {
    'icon-color': ['get', 'color'],
  },
};
