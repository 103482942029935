import { C2_URL, c2Api } from '@services/c2/c2.api';

import { Round, RoundModeEnum, RoundStatusEnum } from '@/types/config/round.types';

export const roundApi = c2Api.injectEndpoints({
  endpoints: (builder) => ({
    updateRoundStatus: builder.mutation<Round, { site: string; appCode: string; id: number; status: RoundStatusEnum }>({
      query: ({ site, appCode, id, status }) => ({
        url: `${C2_URL}/${site}/${appCode}/rounds/${id}/status`,
        method: 'PATCH',
        body: `"${status.valueOf()}"`,
      }),
    }),
    updateRoundSpeed: builder.mutation<Round, { site: string; appCode: string; id: number; speedMultiplier: number }>({
      query: ({ site, appCode, id, speedMultiplier }) => ({
        url: `${C2_URL}/${site}/${appCode}/rounds/${id}/speed`,
        method: 'PATCH',
        params: { speedMultiplier: speedMultiplier },
      }),
    }),
    updateRoundMode: builder.mutation<Round, { site: string; appCode: string; id: number; mode: RoundModeEnum }>({
      query: ({ site, appCode, id, mode }) => ({
        url: `${C2_URL}/${site}/${appCode}/rounds/${id}/mode`,
        method: 'PATCH',
        body: `"${mode.valueOf()}"`,
      }),
    }),
    gotToSection: builder.mutation<Round, { site: string; appCode: string; id: number; rank: number }>({
      query: ({ site, appCode, id, rank }) => ({
        url: `${C2_URL}/${site}/${appCode}/rounds/${id}/go-to/${rank}`,
        method: 'PATCH',
      }),
    }),
    toggleRoundDirection: builder.mutation<Round, { site: string; appCode: string; id: number }>({
      query: ({ site, appCode, id }) => ({
        url: `${C2_URL}/${site}/${appCode}/rounds/${id}/toggle-direction`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useUpdateRoundStatusMutation,
  useUpdateRoundSpeedMutation,
  useUpdateRoundModeMutation,
  useGotToSectionMutation,
  useToggleRoundDirectionMutation,
} = roundApi;
