import { Box, HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  label: string;
  value: string | number;
};

function CameraStatusValue({ label, value }: Readonly<Props>) {
  return (
    <HStack width="100%" backgroundColor="neutral.800-op70" gap={1.5} paddingX={1.5} paddingY={1}>
      <Text fontSize="16px">{label}</Text>
      <Box
        backgroundColor="neutral.900"
        paddingX={0.5}
        border="4px solid"
        borderRadius="10px"
        marginLeft="auto"
        style={{
          borderImage: '1 url(/images/camera-status-border.svg)',
        }}
      >
        <Text size="md">{value}</Text>
      </Box>
    </HStack>
  );
}

export default memo(CameraStatusValue);
