export enum ClassificationEnum {
  UNCLASSIFIED = 'UNCLASSIFIED',
  FRIEND = 'FRIEND',
  UNKNOWN = 'UNKNOWN',
  WATCH = 'WATCH',
  SUSPECT = 'SUSPECT',
  ENEMY = 'ENEMY',
}

export enum ClassificationOriginEnum {
  COMPUTED = 'COMPUTED',
  MANUAL = 'MANUAL',
}

export interface Classification {
  classification: ClassificationEnum;
  manualClassification: ClassificationEnum | null;
}
