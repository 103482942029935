import { FormatMessageType } from '@hooks/useValidation';
import { END, REQUIRED } from '@utils/validation/validation.constant';
import Yup from '@utils/validation/yup-extended';

export const dateSchema = (formatMessage: FormatMessageType) => {
  return Yup.object().shape({
    dateRange: Yup.object().shape({
      from: Yup.date().required(formatMessage({ id: REQUIRED })),
      to: Yup.date()
        .required(formatMessage({ id: REQUIRED }))
        .min(Yup.ref('from'), formatMessage({ id: END })),
    }),
  });
};
