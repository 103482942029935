import { ReactComponent as DroneSureIcon } from '@assets/components/panel/alert/alert-drone-icon-sure.svg';
import { ReactComponent as EnemyBorderDecoration } from '@assets/components/panel/notification/enemy-notification-border-decoration.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Card, CardBody, Icon, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { getIdentificationNotificationColor } from '@utils/data/notification.utils';
import { formatDate } from '@utils/date.utils';
import { intervalToDuration } from 'date-fns';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { IdentificationNotification } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';

interface NotificationCardProps {
  detection: IdentificationNotification;
}

function NotificationCard({ detection }: Readonly<NotificationCardProps>) {
  const { formatMessage } = useIntl();

  const lightColor = getIdentificationNotificationColor(detection.worstClassification);
  const darkColor = getIdentificationNotificationColor(detection.worstClassification, true);

  const detectionDuration =
    detection.endTime &&
    intervalToDuration({ start: new Date(detection.startTime), end: new Date(detection.endTime) }).minutes;

  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(detection.marks)),
    isEqual,
  ).join(', ');

  return (
    <Card
      direction={{ base: 'column', sm: 'row' }}
      borderRadius={0}
      alignItems="center"
      width="100%"
      bgGradient={`linear-gradient(0deg, ${darkColor} 50%, neutral.800 50%)`}
    >
      <HStack gap={4} height="144px">
        {detection.worstClassification === ClassificationEnum.ENEMY ? (
          <Icon as={EnemyBorderDecoration} height="100%" width="16px" flexShrink={0} />
        ) : (
          <Box
            height="100%"
            width="16px"
            flexShrink={0}
            bgGradient={`linear-gradient(90deg, ${lightColor} 50%, transparent 50%)`}
          />
        )}
        <Icon as={DroneSureIcon} width="70px" height="70px" color={lightColor} />
        <CardBody height="100%" padding={0}>
          <VStack height="100%" gap={0} alignItems="start">
            <HStack height="50%" alignItems="center">
              <Text size="lg">
                {`${formatMessage({ id: 'replay.search.droneDetectedOn' })} `}
                <Text as="span" size="lg" color="neutral.200">
                  {formatDate(detection.startTime, DateTimeEnum.DATE)}
                </Text>
                {` ${formatMessage({ id: 'replay.search.at' })} `}
                <Text as="span" size="lg" color="neutral.200">
                  {formatDate(detection.startTime, DateTimeEnum.TIME)}
                </Text>
              </Text>
              {detectionDuration && (
                <Text size="lg" backgroundColor="neutral.900" paddingX={1} paddingY={0.5}>
                  <FormattedMessage
                    id="replay.search.duringMin"
                    values={{
                      duration: detectionDuration,
                    }}
                  />
                </Text>
              )}
            </HStack>
            <HStack height="50%" alignItems="center" gap={2}>
              <VStack width="184px" alignItems="start" gap="6px">
                <Text
                  backgroundColor={lightColor}
                  color={detection.worstClassification === ClassificationEnum.ENEMY ? 'neutral.white' : 'neutral.900'}
                  paddingX={0.5}
                  paddingBottom="1px"
                  size="lg"
                >
                  <FormattedMessage id={`target.classification.${detection.worstClassification}`} />
                </Text>
                <Text height="15px" size="lg">
                  {
                    //TODO missing information, remove height="15px" when done
                  }
                </Text>
              </VStack>
              <VStack width="184px" alignItems="start" gap="6px">
                <Text
                  paddingX={0.5}
                  paddingBottom="1px"
                  backgroundColor="alertHi.900"
                  fontSize="12px"
                  fontWeight="medium"
                  noOfLines={1}
                >
                  {platformsNames || formatMessage({ id: 'components.alert.noPlatform' })}
                </Text>
                <Text height="15px">
                  {
                    //TODO missing informations
                  }
                </Text>
              </VStack>
              <VStack width="184px" alignItems="start">
                <Text
                  paddingX={0.5}
                  paddingBottom="1px"
                  backgroundColor="alertHi.900"
                  fontSize="12px"
                  fontWeight="medium"
                  noOfLines={1}
                  marginBottom="21px"
                >
                  {formatMessage({ id: 'components.alert.alertCount' }, { alertCount: detection.alertCount })}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </CardBody>
      </HStack>
    </Card>
  );
}

export default NotificationCard;
