import { FormatMessageType } from '@hooks/useValidation';
import { containsSubstring } from '@utils/common.utils';
import { EMAIL, REQUIRED_FIELD } from '@utils/validation/validation.constant';
import Yup from '@utils/validation/yup-extended';

export function newUserSchemaValidation(formatMessage: FormatMessageType) {
  return Yup.object({
    firstName: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.firstName' }) }),
    ),
    name: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.name' }) }),
    ),
    login: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.login' }) }),
    ),
    password: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.password' }) }),
    ),
    email: Yup.string()
      .email(formatMessage({ id: EMAIL }))
      .required(formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.email' }) })),
  });
}

export function userSchemaValidation(formatMessage: FormatMessageType) {
  return Yup.object({
    firstName: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.firstName' }) }),
    ),
    name: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.name' }) }),
    ),
    login: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.login' }) }),
    ),
    email: Yup.string()
      .email(formatMessage({ id: EMAIL }))
      .required(formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.user.email' }) })),
  });
}

const SPECIAL_CHARACTERS =
  '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬\u00ad®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗\'\'‚‛""„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾' as const;

export const PASSWORD_COMPOSITION_REGEX = new RegExp(
  `^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[${SPECIAL_CHARACTERS}]).*)$`,
);

export function changePassword(formatMessage: FormatMessageType) {
  return Yup.object({
    currentPassword: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.firstLogin.currentPassword' }) }),
    ),
    newPassword: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'authent.firstLogin.newPassword' }) }),
    ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword')], formatMessage({ id: 'authent.firstLogin.passwordMustMatch' }))
      .required(
        formatMessage(
          { id: REQUIRED_FIELD },
          { field: formatMessage({ id: 'authent.firstLogin.passwordConfirmation' }) },
        ),
      ),
  });
}

export function isValidRepetition(newPassword: string, values: string[]): boolean {
  return !values.some((value) => containsSubstring(newPassword, value));
}

export function isValidSize(newPassword: string, minimumSize = 14): boolean {
  return newPassword.length >= minimumSize;
}

export function isValidComposition(value: string): boolean {
  return PASSWORD_COMPOSITION_REGEX.test(value);
}
