import { ReactComponent as SliderThumbHorizontal } from '@assets/components/slider/slider-thumb-horizontal.svg';
import { ReactComponent as SliderThumbVertical } from '@assets/components/slider/slider-thumb-vertical.svg';
import { ReactComponent as TrackBottomActivated } from '@assets/components/slider/track-bottom-activated.svg';
import { ReactComponent as TrackLeft } from '@assets/components/slider/track-left.svg';
import { ReactComponent as TrackRight } from '@assets/components/slider/track-right.svg';
import { ReactComponent as TrackTop } from '@assets/components/slider/track-top.svg';
import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Image, Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

type Props = {
  displayedValue?: (value: number) => string;
  delay?: number;
  minLength?: string;
  value: number;
};

function CustomSlider({
  id,
  min = 0,
  max = 100,
  step = 1,
  value,
  orientation = 'vertical',
  minLength = '144px',
  delay = 500,
  displayedValue,
  onChange = () => undefined,
}: Readonly<SliderProps & Props>) {
  const [innerValue, setInnerValue] = useState<number>(value);
  const [previousValue, setPreviousValue] = useState<number>(value);

  const sliderGaugeThickness = '9px';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeEnd = useCallback(
    debounce((value) => {
      onChange(value);
    }, delay),
    [],
  );

  function updateValue(value: number) {
    setInnerValue(value);
    onChangeEnd(value);
  }

  if (previousValue !== value) {
    setInnerValue(value);
    setPreviousValue(value);
  }

  if (orientation === 'horizontal') {
    return (
      <HStack gap="13px" justifyContent="start" width="100%">
        <HStack gap={0} justifyContent="center" height="42px" width="100%">
          <Image as={TrackLeft} height={sliderGaugeThickness} width="4px" />
          <Slider
            colorScheme="transparent"
            id={id}
            aria-label="slider-ex-3"
            value={innerValue}
            orientation="horizontal"
            minWidth={`calc(${minLength} - ${displayedValue ? '24px' : 0})`}
            min={min}
            max={max}
            step={step}
            onChange={(value) => updateValue(value)}
          >
            <SliderTrack borderRadius="none" height={sliderGaugeThickness} backgroundColor="neutral.700">
              <SliderFilledTrack>
                <Center backgroundColor="cyber.500" height={sliderGaugeThickness}>
                  <Box backgroundColor="neutral.700" height="1px" width="100%"></Box>
                </Center>
              </SliderFilledTrack>
            </SliderTrack>
            <SliderThumb borderRadius={0} width="8px">
              <Flex>
                <Image as={SliderThumbVertical} style={{ filter: 'drop-shadow( -4px 0px 4px rgba(0, 0, 0, 0.50)' }} />
              </Flex>
            </SliderThumb>
          </Slider>
          <Image as={TrackRight} height={sliderGaugeThickness} width="4px" />
        </HStack>
        {displayedValue && (
          <Text textColor="neutral.300" variant="space" minWidth="37px" whiteSpace="nowrap">
            {displayedValue(innerValue)}
          </Text>
        )}
      </HStack>
    );
  }

  return (
    <VStack gap={1}>
      <VStack gap={0}>
        <Image as={TrackTop} width={sliderGaugeThickness} height="4px" />
        <Slider
          colorScheme="transparent"
          id={id}
          aria-label="slider-ex-3"
          value={value}
          orientation="vertical"
          minHeight={minLength}
          min={min}
          max={max}
          onChange={(value) => updateValue(value)}
        >
          <SliderTrack borderRadius="none" width={sliderGaugeThickness} backgroundColor="neutral.700">
            <SliderFilledTrack>
              <Center backgroundColor="cyber.500" height={minLength} width="9px">
                <Box backgroundColor="neutral.700" width="1px" height="100%"></Box>
              </Center>
            </SliderFilledTrack>
          </SliderTrack>
          <SliderThumb borderRadius={0} height="8px">
            <Flex>
              <Image as={SliderThumbHorizontal} style={{ filter: 'drop-shadow( 0px 4px 4px rgba(0, 0, 0, 0.50)' }} />
            </Flex>
          </SliderThumb>
        </Slider>
        <Image as={TrackBottomActivated} width={sliderGaugeThickness} height="4px" />
      </VStack>
      {displayedValue && (
        <Text textColor="neutral.300" variant="space">
          {displayedValue(innerValue)}
        </Text>
      )}
    </VStack>
  );
}

export default CustomSlider;
