import { ReactComponent as InfoIcon } from '@assets/icons/16x16/ic-info-16.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, Icon, Text } from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { useResetSituationMutation } from '@services/c2/c2.api';
import { createErrorMessage } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
};

export default function ResetSituationPopup({ top, left, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [resetSituation, { isLoading, isError, error }] = useResetSituationMutation();

  const { activeSite } = useAppSelector(selectActiveUser);

  return (
    <DraggableControl
      label={formatMessage({ id: 'situationReset.title' })}
      width={648}
      height={340}
      top={top}
      left={left}
      onClose={onClose}
    >
      <VStack
        backgroundColor="neutral.700"
        width="100%"
        height="100%"
        padding={4}
        gap={4}
        alignItems="start"
        userSelect="none"
      >
        <Text width="100%" size="lg" whiteSpace="pre-line">
          <FormattedMessage id="situationReset.description" />
        </Text>
        <HStack gap={2}>
          <Icon as={InfoIcon} color="neutral.white" />
          <Text fontSize="16px">
            <FormattedMessage id="global.unrecoverableAction" />
          </Text>
        </HStack>
        <Box height="40px">
          <ErrorsContainer errors={{}} touched={{}} apiError={isError ? createErrorMessage(error) : undefined} />
        </Box>
        <HStack width="100%" gap={2} justifyContent="center">
          <Box borderWidth="2px" borderColor="cyber.700">
            <Button variant="formButtonSecondary" height="48px" fontSize="18px" fontWeight="regular" onClick={onClose}>
              <FormattedMessage id="global.cancel" />
            </Button>
          </Box>
          <Box borderWidth="2px" borderColor="cyber.700">
            <Button
              variant="formButtonPrimary"
              height="48px"
              fontSize="18px"
              fontWeight="regular"
              isDisabled={isLoading || isError}
              isLoading={isLoading}
              onClick={() =>
                activeSite &&
                resetSituation([activeSite.code])
                  .unwrap()
                  .then(() => {
                    createToast(formatMessage({ id: 'situationReset.successToast' }), ToastStatusEnum.SUCCESS);
                    onClose();
                  })
              }
            >
              <FormattedMessage id="situationReset.confirm" />
            </Button>
          </Box>
        </HStack>
      </VStack>
    </DraggableControl>
  );
}
