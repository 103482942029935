import { ReactComponent as Corner } from '@assets/components/panel/chat/chat-message-corner.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text } from '@chakra-ui/react';
import { formatDate } from '@utils/date.utils';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Message } from '@/types/chat/chat.types';
import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  activeUserId: number;
  message: Message;
};

function ChatMessage({ activeUserId, message }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const isAuthor = message.author?.id === activeUserId;
  const color = isAuthor ? 'neutral.600' : 'cyber.700';
  return (
    <HStack
      gap={0}
      alignItems="end"
      justifyContent={isAuthor ? 'end' : 'start'}
      paddingBottom={1.5}
      width="100%"
      height="fit-content"
      key={message.id}
    >
      {!isAuthor && <Icon as={Corner} color={color} marginBottom={0.5} />}
      <VStack
        width="377px"
        border="4px solid"
        borderRadius="10px"
        backgroundColor={color}
        paddingX={1}
        style={{
          borderImage: `1 url(${isAuthor ? '/images/author-border.svg' : '/images/other-border.svg'})`,
        }}
        paddingY={1}
        gap={1}
        alignItems="start"
      >
        <HStack justifyContent="space-between" width="100%">
          {isAuthor ? (
            <Text color="neutral.300" fontWeight="medium">
              <FormattedMessage id="components.chat.me" />
            </Text>
          ) : (
            <Text color="sky.500" fontWeight="medium">
              {capitalize(
                message.author?.name.toLocaleLowerCase() ?? formatMessage({ id: 'authent.user.unknownUser' }),
              )}
            </Text>
          )}
          <HStack gap={1}>
            <Text fontSize="12px" fontWeight="medium" userSelect="none">
              {format(new Date(message.time), "HH'h'mm")}
            </Text>
            <Text fontSize="12px" color="neutral.200" userSelect="none">
              {formatDate(message.time, DateTimeEnum.DATE)}
            </Text>
          </HStack>
        </HStack>
        <Text whiteSpace="break-spaces" width="100%" textAlign="left">
          {message.msg}
        </Text>
      </VStack>
      {isAuthor && <Icon as={Corner} color={color} transform="scaleX(-1)" marginBottom={0.5} />}
    </HStack>
  );
}

export default memo(ChatMessage);
