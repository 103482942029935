import {
  groupsAdapter,
  profilesAdapter,
  rolesAdapter,
  sitesAdapter,
  usersAdapter,
} from '@redux/authent/authent.adapters';
import { authentSchema, groupSchema, profileSchema, userSchema } from '@redux/authent/authent.schema';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { normalizeOne } from '@utils/schema.utils';
import { normalize } from 'normalizr';

import { GroupWithUsers, NormalizedGroup, NormalizedProfile, Profile } from '@/types/authent/groups.types';
import { AuthentSettings, NormalizedUser, User } from '@/types/authent/login.types';
import { Role } from '@/types/authent/roles.types';
import { Site } from '@/types/authent/sites.types';

const emptyUser = {
  id: -1,
  name: '',
  firstName: '',
  email: '',
  login: '',
  password: '',
  active: false,
  deactivationDate: null,
  sites: [],
  groups: [],
  activeGroup: null,
  activeSite: null,
  createdBy: null,
  creationTime: null,
  modifiedBy: null,
  modificationTime: null,
};

export type AuthentState = {
  activeUser: User;
  token: string | null;
  renewalToken: string | null;
  sessionUuid: string | null;
  roles: EntityState<Role, number>;
  profiles: EntityState<NormalizedProfile, number>;
  sites: EntityState<Site, string>;
  users: EntityState<NormalizedUser, number>;
  groups: EntityState<NormalizedGroup, string>;
  authentSettings: AuthentSettings | null;
  hasBeenInitialized: boolean;
  hasSelectedGroup: boolean;
  isChangingShift: boolean;
};

export const AUTHENT_INITIAL_STATE: AuthentState = {
  activeUser: emptyUser,
  token: null,
  renewalToken: null,
  sessionUuid: null,
  roles: rolesAdapter.getInitialState(),
  profiles: profilesAdapter.getInitialState(),
  sites: sitesAdapter.getInitialState(),
  users: usersAdapter.getInitialState(),
  groups: groupsAdapter.getInitialState(),
  authentSettings: null,
  hasBeenInitialized: false,
  hasSelectedGroup: false,
  isChangingShift: false,
};

const authent = createSlice({
  name: 'authent',
  initialState: AUTHENT_INITIAL_STATE,
  reducers: {
    userLogout(_, action: PayloadAction<Partial<AuthentState> | undefined>) {
      return { ...AUTHENT_INITIAL_STATE, ...action.payload };
    },
    updateToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    updateRenewalToken(state, action: PayloadAction<string | null>) {
      state.renewalToken = action.payload;
    },
    updateSessionUuid(state, action: PayloadAction<string | null>) {
      state.sessionUuid = action.payload;
    },
    updateActiveUser(state, action: PayloadAction<User>) {
      state.activeUser = action.payload;
    },
    updateHasBeenInitialized(state, action: PayloadAction<boolean>) {
      state.hasBeenInitialized = action.payload;
    },
    updateHasSelectedGroup(state, action: PayloadAction<boolean>) {
      state.hasSelectedGroup = action.payload;
    },
    updateIsChangingShift(state, action: PayloadAction<boolean>) {
      state.isChangingShift = action.payload;
    },
    updateAuthent: (
      state,
      action: PayloadAction<{
        profiles: Profile[];
        users: User[];
        roles: Role[];
        sites: Site[];
        authentSettings: AuthentSettings | null;
        groups: GroupWithUsers[];
      }>,
    ) => {
      const { roles, sites, authentSettings } = action.payload;
      const { profiles, users, groups } = normalize(action.payload, authentSchema).entities as unknown as {
        profiles?: NormalizedProfile[];
        users?: NormalizedUser[];
        groups?: NormalizedGroup[];
      };

      rolesAdapter.setAll(state.roles, roles);
      profilesAdapter.setAll(state.profiles, profiles ?? []);
      sitesAdapter.setAll(state.sites, sites);
      usersAdapter.setAll(state.users, users ?? []);
      groupsAdapter.setAll(state.groups, groups ?? []);
      state.authentSettings = authentSettings;
    },
    addProfile: ({ profiles }, action: PayloadAction<Profile>) => {
      const normalizedProfile = normalizeOne(action.payload, profileSchema);
      normalizedProfile && profilesAdapter.addOne(profiles, normalizedProfile as NormalizedProfile);
    },
    updateProfile: ({ profiles }, action: PayloadAction<Profile>) => {
      const normalizedProfile = normalizeOne(action.payload, profileSchema);
      normalizedProfile && profilesAdapter.upsertOne(profiles, normalizedProfile as NormalizedProfile);
    },
    deleteProfile: ({ profiles }, action: PayloadAction<Profile>) => {
      profilesAdapter.removeOne(profiles, action.payload.id);
    },
    addSite: ({ sites }, action: PayloadAction<Site>) => {
      sitesAdapter.addOne(sites, action.payload);
    },
    updateSite: ({ sites }, action: PayloadAction<Site>) => {
      sitesAdapter.upsertOne(sites, action.payload);
    },
    deleteSite: ({ sites }, action: PayloadAction<Site>) => {
      sitesAdapter.removeOne(sites, action.payload.code);
    },
    addUser: ({ users }, action: PayloadAction<User>) => {
      const normalizedUser = normalizeOne(action.payload, userSchema);
      normalizedUser && usersAdapter.addOne(users, normalizedUser as NormalizedUser);
    },
    updateUser: ({ users }, action: PayloadAction<User>) => {
      const normalizedUser = normalizeOne(action.payload, userSchema);
      normalizedUser && usersAdapter.upsertOne(users, normalizedUser as NormalizedUser);
    },
    deleteUser: ({ users }, action: PayloadAction<User>) => {
      usersAdapter.removeOne(users, action.payload.id);
    },
    addGroup: ({ groups }, action: PayloadAction<GroupWithUsers>) => {
      const normalizedGroup = normalizeOne(action.payload, groupSchema);
      normalizedGroup && groupsAdapter.addOne(groups, normalizedGroup as NormalizedGroup);
    },
    updateGroup: ({ groups }, action: PayloadAction<GroupWithUsers>) => {
      const normalizedGroup = normalizeOne(action.payload, groupSchema);
      normalizedGroup && groupsAdapter.upsertOne(groups, normalizedGroup as NormalizedGroup);
    },
    deleteGroup: ({ groups }, action: PayloadAction<GroupWithUsers>) => {
      groupsAdapter.removeOne(groups, action.payload.code);
    },
    updateAuthentSettings(state, action: PayloadAction<AuthentSettings>) {
      state.authentSettings = action.payload;
    },
  },
});

export const {
  userLogout,
  updateHasBeenInitialized,
  updateActiveUser,
  updateToken,
  updateRenewalToken,
  updateSessionUuid,
  updateAuthent,
  addProfile,
  updateProfile,
  deleteProfile,
  addSite,
  updateSite,
  deleteSite,
  addUser,
  updateUser,
  deleteUser,
  addGroup,
  updateGroup,
  deleteGroup,
  updateAuthentSettings,
  updateHasSelectedGroup,
  updateIsChangingShift,
} = authent.actions;

export default authent.reducer;
