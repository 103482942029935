import { labels, lines, points } from '@components/map/layers/draw/measure/measure.layer';
import { toMeasureFeatures } from '@components/map/layers/draw/measure/measure.mapper';
import { useMapMeasure } from '@hooks/useMapMeasure';
import { FeatureCollection } from 'geojson';
import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export function MeasureLayer() {
  const [mousePosition, measurePoints] = useMapMeasure();

  const measures: FeatureCollection | null = useMemo(
    () => mousePosition && toMeasureFeatures(measurePoints, [mousePosition.longitude, mousePosition.latitude]),
    [mousePosition, measurePoints],
  );

  if (!measures) {
    return;
  }

  return (
    <Source id={SourceNameEnum.MEASURES} type="geojson" data={measures} generateId>
      <Layer key={LayerNameEnum.MEASURE_LINES} {...lines} />
      <Layer key={LayerNameEnum.MEASURE_POINTS} {...points} />
      <Layer key={LayerNameEnum.MEASURE_POINT_LABELS} {...labels} />
    </Source>
  );
}
