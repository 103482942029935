import { DroneClearance, NormalizedDrone } from '@/types/utm/drone.types';

export function getDroneClearanceLabel(drone?: NormalizedDrone): string {
  switch (drone?.clearance) {
    case DroneClearance.FULL_CLEARANCE:
      return DroneClearance.FULL_CLEARANCE;
    case DroneClearance.NO_CLEARANCE:
      return DroneClearance.NO_CLEARANCE;
    default:
      return 'Uncategorized';
  }
}
