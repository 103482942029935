import { getScaledValue, modulo } from '@utils/math.utils';
import { inRange } from 'lodash';

import { Target } from '@/types/c2/c2.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

export type TargetInfo = {
  target: Target<TargetLocation>;
  angle: number;
  isInRange: boolean;
};

export enum CardinalEnum {
  NORTH = 'north',
  EAST = 'east',
  SOUTH = 'south',
  WEST = 'west',
}

export function getHorizontalFovStyle(azimuth: number, fovX: number, element: HTMLDivElement) {
  const upperFov = azimuth + fovX / 2;
  const lowerFov = azimuth - fovX / 2;
  const fovUpperPosition = getScaledValue(-180, 540, upperFov, element.clientWidth);
  const fovLowerPosition = getScaledValue(-180, 540, lowerFov, element.clientWidth);
  const fovWidth = fovUpperPosition - fovLowerPosition;

  return {
    width: fovWidth,
    left: fovLowerPosition - 2,
  };
}

export function getLabel(value: number) {
  switch (modulo(value, 360)) {
    case 0:
      return CardinalEnum.NORTH;
    case 90:
      return CardinalEnum.EAST;
    case 180:
      return CardinalEnum.SOUTH;
    case 270:
      return CardinalEnum.WEST;
    default:
      return null;
  }
}

export function formatDegree(value: number): string {
  return value > 0 ? `+${value.toFixed(2)}°` : `${value.toFixed(2)}°`;
}

/* Return a list of object with property needed to create a div to show on the gauge, each object containe :
    - index : the index of the step
    - smallBar : a boolean, true if the div show a smallBar
    - bigBar : a boolean, true if the div show a bigBar
    - size : the width of the div
    - margin : the margin right of the div if some div are skipped
    - label : the div label (used for cardinals)
    - targets : a list of all targets contained in this div
*/
export function computeHorizontalStepValues(min: number, max: number, targetsWithInfo: TargetInfo[]) {
  const stepEvery = 1;
  const bigBarEvery = 30;
  const smallBarEvery = 10;
  const total = max - min;
  const firstStep = Math.floor((max - 1) / stepEvery) * stepEvery;
  const firstStepSize = (max - firstStep) / total;
  const stepSize = stepEvery / (100 - firstStepSize);
  const stepResults: {
    index: number;
    smallBar: boolean;
    bigBar: boolean;
    size: number;
    margin?: number;
    label: string | null;
    targets: TargetInfo[];
  }[] = [
    {
      index: max,
      smallBar: false,
      bigBar: false,
      size: firstStepSize,
      label: null,
      targets: targetsWithInfo.filter((target) => Math.floor(target.angle) === max),
    },
  ];
  let skip = 0;
  for (let i = firstStep; i > min; i -= stepEvery) {
    const isSmall = i % smallBarEvery === 0;
    const isBig = i % bigBarEvery === 0;
    const targets = targetsWithInfo.filter((target) => Math.floor(target.angle) === modulo(i, 360));
    if (!isSmall && !isBig && targets.length === 0) {
      skip++;
    } else {
      stepResults.push({
        index: i,
        smallBar: isSmall,
        bigBar: isBig,
        size: stepSize,
        margin: skip,
        label: getLabel(i),
        targets,
      });
      skip = 0;
    }
  }
  stepResults.push({
    index: min,
    smallBar: false,
    bigBar: false,
    size: (min - Math.ceil((min + 1) / bigBarEvery) * bigBarEvery) / total,
    margin: skip,
    label: null,
    targets: targetsWithInfo.filter((target) => Math.floor(target.angle) === min),
  });
  return stepResults;
}

//Compute needed div to display a vertical gauge with a certain decimal precision multiplier
export function computeVerticalStepValues(tilt: number, precisionMultiplier: number, numberOfInterval: number) {
  const numberOfGraduation = numberOfInterval - 1;
  const smallBarEvery = 5 * precisionMultiplier;
  const bigBarEvery = 15 * precisionMultiplier;
  const minTilt = -90 * precisionMultiplier;
  const maxTilt = 90 * precisionMultiplier;
  const firstStep = Math.floor((tilt + Math.ceil(numberOfGraduation / 2)) * precisionMultiplier);
  const lastStep = Math.ceil((tilt - Math.floor(numberOfGraduation / 2)) * precisionMultiplier);
  let skip = -1;

  const stepResults: {
    index: number;
    smallBar: boolean;
    bigBar: boolean;
    label: string | null;
    skip: number;
  }[] = [];
  for (let i = firstStep; i >= lastStep; i--) {
    const isInRange = inRange(i, minTilt, maxTilt + 1);
    const isBig = i % bigBarEvery === 0 && isInRange;
    const isSmall = i % smallBarEvery === 0 && isInRange && !isBig;
    if (!isBig && !isSmall && !(lastStep === i)) {
      skip++;
    } else {
      stepResults.push({
        index: i,
        smallBar: isSmall,
        bigBar: isBig,
        label: isBig ? formatDegree(i / precisionMultiplier) : null,
        skip: skip + 1,
      });
      skip = 0;
    }
  }
  return stepResults;
}

//Return the two closest cardinal left and right of the camera pan, assuming 179° are visible on the gauge
export function closestCardinal(pan: number): { closestLeftCardinal: string; closestRightCardinal: string } {
  const cardinals: string[] = [
    CardinalEnum.NORTH,
    CardinalEnum.EAST,
    CardinalEnum.SOUTH,
    CardinalEnum.WEST,
    CardinalEnum.NORTH,
  ];

  // Calculate the index of the closest cardinal to the left and to the right
  const leftIndex: number = Math.floor(modulo(pan - 90, 360) / 90);
  const rightIndex: number = Math.ceil(modulo(pan + 90, 360) / 90);

  return { closestLeftCardinal: cardinals[leftIndex], closestRightCardinal: cardinals[rightIndex] };
}

export function translateTargetAngle<T extends TargetInfo | null>(target: T, value: number): T {
  if (target === null) {
    return target;
  }
  return { ...target, angle: +modulo(target.angle + value, 360).toFixed(1) };
}

//Return the two closest targets left and right of the camera pan, assuming 179° are visible on the gauge
export function findClosestHorizontalTargets(
  targets: TargetInfo[],
  pan: number,
  selectedTargetId?: string,
): { closestLeftTarget: TargetInfo | null; closestRightTarget: TargetInfo | null } {
  const notInRangeTargets = targets
    .filter((target) => !target.isInRange)
    .map((target) => translateTargetAngle(target, 180 - pan));

  const { closestLeftTarget, closestRightTarget } = notInRangeTargets.reduce(
    (acc, curr) => {
      if (
        curr.angle < 180 &&
        (!acc.closestLeftTarget ||
          curr.target.id === selectedTargetId ||
          (acc.closestLeftTarget.target.id !== selectedTargetId && curr.angle > acc.closestLeftTarget.angle))
      ) {
        return { ...acc, closestLeftTarget: curr };
      }
      if (
        curr.angle > 180 &&
        (!acc.closestRightTarget ||
          curr.target.id === selectedTargetId ||
          (acc.closestRightTarget.target.id !== selectedTargetId && curr.angle < acc.closestRightTarget.angle))
      ) {
        return { ...acc, closestRightTarget: curr };
      }
      return acc;
    },
    { closestLeftTarget: null, closestRightTarget: null } as {
      closestLeftTarget: TargetInfo | null;
      closestRightTarget: TargetInfo | null;
    },
  );
  return {
    closestLeftTarget: translateTargetAngle(closestLeftTarget, pan - 180),
    closestRightTarget: translateTargetAngle(closestRightTarget, pan - 180),
  };
}

export function filterTargetsKeepVerticalClosest(
  targets: TargetInfo[],
  tilt: number,
  selectedTargetId?: string,
): TargetInfo[] {
  const notInRangeTargets = targets.filter((target) => !target.isInRange);

  const { closestTopTarget, closestBottomTarget } = notInRangeTargets.reduce(
    (acc, curr) => {
      if (
        curr.angle > tilt &&
        (!acc.closestTopTarget ||
          curr.target.id === selectedTargetId ||
          (acc.closestTopTarget.target.id !== selectedTargetId && curr.angle < acc.closestTopTarget.angle))
      ) {
        return { ...acc, closestTopTarget: curr };
      }
      if (
        curr.angle < tilt &&
        (!acc.closestBottomTarget ||
          curr.target.id === selectedTargetId ||
          (acc.closestBottomTarget.target.id !== selectedTargetId && curr.angle > acc.closestBottomTarget.angle))
      ) {
        return { ...acc, closestBottomTarget: curr };
      }
      return acc;
    },
    { closestTopTarget: null, closestBottomTarget: null } as {
      closestTopTarget: TargetInfo | null;
      closestBottomTarget: TargetInfo | null;
    },
  );

  return targets
    .filter((target) => target.isInRange)
    .concat(closestBottomTarget ?? [])
    .concat(closestTopTarget ?? []);
}

export function verticalTargetOffset(
  height: number,
  tilt: number,
  numberOfInterval: number,
  target: TargetInfo,
): string {
  if (target.isInRange) {
    return ((height / numberOfInterval) * (target.angle - tilt)).toFixed(1);
  }
  const offset = height / 2 + 20;
  return (target.angle > tilt ? offset : -offset).toFixed(1);
}

export function isValueInRange(value: number, start: number, end: number): boolean {
  if (start < end) {
    return value >= start && value <= end;
  } else {
    return value >= start || value <= end;
  }
}

export function getPositionOnCircle(degree: number, radius: number, center: { x: number; y: number }, inset = 0) {
  const radian = (degree * Math.PI) / 180;
  const adjustedRadius = radius - inset;
  const x = Math.round(center.x + adjustedRadius * Math.cos(radian));
  const y = Math.round(center.y + adjustedRadius * Math.sin(radian));
  return { x, y };
}
