import { VStack } from '@chakra-ui/layout';
import { TabPanel, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export default function TargetUtmInfos() {
  return (
    <TabPanel>
      <VStack gap={0.25} backgroundColor="neutral.900">
        <VStack padding={4} gap={1} backgroundColor="neutral.800" width="100%" alignItems="start">
          <Text size="md" color="neutral.300">
            <FormattedMessage id="targetInfos.flightplan" />
          </Text>
          <Text size="md" color="neutral.white">
            <FormattedMessage id="targetInfos.noFlightplan" />
          </Text>
        </VStack>
      </VStack>
    </TabPanel>
  );
}
