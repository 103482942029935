import { equipmentsAdapter } from '@redux/sensors/equipmentsAdapter';
import { perimeterCamerasAdapter } from '@redux/sensors/perimeterCameras.adapters';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';

import { EquipmentConfiguration, SensorConfiguration } from '@/types/sensor/configuration.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import { SensorStorageConfiguration } from '@/types/sensor/storage.types';

import { userLogout } from '../authent/authent.reducer';
import { sensorsAdapter } from './sensors.adapters';

export type SensorsState = {
  appCodes: string[];
  sensors: EntityState<SensorConfiguration, string>;
  equipments: EntityState<EquipmentConfiguration, string>;
  perimeterCameras: EntityState<PerimeterCamera, string>;
  sensorStorage: SensorStorageConfiguration | null;
};

export const SENSORS_INITIAL_STATE: SensorsState = {
  appCodes: [],
  sensors: sensorsAdapter.getInitialState(),
  equipments: equipmentsAdapter.getInitialState(),
  perimeterCameras: perimeterCamerasAdapter.getInitialState(),
  sensorStorage: null,
};

const sensorsReducers = createSlice({
  name: 'sensors',
  initialState: SENSORS_INITIAL_STATE,
  reducers: {
    updateSensors(
      state,
      action: PayloadAction<{
        sensors: SensorConfiguration[];
        perimeterCameras: PerimeterCamera[];
        equipments: EquipmentConfiguration[];
        sensorStorage: SensorStorageConfiguration | null;
      }>,
    ) {
      const { sensors, perimeterCameras, equipments, sensorStorage } = action.payload;
      state.sensors = sensorsAdapter.setAll(state.sensors, sensors);
      state.perimeterCameras = perimeterCamerasAdapter.setAll(state.perimeterCameras, perimeterCameras);
      state.equipments = equipmentsAdapter.setAll(state.equipments, equipments);
      state.sensorStorage = sensorStorage;
    },
    updateSensorsByAppCode(
      state,
      action: PayloadAction<{
        sensors: SensorConfiguration[];
        perimeterCameras: PerimeterCamera[];
        equipments: EquipmentConfiguration[];
        sensorStorage: SensorStorageConfiguration | null;
      }>,
    ) {
      const { sensors, perimeterCameras, equipments, sensorStorage } = action.payload;
      state.sensors = sensorsAdapter.upsertMany(state.sensors, sensors);
      state.perimeterCameras = perimeterCamerasAdapter.upsertMany(state.perimeterCameras, perimeterCameras);
      state.equipments = equipmentsAdapter.upsertMany(state.equipments, equipments);
      state.sensorStorage = sensorStorage;
    },
    addSensor: ({ sensors }, action: PayloadAction<SensorConfiguration>) => {
      sensorsAdapter.addOne(sensors, action.payload);
    },
    updateSensor: ({ sensors }, action: PayloadAction<SensorConfiguration>) => {
      sensorsAdapter.upsertOne(sensors, action.payload);
    },
    deleteSensor: ({ sensors }, action: PayloadAction<SensorConfiguration>) => {
      sensorsAdapter.removeOne(sensors, getSensorUniqueCode(action.payload));
    },
    addPerimeterCamera: ({ perimeterCameras }, action: PayloadAction<PerimeterCamera>) => {
      perimeterCamerasAdapter.addOne(perimeterCameras, action.payload);
    },
    updatePerimeterCamera: ({ perimeterCameras }, action: PayloadAction<PerimeterCamera>) => {
      perimeterCamerasAdapter.upsertOne(perimeterCameras, action.payload);
    },
    deletePerimeterCamera: ({ perimeterCameras }, action: PayloadAction<PerimeterCamera>) => {
      perimeterCamerasAdapter.removeOne(perimeterCameras, action.payload.code);
    },
    addEquipmentConfiguration: ({ equipments }, action: PayloadAction<EquipmentConfiguration>) => {
      equipmentsAdapter.addOne(equipments, action.payload);
    },
    updateEquipmentConfiguration: ({ equipments }, action: PayloadAction<EquipmentConfiguration>) => {
      equipmentsAdapter.upsertOne(equipments, action.payload);
    },
    deleteEquipmentConfiguration: ({ equipments }, action: PayloadAction<EquipmentConfiguration>) => {
      equipmentsAdapter.removeOne(equipments, action.payload.code);
    },
    updateSensorStorage(state, action: PayloadAction<SensorStorageConfiguration>) {
      state.sensorStorage = action.payload;
    },
    updateAppCodes(state, action: PayloadAction<string[]>) {
      state.appCodes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => SENSORS_INITIAL_STATE);
  },
});

export default sensorsReducers.reducer;

export const {
  addSensor,
  deleteSensor,
  updateAppCodes,
  updateSensor,
  updateSensorStorage,
  updateSensors,
  updateSensorsByAppCode,
  addPerimeterCamera,
  updatePerimeterCamera,
  deletePerimeterCamera,
  addEquipmentConfiguration,
  updateEquipmentConfiguration,
  deleteEquipmentConfiguration,
} = sensorsReducers.actions;
