import { Box } from '@chakra-ui/layout';
import { memo } from 'react';

type Props = {
  hasControl: boolean;
};

function CameraBorder({ hasControl }: Readonly<Props>) {
  function getColor() {
    if (hasControl) {
      return 'alertMid.500';
    }
    return 'transparent';
  }

  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      border="8px solid"
      borderColor={getColor()}
      zIndex={1}
      opacity={0.85}
      pointerEvents="none"
    />
  );
}

export default memo(CameraBorder);
