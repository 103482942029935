import { ReactComponent as AirportIcon } from '@assets/icons/24x24/ic-airport-24.svg';
import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import DropSelect from '@components/common/menu/DropSelect';

import { PlatformSearch } from './Search';

type Props = {
  platforms: PlatformSearch;
  onChange: (value: PlatformSearch) => void;
};

function Header({ platforms, onChange }: Readonly<Props>) {
  return (
    <HStack justifyContent="start" width="100%" gap={0.25} padding={3} backgroundColor="cyber.900">
      <DropSelect
        icon={AirportIcon}
        values={new Set(platforms.filter((platform) => platform.active).map((platform) => platform.platform.code))}
        options={[...platforms].map((value) => ({
          value: value.platform.code,
          label: value.platform.name,
        }))}
        onChange={(values) =>
          onChange(
            platforms.map((platform) => ({
              platform: platform.platform,
              active: values.has(platform.platform.code),
            })),
          )
        }
      />
      <CockpitCustomScrollbar>
        <HStack gap={0.25}>
          {platforms
            .filter((platform) => platform.active)
            .map((platform) => (
              <Text
                paddingX={2}
                key={platform.platform.code}
                paddingY="11px"
                backgroundColor="neutral.black"
                flexShrink={0}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {platform.platform.name}
              </Text>
            ))}
        </HStack>
      </CockpitCustomScrollbar>
    </HStack>
  );
}

export default Header;
