import { Box } from '@chakra-ui/layout';
import Scrollbars from 'rc-scrollbars';
import { PropsWithChildren } from 'react';
type MapCustomScrollbarProps = {
  height?: number | string;
  marginTop?: number;
  marginBottom?: number;
  margin?: number;
  thumbColor?: string;
};

export function MapCustomScrollbar({
  height = 400,
  marginTop = 0.5,
  marginBottom = 0.5,
  margin = 0.5,
  thumbColor = 'neutral.500',
  children,
}: Readonly<PropsWithChildren<MapCustomScrollbarProps>>) {
  return (
    <Scrollbars
      disableDefaultStyles
      renderTrackVertical={(props) => (
        <Box
          {...props}
          position="absolute"
          width="8px"
          height={`calc(100% - ${(marginBottom + marginTop) * 8}px)`}
          right={0}
          top={0}
          margin={margin}
          marginTop={marginTop}
          marginBottom={marginBottom}
          backgroundColor="transparent"
        />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <Box style={{ ...style }} width="8px" backgroundColor={thumbColor} {...props} />
      )}
      style={{ flexGrow: 0, overflow: 'hidden', backgroundColor: 'transparent', height }}
    >
      <Box width="100%" height="100%" position="relative">
        {children}
      </Box>
    </Scrollbars>
  );
}
