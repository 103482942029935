import { ReactComponent as CameraIcon24 } from '@assets/icons/24x24/ic-camera-24.svg';
import { ReactComponent as PerimeterAlertIcon } from '@assets/icons/54x54/sensor/effraction-M.svg';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Spinner, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { updateSelectedDoubtCheckSegmentName } from '@redux/global/global.reducer';
import { usePatchContextMutation } from '@services/config/setting.api';
import { useAcknowledgeNotificationMutation } from '@services/data/notification.api';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { AlertLevelEnum, PerimeterEffractionAlert } from '@/types/data/data.types';

import AlertTimeToast from './AlertTimeToast';

interface OwnProps {
  alert: PerimeterEffractionAlert;
  currentDate: Date;
  onClose: () => void;
}

export default function PerimeterEffractionAlertToast({ alert, currentDate, onClose }: Readonly<OwnProps>) {
  const [patchContext, { isLoading }] = usePatchContextMutation();
  const { login } = useAppSelector(selectActiveUser);
  const updateSelectedSegmentName = useWithDispatch(updateSelectedDoubtCheckSegmentName);
  const [acknowledge] = useAcknowledgeNotificationMutation();

  const [isLoadingAcknowledge, setIsLoadingAcknowledge] = useState(false);

  useEffect(() => {
    if (isLoading && alert.ack) {
      setIsLoadingAcknowledge(false);
      patchContext({ login: login, context: ContextEnum.DOUBT_PERIM });
      onClose();
    }
  }, [alert.ack, isLoading, login, onClose, patchContext]);

  function onAckSubmit() {
    if (!isLoadingAcknowledge) {
      setIsLoadingAcknowledge(true);
      updateSelectedSegmentName(alert.segmentName);
      acknowledge(alert).catch(() => {
        setIsLoadingAcknowledge(false);
        onClose();
      });
    }
  }

  return (
    <>
      <HStack
        gap={2}
        width="100%"
        padding={2}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 4 : 3}
        backgroundColor="neutral.white"
      >
        <Icon as={PerimeterAlertIcon} boxSize="54px" />
        <VStack alignItems="start" gap={0.5}>
          <Text size="md" color="neutral.black">
            <FormattedMessage id="notification.perimeterIntrusion" />
          </Text>
          <AlertTimeToast startTime={alert.startTime} endTime={alert.endTime} currentDate={currentDate} />
        </VStack>
      </HStack>
      <VStack
        width="100%"
        backgroundColor="neutral.100"
        alignItems="start"
        paddingTop={2}
        paddingRight={3}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 5 : 4}
      >
        <Text fontSize="12px" fontWeight="medium" color="neutral.600">
          {alert.identifier}
        </Text>
        <HStack gap={2}>
          <Text fontSize="12px" fontWeight="medium" color="neutral.600">
            {alert.cabinetName}
          </Text>
          <Text fontSize="12px" fontWeight="medium" color="neutral.600">
            {alert.segmentName}
          </Text>
        </HStack>
      </VStack>
      <Center
        width="100%"
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 5 : 4}
        paddingRight={3}
        paddingTop={2}
      >
        <HStack
          paddingX={3}
          gap={1.5}
          width="100%"
          height="52px"
          backgroundColor="neutral.800"
          border="2px"
          borderColor="neutral.200"
          boxShadow="inset 0 0 0 2px var(--chakra-colors-neutral-900)"
          cursor="pointer"
          onClick={onAckSubmit}
        >
          {isLoading || isLoadingAcknowledge ? (
            <Spinner color="neutral.white" marginLeft="auto" marginRight="auto" />
          ) : (
            <>
              <Icon as={CameraIcon24} width="24px" height="24px" color="neutral.300" marginLeft={2} />
              <Text fontSize="16px" variant="space" whiteSpace="pre-line">
                <FormattedMessage id="notification.acknowledgeAndShow" />
              </Text>
            </>
          )}
        </HStack>
      </Center>
    </>
  );
}
