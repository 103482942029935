import { Box, Text, VStack } from '@chakra-ui/layout';

export type ElementInfo<E> = {
  element: E;
  status: 'selected' | 'normal' | 'unselectable';
};

type Props<E> = {
  elementInfos: ElementInfo<E>[];
  getId: (element: E) => string | number;
  getLabel: (element: E) => string;
  multiSelect?: boolean;
  isDisabled?: boolean;
  onSelect: (elementInfos: ElementInfo<E>[]) => void;
};

export default function SelectStack<E>({
  elementInfos,
  getId,
  getLabel,
  multiSelect = true,
  isDisabled = false,
  onSelect,
}: Readonly<Props<E>>) {
  function handleElementClick(elementInfo: ElementInfo<E>) {
    const selectedElementInfos = new Set(elementInfos.filter((elementInfo) => elementInfo.status === 'selected'));
    switch (elementInfo.status) {
      case 'normal': {
        if (!multiSelect) {
          selectedElementInfos.clear();
        }
        selectedElementInfos.add(elementInfo);
        break;
      }
      case 'selected': {
        selectedElementInfos.delete(elementInfo);
        break;
      }
      case 'unselectable': {
        return;
      }
    }
    const newElementInfos: ElementInfo<E>[] = [...elementInfos].map((elementInfo) => {
      if (elementInfo.status === 'unselectable') {
        return elementInfo;
      }
      if (selectedElementInfos.has(elementInfo)) {
        return { ...elementInfo, status: 'selected' };
      }
      return { ...elementInfo, status: 'normal' };
    });
    onSelect(newElementInfos);
  }

  return (
    <VStack
      width="300px"
      border="1px solid"
      padding={0.5}
      alignItems="start"
      gap={0.25}
      height="200px"
      overflowY="auto"
    >
      {elementInfos.map((elementInfo) => (
        <Box
          key={getId(elementInfo.element)}
          pointerEvents="all"
          cursor={elementInfo.status === 'unselectable' || isDisabled ? 'default' : 'pointer'}
          onClick={() => {
            if (elementInfo.status !== 'unselectable' && !isDisabled) {
              handleElementClick(elementInfo);
            }
          }}
        >
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            color={elementInfo.status === 'unselectable' || isDisabled ? 'neutral.300' : 'neutral.black'}
            fontWeight={elementInfo.status === 'selected' ? '700' : 'regular'}
            paddingX={2}
          >
            {getLabel(elementInfo.element)}
          </Text>
        </Box>
      ))}
    </VStack>
  );
}
