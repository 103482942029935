import { ZoneExportFormat } from '@/types/commons/commons.types';
import { NoFlyZone3d } from '@/types/config/config.types';

import { CONFIG_URL, configApi } from './config.api';

export const noFlyZone3dApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    // A la date du 23/07/24, le back de l'export KML est pas encore fait pour les zones 3d, que GeoJson
    exportNoFlyZone3d: builder.mutation<Blob, { id: number; zoneExportFormat: ZoneExportFormat.GEOJSON }>({
      query: ({ id, zoneExportFormat }) => ({
        url: `${CONFIG_URL}/no-fly-zones-3d/${id}/export?format=${zoneExportFormat}`,
        method: 'GET',
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    createNoFlyZone3d: builder.mutation<NoFlyZone3d, Partial<NoFlyZone3d>>({
      query: (nfz3d) => ({
        url: `${CONFIG_URL}/no-fly-zones-3d`,
        method: 'POST',
        body: nfz3d,
      }),
    }),
    updateNoFlyZone3d: builder.mutation<NoFlyZone3d, { id: number; nfz3d: NoFlyZone3d }>({
      query: ({ id, nfz3d }) => ({
        url: `${CONFIG_URL}/no-fly-zones-3d/${id}`,
        method: 'PUT',
        body: nfz3d,
      }),
    }),
    updateActiveNoFlyZone3d: builder.mutation<void, { id: number; active: boolean }>({
      query: ({ id, active }) => ({
        url: `${CONFIG_URL}/no-fly-zones-3d/${id}/active?active=${active}`,
        method: 'PATCH',
      }),
    }),
    deleteNoFlyZone3d: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/no-fly-zones-3d/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useUpdateActiveNoFlyZone3dMutation, useDeleteNoFlyZone3dMutation } = noFlyZone3dApi;
