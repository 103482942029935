import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    height: 'auto',
    width: '100%',
    backgroundColor: 'neutral.900',
    rounded: 'none',
    color: 'neutral.white',
    fontFamily: 'roboto',
    fontSize: '16px',
    fontWeight: 'regular',
    boxSizing: 'border-box',
    padding: 2,
    borderRadius: 0,
    minWidth: 0,
    outline: 0,
    position: 'relative',
    _placeholder: {
      color: 'neutral.300',
    },
    border: 'none',
    _autofill: {
      transition: 'background-color 0s 5000s, color 0s 5000S',
    },
  },
  element: {
    height: '100%',
    padding: 2,
  },
});

const form = definePartsStyle({
  field: {
    background: 'neutral.900',
    color: 'neutral.white',
    border: '2px solid',
    borderColor: 'neutral.black',
    _hover: {
      borderColor: 'sky.500',
      background: 'neutral.800',
      _disabled: {
        borderColor: 'neutral.black',
      },
    },
    _focus: {
      borderColor: 'sky.500',
      background: 'neutral.800',
    },
    _invalid: {
      borderColor: 'alertHi.500',
    },
    _readOnly: {
      cursor: 'default',
      _hover: {
        border: '2px solid',
        borderColor: 'neutral.black',
      },
    },
    _disabled: {
      cursor: 'default',
    },
    rounded: 'none',
    fontSize: '16px',
    _focusVisible: {
      boxShadow: 'none',
    },
    padding: 0,
    textAlign: 'center',
  },
  stepperGroup: {
    display: 'none',
  },
});

export const numberInputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { form },
  defaultProps: { variant: 'form' },
});
