import { ReactComponent as DroneSureIcon } from '@assets/components/panel/alert/alert-drone-icon-sure.svg';
import { ReactComponent as DroneUnsureIcon } from '@assets/components/panel/alert/alert-drone-icon-unsure.svg';
import { ReactComponent as CriticalBorderDecoration } from '@assets/components/panel/alert/critical-alert-border-decoration.svg';
import { ReactComponent as PerimeterAlertIcon } from '@assets/icons/70x70/sensor/effraction-L.svg';
import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import SensorIcon from '@components/map/infos/sensors/SensorIcon';
import {
  getAlertColor,
  isAlertType,
  isIdentificationNotification,
  isPerimeterEffractionAlert,
  isSegmentFailureAlert,
  isSensorFailureAlert,
  isSpaceViolationAlert,
} from '@utils/data/notification.utils';
import { formatDate, formatDuration } from '@utils/date.utils';
import { targetColor } from '@utils/map/map.constants';
import { getSensorIconKey } from '@utils/map/sensor.utils';
import format from 'date-fns/format';
import { CSSProperties, memo } from 'react';
import { useIntl } from 'react-intl';

import { AlertLevelEnum, Notification } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';

import IdentificationCard from './IdentificationCard';
import SpaceViolationCard from './SpaceViolationCard';

function getIcon(notification: Notification, lightColor: string) {
  if (isSpaceViolationAlert(notification)) {
    return (
      <Icon
        as={notification.reliability === ReliabilityEnum.SURE ? DroneSureIcon : DroneUnsureIcon}
        width="70px"
        height="70px"
        color={lightColor}
      />
    );
  }
  if (isIdentificationNotification(notification)) {
    return <Icon as={DroneSureIcon} width="70px" height="70px" color={lightColor} />;
  }
  if (isSensorFailureAlert(notification)) {
    return (
      <SensorIcon sensorIconName={getSensorIconKey(notification.sensorType, true, false, SensorStatusEnum.DEGRADED)} />
    );
  }
  if (isPerimeterEffractionAlert(notification) || isSegmentFailureAlert(notification)) {
    return <Icon as={PerimeterAlertIcon} boxSize="70px" />;
  }
}

function getNotificationColor(classification: ClassificationEnum) {
  switch (classification) {
    case ClassificationEnum.FRIEND:
      return `var(--chakra-colors-alertZero-800)`;
    case ClassificationEnum.WATCH:
      return `var(--chakra-colors-alertLo-800)`;
    case ClassificationEnum.SUSPECT:
      return `var(--chakra-colors-alertMid-800)`;
    case ClassificationEnum.ENEMY:
      return `var(--chakra-colors-alertHi-800)`;
    case ClassificationEnum.UNKNOWN:
      return `var(--chakra-colors-neutral-700)`;
    case ClassificationEnum.UNCLASSIFIED:
      return `var(--chakra-colors-neutral-700)`;
  }
}

type Props = {
  notification: Notification;
  style: CSSProperties;
};

function NotificationCard({ notification, style }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const color = isIdentificationNotification(notification)
    ? getNotificationColor(notification.worstClassification)
    : getAlertColor(notification.level, 800);

  const lightColor = isIdentificationNotification(notification)
    ? targetColor[notification.worstClassification]
    : getAlertColor(notification.level);

  function getContent() {
    if (isSpaceViolationAlert(notification)) {
      return <SpaceViolationCard notification={notification} />;
    }
    if (isIdentificationNotification(notification)) {
      return <IdentificationCard notification={notification} />;
    }
  }

  const date = formatDate(notification.startTime, DateTimeEnum.DATE);
  const time = format(new Date(notification.startTime), "HH'h'mm");
  const since = notification.endTime
    ? formatMessage(
        { id: 'notification.durationEnded' },
        {
          duration: formatDuration(notification.startTime, notification.endTime),
        },
      )
    : '';

  return (
    <HStack gap={0.25} height="122px" minHeight="122px" width="100%" paddingTop={0.25} style={style}>
      <HStack gap={2} width="100%" height="100%" bgGradient={`linear-gradient(0deg, ${color} 50%, neutral.800 50%)`}>
        {isAlertType(notification) && notification.level === AlertLevelEnum.CRITICAL ? (
          <Icon as={CriticalBorderDecoration} height="100%" flexShrink={0} />
        ) : (
          <Box
            height="100%"
            width="16px"
            flexShrink={0}
            bgGradient={`linear-gradient(90deg, ${lightColor} 50%, transparent 50%)`}
          />
        )}
        {getIcon(notification, lightColor)}
        <VStack gap={0} height="100%" width="100%" paddingRight={2}>
          <HStack gap={2} height="50%" width="100%">
            <Text paddingX={0.5} paddingY="1px" backgroundColor="neutral.900" size="md">
              {notification.identifier}
            </Text>
            <VStack gap={0.5} marginLeft="auto" alignItems="start">
              <HStack gap={1}>
                <Text fontWeight="medium">{time}</Text>
                <Text>{date}</Text>
              </HStack>
              <Text>{since}</Text>
            </VStack>
          </HStack>
          <HStack gap={4} height="50%" width="100%">
            {getContent()}
          </HStack>
        </VStack>
      </HStack>
    </HStack>
  );
}

export default memo(NotificationCard);
