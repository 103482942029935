import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface MouseFocusState {
  isDisplayed: boolean;
}

export const useMouseFocus = (): MouseFocusState => {
  const [isKeyCombination, setIsKeyCombination] = useState(false);
  const [isMouseHovering, setIsMouseHovering] = useState(false);

  useHotkeys('alt+shift', () => {
    setIsKeyCombination(true);
  });

  useHotkeys(
    ['alt+shift', 'alt', 'shift'],
    () => {
      setIsKeyCombination(false);
    },
    { keyup: true },
  );

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsMouseHovering(true);
    };
    const handleMouseLeave = () => {
      setIsKeyCombination(false);
      setIsMouseHovering(false);
    };

    document.addEventListener('mouseenter', handleMouseEnter);
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseenter', handleMouseEnter);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  return {
    isDisplayed: isKeyCombination && isMouseHovering,
  };
};
