import { Button, Text, VStack } from '@chakra-ui/react';
import CustomRadioToggle from '@components/common/inputs/CustomRadioToggle';
import { appIntl } from '@components/locale/IntlGlobalProvider';
import { useAutomatonCommand } from '@hooks/useAutomatonCommand';
import {
  DefenceState,
  getCabinetSegmentStatuses,
  getCabinetStatuses,
  getDefenceState,
  getPowerState,
  isSegmentRepulsionOn,
  PowerState,
} from '@utils/sensors/sensors.utils';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AutomatonSensorStatus, SensorStatusEnum } from '@/types/sensor/status.types';

type Props = {
  automatonStatus: AutomatonSensorStatus | null;
};

export default function AutomatonCommands({ automatonStatus }: Readonly<Props>) {
  const { formatMessage } = appIntl();

  const cabinetStatuses = getCabinetStatuses(automatonStatus);
  const segmentStatuses = cabinetStatuses.flatMap((cabinetStatus) => getCabinetSegmentStatuses(cabinetStatus));
  const powerState = getPowerState(cabinetStatuses);
  const [cachedPowerState, setCachedPowerState] = useState<PowerState>(powerState);
  const defenceState = getDefenceState(segmentStatuses);
  const [cachedDefenceState, setCachedDefenceState] = useState<DefenceState>(defenceState);

  const useAutomaton = useAutomatonCommand({
    automatonStatus: automatonStatus,
    automatonConfiguration: automatonStatus?.configuration ?? null,
  });

  if (!useAutomaton.canCommand) {
    return null;
  }

  const countDetection = segmentStatuses.filter((segmentStatus) => !isSegmentRepulsionOn(segmentStatus)).length;
  const countAwake = cabinetStatuses.filter(
    (cabinetsStatus) => cabinetsStatus.status !== SensorStatusEnum.SLEEP,
  ).length;

  return (
    <>
      <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%">
        <VStack alignItems="start" width="100%" gap={2}>
          <Text size="md">
            <FormattedMessage id="sensors.command.automaton.sleep.description" />
          </Text>
          <Text color="neutral.200" fontSize="16px">
            <FormattedMessage
              id="sensors.command.automaton.sleep.counter"
              values={{ countAwake, total: cabinetStatuses.length }}
            />
          </Text>
        </VStack>
        <VStack width="100%" gap={2}>
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.automaton.sleep.on' })}
            isDisabled={!automatonStatus}
            isChecked={cachedPowerState === 'awake'}
            onClick={() => !!automatonStatus && setCachedPowerState('awake')}
          />
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.automaton.sleep.off' })}
            isDisabled={!automatonStatus}
            isChecked={cachedPowerState === 'asleep'}
            onClick={() => !!automatonStatus && setCachedPowerState('asleep')}
          />
          <Button
            isDisabled={!automatonStatus || powerState === cachedPowerState}
            isLoading={useAutomaton.isSleepAllCommandLoading}
            variant="formButtonPrimary"
            height="48px"
            width="125px"
            onClick={() => useAutomaton.sleepAll(cachedPowerState === 'awake')}
          >
            <FormattedMessage id="global.validate" />
          </Button>
        </VStack>
      </VStack>
      {(useAutomaton.canRepulse || useAutomaton.canDetect) && (
        <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%">
          <VStack alignItems="start" width="100%" gap={2}>
            <Text size="md">
              <FormattedMessage id="sensors.command.automaton.defence.description" />
            </Text>
            <Text color="neutral.200" fontSize="16px">
              <FormattedMessage
                id="sensors.command.automaton.defence.counter"
                values={{ countDetection, total: segmentStatuses.length }}
              />
            </Text>
          </VStack>
          <VStack width="100%" gap={2}>
            <CustomRadioToggle
              label={formatMessage({ id: 'sensors.command.automaton.defence.detection' })}
              isDisabled={automatonStatus === null || !useAutomaton.canDetect}
              isChecked={cachedDefenceState === 'detection'}
              onClick={() => setCachedDefenceState('detection')}
            />
            <CustomRadioToggle
              label={formatMessage({ id: 'sensors.command.automaton.defence.repulsion' })}
              isDisabled={automatonStatus === null || !useAutomaton.canRepulse}
              isChecked={cachedDefenceState === 'repulsion'}
              onClick={() => setCachedDefenceState('repulsion')}
            />
            <Button
              isDisabled={!automatonStatus || defenceState === cachedDefenceState}
              isLoading={useAutomaton.isRepulsionCommandLoading}
              variant="formButtonPrimary"
              height="48px"
              width="125px"
              onClick={() => useAutomaton.repulse(cachedDefenceState !== 'detection')}
            >
              <FormattedMessage id="global.validate" />
            </Button>
          </VStack>
        </VStack>
      )}
    </>
  );
}
