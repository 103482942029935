import { Box } from '@chakra-ui/react';

import { Coordinates } from '@/types/commons/commons.types';

import AzimuthCompassBackground from './AzimuthCompassBackground';
import AzimuthCompassTargets from './AzimuthCompassTargets';
import AzimuthCompassValue from './AzimuthCompassValue';

type Props = {
  fov: number;
  pan: number;
  replayMode: boolean;
  cameraPosition: Coordinates;
};

export default function AzimuthCompass({ fov, pan, replayMode, cameraPosition }: Readonly<Props>) {
  return (
    <Box width="180px" height="180px" position="relative" backgroundColor="transparent">
      <AzimuthCompassBackground />
      <AzimuthCompassValue pan={pan} fov={fov} />
      <AzimuthCompassTargets replayMode={replayMode} cameraPosition={cameraPosition} />
    </Box>
  );
}
