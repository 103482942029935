import './menuList.scss';

import { Box, Flex, Text, VStack } from '@chakra-ui/layout';
import { ButtonGroup } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { MenuItemOption, MenuItemOptionIcons, MenuItems } from '@/types/menu.types';

import MenuSubItem from './MenuSubItem';

interface MenuListProps {
  menuItems: MenuItems;
  depth: number;
  selectedOptions: string[];
  handleClick: (option: string, value: MenuItemOption | MenuItemOptionIcons | undefined, depth: number) => void;
  parentLabel?: string;
  buttonWidth?: string;
}

function MenuList({
  menuItems,
  depth,
  selectedOptions,
  handleClick,
  parentLabel,
  buttonWidth,
}: Readonly<MenuListProps>) {
  const { formatMessage } = useIntl();
  return (
    <VStack gap={0} backgroundColor="neutral.black" padding={0.25}>
      {parentLabel && (
        <Flex
          height="32px"
          width="100%"
          paddingRight={1}
          paddingLeft={2}
          paddingY={0.5}
          alignItems="center"
          cursor="default"
        >
          <Text size="xs" color="sky.500">
            {formatMessage({ id: parentLabel }).toUpperCase()}
          </Text>
        </Flex>
      )}
      <ButtonGroup flexDir="column" spacing={0} gap={0} width={buttonWidth ?? '100%'}>
        {Object.entries(menuItems).map(([key, value]) => {
          if (value && !value.isHidden) {
            const isSelectedOption = selectedOptions[depth] === key;
            const { children, label, isDisabled, isActive, isLoading, shouldNotTranslate } = value;
            const labelToDisplay = () => {
              if (typeof label === 'string') {
                return shouldNotTranslate ? label : formatMessage({ id: label });
              } else {
                return label;
              }
            };
            return (
              <Box key={key} gap={0} position="relative">
                <MenuSubItem
                  label={labelToDisplay()}
                  isSelected={isSelectedOption}
                  isDisabled={isDisabled}
                  isActive={isActive}
                  hasChildren={!!children}
                  onClick={() => handleClick(key, value, depth)}
                  isLoading={isLoading}
                />
                {key === selectedOptions[depth] && children && (
                  <VStack position="absolute" left="100%" top="-2px" gap={0}>
                    <MenuList
                      menuItems={children}
                      depth={depth + 1}
                      selectedOptions={selectedOptions}
                      handleClick={handleClick}
                      buttonWidth={buttonWidth}
                    />
                  </VStack>
                )}
              </Box>
            );
          }
        })}
      </ButtonGroup>
    </VStack>
  );
}

export default MenuList;
