import { FormatMessageType } from '@hooks/useValidation';
import { LIST, MAX_FIELD, MIN_FIELD, REQUIRED_FIELD } from '@utils/validation/validation.constant';
import Yup from '@utils/validation/yup-extended';

import { EventTypeEnum } from '@/types/replay/replay.types';

export const eventSchema = (formatMessage: FormatMessageType) => {
  return Yup.object({
    name: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'live.event.name' }) }),
    ),
    startDate: Yup.date().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'live.event.dateTime' }) }),
    ),
    duration: Yup.number()
      .min(0, formatMessage({ id: MIN_FIELD }, { value: 0, field: formatMessage({ id: 'live.event.duration' }) }))
      .max(120, formatMessage({ id: MAX_FIELD }, { value: 120, field: formatMessage({ id: 'live.event.duration' }) }))
      .required(formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'live.event.duration' }) })),
    observationType: Yup.mixed<EventTypeEnum>()
      .oneOf(Object.values(EventTypeEnum), formatMessage({ id: LIST }))
      .required(formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'live.event.type' }) })),
  });
};
