import HologardeBackground from '@assets/hologarde-background.jpg';
import { Box, Center, Flex } from '@chakra-ui/layout';
import { Button, Image, Text } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import CustomTitle from '@components/common/CustomTitle';
import { useAppSelector } from '@hooks/redux.hooks';
import { useAuth } from '@hooks/useAuth';
import {
  selectActiveUser,
  selectHasBeenInitialized,
  selectHasSelectedGroup,
  selectIsChangingShift,
} from '@redux/authent/authent.selectors';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { RoutesEnum } from '@/types/routes.types';

export default function PrivateOutletOther() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const hasBeenInitialized = useAppSelector(selectHasBeenInitialized);
  const hasSelectedGroup = useAppSelector(selectHasSelectedGroup);
  const { formatMessage } = useIntl();
  const isChangingShift = useAppSelector(selectIsChangingShift);

  const user = useAppSelector(selectActiveUser);

  return token !== null && hasBeenInitialized && hasSelectedGroup && user.activeGroup ? (
    <Box width="100%" height="100%">
      {isChangingShift && (
        <Box
          width="100%"
          height="100%"
          position="absolute"
          backgroundColor="neutral.900-op30"
          zIndex={9999}
          onClick={() => createToast(formatMessage({ id: 'authent.warningTakeShift' }), ToastStatusEnum.WARNING)}
        />
      )}
      <Outlet />
    </Box>
  ) : (
    <Flex width="100%" height="100%" alignContent="center" justifyContent="center" backgroundColor="neutral.white">
      <Image src={HologardeBackground} fit="cover" height="100%" width="100%" position="absolute" />
      {location.pathname.includes(RoutesEnum.SCREEN) ? (
        <CustomTitle color="cyber.900">
          <Text fontWeight="medium" fontSize="18px" noOfLines={1} userSelect="none">
            <FormattedMessage id={`routes.${location.pathname}`} />
          </Text>
        </CustomTitle>
      ) : (
        <></>
      )}
      <Center>
        {token === null ? (
          <Flex direction="column" fontFamily="roboto">
            <Button onClick={() => navigate(RoutesEnum.LOGIN)}>Retourner à la page de connexion</Button>
          </Flex>
        ) : (
          <Spinner size="xl" color="neutral.white" />
        )}
      </Center>
    </Flex>
  );
}
