import { Text, VStack } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/react';
import SideBySideSelect, { ElementInfo2 } from '@components/common/layout/SideBySideSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import {
  RoleSelectors,
  selectActiveProfile,
  selectDenormalizedProfileById,
  selectDenormalizedProfiles,
} from '@redux/authent/authent.selectors';
import { RootState } from '@redux/store';
import { useUpdateProfileMutation } from '@services/authent/profile.api';
import { isBasicRole, isNotTechnical } from '@utils/authent/roles.utils';
import { useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Role } from '@/types/authent/roles.types';

type Props = {
  initiallySelectedProfileId: number | null;
};

export default function ManageProfiles({ initiallySelectedProfileId }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const profiles = useAppSelector(selectDenormalizedProfiles);
  const roles = useAppSelector(RoleSelectors.selectAllRoles);
  const activeProfile = useAppSelector(selectActiveProfile);

  const [selectedProfileId, setSelectedProfileId] = useReducer(
    (_: number, profileId: number | null) => profileId ?? profiles[0].id,
    initiallySelectedProfileId ?? profiles[0].id,
  );
  const [previousInitialProfileId, setPreviousInitialProfileId] = useState(initiallySelectedProfileId);

  const selectedProfile =
    useAppSelector((state: RootState) => selectDenormalizedProfileById(state, selectedProfileId)) ?? null;

  const leftTitle = formatMessage({ id: 'components.admin.profiles.currentRoles' });
  const rightTitle = formatMessage({ id: 'components.admin.profiles.availableRoles' });
  const currentRolesSet = new Set(selectedProfile?.roles.map((role) => role.id) ?? []);
  const isDisabled = selectedProfile && activeProfile!.id === selectedProfile.id;
  const elementInfos: ElementInfo2<Role>[] = selectedProfileId
    ? roles.filter(isNotTechnical).map((role) => ({
        element: role,
        status: isBasicRole(role) || isDisabled ? 'unselectable' : 'normal',
        side: currentRolesSet.has(role.id) ? 'left' : 'right',
      }))
    : [];

  if (initiallySelectedProfileId !== previousInitialProfileId) {
    setPreviousInitialProfileId(initiallySelectedProfileId);
    setSelectedProfileId(initiallySelectedProfileId);
  }

  function handleRoleChange(elementInfos: ElementInfo2<Role>[]) {
    if (selectedProfile) {
      updateProfile({
        ...selectedProfile,
        roles: elementInfos.map((elementInfo) => elementInfo.element),
      });
    }
  }

  return (
    <VStack alignItems="start" gap={2}>
      <Text color="neutral.black">
        <FormattedMessage id="components.admin.profiles.manageProfiles" />
      </Text>
      <Text fontWeight="600" fontSize="17px" color="neutral.black" gap={0}>
        <FormattedMessage id="authent.user.profile" />
      </Text>
      <Select
        onChange={(event) => {
          const newSelectedProfile = profiles.find((profile) => event.target.value === profile.id.toString());
          setSelectedProfileId(newSelectedProfile?.id ?? null);
        }}
        value={selectedProfileId}
      >
        {profiles.map((profile) => (
          <option key={profile.id} value={profile.id}>
            {profile.name}
          </option>
        ))}
      </Select>
      <SideBySideSelect<Role>
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        elementInfos={elementInfos}
        getId={(role) => role.id}
        getLabel={(role) => role.roleName}
        isLoading={isLoading}
        onChange={handleRoleChange}
      />
      {isDisabled ? (
        <Text color="neutral.black">
          <FormattedMessage id="components.admin.profiles.noModifActiveProfile" />
        </Text>
      ) : (
        <></>
      )}
    </VStack>
  );
}
