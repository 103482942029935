import { GroupWithUsers } from '@/types/authent/groups.types';

import { AUTHENT_URL, authentApi } from './authent.api';

export const groupApi = authentApi.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation<GroupWithUsers, Partial<GroupWithUsers>>({
      query: (group) => ({
        url: `${AUTHENT_URL}/groups`,
        method: 'POST',
        body: group,
      }),
    }),
    updateGroup: builder.mutation<GroupWithUsers, GroupWithUsers>({
      query: ({ id, ...group }) => ({
        url: `${AUTHENT_URL}/groups/${id}`,
        method: 'PUT',
        body: group,
      }),
    }),
    deleteGroup: builder.mutation<void, number>({
      query: (id) => ({
        url: `${AUTHENT_URL}/groups/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateGroupMutation, useUpdateGroupMutation, useDeleteGroupMutation } = groupApi;
