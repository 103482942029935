import CustomChipList from '@components/common/inputs/CustomChipList';
import { useAppSelector, useAppStore } from '@hooks/redux.hooks';
import useCheatCode from '@hooks/useCheatCode';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { selectAllOngoingAlerts, selectSituationTime } from '@redux/situation/situation.selectors';
import { useArchiveAllNotificationsMutation, useGetNotificationLazyQuery } from '@services/data/notification.api';
import { stringToOption } from '@utils/components/customChipList.utils';
import {
  isAlertType,
  isPerimeterEffractionAlert,
  isSegmentFailureAlert,
  isSensorFailureAlert,
  isSpaceViolationAlert,
  sortAlertByLevel,
} from '@utils/data/notification.utils';
import { sortDateDesc } from '@utils/date.utils';
import { addDays } from 'date-fns';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';

import { type Alert, NotificationStatus, NotificationType } from '@/types/data/data.types';

import PanelHeader from '../../../common/layout/PanelHeader';
import Panel from '../Panel';
import ArchivedAlertList from './ArchivedAlertList';
import LiveAlertList from './LiveAlertList';

enum AlertFilterEnum {
  LAD = 'LAD',
  PERIMETER = 'PERIMETER',
  TECHNICAL = 'TECHNICAL',
}

enum NotificationFilterEnum {
  ONGOING = 'ONGOING',
  ARCHIVED = 'ARCHIVED',
}

enum AlertSortType {
  DATE = 'DATE',
  LEVEL = 'LEVEL',
}

export type AlertStateType = 'CONFIRM' | 'ARCHIVE';

export type AlertsState = {
  id: number | null;
  type: AlertStateType | null;
};

export default function AlertsPanel() {
  const { formatMessage } = useIntl();
  const onGoingAlerts = useAppSelector(selectAllOngoingAlerts);
  const store = useAppStore();
  const activeSite = useAppSelector(selectActiveSite);
  const [state, setState] = useReducer(
    (state: AlertsState, newState: AlertsState) => ({
      ...state,
      ...newState,
    }),
    {
      id: null,
      type: null,
    },
  );

  const filter = { ...NotificationFilterEnum, ...AlertFilterEnum };
  const [selectedFilters, setSelectedFilters] = useState<string[]>(['ONGOING']);
  const [selectedSort, setSelectedSort] = useState<string>(AlertSortType.DATE);

  const archivedNotificationCriteria = useCallback(
    (situationTime: string | null) => ({
      site: activeSite!.code,
      from: addDays(new Date(situationTime ?? new Date().toISOString()), -1).toISOString(),
      to: situationTime ?? new Date().toISOString(),
      notificationStatus: [NotificationStatus.ARCHIVED],
      types: [
        // We don't display collision alert for now
        //NotificationType.COLLISION_ALERT
        NotificationType.SENSOR_FAILURE_ALERT,
        NotificationType.PERIMETER_EFFRACTION_ALERT,
        NotificationType.AUTOMATON_SEGMENT_FAILURE_ALERT,
        NotificationType.SPACE_VIOLATION_ALERT,
      ],
      size: 100,
    }),
    [activeSite],
  );

  const [triggerCallGetNotifications, result] = useGetNotificationLazyQuery();

  useEffect(() => {
    triggerCallGetNotifications(archivedNotificationCriteria(selectSituationTime(store.getState())));
  }, [archivedNotificationCriteria, store, triggerCallGetNotifications]);

  function filterItems(alert: Alert): boolean {
    const isLAD = selectedFilters.includes(AlertFilterEnum.LAD) && isSpaceViolationAlert(alert);
    const isPerimeter = selectedFilters.includes(AlertFilterEnum.PERIMETER) && isPerimeterEffractionAlert(alert);
    const isTechnical =
      selectedFilters.includes(AlertFilterEnum.TECHNICAL) &&
      (isSensorFailureAlert(alert) || isSegmentFailureAlert(alert));

    const isOngoing =
      selectedFilters.includes(NotificationFilterEnum.ONGOING) &&
      (alert.notificationStatus === NotificationStatus.ACTIVE || alert.notificationStatus === NotificationStatus.ENDED);
    const isArchived =
      alert.notificationStatus === NotificationStatus.ARCHIVED && selectedFilters.includes(NotificationStatus.ARCHIVED);

    if (
      !(
        selectedFilters.includes(NotificationFilterEnum.ONGOING) ||
        selectedFilters.includes(NotificationFilterEnum.ARCHIVED)
      )
    ) {
      return isLAD || isPerimeter || isTechnical;
    } else if (isArchived) {
      return selectedFilters.length > 1 ? isLAD || isPerimeter || isTechnical : isArchived;
    } else {
      return selectedFilters.length > 1 ? isOngoing && (isLAD || isPerimeter || isTechnical) : isOngoing;
    }
  }

  let alerts: Alert[] = onGoingAlerts;
  if (result.data?.length && selectedFilters.includes('ARCHIVED')) {
    alerts = result.data
      .filter((notification) => isAlertType(notification))
      .filter((alert) => alert.notificationStatus === NotificationStatus.ARCHIVED) as Alert[];
  }

  const alertsFiltered = alerts
    .filter(filterItems)
    .sort((alert1, alert2) =>
      selectedSort === AlertSortType.LEVEL
        ? sortAlertByLevel(alert1.level, alert2.level)
        : sortDateDesc(alert1.startTime, alert2.startTime),
    );

  const handleChangeFilter = useCallback(
    (values: string[]) => {
      values.includes('ARCHIVED') &&
        triggerCallGetNotifications(archivedNotificationCriteria(selectSituationTime(store.getState())));
      setSelectedFilters(values);
    },
    [archivedNotificationCriteria, store, triggerCallGetNotifications],
  );

  function getCount(value: string): number | undefined {
    switch (value) {
      case NotificationFilterEnum.ONGOING:
        return onGoingAlerts.length;
      case NotificationFilterEnum.ARCHIVED:
        return result.data?.length ?? 0;
      case AlertFilterEnum.LAD:
        return alerts.filter(isSpaceViolationAlert).length;
      case AlertFilterEnum.PERIMETER:
        return alerts.filter(isPerimeterEffractionAlert).length;
      case AlertFilterEnum.TECHNICAL:
        return alerts.filter((alert) => isSensorFailureAlert(alert) || isSegmentFailureAlert(alert)).length;
      default:
        return undefined;
    }
  }

  const [archiveAll] = useArchiveAllNotificationsMutation();
  useCheatCode('archive all notifications', ['z', 'z', 'z'], () => archiveAll(alerts));

  return (
    <Panel
      width={914}
      emptyLabel={formatMessage({ id: 'components.alert.noAlert' })}
      isEmpty={alertsFiltered.length === 0}
      header={
        <PanelHeader
          label={formatMessage({ id: 'components.sidebar.ALERT' })}
          count={onGoingAlerts.length}
          isFilterDisabled={alerts.length === 0}
          marginRight={2}
          filter={
            <CustomChipList
              availableOptions={Object.keys(filter)
                .map(stringToOption)
                .map((option) => {
                  return { ...option, count: getCount(option.value) };
                })}
              selectedOptions={selectedFilters.map(stringToOption)}
              path="live.alert.types"
              distinctValues={['ONGOING', 'ARCHIVED']}
              onChange={handleChangeFilter}
            />
          }
          sort={
            <CustomChipList
              availableOptions={Object.keys(AlertSortType).map(stringToOption)}
              selectedOptions={[stringToOption(selectedSort)]}
              path="live.alert.sort"
              distinctValues={['DATE', 'LEVEL']}
              onChange={(value: string[]) => {
                setSelectedSort(value.length !== 0 ? value[0] : 'DATE');
              }}
            />
          }
        />
      }
    >
      {selectedFilters.includes('ARCHIVED') ? (
        <ArchivedAlertList alerts={alertsFiltered} />
      ) : (
        <LiveAlertList alertIds={alertsFiltered.map((alert) => alert.id)} state={state} setState={setState} />
      )}
    </Panel>
  );
}
