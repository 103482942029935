import { updateToken, userLogout } from '@redux/authent/authent.reducer';
import { RootState } from '@redux/store';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { createErrorMessage, isFirstConnectionExceptionPayload, isSyriusExceptionPayload } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { E_CANCELED, Mutex } from 'async-mutex';

import { AUTHENT_URL } from './authent/authent.api';

// create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    const authent = (getState() as RootState).authent;
    if (authent.token && authent.renewalToken) {
      headers.set('authorization', authent.token);
      headers.set('renewal', authent.renewalToken);
    }
    if (authent.activeUser.id !== -1) {
      headers.set('userName', authent.activeUser.login);
    }
    headers.set('Content-type', 'application/json; charset=utf-8');
    return headers;
  },
});
export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  object,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    //First connection, not a real 401
    if (isFirstConnectionExceptionPayload(result.error)) {
      return result;
    }
    if (isSyriusExceptionPayload(result.error) && result.error.data.code !== 'authent.invalidToken') {
      api.dispatch(userLogout());
      return result;
    }
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          { url: `${AUTHENT_URL}/token/renew`, method: 'POST', credentials: 'same-origin' },
          api,
          extraOptions,
        );
        if (refreshResult.meta?.response?.status === 200) {
          // retry the initial query
          api.dispatch(updateToken([...refreshResult.meta.response.headers][0]?.[1] ?? null));
          result = await baseQuery(args, api, extraOptions);
        } else {
          refreshResult.error && createToast(createErrorMessage(refreshResult.error), ToastStatusEnum.ERROR);
          mutex.cancel();
          api.dispatch(userLogout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex
        .waitForUnlock()
        .then(() => {
          return baseQuery(args, api, extraOptions);
        })
        .catch((e) => {
          if (e === E_CANCELED) {
            return;
          }
        });
    }
  }
  return result;
};
