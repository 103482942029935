import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPerimeterCameraFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import {
  perimeterCameraAzimuthLayer,
  perimeterCameraIconLabelLayer,
  perimeterCameraSectorFillLayer,
  perimeterCameraSectorOutlineLayer,
} from './perimeterCamera.layer';

export default function PerimeterCameraLayer() {
  const perimeterCameraFeatures = useSelectorWithReplayMode(selectPerimeterCameraFeatures);

  if (!isFeatureCollection(perimeterCameraFeatures)) return null;

  return (
    <Source id={SourceNameEnum.PERIMETER_CAMERAS} type="geojson" data={perimeterCameraFeatures} generateId>
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_SECTOR_FILLS} {...perimeterCameraSectorFillLayer} />
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_SECTOR_OUTLINES} {...perimeterCameraSectorOutlineLayer} />
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_AZIMUTHS} {...perimeterCameraAzimuthLayer} />
      <Layer key={LayerNameEnum.PERIMETER_CAMERA_LABELS} {...perimeterCameraIconLabelLayer} />
    </Source>
  );
}
