import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateDrawPolygonsData } from '@redux/maps/maps.reducer';
import { selectMapEditPolygonsInfo } from '@redux/maps/maps.selectors';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import { colors } from 'src/theme/colors';

import { Coordinates } from '@/types/commons/commons.types';
import { MapBoxDrawEvent } from '@/types/map.types';

import { DrawPolygonControl } from './DrawPolygonControl';

const drawStyle = [
  // line stroke
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': colors.cyber[600],
      'line-dasharray': [0.2, 2],
      'line-width': 5,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': colors.cyber[600],
      'fill-outline-color': colors.cyber[600],
      'fill-opacity': 0.1,
    },
  },
  // polygon mid points
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 9,
      'circle-color': colors.sky[500],
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': colors.cyber[600],
      'line-dasharray': [0.2, 2],
      'line-width': 5,
    },
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 11,
      'circle-color': colors.neutral[900],
    },
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 9,
      'circle-color': colors.cyber[600],
    },
  },
];

// const drawStyle = [
//   // Points du milieu (midpoints) pour le traçage des polygones
//   {
//     id: 'gl-draw-polygon-midpoint',
//     type: 'circle',
//     filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
//     paint: {
//       'circle-radius': 9,
//       'circle-color': colors.sky[500],
//     },
//   },
//   // Style de la ligne en cours de traçage (actif)
//   {
//     id: 'gl-draw-line-active',
//     type: 'line',
//     filter: ['==', '$type', 'LineString'], // ['all', , ['==', 'active', 'true']],
//     layout: {
//       'line-cap': 'round',
//       'line-join': 'round',
//     },
//     paint: {
//       'line-color': colors.cyber[600],
//       'line-dasharray': [0.2, 2],
//       'line-width': 5,
//     },
//   },
//   // Style de remplissage du polygone en cours de traçage (actif)
//   {
//     id: 'gl-draw-polygon-fill-active',
//     type: 'fill',
//     filter: ['==', '$type', 'Polygon'], //['all',  ['==', 'active', 'true']],
//     paint: {
//       'fill-color': colors.cyber[600],
//       'fill-outline-color': colors.cyber[600],
//       'fill-opacity': 0.2, // Augmentation de l'opacité pour plus de clarté
//     },
//   },
//   // Style du polygone inactif (non en cours de traçage)
//   {
//     id: 'gl-draw-polygon-fill-inactive',
//     type: 'fill',
//     filter: ['==', '$type', 'Polygon'], //['all', , ['==', 'active', 'false']],
//     paint: {
//       'fill-color': colors.cyber[600],
//       'fill-outline-color': colors.cyber[600],
//       'fill-opacity': 0.1,
//     },
//   },
//   // Contour du polygone en cours de traçage (actif)
//   {
//     id: 'gl-draw-polygon-stroke-active',
//     type: 'line',
//     filter: ['==', '$type', 'Polygon'], //['all',  ['==', 'active', 'true']],
//     layout: {
//       'line-cap': 'round',
//       'line-join': 'round',
//     },
//     paint: {
//       'line-color': colors.cyber[600],
//       'line-dasharray': [0.2, 2],
//       'line-width': 5,
//     },
//   },
//   // Halos des points de vertex (sommets)
//   {
//     id: 'gl-draw-polygon-and-line-vertex-halo-active',
//     type: 'circle',
//     filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['==', 'active', 'true']],
//     paint: {
//       'circle-radius': 11,
//       'circle-color': colors.neutral[900],
//     },
//   },
//   // Points de vertex (sommets)
//   {
//     id: 'gl-draw-polygon-and-line-vertex-active',
//     type: 'circle',
//     filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['==', 'active', 'true']],
//     paint: {
//       'circle-radius': 9,
//       'circle-color': colors.cyber[600],
//     },
//   },
// ];

export function DrawPolygonLayer() {
  const { mapId } = useMapContext();
  const dispatchUpdateDrawPolygonsData = useWithDispatch(updateDrawPolygonsData);
  const polyToEdit = useAppSelector((state) => selectMapEditPolygonsInfo(state, mapId));

  const onCreateDraw = (evt: { features: MapBoxDrawEvent[] }) => {
    dispatchUpdateDrawPolygonsData({
      mapId,
      points: evt.features[0].geometry.coordinates[0].map((p) => toCoordinates(p)) as Coordinates[],
    });
  };

  const onUpdateDraw = (evt: { features: MapBoxDrawEvent[]; action: string }) => {
    dispatchUpdateDrawPolygonsData({
      mapId,
      points: evt.features[0].geometry.coordinates[0].map((p) => toCoordinates(p)) as Coordinates[],
    });
  };

  // const mainDrawColor = colors.cyber[600];
  // const secondDrawColor = colors.sky[500];

  return (
    <DrawPolygonControl
      position="top-left"
      displayControlsDefault={false}
      controls={{
        point: false,
        line_string: false,
        polygon: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
      }}
      defaultMode="draw_polygon"
      styles={drawStyle}
      onCreate={onCreateDraw}
      onUpdate={onUpdateDraw}
      polyToEdit={polyToEdit}
    />
  );
}
