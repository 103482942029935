import { useAppSelector } from '@hooks/redux.hooks';
import { selectReplayStatusTime } from '@redux/replay/replay.selectors';
import { customAddSubForDate } from '@utils/date.utils';
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { MapIdEnum } from '@/types/map.types';
import { BackwardForwardTooltip, RangeProps, ReplayTimeScaleEnum } from '@/types/replay/replay.types';

interface ReplayTimelineContextProps {
  mapId: MapIdEnum;
  range: RangeProps;
  setDateRange: (from: Date, to: Date) => void;
  timeScale: ReplayTimeScaleEnum;
  setTimeScale: (timeScale: ReplayTimeScaleEnum) => void;
  backwardForwardTooltip: BackwardForwardTooltip;
  setBackwardForwardTooltip: (tootlip: BackwardForwardTooltip) => void;
  currentReplayTime: Date;
}

const ReplayTimelineContext = createContext<ReplayTimelineContextProps | null>(null);

interface ReplayTimelineProviderProps {
  mapId: MapIdEnum;
  initialRange: RangeProps;
  initialTimeScale: ReplayTimeScaleEnum;
}

export const ReplayTimelineProvider = ({
  children,
  mapId,
  initialRange,
  initialTimeScale,
}: PropsWithChildren<ReplayTimelineProviderProps>) => {
  const [range, setRange] = useState<RangeProps>(initialRange);
  const [timeScale, setTimeScale] = useState<ReplayTimeScaleEnum>(initialTimeScale);
  const replayStatusTime = useAppSelector(selectReplayStatusTime);
  const currentReplayTime = replayStatusTime ?? range.from;
  const [backwardForwardTooltip, setBackwardForwardTooltip] = useState<BackwardForwardTooltip>({
    isDisplayed: false,
    label: '',
    timeout: null,
  });

  const setDateRange = (from: Date, to: Date) => {
    setRange({ from: from, to: to });
  };

  const values = useMemo(
    () => ({
      mapId,
      range,
      setDateRange,
      timeScale,
      setTimeScale,
      currentReplayTime,
      backwardForwardTooltip,
      setBackwardForwardTooltip,
    }),
    [mapId, range, timeScale, currentReplayTime, backwardForwardTooltip, setBackwardForwardTooltip],
  );

  if (currentReplayTime >= range.to || currentReplayTime < range.from) {
    const newTimelineEnd = customAddSubForDate(currentReplayTime, 'add', timeScale, 10);
    setDateRange(currentReplayTime, newTimelineEnd);
  }

  return <ReplayTimelineContext.Provider value={values}>{children}</ReplayTimelineContext.Provider>;
};

export const useReplayTimelineContext = (): ReplayTimelineContextProps => {
  const context = useContext(ReplayTimelineContext);
  if (!context) {
    throw new Error('useReplayTimelineContext must be used within a ReplayTimelineProvider');
  }
  return context;
};
