import { Abstract, Coordinates } from '../commons/commons.types';

export enum RoundDirectionEnum {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

export enum RoundModeEnum {
  NORMAL = 'NORMAL',
  CRITICAL = 'CRITICAL',
}

export enum RoundStatusEnum {
  ONGOING = 'ONGOING',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
}

export enum RoundConfigurationStateEnum {
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
}

export interface RoundPoint {
  tilt: number;
  pan: number;
  focus: number;
  id: number;
  fov: number;
  coordinates: Coordinates;
}

export interface RoundSection {
  id: number;
  rank: number;
  speed: number;
  active: boolean;
  critical: boolean;
  positionDeparture: RoundPoint;
  positionArrival: RoundPoint;
}

export interface RoundConfiguration extends Abstract {
  site: string;
  id: number;
  name: string | null;
  sections: RoundSection[];
  code: string;
  active: boolean;
  sensorCode: string;
  state: RoundConfigurationStateEnum;
}

export interface Round {
  code: string;
  site: string;
  sensorCode: string;
  currentSectionRank: number;
  direction: RoundDirectionEnum;
  loopSection: boolean;
  mode: RoundModeEnum;
  speedMultiplier: number;
  status: RoundStatusEnum;
}
