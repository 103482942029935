import { selectActiveUser } from '@redux/authent/authent.selectors';
import { addAlert, updateSelectedDoubtCheckSegmentName } from '@redux/global/global.reducer';
import { selectAutomatedLad, selectContext } from '@redux/settings/settings.selectors';
import { updateSituation } from '@redux/situation/situation.reducer';
import { selectOngoingAlerts } from '@redux/situation/situation.selectors';
import { RootState } from '@redux/store';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { settingApi } from '@services/config/setting.api';

import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { NotificationType, PerimeterEffractionAlert } from '@/types/data/data.types';
import { RoutesEnum } from '@/types/routes.types';

export const situationMiddleware = createListenerMiddleware();

function extractNewAlerts(originalState: RootState, newState: RootState) {
  const currentAlerts = selectOngoingAlerts(originalState);
  const nextAlerts = selectOngoingAlerts(newState);
  return nextAlerts.filter((nextAlert) => !currentAlerts.some((currentAlert) => currentAlert.id === nextAlert.id));
}

situationMiddleware.startListening({
  actionCreator: updateSituation,
  effect: async (_, listenerApi) => {
    const originalState = listenerApi.getOriginalState() as RootState;
    if (originalState.situation.tacticalSituation === null) {
      // First situation received, we don't want to execute logic based on old alerts
      return;
    }

    if (sessionStorage.getItem('route') !== RoutesEnum.C2) {
      // This middleware should be executed a single time for each situation
      return;
    }
    const newState = listenerApi.getState() as RootState;

    const newAlerts = extractNewAlerts(originalState, newState);

    newAlerts.forEach((alert) => {
      listenerApi.dispatch(addAlert(alert));
    });

    const isLADAutomated = selectAutomatedLad(newState);
    const isLive = selectContext(newState) === ContextEnum.LIVE;
    if (isLADAutomated && isLive) {
      const activeUser = selectActiveUser(newState);
      newAlerts
        .filter((newAlert) => newAlert.type === NotificationType.PERIMETER_EFFRACTION_ALERT)
        .forEach((newAlert) => {
          listenerApi.dispatch(
            settingApi.endpoints.patchContext.initiate({
              login: activeUser.login,
              context: ContextEnum.DOUBT_PERIM,
            }),
          );
          listenerApi.dispatch(updateSelectedDoubtCheckSegmentName((newAlert as PerimeterEffractionAlert).segmentName));
        });
    }
  },
});
