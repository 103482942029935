import './nipple.scss';

import { ReactComponent as Crosshair } from '@assets/icons/cameras/crosshair.svg';
import { ReactComponent as TrackingCrosshair } from '@assets/icons/cameras/tracking-crosshair.svg';
import { FormLabel } from '@chakra-ui/form-control';
import { AbsoluteCenter, Box, Center } from '@chakra-ui/layout';
import { HStack, Text } from '@chakra-ui/react';
import Player from '@components/common/video/Player';
import { useAppSelector } from '@hooks/redux.hooks';
import { UseCameraCommandFunctions } from '@hooks/useCameraCommand';
import { useDimensions } from '@hooks/useDimensions';
import { useWithDispatch } from '@hooks/useWithDispatch';
import {
  addSelectedCameraStreams,
  removeSelectedCameraStreams,
  updateJoystickControlId,
  updateJoystickControlledCam,
  updateVideoTrackerState,
} from '@redux/global/global.reducer';
import { selectJoystickControlId, selectTrackingStateOfCameraFlux } from '@redux/global/global.selector';
import { degreesToRadians, radiansToDegrees } from '@turf/turf';
import { modulo } from '@utils/math.utils';
import { HolopticsSubCameraEnum } from '@utils/sensors/camera/camera.constants';
import { calculateVerticalFov, getDefaultFlux, getNimbleStreamUrl } from '@utils/sensors/camera/camera.utils';
import React, { Dispatch, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactNipple from 'react-nipple';

import { VideoBox } from '@/types/c2/c2.types';
import { SensorTypeEnum, SubCameraConfiguration } from '@/types/sensor/configuration.types';
import { CameraStatus, TrackingStatusEnum } from '@/types/sensor/status.types';

import CameraBorder from './CameraBorder';
import CameraControlTitle from './CameraControlTitle';
import { CameraStatusInfo } from './CameraStatusInfo';
import AzimuthCompass from './gauge/azimuth/AzimuthCompass';
import ElevationCompass from './gauge/elevation/ElevationCompass';
import HorizontalGauge from './gauge/HorizontalGauge';
import VerticalGauge from './gauge/VerticalGauge';
import PipContainer from './PipContainer';

export enum GaugeStyleEnum {
  GAUGE = 'GAUGE',
  COMPASS = 'COMPASS',
  BOTH = 'BOTH',
  NONE = 'NONE',
}

export type ControlState = {
  pip: boolean;
  displayedFlux: SubCameraConfiguration | null;
  pipFlux: SubCameraConfiguration[];
  displayCross: boolean;
  previousSelectedCameraCode: string | null;
  polarity: boolean;
  iso: boolean;
  hybridFocus: boolean;
  whiteBalance: boolean;
  round: boolean;
  simpleRoundSpeed: number | null;
  displayVirtualJoystick: boolean;
  gaugeStyle: GaugeStyleEnum;
};

interface OwnProps {
  replayMode: boolean;
  selectedCameraCode: string | null;
  uniqueKey: string;
  cameraStatus: CameraStatus | null;
  controlState: ControlState;
  setControlState: Dispatch<Partial<ControlState>>;
  useCamera: UseCameraCommandFunctions;
}

const JOYSTICK_CONTAINER_SIZE = 180;
const DEFAULT_CAMERA_RATIO = 16 / 9;

type JoystickData = {
  angle: {
    degree: number;
  };
  distance: number;
};

function CameraControl({
  replayMode,
  selectedCameraCode,
  uniqueKey,
  cameraStatus,
  controlState,
  setControlState,
  useCamera,
}: Readonly<OwnProps>) {
  const updateJoystickControlledCamWithDispatch = useWithDispatch(updateJoystickControlledCam);
  const updateJoystickControlIdWithDispatch = useWithDispatch(updateJoystickControlId);
  const setSelectedCameraStreams = useWithDispatch(addSelectedCameraStreams);
  const clearSelectedCameraStreams = useWithDispatch(removeSelectedCameraStreams);
  const [componentDimension, componentRef] = useDimensions();
  const [dimension16_9, ref16_9] = useDimensions();
  const [effectiveVideoDimension, effectiveVideoRef] = useDimensions();

  const trackingBoxDimensions = {
    width: (effectiveVideoDimension?.width ?? 0) / 10,
    height: (effectiveVideoDimension?.height ?? 0) / 10,
  };

  const joystickControlId = useAppSelector(selectJoystickControlId);

  const cameraConfiguration = cameraStatus?.configuration ?? null;

  const detectedObjects = cameraStatus?.trackingStatus.detectedObjects;

  useEffect(() => {
    const flux = controlState.displayedFlux;
    if (flux) {
      setSelectedCameraStreams(flux);
      return () => {
        clearSelectedCameraStreams(flux);
      };
    }
  }, [clearSelectedCameraStreams, setSelectedCameraStreams, controlState.displayedFlux]);
  const displayedVideoStatus = cameraStatus?.videoStatuses.find((vs) => vs.id === controlState.displayedFlux?.cameraId);

  const trackingStateOfSelectedFlux = useAppSelector(
    selectTrackingStateOfCameraFlux(selectedCameraCode, controlState.displayedFlux?.cameraId),
  );

  const updateTrackingState = useWithDispatch(updateVideoTrackerState);

  const isHolopticsIrCamera =
    cameraConfiguration?.type === SensorTypeEnum.HOLOPTICS &&
    (controlState.displayedFlux?.cameraId === HolopticsSubCameraEnum.MWIR ||
      controlState.displayedFlux?.cameraId === HolopticsSubCameraEnum.SWIR);

  if (controlState.previousSelectedCameraCode !== selectedCameraCode) {
    setControlState({
      displayedFlux: getDefaultFlux(cameraConfiguration),
      pipFlux:
        cameraConfiguration?.subCameras.filter((sc) => sc.code !== getDefaultFlux(cameraConfiguration)?.code) ?? [],
      previousSelectedCameraCode: selectedCameraCode,
    });
  }

  useEffect(() => {
    if (joystickControlId === uniqueKey) {
      updateJoystickControlledCamWithDispatch({
        cameraUniqueCode: selectedCameraCode,
        subCameraId: controlState.displayedFlux?.cameraId,
      });
    }
  }, [
    selectedCameraCode,
    joystickControlId,
    uniqueKey,
    updateJoystickControlledCamWithDispatch,
    updateJoystickControlIdWithDispatch,
    controlState.displayedFlux?.cameraId,
  ]);

  const trackAt = useCallback(
    (clickPosition: { x: number; y: number }, resX: number, resY: number) => {
      if (
        !selectedCameraCode ||
        controlState.displayedFlux === null ||
        dimension16_9 === null ||
        effectiveVideoDimension === null
      ) {
        return;
      }
      if (trackingStateOfSelectedFlux !== TrackingStatusEnum.DISABLED) {
        if (cameraConfiguration?.type === SensorTypeEnum.HOLOPTICS) {
          const holopticsIrStripsWidth = isHolopticsIrCamera
            ? (dimension16_9.width - effectiveVideoDimension.width) / 2
            : 0;

          const videoBox: VideoBox = {
            x: +((clickPosition.x + holopticsIrStripsWidth) / effectiveVideoDimension.width).toFixed(5) * resX,
            y: +(clickPosition.y / effectiveVideoDimension.height).toFixed(5) * resY,
            width: trackingBoxDimensions.width,
            height: trackingBoxDimensions.height,
          };
          useCamera.videoTracking(videoBox, TrackingStatusEnum.ACTIVE);
          updateTrackingState({
            cameraCode: selectedCameraCode,
            fluxId: controlState.displayedFlux.cameraId,
            state: TrackingStatusEnum.ACTIVE,
          });
        } else {
          console.warn('ZMER video tracking soon to be implemented');
        }
      } else {
        useCamera.videoTracking(null, TrackingStatusEnum.PENDING);
        updateTrackingState({
          cameraCode: selectedCameraCode,
          fluxId: controlState.displayedFlux.cameraId,
          state: TrackingStatusEnum.PENDING,
        });
      }
    },
    [
      cameraConfiguration?.type,
      dimension16_9,
      effectiveVideoDimension,
      isHolopticsIrCamera,
      selectedCameraCode,
      controlState.displayedFlux,
      trackingBoxDimensions.height,
      trackingBoxDimensions.width,
      trackingStateOfSelectedFlux,
      updateTrackingState,
      useCamera,
    ],
  );

  const pointAt = useCallback(
    (clickPosition: { x: number; y: number }) => {
      const displayedVideoStatus = cameraStatus?.videoStatuses.find(
        (vs) => vs.id === controlState.displayedFlux?.cameraId,
      );
      if (
        !effectiveVideoDimension ||
        controlState.displayedFlux === null ||
        displayedVideoStatus === undefined ||
        cameraStatus === null
      ) {
        return;
      }
      const currentAzimuth = cameraStatus.pan && modulo(cameraStatus.pan, 360);
      const currentSite = cameraStatus.tilt;

      const fovXDeg = displayedVideoStatus.fov;

      if (fovXDeg === null || currentAzimuth === null || currentSite === null) {
        return;
      }
      const fovX = degreesToRadians(fovXDeg);
      const fovY = (fovX * effectiveVideoDimension.height) / effectiveVideoDimension.width;

      // relative position of the click to the center, from -1 to 1 (0 is the center)
      const x = (2 * clickPosition.x) / effectiveVideoDimension.width - 1;
      const y = 1 - (2 * clickPosition.y) / effectiveVideoDimension.height;
      const deltaAzimuth = Math.atan(x * Math.tan(fovX / 2));
      const deltaSite = Math.atan(y * Math.tan(fovY / 2));
      const deltaAzimuthDeg = radiansToDegrees(deltaAzimuth);
      const deltaSiteDeg = radiansToDegrees(deltaSite);
      const targetAzimuth = modulo(currentAzimuth + deltaAzimuthDeg, 360);
      const targetSite = currentSite + deltaSiteDeg;

      useCamera.setAt(targetAzimuth, targetSite);
    },
    [cameraStatus, effectiveVideoDimension, controlState.displayedFlux, useCamera],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (controlState.displayedFlux === null || componentDimension === null || effectiveVideoDimension === null) {
        return;
      }
      //this is the position of the click from the top left of the effective video area.
      const effectiveClickPosition = {
        x: e.clientX - e.currentTarget.getBoundingClientRect().left,
        y: e.clientY - e.currentTarget.getBoundingClientRect().top,
      };

      const resX = controlState.displayedFlux.resolutionX;
      const resY = controlState.displayedFlux.resolutionY;

      if (e.shiftKey) {
        trackAt(effectiveClickPosition, resX, resY);
      } else {
        pointAt(effectiveClickPosition);
      }
    },
    [controlState.displayedFlux, componentDimension, effectiveVideoDimension, trackAt, pointAt],
  );

  function getTrackingCrosshairTransform(box: VideoBox) {
    // box contains x,y position of the target relative to native flux (native resolution with strips for holoptics IR)
    if (
      componentDimension === null ||
      dimension16_9 === null ||
      effectiveVideoDimension === null ||
      controlState.displayedFlux === null
    ) {
      return '';
    }

    const screenX = (box.x * effectiveVideoDimension.width) / controlState.displayedFlux.resolutionX;
    const screenY = (box.y * effectiveVideoDimension.height) / controlState.displayedFlux.resolutionY;

    const stripsSize1 = {
      width: (componentDimension.width - effectiveVideoDimension.width) / 2,
      height: (componentDimension.height - effectiveVideoDimension.height) / 2,
    };
    const holopticsIrStripsWidth = isHolopticsIrCamera ? (dimension16_9.width - effectiveVideoDimension.width) / 2 : 0;
    const totalTranslate = {
      x: screenX + stripsSize1.width - holopticsIrStripsWidth - componentDimension.width / 2,
      y: screenY + stripsSize1.height - componentDimension.height / 2,
    };

    return `translate(${totalTranslate.x}px,${totalTranslate.y}px)`;
  }

  function onMoveVirtualJoystick(data: JoystickData) {
    const angle = data.angle.degree;
    //The virtual joystick's magnitude is based on half of the nipple container size hence the division by JOYSTICK_CONTAINER_SIZE / 2
    const dx = (Math.cos((angle * Math.PI) / 180) * data.distance) / (JOYSTICK_CONTAINER_SIZE / 2);
    const dy = (Math.sin((angle * Math.PI) / 180) * data.distance) / (JOYSTICK_CONTAINER_SIZE / 2);
    useCamera.moveJoystick(dx, dy);
  }

  const effectiveVideoPlayerWidth =
    controlState.displayedFlux && componentDimension && dimension16_9
      ? Math.min(
          dimension16_9.width,
          dimension16_9.height * (controlState.displayedFlux.resolutionX / controlState.displayedFlux.resolutionY),
        )
      : 0;
  const effectiveVideoPlayerHeight =
    controlState.displayedFlux && componentDimension && dimension16_9
      ? Math.min(
          dimension16_9.height,
          dimension16_9.width * (controlState.displayedFlux.resolutionY / controlState.displayedFlux.resolutionX),
        )
      : 0;

  const playerId = `control-player-${uniqueKey}`;
  const displayPlayer = useCallback(() => {
    if (selectedCameraCode && cameraStatus && controlState.displayedFlux) {
      return (
        <Center width="100%" height="100%" position="relative" ref={componentRef}>
          <Box
            width={`min(100%, calc(${componentDimension?.height ?? 0}px * ${DEFAULT_CAMERA_RATIO}))`}
            height={`min(100%, calc(${componentDimension?.width ?? 0}px * ${1 / DEFAULT_CAMERA_RATIO}))`}
            position="relative"
            ref={ref16_9}
          >
            <Player
              id={playerId}
              displayMessageCentered={!controlState.displayCross}
              src={getNimbleStreamUrl(controlState.displayedFlux, replayMode)}
              type={controlState.displayedFlux.protocol ?? controlState.displayedFlux.sourceProtocol}
              replayMode={replayMode}
            />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              width={`${effectiveVideoPlayerWidth}px`}
              height={`${effectiveVideoPlayerHeight}px`}
              ref={effectiveVideoRef}
              onClick={handleClick}
            />
          </Box>
        </Center>
      );
    }

    const statusMessage = !cameraStatus ? 'cameras.noCameraStatus' : 'cameras.noFlux';

    return (
      <AbsoluteCenter>
        <Text transform={controlState.displayCross ? 'translateY(100px)' : undefined}>
          <FormattedMessage id={!selectedCameraCode ? 'cameras.noSelectedCamera' : statusMessage} />
        </Text>
      </AbsoluteCenter>
    );
  }, [
    selectedCameraCode,
    cameraStatus,
    controlState.displayedFlux,
    controlState.displayCross,
    componentRef,
    componentDimension?.height,
    componentDimension?.width,
    ref16_9,
    playerId,
    replayMode,
    effectiveVideoPlayerWidth,
    effectiveVideoPlayerHeight,
    effectiveVideoRef,
    handleClick,
  ]);

  const pan360: number = cameraStatus?.pan ? Number(modulo(cameraStatus.pan, 360).toFixed(2)) : 0;

  return (
    <>
      <CameraBorder hasControl={joystickControlId === uniqueKey} />
      <CameraControlTitle replayMode={replayMode} />
      {displayPlayer()}
      {cameraStatus && (
        <Box top={replayMode ? '85px' : '50px'} position="absolute" pointerEvents="none">
          <CameraStatusInfo cameraStatus={cameraStatus} cameraId={controlState.displayedFlux?.cameraId} />
        </Box>
      )}
      {cameraStatus && (
        <>
          {(controlState.gaugeStyle === GaugeStyleEnum.BOTH || controlState.gaugeStyle === GaugeStyleEnum.GAUGE) && (
            <>
              <HorizontalGauge
                pan={pan360}
                fov={
                  displayedVideoStatus?.fov && displayedVideoStatus.fov > 0
                    ? Number(displayedVideoStatus.fov.toFixed(1))
                    : 0
                }
                cameraPosition={cameraStatus.configuration.sensorPosition}
                replayMode={replayMode}
              />
              <VerticalGauge
                tilt={cameraStatus.tilt !== null ? Number(cameraStatus.tilt.toFixed(2)) : 0}
                fov={calculateVerticalFov(
                  displayedVideoStatus?.fov,
                  controlState.displayedFlux?.resolutionX,
                  controlState.displayedFlux?.resolutionY,
                )}
                min={controlState.displayedFlux ? Math.max(controlState.displayedFlux.minViewAngle, -90) : -90}
                max={controlState.displayedFlux ? Math.min(controlState.displayedFlux.maxViewAngle, 90) : 90}
                cameraPosition={cameraStatus.configuration.sensorPosition}
                cameraAltitude={cameraStatus.configuration.sensorAltitude}
                replayMode={replayMode}
              />
            </>
          )}
          {trackingStateOfSelectedFlux === TrackingStatusEnum.PENDING && (
            <FormLabel variant="mapFormLabel" position="absolute" bottom={9} left="50%" transform="translateX(-50%)">
              <FormattedMessage id="cameras.cameraMenu.buffered" />
            </FormLabel>
          )}
          {controlState.displayVirtualJoystick && (
            <ReactNipple
              options={{
                mode: 'static',
                position: {
                  bottom: `${110 + JOYSTICK_CONTAINER_SIZE / 2}px`,
                  right: `${56 + JOYSTICK_CONTAINER_SIZE / 2}px`,
                },
                size: JOYSTICK_CONTAINER_SIZE,
              }}
              className="custom-nipple"
              onMove={(_e: unknown, data: unknown) => onMoveVirtualJoystick(data as JoystickData)}
              onEnd={() => useCamera.move({ panSpeed: 0, tiltSpeed: 0 })}
            />
          )}
          {(controlState.gaugeStyle === GaugeStyleEnum.BOTH || controlState.gaugeStyle === GaugeStyleEnum.COMPASS) && (
            <HStack gap={9.5} position="absolute" left="24px" bottom="110px">
              <ElevationCompass
                tilt={cameraStatus.tilt !== null ? Number(cameraStatus.tilt.toFixed(2)) : 0}
                fov={calculateVerticalFov(
                  displayedVideoStatus?.fov,
                  controlState.displayedFlux?.resolutionX,
                  controlState.displayedFlux?.resolutionY,
                )}
                replayMode={replayMode}
                min={controlState.displayedFlux ? Math.max(controlState.displayedFlux.minViewAngle, -90) : -90}
                max={controlState.displayedFlux ? Math.min(controlState.displayedFlux.maxViewAngle, 90) : 90}
                cameraAltitude={cameraStatus.configuration.sensorAltitude}
                cameraPosition={cameraStatus.configuration.sensorPosition}
              />
              <AzimuthCompass
                pan={pan360}
                replayMode={replayMode}
                cameraPosition={cameraStatus.configuration.sensorPosition}
                fov={
                  displayedVideoStatus?.fov && displayedVideoStatus.fov > 0
                    ? Number(displayedVideoStatus.fov.toFixed(1))
                    : 0
                }
              />
            </HStack>
          )}
        </>
      )}
      {controlState.pipFlux.length > 0 && (
        <PipContainer state={controlState} setState={setControlState} replayMode={replayMode} uniqueKey={uniqueKey} />
      )}
      {controlState.displayCross && (
        <AbsoluteCenter pointerEvents="none">
          <Crosshair />
        </AbsoluteCenter>
      )}
      {trackingStateOfSelectedFlux === TrackingStatusEnum.ACTIVE &&
        detectedObjects &&
        detectedObjects.map((detection) => (
          <AbsoluteCenter pointerEvents="none" key={`${detection.id}${controlState.displayedFlux?.cameraId}`}>
            <TrackingCrosshair
              style={{
                transform: getTrackingCrosshairTransform(detection.box),
                width: trackingBoxDimensions.width,
                height: trackingBoxDimensions.height,
              }}
            />
          </AbsoluteCenter>
        ))}
    </>
  );
}

export default CameraControl;
