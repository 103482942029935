import { ReactComponent as DroneSureIcon } from '@assets/components/panel/alert/alert-drone-icon-sure.svg';
import { ReactComponent as DroneUnsureIcon } from '@assets/components/panel/alert/alert-drone-icon-unsure.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack, VStack } from '@chakra-ui/layout';
import { Text, Tooltip } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { getAlertColor, getAlertStatusMessageId } from '@utils/data/notification.utils';
import { extendedSensorShortHand, targetColor } from '@utils/map/map.constants';
import { getTargetId } from '@utils/target.utils';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertLevelEnum, SpaceViolationAlert } from '@/types/data/data.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';

import AlertTime from './AlertTime';

type Props = {
  alert: SpaceViolationAlert;
  confirmMode: boolean;
};

export default function TargetAlertCard({ alert, confirmMode }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const lightColor = getAlertColor(alert.level);

  const uniqueSensorsTypes = Array.from(
    new Set(alert.trackIds.map((track) => extendedSensorShortHand[track.sensorType])),
  ).join(', ');
  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(alert.marks)),
    isEqual,
  ).join(', ');
  const targetId = getTargetId(alert.trackIds);

  return (
    <>
      <Icon
        as={alert.reliability === ReliabilityEnum.SURE ? DroneSureIcon : DroneUnsureIcon}
        width="70px"
        height="70px"
        color={alert.classification ? targetColor[alert.classification] : getAlertColor(alert.level)}
      />
      <VStack gap={0} height="100%" width="100%" paddingRight={2}>
        <HStack gap={2} height="50%" width="100%">
          <HStack gap={2} width="350px" flexShrink={0}>
            <Text fontSize="18px">
              <FormattedMessage id="notification.droneDetected" />
            </Text>
            <Text fontSize="18px" fontWeight="medium" color={lightColor}>
              <FormattedMessage id={`notification.${alert.level.toLocaleLowerCase()}`} />
            </Text>
          </HStack>
          <AlertTime startTime={alert.startTime} endTime={alert.endTime} compact={confirmMode} />
        </HStack>
        <HStack gap={1} height="50%" fontWeight="medium" width="100%" fontSize="12px">
          <VStack width="198px" alignItems="start" gap="6px">
            <Text
              backgroundColor={lightColor}
              color={alert.level === AlertLevelEnum.CRITICAL ? 'neutral.white' : 'neutral.black'}
              paddingX={0.5}
              paddingBottom="1px"
              fontSize="12px"
              fontWeight="medium"
            >
              <FormattedMessage id={getAlertStatusMessageId(alert)} />
            </Text>
            <Tooltip label={targetId}>
              <Text fontSize="12px" fontWeight="medium" isTruncated maxWidth="100%" backgroundColor="neutral.900">
                {targetId}
              </Text>
            </Tooltip>
          </VStack>
          <VStack width="184px" alignItems="start" gap="6px">
            <HStack width="184px" alignItems="start" gap={0.5}>
              <Text fontSize="12px" fontWeight="medium">
                <FormattedMessage
                  id={`notification.${alert.reliability ? alert.reliability.toLocaleLowerCase() : 'unsure'}`}
                />
              </Text>
              <Tooltip label={uniqueSensorsTypes}>
                <Text fontSize="12px" fontWeight="medium" isTruncated>
                  ({uniqueSensorsTypes})
                </Text>
              </Tooltip>
            </HStack>
            <Text fontSize="12px" fontWeight="medium" height="15px">
              {
                //TODO missing information, remove height="15px" when done
              }
            </Text>
          </VStack>
          <VStack maxWidth="184px" alignItems="start" flexShrink={1} gap="6px">
            <Text
              paddingX={0.5}
              paddingBottom="1px"
              backgroundColor="alertHi.900"
              fontSize="12px"
              fontWeight="medium"
              noOfLines={1}
            >
              {platformsNames || formatMessage({ id: 'components.alert.noPlatform' })}
            </Text>
            <HStack>
              <Text fontSize="12px" fontWeight="medium">
                <FormattedMessage id="notification.altitude" />
              </Text>
              <Text
                backgroundColor={lightColor}
                color={alert.level === AlertLevelEnum.CRITICAL ? 'neutral.white' : 'neutral.black'}
                paddingX={0.5}
                paddingBottom="1px"
                fontSize="12px"
                fontWeight="medium"
              >
                {alert.targetAltitude !== null ? `${alert.targetAltitude.toFixed(0)} m` : 'N/A'}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}
