import { NO_FLY_ZONE_OPACITY, SELECTED_FEATURE_COLOR, ZONE_POLYGONS_OPACITY } from '@utils/map/map.constants';
import { FillPaint, LinePaint } from 'mapbox-gl';
import { FillLayer, LineLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

const zoneFillPaint: FillPaint = {
  'fill-color': [
    'case',
    ['boolean', ['feature-state', 'isSelected'], false],
    SELECTED_FEATURE_COLOR,
    ['case', ['get', 'active'], ['get', 'fillColor'], 'transparent'],
  ],
  'fill-opacity': ZONE_POLYGONS_OPACITY,
};

const zoneOutlinePaint: LinePaint = {
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'isSelected'], false],
    SELECTED_FEATURE_COLOR,
    ['get', 'outlineColor'],
  ],
  'line-opacity': 0.8,
  'line-width': 3,
  'line-dasharray': ['case', ['boolean', ['get', 'active'], false], [0, 0], [2, 2]],
};

export const noInitZoneFillLayer: FillLayer = {
  id: LayerNameEnum.NO_INIT_ZONE_FILLS,
  type: 'fill',
  source: SourceNameEnum.ZONES,
  filter: ['==', 'featureType', FeatureTypeEnum.NO_INIT_ZONE],
  paint: { ...zoneFillPaint },
};

export const noInitZoneOutlineLayer: LineLayer = {
  id: LayerNameEnum.NO_INIT_ZONE_OUTLINES,
  type: 'line',
  source: SourceNameEnum.ZONES,
  filter: ['==', 'featureType', FeatureTypeEnum.NO_INIT_ZONE],
  paint: { ...zoneOutlinePaint },
};

export const noFlyZoneFillLayer: FillLayer = {
  id: LayerNameEnum.NO_FLY_ZONE_FILLS,
  type: 'fill',
  source: SourceNameEnum.ZONES,
  filter: ['in', 'featureType', FeatureTypeEnum.NO_FLY_ZONE, FeatureTypeEnum.NO_FLY_ZONE_3D],
  paint: { ...zoneFillPaint, 'fill-opacity': NO_FLY_ZONE_OPACITY },
};

export const noFlyZoneOutlineLayer: LineLayer = {
  id: LayerNameEnum.NO_FLY_ZONE_OUTLINES,
  type: 'line',
  source: SourceNameEnum.ZONES,
  filter: ['in', 'featureType', FeatureTypeEnum.NO_FLY_ZONE, FeatureTypeEnum.NO_FLY_ZONE_3D],
  paint: { ...zoneOutlinePaint },
};

export const initializationMaskingZoneFillLayer: FillLayer = {
  id: LayerNameEnum.INITIALIZATION_MASKING_ZONE_FILLS,
  type: 'fill',
  source: SourceNameEnum.ZONES,
  filter: ['==', 'featureType', FeatureTypeEnum.INITIALIZATION_MASKING_ZONE],
  paint: { ...zoneFillPaint },
};

export const initializationMaskingZoneOutlineLayer: LineLayer = {
  id: LayerNameEnum.INITIALIZATION_MASKING_ZONE_OUTLINES,
  type: 'line',
  source: SourceNameEnum.ZONES,
  filter: ['==', 'featureType', FeatureTypeEnum.INITIALIZATION_MASKING_ZONE],
  paint: { ...zoneOutlinePaint },
};

export const initializationMaskingZoneMarginLayer: FillLayer = {
  id: LayerNameEnum.INITIALIZATION_MASKING_ZONE_MARGINS,
  type: 'fill',
  source: SourceNameEnum.ZONES,
  filter: ['==', 'featureType', FeatureTypeEnum.INITIALIZATION_MASKING_ZONE_MARGIN],
  paint: { ...zoneFillPaint },
};

export const initializationMaskingZoneMarginOutlineLayer: LineLayer = {
  id: LayerNameEnum.INITIALIZATION_MASKING_ZONE_MARGIN_OUTLINES,
  type: 'line',
  source: SourceNameEnum.ZONES,
  filter: ['==', 'featureType', FeatureTypeEnum.INITIALIZATION_MASKING_ZONE_MARGIN],
  paint: { ...zoneOutlinePaint },
};
