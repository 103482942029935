import { VStack } from '@chakra-ui/layout';
import MenuList from '@components/common/menu/MenuList';
import { useMapContext } from '@components/map/MapContext';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { closePopupByMapId, switchDrawModeByMapId, updateEditPolygonsData } from '@redux/maps/maps.reducer';
import { selectMapEditPolygonsInfo, selectPopupControlByMapId, selectZoneViewMode } from '@redux/maps/maps.selectors';
import {
  useDeleteInitializationMaskingZoneMutation,
  useUpdateInitializationMaskingZoneMutation,
} from '@services/config/initializationMaskingZone.api';
import { useDeleteNoFlyZoneMutation, useUpdateNoFlyZoneMutation } from '@services/config/noFlyZone.api';
import { useDeleteNoFlyZone3dMutation, useUpdateActiveNoFlyZone3dMutation } from '@services/config/noFlyZone3d.api';
import { useDeleteNoInitZoneMutation, useUpdateNoInitZonesMutation } from '@services/config/noInitZone.api';
import { isInitializationMaskingZone, isNoFlyZone, isNoFlyZone3d, isNoInitZone } from '@utils/map/zone.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useIntl } from 'react-intl';

import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  ZoneCategoryEnum,
  ZoneToEditType,
} from '@/types/config/config.types';
import { PopupDataTypeEnum } from '@/types/map.types';
import { MenuItems } from '@/types/menu.types';

interface ZoneContextMenuProps {
  clickPosition: { x: number; y: number } | null;
  selectedZone: NoFlyZone | NoInitZone | InitializationMaskingZone | NoFlyZone3d;
  onClose: () => void;
}

function ZoneContextMenu({ selectedZone, onClose }: Readonly<ZoneContextMenuProps>) {
  const { formatMessage } = useIntl();
  const { mapId } = useMapContext();
  const dispatchSwitchDrawModeByMapId = useWithDispatch(switchDrawModeByMapId);
  const dispatchUpdateEditPolygonsData = useWithDispatch(updateEditPolygonsData);
  const editedZone = useSelectorWithMapId(selectMapEditPolygonsInfo);
  const viewMode = useSelectorWithMapId(selectZoneViewMode);
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const [updateNoFlyZone] = useUpdateNoFlyZoneMutation();
  const [updateActiveNoFlyZone3d] = useUpdateActiveNoFlyZone3dMutation();
  const [updateNoInitZone] = useUpdateNoInitZonesMutation();
  const [updateImz] = useUpdateInitializationMaskingZoneMutation();

  const [deleteNoFlyZone] = useDeleteNoFlyZoneMutation();
  const [deleteActiveNoFlyZone3d] = useDeleteNoFlyZone3dMutation();
  const [deleteNoInitZone] = useDeleteNoInitZoneMutation();
  const [deleteImz] = useDeleteInitializationMaskingZoneMutation();

  const closePopupControl = useWithDispatch(closePopupByMapId);
  const isMeasureActive = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const handleActivate = () => {
    let promise: Promise<NoFlyZone | void | InitializationMaskingZone | NoInitZone> = Promise.resolve();
    if (isNoFlyZone(selectedZone)) {
      promise = updateNoFlyZone({
        id: selectedZone.id,
        nfz: { ...selectedZone, active: !selectedZone.active },
      }).unwrap();
    } else if (isNoFlyZone3d(selectedZone)) {
      promise = updateActiveNoFlyZone3d({
        id: selectedZone.id,
        active: !selectedZone.active,
      }).unwrap();
    } else if (isNoInitZone(selectedZone)) {
      promise = updateNoInitZone({
        id: selectedZone.id,
        niz: { ...selectedZone, active: !selectedZone.active },
      }).unwrap();
    } else if (isInitializationMaskingZone(selectedZone)) {
      promise = updateImz({
        id: selectedZone.id,
        imz: { ...selectedZone, active: !selectedZone.active },
      }).unwrap();
    }
    promise
      .then(() =>
        createToast(
          formatMessage(
            { id: 'zone.toastMessage' },
            { name: selectedZone.name, active: selectedZone.active, success: true },
          ),
          ToastStatusEnum.SUCCESS,
        ),
      )
      .catch(() =>
        createToast(
          formatMessage(
            { id: 'zone.toastMessage' },
            { name: selectedZone.name, active: selectedZone.active, success: false },
          ),
          ToastStatusEnum.ERROR,
        ),
      );
    onClose();
  };

  const handleDelete = () => {
    let promise: Promise<NoFlyZone | void | InitializationMaskingZone | NoInitZone> = Promise.resolve();
    if (isNoFlyZone(selectedZone)) {
      promise = deleteNoFlyZone(selectedZone.id).unwrap();
    } else if (isNoFlyZone3d(selectedZone)) {
      promise = deleteActiveNoFlyZone3d(selectedZone.id).unwrap();
    } else if (isNoInitZone(selectedZone)) {
      promise = deleteNoInitZone(selectedZone.id).unwrap();
    } else if (isInitializationMaskingZone(selectedZone)) {
      promise = deleteImz(selectedZone.id).unwrap();
    }
    promise
      .then(() =>
        createToast(
          formatMessage({ id: 'zone.deleteToastMessage' }, { name: selectedZone.name, success: true }),
          ToastStatusEnum.SUCCESS,
        ),
      )
      .catch(() =>
        createToast(
          formatMessage({ id: 'zone.deleteToastMessage' }, { name: selectedZone.name, success: false }),
          ToastStatusEnum.ERROR,
        ),
      );
    onClose();
  };

  const handleZoneEdit = (zoneToEdit: ZoneToEditType, isAlreadyEditing: boolean) => {
    if (isMeasureActive) {
      closePopupControl(mapId);
    }
    dispatchSwitchDrawModeByMapId({ mapId });
    if (!isAlreadyEditing) {
      dispatchUpdateEditPolygonsData({
        mapId,
        zone: zoneToEdit,
      });
    }
  };

  if (!viewMode) {
    onClose();
  }

  const menuItems: MenuItems = {
    activateDeactivate: {
      label: formatMessage({ id: 'contextmenu.actions.activateDeactivate' }, { active: !selectedZone.active }),
      shouldNotTranslate: true,
      isDisabled: currentPopupControl.open && currentPopupControl.type === PopupDataTypeEnum.ZONE,
      onAction: handleActivate,
    },
    update: {
      label:
        editedZone && editedZone.code === selectedZone.code
          ? 'contextmenu.actions.updateZoneCancel'
          : 'contextmenu.actions.updateZone',
      isDisabled:
        selectedZone.type === ZoneCategoryEnum.NFZ_3D || (editedZone && editedZone.code !== selectedZone.code),
      onAction: () => {
        const zoneToEdit = selectedZone as ZoneToEditType;
        handleZoneEdit(zoneToEdit, !!editedZone);
      },
    },
    delete: {
      label: 'contextmenu.actions.deleteZone',
      isDisabled: currentPopupControl.open && currentPopupControl.type === PopupDataTypeEnum.ZONE,
      onAction: handleDelete,
    },
  };

  return (
    <VStack gap={0}>
      <MenuList
        menuItems={menuItems}
        depth={0}
        handleClick={(_, value) => value?.onAction && value.onAction()}
        selectedOptions={[]}
      />
    </VStack>
  );
}

export default ZoneContextMenu;
