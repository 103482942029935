import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomChipList from '@components/common/inputs/CustomChipList';
import { cameraLiteToOption } from '@utils/sensors/camera/camera.utils';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CameraLiteConfiguration, SubCameraLiteConfiguration } from '@/types/sensor/configuration.types';

export interface CameraInfoProps {
  camera: CameraLiteConfiguration;
  onChange: (subCameraList: SubCameraLiteConfiguration[], parentCameraCode: string) => void;
  currentSelectedSubCams: SubCameraLiteConfiguration[]; //Liste des sous caméra cochées dans la caméra courante
}

function CameraInfo({ camera, onChange, currentSelectedSubCams }: Readonly<CameraInfoProps>) {
  const { formatMessage } = useIntl();

  return (
    <HStack marginTop={1}>
      <Text width="max-content">
        {cameraLiteToOption(camera, formatMessage({ id: 'videoExport.deletedCam' })).label}
      </Text>
      {camera.subCameras.length > 0 ? (
        <CustomChipList
          availableOptions={camera.subCameras.map((subCam) => cameraLiteToOption(subCam))}
          selectedOptions={currentSelectedSubCams.map((subCam) => cameraLiteToOption(subCam))}
          //Pour retourner une liste de sous cam et pas juste les codes :
          onChange={(selectedSubCamerasCodes: string[]) =>
            onChange(
              camera.subCameras.filter((subcam) => selectedSubCamerasCodes.includes(subcam.code)),
              camera.code,
            )
          }
        />
      ) : (
        <FormattedMessage id="videoExport.noSubCam" />
      )}
    </HStack>
  );
}

export default memo(CameraInfo);
