import { HStack, Stack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { formatTimeTwoDigits, minutesToTime } from '@utils/date.utils';
import { eventColor } from '@utils/replay/event.utils';
import { formatCoordinates } from '@utils/validation/coordinates.utils';
import format from 'date-fns/format';
import DmsCoordinates from 'dms-conversion';
import { capitalize } from 'lodash';
import { FaCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';

import { FeatureType, FeatureTypeEnum } from '@/types/map.types';
import { ReplayEvent } from '@/types/replay/replay.types';

export interface EventTooltipData extends FeatureType {
  type: FeatureTypeEnum.EVENT_ICON;
  data: ReplayEvent;
}

function EventTooltip({ data }: Readonly<EventTooltipData>) {
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const coordinates = new DmsCoordinates(data.position!.latitude, data.position!.longitude);
  const [durationHours, durationMinutes] = minutesToTime(data.duration);
  const { formatMessage } = useIntl();
  return (
    <VStack gap={1.5} alignItems="start">
      <Stack gap={0}>
        <Text fontSize="16px">
          <FormattedMessage id="live.event.name" />
        </Text>
        <Text fontSize="16px">{capitalize(data.name)}</Text>
      </Stack>
      <Stack gap={0}>
        <Text fontSize="16px">
          <FormattedMessage id="live.event.type" />
        </Text>
        <HStack>
          <FaCircle color={eventColor[data.observationType]} />
          <Text fontSize="16px">{formatMessage({ id: `live.event.types.${data.observationType}` })}</Text>
        </HStack>
      </Stack>
      <Stack gap={0}>
        <Text fontSize="16px">
          <FormattedMessage id="live.event.location" />
        </Text>
        <HStack>
          <Text>
            <FormattedMessage id="targetInfos.latLong" />
          </Text>
          <Text fontSize="16px" fontWeight="600">
            {formatCoordinates(coordinates, userSettingsUnit)}
          </Text>
        </HStack>
      </Stack>
      <Stack gap={0}>
        <Text fontSize="16px">
          <FormattedMessage id="live.event.dateTime" />
        </Text>
        <Text fontSize="16px">{format(new Date(data.startDate), "dd/MM/yyyy' 'HH'h'mm")}</Text>
      </Stack>
      <Stack gap={0}>
        <Text fontSize="16px">
          <FormattedMessage id="live.event.duration" />
        </Text>
        <Text fontSize="16px">
          {formatTimeTwoDigits(durationHours.toString())}h{formatTimeTwoDigits(durationMinutes.toString())}
        </Text>
      </Stack>
      <Stack gap={0}>
        <Text fontSize="16px">
          <FormattedMessage id="live.event.description" />
        </Text>
        <Text fontSize="14px">{data.comment ? data.comment : formatMessage({ id: `live.event.noDescription` })}</Text>
      </Stack>
    </VStack>
  );
}

export default EventTooltip;
