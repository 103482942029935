import { ReactComponent as AlertDefault } from '@assets/components/panel/platform/alert-default.svg';
import { ReactComponent as AlertHi } from '@assets/components/panel/platform/alert-hi.svg';
import { ReactComponent as AlertLo } from '@assets/components/panel/platform/alert-lo.svg';
import { ReactComponent as AlertMid } from '@assets/components/panel/platform/alert-mid.svg';
import { Center, HStack } from '@chakra-ui/layout';
import { Icon, Text } from '@chakra-ui/react';
import CustomCheckbox from '@components/common/inputs/CustomCheckbox';

import type { Platform } from '@/types/config/config.types';
import { AlertLevelEnum } from '@/types/data/data.types';

export type AlertInfo = {
  count: number;
  maxLevel: AlertLevelEnum | null;
};

type Props = {
  platform: Platform;
  alerts: {
    LAD: AlertInfo;
    PERIM: AlertInfo;
    TECH: AlertInfo;
  };
};

function getIcon(alertInfo: AlertInfo) {
  const { count, maxLevel } = alertInfo;
  if (count === 0) {
    return <Icon as={AlertDefault} width="10px" height="10px" />;
  } else {
    const midOrHiIcon = maxLevel === AlertLevelEnum.WARNING ? AlertMid : AlertHi;
    return (
      <>
        <Icon as={maxLevel === AlertLevelEnum.INFO ? AlertLo : midOrHiIcon} width="28px" height="28px" />
        <Text fontWeight="medium" position="absolute">
          {count}
        </Text>
      </>
    );
  }
}

export default function Platform({ platform, alerts }: Readonly<Props>) {
  return (
    <HStack gap={2} paddingX={2} paddingY={0.5} width="100%" height="48px">
      <CustomCheckbox isChecked={true} isDisabled />
      <Text fontWeight="medium" noOfLines={2}>
        {platform.name}
      </Text>
      <HStack paddingX={0.5} gap={2} marginLeft="auto">
        <Center width="24px">{getIcon(alerts.LAD)}</Center>
        <Center width="24px">{getIcon(alerts.PERIM)}</Center>
        <Center width="24px">{getIcon(alerts.TECH)}</Center>
      </HStack>
    </HStack>
  );
}
