import { MapFilters } from '@/types/filters.types';

export const getFilteredList = <T>(
  list: T[],
  mapFilters: MapFilters,
  rule: (item: T, key: keyof MapFilters) => boolean,
) =>
  list.filter((listItem) =>
    Object.entries(mapFilters)
      .filter(([_, value]) => !value) // eslint-disable-line @typescript-eslint/no-unused-vars
      .map(([keyToHide]) => keyToHide)
      .every((keyToHide) => rule(listItem, keyToHide as keyof MapFilters)),
  );
