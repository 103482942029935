import './buttonStyle.scss';

import { ReactComponent as ButtonBarPanelHorizontal } from '@assets/components/button/button-bar-panel-horizontal.svg';
import { ReactComponent as ButtonBarPanelVertical } from '@assets/components/button/button-bar-panel-vertical.svg';
import { Icon } from '@chakra-ui/icon';
import { IconButton } from '@chakra-ui/react';
import classNames from 'classnames';
import { FunctionComponent, memo, SVGProps } from 'react';

type Props = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  orientation?: 'horizontal' | 'vertical';
  isDisabled?: boolean;
  isActive?: boolean;
  onClick: () => void;
};

function ButtonBarPanel({
  icon,
  isDisabled = false,
  isActive = false,
  orientation = 'horizontal',
  onClick,
}: Readonly<Props>) {
  if (orientation === 'vertical') {
    return (
      <IconButton
        aria-label="button-bar-panel"
        colorScheme="transparent"
        width="72px"
        height="100px"
        icon={
          <>
            <Icon as={ButtonBarPanelVertical} width="72px" height="100px" className="button-bar-panel-base" />
            <Icon
              as={icon}
              top={2}
              left={2}
              width="40px"
              height="40px"
              position="absolute"
              className="button-bar-panel-icon"
            />
          </>
        }
        borderRadius={0}
        position="relative"
        className={classNames('button-bar-panel', { isActive, isDisabled })}
        onClick={() => !isDisabled && onClick()}
      />
    );
  }
  return (
    <IconButton
      aria-label="button-bar-panel"
      colorScheme="transparent"
      width="78px"
      height="48px"
      icon={
        <>
          <Icon as={ButtonBarPanelHorizontal} width="78px" height="48px" className="button-bar-panel-base" />
          <Icon
            as={icon}
            top={1}
            left={1}
            width="32px"
            height="32px"
            position="absolute"
            className="button-bar-panel-icon"
          />
        </>
      }
      borderRadius={0}
      position="relative"
      className={classNames('button-bar-panel', { isActive, isDisabled })}
      onClick={() => !isDisabled && onClick()}
    />
  );
}

export default memo(ButtonBarPanel);
