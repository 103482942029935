import { FormatMessageType } from '@hooks/useValidation';
import { LIST, MIN_FIELD, REQUIRED_FIELD } from '@utils/validation/validation.constant';
import Yup from '@utils/validation/yup-extended';

import { Zone2DTypeEnum } from '@/types/config/config.types';

export function zoneSchema(formatMessage: FormatMessageType) {
  return Yup.object({
    zoneName: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'zone.name' }).toLowerCase() }),
    ),

    zoneType: Yup.mixed<Zone2DTypeEnum>()
      .oneOf(Object.values(Zone2DTypeEnum) as Zone2DTypeEnum[], formatMessage({ id: LIST }))
      .required(formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'zone.type' }) })),

    marginSize: Yup.number().when('zoneType', {
      is: (value: Zone2DTypeEnum) => value === Zone2DTypeEnum.IMZ,
      then: (schema) =>
        schema
          .required(
            formatMessage(
              { id: REQUIRED_FIELD },
              { field: formatMessage({ id: 'zone.marginSize' }).toLocaleLowerCase() },
            ),
          )
          .min(
            0,
            formatMessage(
              { id: MIN_FIELD },
              {
                value: 0,
                field: formatMessage({ id: 'zone.marginSize' }).toLocaleLowerCase(),
              },
            ),
          ),
    }),

    upperLevelHeight: Yup.number().when(
      ['displayedFormat', 'lowerLevelHeight'],
      ([displayedFormat, lowerLevelHeight], schema) => {
        const minRequirement = !isNaN(lowerLevelHeight) ? lowerLevelHeight + 1 : -Infinity;
        return schema
          .min(
            minRequirement,
            formatMessage(
              { id: 'zone.upperError' },
              {
                field: formatMessage(
                  { id: 'zone.upperField' },
                  { field: formatMessage({ id: `zone.${displayedFormat}` }).toLowerCase() },
                ),
              },
            ),
          )
          .required(
            formatMessage(
              { id: REQUIRED_FIELD },
              {
                field: formatMessage(
                  { id: 'zone.upperField' },
                  { field: formatMessage({ id: `zone.${displayedFormat}` }).toLowerCase() },
                ),
              },
            ),
          );
      },
    ),

    lowerLevelHeight: Yup.number().when(['displayedFormat'], ([displayedFormat], schema) => {
      return schema.required(
        formatMessage(
          { id: REQUIRED_FIELD },
          {
            field: formatMessage(
              { id: 'zone.lowerField' },
              { field: formatMessage({ id: `zone.${displayedFormat}` }).toLowerCase() },
            ),
          },
        ),
      );
    }),
  });
}
