import bearing from '@turf/bearing';
import { Position } from '@turf/helpers';
import { bearingToAzimuth, length, lineString } from '@turf/turf';

import { Coordinates } from '@/types/commons/commons.types';

const calculateTotalDistance = (points: Position[]): number => {
  if (points.length < 2) return 0;
  const line = lineString(points.map((point) => [point[0], point[1]]));
  return Number(length(line, { units: 'meters' }).toFixed(0));
};

export const calculateAzimuth = (pointA: Position, pointB: Position): number => {
  return Number(bearingToAzimuth(bearing(pointA, pointB)).toFixed(2));
};

export const calculateMeasure = (mousePosition: Coordinates, measurePoints: Position[], isClickEnabled: boolean) => {
  if (measurePoints.length <= 0) {
    return [0, 0];
  }

  const distance = calculateTotalDistance([...measurePoints, [mousePosition.longitude, mousePosition.latitude]]);
  const azimuth = isClickEnabled
    ? calculateAzimuth(measurePoints.at(-1)!, [mousePosition.longitude, mousePosition.latitude])
    : calculateAzimuth(measurePoints.at(-2)!, measurePoints.at(-1)!);
  return [distance, azimuth];
};
