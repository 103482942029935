import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Button, CloseButton, Input, TabPanel, Text, useSize } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { LayoutSelectors, selectCurrentLayout, VideoStreamSelectors } from '@redux/config/config.selectors';
import { selectAllCamerasConfiguration, selectAllSubCameras } from '@redux/sensors/sensors.selectors';
import {
  useCreateGridLayoutMutation,
  useDeleteGridLayoutMutation,
  useUpdateGridLayoutMutation,
} from '@services/config/gridLayout.api';
import { updateItemInArrayByCode } from '@utils/common.utils';
import { generateHandles } from '@utils/config/config.utils';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { GridItem, GridItemComponentEnum, GridLayout } from '@/types/config/gridLayout.types';
import { MapIdEnum } from '@/types/map.types';

import CustomMultiSelect from '../../../../common/inputs/CustomMultiSelect';
import CustomSelect from '../../../../common/inputs/CustomSelect';
import GridComponentList from './GridComponentList';

function compareGridItem(gridItem1: GridItem, gridItem2: GridItem) {
  return (
    gridItem1.i === gridItem2.i &&
    gridItem1.x === gridItem2.x &&
    gridItem1.y === gridItem2.y &&
    gridItem1.h === gridItem2.h &&
    gridItem1.w === gridItem2.w &&
    gridItem1.component === gridItem2.component &&
    gridItem1.selectedCamera === gridItem2.selectedCamera &&
    gridItem1.innerGridWidth === gridItem2.innerGridWidth &&
    gridItem1.innerGridHeight === gridItem2.innerGridHeight &&
    isEqual(gridItem1.selectedStreams, gridItem2.selectedStreams) &&
    gridItem1.selectedMap === gridItem2.selectedMap
  );
}

function compareGridLayout(layout1: GridLayout, layout2: GridLayout) {
  return (
    layout1.resolution === layout2.resolution &&
    layout1.code === layout2.code &&
    layout1.nbCol === layout2.nbCol &&
    layout1.nbRow === layout2.nbRow &&
    layout1.items.length === layout2.items.length &&
    layout1.items.every((layout) => {
      const temp = layout2.items.find((item) => item.i === layout.i);
      return temp && compareGridItem(layout, temp);
    })
  );
}

const NB_ROWS = 36;
const NB_COLS = 64;

export default function GridLayoutConfiguration() {
  const { formatMessage } = useIntl();

  //This ref will be used to get the layout dimensions
  const elementRef = useRef<HTMLDivElement>(null);
  const dimensions = useSize(elementRef);

  const site = useAppSelector(selectActiveSite);
  const videoStreams = useAppSelector(VideoStreamSelectors.selectAllVideoStreams);
  const subCameras = useAppSelector(selectAllSubCameras);
  const cameras = useAppSelector(selectAllCamerasConfiguration);

  const emptyLayout: GridLayout = {
    id: -1,
    name: '',
    site: site?.code ?? 'local',
    code: uuidv4(),
    resolution: '16/9',
    nbCol: NB_COLS,
    nbRow: NB_ROWS,
    items: [],
    createdBy: '',
    creationTime: '',
    modifiedBy: '',
    modificationTime: '',
  };

  //Current selected layout used on the main grid component
  const selectedGridLayout = useAppSelector(selectCurrentLayout);

  //List of saved layouts
  const layouts = useAppSelector(LayoutSelectors.selectAllLayouts);

  //Current temporary list of configuration in this component
  const [currentLayouts, setCurrentLayouts] = useState<GridLayout[]>(layouts);

  //Current displayed layout in this component
  const [currentLayout, setCurrentLayout] = useState<GridLayout>(selectedGridLayout ?? emptyLayout);

  //Displayed layout based on the configuration
  const [layout, _setLayout] = useState<Layout[]>(currentLayout.items);

  //Save the type of the dragging component when we drag from the component list
  const [currentDragging, setCurrentDragging] = useState<Partial<GridItem>>();

  const [updateLayout, { isLoading: isLoadingUpdate }] = useUpdateGridLayoutMutation();
  const [createLayout, { isLoading: isLoadingCreate }] = useCreateGridLayoutMutation();
  const [deleteLayout, { isLoading: isLoadingDelete }] = useDeleteGridLayoutMutation();

  function updateCurrentLayout(config: GridLayout) {
    setCurrentLayout(config);
    setCurrentLayouts(updateItemInArrayByCode(currentLayouts, config));
  }

  function setLayout(value: Layout[], newItem?: GridItem) {
    _setLayout(value.concat(newItem ?? []));
    const updatedLayout = {
      ...currentLayout,
      items: currentLayout.items
        .filter((item) => value.some((e) => e.i === item.i))
        .map((item) => ({ ...item, ...value.find((e) => e.i === item.i) }))
        .concat(newItem ?? []),
    };
    setCurrentLayout(updatedLayout);
    if (currentLayout.code !== '') {
      setCurrentLayouts(updateItemInArrayByCode(currentLayouts, updatedLayout));
    }
  }

  function resetConfiguration(code: string) {
    const config = layouts.find((config) => config.code === code) ?? { ...emptyLayout, name: code, code };
    updateCurrentLayout(config);
    _setLayout(config.items);
  }

  const isNew = currentLayout.id === -1;

  const oldConfig = layouts.find((config) => config.code === currentLayout.code);
  const isEdited = oldConfig && !compareGridLayout(oldConfig, currentLayout);

  const canSave = (isNew && currentLayout.code !== '') || isEdited;

  const canDelete = selectedGridLayout?.code !== currentLayout.code && currentLayout.code !== '';

  useEffect(() => {
    setCurrentLayouts(layouts);
    const newCurrentLayout =
      layouts.find((item) => item.code === currentLayout.code) ?? selectedGridLayout ?? emptyLayout;
    setCurrentLayout(newCurrentLayout);
    _setLayout(newCurrentLayout.items);
  }, [layouts, selectedGridLayout]); // eslint-disable-line react-hooks/exhaustive-deps

  function displayContent(gridItem: GridItem) {
    switch (gridItem.component) {
      case GridItemComponentEnum.LAD_STREAM_GRID: {
        return (
          <VStack>
            <HStack width="100%" justifyContent="space-between">
              <Text>
                <FormattedMessage id={'form.columns'} />:{' '}
              </Text>
              <CustomSelect
                value={gridItem.innerGridWidth.toString()}
                isSearchable
                isClearable={false}
                options={[...Array(16).keys()].map((i) => ({
                  label: (i + 1).toString(),
                  value: (i + 1).toString(),
                }))}
                onChange={(value) => {
                  const config = {
                    ...currentLayout,
                    items: currentLayout.items.map((item) => {
                      if (item.i === gridItem.i) {
                        return {
                          ...item,
                          innerGridWidth: Number(value),
                          selectedStreams: Number(value) !== gridItem.innerGridWidth ? [] : gridItem.selectedStreams,
                          component: GridItemComponentEnum.LAD_STREAM_GRID,
                        } as GridItem;
                      }
                      return item;
                    }),
                  };
                  updateCurrentLayout(config);
                  _setLayout(config.items);
                }}
              />
            </HStack>
            <HStack width="100%" justifyContent="space-between">
              <Text>
                <FormattedMessage id={'form.rows'} />:{' '}
              </Text>
              <CustomSelect
                value={gridItem.innerGridHeight.toString()}
                isSearchable
                isClearable={false}
                options={[...Array(16).keys()].map((i) => ({
                  label: (i + 1).toString(),
                  value: (i + 1).toString(),
                }))}
                onChange={(value) => {
                  const config = {
                    ...currentLayout,
                    items: currentLayout.items.map((item) => {
                      if (item.i === gridItem.i) {
                        return {
                          ...item,
                          innerGridHeight: Number(value),
                          selectedStreams: Number(value) !== gridItem.innerGridHeight ? [] : gridItem.selectedStreams,
                          component: GridItemComponentEnum.LAD_STREAM_GRID,
                        } as GridItem;
                      }
                      return item;
                    }),
                  };
                  updateCurrentLayout(config);
                  _setLayout(config.items);
                }}
              />
            </HStack>
            <CustomMultiSelect
              value={gridItem.selectedStreams}
              isClearable={false}
              options={[
                ...videoStreams.map((stream) => ({ label: stream.name, value: `stream_${stream.code}` })),
                ...subCameras.map((sub) => ({ label: sub.name, value: `camera_${sub.sensorId}_${sub.code}` })),
              ]}
              limit={gridItem.innerGridHeight * gridItem.innerGridWidth}
              size="sm"
              onChange={(values) => {
                const config = {
                  ...currentLayout,
                  items: currentLayout.items.map((item) => {
                    if (item.i === gridItem.i) {
                      return {
                        ...item,
                        selectedStreams: values,
                        component: GridItemComponentEnum.LAD_STREAM_GRID,
                      } as GridItem;
                    }
                    return item;
                  }),
                };
                updateCurrentLayout(config);
              }}
            />
          </VStack>
        );
      }
      case GridItemComponentEnum.PERIMETER_STREAM_GRID: {
        return (
          <VStack>
            <HStack width="100%" justifyContent="space-between">
              <Text color="neutral.black">
                <FormattedMessage id={'form.columns'} />:{' '}
              </Text>
              <CustomSelect
                value={gridItem.innerGridWidth.toString()}
                isSearchable
                isClearable={false}
                options={[...Array(16).keys()].map((i) => ({
                  label: (i + 1).toString(),
                  value: (i + 1).toString(),
                }))}
                onChange={(value) => {
                  const config = {
                    ...currentLayout,
                    items: currentLayout.items.map((item) => {
                      if (item.i === gridItem.i) {
                        return {
                          ...item,
                          innerGridWidth: Number(value),
                          component: GridItemComponentEnum.PERIMETER_STREAM_GRID,
                        } as GridItem;
                      }
                      return item;
                    }),
                  };
                  updateCurrentLayout(config);
                  _setLayout(config.items);
                }}
              />
            </HStack>
            <HStack width="100%" justifyContent="space-between">
              <Text color="neutral.black">
                <FormattedMessage id={'form.rows'} />:{' '}
              </Text>
              <CustomSelect
                value={gridItem.innerGridHeight.toString()}
                isSearchable
                isClearable={false}
                options={[...Array(16).keys()].map((i) => ({
                  label: (i + 1).toString(),
                  value: (i + 1).toString(),
                }))}
                onChange={(value) => {
                  const config = {
                    ...currentLayout,
                    items: currentLayout.items.map((item) => {
                      if (item.i === gridItem.i) {
                        return {
                          ...item,
                          innerGridHeight: Number(value),
                          component: GridItemComponentEnum.PERIMETER_STREAM_GRID,
                        } as GridItem;
                      }
                      return item;
                    }),
                  };
                  updateCurrentLayout(config);
                  _setLayout(config.items);
                }}
              />
            </HStack>
          </VStack>
        );
      }
      case GridItemComponentEnum.CAMERA_CONTROL: {
        return (
          <CustomSelect
            value={gridItem.selectedCamera ?? undefined}
            isSearchable
            options={cameras.map((camera) => ({ label: camera.name, value: getSensorUniqueCode(camera) }))}
            onChange={(value) => {
              const config = {
                ...currentLayout,
                items: currentLayout.items.map((item) => {
                  if (item.i === gridItem.i) {
                    return {
                      ...item,
                      component: GridItemComponentEnum.CAMERA_CONTROL,
                      selectedCamera: value,
                    } as GridItem;
                  }
                  return item;
                }),
              };
              updateCurrentLayout(config);
            }}
          />
        );
      }
      case GridItemComponentEnum.DYNAMIC_CAMERA_CONTROL: {
        return (
          <CustomSelect
            value={gridItem.selectedCamera ?? undefined}
            isSearchable
            options={cameras.map((camera) => ({ label: camera.name, value: getSensorUniqueCode(camera) }))}
            onChange={(value) => {
              const config = {
                ...currentLayout,
                items: currentLayout.items.map((item) => {
                  if (item.i === gridItem.i) {
                    return {
                      ...item,
                      component: GridItemComponentEnum.DYNAMIC_CAMERA_CONTROL,
                      selectedCamera: value,
                    } as GridItem;
                  }
                  return item;
                }),
              };
              updateCurrentLayout(config);
            }}
          />
        );
      }
      case GridItemComponentEnum.MAP: {
        return (
          <CustomSelect
            value={gridItem.selectedMap}
            isClearable={false}
            options={Object.entries(MapIdEnum).map(([key, value]) => ({
              label: formatMessage({ id: `global.${key.toLowerCase()}` }),
              value: value,
            }))}
            onChange={(value) => {
              const config = {
                ...currentLayout,
                items: currentLayout.items.map((item) => {
                  if (item.i === gridItem.i) {
                    return {
                      ...item,
                      component: GridItemComponentEnum.MAP,
                      selectedMap: value,
                    } as GridItem;
                  }
                  return item;
                }),
              };
              updateCurrentLayout(config);
            }}
          />
        );
      }
      default:
        return;
    }
  }

  return (
    <TabPanel backgroundColor="white" height="100%">
      <VStack gap={0} height="100%" padding={3} alignItems="start">
        <Flex alignItems="center" gap={1.5} marginBottom={0.5}>
          <CustomSelect
            value={currentLayout.code || undefined}
            key={currentLayout.code}
            isCreatable
            isClearable={false}
            placeholder={formatMessage({ id: 'grid.select' })}
            isSearchable
            style={{ width: '350px' }}
            size="lg"
            options={currentLayouts
              .filter((config) => config.code !== '')
              .toSorted((a, b) => a.name.localeCompare(b.name))
              .map((config) => ({ label: config.name, value: config.code }))}
            onChange={(value) => {
              const config = currentLayouts.find((config) => config.code === value);
              if (config) {
                updateCurrentLayout(config);
                _setLayout(config.items);
              }
            }}
            onCreate={(value: string) => {
              const newConfig: GridLayout = {
                ...emptyLayout,
                name: value,
                nbCol: currentLayout.nbCol,
                nbRow: currentLayout.nbRow,
                resolution: currentLayout.resolution,
                items: currentLayout.items,
              };
              updateCurrentLayout(newConfig);
            }}
          />
          <>
            <Button
              isDisabled={!canSave}
              isLoading={isLoadingUpdate || isLoadingCreate}
              onClick={() => {
                if (canSave) {
                  isNew
                    ? createLayout(currentLayout)
                    : updateLayout({ id: currentLayout.id, gridLayout: currentLayout });
                }
              }}
            >
              <Text isTruncated color="neutral.black">
                <FormattedMessage id="grid.save" />
              </Text>
            </Button>
            <Button
              isDisabled={!canSave}
              onClick={() => {
                if (canSave) {
                  resetConfiguration(currentLayout.code);
                }
              }}
            >
              <Text isTruncated color="neutral.black">
                <FormattedMessage id="grid.clean" />
              </Text>
            </Button>
            <Button
              isDisabled={!canDelete}
              isLoading={isLoadingDelete}
              onClick={() => {
                if (canDelete) {
                  if (!isNew) {
                    deleteLayout(currentLayout.id);
                    setCurrentLayout(emptyLayout);
                  } else {
                    const newCurrentLayout = selectedGridLayout ?? emptyLayout;
                    setCurrentLayout(newCurrentLayout);
                    _setLayout(newCurrentLayout.items);
                    setCurrentLayouts(currentLayouts.filter((item) => item.code !== currentLayout.code));
                  }
                }
              }}
            >
              <Text isTruncated color="neutral.black">
                <FormattedMessage id="grid.delete" />
              </Text>
            </Button>
            <Input
              value={currentLayout.resolution}
              width="100px"
              min={1}
              max={8}
              onChange={(e) => setCurrentLayout({ ...currentLayout, resolution: e.target.value })}
            />
          </>
        </Flex>
        <Box
          aspectRatio={currentLayout.resolution || 'auto'}
          ref={elementRef}
          border="gray solid 2px"
          maxWidth="100%"
          userSelect="none"
          overflow="hidden"
          flexGrow={1}
          boxSizing="border-box"
        >
          {dimensions && (
            <ReactGridLayout
              isBounded
              compactType={null}
              cols={currentLayout.nbCol}
              layout={layout.map((element) => {
                const resizeHandles = generateHandles(element.minW, element.minH);
                if (element.i === '__dropping-elem__') {
                  return { resizeHandles, ...element, ...currentDragging, i: '__dropping-elem__' };
                }
                return { resizeHandles, ...element };
              })}
              isDroppable
              margin={[8, 8]}
              maxRows={currentLayout.nbRow}
              preventCollision
              rowHeight={(dimensions.height - 4 - (currentLayout.nbRow + 1) * 8) / currentLayout.nbRow}
              style={{ height: dimensions.height - 4 }}
              width={dimensions.width - 4}
              onDrop={(layout: Layout[]) => {
                const newItem = layout.find((item) => item.i === '__dropping-elem__');
                const filteredLayout = layout.filter((item) => item.i !== '__dropping-elem__');
                if (!newItem) {
                  setLayout(filteredLayout);
                } else if (newItem.h + newItem.y <= currentLayout.nbRow) {
                  setLayout(filteredLayout, {
                    ...newItem,
                    ...currentDragging,
                    resizeHandles: generateHandles(currentDragging?.minW, currentDragging?.minH),
                  } as GridItem);
                }
              }}
              onLayoutChange={(currentLayout: Layout[]) => setLayout(currentLayout)}
            >
              {layout.map((element: Layout) => {
                const gridItem = currentLayout.items.find((item) => item.i === element.i);
                const name = formatMessage({
                  id: `grid.components.${gridItem ? gridItem.component : currentDragging?.component}`,
                });
                return (
                  <Center key={element.i} style={{ background: 'gray' }} padding={0.5}>
                    <CloseButton
                      position="absolute"
                      top={0}
                      right={0}
                      width="32px"
                      height="32px"
                      onTouchEnd={() => setLayout(layout.filter((e) => e.i !== element.i))}
                      onClick={() => setLayout(layout.filter((e) => e.i !== element.i))}
                    />
                    <VStack width="100%" padding={0.5} gap={0.5}>
                      <Text fontSize="1em" color="neutral.black">
                        {name}
                      </Text>
                      {gridItem && displayContent(gridItem)}
                    </VStack>
                  </Center>
                );
              })}
            </ReactGridLayout>
          )}
        </Box>
        <GridComponentList onChangeDragging={(value) => setCurrentDragging(value)} />
      </VStack>
    </TabPanel>
  );
}
