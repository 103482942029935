import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import SimpleRoundCommand from '@components/map/popup/round/SimpleRoundCommand';
import { FormattedMessage } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';

import ComplexRoundCommand from './ComplexRoundCommand';

type Props = {
  sensorUniqueCodes: SensorUniqueCodes;
};

export default function RoundContent({ sensorUniqueCodes }: Readonly<Props>) {
  return (
    <Tabs width="100%" height="max-content" variant="styledTab">
      <TabList>
        <Tab>
          <Text size="md">
            <FormattedMessage id="rounds.complex" />
          </Text>
        </Tab>
        <Tab>
          <Text size="md">
            <FormattedMessage id="rounds.simple" />
          </Text>
        </Tab>
      </TabList>
      <TabPanels height="100%">
        <TabPanel>
          <ComplexRoundCommand sensorUniqueCodes={sensorUniqueCodes} />
        </TabPanel>
        <TabPanel height="410px" backgroundColor="neutral.700">
          <SimpleRoundCommand sensorUniqueCodes={sensorUniqueCodes}></SimpleRoundCommand>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
