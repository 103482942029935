import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';

export const CONFIG_URL = import.meta.env.VITE_CONFIG_URL;

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Configuration', 'UserSettings'],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
