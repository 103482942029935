import { ReactComponent as AzimuthEastIcon } from '@assets/components/gauge/azimut-E.svg';
import { ReactComponent as AzimuthNorthIcon } from '@assets/components/gauge/azimut-N.svg';
import { ReactComponent as AzimuthSouthIcon } from '@assets/components/gauge/azimut-S.svg';
import { ReactComponent as AzimuthWestIcon } from '@assets/components/gauge/azimut-W.svg';
import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { memo } from 'react';

function AzimuthCompassBackground() {
  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      borderRadius="50%"
      border="2px solid"
      borderColor="neutral.white"
      boxShadow="inset 0 0 0 18px var(--chakra-colors-neutral-800-op70)"
    >
      <VStack position="absolute" top={0} left="50%" transform="translateX(-50%)" gap="17px">
        <Box width="2px" height="5px" backgroundColor="white" />
        <Icon as={AzimuthNorthIcon} width="20px" height="20px" />
      </VStack>
      <HStack position="absolute" right={0} top="50%" transform="translateY(-50%)" gap="17px">
        <Icon as={AzimuthEastIcon} width="20px" height="20px" />
        <Box height="2px" width="5px" backgroundColor="white" />
      </HStack>
      <VStack position="absolute" bottom={0} left="50%" transform="translateX(-50%)" gap="17px">
        <Icon as={AzimuthSouthIcon} width="20px" height="20px" />
        <Box width="2px" height="5px" backgroundColor="white" />
      </VStack>
      <HStack position="absolute" left={0} top="50%" transform="translateY(-50%)" gap="17px">
        <Box height="2px" width="5px" backgroundColor="white" />
        <Icon as={AzimuthWestIcon} width="20px" height="20px" />
      </HStack>
    </Box>
  );
}

export default memo(AzimuthCompassBackground);
