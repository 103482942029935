import { ReactComponent as ExportIcon } from '@assets/icons/24x24/ic-share-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, VStack } from '@chakra-ui/layout';
import CustomChipList from '@components/common/inputs/CustomChipList';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { updateNewLogBookEntry } from '@redux/global/global.reducer';
import { selectAllLogs, selectNewLogBookEntry } from '@redux/global/global.selector';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { useCreateInstructionMutation } from '@services/chat/messages.api';
import { ALL_OPTION_ID, stringToOption } from '@utils/components/customChipList.utils';
import { getLogAuthor, getLogContent } from '@utils/components/logbook.utils';
import { formatDate } from '@utils/date.utils';
import Scrollbars from 'rc-scrollbars';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { HoloLog, LogTypeEnum } from '@/types/c2/logs.type';
import { DateTimeEnum } from '@/types/dateTime.types';

import PanelHeader from '../../../common/layout/PanelHeader';
import { ChatFooter } from '../chat/ChatFooter';
import Panel from '../Panel';
import LogBookMessage from './LogBookMessage';

type Props = {
  onClose: () => void;
};

export default function LogBook({ onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const activeSite = useAppSelector(selectActiveSite);
  const newLogbookEntry = useAppSelector(selectNewLogBookEntry);
  const currentSituationTime = useAppSelector((state) => selectSituationTime(state, false));
  const csvDelimiter = ';';
  const [selectedFilters, setSelectedFilters] = useState<string[]>([ALL_OPTION_ID]);
  const scrollbarRef = useRef<Scrollbars>(null);
  const [createInstruction] = useCreateInstructionMutation();
  const resetNewLogbookEntry = useWithDispatch(updateNewLogBookEntry);

  const logs = useAppSelector(selectAllLogs);
  const filteredLogs = logs.filter(
    (log) => selectedFilters.includes(ALL_OPTION_ID) || selectedFilters.includes(log.type),
  );

  function convertLogsToCSV(logs: HoloLog[]) {
    const headers = [
      formatMessage({ id: 'components.logbook.csvHeader.date' }),
      formatMessage({ id: 'components.logbook.csvHeader.type' }),
      formatMessage({ id: 'components.logbook.csvHeader.author' }),
      formatMessage({ id: 'components.logbook.csvHeader.content' }),
    ];

    const csvRows = [
      `sep=${csvDelimiter}`,
      headers.join(csvDelimiter),
      ...logs.map((log) => {
        const author = getLogAuthor(log);
        return [
          formatDate(log.time, DateTimeEnum.DATE_TIME),
          log.type,
          `${author?.name}${author?.firstName ? ` ${author.firstName}` : ''}`,
          getLogContent(log),
        ].join(csvDelimiter);
      }),
    ];

    return csvRows.join('\n');
  }

  function downloadCSV(logs: HoloLog[]) {
    const csvContent = convertLogsToCSV(logs);

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const url = URL.createObjectURL(blob);

    const date = formatDate(currentSituationTime ?? Date.now(), DateTimeEnum.DATE_TIME);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `filtered_logs_${date}.csv`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  const [previousListLength, setPreviousListLength] = useState<number>(filteredLogs.length);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState<boolean>(false);

  if (previousListLength !== filteredLogs.length) {
    setPreviousListLength(filteredLogs.length);
    const values = scrollbarRef.current?.getValues();
    if (values && values.clientHeight + values.scrollTop === values.scrollHeight) {
      scrollbarRef.current?.scrollToBottom();
      setShouldScrollToBottom(true);
    }
  }

  useLayoutEffect(() => {
    if (shouldScrollToBottom) {
      scrollbarRef.current?.scrollToBottom();
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  useLayoutEffect(() => {
    scrollbarRef.current?.scrollToBottom();
  }, []);

  if (newLogbookEntry) {
    resetNewLogbookEntry(false);
  }

  return (
    <Panel
      width={510}
      isEmpty={filteredLogs.length === 0}
      emptyLabel={formatMessage({ id: 'components.logbook.noLog' })}
      header={
        <PanelHeader
          label={formatMessage({ id: 'components.sidebar.LOG_BOOK' })}
          isFilterDisabled={logs.length === 0}
          marginRight={2}
          filter={
            <CustomChipList
              availableOptions={Object.keys(LogTypeEnum).map(stringToOption)}
              selectedOptions={selectedFilters.map(stringToOption)}
              isDisabled={logs.length === 0}
              displayOptionAll
              path="components.logbook.filter"
              onChange={(value: string[]) => setSelectedFilters(value)}
            />
          }
          actions={
            <Icon
              as={ExportIcon}
              color="neutral.300"
              cursor="pointer"
              width="24px"
              height="24px"
              onClick={() => downloadCSV(filteredLogs)}
            />
          }
          onClose={onClose}
        />
      }
      footer={
        <ChatFooter
          scrollbarRef={scrollbarRef}
          onSubmit={(instruction) => createInstruction({ msg: instruction, site: activeSite?.code ?? '' })}
        />
      }
    >
      <CockpitCustomScrollbar scrollbarRef={scrollbarRef}>
        <VStack height="100%" width="100%" gap={0}>
          {filteredLogs.map((log) => {
            return (
              <LogBookMessage
                label={getLogContent(log)}
                date={log.time}
                type={log.type}
                author={getLogAuthor(log)}
                key={log.code}
              />
            );
          })}
          {
            <Box
              width="100%"
              maxHeight="100%"
              height="100%"
              marginTop={0.5}
              marginBottom="-4px"
              minHeight={0}
              flexGrow={0}
              flexShrink={1}
              backgroundColor="neutral.800-op70"
            />
          }
        </VStack>
      </CockpitCustomScrollbar>
    </Panel>
  );
}
