import { Text, VStack } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { formatCoordinates } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { FeatureType, FeatureTypeEnum } from '@/types/map.types';
import { AbstractLocation, PlanarLocation } from '@/types/sensor/sensor.types';

export interface TargetTooltipData extends FeatureType {
  type: FeatureTypeEnum.TARGET_ICON;
  data: Target<AbstractLocation>;
}

function TargetTooltip(props: Readonly<TargetTooltipData>) {
  const { id, identification, classification, lastPosition, trackIds } = props.data;
  const coordinatesUnit = useAppSelector(selectCoordinatesUnit);

  const sensorCodes = trackIds.map((sensor) => sensor.sensorCode).join(', ');

  return (
    <VStack gap={0.5} alignItems="flex-start">
      <Text fontSize="16px">{id}</Text>
      <Text fontSize="16px">
        <FormattedMessage id={`target.identification.${identification.mainIdentification}`} />
      </Text>
      <Text fontSize="16px">
        <FormattedMessage id={`target.classification.${classification.classification}`} />
      </Text>
      {lastPosition !== null && (
        <Text fontSize="16px">
          {formatCoordinates(
            new DmsCoordinates(
              (lastPosition.location as PlanarLocation).position?.latitude as number,
              (lastPosition.location as PlanarLocation).position?.longitude as number,
            ),
            coordinatesUnit,
          )}
        </Text>
      )}
      <Text fontSize="16px">{sensorCodes}</Text>
    </VStack>
  );
}

export default TargetTooltip;
