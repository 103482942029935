import {
  AuthentState,
  updateRenewalToken,
  updateSessionUuid,
  updateToken,
  userLogout,
} from '@redux/authent/authent.reducer';
import { RootState } from '@redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AUTHENT_URL } from './authent.api';

export interface LoginRequest {
  login: string;
  password: string;
}

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AUTHENT_URL,
    prepareHeaders: (headers, { getState }) => {
      const authent = (getState() as RootState).authent;
      if (authent.token && authent.renewalToken) {
        headers.set('authorization', authent.token);
        headers.set('renewal', authent.renewalToken);
      }
      if (authent.activeUser.id !== -1) {
        headers.set('userName', authent.activeUser.login);
      }
      headers.set('Content-type', 'application/json; charset=utf-8');
      return headers;
    },
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    login: builder.mutation<{ token: string | null; renew: string | null; sessionUuid: string | null }, LoginRequest>({
      query: (credentials) => ({
        url: `/login`,
        method: 'POST',
        body: credentials,
      }),
      transformResponse: (_, meta) => {
        return {
          token: meta?.response?.headers.get('AUTHORIZATION') ?? null,
          renew: meta?.response?.headers.get('RENEWAL') ?? null,
          sessionUuid: meta?.response?.headers.get('Session-Uuid') ?? null,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.token && data.renew) {
            dispatch(updateToken(data.token));
            dispatch(updateRenewalToken(data.renew));
            dispatch(updateSessionUuid(data.sessionUuid));
          }
        } catch (error) {
          //console.log('error', error); //todo
        }
      },
    }),
    logout: builder.mutation<{ token: string | null }, { token: string | null; newState?: Partial<AuthentState> }>({
      query: (credentials) => ({
        url: `/logout`,
        method: 'POST',
        headers: {
          authorization: `${credentials.token}`,
        },
      }),
      onQueryStarted: async (credentials, { dispatch }) => {
        try {
          dispatch(userLogout(credentials.newState));
        } catch (error) {
          //console.log('error', error); //todo
        }
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = loginApi;
