import { ReactComponent as InfoIcon } from '@assets/icons/16x16/ic-info-16.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, Icon, Text } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import { FormattedMessage } from 'react-intl';

type Props = {
  top: number;
  left: number;
  title: string;
  description: string;
  submitLabel: string;
  width?: number;
  height?: number;
  onSubmit: () => void;
  onClose: () => void;
};

export default function ConfirmationPopup({
  top,
  left,
  title,
  description,
  submitLabel,
  width = 648,
  height = 270,
  onSubmit,
  onClose,
}: Readonly<Props>) {
  return (
    <DraggableControl label={title} width={width} height={height} top={top} left={left} onClose={onClose}>
      <VStack backgroundColor="neutral.700" width="100%" height="100%" padding={4} gap={4} alignItems="start">
        <Text width="100%" size="lg" whiteSpace="pre-line">
          {description}
        </Text>
        <HStack gap={2}>
          <Icon as={InfoIcon} color="neutral.white" />
          <Text fontSize="16px">
            <FormattedMessage id="global.unrecoverableAction" />
          </Text>
        </HStack>
        <HStack width="100%" gap={2} justifyContent="center">
          <Box borderWidth="2px" borderColor="cyber.700">
            <Button variant="formButtonSecondary" height="48px" fontSize="18px" fontWeight="regular" onClick={onClose}>
              <FormattedMessage id="global.cancel" />
            </Button>
          </Box>
          <Box borderWidth="2px" borderColor="cyber.700">
            <Button variant="formButtonPrimary" height="48px" fontSize="18px" fontWeight="regular" onClick={onSubmit}>
              {submitLabel}
            </Button>
          </Box>
        </HStack>
      </VStack>
    </DraggableControl>
  );
}
