import { ReactComponent as VolumeOnIcon } from '@assets/icons/24x24/ic-volumeOn-24.svg';
import { ReactComponent as ChatIcon } from '@assets/icons/32x32/ic-chat-32.svg';
import { Center, Divider, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import CockpitTime from '@components/cockpit/CockpitTime';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectAllOngoingAlertsPerTypeByPlatforms } from '@redux/situation/situation.selectors';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Platform from './Platform';

function Platforms() {
  const alertsByPlatform = useAppSelector(selectAllOngoingAlertsPerTypeByPlatforms, isEqual);
  const currentContext = useAppSelector(selectContext);

  return (
    <VStack
      width="300px"
      flexShrink={0}
      height="100%"
      backgroundColor="transparent"
      gap={0.25}
      alignItems="start"
      paddingTop={1}
      userSelect="none"
    >
      <Text textTransform="uppercase" size="md" color="neutral.200" padding={2}>
        <FormattedMessage id="components.platform.title" />
      </Text>
      <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
      <HStack width="100%" paddingY={1} paddingX={2}>
        <HStack gap={1} marginLeft="auto">
          <Text width="32px" color="neutral.300" textAlign="center">
            <FormattedMessage id="components.platform.c-uas" />
          </Text>
          <Text width="32px" color="neutral.300" textAlign="center">
            <FormattedMessage id="components.platform.perim" />
          </Text>
          <Text width="34px" color="neutral.300" textAlign="center">
            <FormattedMessage id="components.platform.tech" />
          </Text>
        </HStack>
      </HStack>
      <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
      <VStack gap={0.25} width="100%" height="100%" flexShrink={1}>
        {alertsByPlatform.map(({ platform, ...alerts }) => (
          <Platform platform={platform} key={platform.id} alerts={alerts} />
        ))}
      </VStack>
      <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
      <HStack paddingX={2} paddingY="18px" width="100%">
        <Text marginTop="3px" fontSize="12px" color="neutral.300">
          MODE
        </Text>
        <Text width="100%" textAlign="center" size="md">
          <FormattedMessage id={`screenConfiguration.context.${currentContext}`} />
        </Text>
      </HStack>
      <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
      <HStack gap={0.25} paddingY={0.5}>
        <Center width="147px">
          <Icon as={ChatIcon} width="32px" height="32px" color="neutral.600" />
        </Center>
        <Divider orientation="vertical" height="100%" borderColor="neutral.700" borderWidth="1px" />
        <Center width="147px">
          <Icon as={VolumeOnIcon} width="32px" height="32px" color="neutral.600" />
        </Center>
      </HStack>
      <Divider width="100%" borderColor="neutral.700" borderWidth="1px" />
      <CockpitTime />
    </VStack>
  );
}

export default memo(Platforms);
