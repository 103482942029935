import NiceModal from '@ebay/nice-modal-react';
import globalReducer from '@redux/global/global.reducer';
import mapsReducer from '@redux/maps/maps.reducer';
import situationReducer from '@redux/situation/situation.reducer';
import { combineReducers, Reducer, UnknownAction } from '@reduxjs/toolkit';
import { authentApi } from '@services/authent/authent.api';
import { loginApi } from '@services/authent/login.api';
import { c2Api } from '@services/c2/c2.api';
import { sseApi } from '@services/c2/sse.api';
import { messagesApi } from '@services/chat/messages.api';
import { configApi } from '@services/config/config.api';
import { dataApi } from '@services/data/data.api';
import { replayApi } from '@services/replay/replay.api';
import { utmApi } from '@services/utm/utm.api';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { withReduxStateSync } from 'redux-state-sync';

import authentReducer from './authent/authent.reducer';
import chatReducer from './chat/chat.reducer';
import configReducer from './config/config.reducer';
import replayReducer from './replay/replay.reducer';
import sensorsReducer from './sensors/sensors.reducer';
import settingsReducer from './settings/settings.reducer';
import utmReducer from './utm/utm.reducer';

const rootReducer = {
  authent: authentReducer,
  chat: chatReducer,
  config: configReducer,
  global: globalReducer,
  maps: mapsReducer,
  replay: replayReducer,
  sensors: sensorsReducer,
  settings: settingsReducer,
  situation: situationReducer,
  utm: utmReducer,
  modals: NiceModal.reducer,
  [authentApi.reducerPath]: authentApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [c2Api.reducerPath]: c2Api.reducer,
  [sseApi.reducerPath]: sseApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [dataApi.reducerPath]: dataApi.reducer,
  [replayApi.reducerPath]: replayApi.reducer,
  [utmApi.reducerPath]: utmApi.reducer,
  [messagesApi.reducerPath]: messagesApi.reducer,
};

type InferState<Type> = Type extends Reducer<infer S> ? S : never;

type State<T extends typeof rootReducer = typeof rootReducer, P extends keyof T = keyof T> = {
  [K in P]: InferState<T[K]>;
};

const persistConfig = {
  key: 'rootV5',
  storage,
  whitelist: ['authent', 'locale'],
  stateReconciler: autoMergeLevel2,
  transforms: [
    createWhitelistFilter('locale', ['locale']),
    createWhitelistFilter('authent', ['token', 'renewalToken', 'sessionUuid', 'hasSelectedGroup']),
  ],
};

const persistedReducer: Reducer<State, UnknownAction> = persistReducer<State, UnknownAction>(
  persistConfig,
  combineReducers(rootReducer),
);

export default withReduxStateSync(persistedReducer);
