import { useReducer } from 'react';

import { Coordinates } from '@/types/commons/commons.types';

interface State {
  mouseSelectedPosition: Coordinates;
  isSelectionEnabled: boolean;
}

type Action = { type: 'SET_SELECTION'; payload: boolean } | { type: 'SET_SELECTED_POSITION'; payload: Coordinates };

const initialState: State = {
  mouseSelectedPosition: { latitude: 0, longitude: 0, type: '2D' },
  isSelectionEnabled: false,
};

function selectionReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_SELECTION':
      return { ...state, isSelectionEnabled: action.payload };
    case 'SET_SELECTED_POSITION':
      return { isSelectionEnabled: false, mouseSelectedPosition: action.payload };
    default:
      return state;
  }
}

export const useMapSelectedPositionState = () => {
  const [positionState, dispatch] = useReducer(selectionReducer, initialState);

  const setSelectionOn = (active: boolean) => {
    dispatch({ type: 'SET_SELECTION', payload: active });
  };

  const setSelectedPosition = (position: Coordinates) => {
    dispatch({ type: 'SET_SELECTED_POSITION', payload: position });
  };

  return {
    positionState,
    setSelectionOn,
    setSelectedPosition,
  };
};
