import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  th: {
    background: 'neutral.white',
    position: 'sticky',
    top: 0,
  },
});

export const tableTheme = defineMultiStyleConfig({ baseStyle });
