import { Text } from '@chakra-ui/react';

import { FeatureType, FeatureTypeEnum } from '@/types/map.types';

export interface PoiTooltipData extends FeatureType {
  type: FeatureTypeEnum.POI_ICON;
  data: { name: string };
}

function PoiTooltip({ data }: Readonly<PoiTooltipData>) {
  return <Text fontSize="16px">{data.name}</Text>;
}

export default PoiTooltip;
