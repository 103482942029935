import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { mapStyles } from '@utils/map/map.constants';
import { MapStyle } from 'react-map-gl';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { MapFilters } from '@/types/filters.types';

import { SettingsState } from './settings.reducer';

export function selectSettingsState(state: RootState): SettingsState {
  return state.settings;
}

export const selectContext = createSelector(selectSettingsState, (state: SettingsState): ContextEnum => state.context);

export const selectCoordinatesUnit = createSelector(
  selectSettingsState,
  (state: SettingsState): CoordinatesUnitEnum => state.coordinatesUnit,
);

export const selectMapBackground = createSelector(
  selectSettingsState,
  (state: SettingsState): MapStyle => mapStyles[state.mapBackground],
);

export const selectAxisInvertedY = createSelector(
  selectSettingsState,
  (state: SettingsState): boolean => state.axisInvertedY,
);

export const selectLinearJoystickCoefficient = createSelector(
  selectSettingsState,
  (state: SettingsState): number => state.linearJoystickCoefficient,
);

export const selectZoomJoystickCameraSensitivity = createSelector(
  selectSettingsState,
  (state: SettingsState): number => state.zoomJoystickCameraSensitivity,
);

export const selectAutomatedLad = createSelector(
  selectSettingsState,
  (state: SettingsState): boolean => state.automatedLad,
);

export const selectMapFilters = createSelector(
  selectSettingsState,
  (state: SettingsState): MapFilters => state.mapFilters,
);

export const selectMapFilterByKey = createSelector(
  [selectMapFilters, (state, filterKey: keyof MapFilters) => filterKey],
  (mapFilters, filterKey): boolean => {
    return mapFilters[filterKey];
  },
);

export const selectAreAllMapFilterChecked = createSelector([selectMapFilters], (mapFilters: MapFilters): boolean => {
  return Object.values(mapFilters).every((filter) => filter);
});

export const selectAreAllMapFilterUnchecked = createSelector([selectMapFilters], (mapFilters: MapFilters): boolean => {
  return Object.values(mapFilters).every((filter) => !filter);
});
