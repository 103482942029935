import { ZoneExportFormat } from '@/types/commons/commons.types';
import { NoFlyZone } from '@/types/config/config.types';

import { CONFIG_URL, configApi } from './config.api';

export const noFlyZoneApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    exportNoFlyZone: builder.mutation<Blob, { id: number; zoneExportFormat: ZoneExportFormat }>({
      query: ({ id, zoneExportFormat }) => ({
        url: `${CONFIG_URL}/no-fly-zones/${id}/export?format=${zoneExportFormat}`,
        method: 'GET',
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    createNoFlyZone: builder.mutation<NoFlyZone, Partial<NoFlyZone>>({
      query: (nfz) => ({
        url: `${CONFIG_URL}/no-fly-zones`,
        method: 'POST',
        body: nfz,
      }),
    }),
    updateNoFlyZone: builder.mutation<NoFlyZone, { id: number; nfz: NoFlyZone }>({
      query: ({ id, nfz }) => ({
        url: `${CONFIG_URL}/no-fly-zones/${id}`,
        method: 'PUT',
        body: nfz,
      }),
    }),
    deleteNoFlyZone: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/no-fly-zones/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateNoFlyZoneMutation, useUpdateNoFlyZoneMutation, useDeleteNoFlyZoneMutation } = noFlyZoneApi;
