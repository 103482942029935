import { Box, Flex, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { timeDiff } from '@utils/date.utils';
import { addMinutes } from 'date-fns';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';
import { RangeProps } from '@/types/replay/replay.types';

import CustomInputDateTime from './CustomInputDateTime';

interface CustomInputDateTimeRange {
  range?: RangeProps;
  variant?: 'filled';
  onChange?: (range: RangeProps) => void;
  singleLine?: boolean;
  showSecond?: boolean;
}

function CustomInputDateTimeRange({
  range,
  onChange,
  singleLine = true,
  variant,
  showSecond = true,
}: Readonly<CustomInputDateTimeRange>) {
  const [dateRange, setDateRange] = useState(range ?? { from: addMinutes(Date.now(), -10), to: new Date() });
  const { formatMessage } = useIntl();

  function handleChange(position: string, value: string) {
    let newRange: { from: Date; to: Date } = { ...dateRange, [position]: new Date(value) };
    if (position === 'from' && timeDiff(newRange.to, newRange.from) < 0) {
      newRange = {
        ...newRange,
        to: addMinutes(newRange.from, 10),
      };
    }
    setDateRange(newRange);
    onChange && onChange(newRange);
  }

  return singleLine ? (
    <Flex alignItems="center" gap={2}>
      <Text size="md">
        <FormattedMessage id="global.from" />
      </Text>
      <CustomInputDateTime
        type={DateTimeEnum.DATE_TIME}
        value={dateRange.from.toString()}
        variant={variant}
        showSecond={showSecond}
        onChange={(date) => handleChange('from', date)}
      />
      <Text size="md">
        <FormattedMessage id="global.to" />
      </Text>
      <CustomInputDateTime
        type={DateTimeEnum.DATE_TIME}
        min={dateRange.from.toString()}
        value={dateRange.to.toString()}
        variant={variant}
        showSecond={showSecond}
        onChange={(date) => handleChange('to', date)}
      />
    </Flex>
  ) : (
    <Flex width="full" flexDir="row">
      <VStack spacing={1}>
        <Flex alignItems="center" gap={2}>
          <Box width="2em">
            <Text>
              <FormattedMessage id="global.from" />
            </Text>
          </Box>
          <Flex gap={2}>
            <CustomInputDateTime
              type={DateTimeEnum.DATE_TIME}
              value={dateRange.from.toString()}
              variant={variant}
              showSecond={showSecond}
              onChange={(date) => handleChange('from', date)}
            />
          </Flex>
        </Flex>

        <Flex alignItems="center" gap={2}>
          <Box width="2em">
            <Text>{capitalize(formatMessage({ id: 'global.to' }))}</Text>
          </Box>

          <CustomInputDateTime
            type={DateTimeEnum.DATE_TIME}
            min={dateRange.from.toString()}
            value={dateRange.to.toString()}
            variant={variant}
            showSecond={showSecond}
            onChange={(date) => handleChange('to', date)}
          />
        </Flex>
      </VStack>
    </Flex>
  );
}

export default CustomInputDateTimeRange;
