import { Text } from '@chakra-ui/layout';
import { FormattedDate } from 'react-intl';

interface FormattedDateTimeProps {
  date: Date;
  color?: string;
  fontSize?: string;
}

export default function FormattedDateTime(
  props: Readonly<FormattedDateTimeProps> & Readonly<Intl.DateTimeFormatOptions>,
) {
  const { date, color = 'neutral.black', fontSize = '14px' } = props;
  return (
    <FormattedDate value={date} {...props}>
      {(date) => (
        <Text color={color} fontSize={fontSize}>
          {date}
        </Text>
      )}
    </FormattedDate>
  );
}
