import { ReactComponent as NoAlertDroneIcon } from '@assets/components/panel/alert/no-alert-drone-icon.svg';
import { ReactComponent as FilterIcon } from '@assets/icons/24x24/ic-filter-24.svg';
import { Center, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import CustomChipList from '@components/common/inputs/CustomChipList';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import { useAppSelector } from '@hooks/redux.hooks';
import { SensorSelectors } from '@redux/sensors/sensors.selectors';
import { selectSensorAndSubSensorStatuses } from '@redux/situation/monitoring.selectors';
import { getPlatformsCodesFromMarks, isSetEqual } from '@utils/common.utils';
import { getSectionStatus } from '@utils/map/automatonSection.utils';
import {
  LadSensorCategoryEnum,
  PerimSensorCategoryEnum,
  sensorCategoryFromSensorType,
  sensorCategoryType,
  SensorStatusCategoryEnum,
  statusCategoryFromSensorStatus,
  TypeEnum,
} from '@utils/sensors/configuration.constants';
import { getSubSensorAndSensorConfiguration, isLadCategory, isPerimCategory } from '@utils/sensors/sensors.utils';
import { Dispatch, useMemo, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Platform } from '@/types/config/config.types';
import { SensorUniqueCodes } from '@/types/map.types';
import {
  AutomatonSegment,
  SensorConfiguration,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import { AutomatonSegmentStatus, SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import Filters from '../filters/Filters';
import CriticalityList from './CriticalityList';
import TypeList from './TypeList';

export enum SortEnum {
  TYPE = 'TYPE',
  CRITICALITY = 'CRITICALITY',
}

export type configurationWithSensorUniqueCodes = {
  configuration: SensorConfiguration | SubSensorConfiguration | AutomatonSegment;
  sensorUniqueCodes: SensorUniqueCodes;
};

export type MonitoringFilter = {
  types: Set<TypeEnum>;
  ladCategories: Set<LadSensorCategoryEnum>;
  perimCategories: Set<PerimSensorCategoryEnum>;
  statusCategories: Set<SensorStatusCategoryEnum>;
};

export type MonitoringState = {
  open: boolean;
  selectedSensorCode: SensorUniqueCodes | null;
  sort: SortEnum;
} & MonitoringFilter;

function countChange(defaultState: MonitoringFilter, currentState: MonitoringState): number {
  let countChange = 0;
  if (!isSetEqual(defaultState.ladCategories, currentState.ladCategories)) {
    countChange += 1;
  }
  if (!isSetEqual(defaultState.perimCategories, currentState.perimCategories)) {
    countChange += 1;
  }
  if (!isSetEqual(defaultState.types, currentState.types)) {
    countChange += 1;
  }
  if (!isSetEqual(defaultState.statusCategories, currentState.statusCategories)) {
    countChange += 1;
  }
  return countChange;
}

type Props = {
  platformFilters: { platform: Platform; active: boolean }[];
  setSelectedSensorCode: Dispatch<SensorUniqueCodes | null>;
};

export default function MonitoringList({ platformFilters, setSelectedSensorCode }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const sensorStatuses = useAppSelector(selectSensorAndSubSensorStatuses);
  const sensorConfigurations = useAppSelector(SensorSelectors.selectAllSensors);

  const defaultFilters: MonitoringFilter = {
    types: new Set(Object.values(TypeEnum)),
    ladCategories: new Set(Object.values(LadSensorCategoryEnum)),
    perimCategories: new Set(Object.values(PerimSensorCategoryEnum)),
    statusCategories: new Set(Object.values(SensorStatusCategoryEnum)),
  };

  const [state, setState] = useReducer(
    (state: MonitoringState, newState: Partial<MonitoringState>) => ({
      ...state,
      ...newState,
    }),
    {
      open: true,
      selectedSensorCode: null,
      sort: SortEnum.TYPE,
      ...defaultFilters,
    },
  );

  const filteredConfigurations: configurationWithSensorUniqueCodes[] = useMemo(() => {
    return sensorConfigurations
      .filter((sensor) => {
        const platforms = getPlatformsCodesFromMarks(sensor.marks);
        if (platforms.length === 0) {
          return platformFilters.find((platformFilter) => platformFilter.platform.id === -1)?.active;
        }
        return platformFilters.some(
          (platformFilter) => platforms.includes(platformFilter.platform.code) && platformFilter.active,
        );
      })
      .filter((sensor) => {
        return state.types.has(sensorCategoryType[sensor.type]);
      })
      .flatMap((sensor) => getSubSensorAndSensorConfiguration(sensor))
      .filter((sensor) => {
        const type = sensor.configuration.type;
        if (sensorCategoryType[type] === TypeEnum.LAD) {
          const sensorCategory = sensorCategoryFromSensorType[type];
          if (isLadCategory(sensorCategory)) {
            return state.ladCategories.has(sensorCategory);
          }
        }
        if (sensorCategoryType[type] === TypeEnum.PERIM) {
          const sensorCategory = sensorCategoryFromSensorType[type];
          if (isPerimCategory(sensorCategory)) {
            return state.perimCategories.has(sensorCategory);
          }
        }
        return true;
      })
      .filter((sensor) => {
        if (!sensor.configuration.active) {
          return state.statusCategories.has(SensorStatusCategoryEnum.INACTIVE);
        } else if (sensor.configuration.maintenance) {
          return state.statusCategories.has(SensorStatusCategoryEnum.MAINTENANCE);
        } else {
          const statusInfo = sensorStatuses.find(
            (status) => status.uniqueCode === sensor.sensorUniqueCodes.sensorUniqueCode,
          );
          if (!statusInfo) {
            return state.statusCategories.has(SensorStatusCategoryEnum.UNREACHABLE);
          }
          const status =
            sensor.configuration.type === SubSensorTypeEnum.AUTOMATON_SEGMENT
              ? getSectionStatus(
                  Object.values(statusInfo.status as AutomatonSegmentStatus)
                    .filter(Boolean)
                    .map((segment) => segment.status),
                )
              : (statusInfo.status as SubSensorStatus | SensorStatus).status;
          return state.statusCategories.has(statusCategoryFromSensorStatus[status]);
        }
      });
  }, [
    sensorConfigurations,
    sensorStatuses,
    state.ladCategories,
    state.perimCategories,
    platformFilters,
    state.statusCategories,
    state.types,
  ]);

  const chipOptions = Object.values(SortEnum).map((key) => ({
    label: formatMessage({ id: `components.monitoring.${key}` }),
    value: key,
  }));

  return (
    <HStack gap={0} width="100%" height="100%" userSelect="none">
      <Filters state={state} setState={setState} defaultFilters={defaultFilters} />
      <VStack
        backgroundColor="neutral.900"
        width="100%"
        height="100%"
        gap={0}
        borderTop="2px solid"
        borderColor="neutral.black"
      >
        <HStack
          gap={2}
          width="100%"
          paddingX={3}
          height="58px"
          borderBottom="2px solid"
          borderColor="neutral.black"
          flexShrink={0}
        >
          {!state.open && (
            <HStack
              gap={1}
              paddingX={1.5}
              border="2px solid"
              borderColor="cyber.500"
              cursor="pointer"
              onClick={() => setState({ open: true })}
            >
              <Icon as={FilterIcon} color="neutral.300" width="24px" height="24px" />
              <Text>
                <FormattedMessage id="components.monitoring.filter.title" />
              </Text>
              <Text fontWeight="medium" color="sky.500">
                {countChange(defaultFilters, state)}
              </Text>
            </HStack>
          )}
          <HStack gap={1}>
            {[...state.types].map((type) => (
              <Text fontWeight="medium" padding={1} backgroundColor="neutral.600" lineHeight="16px" key={type}>
                <FormattedMessage id={`components.monitoring.filter.${type}`} />
              </Text>
            ))}
          </HStack>
          <Text fontWeight="medium" color="neutral.200" marginY={1}>
            <FormattedMessage
              id="components.monitoring.filter.result"
              values={{ count: filteredConfigurations.length }}
            />
          </Text>
          <HStack gap={1} marginLeft="auto">
            <CustomChipList
              availableOptions={chipOptions}
              selectedOptions={chipOptions.filter((option) => option.value === state.sort)}
              distinctValues={[SortEnum.TYPE, SortEnum.CRITICALITY]}
              onChange={(value) => setState({ sort: value[0] as SortEnum })}
              size="sm"
              variant="filled"
            />
          </HStack>
        </HStack>
        <CockpitCustomScrollbar marginTop={1} marginRight={1} marginBottom={1}>
          {filteredConfigurations.length !== 0 ? (
            <VStack padding={3} width="100%" height="100%" alignItems="start" gap={2}>
              {state.sort === SortEnum.CRITICALITY ? (
                <CriticalityList
                  filteredConfigurations={filteredConfigurations}
                  setSelectedSensorCode={setSelectedSensorCode}
                />
              ) : (
                <TypeList
                  filteredConfigurations={filteredConfigurations}
                  ladCategories={state.ladCategories}
                  perimCategories={state.perimCategories}
                  types={state.types}
                  setSelectedSensorCode={setSelectedSensorCode}
                />
              )}
            </VStack>
          ) : (
            <Center height="100%">
              <VStack gap={3}>
                <NoAlertDroneIcon />
                <Text
                  fontSize="18px"
                  variant="space"
                  color="neutral.200"
                  maxWidth="230px"
                  textAlign="center"
                  userSelect="none"
                >
                  <FormattedMessage id="components.monitoring.noResult" />
                </Text>
              </VStack>
            </Center>
          )}
        </CockpitCustomScrollbar>
      </VStack>
    </HStack>
  );
}
