import { Box, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { getAlertColor, getTargetAlertIcon } from '@utils/data/notification.utils';
import { extendedSensorShortHand } from '@utils/map/map.constants';
import { getTargetId } from '@utils/target.utils';
import { FormattedMessage } from 'react-intl';

import { AlertLevelEnum, SpaceViolationAlert } from '@/types/data/data.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';

import AlertTimeToast from './AlertTimeToast';

interface OwnProps {
  alert: SpaceViolationAlert;
  currentDate: Date;
}

export default function SpaceViolationAlertToast({ alert, currentDate }: Readonly<OwnProps>) {
  const targetId = getTargetId(alert.trackIds);

  const uniqueSensorsTypes = Array.from(
    new Set(alert.trackIds.map((track) => extendedSensorShortHand[track.sensorType])),
  ).join(', ');

  return (
    <>
      <HStack
        gap={2}
        width="100%"
        paddingY={2}
        paddingRight={2}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 4 : 3}
        backgroundColor="neutral.white"
      >
        <Flex alignItems="center">
          <Icon
            as={getTargetAlertIcon(alert, alert.reliability ?? ReliabilityEnum.UNSURE)}
            height="54px"
            width="54px"
          />
        </Flex>
        <VStack alignItems="start" gap={0.5}>
          <Text fontSize="16px" fontWeight="medium" color="neutral.black">
            <FormattedMessage id="notification.droneDetected" />
          </Text>
          <Box backgroundColor={getAlertColor(alert.level)} paddingX={0.5} paddingY={0.25}>
            <Text
              fontSize="14px"
              fontWeight="medium"
              color={alert.level === AlertLevelEnum.INFO ? 'neutral.black' : 'neutral.white'}
            >
              <FormattedMessage id={`notification.${alert.level.toLocaleLowerCase()}`} />
            </Text>
          </Box>
          <AlertTimeToast startTime={alert.startTime} endTime={alert.endTime} currentDate={currentDate} />
        </VStack>
      </HStack>
      <VStack
        width="100%"
        backgroundColor="neutral.100"
        alignItems="start"
        paddingRight={3}
        paddingY={2}
        paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 5 : 4}
      >
        <Text fontSize="12px" fontWeight="medium" backgroundColor="neutral.700">
          {targetId}
        </Text>
        <HStack maxWidth="300px">
          <Text fontSize="12px" fontWeight="medium" color="neutral.600">
            <FormattedMessage
              id={`notification.${(alert.reliability ?? ReliabilityEnum.UNSURE).toLocaleLowerCase()}`}
            />
          </Text>
          <Tooltip label={uniqueSensorsTypes}>
            <Text fontSize="12px" fontWeight="medium" color="neutral.600" isTruncated>
              ({uniqueSensorsTypes})
            </Text>
          </Tooltip>
        </HStack>
        <HStack>
          <Text fontSize="12px" fontWeight="medium" color="neutral.600">
            <FormattedMessage id="notification.altitude" />
          </Text>
          <Text fontSize="12px" fontWeight="medium" color="neutral.600">
            {alert.targetAltitude !== null ? `${alert.targetAltitude.toFixed(0)} m` : 'N/A'}
          </Text>
        </HStack>
      </VStack>
    </>
  );
}
