import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';

export const AUTHENT_URL = import.meta.env.VITE_AUTHENT_URL;

export const authentApi = createApi({
  reducerPath: 'authentApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['User'],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
