import { createEntityAdapter } from '@reduxjs/toolkit';
import { sortDateDesc } from '@utils/date.utils';

import { HoloLog } from '@/types/c2/logs.type';
import { Alert } from '@/types/data/data.types';

export const alertAdapter = createEntityAdapter<Alert, number>({
  selectId: (alert) => alert.id,
  sortComparer: (alert1, alert2) => sortDateDesc(alert2.startTime, alert1.startTime),
});

export const logsAdapter = createEntityAdapter<HoloLog, string>({
  selectId: (log) => log.code,
  sortComparer: (logA, logB) => new Date(logA.time).getTime() - new Date(logB.time).getTime(),
});
