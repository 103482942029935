import { appIntl } from '@components/locale/IntlGlobalProvider';
import { useMapContext } from '@components/map/MapContext';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { switchDrawModeByMapId, updateMenuNavigationStatus, updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectMapNavigationOptions } from '@redux/maps/maps.selectors';
import { useCallback } from 'react';

import { Coordinates } from '@/types/commons/commons.types';
import { PopupDataTypeEnum } from '@/types/map.types';
import { MenuItems } from '@/types/menu.types';

interface UseCommonContextMenuItemsParameter {
  position: Coordinates | null;
  closeContextMenu: () => void;
  measureOrigin: MeasureOriginEnum;
}

export enum MeasureOriginEnum {
  POINT = 'POINT',
  CAMERA = 'CAMERA',
  API = 'API',
  BT = 'BT',
  PERIMETER_SECTION = 'PERIMETER_SECTION',
  OTHER_SENSOR = 'OTHER_SENSOR',
}

export const useCommonContextMenuItems = ({
  closeContextMenu,
  position,
  measureOrigin,
}: UseCommonContextMenuItemsParameter): MenuItems => {
  const { formatMessage } = appIntl();
  const { mapId, setMeasurePoints, setIsClickEnabled } = useMapContext();

  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const dispatchSelectedOptions = useWithDispatch(updateMenuNavigationStatus);
  const dispatchSwitchDrawModeByMapId = useWithDispatch(switchDrawModeByMapId);
  const selectedToolMenuOptions = useSelectorWithMapId(selectMapNavigationOptions);

  //Closes all draw modes
  const enableMeasure = useCallback(() => {
    if (position?.longitude && position.latitude) {
      const firstPoint = [position.longitude, position.latitude];
      setMeasurePoints([firstPoint]);
      setIsClickEnabled(true);

      updatePopupControl({
        mapId: mapId,
        popupControl: {
          type: PopupDataTypeEnum.MEASURE_INFO,
          open: true,
          position: { x: 0, y: 0 },
          data: null,
        },
      });

      let newSelectedOptions = structuredClone(selectedToolMenuOptions ?? []);
      if (newSelectedOptions.includes('create')) {
        newSelectedOptions = newSelectedOptions.filter((opt) => opt !== 'create');
      }
      dispatchSelectedOptions({ mapId, selectedOptions: newSelectedOptions });
      dispatchSwitchDrawModeByMapId({ mapId, isDrawable: false });

      closeContextMenu();
    }
  }, [
    position,
    setMeasurePoints,
    setIsClickEnabled,
    updatePopupControl,
    mapId,
    selectedToolMenuOptions,
    dispatchSelectedOptions,
    dispatchSwitchDrawModeByMapId,
    closeContextMenu,
  ]);

  return {
    measureFromPoint: {
      label: formatMessage({ id: 'contextmenu.actions.measureFromPoint' }, { measureOrigin: measureOrigin }),
      shouldNotTranslate: true,
      onAction: enableMeasure,
      isDisabled: !position?.longitude || !position.latitude,
    },
  };
};
