import { Button, Icon, Text, Tooltip, VStack } from '@chakra-ui/react';
import { MaintenanceVariant } from '@components/map/infos/sensors/maintenance/SpecificMaintenance';
import { FunctionComponent, memo, SVGProps } from 'react';

type Props = {
  variant: MaintenanceVariant;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  description: string;
  buttonLabel: string;
  tooltip?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

function getVariantStyle(variant: MaintenanceVariant) {
  switch (variant) {
    case MaintenanceVariant.MONITORING:
      return { gap: 2, padding: 2, iconColor: 'neutral.300' };
    case MaintenanceVariant.POPUP:
      return { gap: 3, padding: 4, iconColor: 'neutral.white' };
  }
}

function DataSheetCard({
  variant,
  icon,
  description,
  buttonLabel,
  tooltip,
  isDisabled,
  isLoading,
  onClick,
}: Readonly<Props>) {
  const { gap, padding, iconColor } = getVariantStyle(variant);

  return (
    <VStack padding={padding} gap={gap} width="100%" backgroundColor="neutral.800" userSelect="none">
      <Icon as={icon} width="40px" height="40px" color={iconColor} />
      <Text whiteSpace="pre-line" textAlign="center">
        {description}
      </Text>
      <Tooltip label={tooltip} isDisabled={!tooltip}>
        <Button
          variant="formButtonPrimary"
          height="48px"
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      </Tooltip>
    </VStack>
  );
}

export default memo(DataSheetCard);
