import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';

import { LightMessage } from '@/types/chat/chat.types';

export const CHAT_URL = import.meta.env.VITE_CHAT_URL;

export const messagesApi = createApi({
  reducerPath: 'messagesApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createMessage: builder.mutation<void, LightMessage>({
      query: (message) => ({
        url: `${CHAT_URL}/messages/global`,
        method: 'POST',
        body: message,
      }),
    }),
    createInstruction: builder.mutation<void, LightMessage>({
      query: (message) => ({
        url: `${CHAT_URL}/messages/instruction`,
        method: 'POST',
        body: message,
      }),
    }),
  }),
});

export const { useCreateMessageMutation, useCreateInstructionMutation } = messagesApi;
