import { ReactComponent as GarbageCanIcon } from '@assets/icons/16x16/garbage-can.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/16x16/pencil.svg';
import { ReactComponent as ConfirmIcon } from '@assets/icons/24x24/ic-confirmed-bold-24.svg';
import { ReactComponent as CancelIcon } from '@assets/icons/24x24/ic-error-24.svg';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { Button, FormControl, FormLabel, IconButton, Input, TabPanel, Tooltip } from '@chakra-ui/react';
import CustomSelect from '@components/common/inputs/CustomStyledSelect';
import { DataTable } from '@components/common/layout/DataTable';
import { useAppSelector } from '@hooks/redux.hooks';
import {
  selectActiveGroup,
  selectDenormalizedGroups,
  selectDenormalizedProfiles,
} from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors, PlatformSelectors } from '@redux/config/config.selectors';
import { useCreateGroupMutation, useDeleteGroupMutation, useUpdateGroupMutation } from '@services/authent/group.api';
import { createColumnHelper } from '@tanstack/react-table';
import { createErrorMessage } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { Field, Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { GroupWithUsers } from '@/types/authent/groups.types';

const columnHelper = createColumnHelper<GroupWithUsers>();

export default function Groups() {
  const { formatMessage } = useIntl();
  const groups = useAppSelector(selectDenormalizedGroups);
  const activeGroup = useAppSelector(selectActiveGroup);
  const globalScreenConfigurations = useAppSelector(
    GlobalScreenConfigurationSelectors.selectAllGlobalScreenConfigurations,
  );
  const platforms = useAppSelector(PlatformSelectors.selectAllPlatforms);
  const profiles = useAppSelector(selectDenormalizedProfiles);
  const [editMode, setEditMode] = useState<GroupWithUsers | null>(null);
  const [deleteMode, setDeleteMode] = useState<string | null>(null);

  const [createGroup, { isLoading: isLoadingCreate }] = useCreateGroupMutation();
  const [updateGroup, { isLoading: isLoadingUpdate }] = useUpdateGroupMutation();
  const [deleteGroup, { isLoading: isLoadingDelete }] = useDeleteGroupMutation();

  const emptyGroup: Partial<GroupWithUsers> = {
    name: '',
    profile: activeGroup?.profile,
  };

  function onSubmit(group: Partial<GroupWithUsers>, helper: FormikHelpers<Partial<GroupWithUsers>>) {
    createGroup({ ...group, code: uuidv4() } as Partial<GroupWithUsers>)
      .unwrap()
      .then(() => {
        helper.resetForm();
        createToast(
          formatMessage({ id: 'components.admin.groups.message.add' }, { name: group.name }),
          ToastStatusEnum.SUCCESS,
        );
      });
  }

  const handleClick = useCallback(
    (group: GroupWithUsers) => {
      if (group.code === deleteMode && group.users.length === 0) {
        deleteGroup(group.id)
          .unwrap()
          .then(() => {
            createToast(
              formatMessage({ id: 'components.admin.groups.message.delete' }, { name: group.name }),
              ToastStatusEnum.SUCCESS,
            );
            setDeleteMode(null);
          })
          .catch((error) => {
            createToast(formatMessage({ id: createErrorMessage(error) }), ToastStatusEnum.ERROR);
          });
      } else if (group.code === editMode?.code) {
        updateGroup(editMode)
          .unwrap()
          .then(() => {
            createToast(
              formatMessage({ id: 'components.admin.groups.message.update' }, { name: editMode.name }),
              ToastStatusEnum.SUCCESS,
            );
            setEditMode(null);
          });
      }
    },
    [deleteGroup, deleteMode, editMode, formatMessage, updateGroup],
  );

  const handleProfileChange = useCallback(
    (profileId: string) => {
      const newProfile = profiles.find((profile) => profile.id.toString() === profileId);
      newProfile && editMode && setEditMode({ ...editMode, profile: newProfile });
    },
    [editMode, profiles],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: formatMessage({ id: 'components.admin.groups.name' }),
        minSize: 250,
      }),
      columnHelper.accessor('platforms', {
        cell: (info) => {
          if (info.row.original.code === editMode?.code) {
            return (
              <CustomSelect
                value={editMode.platforms}
                options={platforms.map((platform) => ({
                  label: platform.name,
                  value: platform.code,
                }))}
                menuPortalTarget={document.body}
                width={info.column.getSize() - 24}
                size="xs"
                isClearable={false}
                isMulti
                onChange={(value) => setEditMode({ ...editMode, platforms: value })}
              />
            );
          } else {
            const platformInGroups = platforms.filter((ptf) => info.row.original.platforms.includes(ptf.code));
            return platformInGroups.length
              ? platformInGroups.map((platform) => platform.name).toString()
              : formatMessage({ id: 'global.noInformation' });
          }
        },
        minSize: 400,
        header: formatMessage({ id: 'components.admin.groups.platforms' }),
      }),
      columnHelper.accessor('globalScreenConfig', {
        cell: (info) => {
          if (info.row.original.code === editMode?.code) {
            return (
              <CustomSelect
                value={editMode.globalScreenConfig ?? undefined}
                options={globalScreenConfigurations.map((config) => ({
                  label: config.name,
                  value: config.code,
                }))}
                menuPortalTarget={document.body}
                width={info.column.getSize() - 24}
                size="xs"
                isClearable={false}
                onChange={(value) => setEditMode({ ...editMode, globalScreenConfig: value })}
              />
            );
          } else {
            if (info.getValue() === null) {
              return formatMessage({ id: 'global.noInformation' });
            }
            const config = globalScreenConfigurations.find((config) => info.getValue() === config.code);
            if (config) {
              return config.name;
            }
            return formatMessage({ id: 'components.admin.groups.deletedConfig' });
          }
        },
        minSize: 250,
        header: formatMessage({ id: 'components.admin.groups.configuration' }),
      }),
      columnHelper.accessor('profile', {
        cell: (info) => {
          if (info.row.original.code === editMode?.code) {
            return (
              <CustomSelect
                value={editMode.profile.id.toString()}
                options={profiles.map((profile) => ({
                  label: profile.name,
                  value: profile.id.toString(),
                }))}
                size="xs"
                menuPortalTarget={document.body}
                width={info.column.getSize() - 24}
                isClearable={false}
                onChange={(value) => handleProfileChange(value)}
              />
            );
          } else {
            return info.getValue().name;
          }
        },
        size: 300,
        header: formatMessage({ id: 'components.admin.groups.profile' }),
      }),
      columnHelper.display({
        id: 'actions',
        maxSize: 114,
        cell: (props) => {
          const group = props.row.original;
          const isInEditionMode = editMode?.code === group.code;
          const isInDeletionMode = deleteMode === group.code;

          if (isInEditionMode || isInDeletionMode) {
            return (
              <HStack gap={0.5} justifyContent="center">
                <IconButton
                  aria-label="confirm"
                  icon={<ConfirmIcon />}
                  isLoading={isLoadingUpdate || isLoadingDelete}
                  onClick={() => handleClick(group)}
                />
                <IconButton
                  aria-label="cancel"
                  icon={<CancelIcon />}
                  onClick={() => {
                    setDeleteMode(null);
                    setEditMode(null);
                  }}
                />
              </HStack>
            );
          }
          return (
            <HStack gap={0.5} justifyContent="center">
              <Tooltip
                isDisabled={activeGroup?.code !== group.code}
                label={formatMessage({ id: 'components.admin.groups.isActiveGroup' })}
              >
                <IconButton
                  aria-label="edit"
                  icon={<PencilIcon />}
                  isDisabled={activeGroup?.code === group.code}
                  onClick={() => {
                    setEditMode(group);
                    setDeleteMode(null);
                  }}
                />
              </Tooltip>
              <Tooltip
                label={formatMessage(
                  { id: 'components.admin.groups.hasUserTooltip' },
                  { users: group.users.map((user) => user.login).join(', '), count: group.users.length },
                )}
                isDisabled={group.users.length === 0}
              >
                <IconButton
                  aria-label="delete"
                  icon={<GarbageCanIcon />}
                  isDisabled={group.users.length > 0}
                  onClick={() => {
                    setDeleteMode(group.code);
                    setEditMode(null);
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
      }),
    ],
    [
      activeGroup?.code,
      deleteMode,
      editMode,
      formatMessage,
      globalScreenConfigurations,
      handleClick,
      handleProfileChange,
      isLoadingDelete,
      isLoadingUpdate,
      platforms,
      profiles,
    ],
  );

  return (
    <TabPanel height="100%" backgroundColor="white" padding={3}>
      <VStack width="100%" alignItems="start" style={{ containerType: 'size' }} height="100%">
        <Text color="neutral.black">
          <FormattedMessage id="components.admin.groups.subTitle" />
        </Text>
        <Formik initialValues={emptyGroup} enableReinitialize validateOnBlur onSubmit={onSubmit}>
          {({ handleSubmit, errors, touched, isValid, dirty, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <FormControl id="name" isInvalid={touched.name && !!errors.name}>
                <HStack gap={2}>
                  <VStack gap={0} alignItems="left">
                    <FormLabel fontWeight="600" fontSize="17px" color="neutral.black" gap={0}>
                      <FormattedMessage id="components.admin.groups.newGroup" />
                    </FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name="name"
                      type="text"
                      height="30px"
                      width="300px"
                      isInvalid={touched.name && !!errors.name}
                    />
                  </VStack>
                  <VStack gap={0} alignItems="left">
                    <FormLabel fontWeight="600" fontSize="17px" color="neutral.black" gap={0}>
                      <FormattedMessage id="components.admin.groups.profile" />
                    </FormLabel>
                    <CustomSelect
                      value={values.profile?.id.toString()}
                      options={profiles.map((profile) => ({
                        label: profile.name,
                        value: profile.id.toString(),
                      }))}
                      size="xs"
                      menuPortalTarget={document.body}
                      isClearable={false}
                      onChange={(value) =>
                        setFieldValue(
                          'profile',
                          profiles.find((profile) => profile.id.toString() === value),
                        )
                      }
                    />
                  </VStack>
                  <Button type="submit" isDisabled={!dirty || !isValid} isLoading={isLoadingCreate} alignSelf="end">
                    <FormattedMessage id="components.admin.groups.add" />
                  </Button>
                </HStack>
              </FormControl>
            </form>
          )}
        </Formik>
        <Text fontWeight="600" color="neutral.black" paddingLeft={1.5} paddingTop={2}>
          <FormattedMessage id="components.admin.groups.availableGroups" />
        </Text>
        <DataTable columns={columns} data={groups} defaultSorting={[{ id: 'name', desc: false }]} />
      </VStack>
    </TabPanel>
  );
}
