import { Table, TableContainer, TabPanel, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { SensorSelectors } from '@redux/sensors/sensors.selectors';
import { isHostConfiguration, isPortConfiguration } from '@utils/sensors/sensors.utils';

import { HostConfiguration, PortConfiguration } from '@/types/sensor/configuration.types';

export default function LadSensorsTab() {
  const sensors = useAppSelector(SensorSelectors.selectAllSensors);
  return (
    <TabPanel backgroundColor="white" height="100%">
      <TableContainer height="730px" marginBottom={0} overflow="hidden" overflowY="auto">
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>code</Th>
              <Th>name</Th>
              <Th>host</Th>
              <Th>port</Th>
              <Th>active</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sensors.map((sensor) => (
              <Tr key={sensor.code}>
                <Td>{sensor.code}</Td>
                <Td>{sensor.name}</Td>
                <Td>{isHostConfiguration(sensor) ? (sensor as HostConfiguration).host : ''}</Td>
                <Td>{isPortConfiguration(sensor) ? (sensor as PortConfiguration).port : ''}</Td>
                <Td>{sensor.active ? 'Oui' : 'Non'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
}
