import * as SensorsIconM from '@assets/icons/54x54/sensor';
import * as SensorsIconL from '@assets/icons/70x70/sensor';
import { SensorIconKeys } from '@assets/icons/70x70/sensor';
import {
  extendedSensorShortHand,
  IconColorEnum,
  SensorIconStatusFromSensorStatus,
  sensorLikeIconShorthand,
} from '@utils/map/map.constants';
import { isAutomaton, isCircuitBreakerEnabled, isEmergencyStopEnabled } from '@utils/sensors/sensors.utils';
import { capitalize, partition } from 'lodash';
import { FunctionComponent, SVGProps } from 'react';

import { MapFilters } from '@/types/filters.types';
import {
  SensorDisplayedOnMap,
  SensorLikeIconBasicInfo,
  SENSORS_NOT_DISPLAYED_ON_MAP,
  SplitMonitoringSensors,
  SubSensorInfo,
} from '@/types/map.types';
import {
  ExtendedSensor,
  GetSensorConfiguration,
  SensorConfiguration,
  SENSORS_WITH_SUBSENSOR,
  SensorTypeEnum,
  SensorWithSubSensor,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import {
  AutomatonCabinetStatus,
  GetSensorStatus,
  MonitoringSensor,
  SensorStatus,
  SensorStatusEnum,
} from '@/types/sensor/status.types';

function getSensorColor(active: boolean, maintenance: boolean, status?: SensorStatusEnum | null): IconColorEnum {
  if (active && maintenance) {
    return IconColorEnum.ORANGE;
  } else if (active && status) {
    return SensorIconStatusFromSensorStatus[status];
  } else return IconColorEnum.WHITE;
}

export function getTechnicalStatusName(status: SensorStatusEnum | undefined): string {
  switch (status) {
    case SensorStatusEnum.ROUND:
      return '-round';
    case SensorStatusEnum.TRACKING:
      return '-tracking';
    case SensorStatusEnum.HT_DETECTION:
      return '-ht-detection';
    default:
      return '';
  }
}

function getTechnicalStatusNameIconKey(status: SensorStatusEnum): string {
  switch (status) {
    case SensorStatusEnum.ROUND:
      return 'Round';
    case SensorStatusEnum.TRACKING:
      return 'Tracking';
    case SensorStatusEnum.HT_DETECTION:
      return 'HtDetection';
    default:
      return '';
  }
}

export function getSensorSpriteIconName({ active, maintenance, type, status }: SensorLikeIconBasicInfo): string {
  if (active && maintenance) {
    return `icSymb-${sensorLikeIconShorthand[type]}-work-${getSensorColor(active, maintenance, status)}`;
  } else {
    const technicalStatusName = getTechnicalStatusName(status);
    return `icSymb-${sensorLikeIconShorthand[type]}${technicalStatusName}-${getSensorColor(
      active,
      maintenance,
      status,
    )}`;
  }
}

export function getIconBasicInfoFromSensor(
  config: GetSensorConfiguration<SensorDisplayedOnMap>,
  status?: SensorStatusEnum | undefined,
): SensorLikeIconBasicInfo {
  return {
    active: config.active,
    maintenance: config.maintenance,
    type: config.type,
    status,
  };
}

function getIconBasicInfoFromSubSensorInfo({
  parentSensorConfig,
  subSensorConfig,
  subSensorStatus,
}: SubSensorInfo<SensorWithSubSensor>): SensorLikeIconBasicInfo {
  return {
    active: parentSensorConfig.active && subSensorConfig.active,
    maintenance: parentSensorConfig.maintenance || subSensorConfig.maintenance,
    type: subSensorConfig.type,
    status: subSensorStatus?.status,
  };
}

function getCabinetSpecialStatus(status: AutomatonCabinetStatus) {
  if (
    status.status !== SensorStatusEnum.DEGRADED &&
    status.status !== SensorStatusEnum.SLEEP &&
    status.status !== SensorStatusEnum.DESYNCHRONIZED
  ) {
    return '';
  }
  const statusArray: string[] = [];
  if (isCircuitBreakerEnabled(status)) {
    statusArray.push('-cb');
  }
  if (isEmergencyStopEnabled(status)) {
    statusArray.push('-es');
  }
  return statusArray.join('');
}

function getSubSensorSpecialStatus(subsensorInfo: SubSensorInfo<SensorWithSubSensor>) {
  const { active, maintenance } = getIconBasicInfoFromSubSensorInfo(subsensorInfo);
  if (active && maintenance) {
    return '-work';
  }
  if (subsensorInfo.subSensorStatus) {
    switch (subsensorInfo.subSensorConfig.type) {
      case SubSensorTypeEnum.AUTOMATON_CABINET:
        return getCabinetSpecialStatus(subsensorInfo.subSensorStatus as AutomatonCabinetStatus);
      case SubSensorTypeEnum.ARDRONIS_ANTENNA:
      case SubSensorTypeEnum.SENID_ANTENNA:
      case SubSensorTypeEnum.SKY_EYE_ANTENNA:
      case SubSensorTypeEnum.SPEXER_RADAR:
        return '';
    }
  }
  return '';
}

export function getSubSensorSpriteIconName(subsensorInfo: SubSensorInfo<SensorWithSubSensor>): string {
  const { active, maintenance, status, type } = getIconBasicInfoFromSubSensorInfo(subsensorInfo);
  const specialStatus = getSubSensorSpecialStatus(subsensorInfo);
  return `icSymb-${sensorLikeIconShorthand[type]}${specialStatus}-${getSensorColor(active, maintenance, status)}`;
}

export function getSensorIconKey(
  type: ExtendedSensor,
  active = false,
  maintenance = false,
  status?: SensorStatusEnum | null,
  skipTechnicalName = false,
): SensorIconKeys {
  if (active && maintenance) {
    return `${capitalize(extendedSensorShortHand[type])}Work${capitalize(
      getSensorColor(active, maintenance, status),
    )}` as SensorIconKeys;
  } else {
    const technicalStatusName = status && !skipTechnicalName ? getTechnicalStatusNameIconKey(status) : '';
    return `${capitalize(extendedSensorShortHand[type])}${technicalStatusName}${capitalize(
      getSensorColor(active, maintenance, status),
    )}` as SensorIconKeys;
  }
}

export function getSensorIconByKey(
  key: SensorIconKeys,
  size: 'md' | 'lg' | 'sm',
): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (size) {
    case 'lg':
      return SensorsIconL[key] as FunctionComponent<SVGProps<SVGSVGElement>>;
    case 'sm':
    case 'md':
      return SensorsIconM[key] as FunctionComponent<SVGProps<SVGSVGElement>>;
  }
}

export function isSensorWithSubSensors(
  sensor: SensorConfiguration,
): sensor is GetSensorConfiguration<SensorWithSubSensor> {
  return (SENSORS_WITH_SUBSENSOR as readonly SensorTypeEnum[]).includes(sensor.type);
}

export function isMonitoringSensorWithSubSensors(
  sensor: MonitoringSensor,
): sensor is MonitoringSensor<SensorWithSubSensor> {
  return isSensorWithSubSensors(sensor.configuration);
}

export function splitSensors(
  sensorStatuses: MonitoringSensor[],
  mapFilters: Partial<MapFilters>,
): SplitMonitoringSensors {
  const tmp = partition<MonitoringSensor, MonitoringSensor<SensorWithSubSensor>>(
    sensorStatuses,
    isMonitoringSensorWithSubSensors,
  );
  let withSubSensors = tmp[0];
  let regularSensors = tmp[1] as MonitoringSensor<SensorDisplayedOnMap>[];
  const automatons = withSubSensors.filter(isAutomaton);

  if (mapFilters['displayPerimeterSensorAutomaton']) {
    regularSensors = [...regularSensors, ...automatons];
  }

  if (!mapFilters['displayPerimeterSensorCabinet']) {
    withSubSensors = withSubSensors.filter((ms) => ms.configuration.type !== SensorTypeEnum.AUTOMATON);
  }

  return { regularSensors, withSubSensors };
}

export function isConfigDisplayedOnMap(
  sensor: SensorConfiguration,
): sensor is GetSensorConfiguration<SensorDisplayedOnMap> {
  return !(SENSORS_NOT_DISPLAYED_ON_MAP as readonly SensorTypeEnum[]).includes(sensor.type);
}

export function isSensorDisplayedOnMap(sensor: SensorStatus): sensor is GetSensorStatus<SensorDisplayedOnMap> {
  return isConfigDisplayedOnMap(sensor.configuration);
}
