import { ReactComponent as ArrowLeft } from '@assets/icons/cameras/gauge-arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/cameras/gauge-arrow-right.svg';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { IconButton, Spinner } from '@chakra-ui/react';
import { useState } from 'react';

import SelectStack, { ElementInfo } from './SelectStack';

export type ElementInfo2<E> = {
  side: 'left' | 'right';
} & ElementInfo<E>;

type Props<E> = {
  leftTitle: string;
  rightTitle: string;
  elementInfos: ElementInfo2<E>[];
  isLoading?: boolean;
  getId: (element: E) => string | number;
  getLabel: (element: E) => string;
  onChange: (elementInfos: ElementInfo2<E>[]) => void;
};

export default function SideBySideSelect<E>({
  elementInfos,
  leftTitle,
  rightTitle,
  isLoading = false,
  getId,
  getLabel,
  onChange,
}: Readonly<Props<E>>) {
  const [previousElementInfo, setpreviousElementInfo] = useState<ElementInfo2<E>[]>(elementInfos);
  const [leftSideElementInfos, setLeftSideElementInfos] = useState<ElementInfo<E>[]>(
    elementInfos.filter((elementInfo) => elementInfo.side === 'left'),
  );
  const [rightSideElementInfos, setRightSideElementInfos] = useState<ElementInfo<E>[]>(
    elementInfos.filter((elementInfo) => elementInfo.side === 'right'),
  );

  if (previousElementInfo !== elementInfos) {
    setLeftSideElementInfos(elementInfos.filter((elementInfo) => elementInfo.side === 'left'));
    setRightSideElementInfos(elementInfos.filter((elementInfo) => elementInfo.side === 'right'));
    setpreviousElementInfo(elementInfos);
  }

  function handleToLeftArrowClick() {
    onChange(
      leftSideElementInfos
        .map((elementInfo) => ({ ...elementInfo, side: 'left' }) as ElementInfo2<E>)
        .concat(
          rightSideElementInfos
            .filter((elementInfo) => elementInfo.status === 'selected')
            .map((elementInfo) => ({ ...elementInfo, side: 'right' })),
        ),
    );
  }

  return (
    <HStack alignItems="start">
      <VStack alignItems="start">
        <Text color="neutral.black">{leftTitle}</Text>
        <SelectStack
          elementInfos={leftSideElementInfos}
          getId={getId}
          getLabel={getLabel}
          isDisabled={isLoading}
          onSelect={(elementInfos) => {
            setLeftSideElementInfos(elementInfos);
          }}
        />
      </VStack>
      <VStack marginTop="25.5px" width="40px">
        {isLoading ? (
          <Spinner size="lg" color="neutral.black" />
        ) : (
          <>
            <IconButton
              isDisabled={rightSideElementInfos.every((elementInfo) => elementInfo.status !== 'selected')}
              aria-label={'Left Arrow Icon'}
              icon={<ArrowLeft />}
              onClick={handleToLeftArrowClick}
            />
            <IconButton
              isDisabled={leftSideElementInfos.every((elementInfo) => elementInfo.status !== 'selected')}
              aria-label={'Right Arrow Icon'}
              icon={<ArrowRight />}
              onClick={() => {
                onChange(
                  leftSideElementInfos
                    .filter((elementInfo) => elementInfo.status !== 'selected')
                    .map((elementInfo) => ({ ...elementInfo, side: 'left' }) as ElementInfo2<E>),
                );
              }}
            />
          </>
        )}
      </VStack>
      <VStack alignItems="start">
        <Text color="neutral.black">{rightTitle}</Text>
        <SelectStack
          elementInfos={rightSideElementInfos}
          getId={getId}
          getLabel={getLabel}
          isDisabled={isLoading}
          onSelect={(elementInfos) => {
            setRightSideElementInfos(elementInfos);
          }}
        />
      </VStack>
    </HStack>
  );
}
