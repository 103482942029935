import AutomatonSectionLayer from '@components/map/layers/automatonSections/AutomatonSectionLayer';
import FlightPlanLayer from '@components/map/layers/flightPlans/FlightPlanLayer';
import LadCameraLayer from '@components/map/layers/ladCameras/LadCameraLayer';
import PerimeterCameraLayer from '@components/map/layers/perimeterCameras/PerimeterCameraLayer';
import PerimeterSensorLayer from '@components/map/layers/perimeterSensors/PerimeterSensorLayer';
import RadarCoverageLayer from '@components/map/layers/radarCoverage/RadarCoverageLayer';
import SelectionLayer from '@components/map/layers/SelectionLayer';
import SensorLayer from '@components/map/layers/sensors/SensorLayer';
import StrobeLayer from '@components/map/layers/strobes/StrobeLayer';
import TargetLayer from '@components/map/layers/targets/TargetLayer';
import ZoneLayer from '@components/map/layers/zones/ZoneLayer';

import EventLayer from './layers/event/EventLayer';
import PoiLayer from './layers/poi/PoiLayer';
import MapboxMap from './MapboxMap';

export default function ReplayMap() {
  // Do not add controls here
  return (
    <MapboxMap>
      <ZoneLayer />
      <FlightPlanLayer />
      <RadarCoverageLayer />
      <PerimeterCameraLayer />
      <LadCameraLayer />
      <StrobeLayer />
      <PerimeterSensorLayer />
      <SensorLayer />
      <AutomatonSectionLayer />
      <PoiLayer />
      <EventLayer />
      <TargetLayer />
      <SelectionLayer />
    </MapboxMap>
  );
}
