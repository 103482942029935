import { VStack } from '@chakra-ui/layout';
import { PropsWithChildren } from 'react';

export interface MapSidebarProps {
  position?: 'left' | 'right';
  paddingBottom: number;
}

export default function MapSidebar({ position, paddingBottom, children }: PropsWithChildren<MapSidebarProps>) {
  return (
    <VStack
      zIndex={1}
      gap="2px"
      position="absolute"
      height="100%"
      right={position === 'right' ? 0 : undefined}
      alignItems="flex-start"
      justifyContent="space-between"
      width={position === 'right' ? '76px' : '56px'}
      backgroundColor="neutral.black"
      paddingLeft={0.25}
      paddingBottom={paddingBottom}
    >
      {children}
    </VStack>
  );
}
