import { Icon } from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/layout';
import { memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  color?: string;
};

function CustomTitle({ children, color = 'neutral.black' }: Readonly<Props>) {
  return (
    <HStack height="45px" position="absolute" left="50%" transform="translateX(-50%)" top={0} gap={0} zIndex={3}>
      <Icon width="47px" height="45px" viewBox="0 0 47 45" marginRight="-2px" color={color}>
        <path d="M0 0L45 45L45 45L47 45L47 45L47 0L0 0Z" fill="currentColor" />
      </Icon>
      <HStack height="100%" gap={4} paddingX={2} paddingY={1} backgroundColor={color}>
        {children}
      </HStack>
      <Icon width="47px" height="45px" viewBox="0 0 47 45" marginLeft="-2px" color={color}>
        <path d="M0 0L0 45L1 45L47 0L0 0Z" fill="currentColor" />
      </Icon>
    </HStack>
  );
}

export default memo(CustomTitle);
