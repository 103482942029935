import { Box } from '@chakra-ui/react';

import { Coordinates } from '@/types/commons/commons.types';

import ElevationCompassBackground from './ElevationCompassBackground';
import ElevationCompassTargets from './ElevationCompassTargets';
import ElevationCompassValue from './ElevationCompassValue';

type Props = {
  fov: number;
  tilt: number;
  min: number;
  max: number;
  replayMode: boolean;
  cameraPosition: Coordinates;
  cameraAltitude: number;
};

export default function ElevationCompass({
  fov,
  tilt,
  replayMode,
  cameraPosition,
  min,
  max,
  cameraAltitude,
}: Readonly<Props>) {
  return (
    <Box
      marginLeft="-90px"
      width="180px"
      height="180px"
      position="relative"
      backgroundColor="transparent"
      clipPath="polygon(50% -20%, 150% -20%, 150% 120%, 50% 120%)"
    >
      <ElevationCompassBackground min={min} max={max} />
      <ElevationCompassValue fov={fov} tilt={tilt} />
      <ElevationCompassTargets
        replayMode={replayMode}
        cameraPosition={cameraPosition}
        cameraAltitude={cameraAltitude}
      />
    </Box>
  );
}
