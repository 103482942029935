import { Center, Text, VStack } from '@chakra-ui/layout';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectZoomByMapId } from '@redux/maps/maps.selectors';
import distance from '@turf/distance';
import { memo, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

type ScaleControlProps = {
  maxWidth?: number;
};

function getRoundNum(num: number) {
  const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1);
  let d = num / pow10;

  d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

  return pow10 * d;
}

function ScaleControl({ maxWidth = 100 }: Readonly<ScaleControlProps>) {
  const { current: mapbox } = useMap();
  const [width, setWidth] = useState<number>(maxWidth);
  const [value, setValue] = useState<string | null>(null);
  const { mapId } = useMapContext();
  const zoom = useAppSelector((state) => selectZoomByMapId(state, mapId));

  function setScale(maxWidth: number, maxDistance: number) {
    let unit = 'm';
    let distance = getRoundNum(maxDistance);
    const ratio = distance / maxDistance;

    if (distance >= 1000) {
      distance = distance / 1000;
      unit = 'km';
    }

    setWidth(maxWidth * ratio);
    setValue(distance + unit);
  }

  useEffect(() => {
    if (mapbox) {
      const y = mapbox.getContainer().clientHeight / 2;
      const src = mapbox.unproject([0, y]);
      const target = mapbox.unproject([maxWidth, y]);
      const maxMeters = distance([src.lng, src.lat], [target.lng, target.lat], { units: 'meters' });
      setScale(maxWidth, maxMeters);
    }
  }, [mapbox, maxWidth, zoom]);

  return (
    <VStack
      gap={0}
      backgroundColor="neutral.800-op70"
      color="neutral.white"
      width={maxWidth + 10}
      height="100%"
      justifyContent="center"
    >
      <Center>
        <Text>{value}</Text>
      </Center>
      <svg xmlns="http://www.w3.org/2000/svg" width={maxWidth} height="8" viewBox={`0 0 ${width} 9`} fill="none">
        <path d={`M0 0 L0 8 H${width} V 0`} stroke="white" strokeWidth="2" />
      </svg>
    </VStack>
  );
}

export default memo(ScaleControl);
