import {
  dronesAdapter,
  flightPlansAdapter,
  flyRoutesAdapter,
  flySectionsAdapter,
  flyZonesAdapter,
  pilotsAdapter,
} from '@redux/utm/utm.adapters';
import { droneSchema, flightPlanSchema, flyRouteSchema, flySectionSchema, utmSchema } from '@redux/utm/utm.schema';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { normalizeOne } from '@utils/schema.utils';
import { normalize } from 'normalizr';

import { Drone, NormalizedDrone } from '@/types/utm/drone.types';
import { FlightPlan, NormalizedFlightPlan } from '@/types/utm/flightPlan.types';
import { FlyZone } from '@/types/utm/flyZone.types';
import { Pilot } from '@/types/utm/pilot.types';
import { FlyRoute, FlySection, NormalizedFlyRoute, NormalizedFlySection } from '@/types/utm/route.types';

import { userLogout } from '../authent/authent.reducer';

export interface UtmState {
  pilots: EntityState<Pilot, string>;
  drones: EntityState<NormalizedDrone, string>;
  flightPlans: EntityState<NormalizedFlightPlan, string>;
  flyZones: EntityState<FlyZone, string>;
  flySections: EntityState<NormalizedFlySection, string>;
  flyRoutes: EntityState<NormalizedFlyRoute, string>;
}

export const UTM_INITIAL_STATE: UtmState = {
  pilots: pilotsAdapter.getInitialState(),
  drones: dronesAdapter.getInitialState(),
  flightPlans: flightPlansAdapter.getInitialState(),
  flyZones: flyZonesAdapter.getInitialState(),
  flySections: flySectionsAdapter.getInitialState(),
  flyRoutes: flyRoutesAdapter.getInitialState(),
};

const utmReducers = createSlice({
  name: 'utm',
  initialState: UTM_INITIAL_STATE,
  reducers: {
    updateUtm: (
      state,
      action: PayloadAction<{
        drones: Drone[];
        pilots: Pilot[];
        flightPlans: FlightPlan[];
        flyRoutes: FlyRoute[];
        flySections: FlySection[];
        flyZones: FlyZone[];
      }>,
    ) => {
      const { pilots, flyZones } = action.payload;
      const { drones, flightPlans, flySections, flyRoutes } = normalize(action.payload, utmSchema)
        .entities as unknown as {
        drones?: NormalizedDrone[];
        flightPlans?: NormalizedFlightPlan[];
        flyRoutes?: NormalizedFlyRoute[];
        flySections?: NormalizedFlySection[];
      };

      pilotsAdapter.setAll(state.pilots, pilots);
      dronesAdapter.setAll(state.drones, drones ?? []);
      flightPlansAdapter.setAll(state.flightPlans, flightPlans ?? []);
      flyZonesAdapter.setAll(state.flyZones, flyZones);
      flySectionsAdapter.setAll(state.flySections, flySections ?? []);
      flyRoutesAdapter.setAll(state.flyRoutes, flyRoutes ?? []);
    },
    addPilot: ({ pilots }, action: PayloadAction<Pilot>) => {
      pilotsAdapter.addOne(pilots, action.payload);
    },
    updatePilot: ({ pilots }, action: PayloadAction<Pilot>) => {
      pilotsAdapter.upsertOne(pilots, action.payload);
    },
    deletePilot: ({ pilots }, action: PayloadAction<Pilot>) => {
      pilotsAdapter.removeOne(pilots, action.payload.code);
    },
    addDrone: ({ drones }, action: PayloadAction<Drone>) => {
      const normalizedDrone = normalizeOne(action.payload, droneSchema);
      normalizedDrone && dronesAdapter.addOne(drones, normalizedDrone as NormalizedDrone);
    },
    updateDrone: ({ drones }, action: PayloadAction<Drone>) => {
      const normalizedDrone = normalizeOne(action.payload, droneSchema);
      normalizedDrone && dronesAdapter.upsertOne(drones, normalizedDrone as NormalizedDrone);
    },
    deleteDrone: ({ drones }, action: PayloadAction<Drone>) => {
      dronesAdapter.removeOne(drones, action.payload.code);
    },
    addFlightPlan: ({ flightPlans }, action: PayloadAction<FlightPlan>) => {
      const normalizedFlightPlan = normalizeOne(action.payload, flightPlanSchema);
      normalizedFlightPlan && flightPlansAdapter.addOne(flightPlans, normalizedFlightPlan as NormalizedFlightPlan);
    },
    updateFlightPlan: ({ flightPlans }, action: PayloadAction<FlightPlan>) => {
      const normalizedFlightPlan = normalizeOne(action.payload, flightPlanSchema);
      normalizedFlightPlan && flightPlansAdapter.upsertOne(flightPlans, normalizedFlightPlan as NormalizedFlightPlan);
    },
    deleteFlightPlan: ({ flightPlans }, action: PayloadAction<FlightPlan>) => {
      flightPlansAdapter.removeOne(flightPlans, action.payload.code);
    },
    addFlyZone: ({ flyZones }, action: PayloadAction<FlyZone>) => {
      flyZonesAdapter.addOne(flyZones, action.payload);
    },
    updateFlyZone: ({ flyZones }, action: PayloadAction<FlyZone>) => {
      flyZonesAdapter.upsertOne(flyZones, action.payload);
    },
    deleteFlyZone: ({ flyZones }, action: PayloadAction<FlyZone>) => {
      flyZonesAdapter.removeOne(flyZones, action.payload.code);
    },
    addFlySection: ({ flySections }, action: PayloadAction<FlySection>) => {
      const normalizedFlySection = normalizeOne(action.payload, flySectionSchema);
      normalizedFlySection && flySectionsAdapter.addOne(flySections, normalizedFlySection as NormalizedFlySection);
    },
    updateFlySection: ({ flySections }, action: PayloadAction<FlySection>) => {
      const normalizedFlySection = normalizeOne(action.payload, flySectionSchema);
      normalizedFlySection && flySectionsAdapter.upsertOne(flySections, normalizedFlySection as NormalizedFlySection);
    },
    deleteFlySection: ({ flySections }, action: PayloadAction<FlySection>) => {
      flySectionsAdapter.removeOne(flySections, action.payload.code);
    },
    addFlyRoute: ({ flyRoutes }, action: PayloadAction<FlyRoute>) => {
      const normalizedFlyRoute = normalizeOne(action.payload, flyRouteSchema);
      normalizedFlyRoute && flyRoutesAdapter.addOne(flyRoutes, normalizedFlyRoute as NormalizedFlyRoute);
    },
    updateFlyRoute: ({ flyRoutes }, action: PayloadAction<FlyRoute>) => {
      const normalizedFlyRoute = normalizeOne(action.payload, flyRouteSchema);
      normalizedFlyRoute && flyRoutesAdapter.upsertOne(flyRoutes, normalizedFlyRoute as NormalizedFlyRoute);
    },
    deleteFlyRoute: ({ flyRoutes }, action: PayloadAction<FlyRoute>) => {
      flyRoutesAdapter.removeOne(flyRoutes, action.payload.code);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => UTM_INITIAL_STATE);
  },
});
export const {
  updateUtm,
  addPilot,
  updatePilot,
  deletePilot,
  addDrone,
  updateDrone,
  deleteDrone,
  addFlightPlan,
  updateFlightPlan,
  deleteFlightPlan,
  addFlyZone,
  updateFlyZone,
  deleteFlyZone,
  addFlySection,
  updateFlySection,
  deleteFlySection,
  addFlyRoute,
  updateFlyRoute,
  deleteFlyRoute,
} = utmReducers.actions;

export type UtmActionType = typeof utmReducers.actions;
export default utmReducers.reducer;
