import { ResizeHandle } from '@/types/config/gridLayout.types';
import { GlobalScreenConfiguration, ScreenConfiguration } from '@/types/config/screenConfiguration.types';

export function compareScreenConfigurations(config1: ScreenConfiguration, config2: ScreenConfiguration) {
  return config1.code === config2.code && config1.gridLayoutCode === config2.gridLayoutCode;
}

export function compareGlobalScreenConfiguration(
  config1: GlobalScreenConfiguration,
  config2: GlobalScreenConfiguration,
) {
  return (
    config1.code === config2.code &&
    config1.name === config2.name &&
    config1.site === config2.site &&
    config1.position.latitude === config2.position.latitude &&
    config1.position.longitude === config2.position.longitude &&
    config2.screenConfigurations.every((screenConfiguration2) => {
      const screenConfiguration1 = config1.screenConfigurations.find(
        (element) => element.code === screenConfiguration2.code,
      );
      return screenConfiguration1 && compareScreenConfigurations(screenConfiguration1, screenConfiguration2);
    })
  );
}

export function generateHandles(minW: number | null | undefined, minH: number | null | undefined): ResizeHandle[] {
  const isDefinedMinW = minW !== null && minW !== undefined;
  const isDefinedMinH = minH !== null && minH !== undefined;
  if (isDefinedMinW && isDefinedMinH) {
    return ['nw', 'se', 'sw'];
  }
  if (isDefinedMinW) {
    return ['e', 'w'];
  }
  if (isDefinedMinH) {
    return ['n', 's'];
  }
  return [];
}
