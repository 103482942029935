import { ReactComponent as ChevronIcon } from '@assets/icons/24x24/ic-chevron-24.svg';
import { HStack, Icon, Tab, TabList, Text, VStack } from '@chakra-ui/react';
import SensorIcon from '@components/map/infos/sensors/SensorIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { formatDuration } from '@utils/date.utils';
import { getSensorIconKey } from '@utils/map/sensor.utils';
import { FormattedMessage } from 'react-intl';

import { SensorConfiguration, SubSensorConfiguration } from '@/types/sensor/configuration.types';
import { SensorStatus, SensorStatusEnum } from '@/types/sensor/status.types';

type Props = {
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  sensorStatus: SensorStatus | null;
  onClose: () => void;
};

export default function SensorDetailHeader({ sensorConfiguration, sensorStatus, onClose }: Readonly<Props>) {
  const time = useAppSelector(selectSituationTime);

  const timeSinceLastDetection = sensorStatus && time ? formatDuration(sensorStatus.lastMessageDate, time) : 'N/A';

  return (
    <HStack flexShrink={0} backgroundColor="neutral.800" width="100%" paddingX={3} position="relative" height="63px">
      <HStack gap={3} marginRight="auto">
        <Icon
          as={ChevronIcon}
          width="24px"
          height="24px"
          color="neutral.white"
          cursor="pointer"
          _hover={{
            color: 'sky.500',
          }}
          onClick={onClose}
        />
        <HStack gap={2}>
          <SensorIcon
            sensorIconName={getSensorIconKey(
              sensorConfiguration.type,
              sensorConfiguration.active,
              false,
              sensorConfiguration.maintenance ? SensorStatusEnum.DEGRADED : (sensorStatus?.status ?? null),
              true,
            )}
            size="sm"
          />
          <VStack gap={0.5} alignItems="start" maxWidth="290px">
            <Text fontSize="12px" fontWeight="medium" padding={0.25} backgroundColor="neutral.black">
              {sensorConfiguration.name}
            </Text>
            <Text color="neutral.200">
              <FormattedMessage id={`sensors.type.${sensorConfiguration.type}`} />
            </Text>
          </VStack>
        </HStack>
      </HStack>
      <TabList position="absolute" left="50%" transform="translateX(-50%)">
        <Tab>
          <FormattedMessage id="components.monitoring.sensorDetail.global.title" />
        </Tab>
        <Tab isDisabled>
          <FormattedMessage id="components.monitoring.sensorDetail.analysis" />
        </Tab>
        <Tab isDisabled>
          <FormattedMessage id="components.monitoring.sensorDetail.technical" />
        </Tab>
        <Tab isDisabled>
          <FormattedMessage id="components.monitoring.sensorDetail.documentation" />
        </Tab>
      </TabList>
      <VStack gap={0.5} marginLeft="auto" alignItems="start" width="290px">
        <Text fontWeight="medium" color="neutral.300">
          <FormattedMessage id="components.monitoring.sensorDetail.lastDetection" />
        </Text>
        <Text fontWeight="medium">{timeSinceLastDetection}</Text>
      </VStack>
    </HStack>
  );
}
