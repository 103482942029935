import { createEntityAdapter } from '@reduxjs/toolkit';
import { idComparator, stringPropertyComparator } from '@utils/common.utils';

import { NormalizedGroup, NormalizedProfile } from '@/types/authent/groups.types';
import { NormalizedUser } from '@/types/authent/login.types';
import { Role } from '@/types/authent/roles.types';
import { Site } from '@/types/authent/sites.types';

export const rolesAdapter = createEntityAdapter<Role, number>({
  selectId: (role) => role.id,
  sortComparer: stringPropertyComparator('roleName'),
});

export const profilesAdapter = createEntityAdapter<NormalizedProfile, number>({
  selectId: (profile) => profile.id,
  sortComparer: idComparator,
});

export const sitesAdapter = createEntityAdapter<Site, string>({
  selectId: (site) => site.code,
  sortComparer: stringPropertyComparator('code'),
});

export const usersAdapter = createEntityAdapter<NormalizedUser, number>({
  selectId: (user) => user.id,
  sortComparer: stringPropertyComparator('name'),
});

export const groupsAdapter = createEntityAdapter<NormalizedGroup, string>({
  selectId: (group) => group.code,
  sortComparer: stringPropertyComparator('name'),
});
