import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface OwnProps extends Omit<NumberInputProps, 'value' | 'onChange'> {
  allowMouseWheel?: boolean;
  showStepper?: boolean;
  value?: string | number;
  paddingRight?: number | string;
  paddingLeft?: number | string;
  onChange: (value?: number) => void;
}

export default function CustomInputNumber({
  value,
  showStepper = false,
  allowMouseWheel = true,
  paddingLeft,
  paddingRight,
  ...props
}: Readonly<OwnProps>) {
  const [innerValue, setInnerValue] = useState<string>(isNaN(Number(value)) ? '' : value!.toString());

  function handleChange(valueAsString: string, valueAsNumber: number) {
    const valueWithPoint = valueAsString.replace(',', '.');
    setInnerValue(valueWithPoint);
    if (valueAsNumber === Number(valueWithPoint)) {
      props.onChange(valueAsNumber);
    } else if (isNaN(valueAsNumber) && valueWithPoint === '') {
      props.onChange(undefined);
    }
  }

  useEffect(() => {
    setInnerValue(isNaN(Number(value)) ? '' : value!.toString());
  }, [value]);

  return (
    <NumberInput
      value={innerValue}
      allowMouseWheel={allowMouseWheel}
      isValidCharacter={(v) => /[0-9+\-.,]/.test(v)}
      {...props}
      onChange={handleChange}
    >
      <NumberInputField height={props.height} paddingLeft={paddingLeft} paddingRight={paddingRight} />
      {showStepper && (
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      )}
    </NumberInput>
  );
}
