import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomTitle from '@components/common/CustomTitle';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

type Props = {
  replayMode: boolean;
};

export default function CameraControlTitle({ replayMode }: Readonly<Props>) {
  const currentSituationTime = useAppSelector((state) => selectSituationTime(state, replayMode));

  return (
    <CustomTitle>
      <>
        <Text
          fontWeight="medium"
          fontSize="16px"
          color={replayMode ? 'sky.500' : 'alertHi.500'}
          noOfLines={1}
          userSelect="none"
        >
          <FormattedMessage id={replayMode ? 'cameras.replay' : 'cameras.live'} />
        </Text>
        {currentSituationTime && (
          <>
            <HStack gap={0}>
              <Text size="md" color="neutral.200">
                {format(new Date(currentSituationTime), 'HH:mm')}
              </Text>
              <Text fontSize="16px" color="neutral.300">
                {format(new Date(currentSituationTime), ':ss')}
              </Text>
            </HStack>
            <Text fontSize="16px" color="neutral.200">
              {format(new Date(currentSituationTime), 'dd/MM/yyyy')}
            </Text>
          </>
        )}
      </>
    </CustomTitle>
  );
}
