import { SHOW_LAD_SENSOR_MIN_ZOOM } from '@utils/map/mapZoom.constants';
import { SENSOR_LABEL_TEXT_SIZE } from '@utils/sensors/configuration.constants';
import { SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';
import { SENSOR_LABEL_ICON_OFFSET_PX, SENSOR_LABEL_TEXT_OFFSET_PX_L } from '@/types/sensor/sensor.types';

export const sensorIconLayer: SymbolLayer = {
  id: LayerNameEnum.SENSOR_ICONS,
  type: 'symbol',
  source: SourceNameEnum.SENSORS,
  filter: ['==', 'featureType', FeatureTypeEnum.SENSOR_ICON],
  layout: {
    'icon-image': [
      'step',
      ['zoom'],
      ['concat', ['get', 'icon'], '-S'],
      SHOW_LAD_SENSOR_MIN_ZOOM,
      ['concat', ['get', 'icon'], '-L'],
    ],
    'icon-size': 1,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'symbol-z-order': 'source',
  },
};

export const selectedSensorIconLayer: SymbolLayer = {
  ...sensorIconLayer,
  id: LayerNameEnum.SENSOR_ICON_SELECTION,
  source: SourceNameEnum.SELECTION,
  layout: {
    ...sensorIconLayer.layout,
    'icon-image': [
      'step',
      ['zoom'],
      ['concat', ['get', 'icon'], '-S-halo'],
      SHOW_LAD_SENSOR_MIN_ZOOM,
      ['concat', ['get', 'icon'], '-L-halo'],
    ],
  },
};

export const sensorIconLabelLayer: SymbolLayer = {
  id: LayerNameEnum.SENSOR_LABELS,
  type: 'symbol',
  source: SourceNameEnum.SENSORS,
  filter: ['==', ['get', 'featureType'], FeatureTypeEnum.SENSOR_LABEL],
  minzoom: SHOW_LAD_SENSOR_MIN_ZOOM,
  layout: {
    'icon-image': 'icSymb-label',
    'icon-size': 1,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-offset': SENSOR_LABEL_ICON_OFFSET_PX,
    'text-font': ['roboto'],
    'text-field': ['get', 'textField'],
    'text-size': SENSOR_LABEL_TEXT_SIZE,
    'text-allow-overlap': true,
    'text-offset': SENSOR_LABEL_TEXT_OFFSET_PX_L,
    'symbol-z-order': 'source',
  },
  paint: {
    'text-color': 'white',
  },
};

export const selectedSensorIconLabelLayer: SymbolLayer = {
  ...sensorIconLabelLayer,
  id: LayerNameEnum.SENSOR_LABEL_SELECTION,
  source: SourceNameEnum.SELECTION,
};
