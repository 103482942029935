import { SensorTypeEnum } from '@/types/sensor/configuration.types';

import { Abstract, Coordinates, Mark, TrackLight } from '../commons/commons.types';
import { ClassificationEnum } from '../sensor/classification.types';
import { IdentificationEnum, ReliabilityEnum } from '../sensor/identification.types';

export interface BaseNotification extends Abstract {
  id: number;
  site: string;
  appCode: string;
  notificationStatus: NotificationStatus;
  startTime: string;
  confirmationTime: string | null;
  endTime: string | null;
  archivedTime: string | null;
  confirmed: boolean;
  identifier: string;
  position?: Coordinates;
  marks: Mark[];
}

export interface IdentificationNotification extends BaseNotification {
  type: NotificationType.DRONE_IDENTIFICATION;
  identification: IdentificationEnum;
  classification: ClassificationEnum;
  creationPosition: Coordinates;
  trajectory?: Coordinates[];
  worstClassification: ClassificationEnum;
  alertCount: number;
}

export interface AlertLight {
  id: number;
  level: AlertLevelEnum;
  alertType: AlertTypeEnum;
}

export interface BaseAlert extends BaseNotification {
  alertType: AlertTypeEnum;
  level: AlertLevelEnum;
  future?: NotificationFutureEnum;
  ack: boolean;
  ackUser: string;
  ackTime: string;
  parentId: number | null;
}

export interface SpaceViolationAlert extends BaseAlert {
  type: NotificationType.SPACE_VIOLATION_ALERT;
  zoneId: number;
  heightMin: number;
  heightMax: number;
  verticalTrespass: VerticalTrespassEnum;
  targetAltitude: number | null;
  targetStatus: NotificationTargetStatus;
  trackIds: TrackLight[];
  reliability: ReliabilityEnum | null;
  classification: ClassificationEnum | null;
  startPosition: Coordinates | null;
}

export interface CollisionAlert extends BaseAlert {
  type: NotificationType.COLLISION_ALERT;
  targetStatus: NotificationTargetStatus;
  targetId2: string;
  targetStatus2: NotificationTargetStatus;
  trackIds: TrackLight[];
  safetyDistance: number;
}

export interface SensorFailureAlert extends BaseAlert {
  type: NotificationType.SENSOR_FAILURE_ALERT;
  sensorType: SensorTypeEnum;
}

export interface PerimeterEffractionAlert extends BaseAlert {
  type: NotificationType.PERIMETER_EFFRACTION_ALERT;
  sensorType: SensorTypeEnum;
  cabinetName: string;
  segmentName: string;
}

export interface SegmentFailureAlert extends BaseAlert {
  type: NotificationType.AUTOMATON_SEGMENT_FAILURE_ALERT;
  sensorType: SensorTypeEnum;
  cabinetName: string;
  segmentName: string;
}

export type TargetAlertType = NotificationType.COLLISION_ALERT | NotificationType.SPACE_VIOLATION_ALERT;
export type SensorAlertType = NotificationType.SENSOR_FAILURE_ALERT;
export type Notification =
  | SpaceViolationAlert
  | CollisionAlert
  | IdentificationNotification
  | SensorFailureAlert
  | PerimeterEffractionAlert
  | SegmentFailureAlert;
export type TargetAlert = Extract<Notification, { type: TargetAlertType }>;
export type SensorAlert = Extract<Notification, { type: SensorAlertType }>;
export type Alert = TargetAlert | SensorAlert | PerimeterEffractionAlert | SegmentFailureAlert;

export enum NotificationType {
  SPACE_VIOLATION_ALERT = 'SPACE_VIOLATION_ALERT',
  COLLISION_ALERT = 'COLLISION_ALERT',
  DRONE_IDENTIFICATION = 'DRONE_IDENTIFICATION',
  SENSOR_FAILURE_ALERT = 'SENSOR_FAILURE_ALERT',
  PERIMETER_EFFRACTION_ALERT = 'PERIMETER_EFFRACTION_ALERT',
  AUTOMATON_SEGMENT_FAILURE_ALERT = 'AUTOMATON_SEGMENT_FAILURE_ALERT',
}

export enum NotificationStatus {
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  ARCHIVED = 'ARCHIVED',
}

export enum NotificationTargetStatus {
  ONGOING = 'ONGOING',
  LOST = 'LOST',
}

export enum AlertTypeEnum {
  FLIGHT_PLAN_ALERT = 'FLIGHT_PLAN_ALERT',
  FLIGHT_PLAN_VIOLATION_ALERT = 'FLIGHT_PLAN_VIOLATION_ALERT',
  NO_FLY_ZONE_ALERT = 'NO_FLY_ZONE_ALERT',
  COLLISION_ALERT = 'COLLISION_ALERT',
  SENSOR_FAILURE = 'SENSOR_FAILURE',
}

//Order matter for selectSituationAlertLevel in situation.selectors.ts
export enum AlertLevelEnum {
  INFO = 'INFO',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export enum VerticalTrespassEnum {
  INSIDE_BOUNDARIES = 'INSIDE_BOUNDARIES',
  ABOVE_MAX_HEIGHT = 'ABOVE_MAX_HEIGHT',
  BELOW_MIN_HEIGHT = 'BELOW_MIN_HEIGHT',
}

export enum NotificationFutureEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  PATCH_STATUS = 'PATCH_STATUS',
  DELETE = 'DELETE',
}

export interface NotificationCriteria {
  site: string;
  to?: string;
  from?: string;
  identifier?: string;
  page?: number;
  size?: number;
  types?: NotificationType[];
  unpaginated?: boolean;
  notificationStatus?: NotificationStatus[];
  platforms?: string[];
}
