import { roundSectionLayer } from '@components/map/layers/round/roundSection.layer';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectRoundSectionsFeatures } from '@redux/config/config.selectors';
import { selectZoomByMapId } from '@redux/maps/maps.selectors';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export default function RoundLayer() {
  const { mapId } = useMapContext();
  const zoom = useAppSelector((state) => selectZoomByMapId(state, mapId));
  const segmentFeatures = useSelectorWithReplayMode(selectRoundSectionsFeatures, zoom);

  return (
    <Source id={SourceNameEnum.ROUNDS} type="geojson" data={segmentFeatures} generateId>
      <Layer key={LayerNameEnum.ROUND_SECTIONS} {...roundSectionLayer} />
    </Source>
  );
}
