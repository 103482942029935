import { ReactComponent as FileConfigIcon } from '@assets/icons/40x40/ic-file-config-40.svg';
import { VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import DataSheetCardLink from '@components/common/layout/DataSheetCardLink';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSubSensorStatusByUniqueCode } from '@redux/situation/monitoring.selectors';
import { FormattedMessage, useIntl } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import { AutomatonCabinetStatus } from '@/types/sensor/status.types';

import { MaintenanceVariant } from './SpecificMaintenance';

type Props = {
  sensorUniqueCodes: SensorUniqueCodes;
  replayMode: boolean;
  variant: MaintenanceVariant;
};

export default function CabinetMaintenance({ sensorUniqueCodes, replayMode, variant }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const cabinetStatus = useAppSelector((state) =>
    selectSubSensorStatusByUniqueCode(state, replayMode, sensorUniqueCodes.sensorUniqueCode),
  ) as AutomatonCabinetStatus | null;

  const cabinetsStates = cabinetStatus?.cabinetStates;

  if (variant === MaintenanceVariant.MONITORING) {
    return null;
  }

  return (
    <DataSheetCardLink icon={FileConfigIcon} label={formatMessage({ id: 'sensors.maintenance.cabinet.technical' })}>
      <VStack padding={4} backgroundColor="neutral.800" width="100%" maxHeight="250px">
        <MapCustomScrollbar marginTop={0}>
          {cabinetsStates && Object.keys(cabinetsStates).length ? (
            <VStack gap={2} alignItems="start">
              <VStack width="100%" gap={1} alignItems="start">
                {Object.entries(cabinetsStates).map(([key, values]) => {
                  return (
                    <VStack key={key} gap={0.5} alignItems="start" paddingRight={2}>
                      <Text size="md" color="neutral.300">
                        <FormattedMessage id={`automatonCabinet.state.${key}`} />
                      </Text>
                      <VStack alignItems="start" gap={0.25} userSelect="text">
                        {values
                          .toSorted((a, b) => a.localeCompare(b))
                          .map((value) => (
                            <Text key={value} wordBreak="break-all">
                              {value}
                            </Text>
                          ))}
                      </VStack>
                    </VStack>
                  );
                })}
              </VStack>
            </VStack>
          ) : (
            <Text wordBreak="break-word">
              <FormattedMessage id="sensors.status.NODATA" />
            </Text>
          )}
        </MapCustomScrollbar>
      </VStack>
    </DataSheetCardLink>
  );
}
