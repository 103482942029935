import { ReactComponent as ChevronDownIcon } from '@assets/icons/24x24/ic-chevron-down-24.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, SVGProps, useState } from 'react';

//This component can open a link or display children, but not both
type AdditionalProps =
  | {
      children: ReactNode;
      onClick?: never;
    }
  | {
      children?: never;
      onClick: () => void;
    };

type GlobalProps = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
};

type Props = GlobalProps & AdditionalProps;

export default function DataSheetCardLink({ icon, label, children, onClick }: Readonly<Props>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <VStack gap={0.25} width="100%" backgroundColor="neutral.700">
      <HStack
        paddingX={4}
        paddingY={3}
        gap={2}
        width="100%"
        userSelect="none"
        backgroundColor="neutral.800"
        _hover={{ backgroundColor: 'cyber.700', cursor: 'pointer' }}
        onClick={() => (onClick ? onClick() : setIsOpen((prev) => !prev))}
      >
        <Icon as={icon} width="40px" height="40px" color="neutral.300" />
        <Text size="md" width="100%">
          {label}
        </Text>
        <Icon
          as={ChevronDownIcon}
          width="24px"
          height="24px"
          color="neutral.white"
          transform={isOpen ? 'rotate(180deg)' : ''}
        />
      </HStack>
      {isOpen && children}
    </VStack>
  );
}
