import { ReactComponent as LevelBackground } from '@assets/components/panel/zone/level.svg';
import { FormControl } from '@chakra-ui/form-control';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import ErrorContainer from '@components/common/form/ErrorContainer';
import CustomCheckbox from '@components/common/inputs/CustomCheckbox';
import CustomInputNumber from '@components/common/inputs/CustomInputNumber';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectHome } from '@redux/config/config.selectors';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { Coordinates, CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { ZoneFormDisplayedFormat } from '@/types/map.types';

import { ZoneForm } from './ZoneFormPopup';
import ZonePointsGrid from './ZonePointsGrid';

type Props = {
  touched: FormikTouched<ZoneForm>;
  errors: FormikErrors<ZoneForm>;
  values: ZoneForm;
  unit: CoordinatesUnitEnum;
  points: Coordinates[];
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<ZoneForm>>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<ZoneForm>>;
};

export default function RightSideForm({
  touched,
  errors,
  values,
  unit,
  points,
  setFieldTouched,
  setFieldValue,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const home = useAppSelector(selectHome);

  return (
    <MapCustomScrollbar height="100%" margin={1} marginTop={1} marginBottom={1} thumbColor="neutral.600">
      <VStack width="100%" padding={2} paddingRight={3} gap={2} backgroundColor="neutral.900" flexGrow={1}>
        <HStack width="100%" gap={0.25} alignItems="start">
          <Box position="relative" width="64px" flexShrink={0}>
            <LevelBackground style={{ position: 'absolute' }} />
            <VStack zIndex={1} gap={0} paddingTop="14px" paddingLeft="14px" paddingRight="10px">
              <Text size="md" color="neutral.300" zIndex={1}>
                <FormattedMessage id="zone.niv" />
              </Text>
              <Text variant="space" fontSize="32px" zIndex={1}>
                1
              </Text>
              <Text size="md" color="neutral.300" zIndex={1}>
                <FormattedMessage id="zone.upper" />
              </Text>
            </VStack>
          </Box>
          <VStack width="100%" minHeight="328px" padding={2} gap={2} backgroundColor="neutral.800" alignItems="start">
            <FormControl id="upperLevelHeight" isRequired isInvalid={!!errors.upperLevelHeight}>
              <HStack gap={1.5}>
                <Text color="neutral.300" size="md" pointerEvents="none">
                  <FormattedMessage
                    id="zone.meter"
                    values={{ label: formatMessage({ id: `zone.${values.displayedFormat}` }) }}
                  />
                </Text>
                <Field
                  as={CustomInputNumber}
                  id="upperLevelHeight"
                  width="64px"
                  height="48px"
                  name="upperLevelHeight"
                  isInvalid={!!errors.upperLevelHeight}
                  onChange={async (value?: number) => {
                    await setFieldTouched('upperLevelHeight', true);
                    await setFieldValue('upperLevelHeight', value ?? '');
                  }}
                />
              </HStack>
            </FormControl>
            <ErrorContainer error={errors.upperLevelHeight} touched={touched.upperLevelHeight} />
            <ZonePointsGrid mapboxPoints={points} height={values.upperLevelHeight} unit={unit} />
          </VStack>
        </HStack>
        <HStack width="100%" gap={0.25} alignItems="start">
          <Box position="relative" width="64px" flexShrink={0}>
            <LevelBackground style={{ position: 'absolute' }} />
            <VStack zIndex={1} gap={0} paddingTop="14px" paddingLeft="14px" paddingRight="10px">
              <Text size="md" color="neutral.300" zIndex={1}>
                <FormattedMessage id="zone.niv" />
              </Text>
              <Text variant="space" fontSize="32px" zIndex={1}>
                2
              </Text>
              <Text size="md" color="neutral.300" zIndex={1}>
                <FormattedMessage id="zone.lower" />
              </Text>
            </VStack>
          </Box>
          <VStack width="100%" minHeight="288px" padding={2} gap={2} backgroundColor="neutral.800" alignItems="start">
            <HStack gap={1.5} width="100%">
              <Text color="neutral.300" size="md" pointerEvents="none">
                <FormattedMessage
                  id="zone.meter"
                  values={{ label: formatMessage({ id: `zone.${values.displayedFormat}` }) }}
                />
              </Text>
              <FormControl
                id="lowerLevelHeight"
                isRequired={!values.isLowerHeightOnGround}
                isInvalid={!!errors.lowerLevelHeight}
                isDisabled={values.isLowerHeightOnGround}
                width="64px"
              >
                <Field
                  as={CustomInputNumber}
                  id="lowerLevelHeight"
                  width="64px"
                  height="48px"
                  name="lowerLevelHeight"
                  isInvalid={!!errors.lowerLevelHeight}
                  isDisabled={values.isLowerHeightOnGround}
                  onChange={async (value?: number) => {
                    await setFieldTouched('upperLevelHeight', true);
                    await setFieldTouched('lowerLevelHeight', true);
                    await setFieldValue('lowerLevelHeight', value ?? '');
                  }}
                />
              </FormControl>
              <CustomCheckbox
                isChecked={values.isLowerHeightOnGround}
                marginLeft={2.5}
                onChange={() => {
                  const siteAltitude = home ? home.altitude : 0;
                  setFieldValue('isLowerHeightOnGround', !values.isLowerHeightOnGround);
                  setFieldValue(
                    'lowerLevelHeight',
                    values.displayedFormat === ZoneFormDisplayedFormat.HEIGHT ? 0 : siteAltitude,
                  );
                }}
              >
                <FormattedMessage id="zone.groundHeight" />
              </CustomCheckbox>
            </HStack>
            <ErrorContainer error={errors.lowerLevelHeight} touched={touched.lowerLevelHeight} />
            <ZonePointsGrid mapboxPoints={points} height={values.lowerLevelHeight} unit={unit} isLowerLevel />
          </VStack>
        </HStack>
      </VStack>
    </MapCustomScrollbar>
  );
}
