import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole } from '@redux/authent/authent.selectors';
import { usePostAutomatonCommandMutation } from '@services/c2/c2.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { AutomatonCommandEnum, AutomatonDefenceCommand, AutomatonSleepAllCabinetsCommand } from '@/types/c2/c2.types';
import { AutomatonConfiguration } from '@/types/sensor/configuration.types';
import { AutomatonSensorStatus } from '@/types/sensor/status.types';

export interface UseAutomatonCommandParameters {
  automatonStatus: AutomatonSensorStatus | null;
  automatonConfiguration: AutomatonConfiguration | null;
}

export interface UseAutomatonCommandFunctions {
  canCommand: boolean;
  canRepulse: boolean;
  canDetect: boolean;
  repulse: (enableRepulsion: boolean) => void;
  sleepAll: (powerOnCabinets: boolean) => void;
  isSleepAllCommandLoading: boolean;
  isRepulsionCommandLoading: boolean;
}

export const useAutomatonCommand: (params: UseAutomatonCommandParameters) => UseAutomatonCommandFunctions = ({
  automatonStatus,
  automatonConfiguration,
}: UseAutomatonCommandParameters) => {
  const { formatMessage } = useIntl();

  const [postSleepAllCommand, { isLoading: isSleepAllLoading }] = usePostAutomatonCommandMutation();
  const [postRepulsionCommand, { isLoading: isRepulsionLoading }] = usePostAutomatonCommandMutation();

  const canSendPerimeterCommands = useAppSelector((state) => hasRole(state, RoleEnum.PERI_SENSOR_COMMAND));
  const canSendRepulsionCommand = useAppSelector((state) => hasRole(state, RoleEnum.AUTOMATON_REPULSION_COMMAND));
  const canSendDetectionCommand = useAppSelector((state) => hasRole(state, RoleEnum.AUTOMATON_DETECTION_COMMAND));

  const handleCabinetsPower = (powerOnCabinets: boolean) => {
    if (automatonStatus !== null && automatonConfiguration !== null) {
      const command: AutomatonSleepAllCabinetsCommand = {
        type: AutomatonCommandEnum.AUTOMATON_COMMAND_SLEEP_ALL_CABINETS,
        sensorCode: automatonConfiguration.code,
        sleep: !powerOnCabinets,
      };
      postSleepAllCommand({
        site: automatonConfiguration.site,
        appCode: automatonConfiguration.appCode,
        command,
      })
        .unwrap()
        .then(() =>
          createToast(
            formatMessage(
              { id: 'contextmenu.actions.automaton.sleep.toast' },
              {
                sleep: !powerOnCabinets,
                success: true,
              },
            ),
            ToastStatusEnum.SUCCESS,
          ),
        )
        .catch(() =>
          createToast(
            formatMessage(
              { id: 'contextmenu.actions.automaton.sleep.toast' },
              {
                sleep: !powerOnCabinets,
                success: false,
              },
            ),
            ToastStatusEnum.ERROR,
          ),
        );
    }
  };

  const handleAutomatonRepulsion = (enableRepulsion: boolean) => {
    if (automatonStatus !== null && automatonConfiguration !== null) {
      const command = {
        type: AutomatonCommandEnum.AUTOMATON_COMMAND_DEFENCE,
        sensorCode: automatonConfiguration.code,
        defence: enableRepulsion,
      } as AutomatonDefenceCommand;
      postRepulsionCommand({
        site: automatonConfiguration.site,
        appCode: automatonConfiguration.appCode,
        command,
      })
        .unwrap()
        .then(() =>
          createToast(
            formatMessage(
              { id: 'contextmenu.actions.automaton.defence.toast' },
              {
                repulsion: enableRepulsion,
                success: true,
              },
            ),
            ToastStatusEnum.SUCCESS,
          ),
        )
        .catch(() =>
          createToast(
            formatMessage(
              { id: 'contextmenu.actions.automaton.defence.toast' },
              {
                repulsion: enableRepulsion,
                success: false,
              },
            ),
            ToastStatusEnum.ERROR,
          ),
        );
    }
  };

  return {
    canCommand: canSendPerimeterCommands,
    canDetect: canSendDetectionCommand,
    canRepulse: canSendRepulsionCommand,
    isRepulsionCommandLoading: isRepulsionLoading,
    isSleepAllCommandLoading: isSleepAllLoading,
    repulse: handleAutomatonRepulsion,
    sleepAll: handleCabinetsPower,
  };
};
