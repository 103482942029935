import { ReactComponent as PlayerPause } from '@assets/components/button/icPlayer-pause.svg';
import { ReactComponent as PlayerPlay } from '@assets/components/button/icPlayer-play.svg';
import { ReactComponent as Calendar } from '@assets/icons/24x24/ic-calendar-24.svg';
import { ReactComponent as Export } from '@assets/icons/32x32/ic-share-32.svg';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import CustomSelect from '@components/common/inputs/CustomStyledSelect';
import ReplayDatePopupForm from '@components/replay/ReplayDatePopupForm';
import { useReplayTimelineContext } from '@components/replay/ReplayTimelineContext';
import VideoExport from '@components/replay/videoExport/VideoExport';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasRole } from '@redux/authent/authent.selectors';
import { closePopupByMapId, updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { selectReplaySpeed, selectReplayUuid } from '@redux/replay/replay.selectors';
import { useUpdateStreamMutation } from '@services/replay/replay.api';
import { EPSILON } from '@utils/common.utils';
import { getNewRangeFromTimeScale } from '@utils/replay/replay.utils';
import { useRef } from 'react';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { PopupDataTypeEnum } from '@/types/map.types';
import { ReplayTimeScaleEnum } from '@/types/replay/replay.types';

export default function TimelineControls() {
  const { formatMessage } = useIntl();
  const hasRoleExportVideo = useAppSelector((state) => hasRole(state, RoleEnum.EXPORT_VIDEO));
  const timelineRef = useRef<HTMLDivElement>(null);
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const closePopupControl = useWithDispatch(closePopupByMapId);
  const { mapId, range, setDateRange, timeScale, setTimeScale, currentReplayTime, backwardForwardTooltip } =
    useReplayTimelineContext();
  const popupControl = useAppSelector((state) => selectPopupControlByMapId(state, mapId));

  const replaySpeed = useAppSelector(selectReplaySpeed);
  const replayUuid = useAppSelector(selectReplayUuid);
  const isPlaying = !!replaySpeed && Math.abs(replaySpeed) > EPSILON;
  const [updateStream] = useUpdateStreamMutation();

  function handleCalendar(position: { x: number; y: number }) {
    if (popupControl.open && popupControl.type === PopupDataTypeEnum.REPLAY) {
      closePopupControl(mapId);
    } else {
      updatePopupControl({
        mapId: mapId,
        popupControl: {
          type: PopupDataTypeEnum.REPLAY,
          open: true,
          position,
          data: null,
        },
      });
    }
  }

  function handleSelectTimeScale(value: string) {
    const newTimeScale = value as ReplayTimeScaleEnum;
    setTimeScale(newTimeScale);
    const { from, to } = getNewRangeFromTimeScale(newTimeScale, range.from, currentReplayTime);
    setDateRange(from, to);
  }

  function handleVideoExport(position: { x: number; y: number }) {
    if (popupControl.open && popupControl.type === PopupDataTypeEnum.VIDEO_EXPORT) {
      closePopupControl(mapId);
    } else {
      updatePopupControl({
        mapId: mapId,
        popupControl: {
          type: PopupDataTypeEnum.VIDEO_EXPORT,
          open: true,
          position,
          data: null,
        },
      });
    }
  }

  function handlePlayStop() {
    if (replayUuid) {
      updateStream({ uuid: replayUuid, command: { speed: isPlaying ? 0 : 1 } });
    }
  }

  return (
    <HStack
      ref={timelineRef}
      alignItems="center"
      position="relative"
      backgroundColor="neutral.black"
      color="neutral.white"
      height="62px"
      gap={0}
    >
      {popupControl.open && popupControl.type === PopupDataTypeEnum.REPLAY && <ReplayDatePopupForm />}
      {popupControl.open && popupControl.type === PopupDataTypeEnum.VIDEO_EXPORT && <VideoExport />}
      <IconButton
        marginX={3}
        marginY="14px"
        aria-label="choose dates"
        variant="calendar"
        isActive={popupControl.open && popupControl.type === PopupDataTypeEnum.REPLAY}
        onClick={(e) => handleCalendar({ x: e.clientX, y: e.clientY })}
        icon={<Calendar />}
      />
      <CustomSelect
        variant="dark"
        width={205}
        menuPortalTarget={document.body}
        value={timeScale}
        isClearable={false}
        menuPlacement="top"
        options={Object.values(ReplayTimeScaleEnum).map((value) => ({
          label: formatMessage({ id: `replay.timeScale.${value}` }),
          value,
        }))}
        onChange={handleSelectTimeScale}
      />
      <Box marginLeft="auto" marginRight="102px">
        <Tooltip
          isOpen={backwardForwardTooltip.isDisplayed}
          label={backwardForwardTooltip.label}
          placement="top"
          closeDelay={300}
          top={-0.5}
        >
          <IconButton
            aria-label={formatMessage({ id: `replay.timeline.actions.${isPlaying ? 'stop' : 'play'}` })}
            variant="smallPlayer"
            icon={<Icon as={isPlaying ? PlayerPause : PlayerPlay} width="32px" height="32px" />}
            isDisabled={!replayUuid}
            onClick={handlePlayStop}
          />
        </Tooltip>
      </Box>
      <VStack
        backgroundColor="neutral.800"
        width="151px"
        height="48px"
        marginRight="auto"
        top={0}
        alignItems="center"
        justifyContent="center"
        gap={0}
      >
        <Text>
          <FormattedDate value={currentReplayTime} day="numeric" month="numeric" year="numeric" />
        </Text>
        <Text as="b">
          <FormattedTime value={currentReplayTime} hour="numeric" minute="numeric" second="numeric" />
        </Text>
      </VStack>
      {hasRoleExportVideo && (
        <IconButton
          aria-label={formatMessage({ id: 'videoExport.title' })}
          variant="smallPlayer"
          justifySelf="end"
          marginRight="24px"
          icon={<Icon as={Export} width="32px" height="32px" />}
          title={formatMessage({ id: 'videoExport.title' })}
          isActive={popupControl.open && popupControl.type === PopupDataTypeEnum.VIDEO_EXPORT}
          onClick={(e) => handleVideoExport({ x: e.clientX, y: e.clientY })}
        />
      )}
    </HStack>
  );
}
