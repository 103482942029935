import { ReactComponent as DroneIcon } from '@assets/components/gauge/drone.svg';
import { Box, Icon } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSpatialDrones } from '@redux/situation/situation.selectors';
import distance from '@turf/distance';
import { getTargetColor } from '@utils/map/map.utils';
import { getPositionOnCircle } from '@utils/sensors/camera/gauge.utils';
import React from 'react';

import { Coordinates } from '@/types/commons/commons.types';

type Props = {
  replayMode: boolean;
  cameraAltitude: number;
  cameraPosition: Coordinates;
};

export default function ElevationCompassTargets({ replayMode, cameraAltitude, cameraPosition }: Readonly<Props>) {
  const targets = useAppSelector((state) => selectSpatialDrones(state, replayMode));

  return (
    <Box position="absolute" width="100%" height="100%" top={0} zIndex={2}>
      {targets
        .filter((target) => !!target.lastPosition.location?.position && !!target.lastPosition.location.altitude)
        .map((target) => {
          const targetPosition = target.lastPosition.location!.position!;
          const targetAltitude = target.lastPosition.location!.altitude!;
          const targetDistance = distance(
            [cameraPosition.longitude, cameraPosition.latitude],
            [targetPosition.longitude, targetPosition.latitude],
            { units: 'meters' },
          );
          const angle = +(Math.atan((targetAltitude - cameraAltitude) / targetDistance) * (180 / Math.PI)).toFixed(1);
          const position = getPositionOnCircle(-angle, 90, { x: 90, y: 90 }, 0);
          return (
            <Icon
              as={DroneIcon}
              key={target.id}
              color={getTargetColor(target)}
              width="24px"
              height="24px"
              position="absolute"
              transform="translate(-50%, -50%)"
              top={`${position.y}px`}
              left={`${position.x}px`}
            />
          );
        })}
    </Box>
  );
}
