import { useMapContext } from '@components/map/MapContext';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { Position } from '@turf/helpers';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import { EventData, MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMap } from 'react-map-gl';

import { Coordinates } from '@/types/commons/commons.types';
import { PopupDataTypeEnum } from '@/types/map.types';

export const useMapMeasure = (): [Coordinates | null, Position[]] => {
  const { current: mapbox } = useMap();
  const { mousePosition, measurePoints, isClickEnabled, setMousePosition, setIsClickEnabled, setMeasurePoints } =
    useMapContext();
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const shouldMeasure = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const handleMouseMove = useCallback(
    (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
      if (mapbox && shouldMeasure && isClickEnabled) {
        mapbox.getCanvas().style.cursor = 'crosshair';
        const mousePosition = toCoordinates(event.lngLat);
        if (mousePosition) {
          setMousePosition(mousePosition);
        }
      }
    },
    [mapbox, setMousePosition, shouldMeasure, isClickEnabled],
  );

  const handleMeasure = useCallback(
    (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
      if (mapbox && shouldMeasure && isClickEnabled) {
        const newPoints: Position[] = [...measurePoints, [event.lngLat.lng, event.lngLat.lat]];
        setMeasurePoints(newPoints);
      }
    },
    [mapbox, shouldMeasure, isClickEnabled, measurePoints, setMeasurePoints],
  );

  const handleDoubleClick = useCallback(
    (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
      event.preventDefault();
      if (mapbox && isClickEnabled && measurePoints.length > 2) {
        setMeasurePoints(measurePoints.slice(0, -1));
        mapbox.getCanvas().style.cursor = 'auto';
        setIsClickEnabled(false);
      }
    },
    [mapbox, isClickEnabled, measurePoints, setMeasurePoints, setIsClickEnabled],
  );

  const handleEnterKey = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (mapbox && isClickEnabled && measurePoints.length >= 2) {
        mapbox.getCanvas().style.cursor = 'auto';
        setIsClickEnabled(false);
        setMousePosition(toCoordinates(measurePoints[measurePoints.length - 1]));
      }
    },
    [mapbox, isClickEnabled, measurePoints, setIsClickEnabled, setMousePosition],
  );

  const handleClearDrawing = useCallback(() => {
    if (mapbox && !isClickEnabled) {
      setMeasurePoints([]);
      setIsClickEnabled(true);
    }
  }, [mapbox, isClickEnabled, setMeasurePoints, setIsClickEnabled]);

  useEffect(() => {
    if (!shouldMeasure) {
      setIsClickEnabled(true);
      setMeasurePoints([]);
    }

    return () => {
      if (mapbox) {
        mapbox.getCanvas().style.cursor = 'auto';
      }
    };
    // eslint-disable-next-line
  }, [mapbox, shouldMeasure]);

  useMapLayerMouseEvent('mousemove', handleMouseMove);
  useMapLayerMouseEvent('click', handleMeasure);

  useMapLayerMouseEvent('dblclick', handleDoubleClick);
  useHotkeys('enter', handleEnterKey);
  useMapLayerMouseEvent('click', handleClearDrawing);

  return [mousePosition, measurePoints];
};
