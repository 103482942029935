export enum RoleEnum {
  ACCESS_SENSOR = 'ACCESS_SENSOR',
  CREATE_SENSOR = 'CREATE_SENSOR',
  EDIT_SENSOR = 'EDIT_SENSOR',
  SENSOR_MAINTENANCE = 'SENSOR_MAINTENANCE',
  DELETE_SENSOR = 'DELETE_SENSOR',
  EDIT_AUTOMATON = 'EDIT_AUTOMATON',

  ACCESS_USER = 'ACCESS_USER',
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  EDIT_AUTHENT_SETTINGS = 'EDIT_AUTHENT_SETTINGS',
  DELETE_USER = 'DELETE_USER',

  ACCESS_PROFILE = 'ACCESS_PROFILE',
  CREATE_PROFILE = 'CREATE_PROFILE',
  EDIT_PROFILE = 'EDIT_PROFILE',
  DELETE_PROFILE = 'DELETE_PROFILE',

  ACCESS_DRONE = 'ACCESS_DRONE',
  CREATE_DRONE = 'CREATE_DRONE',
  EDIT_DRONE = 'EDIT_DRONE',
  DELETE_DRONE = 'DELETE_DRONE',

  CHANGE_CLEARANCE = 'CHANGE_CLEARANCE',

  ACCESS_PILOT = 'ACCESS_PILOT',
  CREATE_PILOT = 'CREATE_PILOT',
  EDIT_PILOT = 'EDIT_PILOT',
  DELETE_PILOT = 'DELETE_PILOT',

  ACCESS_FLIGHT_PLAN = 'ACCESS_FLIGHT_PLAN',
  CREATE_FLIGHT_PLAN = 'CREATE_FLIGHT_PLAN',
  EDIT_FLIGHT_PLAN = 'EDIT_FLIGHT_PLAN',
  DELETE_FLIGHT_PLAN = 'DELETE_FLIGHT_PLAN',

  ACCESS_SITE = 'ACCESS_SITE',
  EDIT_SITE = 'EDIT_SITE',
  DELETE_SITE = 'DELETE_SITE',

  CREATE_NOTIFICATION = 'CREATE_NOTIFICATION',
  EDIT_NOTIFICATION = 'EDIT_NOTIFICATION',
  ACK_NOTIFICATION = 'ACK_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
  ACCESS_NOTIFICATION = 'ACCESS_NOTIFICATION',

  CREATE_REPLAY = 'CREATE_REPLAY',
  EDIT_REPLAY = 'EDIT_REPLAY',
  DELETE_REPLAY = 'DELETE_REPLAY',
  ACCESS_REPLAY = 'ACCESS_REPLAY',
  EXPORT_VIDEO = 'EXPORT_VIDEO',

  CREATE_NFZ = 'CREATE_NFZ',
  EDIT_NFZ = 'EDIT_NFZ',
  DELETE_NFZ = 'DELETE_NFZ',
  ACCESS_NFZ = 'ACCESS_NFZ',

  CREATE_IMZ = 'CREATE_IMZ',
  EDIT_IMZ = 'EDIT_IMZ',
  DELETE_IMZ = 'DELETE_IMZ',
  ACCESS_IMZ = 'ACCESS_IMZ',

  CREATE_NIZ = 'CREATE_NIZ',
  EDIT_NIZ = 'EDIT_NIZ',
  DELETE_NIZ = 'DELETE_NIZ',
  ACCESS_NIZ = 'ACCESS_NIZ',

  CREATE_NNZ = 'CREATE_NNZ',
  EDIT_NNZ = 'EDIT_NNZ',
  DELETE_NNZ = 'DELETE_NNZ',
  ACCESS_NNZ = 'ACCESS_NNZ',

  CREATE_ZOI = 'CREATE_ZOI',
  EDIT_ZOI = 'EDIT_ZOI',
  DELETE_ZOI = 'DELETE_ZOI',
  ACCESS_ZOI = 'ACCESS_ZOI',

  ACCESS_VIDEO_STREAM = 'ACCESS_VIDEO_STREAM',
  CREATE_VIDEO_STREAM = 'CREATE_VIDEO_STREAM',
  EDIT_VIDEO_STREAM = 'EDIT_VIDEO_STREAM',
  DELETE_VIDEO_STREAM = 'DELETE_VIDEO_STREAM',

  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',

  ACCESS_MERGING = 'ACCESS_MERGING',

  CREATE_STORAGE = 'CREATE_STORAGE',
  EDIT_STORAGE = 'EDIT_STORAGE',
  ACCESS_STORAGE = 'ACCESS_STORAGE',

  CREATE_POI = 'CREATE_POI',
  EDIT_POI = 'EDIT_POI',
  DELETE_POI = 'DELETE_POI',
  ACCESS_POI = 'ACCESS_POI',

  EDIT_HOME = 'EDIT_HOME',
  DELETE_HOME = 'DELETE_HOME',
  ACCESS_HOME = 'ACCESS_HOME',

  CREATE_MAP_BACKGROUND = 'CREATE_MAP_BACKGROUND',
  ACCESS_MAP_BACKGROUND = 'ACCESS_MAP_BACKGROUND',
  EDIT_MAP_BACKGROUND = 'EDIT_MAP_BACKGROUND',
  DELETE_MAP_BACKGROUND = 'DELETE_MAP_BACKGROUND',

  ACCESS_SITUATION = 'ACCESS_SITUATION',

  TAKE_CAMERA_CONTROL_STANDARD = 'TAKE_CAMERA_CONTROL_STANDARD',
  TAKE_CAMERA_CONTROL_PREMIUM = 'TAKE_CAMERA_CONTROL_PREMIUM',

  ACCESS_PLOT = 'ACCESS_PLOT',

  CREATE_FLY_ZONE = 'CREATE_FLY_ZONE',
  EDIT_FLY_ZONE = 'EDIT_FLY_ZONE',
  DELETE_FLY_ZONE = 'DELETE_FLY_ZONE',
  ACCESS_FLY_ZONE = 'ACCESS_FLY_ZONE',

  ACCESS_FATO = 'ACCESS_FATO',
  EDIT_FATO = 'EDIT_FATO',

  CREATE_FLY_ROUTE = 'CREATE_FLY_ROUTE',
  EDIT_FLY_ROUTE = 'EDIT_FLY_ROUTE',
  DELETE_FLY_ROUTE = 'DELETE_FLY_ROUTE',
  ACCESS_FLY_ROUTE = 'ACCESS_FLY_ROUTE',

  ACCESS_LAYOUT = 'ACCESS_LAYOUT',
  CREATE_LAYOUT = 'CREATE_LAYOUT',
  EDIT_LAYOUT = 'EDIT_LAYOUT',
  DELETE_LAYOUT = 'DELETE_LAYOUT',

  ACCESS_GLOBAL_SCREEN_CONFIGURATION = 'ACCESS_GLOBAL_SCREEN_CONFIGURATION',
  CREATE_GLOBAL_SCREEN_CONFIGURATION = 'CREATE_GLOBAL_SCREEN_CONFIGURATION',
  EDIT_GLOBAL_SCREEN_CONFIGURATION = 'EDIT_GLOBAL_SCREEN_CONFIGURATION',
  DELETE_GLOBAL_SCREEN_CONFIGURATION = 'DELETE_GLOBAL_SCREEN_CONFIGURATION',

  EDIT_MAP_FILTERS = 'EDIT_MAP_FILTERS',

  ACCESS_PLATFORM = 'ACCESS_PLATFORM',
  CREATE_PLATFORM = 'CREATE_PLATFORM',
  EDIT_PLATFORM = 'EDIT_PLATFORM',
  DELETE_PLATFORM = 'DELETE_PLATFORM',

  ACCESS_MSG = 'ACCESS_MSG',
  CREATE_MSG = 'CREATE_MSG',

  SENSOR_COMMAND = 'SENSOR_COMMAND',
  PERI_SENSOR_COMMAND = 'PERI_SENSOR_COMMAND',
  AUTOMATON_REPULSION_COMMAND = 'AUTOMATON_REPULSION_COMMAND',
  AUTOMATON_DETECTION_COMMAND = 'AUTOMATON_DETECTION_COMMAND',

  ADMIN = 'ADMIN',

  APP_USER = 'APP_USER',
}

export enum RoleOperationalDomainCategoryEnum {
  LAD = 'LAD',
  PERIMETRIC = 'PERIMETRIC',
  UTM = 'UTM',
  CONFIGURATION = 'CONFIGURATION',
  USERS = 'USERS',
}

export enum RoleTaskCategoryEnum {
  OPERATION = 'OPERATION',
  SUPERVISION = 'SUPERVISION',
  MAINTENANCE = 'MAINTENANCE',
  MANAGEMENT = 'MANAGEMENT',
  ADMINISTRATION = 'ADMINISTRATION',
  SUPER_ADMINISTRATION = 'SUPER_ADMINISTRATION',
  APPLICATION = 'APPLICATION', // technical role that bypasses every authorization
}

export type Role = {
  id: number;
  roleName: RoleEnum;
  operationalDomainCategory: RoleOperationalDomainCategoryEnum;
  taskCategory: RoleTaskCategoryEnum;
};

export type NormalizedRole = Role;
