import { codeExtractor, idExtractor } from '@utils/schema.utils';
import { schema } from 'normalizr';

import { GroupWithUsers, Profile } from '@/types/authent/groups.types';
import { User } from '@/types/authent/login.types';
import { Role } from '@/types/authent/roles.types';
import { Site } from '@/types/authent/sites.types';
import Entity = schema.Entity;

const roleSchema: Entity<Role> = new Entity('roles', {}, { idAttribute: idExtractor<Role> });

const profileSchema: Entity<Profile> = new Entity(
  'profiles',
  { roles: [roleSchema] },
  { idAttribute: idExtractor<Profile> },
);

const siteSchema: Entity<Site> = new Entity('sites', {}, { idAttribute: codeExtractor<Site> });

const groupSchema: Entity<GroupWithUsers> = new Entity(
  'groups',
  { profile: profileSchema },
  { idAttribute: codeExtractor<GroupWithUsers> },
);

const userSchema: Entity<User> = new Entity(
  'users',
  { activeSite: siteSchema, sites: [siteSchema] },
  { idAttribute: idExtractor<User> },
);

const authentSchema = {
  roles: [roleSchema],
  profiles: [profileSchema],
  sites: [siteSchema],
  users: [userSchema],
  groups: [groupSchema],
};

export { authentSchema, groupSchema, profileSchema, roleSchema, siteSchema, userSchema };
