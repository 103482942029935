import { AlertInfo } from '@components/cockpit/panels/platform/Platform';
import { toFlightPlanFillFeatures } from '@components/map/layers/flightPlans/flightPlan.mapper';
import { toActivePoiFeatures } from '@components/map/layers/poi/poi.mapper';
import { toStrobeAzimuthLineFeatures, toStrobeSectorFeatures } from '@components/map/layers/strobes/strobe.mapper';
import {
  toTargetCrossFeature,
  toTargetIconFeatures,
  toTargetLinkFeatures,
  toTargetTrajectoryFeatures,
} from '@components/map/layers/targets/target.mapper';
import {
  toIMZFeatures,
  toIMZMarginFeatures,
  toNFZ3dFeatures,
  toNFZFeatures,
  toNIZFeatures,
} from '@components/map/layers/zones/zone.mapper';
import { selectActiveGroupsPlatforms } from '@redux/config/config.selectors';
import { selectSelectedFeatureDateByMapId, selectSelectedTargetIdByMapId } from '@redux/maps/maps.selectors';
import { selectMapFilters } from '@redux/settings/settings.selectors';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getPlatformsCodesFromMarks, selectParameterWithReplayMode } from '@utils/common.utils';
import {
  isAlertType,
  isPerimeterEffractionAlert,
  isSegmentFailureAlert,
  isSensorFailureAlert,
  isSpaceViolationAlert,
  sortAlertByLevel,
} from '@utils/data/notification.utils';
import { sortDateDesc } from '@utils/date.utils';
import { getFilteredList } from '@utils/filter.utils';
import { isSolid3dPolygonValid } from '@utils/map/zone.utils';
import {
  getTargetClassification,
  getTargetIdentification,
  getTargetReliability,
  getTargetStatus,
  isDrone,
} from '@utils/target.utils';
import { isValidCoordinates, isValidLinearLocation, isValidPolygon } from '@utils/validation/coordinates.utils';
import { Feature, FeatureCollection } from 'geojson';
import { groupBy, uniqBy } from 'lodash';

import { TacticalSituation, Target, Track } from '@/types/c2/c2.types';
import {
  ConfigConfiguration,
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  Platform,
} from '@/types/config/config.types';
import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { Round } from '@/types/config/round.types';
import { Alert, NotificationStatus, NotificationType, PerimeterEffractionAlert } from '@/types/data/data.types';
import { filterKeyToFeatureEnum, filterKeyToFilterTypeEnum, FilterTypeEnum, MapFilters } from '@/types/filters.types';
import { MapIdEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import {
  AbstractLocation,
  LocationTypeEnum,
  PlanarLocation,
  SpatialLocation,
  StrobeLocation,
  TargetLocation,
} from '@/types/sensor/sensor.types';
import { FlightPlan, FlightPlanStatusEnum } from '@/types/utm/flightPlan.types';

import { SituationState } from './situation.reducer';

function selectSituationState(state: RootState, replayMode = false): SituationState | null {
  if (replayMode) {
    return state.replay.situation;
  }
  return state.situation;
}

export const selectSituationVersion = createSelector(
  selectSituationState,
  (state: SituationState | null): number => state?.version ?? -1,
);

export function selectSituation(state: RootState): SituationState {
  return state.situation;
}

export const selectTacticalSituation = createSelector(
  selectSituationState,
  (state: SituationState | null): TacticalSituation | null => state?.tacticalSituation ?? null,
);

export const selectSituationFlightPlans = createSelector(
  selectSituationState,
  (state: SituationState | null): FlightPlan[] => state?.flightPlans ?? [],
);

export const selectSituationTime = createSelector(
  selectSituationState,
  (situation: SituationState | null): string | null => situation?.time ?? null,
);

export const selectValidSituationFlightPlans = createSelector(
  selectSituationFlightPlans,
  (flightPlans: FlightPlan[]): FlightPlan[] =>
    flightPlans.filter((fp) => fp.polygon.every((point) => isValidCoordinates(point))),
);

export const selectFlightPlanFeatures = createSelector(
  [selectValidSituationFlightPlans, selectParameterWithReplayMode<FlightPlanStatusEnum[]>, selectMapFilters],
  (flightPlans, statuses, mapFilters): FeatureCollection => ({
    type: 'FeatureCollection',
    features: toFlightPlanFillFeatures(
      getFilteredList(
        flightPlans,
        mapFilters,
        (fp: FlightPlan, key: keyof MapFilters): boolean =>
          statuses.includes(fp.status) && filterKeyToFeatureEnum(key, FilterTypeEnum.FLIGHT_PLAN) !== fp.status,
      ),
    ),
  }),
);

export const selectConfigurations = createSelector(
  selectSituationState,
  (state: SituationState | null): ConfigConfiguration | null => state?.configuration ?? null,
);

export const selectTargets = createSelector(
  selectTacticalSituation,
  (state: TacticalSituation | null): Target<TargetLocation>[] =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    state?.targets.filter((target) => isValidCoordinates(target.lastPosition?.location?.position)) ?? [],
);

export const selectFilteredTargets = createSelector(
  [selectTargets, selectMapFilters],
  (targets: Target<TargetLocation>[], mapFilters: MapFilters): Target<TargetLocation>[] =>
    getFilteredList(targets, mapFilters, (target: Target<TargetLocation>, key: keyof MapFilters) => {
      const filterType = filterKeyToFilterTypeEnum(key);
      const feature = filterType && filterKeyToFeatureEnum(key, filterType);
      switch (filterType) {
        case FilterTypeEnum.TARGET_TYPE:
          return feature !== getTargetIdentification(target);
        case FilterTypeEnum.TARGET_CLASSIFICATION:
          return feature !== getTargetClassification(target);
        case FilterTypeEnum.TARGET_RELIABILITY:
          return feature !== getTargetReliability(target);
        case FilterTypeEnum.TARGET_STATUS:
          return feature !== getTargetStatus(target);
        default:
          return true;
      }
    }).sort(
      (targetA, targetB) =>
        Object.keys(IdentificationEnum).indexOf(targetB.identification.mainIdentification) -
        Object.keys(IdentificationEnum).indexOf(targetA.identification.mainIdentification),
    ),
);

export const selectStrobes = createSelector(
  selectTacticalSituation,
  (state: TacticalSituation | null): Target<StrobeLocation>[] =>
    state?.strobes.filter((s) => isValidLinearLocation(s.lastPosition?.location)) ?? [],
);

export const selectFilteredStrobes = createSelector(
  [selectStrobes, selectMapFilters],
  (strobes: Target<StrobeLocation>[], mapFilters: MapFilters): Target<StrobeLocation>[] =>
    getFilteredList(
      strobes,
      mapFilters,
      (strobe: Target<StrobeLocation>, key: keyof MapFilters) =>
        filterKeyToFeatureEnum(key, FilterTypeEnum.RF_DETECTION_TYPE) !== getTargetIdentification(strobe) &&
        strobe.trackIds.every((track) => track.sensorType !== filterKeyToFeatureEnum(key, FilterTypeEnum.C_UAS_SENSOR)),
    ),
);

export const selectSpatialTargets = createSelector(
  selectTargets,
  (targets: Target<TargetLocation>[]): Target<SpatialLocation>[] =>
    targets.filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (target) => target.lastPosition?.location?.type === LocationTypeEnum.SPATIAL,
    ) as Target<SpatialLocation>[],
);

export const selectDrones = createSelector(
  selectFilteredTargets,
  (targets: Target<TargetLocation>[]): Target<TargetLocation>[] => targets.filter((target) => isDrone(target)),
);

export const selectSpatialDrones = createSelector(
  selectSpatialTargets,
  (targets: Target<SpatialLocation>[]): Target<SpatialLocation>[] => targets.filter((target) => isDrone(target)),
);

export const selectGroupedTargets = createSelector(
  selectFilteredTargets,
  (targets): [Target<TargetLocation>, Target<TargetLocation>][] => {
    const targetsWithGroup = targets.filter((target) => target.targetGroup);
    return Object.values(groupBy(targetsWithGroup, 'targetGroup'))
      .filter((targets) => targets.length === 2)
      .map((targets) => [targets[0], targets[1]] as [Target<PlanarLocation>, Target<PlanarLocation>]);
  },
);

export const selectTargetIconFeatures = createSelector(
  [selectFilteredTargets],
  (targets: Target<TargetLocation>[]): Feature[] => toTargetIconFeatures(targets),
);

export const selectTargetLinkFeatures = createSelector(
  selectGroupedTargets,
  (targets: [Target<TargetLocation>, Target<TargetLocation>][]): Feature[] => toTargetLinkFeatures(targets),
);

export const selectSelectedTarget = createSelector(
  [(state, mapId: MapIdEnum) => selectTargets(state, mapId === MapIdEnum.REPLAY), selectSelectedTargetIdByMapId],
  (targets: Target<TargetLocation>[], selectedTargetId): Target<TargetLocation> | null =>
    targets.find((target) => selectedTargetId === target.id) ?? null,
);

export const selectFilteredTargetForTrajectory = createSelector(
  [
    (state, mapId: MapIdEnum) => selectFilteredTargets(state, mapId === MapIdEnum.REPLAY),
    selectMapFilters,
    selectSelectedTarget,
  ],
  (filteredTargets: Target<TargetLocation>[], mapFilters: MapFilters, selectedTarget): Target<TargetLocation>[] => {
    // TANT QUE LA CIBLE SELECTIONNEE NE PREND PAS EN COMPTE LES FILTRES
    const targets = selectedTarget
      ? uniqBy([...filteredTargets, selectedTarget], (target) => target.id)
      : filteredTargets;

    return getFilteredList(targets, mapFilters, (target: Target<TargetLocation>, key: keyof MapFilters) => {
      const filterType = filterKeyToFilterTypeEnum(key);
      const feature = filterType && filterKeyToFeatureEnum(key, filterType);
      if (filterType === FilterTypeEnum.TARGET_TRAJECTORY) {
        return (
          feature?.split('_').slice(1).join('_') !== getTargetIdentification(target) ||
          (target.id === selectedTarget?.id && mapFilters.displayTrajectoryPinnedTarget)
        );
      }
      return true;
    });
  },
);

export const selectTargetTrajectoryFeatures = createSelector(
  selectFilteredTargetForTrajectory,
  (targets: Target<TargetLocation>[]): Feature[] => toTargetTrajectoryFeatures(targets),
);

const selectAnyTargetSelected = (
  state: RootState,
  replayMode: boolean,
  selectedTarget: Target<AbstractLocation> | null,
) => ({
  targets: [...selectTargets(state, replayMode), ...selectStrobes(state, replayMode)],
  selectedTarget,
});

export const selectGroupedTargetWithSelectedTarget = createSelector(
  selectAnyTargetSelected,
  ({ targets, selectedTarget }) =>
    targets.find(
      (target) =>
        target.targetGroup && target.targetGroup === selectedTarget?.targetGroup && target.id !== selectedTarget.id,
    ) ?? null,
);

const selectAnyStrobeId = (state: RootState, replayMode: boolean, strobeId: string) => ({
  strobes: selectStrobes(state, replayMode),
  strobeId,
});

export const selectStrobeById = createSelector(
  selectAnyStrobeId,
  ({ strobes, strobeId }: { strobes: Target<StrobeLocation>[]; strobeId: string }) =>
    strobes.find((strobe) => strobe.id === strobeId),
);

export const selectSelectedDate = createSelector(selectSelectedFeatureDateByMapId, (date): Date | null => date);

export const selectTargetFeatures = createSelector(
  [
    (state, mapId: MapIdEnum) => selectTargetIconFeatures(state, mapId === MapIdEnum.REPLAY),
    (state, mapId: MapIdEnum) => selectTargetLinkFeatures(state, mapId === MapIdEnum.REPLAY),
    selectTargetTrajectoryFeatures,
  ],
  (targetIconFeatures, targetLinkFeatures, targetTrajectoryFeatures): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [...targetIconFeatures, ...targetLinkFeatures, ...targetTrajectoryFeatures],
  }),
);

export const selectSelectedTargetCrossFeature = createSelector(
  [selectSelectedTarget, selectSelectedDate],
  (selectSelectedTarget: Target<TargetLocation> | null, selectedDate: Date | null): Feature[] =>
    toTargetCrossFeature(selectSelectedTarget, selectedDate),
);

export const selectSelectedTargetFeatures = createSelector(
  [selectSelectedTarget, selectSelectedTargetCrossFeature],
  (selectedTarget, selectedTargetCrossFeature): Feature[] =>
    (selectedTarget && [...toTargetIconFeatures([selectedTarget]), ...selectedTargetCrossFeature]) ?? [],
);

export const selectStrobeSectorFeatures = createSelector(
  selectFilteredStrobes,
  (strobes: Target<StrobeLocation>[]): Feature[] => toStrobeSectorFeatures(strobes),
);

export const selectStrobeAzimuthLineFeatures = createSelector(
  selectFilteredStrobes,
  (strobes: Target<StrobeLocation>[]): Feature[] => toStrobeAzimuthLineFeatures(strobes),
);

export const selectStrobeFeatures = createSelector(
  [selectStrobeSectorFeatures, selectStrobeAzimuthLineFeatures],
  (sectors: Feature[], azimuthLines: Feature[]): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [...sectors, ...azimuthLines],
  }),
);

const selectTargetByReplayMode = (state: RootState, replayMode: boolean, targetId: string) => ({
  targets: selectTargets(state, replayMode),
  targetId: targetId,
});

export const selectTargetById = createSelector(
  selectTargetByReplayMode,
  ({ targets, targetId }): Target<TargetLocation> | undefined => targets.find((target) => target.id === targetId),
);

export const selectAllNoInitZones = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): NoInitZone[] => config?.noInitZones ?? [],
);

export const selectAllValidNoInitZone = createSelector(selectAllNoInitZones, (nizs: NoInitZone[]): NoInitZone[] =>
  nizs.filter((niz: NoInitZone) => isValidPolygon(niz.polygon)),
);

export const selectAllNoInitZoneFeatures = createSelector(selectAllValidNoInitZone, (nizs: NoInitZone[]): Feature[] =>
  toNIZFeatures(nizs),
);

export const selectAllNoFlyZones = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): NoFlyZone[] => config?.noFlyZones ?? [],
);

export const selectAllValidNoFlyZone = createSelector(selectAllNoFlyZones, (nfzs: NoFlyZone[]): NoFlyZone[] =>
  nfzs.filter((nfz: NoFlyZone) => nfz.active && isValidPolygon(nfz.polygon)),
);

export const selectAllNoFlyZoneFeatures = createSelector(selectAllValidNoFlyZone, (nfzs: NoFlyZone[]): Feature[] =>
  toNFZFeatures(nfzs),
);

export const selectAllNoFlyZones3d = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): NoFlyZone3d[] => config?.noFlyZones3d ?? [],
);

export const selectAllValidNoFlyZone3d = createSelector(selectAllNoFlyZones3d, (nfzs: NoFlyZone3d[]): NoFlyZone3d[] =>
  nfzs.filter((nfz: NoFlyZone3d) => nfz.active && isSolid3dPolygonValid(nfz.solidPolygon)),
);

export const selectAllNoFlyZone3dFeatures = createSelector(
  selectAllValidNoFlyZone3d,
  (nfzs: NoFlyZone3d[]): Feature[] => toNFZ3dFeatures(nfzs),
);

export const selectAllInitializationMaskingZones = createSelector(
  selectConfigurations,
  (config: ConfigConfiguration | null): InitializationMaskingZone[] => config?.initializationMaskingZones ?? [],
);

export const selectAllValidInitializationMaskingZone = createSelector(
  selectAllInitializationMaskingZones,
  (imzs: InitializationMaskingZone[]): InitializationMaskingZone[] =>
    imzs.filter((imz: InitializationMaskingZone) => imz.active && isValidPolygon(imz.polygon)),
);

export const selectAllInitializationMaskingZoneFeatures = createSelector(
  selectAllValidInitializationMaskingZone,
  (imzs: InitializationMaskingZone[]): Feature[] => [...toIMZFeatures(imzs), ...toIMZMarginFeatures(imzs)],
);

export const selectZonesFeatures = createSelector(
  [
    selectAllNoInitZoneFeatures,
    selectAllNoFlyZoneFeatures,
    selectAllInitializationMaskingZoneFeatures,
    selectAllNoFlyZone3dFeatures,
  ],
  (nizFeatures: Feature[], nfzFeatures: Feature[], imzFeatures: Feature[], nfz3dFeatures: Feature[]): Feature[] => [
    ...nizFeatures,
    ...nfzFeatures,
    ...imzFeatures,
    ...nfz3dFeatures,
  ],
);

export const selectZonesFilteredFeatures = createSelector(
  [selectZonesFeatures, selectMapFilters],
  (zones: Feature[], mapFilters: MapFilters): FeatureCollection => ({
    type: 'FeatureCollection',
    features: getFilteredList(
      zones,
      mapFilters,
      (zone: Feature, key: keyof MapFilters) => zone.properties?.filterTrigger !== key,
    ),
  }),
);

export const selectPointOfInterests = createSelector(
  selectConfigurations,
  (configuration) => configuration?.pointOfInterests ?? [],
);

export const selectFilteredPointOfInterests = createSelector(
  [selectPointOfInterests, selectMapFilters],
  (pois, mapFilters): PointOfInterest[] => (mapFilters.displayOtherInfoPoi ? pois : []),
);

export const selectPointOfInterestFeatures = createSelector(selectFilteredPointOfInterests, (pois) => ({
  type: 'FeatureCollection',
  features: toActivePoiFeatures(pois),
}));

export const selectOngoingAlerts = createSelector(
  selectTacticalSituation,
  (state: TacticalSituation | null): Alert[] =>
    (state?.notifications.filter((notification) => isAlertType(notification)) ?? []) as Alert[],
);

export const selectOngoingAlertsCount = createSelector(selectOngoingAlerts, (alerts: Alert[]): number => alerts.length);

export const selectAllOngoingAlerts = createSelector(selectOngoingAlerts, (alerts: Alert[]): Alert[] =>
  alerts.filter((alert) => alert.notificationStatus !== NotificationStatus.ARCHIVED),
);

export const selectOngoingAlertById = createSelector(
  [selectAllOngoingAlerts, selectParameterWithReplayMode<number | undefined>],
  (alerts: Alert[], id: number | undefined): Alert | null => alerts.find((alert) => alert.id === id) ?? null,
);

export const selectAllOngoingAlertsBySegmentName = createSelector(
  [selectOngoingAlerts, selectParameterWithReplayMode<string | null>],
  (alerts, segmentName) =>
    alerts
      .filter(
        (alert) =>
          alert.notificationStatus !== NotificationStatus.ARCHIVED &&
          alert.type === NotificationType.PERIMETER_EFFRACTION_ALERT,
      )
      .map((a) => a as PerimeterEffractionAlert)
      .toSorted((a, b) => sortDateDesc(a.startTime, b.startTime))
      .find((al) => al.segmentName === segmentName),
);

export const selectAllActiveNoAckAlerts = createSelector(selectAllOngoingAlerts, (alerts: Alert[]): Alert[] =>
  alerts.filter((alert) => !alert.ack),
);

//There's a circular dependancy between situation.selectors and config.selectors
//To avoid an error we use an anonymous function to call selectActiveGroupsPlatforms
//Solution found here : https://github.com/reduxjs/reselect/issues/169#issuecomment-675021575
export const selectAllOngoingAlertsPerTypeByPlatforms = createSelector(
  [selectAllOngoingAlerts, (state) => selectActiveGroupsPlatforms(state)],
  (
    alerts: Alert[],
    platforms: Platform[],
  ): {
    platform: Platform;
    LAD: AlertInfo;
    PERIM: AlertInfo;
    TECH: AlertInfo;
  }[] => {
    const platformWithAlerts = platforms.map((platform) => ({
      platform,
      alerts: alerts.filter((alert) => getPlatformsCodesFromMarks(alert.marks).includes(platform.code)),
    }));

    return platformWithAlerts.map(({ platform, alerts }) => {
      const ladAlerts = alerts
        .filter(isSpaceViolationAlert)
        .sort((alert1, alert2) => sortAlertByLevel(alert1.level, alert2.level));
      const perimAlerts = alerts
        .filter(isPerimeterEffractionAlert)
        .sort((alert1, alert2) => sortAlertByLevel(alert1.level, alert2.level));
      const techAlerts = alerts
        .filter((alert) => isSegmentFailureAlert(alert) || isSensorFailureAlert(alert))
        .sort((alert1, alert2) => sortAlertByLevel(alert1.level, alert2.level));

      return {
        platform,
        LAD: { count: ladAlerts.length, maxLevel: ladAlerts.length === 0 ? null : ladAlerts[0].level },
        PERIM: { count: perimAlerts.length, maxLevel: perimAlerts.length === 0 ? null : perimAlerts[0].level },
        TECH: { count: techAlerts.length, maxLevel: techAlerts.length === 0 ? null : techAlerts[0].level },
      };
    });
  },
);

export const selectHighestAlertType = createSelector(
  selectAllActiveNoAckAlerts,
  (alerts: Alert[]): NotificationType | null => {
    if (alerts.some((alert) => isSpaceViolationAlert(alert))) {
      return NotificationType.SPACE_VIOLATION_ALERT;
    }
    if (alerts.some((alert) => isPerimeterEffractionAlert(alert))) {
      return NotificationType.PERIMETER_EFFRACTION_ALERT;
    }
    if (alerts.some((alert) => isSensorFailureAlert(alert))) {
      return NotificationType.SENSOR_FAILURE_ALERT;
    }
    if (alerts.some((alert) => isSegmentFailureAlert(alert))) {
      return NotificationType.AUTOMATON_SEGMENT_FAILURE_ALERT;
    }
    return null;
  },
);

export const selectRounds = createSelector(
  selectSituationState,
  (state: SituationState | null): Round[] => state?.rounds ?? [],
);

export const selectTrackFromRawSituationById = createSelector(
  (state: RootState, replayMode: boolean, trackId: string) => ({
    situation: selectSituationState(state, replayMode),
    trackId,
  }),
  ({ situation, trackId }) =>
    situation?.rawSituation?.tracks.find((track: Track<AbstractLocation>) => track.id === trackId) ?? null,
);

export const selectTrackFromRawSituationByTrackLights = createSelector(
  (state: RootState, replayMode: boolean, trackIds: string[]) => ({
    situation: selectSituationState(state, replayMode),
    trackIds,
  }),
  ({ situation, trackIds }) =>
    situation?.rawSituation?.tracks.filter((track: Track<AbstractLocation>) => trackIds.includes(track.uniqueId)) ?? [],
);
