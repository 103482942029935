import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { createErrorMessage, isStatusPayload } from '@utils/common.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { v4 } from 'uuid';

import { RoutesEnum } from '@/types/routes.types';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  const currentRoute = sessionStorage.getItem('route');

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (
    isRejectedWithValue(action) &&
    isStatusPayload(action.payload) &&
    !!currentRoute &&
    currentRoute !== RoutesEnum.LOGIN
  ) {
    createToast(createErrorMessage(action.payload), ToastStatusEnum.ERROR, v4());
  }
  return next(action);
};
