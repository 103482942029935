import { Center, HStack, Text, Tooltip, useToast } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import useCurrentDateTime from '@hooks/useCurrentDateTime';
import { selectSituationTime, selectSituationVersion } from '@redux/situation/situation.selectors';
import { timeDuration } from '@utils/date.utils';
import { errorToastConfig } from '@utils/toast.utils';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export default function CockpitTime() {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const currentSituationTime = useAppSelector(selectSituationTime);
  const situationVersion = useAppSelector(selectSituationVersion);
  const [previousSituationTime, setPreviousSituationTime] = useState<string | null>(currentSituationTime);
  const [previousSituationVersion, setPreviousSituationVersion] = useState<number>(situationVersion);
  const [isBroken, setIsBroken] = useState<boolean>(currentSituationTime == null);
  const localeDate = useCurrentDateTime();

  //If there's more than 5s between the two last situation, system is considered "broken"
  if (previousSituationTime && currentSituationTime && previousSituationTime !== currentSituationTime) {
    if (previousSituationVersion !== -1) {
      if (timeDuration(previousSituationTime, currentSituationTime) > 5000) {
        if (!isBroken) {
          setIsBroken(true);
          if (!toast.isActive('noServerResponse')) {
            toast({
              ...errorToastConfig,
              title: formatMessage({ id: 'components.cockpit.noServerResponse' }),
              id: 'noServerResponse',
            });
          }
        }
      } else if (isBroken) {
        setIsBroken(false);
      }
    }
    setPreviousSituationTime(currentSituationTime);
    setPreviousSituationVersion(situationVersion);
  }

  //If we did not receive a situation for more than 5s, system is considered "broken"
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsBroken(true);
      if (!toast.isActive('noServerResponse')) {
        toast({
          ...errorToastConfig,
          title: formatMessage({ id: 'components.cockpit.noServerResponse' }),
          id: 'noServerResponse',
        });
      }
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentSituationTime, formatMessage, toast]);

  const date = isBroken || !currentSituationTime ? new Date(localeDate) : new Date(currentSituationTime);

  return (
    <Tooltip
      maxWidth="400px"
      isDisabled={!isBroken}
      placement="top-start"
      label={formatMessage({ id: 'components.cockpit.degraded' })}
    >
      <Center width="100%" height="56px" gap={0} flexShrink={0}>
        <HStack
          gap={4}
          justifyContent="center"
          height="100%"
          width="100%"
          border="2px solid"
          backgroundColor={isBroken ? 'alertHi.800' : 'transparent'}
          borderColor={isBroken ? 'alertHi.500' : 'transparent'}
        >
          <HStack gap={0}>
            <Text size="md" color={isBroken ? 'neutral.white' : 'neutral.200'}>
              {format(date, 'HH:mm')}
            </Text>
            <Text fontSize="16px" color={isBroken ? 'neutral.white' : 'neutral.300'}>
              {format(date, ':ss')}
            </Text>
          </HStack>
          <Text fontSize="16px" color={isBroken ? 'neutral.white' : 'neutral.300'}>
            {format(date, 'dd/MM/yyyy')}
          </Text>
        </HStack>
      </Center>
    </Tooltip>
  );
}
