import { ReactComponent as InfoIcon } from '@assets/icons/16x16/ic-info-16.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack, Input, InputGroup, InputRightElement, TabPanel, Text, VStack } from '@chakra-ui/react';
import CustomInputDateTimeRange from '@components/common/inputs/CustomInputDateTimeRange';
import CustomInputNumber from '@components/common/inputs/CustomInputNumber';
import DropSelect from '@components/common/menu/DropSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { useGetNotificationLazyQuery } from '@services/data/notification.api';
import { getClassificationIcon } from '@utils/target.utils';
import { debounce } from 'lodash';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Platform } from '@/types/config/config.types';
import { Notification, NotificationStatus, NotificationType } from '@/types/data/data.types';
import { RangeProps } from '@/types/replay/replay.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';

import Notifications from './Notifications';

type LadSearchParameters = {
  identifier: string;
  classifications: Set<Omit<ClassificationEnum, ClassificationEnum.UNCLASSIFIED>>;
  range: RangeProps;
  duration?: number;
};

type Props = {
  platforms: Platform[];
};

//Not useful for now until platforms are added to notification criteria
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LadSearch({ platforms }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const activeSite = useAppSelector(selectActiveSite);
  const [triggerCallGetNotifications] = useGetNotificationLazyQuery();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const classifications: Set<Omit<ClassificationEnum, ClassificationEnum.UNCLASSIFIED>> = new Set(
    Object.values(ClassificationEnum).filter((classification) => classification !== ClassificationEnum.UNCLASSIFIED),
  );

  const defaultSearchState = {
    identifier: '',
    classifications,
    range: {
      from: new Date(Date.now() - 24 * 60 * 60 * 1000),
      to: new Date(),
    },
    duration: 0,
  };

  const [state, setState] = useReducer(
    (state: LadSearchParameters, newState: Partial<LadSearchParameters>) => ({
      ...state,
      ...newState,
    }),
    { ...defaultSearchState },
  );

  const debouncedTriggerCallGetNotifications = useRef(
    debounce((params) => {
      triggerCallGetNotifications(params).then((result) => setNotifications(result.data ?? []));
    }, 300),
  ).current;

  const criteria = useMemo(
    () => ({
      site: activeSite!.code,
      from: state.range.from.toISOString(),
      to: state.range.to.toISOString(),
      notificationStatus: [NotificationStatus.ARCHIVED, NotificationStatus.ENDED],
      types: [
        //NotificationType.COLLISION_ALERT
        NotificationType.DRONE_IDENTIFICATION,
        NotificationType.SPACE_VIOLATION_ALERT,
      ],
      size: 50,
      page: 0,
      //identifier: state.identifier,
      //platforms,
      //classifications: state.classifications,
      //duration: state.duration,
    }),
    [activeSite, state.range.from, state.range.to],
  );

  useEffect(() => {
    debouncedTriggerCallGetNotifications(criteria);
    return () => {
      debouncedTriggerCallGetNotifications.cancel();
    };
  }, [criteria, debouncedTriggerCallGetNotifications]);

  return (
    <TabPanel height="100%">
      <VStack gap={0.25} backgroundColor="neutral.900" width="100%" height="100%" alignItems="start">
        <HStack gap={3} padding={3} backgroundColor="neutral.800" width="100%">
          <VStack gap={1.5} alignItems="start" flex="1">
            <HStack gap={2}>
              <Text size="md" color="neutral.300">
                <FormattedMessage id="replay.search.ladSearch.identifier" />
              </Text>
              <Icon as={InfoIcon} width="16px" height="16px" color="neutral.500" />
            </HStack>
            <Input
              height="48px"
              value={state.identifier}
              onChange={(event) => setState({ identifier: event.target.value })}
            />
          </VStack>
          <VStack gap={1.5} alignItems="start" flex="1">
            <HStack gap={2}>
              <Text size="md" color="neutral.300">
                <FormattedMessage id="replay.search.ladSearch.classification" />
              </Text>
              <Icon as={InfoIcon} width="16px" height="16px" color="neutral.500" />
            </HStack>
            <DropSelect
              label="replay.search.ladSearch.classificationLabel"
              values={state.classifications}
              options={[...classifications].map((value) => ({
                value,
                label: formatMessage({ id: `target.classification.${value}` }),
              }))}
              getOptionIcon={(classification) => getClassificationIcon(classification)}
              onChange={(classifications) => setState({ classifications })}
            />
          </VStack>
        </HStack>
        <VStack gap={3} padding={3} backgroundColor="neutral.800" width="100%" alignItems="start">
          <CustomInputDateTimeRange
            showSecond={false}
            variant="filled"
            range={state.range}
            onChange={(range) => setState({ range })}
          />
          <HStack gap={2}>
            <Text size="md" color="neutral.300">
              <FormattedMessage id="replay.search.ladSearch.detectionDuration" />
            </Text>
            <InputGroup width="88px" height="48px">
              <CustomInputNumber
                width="88px"
                height="48px"
                textAlign="left"
                paddingLeft={1.5}
                paddingRight="49px"
                value={state.duration}
                onChange={(duration) => setState({ duration })}
              />
              <InputRightElement padding={1.5} paddingLeft={1}>
                <Text fontSize="16px" color="neutral.300" height="16px">
                  <FormattedMessage id="replay.search.ladSearch.min" />
                </Text>
              </InputRightElement>
            </InputGroup>
          </HStack>
        </VStack>
        <Notifications notifications={notifications} criteria={criteria} />
      </VStack>
    </TabPanel>
  );
}
