import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { Message } from '@/types/chat/chat.types';

import { messagesAdapter } from './chat.adapters';

export type ChatState = {
  messages: EntityState<Message, number>;
  newMessageEntry: boolean;
};

export const CHAT_INITIAL_STATE: ChatState = {
  messages: messagesAdapter.getInitialState(),
  newMessageEntry: false,
};

export const chat = createSlice({
  name: 'chat',
  initialState: CHAT_INITIAL_STATE,
  reducers: {
    addMessage({ messages }, action: PayloadAction<Message>) {
      messagesAdapter.addOne(messages, action.payload);
    },
    addMessages({ messages }, action: PayloadAction<Message[]>) {
      messagesAdapter.addMany(messages, action.payload);
    },
    updateNewMessageEntry(state, action: PayloadAction<boolean>) {
      state.newMessageEntry = action.payload;
    },
  },
});

export const { addMessage, addMessages, updateNewMessageEntry } = chat.actions;

export default chat.reducer;
