import { ReactComponent as CaptureIcon } from '@assets/icons/32x32/ic-capture-32.svg';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import { useAppSelector } from '@hooks/redux.hooks';
import { PerimeterCameraSelectors } from '@redux/sensors/sensors.selectors';
import { isEqual } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

type OwnProps = {
  selectedPerimeterCameraCode: string | null;
  downloadScreenshot: (cameraName?: string) => void;
};

export default function CameraPerimButtons({ downloadScreenshot, selectedPerimeterCameraCode }: Readonly<OwnProps>) {
  const { formatMessage } = useIntl();

  const perimeterCameraConfig: PerimeterCamera | undefined = useAppSelector(
    (state) => PerimeterCameraSelectors.selectPerimeterCameraByUniqueCode(state, selectedPerimeterCameraCode ?? ''),
    isEqual,
  );
  const perimeterCameraName = perimeterCameraConfig?.name;

  return (
    <ButtonBar
      label={formatMessage({ id: 'cameras.cameraMenu.capture' })}
      icon={CaptureIcon}
      size="sm"
      onClick={() => downloadScreenshot(perimeterCameraName)}
    />
  );
}
