import { formatDate } from '@utils/date.utils';
import { createErrorToastFromTradId } from '@utils/toast.utils';
import { toPng } from 'html-to-image';

import { DateTimeEnum } from '@/types/dateTime.types';

function getVideoElementFromPlayerId(playerId: string): HTMLVideoElement {
  const video = document.getElementById(playerId)?.children[0]?.children[0];
  if (!(video instanceof HTMLVideoElement)) {
    createErrorToastFromTradId('error.screenshotError');
    throw new Error(`Grand child of element with playerId ${playerId} is not an HTMLVideoElement `);
  }
  return video;
}

function takeScreenshotFromPlayer(video: HTMLVideoElement, mimeType: string): string {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  if (!context) {
    throw new Error('Could not get a 2d context from canvas');
  }

  context.drawImage(video, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL(mimeType);
}

function downloadImage(base64Image: string, fileName: string) {
  const downloadAnchor = document.createElement('a');
  downloadAnchor.href = base64Image;
  downloadAnchor.download = fileName;
  downloadAnchor.click();
}

function downloadScreenshot(screenshotImg: string, name: string, situationDate: string) {
  const date = formatDate(situationDate, DateTimeEnum.DATE_TIME_SECONDS);
  const fileName = `capture_${name}_${date}.png`;
  downloadImage(screenshotImg, fileName);
}

/**
 * @param playerId The DOM element id of the player
 * @param situationDate Date and time of the situation to put on the file name
 * @param cameraName The name of the captured camera to put on the file name
 */
export function takeAndDownloadScreenshotFromPlayer(
  playerId: string,
  situationDate: string | null,
  cameraName?: string,
) {
  const videoPlayerElement = getVideoElementFromPlayerId(playerId);
  const screenshotImg = takeScreenshotFromPlayer(videoPlayerElement, 'image/png');
  downloadScreenshot(screenshotImg, cameraName ?? 'unknownCameraName', situationDate ?? 'unknownDate');
}

async function screenshotAllScreen(): Promise<string> {
  try {
    return document.fonts.ready.then(() => toPng(document.body));
  } catch (error) {
    createErrorToastFromTradId('error.screenshotError');
    throw error;
  }
}

/**
 * Screenshots the entire browser tab which has the clicked button
 * @param name Custom name of the captured feature to put on the file name (e.g. "LAD_map")
 * @param dateTime Date and time to put on the file name
 */
export async function takeAndDownloadScreenshotFromEntireScreen(name: string, dateTime: string) {
  const screenshotImg = await screenshotAllScreen();
  downloadScreenshot(screenshotImg, name, dateTime);
}
