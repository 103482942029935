import { ReactComponent as FocusIcon } from '@assets/icons/40x40/mapMenu/ic-focus-40.svg';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectGlobalScreenConfigurationCode } from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { centerMapByMapId } from '@redux/maps/maps.reducer';
import { memo } from 'react';
import { useIntl } from 'react-intl';

function CenterMapControl() {
  const { mapId } = useMapContext();
  const { formatMessage } = useIntl();

  const activeGlobalScreenConfigCode = useAppSelector(selectGlobalScreenConfigurationCode);
  const activeGlobalScreenConfig = useAppSelector((state) =>
    GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(state, activeGlobalScreenConfigCode),
  );
  const setCenter = useWithDispatch(centerMapByMapId);

  return (
    <ButtonBar
      label={formatMessage({ id: 'controls.toolMenu.center' })}
      icon={FocusIcon}
      orientation="bottom-left"
      tooltipPlacement="left"
      isDisabled={!activeGlobalScreenConfig}
      onClick={() =>
        activeGlobalScreenConfig &&
        setCenter({
          mapId: mapId,
          position: activeGlobalScreenConfig.position,
        })
      }
    />
  );
}

export default memo(CenterMapControl);
