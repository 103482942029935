import { UserLight } from '../authent/login.types';

type BaseLog = {
  id: number;
  time: string;
  site: string;
  code: string;
  parameters: string[];
};

export interface InstructionLog extends BaseLog {
  type: LogTypeEnum.INSTRUCTION;
  instruction: string;
  author: UserLight | null;
}

export interface EventLog extends BaseLog {
  type: LogTypeEnum.EVENT;
  author: UserLight | null;
  name: string;
}

export interface SystemLog extends BaseLog {
  type: LogTypeEnum.SYSTEM;
  action: SystemActionEnum;
}

export interface UserActionLog extends BaseLog {
  type: LogTypeEnum.USER_ACTION;
  action: UserActionEnum;
  author: UserLight | null;
}

export type HoloLog = InstructionLog | EventLog | SystemLog | UserActionLog;

export enum UserActionEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  ACKNOWLEDGE_NOTIFICATION = 'ACKNOWLEDGE_NOTIFICATION',
  CONFIRM_NOTIFICATION = 'CONFIRM_NOTIFICATION',
  ARCHIVE_NOTIFICATION = 'ARCHIVE_NOTIFICATION',
  CLEARANCE_CHANGE = 'CLEARANCE_CHANGE',
}

export enum SystemActionEnum {
  NEW_ALERT = 'NEW_ALERT',
}

export type LogAction = UserActionEnum | SystemActionEnum;

export enum LogTypeEnum {
  EVENT = 'EVENT',
  INSTRUCTION = 'INSTRUCTION',
  USER_ACTION = 'USER_ACTION',
  SYSTEM = 'SYSTEM',
}
