import { ReactComponent as HexagonBackground } from '@assets/components/button/cockpit-sidebar-button-hexagon.svg';
import { Icon } from '@chakra-ui/icon';
import { Center, HStack } from '@chakra-ui/layout';
import { Text, Tooltip } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

import Badge from './Badge';

type Props = {
  isActive: boolean;
  isMaximised: boolean;
  label: string;
  badge?: number | boolean;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  children?: ReactNode;
  onClick: () => void;
};

export default function CockpitSidebarButton({
  isActive,
  isMaximised,
  label,
  badge = false,
  icon,
  children,
  onClick,
}: Readonly<Props>) {
  const color = isActive ? 'sky.500' : 'neutral.300';
  return (
    <HStack
      backgroundColor="transparent"
      width={isMaximised ? '290px' : '84px'}
      height="72px"
      userSelect="none"
      position="relative"
      _hover={{ cursor: 'pointer' }}
      gap={2}
      role="group"
      onClick={onClick}
    >
      <Tooltip label={label} isDisabled={isMaximised} placement="right" openDelay={300}>
        <Center width="84px" height="72px" position="relative">
          {isActive && <Icon as={HexagonBackground} width="100%" height="100%" color="cyber.600" />}
          <Icon
            as={icon}
            width="40px"
            height="40px"
            color={color}
            position="absolute"
            _groupHover={{ color: 'sky.500' }}
          />
          {badge !== false && <Badge count={typeof badge === 'number' ? badge : undefined} />}
        </Center>
      </Tooltip>
      {isMaximised && (
        <Text
          fontSize="16px"
          fontWeight={isActive ? 'medium' : 'regular'}
          color={color}
          noOfLines={2}
          width="191px"
          _groupHover={{ color: 'sky.500' }}
        >
          {label}
        </Text>
      )}
      {children}
    </HStack>
  );
}
