import replayMiddleware from '@redux/middlewares/replay.middleware';
import { rtkQueryErrorLogger } from '@redux/middlewares/rtkQueryErrorHandler.middleware';
import { configureStore, Middleware, UnknownAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authentApi } from '@services/authent/authent.api';
import { loginApi } from '@services/authent/login.api';
import { c2Api } from '@services/c2/c2.api';
import { sseApi } from '@services/c2/sse.api';
import { messagesApi } from '@services/chat/messages.api';
import { configApi } from '@services/config/config.api';
import { dataApi } from '@services/data/data.api';
import { replayApi } from '@services/replay/replay.api';
import { utmApi } from '@services/utm/utm.api';
import { persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { Config, createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

import { chatListenerMiddleware } from './middlewares/chat.middleware';
import { logbookListenerMiddleware } from './middlewares/logbook.middleware';
import { situationMiddleware } from './middlewares/situation.middleware';
import rootReducer from './rootReducer';

//List of action that won't be synchronized
const blacklist = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  '@@router/CALL_HISTORY_METHOD',
  '@@router/LOCATION_CHANGE',
  'global/updateLogs',
  'global/addLog',
  'global/deleteLog',
  'global/updateLog',
];

function containEndpointName(action: UnknownAction, endpoints: string[]): boolean {
  const meta = action.meta;
  if (
    meta &&
    typeof meta === 'object' &&
    'arg' in meta &&
    meta.arg &&
    typeof meta.arg === 'object' &&
    'endpointName' in meta.arg &&
    typeof meta.arg.endpointName === 'string'
  ) {
    return endpoints.includes(meta.arg.endpointName);
  }
  return false;
}

const config: Config = {
  //Prevent blacklisted action synchronization between browser tab
  //Also prevent all rejected api action to avoid creating toast in unwanted browser tab (might cause some issue)
  //Also blacklist all of our sse actions
  predicate: (action: UnknownAction) => {
    return (
      !blacklist.includes(action.type) &&
      !action.type.includes('/Rejected') &&
      (!action.type.includes('maps/') || action.type === 'maps/centerMapByMapId') &&
      !containEndpointName(action, [
        'getMonitoringStream',
        'getSituation',
        'getModelsStream',
        'getSituationDiff',
        'getReplayStream',
        'updateStream',
      ])
    );
  },
  broadcastChannelOption: { type: 'localstorage' },
};

const middlewares: Middleware[] = [
  createStateSyncMiddleware(config) as Middleware,
  authentApi.middleware,
  loginApi.middleware,
  c2Api.middleware,
  sseApi.middleware,
  utmApi.middleware,
  configApi.middleware,
  replayApi.middleware,
  dataApi.middleware,
  messagesApi.middleware,
  replayMiddleware.middleware,
  rtkQueryErrorLogger,
];

// const logger = createLogger({
//   collapsed: (getState, action, logEntry) => !logEntry?.error,
// });

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger);
// }

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //Our store is too big for those middlewares to run in a short time
      //which leads to a lot of warning during development so we disable them
      serializableCheck: false, // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      immutableCheck: false,
    })
      .concat(middlewares)
      .prepend([
        chatListenerMiddleware.middleware,
        logbookListenerMiddleware.middleware,
        situationMiddleware.middleware,
      ]),
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type StoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

initStateWithPrevTab(store);

export const persistor = persistStore(store);
