import { ReactComponent as RadioIcon } from '@assets/components/button/radio-button.svg';
import { ReactComponent as RadioCheckedIcon } from '@assets/components/button/radio-button-checked.svg';
import { HStack, Icon, Text } from '@chakra-ui/react';

type Props = {
  label: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onClick: () => void;
};

export default function CustomRadioToggle({ label, isChecked, isDisabled = false, onClick }: Readonly<Props>) {
  const borderColor = isChecked ? 'cyber.500' : 'neutral.600';
  return (
    <HStack
      padding={2}
      gap={2}
      border="2px solid"
      userSelect="none"
      width="100%"
      boxSizing="border-box"
      _hover={{
        borderColor: isDisabled || isChecked ? borderColor : 'sky.500',
        cursor: isChecked || isDisabled ? 'default' : 'pointer',
      }}
      borderColor={borderColor}
      backgroundColor={isChecked ? 'cyber.700' : 'transparent'}
      onClick={() => !isChecked && !isDisabled && onClick()}
    >
      <Icon as={isChecked ? RadioCheckedIcon : RadioIcon} width="32px" height="32px" />
      <Text variant="space" fontSize="16px">
        {label}
      </Text>
    </HStack>
  );
}
