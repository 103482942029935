import { Box, Center, HStack, TabPanel, Text, VStack } from '@chakra-ui/react';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import { FormattedMessage } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import {
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import { SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import DetailMaintenance from './DetailMaintenance';

type Props = {
  parentConfiguration: SensorConfiguration | null;
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorUniqueCodes: SensorUniqueCodes;
  type: SensorTypeEnum | SubSensorTypeEnum;
};

export default function GlobalTab({
  parentConfiguration,
  sensorConfiguration,
  sensorStatus,
  sensorUniqueCodes,
  type,
}: Readonly<Props>) {
  return (
    <TabPanel height="100%" width="100%" backgroundColor="neutral.900">
      <CockpitCustomScrollbar viewWidth="100%" viewHeight="100%" marginTop={1} marginRight={1} marginBottom={1}>
        <HStack width="100%" height="100%" gap={0.25}>
          <VStack width="100%" height="100%" gap={0.25}>
            <HStack padding={3} paddingRight="22px" backgroundColor="neutral.850" gap={0.25} width="100%">
              <Center
                backgroundColor="neutral.100"
                width="708px"
                height="276px"
                border="1px solid"
                borderColor="neutral.200"
              >
                <Text paddingX={0.5} backgroundColor="neutral.500">
                  <FormattedMessage id="components.monitoring.sensorDetail.global.maintenance.synoptic" />
                </Text>
              </Center>
              <Center
                backgroundColor="neutral.100"
                width="341px"
                height="276px"
                border="1px solid"
                borderColor="neutral.200"
              >
                <Text paddingX={0.5} backgroundColor="neutral.500">
                  <FormattedMessage id="components.monitoring.sensorDetail.global.maintenance.visual" />
                </Text>
              </Center>
            </HStack>
            <HStack height="100%" backgroundColor="neutral.900" gap={0.25} width="100%">
              <Box width="365px" height="100%" backgroundColor="neutral.800"></Box>
              <Box width="365px" height="100%" backgroundColor="neutral.800"></Box>
              <Box width="363px" height="100%" backgroundColor="neutral.800"></Box>
            </HStack>
          </VStack>
          <DetailMaintenance
            parentConfiguration={parentConfiguration}
            sensorConfiguration={sensorConfiguration}
            sensorStatus={sensorStatus}
            sensorUniqueCodes={sensorUniqueCodes}
            type={type}
          />
        </HStack>
      </CockpitCustomScrollbar>
    </TabPanel>
  );
}
