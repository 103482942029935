import { AbsoluteCenter, Flex } from '@chakra-ui/layout';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { GridItem as GridItemType, GridItemComponentEnum } from '@/types/config/gridLayout.types';
import { MapIdEnum } from '@/types/map.types';

interface OwnProps {
  rowSpan: number;
  colSpan: number;
  minW: number | null;
  minH: number | null;
  innerGridWidth: number | null;
  innerGridHeight: number | null;
  isResizable?: boolean;
  component: GridItemComponentEnum;
  onChangeDragging: (value: Partial<GridItemType>) => void;
}

export default function GridComponent({
  component,
  colSpan,
  rowSpan,
  minW,
  minH,
  isResizable,
  onChangeDragging,
}: Readonly<OwnProps>) {
  function generateGridItem(): Partial<GridItemType> {
    switch (component) {
      case GridItemComponentEnum.CAMERA_CONTROL:
        return {
          i: `${component}-${uuidv4()}`,
          component,
          selectedCamera: null,
        };
      case GridItemComponentEnum.DYNAMIC_CAMERA_CONTROL:
        return {
          i: `${component}-${uuidv4()}`,
          component,
          selectedCamera: null,
        };
      case GridItemComponentEnum.LAD_STREAM_GRID:
        return {
          i: `${component}-${uuidv4()}`,
          component,
          innerGridWidth: 1,
          innerGridHeight: 1,
          selectedStreams: [],
        };
      case GridItemComponentEnum.PERIMETER_STREAM_GRID:
        return {
          i: `${component}-${uuidv4()}`,
          component,
          innerGridWidth: 1,
          innerGridHeight: 1,
        };
      case GridItemComponentEnum.MAP:
        return {
          i: `${component}-${uuidv4()}`,
          component,
          selectedMap: MapIdEnum.LIVE,
        };
      default:
        return {
          i: `${component}-${uuidv4()}`,
          component,
        };
    }
  }

  return (
    <Flex
      unselectable="on"
      draggable
      position="relative"
      justifyContent="center"
      height="100%"
      aspectRatio={1}
      backgroundColor="neutral.300"
      onMouseDown={() =>
        onChangeDragging({
          ...generateGridItem(),
          w: colSpan,
          h: rowSpan,
          minW: minW ?? undefined,
          minH: minH ?? undefined,
          isResizable: isResizable,
        })
      }
    >
      <AbsoluteCenter textAlign="center">
        <FormattedMessage id={`grid.components.${component}`} />
      </AbsoluteCenter>
    </Flex>
  );
}
