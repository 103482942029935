import { updateReplaySituation, updateStatus } from '@redux/replay/replay.reducer';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';
import { C2_URL } from '@services/c2/c2.api';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { ActiveSensorCriteria, ReplayStreamCriteria } from '@/types/replay/replay.types';
import { CameraLiteConfiguration } from '@/types/sensor/configuration.types';

export const REPLAY_URL = import.meta.env.VITE_REPLAY_URL;

export const replayApi = createApi({
  reducerPath: 'replayApi',
  baseQuery: baseQueryWithReAuth,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getReplayStream: builder.query({
      queryFn: async () => ({
        data: null,
      }),
      onCacheEntryAdded: async ({ site, token, time }, { dispatch, cacheDataLoaded, cacheEntryRemoved }) => {
        const eventSource = new EventSourcePolyfill(`${REPLAY_URL}/situation/stream?site=${site}&time=${time}`, {
          headers: {
            Authorization: token,
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            'Content-Type': 'text/event-stream',
          },
        });
        const abortController = new AbortController();

        try {
          await cacheDataLoaded;
          eventSource.addEventListener('message', (ev: { data: string; lastEventId: string }) => {
            if (ev.lastEventId !== 'HEARTBEAT') {
              const parsedData = JSON.parse(ev.data);
              if (parsedData.type === 'STATUS') {
                dispatch(updateStatus(parsedData.data));
              } else {
                dispatch(updateReplaySituation(JSON.parse(parsedData.data)));
              }
            }
          });
          eventSource.addEventListener('error', (e) => {
            console.error('Error: ', e);
          });
          window.addEventListener(
            'beforeunload',
            () => {
              eventSource.close();
            },
            { signal: abortController.signal },
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`
        }
        await cacheEntryRemoved;
        abortController.abort();
        eventSource.close();
      },
    }),
    updateStream: builder.mutation<void, ReplayStreamCriteria>({
      query: ({ uuid, command }) => ({
        url: `${REPLAY_URL}/situation/stream/${uuid}`,
        method: 'PUT',
        body: command,
      }),
    }),
    getAvailableSituationRanges: builder.query<
      { from: string; to: string }[],
      { site: string; from: string; to: string }
    >({
      query: ({ site, from, to }) => ({
        url: `${REPLAY_URL}/situation/available-situation-ranges`,
        method: 'GET',
        params: {
          site,
          from,
          to,
        },
      }),
    }),
    getAvailableCameras: builder.query<CameraLiteConfiguration[], ActiveSensorCriteria>({
      query: ({ site, from, to }) => ({
        url: `${C2_URL}/sensor/${site}/replay/available-cameras`,
        params: {
          from: from,
          to: to,
        },
      }),
    }),
  }),
});

export const {
  useGetReplayStreamQuery,
  useUpdateStreamMutation,
  useGetAvailableSituationRangesQuery,
  useGetAvailableCamerasQuery,
} = replayApi;
