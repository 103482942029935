import { Abstract, Coordinates } from '@/types/commons/commons.types';

export enum ContextEnum {
  LIVE = 'LIVE',
  DOUBT_LAD = 'DOUBT_LAD',
  DOUBT_PERIM = 'DOUBT_PERIM',
  REPLAY = 'REPLAY',
}

export interface GlobalScreenConfiguration extends Abstract {
  id: number;
  code: string;
  name: string;
  site: string;
  screenConfigurations: ScreenConfiguration[];
  position: Coordinates;
}

export interface ScreenConfiguration {
  code: string;
  context: ContextEnum;
  gridLayoutCode: string | null;
}
