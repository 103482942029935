import { ReactComponent as InfoIcon } from '@assets/icons/16x16/ic-info-filled-16.svg';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  label: string;
  value: string | number;
  infoLabel?: string;
  showTooltip?: boolean;
};

function InfoValue({ label, value, showTooltip = false, infoLabel }: Readonly<Props>) {
  return (
    <VStack width="100%" gap={1} alignItems="start" cursor="default">
      <HStack gap={1} justifyContent="center">
        <Text size="md" color="neutral.300">
          {label}
        </Text>
        {infoLabel && (
          <Tooltip label={infoLabel} placement="top">
            <Center>
              <Icon as={InfoIcon} color="neutral.300" />
            </Center>
          </Tooltip>
        )}
      </HStack>
      <Tooltip isDisabled={!showTooltip} label={value}>
        <Text size="lg" noOfLines={2} wordBreak="break-all">
          {value}
        </Text>
      </Tooltip>
    </VStack>
  );
}

export default memo(InfoValue);
