import {
  addGroup,
  addProfile,
  addSite,
  addUser,
  deleteGroup,
  deleteProfile,
  deleteSite,
  deleteUser,
  updateAuthentSettings,
  updateGroup,
  updateProfile,
  updateSite,
  updateUser,
} from '@redux/authent/authent.reducer';
import { addMessage } from '@redux/chat/chat.reducer';
import {
  addGlobalScreenConfiguration,
  addGridConfiguration,
  addGridLayout,
  addInitializationMaskingZone,
  addNoFlyZone,
  addNoFlyZone3d,
  addNoInitZone,
  addNoNeutralizationZone,
  addPlatform,
  addPointOfInterest,
  addRoundconfiguration,
  addVideoStream,
  addZoneOfInterest,
  deleteGlobalScreenConfiguration,
  deleteGridConfiguration,
  deleteGridLayout,
  deleteInitializationMaskingZone,
  deleteNoFlyZone,
  deleteNoFlyZone3d,
  deleteNoInitZone,
  deleteNoNeutralizationZone,
  deletePlatform,
  deletePointOfInterest,
  deleteRoundconfiguration,
  deleteVideoStream,
  deleteZoneOfInterest,
  updateGlobalScreenConfiguration,
  updateGridConfiguration,
  updateGridLayout,
  updateHome,
  updateInitializationMaskingZone,
  updateMergingConfiguration,
  updateNoFlyZone,
  updateNoFlyZone3d,
  updateNoInitZone,
  updateNoNeutralizationZone,
  updatePlatform,
  updatePointOfInterest,
  updateRoundconfiguration,
  updateVideoConfiguration,
  updateVideoStream,
  updateZoneOfInterest,
} from '@redux/config/config.reducer';
import { addLog, deleteLog, updateLog } from '@redux/global/global.reducer';
import {
  addReplayEvent,
  addReplayManualPeriods,
  deleteReplayEvent,
  deleteReplayManualPeriods,
  updateReplayEvent,
  updateReplayManualPeriods,
  updateReplaySettings,
  updateReplayStorageConfiguration,
} from '@redux/replay/replay.reducer';
import {
  addEquipmentConfiguration,
  addPerimeterCamera,
  addSensor,
  deleteEquipmentConfiguration,
  deletePerimeterCamera,
  deleteSensor,
  updateEquipmentConfiguration,
  updatePerimeterCamera,
  updateSensor,
  updateSensorStorage,
} from '@redux/sensors/sensors.reducer';
import {
  addDrone,
  addFlightPlan,
  addFlyRoute,
  addFlySection,
  addFlyZone,
  addPilot,
  deleteDrone,
  deleteFlightPlan,
  deleteFlyRoute,
  deleteFlySection,
  deleteFlyZone,
  deletePilot,
  updateDrone,
  updateFlightPlan,
  updateFlyRoute,
  updateFlySection,
  updateFlyZone,
  updatePilot,
} from '@redux/utm/utm.reducer';
import { ActionCreator } from '@reduxjs/toolkit';

export enum SseActionEnum {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  INIT = 'INIT',
  HEARTBEAT = 'HEARTBEAT',
  INFO = 'INFO',
}

export type CrudSseAction = SseActionEnum.CREATE | SseActionEnum.UPDATE | SseActionEnum.DELETE;

export enum SseTypeEnum {
  //======GLOBAL=====\\
  LOG = 'LOG',
  //======AUTHENT=====\\
  PROFILE = 'PROFILE',
  SITE = 'SITE',
  USER = 'USER',
  AUTHENT_SETTINGS = 'AUTHENT_SETTINGS',
  GROUP = 'GROUP',
  //======CHAT======\\
  MSG = 'MSG',
  //======CONFIG======\\
  GRID_LAYOUT = 'GRID_LAYOUT',
  GRID_CONFIGURATION = 'GRID_CONFIGURATION',
  VIDEO_STREAM = 'VIDEO_STREAM',
  NIMBLE_CONFIGURATION = 'NIMBLE_CONFIGURATION',
  MERGING_CONFIGURATION = 'MERGING_CONFIGURATION',
  GLOBAL_SCREEN_CONFIGURATION = 'GLOBAL_SCREEN_CONFIGURATION',
  HOME = 'HOME',
  IMZ = 'IMZ',
  NFZ = 'NFZ',
  NFZ_3D = 'NFZ_3D',
  NIZ = 'NIZ',
  NNZ = 'NNZ',
  POI = 'POI',
  ZOI = 'ZOI',
  ROUND_CONFIGURATION = 'ROUND_CONFIGURATION',
  PLATFORM = 'PLATFORM',
  //======SENSOR======\\
  SENSOR_CONFIGURATION = 'SENSOR_CONFIGURATION',
  PERIMETER_CAMERA = 'PERIMETER_CAMERA',
  EQUIPMENT_CONFIGURATION = 'EQUIPMENT_CONFIGURATION',
  SENSOR_STORAGE = 'SENSOR_STORAGE',
  //========UTM=======\\
  PILOT = 'PILOT',
  DRONE = 'DRONE',
  FLIGHT_PLAN = 'FLIGHT_PLAN',
  FLY_ZONE = 'FLY_ZONE',
  FLY_ROUTE_SECTION = 'FLY_ROUTE_SECTION',
  FLY_ROUTE = 'FLY_ROUTE',
  //======REPLAY======\\
  REPLAY_EVENT = 'REPLAY_EVENT',
  REPLAY_PERIOD = 'REPLAY_PERIOD',
  REPLAY_STORAGE = 'REPLAY_STORAGE',
  REPLAY_SETTINGS = 'REPLAY_SETTINGS',
  //======DATA======\\
  NOTIFICATION = 'NOTIFICATION',
}

export enum SseModuleTypeEnum {
  AUTHENT = 'AUTHENT',
  CHAT = 'CHAT',
  CONFIG = 'CONFIG',
  DATA = 'DATA',
  REPLAY = 'REPLAY',
  SENSOR = 'SENSOR',
  UTM = 'UTM',
  GLOBAL = 'GLOBAL',
}

export type ActionRecord = Partial<
  Record<Exclude<SseActionEnum, SseActionEnum.HEARTBEAT | SseActionEnum.INIT>, ActionCreator<unknown>>
>;

// Todo remove partial and move to constant file
export const ActionsMap: Record<Exclude<SseTypeEnum, SseTypeEnum.NOTIFICATION>, ActionRecord> = {
  //======GLOBAL=====\\
  [SseTypeEnum.LOG]: {
    [SseActionEnum.CREATE]: addLog,
    [SseActionEnum.UPDATE]: updateLog,
    [SseActionEnum.DELETE]: deleteLog,
  },
  //======AUTHENT=====\\
  [SseTypeEnum.PROFILE]: {
    [SseActionEnum.CREATE]: addProfile,
    [SseActionEnum.UPDATE]: updateProfile,
    [SseActionEnum.DELETE]: deleteProfile,
  },
  [SseTypeEnum.SITE]: {
    [SseActionEnum.CREATE]: addSite,
    [SseActionEnum.UPDATE]: updateSite,
    [SseActionEnum.DELETE]: deleteSite,
  },
  [SseTypeEnum.USER]: {
    [SseActionEnum.CREATE]: addUser,
    [SseActionEnum.UPDATE]: updateUser,
    [SseActionEnum.DELETE]: deleteUser,
  },
  [SseTypeEnum.AUTHENT_SETTINGS]: {
    [SseActionEnum.CREATE]: updateAuthentSettings,
    [SseActionEnum.UPDATE]: updateAuthentSettings,
    [SseActionEnum.DELETE]: updateAuthentSettings,
  },
  [SseTypeEnum.GROUP]: {
    [SseActionEnum.CREATE]: addGroup,
    [SseActionEnum.UPDATE]: updateGroup,
    [SseActionEnum.DELETE]: deleteGroup,
  },
  //========UTM=======\\
  [SseTypeEnum.PILOT]: {
    [SseActionEnum.CREATE]: addPilot,
    [SseActionEnum.UPDATE]: updatePilot,
    [SseActionEnum.DELETE]: deletePilot,
  },
  [SseTypeEnum.DRONE]: {
    [SseActionEnum.CREATE]: addDrone,
    [SseActionEnum.UPDATE]: updateDrone,
    [SseActionEnum.DELETE]: deleteDrone,
  },
  [SseTypeEnum.FLIGHT_PLAN]: {
    [SseActionEnum.CREATE]: addFlightPlan,
    [SseActionEnum.UPDATE]: updateFlightPlan,
    [SseActionEnum.DELETE]: deleteFlightPlan,
  },
  [SseTypeEnum.FLY_ZONE]: {
    [SseActionEnum.CREATE]: addFlyZone,
    [SseActionEnum.UPDATE]: updateFlyZone,
    [SseActionEnum.DELETE]: deleteFlyZone,
  },
  [SseTypeEnum.FLY_ROUTE_SECTION]: {
    [SseActionEnum.CREATE]: addFlySection,
    [SseActionEnum.UPDATE]: updateFlySection,
    [SseActionEnum.DELETE]: deleteFlySection,
  },
  [SseTypeEnum.FLY_ROUTE]: {
    [SseActionEnum.CREATE]: addFlyRoute,
    [SseActionEnum.UPDATE]: updateFlyRoute,
    [SseActionEnum.DELETE]: deleteFlyRoute,
  },
  //=======SENSOR======\\
  [SseTypeEnum.SENSOR_CONFIGURATION]: {
    [SseActionEnum.CREATE]: addSensor,
    [SseActionEnum.UPDATE]: updateSensor,
    [SseActionEnum.DELETE]: deleteSensor,
  },
  [SseTypeEnum.PERIMETER_CAMERA]: {
    [SseActionEnum.CREATE]: addPerimeterCamera,
    [SseActionEnum.UPDATE]: updatePerimeterCamera,
    [SseActionEnum.DELETE]: deletePerimeterCamera,
  },
  [SseTypeEnum.EQUIPMENT_CONFIGURATION]: {
    [SseActionEnum.CREATE]: addEquipmentConfiguration,
    [SseActionEnum.UPDATE]: updateEquipmentConfiguration,
    [SseActionEnum.DELETE]: deleteEquipmentConfiguration,
  },
  [SseTypeEnum.SENSOR_STORAGE]: {
    [SseActionEnum.CREATE]: updateSensorStorage,
    [SseActionEnum.UPDATE]: updateSensorStorage,
    [SseActionEnum.DELETE]: updateSensorStorage,
  },
  //=======REPLAY======\\
  [SseTypeEnum.REPLAY_EVENT]: {
    [SseActionEnum.CREATE]: addReplayEvent,
    [SseActionEnum.UPDATE]: updateReplayEvent,
    [SseActionEnum.DELETE]: deleteReplayEvent,
  },
  [SseTypeEnum.REPLAY_PERIOD]: {
    [SseActionEnum.CREATE]: addReplayManualPeriods,
    [SseActionEnum.UPDATE]: updateReplayManualPeriods,
    [SseActionEnum.DELETE]: deleteReplayManualPeriods,
  },
  [SseTypeEnum.REPLAY_STORAGE]: {
    [SseActionEnum.CREATE]: updateReplayStorageConfiguration,
    [SseActionEnum.UPDATE]: updateReplayStorageConfiguration,
    [SseActionEnum.DELETE]: updateReplayStorageConfiguration,
  },
  [SseTypeEnum.REPLAY_SETTINGS]: {
    [SseActionEnum.UPDATE]: updateReplaySettings,
  },
  //=======CHAT======\\
  [SseTypeEnum.MSG]: {
    [SseActionEnum.CREATE]: addMessage,
  },
  //=======CONFIG======\\
  [SseTypeEnum.IMZ]: {
    [SseActionEnum.CREATE]: addInitializationMaskingZone,
    [SseActionEnum.UPDATE]: updateInitializationMaskingZone,
    [SseActionEnum.DELETE]: deleteInitializationMaskingZone,
  },
  [SseTypeEnum.NFZ]: {
    [SseActionEnum.CREATE]: addNoFlyZone,
    [SseActionEnum.UPDATE]: updateNoFlyZone,
    [SseActionEnum.DELETE]: deleteNoFlyZone,
  },
  [SseTypeEnum.NFZ_3D]: {
    [SseActionEnum.CREATE]: addNoFlyZone3d,
    [SseActionEnum.UPDATE]: updateNoFlyZone3d,
    [SseActionEnum.DELETE]: deleteNoFlyZone3d,
  },
  [SseTypeEnum.NIZ]: {
    [SseActionEnum.CREATE]: addNoInitZone,
    [SseActionEnum.UPDATE]: updateNoInitZone,
    [SseActionEnum.DELETE]: deleteNoInitZone,
  },
  [SseTypeEnum.NNZ]: {
    [SseActionEnum.CREATE]: addNoNeutralizationZone,
    [SseActionEnum.UPDATE]: updateNoNeutralizationZone,
    [SseActionEnum.DELETE]: deleteNoNeutralizationZone,
  },
  [SseTypeEnum.ZOI]: {
    [SseActionEnum.CREATE]: addZoneOfInterest,
    [SseActionEnum.UPDATE]: updateZoneOfInterest,
    [SseActionEnum.DELETE]: deleteZoneOfInterest,
  },
  [SseTypeEnum.POI]: {
    [SseActionEnum.CREATE]: addPointOfInterest,
    [SseActionEnum.UPDATE]: updatePointOfInterest,
    [SseActionEnum.DELETE]: deletePointOfInterest,
  },
  [SseTypeEnum.GRID_LAYOUT]: {
    [SseActionEnum.CREATE]: addGridLayout,
    [SseActionEnum.UPDATE]: updateGridLayout,
    [SseActionEnum.DELETE]: deleteGridLayout,
  },
  [SseTypeEnum.GRID_CONFIGURATION]: {
    [SseActionEnum.CREATE]: addGridConfiguration,
    [SseActionEnum.UPDATE]: updateGridConfiguration,
    [SseActionEnum.DELETE]: deleteGridConfiguration,
  },
  [SseTypeEnum.VIDEO_STREAM]: {
    [SseActionEnum.CREATE]: addVideoStream,
    [SseActionEnum.UPDATE]: updateVideoStream,
    [SseActionEnum.DELETE]: deleteVideoStream,
  },
  [SseTypeEnum.ROUND_CONFIGURATION]: {
    [SseActionEnum.CREATE]: addRoundconfiguration,
    [SseActionEnum.UPDATE]: updateRoundconfiguration,
    [SseActionEnum.DELETE]: deleteRoundconfiguration,
  },
  [SseTypeEnum.HOME]: {
    [SseActionEnum.CREATE]: updateHome,
    [SseActionEnum.UPDATE]: updateHome,
    [SseActionEnum.DELETE]: updateHome,
  },
  [SseTypeEnum.MERGING_CONFIGURATION]: {
    [SseActionEnum.CREATE]: updateMergingConfiguration,
    [SseActionEnum.UPDATE]: updateMergingConfiguration,
    [SseActionEnum.DELETE]: updateMergingConfiguration,
  },
  [SseTypeEnum.GLOBAL_SCREEN_CONFIGURATION]: {
    [SseActionEnum.CREATE]: addGlobalScreenConfiguration,
    [SseActionEnum.UPDATE]: updateGlobalScreenConfiguration,
    [SseActionEnum.DELETE]: deleteGlobalScreenConfiguration,
  },
  [SseTypeEnum.NIMBLE_CONFIGURATION]: {
    [SseActionEnum.CREATE]: updateVideoConfiguration,
    [SseActionEnum.UPDATE]: updateVideoConfiguration,
    [SseActionEnum.DELETE]: updateVideoConfiguration,
  },
  [SseTypeEnum.PLATFORM]: {
    [SseActionEnum.CREATE]: addPlatform,
    [SseActionEnum.UPDATE]: updatePlatform,
    [SseActionEnum.DELETE]: deletePlatform,
  },
};

export interface SseMessageEvent {
  data: string;
  comment: string;
  lastEventId: string;
}

export interface SituationMessage {
  version: number;
  json: string;
  fullSituation: boolean;
}
