import { Center, Grid, GridItem } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import Player from '@components/common/video/Player';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSelectedDoubtCheckSegmentName } from '@redux/global/global.selector';
import {
  selectPerimeterCamerasBySegmentName,
  selectSelectedDoubtCheckPerimeterCamera,
} from '@redux/sensors/sensors.selectors';
import { selectAllOngoingAlertsBySegmentName } from '@redux/situation/situation.selectors';
import { useUpdatePerimeterCameraConfigurationMutation } from '@services/c2/c2.api';
import { formatDate } from '@utils/date.utils';
import { addDateToSourceReplayUrl } from '@utils/replay/replay.utils';
import { getNimbleStreamFromUrl } from '@utils/sensors/camera/camera.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { subSeconds } from 'date-fns';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PerimeterEffractionAlert } from '@/types/data/data.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

import PerimeterCameraWrapper from './PerimeterCameraWrapper';

interface OwnProps {
  uniqueKey: string;
}

function PerimeterDoubtCheckGrid({ uniqueKey }: Readonly<OwnProps>) {
  const [urls, setUrls] = useState(new Map<string, string>());
  const { formatMessage } = useIntl();

  const singlePerimeterCamera = useAppSelector(selectSelectedDoubtCheckPerimeterCamera, isEqual);

  useEffect(() => {
    if (singlePerimeterCamera && !singlePerimeterCamera.url) {
      createToast(
        formatMessage({ id: 'automatonSegments.doubtCheck.noUrl' }, { cam: singlePerimeterCamera.code }),
        ToastStatusEnum.ERROR,
      );
    }
  }, [formatMessage, singlePerimeterCamera]);

  const segmentName: string | null = useAppSelector(selectSelectedDoubtCheckSegmentName);
  const effractionAlert: PerimeterEffractionAlert | undefined = useAppSelector(
    (state) => selectAllOngoingAlertsBySegmentName(state, false, segmentName),
    isEqual,
  );
  const segmentPerimeterCameras: PerimeterCamera[] = useAppSelector(
    (state) => selectPerimeterCamerasBySegmentName(state, segmentName),
    isEqual,
  );
  const segmentPerimeterCamerasChunk: PerimeterCamera[] = useMemo(
    () => segmentPerimeterCameras.slice(0, 4),
    [segmentPerimeterCameras],
  );

  const [updatePericam] = useUpdatePerimeterCameraConfigurationMutation();

  useEffect(() => {
    const tmpUrls = new Map<string, string>();
    segmentPerimeterCamerasChunk.forEach((pericam) => {
      let url = pericam.url ? getNimbleStreamFromUrl(pericam.url) : null;
      if (effractionAlert) {
        if (pericam.sourceReplayUrl) {
          const newReplayUrl = addDateToSourceReplayUrl(
            pericam.sourceReplayUrl,
            formatDate(subSeconds(new Date(effractionAlert.startTime), 5), DateTimeEnum.DATE_TIME_SECONDS_PERIM_FORMAT),
          );
          if (pericam.sourceReplayUrl != newReplayUrl) {
            updatePericam({
              id: pericam.id,
              site: pericam.site,
              pericam: {
                ...pericam,
                sourceReplayUrl: newReplayUrl,
              },
            });
            url = null;
          } else if (!pericam.replayUrl) {
            createToast(
              formatMessage({ id: 'automatonSegments.doubtCheck.noReplayUrl' }, { cam: pericam.code }),
              ToastStatusEnum.ERROR,
            );
          } else {
            url = pericam.replayUrl;
          }
        }
      }
      if (!url) {
        createToast(
          formatMessage({ id: 'automatonSegments.doubtCheck.noUrl' }, { cam: pericam.code }),
          ToastStatusEnum.ERROR,
        );
      } else {
        tmpUrls.set(pericam.code, url);
      }
      setUrls(tmpUrls);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentName, effractionAlert, segmentPerimeterCamerasChunk, formatMessage]);

  if (singlePerimeterCamera?.url) {
    return (
      <PerimeterCameraWrapper name={singlePerimeterCamera.name}>
        <Player
          src={getNimbleStreamFromUrl(singlePerimeterCamera.url)}
          replayMode={false}
          id={`player-${uniqueKey}-pericam`}
        />
      </PerimeterCameraWrapper>
    );
  }

  if (!segmentName) {
    return (
      <Center width="100%" height="100%">
        <Text>
          <FormattedMessage id="automatonSegments.doubtCheck.noFenceSelected" />
        </Text>
      </Center>
    );
  }

  if (segmentPerimeterCamerasChunk.length === 0) {
    return (
      <Center width="100%" height="100%">
        <Text>
          <FormattedMessage id="automatonSegments.doubtCheck.noCameraLinkedToFence" />
        </Text>
      </Center>
    );
  }

  return (
    <Grid
      templateAreas={`"stream-2 stream-0""stream-3 stream-1"`}
      templateColumns="1fr 1fr"
      templateRows="1fr 1fr"
      minHeight={0}
      minWidth={0}
      width="100%"
      height="100%"
    >
      {segmentPerimeterCamerasChunk.map((cam, index) => {
        return (
          <GridItem key={`${uniqueKey}-${cam.code}`} minHeight={0} minWidth={0} area={`stream-${index}`}>
            <PerimeterCameraWrapper name={cam.name}>
              <Player src={urls.get(cam.code) ?? ''} id={`player-${uniqueKey}-${cam.code}`} />
            </PerimeterCameraWrapper>
          </GridItem>
        );
      })}
    </Grid>
  );
}

export default PerimeterDoubtCheckGrid;
