import { ReactComponent as EventIcon } from '@assets/icons/sprite_icons/warning_event.svg';
import { Box, Icon, Tooltip } from '@chakra-ui/react';

import { ReplayEvent } from '@/types/replay/replay.types';

import EventTooltip from './EventTooltip';

const LEFT = 140;

type Props = {
  event: ReplayEvent;
  timelineStart: number;
  timelineEnd: number;
};

export default function TimelineEvent({ event, timelineStart, timelineEnd }: Readonly<Props>) {
  if (timelineStart === timelineEnd) {
    return <></>;
  }
  const eventStart = new Date(event.startDate).getTime();
  const ratio = (eventStart - timelineStart) / (timelineEnd - timelineStart);
  return (
    <Tooltip label={<EventTooltip event={event} />}>
      <Box
        position="absolute"
        height="32px"
        width="32px"
        top="100px"
        transform="translateX(-50%)"
        left={`calc(${LEFT * (1 - ratio)}px + ${ratio * 100}cqw)`}
        zIndex="1"
      >
        <Icon as={EventIcon} height="32px" width="32px" />
      </Box>
    </Tooltip>
  );
}
