import { Text } from '@chakra-ui/react';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import {
  selectAutomatonSectionByName,
  selectSensorOrSubSensorConfigurationByUniqueCodes,
} from '@redux/situation/monitoring.selectors';
import { getSectionName } from '@utils/map/automatonSection.utils';

import { FeatureType, FeatureTypeEnum, SensorUniqueCodes } from '@/types/map.types';
import { AutomatonSectionNames } from '@/types/sensor/configuration.types';

export interface LabelTooltipData extends FeatureType {
  type:
    | FeatureTypeEnum.SENSOR_LABEL
    | FeatureTypeEnum.SENSOR_ICON
    | FeatureTypeEnum.AUTOMATON_SECTION_LABEL
    | FeatureTypeEnum.PERIMETER_PORTAL;
  data: SensorUniqueCodes | AutomatonSectionNames;
}

function LabelTooltip({ data, type }: Readonly<LabelTooltipData>) {
  const config = useSelectorWithReplayMode(
    selectSensorOrSubSensorConfigurationByUniqueCodes,
    data as SensorUniqueCodes,
  );
  const sectionData = useSelectorWithReplayMode(
    selectAutomatonSectionByName,
    (data as AutomatonSectionNames).sectionName,
  );

  if (type === FeatureTypeEnum.AUTOMATON_SECTION_LABEL) {
    const sectionName = getSectionName(sectionData!.section);
    return <Text fontSize="16px">{sectionName}</Text>;
  }

  if (type === FeatureTypeEnum.PERIMETER_PORTAL) {
    const portalName = sectionData?.portal?.name;
    return <Text fontSize="16px">{portalName}</Text>;
  }

  return <Text fontSize="16px">{config?.name}</Text>;
}

export default LabelTooltip;
