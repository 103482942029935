import { Button, Text, VStack } from '@chakra-ui/react';
import CustomRadioToggle from '@components/common/inputs/CustomRadioToggle';
import { appIntl } from '@components/locale/IntlGlobalProvider';
import { useCabinetCommand } from '@hooks/useCabinetCommand';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import {
  selectSensorConfigurationByUniqueCode,
  selectSensorOrSubSensorConfigurationByUniqueCodes,
  selectSubSensorStatusByUniqueCode,
} from '@redux/situation/monitoring.selectors';
import {
  DefenceState,
  getCabinetSegmentStatuses,
  getDefenceState,
  isSegmentRepulsionOn,
} from '@utils/sensors/sensors.utils';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import { AutomatonCabinet, AutomatonConfiguration } from '@/types/sensor/configuration.types';
import { AutomatonCabinetStatus, SensorStatusEnum } from '@/types/sensor/status.types';

type Props = {
  sensorUniqueCodes: SensorUniqueCodes;
};

export default function AutomatonCommands({ sensorUniqueCodes }: Readonly<Props>) {
  const { formatMessage } = appIntl();

  const cabinetStatus = useSelectorWithReplayMode(
    selectSubSensorStatusByUniqueCode,
    sensorUniqueCodes.sensorUniqueCode,
  ) as AutomatonCabinetStatus | null;

  const segmentStatuses = getCabinetSegmentStatuses(cabinetStatus);

  const [isSleeping, setIsSleeping] = useState<boolean>(cabinetStatus?.status === SensorStatusEnum.SLEEP);
  const defenceState = getDefenceState(segmentStatuses);
  const [state, setState] = useState<DefenceState>(defenceState);

  const cabinetConfiguration = useSelectorWithReplayMode(
    selectSensorOrSubSensorConfigurationByUniqueCodes,
    sensorUniqueCodes,
  ) as AutomatonCabinet | null;

  const parentAutomatonConfiguration = useSelectorWithReplayMode(
    selectSensorConfigurationByUniqueCode,
    sensorUniqueCodes.parentUniqueCode,
  ) as AutomatonConfiguration | null;

  const useCabinet = useCabinetCommand({
    parentSensorConfiguration: parentAutomatonConfiguration,
    cabinetConfiguration: cabinetConfiguration,
  });

  if (!cabinetConfiguration || !parentAutomatonConfiguration || !useCabinet.canCommand) {
    return null;
  }

  const countDetection = segmentStatuses.filter((segmentStatus) => !isSegmentRepulsionOn(segmentStatus)).length;

  const alarmCount = cabinetStatus
    ? Object.values(cabinetStatus.segmentStatuses).reduce((acc, curr) => {
        const lowerStatus = curr?.lowerFenceStatusAndState?.status === SensorStatusEnum.EFFRACTION ? 1 : 0;
        const upperStatus = curr?.upperFenceStatusAndState?.status === SensorStatusEnum.EFFRACTION ? 1 : 0;
        return acc + lowerStatus + upperStatus;
      }, 0)
    : 0;

  return (
    <>
      <VStack padding={4} gap={3} backgroundColor="neutral.800" width="100%">
        <Text size="md">
          <FormattedMessage id="sensors.command.cabinet.sleep.description" />
        </Text>
        <VStack width="100%" gap={2}>
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.cabinet.sleep.on' })}
            isDisabled={cabinetStatus === null}
            isChecked={cabinetStatus !== null && !isSleeping}
            onClick={() => cabinetStatus !== null && setIsSleeping(false)}
          />
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.cabinet.sleep.off' })}
            isDisabled={cabinetStatus === null}
            isChecked={cabinetStatus !== null && isSleeping}
            onClick={() => cabinetStatus !== null && setIsSleeping(true)}
          />
        </VStack>
        <Button
          isDisabled={cabinetStatus === null || isSleeping === (cabinetStatus.status === SensorStatusEnum.SLEEP)}
          isLoading={useCabinet.isSleepCommandLoading}
          variant="formButtonPrimary"
          height="48px"
          width="125px"
          onClick={() => useCabinet.sleep(isSleeping)}
        >
          <FormattedMessage id="global.validate" />
        </Button>
      </VStack>
      {(useCabinet.canRepulse || useCabinet.canDetect) && (
        <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%">
          <VStack alignItems="start" width="100%" gap={2}>
            <Text size="md">
              <FormattedMessage id="sensors.command.cabinet.defence.description" />
            </Text>
            <Text color="neutral.200" fontSize="16px">
              <FormattedMessage
                id="sensors.command.cabinet.defence.counter"
                values={{ countDetection, total: segmentStatuses.length }}
              />
            </Text>
          </VStack>
          <VStack width="100%" gap={2}>
            <CustomRadioToggle
              label={formatMessage({ id: 'sensors.command.cabinet.defence.detection' })}
              isDisabled={cabinetStatus === null || !useCabinet.canDetect}
              isChecked={state === 'detection'}
              onClick={() => setState('detection')}
            />
            <CustomRadioToggle
              label={formatMessage({ id: 'sensors.command.cabinet.defence.repulsion' })}
              isDisabled={cabinetStatus === null || !useCabinet.canRepulse}
              isChecked={state === 'repulsion'}
              onClick={() => setState('repulsion')}
            />
            <Button
              isDisabled={!cabinetStatus || defenceState === state}
              isLoading={useCabinet.isRepulsionCommandLoading}
              variant="formButtonPrimary"
              height="48px"
              width="125px"
              onClick={() => useCabinet.repulse(state !== 'detection')}
            >
              <FormattedMessage id="global.validate" />
            </Button>
          </VStack>
        </VStack>
      )}
      <VStack alignItems="start" width="100%" padding={4} gap={3} backgroundColor="neutral.800">
        <VStack alignItems="start" width="100%" gap={2}>
          <Text size="md">
            <FormattedMessage id="sensors.command.cabinet.alarmsAction" />
          </Text>
          <Text fontSize="16px" color="neutral.200">
            <FormattedMessage id="sensors.command.cabinet.sectionInalarm" values={{ count: alarmCount }} />
          </Text>
        </VStack>
        <Button
          isDisabled={cabinetStatus === null || alarmCount === 0}
          isLoading={useCabinet.isReinitializationCommandLoading}
          variant="formButtonPrimary"
          alignSelf="center"
          height="48px"
          width="262px"
          onClick={useCabinet.reinitialize}
        >
          <FormattedMessage id="sensors.command.cabinet.ackAlarms" values={{ count: alarmCount }} />
        </Button>
      </VStack>
    </>
  );
}
