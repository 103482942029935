import { HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { extendedSensorShortHand } from '@utils/map/map.constants';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { SpaceViolationAlert } from '@/types/data/data.types';

type Props = {
  notification: SpaceViolationAlert;
};
export default function SpaceViolationCard({ notification }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const uniqueSensorsTypes = Array.from(
    new Set(notification.trackIds.map((track) => extendedSensorShortHand[track.sensorType])),
  ).join(', ');
  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(notification.marks)),
    isEqual,
  ).join(', ');

  return (
    <>
      <VStack width="232px" alignItems="start" justifyContent="start" paddingY="11px" height="100%" gap={0.25}>
        <HStack gap={0.5}>
          <Text>
            <FormattedMessage
              id={`notification.${notification.reliability ? notification.reliability.toLocaleLowerCase() : 'unsure'}`}
            />
          </Text>
          <Tooltip label={uniqueSensorsTypes}>
            <Text isTruncated>({uniqueSensorsTypes})</Text>
          </Tooltip>
        </HStack>
      </VStack>
      <VStack alignItems="start" justifyContent="start" paddingY="11px" height="100%" gap={0.25}>
        <Text>{platformsNames || formatMessage({ id: 'components.alert.noPlatform' })}</Text>
        <HStack gap={0.25}>
          <Text>
            <FormattedMessage id="notification.altitude" />
          </Text>
          <Text>{notification.targetAltitude !== null ? `${notification.targetAltitude.toFixed(0)} m` : 'N/A'}</Text>
        </HStack>
      </VStack>
    </>
  );
}
