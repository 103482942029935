import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type Props = {
  children?: ReactNode;
};

// 'intl' service singleton reference
let intl: IntlShape;

export function IntlGlobalProvider({ children }: Readonly<Props>) {
  intl = useIntl(); // Keep the 'intl' service reference
  return <>{children}</>;
}

// Getter function to expose the read-only 'intl' service
export function appIntl() {
  return intl;
}
