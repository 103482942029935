import { updateActiveUser, updateRenewalToken, updateSessionUuid, updateToken } from '@redux/authent/authent.reducer';
import { isFirstConnectionExceptionPayload } from '@utils/common.utils';

import { AuthentSettings, DisplayModeEnum, User } from '@/types/authent/login.types';

import { AUTHENT_URL, authentApi } from './authent.api';

export const userApi = authentApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => ({
        url: `${AUTHENT_URL}/users/me`,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(updateActiveUser(data));
      },
      providesTags: (result, error) => {
        return [{ type: 'User', id: isFirstConnectionExceptionPayload(error) ? error.data.user_id : result?.id }];
      },
    }),
    checkPreviousPassword: builder.mutation<boolean, { newPassword: string; id: string | number }>({
      query: ({ newPassword, id }) => ({
        url: `${AUTHENT_URL}/users/${id}/check-previous-passwords`,
        method: 'POST',
        body: newPassword,
      }),
    }),
    createUser: builder.mutation<User, Partial<User>>({
      query: (user) => ({
        url: `${AUTHENT_URL}/users`,
        method: 'POST',
        body: user,
      }),
    }),
    updateUser: builder.mutation<User, User>({
      query: (user) => ({
        url: `${AUTHENT_URL}/users/${user.id}`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: (result) => [
        { type: 'User', id: result?.id },
        { type: 'User', id: 'LIST' },
      ],
    }),
    updateAuthentSettings: builder.mutation<AuthentSettings, AuthentSettings>({
      query: (configuration) => ({
        url: `${AUTHENT_URL}/settings`,
        method: 'PUT',
        body: configuration,
      }),
    }),
    updateDisplayMode: builder.mutation<
      { token: string | null; renew: string | null; sessionUuid: string | null },
      {
        displayMode: DisplayModeEnum;
      }
    >({
      query: (params: { displayMode: DisplayModeEnum }) => ({
        url: `${AUTHENT_URL}/settings/displayMode`,
        method: 'PUT',
        params: params,
      }),
      transformResponse: (_, meta) => {
        return {
          token: meta?.response?.headers.get('AUTHORIZATION') ?? null,
          renew: meta?.response?.headers.get('RENEWAL') ?? null,
          sessionUuid: meta?.response?.headers.get('Session-Uuid') ?? null,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data.token && data.renew) {
          dispatch(updateToken(data.token));
          dispatch(updateRenewalToken(data.renew));
          dispatch(updateSessionUuid(data.sessionUuid));
        }
      },
    }),
    patchUserPassword: builder.mutation<User, { id: number | string; currentPassword: string; newPassword: string }>({
      query: ({ id, currentPassword, newPassword }) => ({
        url: `${AUTHENT_URL}/users/${id}/password`,
        method: 'PATCH',
        body: {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
      }),
      invalidatesTags: (result) => {
        if (result) {
          return [
            { type: 'User', id: result.id },
            { type: 'User', id: 'LIST' },
          ];
        }
        return [];
      },
    }),
    patchReinitializePassword: builder.mutation<void, { id: number; newPassword: string }>({
      query: ({ id, newPassword }) => ({
        url: `${AUTHENT_URL}/users/${id}/reinitializePassword`,
        method: 'PATCH',
        body: {
          newPassword: newPassword,
        },
      }),
    }),
    deleteUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `${AUTHENT_URL}/users/${id}`,
        method: 'DELETE',
      }),
    }),
    patchActiveGroup: builder.mutation<User, { id: number; newActiveGroupId: number }>({
      query: ({ id, newActiveGroupId }) => ({
        url: `${AUTHENT_URL}/users/${id}/active-group`,
        method: 'PATCH',
        body: newActiveGroupId,
      }),
    }),
    patchPersonalActiveGroup: builder.mutation<User, { newActiveGroupId: number }>({
      query: ({ newActiveGroupId }) => ({
        url: `${AUTHENT_URL}/users/me/active-group`,
        method: 'PATCH',
        body: newActiveGroupId,
      }),
    }),
    patchGroups: builder.mutation<User, { id: number; groupsId: number[] }>({
      query: ({ id, groupsId }) => ({
        url: `${AUTHENT_URL}/users/${id}/groups`,
        method: 'PATCH',
        body: groupsId,
      }),
      invalidatesTags: (result) => [
        { type: 'User', id: result?.id },
        { type: 'User', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateUserMutation,
  useUpdateDisplayModeMutation,
  usePatchActiveGroupMutation,
  usePatchPersonalActiveGroupMutation,
  usePatchUserPasswordMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useCheckPreviousPasswordMutation,
  usePatchGroupsMutation,
} = userApi;
