import { getSector, getSectorAzimuthLine } from '@utils/map/sector.utils';
import { cameraRange } from '@utils/sensors/camera/camera.constants';
import { compareCameraRange, isCameraType } from '@utils/sensors/camera/camera.utils';
import { Feature } from 'geojson';

import { FeatureTypeEnum } from '@/types/map.types';
import { SubCameraConfiguration } from '@/types/sensor/configuration.types';
import { CameraStatus, VideoStatus } from '@/types/sensor/status.types';

export const getVideoStatusToDisplay = (
  cameraStatus: CameraStatus,
  selectedSubCameras: SubCameraConfiguration[],
): VideoStatus | null => {
  const configuration = cameraStatus.configuration;
  const videoStatusesWithFov = cameraStatus.videoStatuses.filter((s) => s.fov !== null);
  const correspondingCamera = selectedSubCameras.find((camera) => camera.sensorId === configuration.id);
  let statusToDisplay = null;
  if (correspondingCamera) {
    statusToDisplay = videoStatusesWithFov.find((status) => status.id === correspondingCamera.cameraId);
  }
  if (!statusToDisplay) {
    statusToDisplay = videoStatusesWithFov.sort((s1, s2) => compareCameraRange(s1, s2)).shift();
  }
  return statusToDisplay ?? null;
};

export const toLadCameraSectorFeatures = (
  camerasStatus: CameraStatus[],
  selectedCameraStreams: SubCameraConfiguration[],
): Feature[] =>
  camerasStatus
    .filter((cameraStatus) => !!cameraStatus.videoStatuses.length && cameraStatus.pan !== null)
    .map((cameraStatus: CameraStatus) => {
      const configuration = cameraStatus.configuration;

      const center = configuration.sensorPosition;

      const displayedVideoStatus = getVideoStatusToDisplay(cameraStatus, selectedCameraStreams);

      if (!displayedVideoStatus || displayedVideoStatus.fov === null) {
        return null;
      }

      const bearing1 = cameraStatus.pan! - displayedVideoStatus!.fov / 2;
      const bearing2 = cameraStatus.pan! + displayedVideoStatus!.fov / 2;
      const range = cameraRange[cameraStatus.type];

      const sector = getSector(center, bearing1, bearing2, range);
      if (!sector) {
        return null;
      }

      return {
        type: 'Feature',
        properties: {
          featureType: FeatureTypeEnum.LAD_CAMERA_SECTOR,
          value: cameraStatus,
          color: cameraStatus.configuration.sensorColor,
        },
        geometry: {
          type: 'Polygon',
          coordinates: sector.geometry.coordinates,
        },
      };
    })
    .filter((sector) => !!sector)
    .map((sector) => sector as Feature);

export const toLadCameraAzimuthLineFeatures = (cameraStatuses: CameraStatus[]): Feature[] =>
  cameraStatuses
    .map((cameraStatus) => {
      if (cameraStatus.pan === null || !isCameraType(cameraStatus.configuration)) {
        return null;
      }

      const range = cameraRange[cameraStatus.type];

      const azimuthLine = getSectorAzimuthLine(cameraStatus.configuration.sensorPosition, range, cameraStatus.pan);
      if (!azimuthLine) {
        return null;
      }

      return {
        type: 'Feature',
        properties: {
          featureType: FeatureTypeEnum.LAD_CAMERA_AZIMUTH,
        },
        geometry: {
          type: 'LineString',
          coordinates: azimuthLine.geometry.coordinates,
        },
      } as Feature;
    })
    .filter((azimuthLine) => !!azimuthLine)
    .map((azimuthLine) => azimuthLine as Feature);
