import HologardeBackground from '@assets/hologarde-background.jpg';
import { Center, Flex } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { useAppSelector } from '@hooks/redux.hooks';
import { useAuth } from '@hooks/useAuth';
import { selectActiveUser, selectHasBeenInitialized } from '@redux/authent/authent.selectors';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAppCodesQuery } from '@services/c2/c2.api';
import { useGetModelsStreamQuery } from '@services/c2/sse.api';
import { useGetUserSettingsQuery } from '@services/config/setting.api';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

export default function StoreInit({ children }: Readonly<Props>) {
  const hasBeenInitialized = useAppSelector(selectHasBeenInitialized);
  const { token } = useAuth();
  const user = useAppSelector(selectActiveUser);

  const { isLoading: isLoadingAppCodes } = useGetAppCodesQuery(user.activeSite?.code ?? skipToken, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: isLoadingUserSettings } = useGetUserSettingsQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useGetModelsStreamQuery(
    user.activeSite?.code && token
      ? {
          site: user.activeSite.code,
          platforms: user.activeGroup ? user.activeGroup.platforms : [],
          token,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const loading = isLoadingAppCodes || isLoadingUserSettings || !hasBeenInitialized;

  if (loading) {
    return (
      <Flex width="100%" height="100%" alignContent="center" justifyContent="center">
        <Image src={HologardeBackground} fit="cover" height="100%" width="100%" position="absolute" />
        <Center>
          <Spinner size="xl" color="neutral.white" />
        </Center>
      </Flex>
    );
  }

  return <>{children}</>;
}
