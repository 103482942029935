import './tooltip.scss';

import { useAppSelector } from '@hooks/redux.hooks';
import { useMapTooltip } from '@hooks/useMapTooltip';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPopupControlByMapId, selectZoomByMapId } from '@redux/maps/maps.selectors';
import { selectMapFilterByKey } from '@redux/settings/settings.selectors';
import { selectTargets } from '@redux/situation/situation.selectors';
import { getTargetIdentification } from '@utils/target.utils';

import { Target } from '@/types/c2/c2.types';
import { FeatureTypeEnum, LayerNameEnum, PopupDataTypeEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import { useMapContext } from '../MapContext';
import AircraftPermanentTooltip from './AircraftPermanentTooltip';

const PERMANENT_TOOLTIP_ZOOM_LEVEL = 15;

function PermanentTooltips() {
  const { mapId } = useMapContext();
  const isAircraftShown = useAppSelector((state) => selectMapFilterByKey(state, 'displayTargetAircraft'));
  const [position, featureToDisplay] = useMapTooltip([
    LayerNameEnum.TARGETS,
    LayerNameEnum.EVENTS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.NO_FLY_ZONE_FILLS,
  ]);
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const showMeasureTooltip = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const targets: Target<TargetLocation>[] = useSelectorWithReplayMode(selectTargets);
  const zoomLevel = useAppSelector((state) => selectZoomByMapId(state, mapId));
  if (!isAircraftShown || showMeasureTooltip) {
    return null;
  }

  const aircraftTargets = targets
    .filter((target) => getTargetIdentification(target) === IdentificationEnum.AIRCRAFT)
    .filter(
      (target) =>
        !position ||
        !featureToDisplay ||
        featureToDisplay.type !== FeatureTypeEnum.TARGET_ICON ||
        target.id !== featureToDisplay.data.id,
    );
  return (
    <>
      {zoomLevel &&
        zoomLevel > PERMANENT_TOOLTIP_ZOOM_LEVEL &&
        aircraftTargets.map((target) => <AircraftPermanentTooltip {...target} key={target.id} />)}
    </>
  );
}

export default PermanentTooltips;
