import { Option } from '@components/common/inputs/CustomSelect';
import { v4 as uuid } from 'uuid';

export type CustomOption = {
  count?: number;
} & Option;

/**
 * Represents the value of the option 'All' so it's unique. Used mainly for the CustomChipList
 */
export const ALL_OPTION_ID = uuid();
export const ALL_OPTION: CustomOption = { label: 'all' as const, value: ALL_OPTION_ID } satisfies CustomOption;

/**
 * Converts a string input to an Option object with the same label and value, as follows : {label: input, value:
 * input}.
 *
 * If the input is the uuid representing the unique ALL option, it instead creates a special Option object
 * @param input
 */
export function stringToOption(input: string): Option {
  const label = input === ALL_OPTION_ID ? ALL_OPTION.label : input;
  return { label: label, value: input };
}

/**
 * Returns true if 2 options have the same value, regardless of the label
 */
export function areOptionsEqualByValue(option1: Option, option2: Option): boolean {
  return option1.value === option2.value;
}

/**
 * Returns true if an option is present in a list of options, comparing elements by its value
 */
export function isOptionInListByValue(option: Option, list: Option[]): boolean {
  return list.some((o) => areOptionsEqualByValue(o, option));
}

export function removeOptionFromListByValue(option: Option, list: Option[]): Option[] {
  return list.filter((o) => !areOptionsEqualByValue(o, option));
}
