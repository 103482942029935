import { SensorStatusEnum } from '@/types/sensor/status.types';

enum SimplifiedSensorStatus {
  OK = 'sensors.status.HEALTHY',
  ROUND = 'sensors.status.ROUND',
  TRACKING = 'sensors.status.TRACKING',
  KO = 'sensors.status.KO',
  DISCONNECTED = 'sensors.status.DISCONNECTED',
  DEGRADED = 'sensors.status.DEGRADED',
}

export const SIMPLIFIED_SENSOR_STATUS: Partial<Record<SensorStatusEnum, SimplifiedSensorStatus>> = {
  [SensorStatusEnum.DEGRADED]: SimplifiedSensorStatus.DEGRADED,
  [SensorStatusEnum.DESYNCHRONIZED]: SimplifiedSensorStatus.KO,
  [SensorStatusEnum.DISCONNECTED]: SimplifiedSensorStatus.DISCONNECTED,
  [SensorStatusEnum.WAITING]: SimplifiedSensorStatus.KO,
  [SensorStatusEnum.HEALTHY]: SimplifiedSensorStatus.OK,
  [SensorStatusEnum.NODATA]: SimplifiedSensorStatus.OK,
  [SensorStatusEnum.ROUND]: SimplifiedSensorStatus.ROUND,
  [SensorStatusEnum.TRACKING]: SimplifiedSensorStatus.TRACKING,
};
