import { Box } from '@chakra-ui/layout';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface VideoDownloadProgressProps {
  receivedDataSize: number;
  totalDataSize: number | null;
  remainingDownloadTime: number | null;
}

function VideoDownloadProgress({
  receivedDataSize,
  totalDataSize,
  remainingDownloadTime,
}: Readonly<VideoDownloadProgressProps>) {
  const { formatMessage } = useIntl();
  const formatSecondsDuration = (seconds: number): string => {
    if (isNaN(seconds) || seconds < 0) {
      return 'Invalid input';
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const minutesString = minutes > 0 ? `${minutes} min` : '';
    const secondsString =
      remainingSeconds > 0
        ? `${Math.ceil(remainingSeconds)} ${formatMessage({ id: 'common.units.time.second' })}${
            remainingSeconds > 1 ? 's' : ''
          }`
        : '';

    if (minutesString && secondsString) {
      return `${minutesString} ${formatMessage({ id: 'global.and' })} ${secondsString} ${
        remainingSeconds > 1
          ? formatMessage({ id: 'global.remainingPlural' })
          : formatMessage({ id: 'global.remaining' })
      }`;
    } else {
      return `${minutesString || secondsString} ${
        remainingSeconds > 1
          ? formatMessage({ id: 'global.remainingPlural' })
          : formatMessage({ id: 'global.remaining' })
      }...`;
    }
  };

  return (
    <Box textColor="white">
      <strong>
        <FormattedMessage id="videoExport.downloadInProgress" />
      </strong>
      <br />
      {totalDataSize == null ? '?' : ((receivedDataSize / totalDataSize) * 100).toFixed(0)}%{' '}
      <FormattedMessage id="global.done" /> - {(receivedDataSize / 1000000).toFixed(0)}{' '}
      <FormattedMessage id="global.outOf" />
      {totalDataSize == null ? '?' : (totalDataSize / 1000000).toFixed(0)}
      Mo
      <br />
      {remainingDownloadTime == null ? '' : formatSecondsDuration(remainingDownloadTime)}
    </Box>
  );
}

export default VideoDownloadProgress;
