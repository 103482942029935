import UserInit from '@components/UserInit';
import { useAuth } from '@hooks/useAuth';
import { decodeToken } from '@utils/common.utils';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { DisplayModeEnum } from '@/types/authent/login.types';
import { RoutesEnum } from '@/types/routes.types';

import StoreInit from '../StoreInit';
import UserGroupInit from '../UserGroupInit';

export default function PrivateOutlet() {
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const displayMode = decodeToken(token)?.displayMode;
  const shouldRedirectToDisplayMode = token && (!displayMode || displayMode === DisplayModeEnum.NOT_SET);

  useEffect(() => {
    if (shouldRedirectToDisplayMode) {
      navigate(RoutesEnum.DISPLAY_MODE);
    } else if (token) {
      navigate(RoutesEnum.C2);
    }
  }, [navigate, shouldRedirectToDisplayMode, token]);

  return token !== null ? (
    <UserInit>
      <UserGroupInit>
        <StoreInit>
          <Outlet />
        </StoreInit>
      </UserGroupInit>
    </UserInit>
  ) : (
    <Navigate to={RoutesEnum.LOGIN} state={{ from: location }} />
  );
}
