import { POLYGONS_OPACITY } from '@utils/map/map.constants';
import { SHOW_LAD_CAMERA_STROBE_MAX_ZOOM } from '@utils/map/mapZoom.constants';
import { FillLayer, LineLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const ladCameraSectorLayer: FillLayer = {
  id: LayerNameEnum.LAD_CAMERA_SECTORS,
  type: 'fill',
  source: SourceNameEnum.LAD_CAMERAS,
  maxzoom: SHOW_LAD_CAMERA_STROBE_MAX_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.LAD_CAMERA_SECTOR],
  paint: {
    'fill-color': ['get', 'color'],
    'fill-outline-color': '#000000',
    'fill-opacity': POLYGONS_OPACITY,
  },
};

export const ladCameraSectorOutlineLayer: LineLayer = {
  id: LayerNameEnum.LAD_CAMERA_SECTOR_OUTLINES,
  type: 'line',
  source: SourceNameEnum.LAD_CAMERAS,
  maxzoom: SHOW_LAD_CAMERA_STROBE_MAX_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.LAD_CAMERA_SECTOR],
  paint: {
    'line-color': '#000000',
    'line-opacity': 1,
    'line-width': 2,
    'line-dasharray': [1, 1],
  },
};

export const ladCameraAzimuthLayer: LineLayer = {
  id: LayerNameEnum.LAD_CAMERA_AZIMUTHS,
  type: 'line',
  source: SourceNameEnum.LAD_CAMERAS,
  maxzoom: SHOW_LAD_CAMERA_STROBE_MAX_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.LAD_CAMERA_AZIMUTH],
  paint: {
    'line-color': '#000000',
    'line-width': 2,
    'line-dasharray': [1, 1],
  },
};
