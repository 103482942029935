import { ReactComponent as DroneIcon } from '@assets/icons/70x70/target/icSymb-drone-gy-L.svg';
import { ReactComponent as RemoteControlIcon } from '@assets/icons/70x70/target/icSymb-remoteControl-gy-L.svg';
import { ReactComponent as UnknownIcon } from '@assets/icons/70x70/target/icSymb-unknown-wh-L.svg';
import { getTargetIdentification } from '@utils/target.utils';
import { formatFrequencyWithUnit } from '@utils/unit.utils';
import { FunctionComponent, SVGProps } from 'react';

import { Target } from '@/types/c2/c2.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { RfData, StrobeLocation } from '@/types/sensor/sensor.types';

export function getFrequencyFromRfData(rfData?: RfData) {
  return rfData?.frequency ? formatFrequencyWithUnit(rfData.frequency) : null;
}

export function getStrobeIcon(target: Target<StrobeLocation>): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (getTargetIdentification(target)) {
    case IdentificationEnum.REMOTE_CONTROL:
      return RemoteControlIcon;
    case IdentificationEnum.DRONE:
      return DroneIcon;
    case IdentificationEnum.UNKNOWN:
    default:
      return UnknownIcon;
  }
}
