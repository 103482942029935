import { Button, Text, VStack } from '@chakra-ui/react';
import CustomRadioToggle from '@components/common/inputs/CustomRadioToggle';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole } from '@redux/authent/authent.selectors';
import { usePostXentaCommandMutation } from '@services/c2/c2.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import {
  XENTA_INITIAL_AZIMUTH,
  XentaCommand,
  XentaCommandEnum,
  XentaEmissionCommand,
  XentaRotationCommand,
  XentaStopRotationCommand,
  XentaSurveillanceModeEnum,
} from '@/types/c2/c2.types';
import {
  XentaCapabilityStatusTypeEnum,
  XentaMastMotionEnum,
  XentaSensorStatus,
  XentaTransmitStateEnum,
} from '@/types/sensor/status.types';

type Props = {
  xentaStatus: XentaSensorStatus | null;
};

export default function XentaCommands({ xentaStatus }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [postXentaCommand, { isLoading }] = usePostXentaCommandMutation();

  const xentaTransmitState =
    !!xentaStatus && xentaStatus.capabilities[XentaCapabilityStatusTypeEnum.TRANSMITTER_STATE]?.transmitState;

  const isXentaRotating = !!xentaStatus && xentaStatus.mastMotion === XentaMastMotionEnum.MOVING;
  const isXentaEmitting = !!xentaStatus && xentaTransmitState === XentaTransmitStateEnum.FULL_POWER;

  const [isRotating, setIsRotating] = useState<boolean>(isXentaRotating);
  const [isEmitting, setIsEmitting] = useState<boolean>(isXentaEmitting);

  const canDoCommand = useAppSelector((state) => hasRole(state, RoleEnum.SENSOR_COMMAND));

  if (!canDoCommand) {
    return null;
  }

  const isXentaEmissionUnknown = !xentaTransmitState || xentaTransmitState === XentaTransmitStateEnum.UNKNOWN;
  const isXentaRotationUnknown = !xentaStatus || xentaStatus.mastMotion === XentaMastMotionEnum.UNKNOWN;

  function handleEmissionRotation(sendEnableCommand: boolean, isEmissionButton: boolean) {
    if (xentaStatus !== null) {
      let command: XentaCommand;

      if (isEmissionButton) {
        // Gestion de l'émission
        command = {
          type: XentaCommandEnum.COMMAND_PSR_TX_ON,
          on: sendEnableCommand,
          sensorCode: xentaStatus.configuration.code,
        } as XentaEmissionCommand;
      } else if (sendEnableCommand) {
        command = {
          type: XentaCommandEnum.COMMAND_ANTENNA_SURVEILLANCE,
          mode: XentaSurveillanceModeEnum.PROFILE_1,
          sensorCode: xentaStatus.configuration.code,
        } as XentaRotationCommand;
      } else {
        command = {
          type: XentaCommandEnum.COMMAND_ANTENNA_STOP_AND_STARE_AZIMUTH,
          sensorCode: xentaStatus.configuration.code,
          azimuth: XENTA_INITIAL_AZIMUTH,
        } as XentaStopRotationCommand;
      }

      postXentaCommand({
        site: xentaStatus.configuration.site,
        appCode: xentaStatus.configuration.appCode,
        command,
      })
        .unwrap()
        .then(() =>
          createToast(
            formatMessage(
              { id: 'sensors.command.xenta.emissionOrRotationOnOffToast' },
              {
                enable: sendEnableCommand,
                isEmissionButton: isEmissionButton,
                success: true,
                name: xentaStatus.configuration.name,
              },
            ),
            ToastStatusEnum.SUCCESS,
          ),
        )
        .catch(() =>
          createToast(
            formatMessage(
              { id: 'sensors.command.xenta.emissionOrRotationOnOffToast' },
              {
                enable: sendEnableCommand,
                isEmissionButton: isEmissionButton,
                success: false,
                name: xentaStatus.configuration.name,
              },
            ),
            ToastStatusEnum.ERROR,
          ),
        );
    }
  }

  return (
    <>
      <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%" userSelect="none">
        <Text size="md">
          <FormattedMessage id="sensors.command.xenta.rotationDescription" />
        </Text>
        <VStack width="100%" gap={2}>
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.xenta.startRotation' })}
            isChecked={xentaStatus !== null && isRotating}
            isDisabled={xentaStatus === null}
            onClick={() => setIsRotating(true)}
          />
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.xenta.stopRotation' })}
            isChecked={xentaStatus !== null && !isRotating}
            isDisabled={xentaStatus === null}
            onClick={() => setIsRotating(false)}
          />
          <Button
            isDisabled={isXentaRotationUnknown || isRotating === isXentaRotating}
            isLoading={isLoading}
            variant="formButtonPrimary"
            height="48px"
            width="125px"
            onClick={() => handleEmissionRotation(isRotating, false)}
          >
            <FormattedMessage id="global.validate" />
          </Button>
        </VStack>
      </VStack>
      <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%" userSelect="none">
        <Text size="md">
          <FormattedMessage id="sensors.command.xenta.emissionDescription" />
        </Text>
        <VStack width="100%" gap={2}>
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.xenta.startEmitting' })}
            isChecked={xentaStatus !== null && isEmitting}
            isDisabled={xentaStatus === null}
            onClick={() => setIsEmitting(true)}
          />
          <CustomRadioToggle
            label={formatMessage({ id: 'sensors.command.xenta.stopEmitting' })}
            isChecked={xentaStatus !== null && !isEmitting}
            isDisabled={xentaStatus === null}
            onClick={() => setIsEmitting(false)}
          />
          <Button
            isDisabled={isXentaEmissionUnknown || isEmitting === isXentaEmitting}
            isLoading={isLoading}
            variant="formButtonPrimary"
            height="48px"
            width="125px"
            onClick={() => handleEmissionRotation(isEmitting, true)}
          >
            <FormattedMessage id="global.validate" />
          </Button>
        </VStack>
      </VStack>
    </>
  );
}
