import { SensorUniqueCodes } from '@/types/map.types';
import {
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import { SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import CabinetMaintenance from './CabinetMaintenance';
import HolodroneMaintenance from './HolodroneMaintenance';
import ZmerMaintenance from './ZmerMaintenance';

export enum MaintenanceVariant {
  POPUP = 'POPUP',
  MONITORING = 'MONITORING',
}

type SpecificMaintenanceProps = {
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  sensorUniqueCodes: SensorUniqueCodes;
  variant?: MaintenanceVariant;
  replayMode?: boolean;
};

export default function SpecificMaintenance({
  sensorStatus,
  sensorConfiguration,
  sensorUniqueCodes,
  variant = MaintenanceVariant.POPUP,
  replayMode = false,
}: Readonly<SpecificMaintenanceProps>) {
  switch (sensorConfiguration.type) {
    case SensorTypeEnum.ZMER: {
      if (sensorStatus !== null && sensorStatus.type !== SensorTypeEnum.ZMER) {
        return null;
      }
      return <ZmerMaintenance variant={variant} zmerStatus={sensorStatus} />;
    }
    case SensorTypeEnum.HOLODRONE: {
      return <HolodroneMaintenance variant={variant} holodroneConfiguration={sensorConfiguration} />;
    }
    case SubSensorTypeEnum.AUTOMATON_CABINET: {
      return <CabinetMaintenance sensorUniqueCodes={sensorUniqueCodes} replayMode={replayMode} variant={variant} />;
    }
    default:
      return null;
  }
}
