import { ReactComponent as CategoryIcon } from '@assets/components/panel/monitoring/category.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, Center, HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import {
  LadSensorCategoryEnum,
  PerimSensorCategoryEnum,
  sensorCategoryFromSensorType,
  TypeEnum,
} from '@utils/sensors/configuration.constants';
import { groupBy } from 'lodash';
import { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import { SubSensorTypeEnum } from '@/types/sensor/configuration.types';

import { configurationWithSensorUniqueCodes, SortEnum } from './MonitoringList';
import SensorCard from './SensorCard';

type Props = {
  filteredConfigurations: configurationWithSensorUniqueCodes[];
  ladCategories: Set<LadSensorCategoryEnum>;
  perimCategories: Set<PerimSensorCategoryEnum>;
  types: Set<TypeEnum>;
  setSelectedSensorCode: Dispatch<SensorUniqueCodes>;
};

function TypeList({
  filteredConfigurations,
  ladCategories,
  perimCategories,
  types,
  setSelectedSensorCode,
}: Readonly<Props>) {
  const groupedSensorsByType = groupBy(
    filteredConfigurations,
    (sensor) => sensorCategoryFromSensorType[sensor.configuration.type],
  );

  const missingLadCategory = types.has(TypeEnum.LAD)
    ? Object.values(LadSensorCategoryEnum).filter(
        (category: LadSensorCategoryEnum) =>
          !Object.keys(groupedSensorsByType).includes(category) && ladCategories.has(category),
      )
    : [];

  const missingPerimCategory = types.has(TypeEnum.PERIM)
    ? Object.values(PerimSensorCategoryEnum).filter(
        (category: PerimSensorCategoryEnum) =>
          !Object.keys(groupedSensorsByType).includes(category) && perimCategories.has(category),
      )
    : [];

  return (
    <VStack width="100%" alignItems="start" gap={2}>
      {Object.entries(groupedSensorsByType)
        .concat([...missingLadCategory, ...missingPerimCategory].map((category) => [category, []]))
        .sort(([key], [key2]) => key.localeCompare(key2))
        .map(([category, sensors], index) => (
          <HStack
            gap={2}
            alignItems="start"
            width="100%"
            key={category}
            marginBottom={
              index ===
              Object.entries(groupedSensorsByType).length + missingLadCategory.length + missingPerimCategory.length - 1
                ? 6
                : 0
            }
          >
            <Box position="relative">
              <Icon as={CategoryIcon} width="174px" height="88px" />
              <Text position="absolute" left="132px" top="45px" transform="translate(-50%, -50%)">
                {category}
              </Text>
            </Box>
            <HStack gap={2} flexWrap="wrap" width="100%">
              {sensors.length !== 0 ? (
                sensors.map((sensor) => (
                  <SensorCard
                    sensorConfiguration={sensor.configuration}
                    type={SortEnum.TYPE}
                    key={sensor.sensorUniqueCodes.sensorUniqueCode}
                    sensorUniqueCodes={sensor.sensorUniqueCodes}
                    onClick={
                      sensor.configuration.type !== SubSensorTypeEnum.AUTOMATON_SEGMENT
                        ? () => setSelectedSensorCode(sensor.sensorUniqueCodes)
                        : undefined
                    }
                  />
                ))
              ) : (
                <Center border="2px solid" width="100%" height="88px" borderColor="neutral.600">
                  <Text>
                    <FormattedMessage id="components.monitoring.type.noSensor" />
                  </Text>
                </Center>
              )}
            </HStack>
          </HStack>
        ))}
    </VStack>
  );
}

export default TypeList;
