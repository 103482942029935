//Order is important, it determines target layer priority on the map
export enum IdentificationEnum {
  DRONE = 'DRONE',
  REMOTE_CONTROL = 'REMOTE_CONTROL',
  AIRCRAFT = 'AIRCRAFT',
  UNKNOWN = 'UNKNOWN',
  BIRD = 'BIRD',
  SHIP = 'SHIP',
  PERSON = 'PERSON',
  GROUND_VEHICLE = 'GROUND_VEHICLE',
  HELICOPTER = 'HELICOPTER',
  BUILDING = 'BUILDING',
}

export enum StrobeIdentificationEnum {
  UNKNOWN = 'UNKNOWN',
  REMOTE_CONTROL = 'REMOTE_CONTROL',
  DRONE = 'DRONE',
}

export enum IdentificationOriginEnum {
  COMPUTED = 'COMPUTED',
  MANUAL = 'MANUAL',
}

export enum ReliabilityEnum {
  UNSURE = 'UNSURE',
  SURE = 'SURE',
}

export interface Identification {
  identifications: Partial<Record<IdentificationEnum, number>>;
  mainIdentification: IdentificationEnum;
  autoIdentification: IdentificationEnum;
  manualIdentification: IdentificationEnum | null;
  reliability: ReliabilityEnum;
}
