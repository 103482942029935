import { ReactComponent as SelectedValueContainer } from '@assets/components/slider/selected-value-container.svg';
import { ReactComponent as SliderThumbVertical } from '@assets/components/slider/slider-thumb-vertical.svg';
import { Flex } from '@chakra-ui/layout';
import {
  Center,
  Icon,
  Image,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { range } from 'lodash';
import { useState } from 'react';

type Props = {
  value: number;
};

function CustomSliderWithMark({
  id,
  min = 1,
  max = 10,
  step = 1,
  value,
  onChange = () => undefined,
}: Readonly<SliderProps & Props>) {
  const [innerValue, setInnerValue] = useState<number>(value);

  return (
    <Slider
      colorScheme="transparent"
      id={id}
      aria-label="slider-ex-3"
      value={innerValue}
      orientation="horizontal"
      width="100%"
      min={min}
      max={max}
      height="62px"
      padding="0 !important"
      onChange={(value) => setInnerValue(value)}
      onChangeEnd={(value) => onChange(value)}
    >
      <SliderTrack
        borderRadius="none"
        height="24px"
        backgroundColor="neutral.700"
        marginLeft="2px"
        bottom="0 !important"
        top="auto !important"
        transform="none !important"
      >
        <SliderFilledTrack backgroundColor="cyber.500" height="24px" />
      </SliderTrack>
      {range(min, max + 1, step).map((e) => (
        <SliderMark
          value={e}
          height="40px"
          key={`mark-${e}`}
          bottom={0}
          width="6px"
          transform="translateX(1px)"
          backgroundColor="neutral.600"
          border="2px solid"
          borderColor="neutral.700"
        >
          <Center position="absolute" top="-14px" transform="translateX(calc(-50% + 1px))">
            {value === e && <Icon position="absolute" width="24px" height="24px" as={SelectedValueContainer} />}
            <Text position="absolute">{e}</Text>
          </Center>
        </SliderMark>
      ))}
      <SliderThumb
        borderRadius={0}
        width="8px"
        top="auto !important"
        bottom={0}
        transform="translate(calc(50% + 3px), -50%) !important"
      >
        <Flex>
          <Image as={SliderThumbVertical} style={{ filter: 'drop-shadow( -4px 0px 4px rgba(0, 0, 0, 0.50)' }} />
        </Flex>
      </SliderThumb>
    </Slider>
  );
}

export default CustomSliderWithMark;
