import { CircleLayer } from 'mapbox-gl';
import { LineLayer, SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const lines: LineLayer = {
  id: LayerNameEnum.MEASURE_LINES,
  type: 'line',
  source: SourceNameEnum.MEASURES,
  filter: ['all', ['in', '$type', 'LineString'], ['==', 'featureType', FeatureTypeEnum.MEASURE]],
  paint: {
    'line-color': 'white',
    'line-width': 4,
    'line-opacity': 1,
  },
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
};

export const points: CircleLayer = {
  id: LayerNameEnum.MEASURE_POINTS,
  type: 'circle',
  source: SourceNameEnum.MEASURES,
  filter: ['all', ['in', '$type', 'Point'], ['==', 'featureType', FeatureTypeEnum.MEASURE]],
  paint: {
    'circle-color': 'white',
    'circle-radius': 10,
    'circle-stroke-color': 'black',
    'circle-stroke-width': 2,
  },
};

export const labels: SymbolLayer = {
  id: LayerNameEnum.MEASURE_POINT_LABELS,
  type: 'symbol',
  source: SourceNameEnum.MEASURES,
  filter: ['all', ['in', '$type', 'Point'], ['==', 'featureType', FeatureTypeEnum.MEASURE]],
  layout: {
    'text-field': '{index}',
    'text-size': 16,
    'text-offset': [0, 0.1],
    'text-anchor': 'center',
    'text-font': ['roboto'],
    'symbol-sort-key': ['*', -1, ['number', ['get', 'index']]],
  },
  paint: {
    'text-color': 'black',
  },
};
