import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const smContainer = defineStyle({
  paddingX: '1',
  paddingY: '0.25',
  border: '1px solid',
});

const mdContainer = defineStyle({
  paddingX: '2',
  paddingY: '0.25',
  height: '44px',
  border: '2px solid',
});

const sizes = {
  sm: definePartsStyle({ container: smContainer }),
  md: definePartsStyle({ container: mdContainer }),
};

export const tagTheme = defineMultiStyleConfig({ sizes, defaultProps: { size: 'sm' } });
