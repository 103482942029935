import { ReactComponent as CornerIcon } from '@assets/components/panel/monitoring/corner.svg';
import { ReactComponent as WorkIcon } from '@assets/icons/16x16/ic-works-16.svg';
import { Icon } from '@chakra-ui/icon';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Text, Tooltip } from '@chakra-ui/react';
import SensorIcon from '@components/map/infos/sensors/SensorIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSensorAndSubSensorStatusesByUniqueCode } from '@redux/situation/monitoring.selectors';
import { getSensorIconKey } from '@utils/map/sensor.utils';
import { getSensorColor } from '@utils/sensors/sensors.utils';
import { useIntl } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import { AutomatonSegment, SensorConfiguration, SubSensorConfiguration } from '@/types/sensor/configuration.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';

import { SortEnum } from './MonitoringList';

type Props = {
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration | AutomatonSegment;
  sensorUniqueCodes: SensorUniqueCodes;
  type: SortEnum;
  onClick?: () => void;
};

function SensorCard({ sensorConfiguration, type, sensorUniqueCodes, onClick }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const sensorStatus = useAppSelector((state) =>
    selectSensorAndSubSensorStatusesByUniqueCode(state, false, sensorUniqueCodes.sensorUniqueCode),
  );

  const statusColor = getSensorColor(sensorConfiguration, sensorStatus);

  const typeLabel = formatMessage({ id: `sensors.type.${sensorConfiguration.type}` });

  if (type === SortEnum.CRITICALITY) {
    return (
      <HStack
        gap={2}
        paddingX={2}
        height="88px"
        width="208px"
        position="relative"
        backgroundColor="neutral.600"
        justifyContent="start"
        cursor={onClick ? 'pointer' : 'default'}
        onClick={onClick}
      >
        <Icon as={CornerIcon} width="6px" height="6px" position="absolute" bottom={0} right={0} />
        <HStack gap={0} position="absolute" top="-8px" right={1}>
          {sensorConfiguration.maintenance && (
            <Center backgroundColor="alertMid.500" width="20px" height="20px">
              <Icon as={WorkIcon} />
            </Center>
          )}
        </HStack>
        <SensorIcon
          sensorIconName={getSensorIconKey(
            sensorConfiguration.type,
            sensorConfiguration.active,
            false,
            sensorConfiguration.maintenance ? SensorStatusEnum.DEGRADED : sensorStatus,
            true,
          )}
          size="sm"
        />
        <VStack gap={0.5} alignItems="start">
          <Tooltip placement="top" isDisabled={sensorConfiguration.name.length <= 14} label={sensorConfiguration.name}>
            <Text
              fontWeight="medium"
              backgroundColor="neutral.black"
              padding={0.25}
              fontSize="12px"
              noOfLines={1}
              wordBreak="break-all"
            >
              {sensorConfiguration.name}
            </Text>
          </Tooltip>
          <Text fontSize="12px" color="neutral.200" noOfLines={2}>
            {typeLabel}
          </Text>
        </VStack>
      </HStack>
    );
  }

  return (
    <HStack
      gap={0}
      height="88px"
      width="152px"
      position="relative"
      backgroundColor="neutral.600"
      borderLeft="8px solid"
      borderColor={statusColor}
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
    >
      <Icon as={CornerIcon} width="6px" height="6px" position="absolute" bottom={0} right={0} />
      <HStack gap={0} position="absolute" top="-8px" right={1}>
        {sensorConfiguration.maintenance && (
          <Center backgroundColor="alertMid.500" width="20px" height="20px">
            <Icon as={WorkIcon} width="16px" height="16px" />
          </Center>
        )}
      </HStack>
      <VStack paddingX={2} gap={0.5} alignItems="start">
        <Tooltip placement="top" isDisabled={sensorConfiguration.name.length <= 13} label={sensorConfiguration.name}>
          <Text
            fontWeight="medium"
            backgroundColor="neutral.black"
            padding={0.25}
            fontSize="12px"
            noOfLines={1}
            wordBreak="break-all"
          >
            {sensorConfiguration.name}
          </Text>
        </Tooltip>
        <Text fontSize="12px" color="neutral.200" noOfLines={2}>
          {typeLabel}
        </Text>
      </VStack>
    </HStack>
  );
}

export default SensorCard;
