import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const l = defineStyle({
  height: '68px',
  fontSize: '20px',
  fontWeight: 'medium',
  lineHeight: '25px',
  paddingY: '18px',
  paddingX: 4,
});

const m = defineStyle({
  height: '52px',
  fontSize: '18px',
  fontWeight: 'medium',
  lineHeight: '22.5px',
  paddingY: '12px',
  paddingX: 3,
});

const s = defineStyle({
  height: '36px',
  fontSize: '14px',
  fontWeight: 'regular',
  lineHeight: '17.5px',
  paddingY: 1,
  paddingX: 2,
});

const baseStyle = defineStyle({
  fontWeight: 'regular',
  fontSize: '16px',
  textColor: 'black',
  fontFamily: 'roboto',
  borderRadius: 0,
  _disabled: {
    cursor: 'default',
  },
});

const contextMenu = defineStyle({
  width: '100%',
  height: '48px',
  padding: 1.5,
  borderRadius: 0,
  border: '2px solid transparent',
  color: 'neutral.white',
  fontFamily: 'roboto',
  fontSize: '16px',
  fontWeight: 'regular',
  backgroundColor: 'neutral.800',
  boxShadow: 'none',
  justifyContent: 'start',
  _active: {
    backgroundColor: 'cyber.500',
    borderColor: 'cyber.500',
  },
  _disabled: {
    opacity: 1,
    color: 'neutral.500',
    cursor: 'default',
  },
  _focusVisible: {
    boxShadow: 'none',
  },
  _hover: {
    boxShadow: 'inset 0 0 0 2px var(--chakra-colors-sky-500)',
    backgroundColor: 'neutral.700',
    borderColor: 'neutral.700',
    _disabled: {
      boxShadow: 'none',
      background: 'neutral.800',
      borderColor: 'neutral.800',
    },
  },
});

const formButtonPrimary = defineStyle({
  width: '100%',
  rounded: 'none',
  color: 'neutral.white',
  backgroundColor: 'cyber.600',
  fontFamily: 'space',
  fontFeatureSettings: "'liga' off",
  border: '2px solid',
  borderRadius: 0,
  borderColor: 'cyber.700',
  boxShadow: 'inset 0 0 0 2px var(--chakra-colors-neutral-900)',
  _hover: {
    backgroundColor: 'cyber.500',
    _disabled: {
      backgroundColor: 'neutral.900-op30',
    },
  },
  _disabled: {
    cursor: 'default',
    color: 'neutral.500',
    backgroundColor: 'neutral.900-op30',
    borderColor: 'storm.600-op30',
    opacity: 1,
  },
});

const formButtonSecondary = defineStyle({
  width: '100%',
  rounded: 'none',
  color: 'neutral.white',
  backgroundColor: 'cyber.700',
  fontFamily: 'space',
  fontFeatureSettings: "'liga' off",
  border: '2px solid',
  borderRadius: 0,
  borderColor: 'cyber.700',
  boxShadow: 'inset 0 0 0 2px var(--chakra-colors-neutral-900)',
  _hover: {
    backgroundColor: 'cyber.500',
    _disabled: {
      backgroundColor: 'neutral.900-op30',
    },
  },
  _disabled: {
    cursor: 'default',
    color: 'neutral.500',
    backgroundColor: 'neutral.900-op30',
    borderColor: 'storm.600-op30',
    opacity: 1,
  },
});

const player = defineStyle({
  height: '72px',
  width: '72px',
  border: '2px solid',
  borderColor: 'neutral.800',
  background: 'neutral.900',
  color: 'neutral.300',
  padding: 2,
  _hover: {
    borderColor: 'sky.500',
    background: 'neutral.800',
    color: 'sky.500',
    _disabled: {
      borderColor: 'neutral.800',
      background: 'neutral.900',
      color: 'neutral.300',
    },
  },
  _disabled: {
    cursor: 'default',
  },
  _active: {
    background: 'cyber.500',
    color: 'neutral.white',
    _hover: {
      borderColor: 'sky.500',
    },
  },
  _pressed: {
    background: 'cyber.600',
    color: 'neutral.white',
  },
});

const smallPlayer = defineStyle({
  ...player,
  height: '48px',
  width: '48px',
});

const positionPicker = defineStyle({
  border: '2px solid',
  borderRadius: 0,
  borderColor: 'neutral.black',
  background: 'neutral.800',
  color: 'neutral.300',
  _hover: {
    borderColor: 'sky.500',
  },
  _active: {
    borderColor: 'sky.500',
    color: 'neutral.white',
  },
});

const calendar = defineStyle({
  height: '44px',
  width: '44px',
  border: '2px solid',
  borderColor: 'neutral.800',
  backgroundColor: 'neutral.black',
  color: 'neutral.300',
  _hover: {
    borderColor: 'sky.500',
    backgroundColor: 'neutral.800',
    color: 'neutral.200',
  },
  _active: {
    border: '0',
    backgroundColor: 'cyber.500',
    color: 'neutral.white',
  },
});

const replayTimelineBackwardForward = defineStyle({
  height: '110px',
  width: '48px',
  backgroundColor: 'neutral.900',
  color: 'neutral.white',
  _hover: {
    backgroundColor: 'neutral.700',
    _disabled: {
      backgroundColor: 'neutral.900',
    },
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: {
    contextMenu,
    formButtonPrimary,
    formButtonSecondary,
    player,
    smallPlayer,
    positionPicker,
    calendar,
    replayTimelineBackwardForward,
  },
  sizes: { l, m, s },
});
