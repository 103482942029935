import { useAppSelector } from '@hooks/redux.hooks';
import { hasAnyRoles } from '@redux/authent/authent.selectors';
import { RootState } from '@redux/store';

import { RoleEnum } from '@/types/authent/roles.types';

import ScreenConfiguration from './ScreenConfiguration';
import ScreenConfigurationAdmin from './ScreenConfigurationAdmin';

export default function ScreenConfigurationWrapper() {
  const isAdministrator = useAppSelector((state: RootState) =>
    hasAnyRoles(state, [
      RoleEnum.EDIT_GLOBAL_SCREEN_CONFIGURATION,
      RoleEnum.CREATE_GLOBAL_SCREEN_CONFIGURATION,
      RoleEnum.DELETE_GLOBAL_SCREEN_CONFIGURATION,
    ]),
  );

  if (isAdministrator) {
    return <ScreenConfigurationAdmin />;
  } else {
    return <ScreenConfiguration />;
  }
}
