import { Box, HStack, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import VideoDownloadProgress from '@components/replay/videoExport/VideoDownloadProgress';
import { useAuth } from '@hooks/useAuth';
import { C2_URL } from '@services/c2/c2.api';
import { formatDate } from '@utils/date.utils';
import { getFileNameFromHttpResponse } from '@utils/file.utils';
import React, { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Site } from '@/types/authent/sites.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { MapIdEnum } from '@/types/map.types';
import { SubCameraLiteConfiguration } from '@/types/sensor/configuration.types';

interface VideoExportSubmitProps {
  isFormValid: boolean;
  activeSite: Site | null;
  dateFrom: Date;
  dateTo: Date;
  subCamerasToSend: SubCameraLiteConfiguration[];
  isFetching: boolean;
  onPopupClose: () => { payload: MapIdEnum; type: 'maps/closePopupByMapId' };
}

function VideoExportSubmit({
  isFormValid,
  activeSite,
  dateFrom,
  dateTo,
  subCamerasToSend,
  isFetching,
  onPopupClose,
}: Readonly<VideoExportSubmitProps>) {
  const { token } = useAuth();
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [receivedDownloadLength, setReceivedDownloadLength] = useState<number>(0);
  const [totalDownloadLength, setTotalDownloadLength] = useState<number | null>(null);
  const [remainingDownloadTime, setRemainingDownloadTime] = useState<number | null>(null);
  const [requestError, setRequestError] = useState<string | null>(null);

  const { formatMessage } = useIntl();

  const downloadZipResponse = async (from: Date, to: Date, subCameras: SubCameraLiteConfiguration[]) => {
    const controller: AbortController = new AbortController();
    setAbortController(controller);

    setIsDownloadSuccess(false);
    setReceivedDownloadLength(0);
    setTotalDownloadLength(null);
    setRemainingDownloadTime(null);
    setIsDownloadLoading(true);

    try {
      if (token == null) {
        throw new Error('Failed to retrieve the authentification token');
      }
      const url = `${C2_URL}/sensor/replay/videos?from=${from.getTime()}&to=${to.getTime()}`;
      const response = await fetch(url, {
        method: 'post',
        signal: controller.signal,
        headers: {
          Authorization: token,
          'Cache-Control': 'no-cache',
          Connection: 'keep-alive',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(subCameras),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message;
        throw new Error(errorMessage);
      }
      if (!response.body) {
        return;
      }
      const reader = response.body.getReader();

      const contentLength = response.headers.get('Content-Length');
      if (contentLength !== null) {
        setTotalDownloadLength(parseInt(contentLength, 10));
      }

      const startTime = performance.now();
      const chunks: Uint8Array[] = [];
      let result;
      let receivedLength = 0;

      do {
        result = await reader.read();
        const { done, value } = result;

        if (!done) {
          setIsDownloadSuccess(false);

          chunks.push(value);
          receivedLength += value.length;
          setReceivedDownloadLength(receivedLength);

          const currentTime = performance.now();
          const elapsedTime = (currentTime - startTime) / 1000;
          const downloadSpeed = receivedLength / elapsedTime;

          if (contentLength !== null) {
            const remainingTime = (parseInt(contentLength, 10) - receivedLength) / downloadSpeed;
            setRemainingDownloadTime(remainingTime);
          }
        }
      } while (!result.done);

      const blob = new Blob(chunks);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);

      const alternateFileName = `ReplayVideos_${formatDate(
        dateFrom,
        DateTimeEnum.DATE_TIME_SECONDS_ALTERNATE,
      )}_${formatDate(dateTo, DateTimeEnum.DATE_TIME_SECONDS_ALTERNATE)}_${activeSite?.name ?? 'unknownSite'}.zip`;
      downloadLink.download = getFileNameFromHttpResponse(response, alternateFileName);

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setIsDownloadSuccess(true);
    } catch (error) {
      if (error.name !== 'AbortError') {
        setRequestError(error.message || formatMessage({ id: 'videoExport.unknownError' }));
        //La valeur de requestError pourra être utilisée si on veut afficher un code d'erreur plus précis au front
        console.error(`Backend error when downloading videos : ${error.message}`);
      }
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const handleSubmit = () => {
    setRequestError(null);
    downloadZipResponse(dateFrom, dateTo, subCamerasToSend);
  };

  const handleCancel = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
    onPopupClose();
  };

  const buttonMarginLeft = '70px';
  return (
    <Box>
      <Box marginLeft={buttonMarginLeft}>
        {isDownloadLoading && (
          <VideoDownloadProgress
            receivedDataSize={receivedDownloadLength}
            totalDataSize={totalDownloadLength}
            remainingDownloadTime={remainingDownloadTime}
          />
        )}
        {isDownloadSuccess && (
          <Text>
            <strong>
              <FormattedMessage id="videoExport.exportFinished" />
            </strong>
            <br />
            100% <FormattedMessage id="global.done" /> -{' '}
            {totalDownloadLength == null ? '?' : (totalDownloadLength / 1000000).toFixed(0)}Mo
            <br />
            <FormattedMessage id="videoExport.downloadSuccess" />
          </Text>
        )}

        {requestError !== null && (
          <Text>
            <strong>
              !! <FormattedMessage id="global.error" /> !! :
            </strong>
            <br />
            <FormattedMessage id="videoExport.errorOnExport" />,
            <br />
            <FormattedMessage id="videoExport.contactAdmin" />
          </Text>
        )}
      </Box>
      <HStack alignItems="center" marginLeft={buttonMarginLeft} marginTop={2} gap="30px" marginBottom="20px">
        <Button type="button" onClick={() => handleCancel()} width="135px" height="40px">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          width="248px"
          height="40px"
          onClick={() => handleSubmit()}
          isDisabled={!isFormValid || subCamerasToSend.length == 0 || isDownloadLoading || isFetching}
        >
          <FormattedMessage id="videoExport.submit" />
        </Button>
      </HStack>
    </Box>
  );
}

export default memo(VideoExportSubmit);
