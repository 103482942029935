import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole } from '@redux/authent/authent.selectors';
import { usePostAutomatonCommandMutation } from '@services/c2/c2.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import {
  AutomatonCabinetDefenceCommand,
  AutomatonCommandEnum,
  AutomatonReinitializeCommand,
  AutomatonSleepCommand,
} from '@/types/c2/c2.types';
import { AutomatonCabinet, AutomatonConfiguration } from '@/types/sensor/configuration.types';

export interface UseCabinetCommandParameters {
  parentSensorConfiguration: AutomatonConfiguration | null;
  cabinetConfiguration: AutomatonCabinet | null;
}

export interface UseCabinetCommandFunctions {
  canCommand: boolean;
  canRepulse: boolean;
  canDetect: boolean;
  sleep: (sleep: boolean) => void;
  repulse: (enableRepulsion: boolean) => void;
  reinitialize: () => void;
  isSleepCommandLoading: boolean;
  isRepulsionCommandLoading: boolean;
  isReinitializationCommandLoading: boolean;
}

export const useCabinetCommand: (params: UseCabinetCommandParameters) => UseCabinetCommandFunctions = ({
  parentSensorConfiguration,
  cabinetConfiguration,
}: UseCabinetCommandParameters) => {
  const { formatMessage } = useIntl();
  const canSendPerimeterCommands = useAppSelector((state) => hasRole(state, RoleEnum.PERI_SENSOR_COMMAND));
  const canSendRepulsionCommand = useAppSelector((state) => hasRole(state, RoleEnum.AUTOMATON_REPULSION_COMMAND));
  const canSendDetectionCommand = useAppSelector((state) => hasRole(state, RoleEnum.AUTOMATON_DETECTION_COMMAND));

  const [postSleepCommand, { isLoading: isSleepLoading }] = usePostAutomatonCommandMutation();
  const [postRepulsionCommand, { isLoading: isRepulsionLoading }] = usePostAutomatonCommandMutation();
  const [postReinitCommand, { isLoading: isReinitLoading }] = usePostAutomatonCommandMutation();

  const handleCabinetPower = (sleep: boolean) => {
    if (parentSensorConfiguration !== null && cabinetConfiguration !== null) {
      const command: AutomatonSleepCommand = {
        type: AutomatonCommandEnum.AUTOMATON_COMMAND_SLEEP,
        sensorCode: parentSensorConfiguration.code,
        cabinetId: cabinetConfiguration.subSensorId,
        sleep: sleep,
      };
      postSleepCommand({
        site: parentSensorConfiguration.site,
        appCode: parentSensorConfiguration.appCode,
        command,
      })
        .unwrap()
        .then(() =>
          createToast(
            formatMessage({ id: 'contextmenu.actions.cabinet.sleep.toast' }, { sleep: sleep, success: true }),
            ToastStatusEnum.SUCCESS,
          ),
        )
        .catch(() =>
          createToast(
            formatMessage({ id: 'contextmenu.actions.cabinet.sleep.toast' }, { sleep: sleep, success: false }),
            ToastStatusEnum.ERROR,
          ),
        );
    }
  };

  const handleCabinetRepulsion = (enableRepulsion: boolean) => {
    if (parentSensorConfiguration !== null && cabinetConfiguration !== null) {
      const command: AutomatonCabinetDefenceCommand = {
        type: AutomatonCommandEnum.AUTOMATON_CABINET_COMMAND_DEFENCE,
        sensorCode: parentSensorConfiguration.code,
        cabinetId: cabinetConfiguration.subSensorId,
        defence: enableRepulsion,
      };
      postRepulsionCommand({
        site: parentSensorConfiguration.site,
        appCode: parentSensorConfiguration.appCode,
        command,
      })
        .unwrap()
        .then(() =>
          createToast(
            formatMessage(
              { id: 'contextmenu.actions.cabinet.defence.toast' },
              {
                repulsion: enableRepulsion,
                success: true,
              },
            ),
            ToastStatusEnum.SUCCESS,
          ),
        )
        .catch(() =>
          createToast(
            formatMessage(
              { id: 'contextmenu.actions.cabinet.defence.toast' },
              {
                repulsion: enableRepulsion,
                success: false,
              },
            ),
            ToastStatusEnum.ERROR,
          ),
        );
    }
  };

  const handleReinitCabinet = () => {
    if (parentSensorConfiguration !== null && cabinetConfiguration !== null) {
      const command: AutomatonReinitializeCommand = {
        type: AutomatonCommandEnum.AUTOMATON_COMMAND_REINITIALIZE,
        sensorCode: parentSensorConfiguration.code,
        cabinetId: cabinetConfiguration.subSensorId,
        reinitialize: true,
      };
      postReinitCommand({
        site: parentSensorConfiguration.site,
        appCode: parentSensorConfiguration.appCode,
        command,
      })
        .unwrap()
        .then(() =>
          createToast(formatMessage({ id: 'sensors.command.cabinet.reinitializeSuccess' }), ToastStatusEnum.SUCCESS),
        )
        .catch(() =>
          createToast(formatMessage({ id: 'sensors.command.cabinet.reinitializeFail' }), ToastStatusEnum.ERROR),
        );
    }
  };

  return {
    canCommand: canSendPerimeterCommands,
    canDetect: canSendDetectionCommand,
    canRepulse: canSendRepulsionCommand,
    isRepulsionCommandLoading: isRepulsionLoading,
    isSleepCommandLoading: isSleepLoading,
    isReinitializationCommandLoading: isReinitLoading,
    repulse: handleCabinetRepulsion,
    sleep: handleCabinetPower,
    reinitialize: handleReinitCabinet,
  };
};
