import { Center } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

type Props = {
  count?: number;
};

export default function Badge({ count }: Readonly<Props>) {
  if (count !== undefined) {
    if (count === 0) {
      return null;
    }
    return (
      <Center
        position="absolute"
        backgroundColor="alertHi.500"
        height="20px"
        paddingX={0.25}
        transform="translateX(calc(20px + 50%))"
      >
        <Text paddingX={0.5} fontSize="12px" fontWeight="medium">
          {count}
        </Text>
      </Center>
    );
  }
  return (
    <Center
      position="absolute"
      backgroundColor="alertHi.500"
      height="15px"
      width="15px"
      borderRadius="50%"
      transform="translate(20px, -100%)"
    ></Center>
  );
}
