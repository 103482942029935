import * as Yup from 'yup';

import { Coordinates } from '@/types/commons/commons.types';

import { validateCoordinates } from './coordinates.utils';
import { validateEmail } from './validation.utils';

//Extend number schema
Yup.addMethod(Yup.ObjectSchema<Coordinates>, 'validCoordinates', () => validateCoordinates);

//Extend string schema
Yup.addMethod(Yup.StringSchema, 'validEmail', () => validateEmail);

declare module 'yup' {
  interface StringSchema {
    validEmail(): StringSchema;
  }
  interface ObjectSchema<
    TIn extends Yup.Maybe<Yup.AnyObject>,
    TContext = Yup.AnyObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TDefault = any,
    TFlags extends Yup.Flags = '',
  > {
    validCoordinates(): ObjectSchema<TIn, TContext, TDefault, TFlags>;
  }
}

export default Yup;
