/* eslint-disable @typescript-eslint/no-empty-function */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { toFeature } from '@utils/validation/coordinates.utils';
import { useEffect } from 'react';
import { type ControlPosition, IControl, MapInstance, useControl } from 'react-map-gl';

import { ZoneToEditType } from '@/types/config/config.types';
import { MapBoxDrawEvent } from '@/types/map.types';

type DrawPolygonControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  polyToEdit?: ZoneToEditType;

  onCreate?: (evt: { features: MapBoxDrawEvent[] }) => void;
  onUpdate?: (evt: { features: MapBoxDrawEvent[]; action: string }) => void;
};

type MapboxDrawControl = MapboxDraw & IControl<MapInstance>;

export function DrawPolygonControl({
  position,
  polyToEdit,
  onCreate = () => {},
  onUpdate = () => {},
  ...props
}: DrawPolygonControlProps) {
  const drawInstance = new MapboxDraw(props) as MapboxDrawControl;

  useControl<MapboxDrawControl>(
    () => drawInstance,
    ({ map }) => {
      map.on('draw.create', onCreate);
      map.on('draw.update', onUpdate);
    },
    ({ map }) => {
      map.off('draw.create', onCreate);
      map.off('draw.update', onUpdate);
    },
    {
      position: position,
    },
  );

  // trigger after useControl, since useControl allocates drawInstance to map.
  useEffect(() => {
    if (polyToEdit) {
      drawInstance.deleteAll();
      drawInstance.add(toFeature(polyToEdit.polygon, polyToEdit.id));
      drawInstance.changeMode('simple_select', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polyToEdit]);

  return null;
}
