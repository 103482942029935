import { ReactComponent as IcConfigInstall } from '@assets/icons/40x40/ic-configInstall-40.svg';
import { ReactComponent as IcMap } from '@assets/icons/40x40/mapMenu/ic-map-40.svg';
import { ReactComponent as IcMeasure } from '@assets/icons/40x40/mapMenu/ic-measure-40.svg';
//import {ReactComponent as IcFP} from '@assets/icons/40x40/mapMenu/ic-pdv-40.svg';
import { ReactComponent as IcZone } from '@assets/icons/40x40/mapMenu/ic-zone-40.svg';
import { ReactComponent as IcZoneInfo } from '@assets/icons/40x40/mapMenu/ic-zoneInfo-40.svg';
import { HStack } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';
import { MenuIconBar } from '@components/common/menu/MenuIconBar';
import { useMapContext } from '@components/map/MapContext';
import PoiFormPopup from '@components/map/popup/PoiFormPopup';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import {
  closePopupByMapId,
  switchDrawModeByMapId,
  updateDrawZoneTypeSelected,
  updateMenuNavigationStatus,
  updatePopupControlByMapId,
} from '@redux/maps/maps.reducer';
import {
  selectMapDrawModeByMapId,
  selectMapNavigationOptions,
  selectPopupControlByMapId,
} from '@redux/maps/maps.selectors';
import { usePatchMapBackgroundMutation } from '@services/config/setting.api';
import { memo, ReactElement, useCallback, useState } from 'react';

import { SEE_POI, Zone2DTypeEnum, Zone3DTypeEnum } from '@/types/config/config.types';
import { PopupDataTypeEnum } from '@/types/map.types';
import { MenuItemOption, MenuItemOptionIcons, MenuItemsIcons } from '@/types/menu.types';

import FiltersMenu from './filters/FiltersMenu';

function ToolMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useAppSelector(selectActiveUser);
  const [updateMapBackground] = usePatchMapBackgroundMutation();
  const { mapId } = useMapContext();
  const isMapDrawModeOn = useSelectorWithMapId(selectMapDrawModeByMapId);
  const dispatchSwitchDrawModeByMapId = useWithDispatch(switchDrawModeByMapId);
  const selectedOptions = useSelectorWithMapId(selectMapNavigationOptions) ?? [];
  const dispatchSelectedOptions = useWithDispatch(updateMenuNavigationStatus);
  const dispatchZoneType = useWithDispatch(updateDrawZoneTypeSelected);
  const [componentToOpen, setComponentToOpen] = useState<ReactElement | undefined>();

  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const closePopupControl = useWithDispatch(closePopupByMapId);
  const isMeasureActive = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);

  const enableMeasure = useCallback(() => {
    updatePopupControl({
      mapId: mapId,
      popupControl: {
        type: PopupDataTypeEnum.MEASURE_INFO,
        open: true,
        position: { x: 0, y: 0 },
        data: null,
      },
    });
  }, [updatePopupControl, mapId]);

  const handleClick = (option: string, value: MenuItemOptionIcons | MenuItemOption | undefined, depth: number) => {
    let temp = structuredClone(selectedOptions).slice(0, depth + 1);
    onClose();
    if (isMapDrawModeOn) {
      dispatchSwitchDrawModeByMapId({ mapId, isDrawable: false });
    }
    // click on same place => revert navigation
    if (selectedOptions[depth] === option) {
      if (option === 'azimuthAndDistance' && isMeasureActive) {
        closePopupControl(mapId);
      }
      dispatchSelectedOptions({ mapId, selectedOptions: temp.slice(0, depth) });
    } else {
      // else forward navigation
      temp = temp.toSpliced(depth, 1, option);

      if (isMeasureActive && temp.includes('measure') && !temp.includes('azimuthAndDistance')) {
        temp = temp.concat('azimuthAndDistance');
      }

      dispatchSelectedOptions({ mapId, selectedOptions: temp });
      // trigger all potential action linked to menu btn
      if (value?.onAction) {
        value.onAction();
      }
    }
  };

  const handleMapBackgroundChanges = (mapBackground: string) => {
    if (mapBackground) {
      updateMapBackground({ login: user.login, mapBackground });
    }
  };

  const handleZoneCreateClick = (zoneType: Zone2DTypeEnum | Zone3DTypeEnum | undefined) => {
    if (isMeasureActive) {
      closePopupControl(mapId);
    }
    dispatchSwitchDrawModeByMapId({ mapId, isDrawable: true });
    dispatchZoneType({ mapId, zoneType });
  };

  const handleOnOpen = (component: ReactElement) => {
    onOpen();
    setComponentToOpen(component);
  };

  const handleMenuClose = () => {
    onClose();
    dispatchSelectedOptions({ mapId, selectedOptions: [] });
  };

  const menuItems: MenuItemsIcons = {
    maps: {
      label: 'controls.toolMenu.maps.maps',
      icon: IcMap,
      children: {
        plan: {
          label: 'controls.toolMenu.maps.plan',
          onAction: () => handleMapBackgroundChanges('plan'),
        },
        darkRoad: {
          label: 'controls.toolMenu.maps.darkRoad',
          onAction: () => handleMapBackgroundChanges('darkRoad'),
        },
        lightRoad: {
          label: 'controls.toolMenu.maps.lightRoad',
          onAction: () => handleMapBackgroundChanges('lightRoad'),
        },
        satellite: {
          label: 'controls.toolMenu.maps.satellite',
          onAction: () => handleMapBackgroundChanges('satellite'),
        },
      },
    },
    zones: {
      label: 'controls.toolMenu.zones.zones',
      icon: IcZone,
      children: {
        criticality: {
          label: 'controls.toolMenu.zones.criticality.criticality',
          children: {
            observation: {
              label: 'controls.toolMenu.zones.criticality.observation',
              children: {
                twoDimension: {
                  label: 'controls.toolMenu.zones.twoDimension',
                  children: {
                    [`see_${Zone2DTypeEnum.NFZ_INFO}`]: {
                      label: 'controls.toolMenu.zones.interact.see',
                    },
                    create: {
                      label: 'controls.toolMenu.zones.interact.create',
                      onAction: () => handleZoneCreateClick(Zone2DTypeEnum.NFZ_INFO),
                    },
                  },
                },
                threeDimension: {
                  label: 'controls.toolMenu.zones.threeDimension',
                  children: {
                    [`see_${Zone3DTypeEnum.NFZ_INFO_3D}`]: {
                      label: 'controls.toolMenu.zones.interact.see',
                    },
                    create: {
                      label: 'controls.toolMenu.zones.interact.create',
                      isDisabled: true, // On n'a pas encore le formulaire de zones 3d
                    },
                  },
                },
              },
            },
            vigilance: {
              label: 'controls.toolMenu.zones.criticality.vigilance',
              children: {
                twoDimension: {
                  label: 'controls.toolMenu.zones.twoDimension',
                  children: {
                    [`see_${Zone2DTypeEnum.NFZ_WARNING}`]: {
                      label: 'controls.toolMenu.zones.interact.see',
                    },
                    create: {
                      label: 'controls.toolMenu.zones.interact.create',
                      onAction: () => handleZoneCreateClick(Zone2DTypeEnum.NFZ_WARNING),
                    },
                  },
                },
                threeDimension: {
                  label: 'controls.toolMenu.zones.threeDimension',
                  children: {
                    [`see_${Zone3DTypeEnum.NFZ_WARNING_3D}`]: {
                      label: 'controls.toolMenu.zones.interact.see',
                    },
                    create: {
                      label: 'controls.toolMenu.zones.interact.create',
                      isDisabled: true, // On n'a pas encore le formulaire de zones 3d
                    },
                  },
                },
              },
            },
            instantDanger: {
              label: 'controls.toolMenu.zones.criticality.instantDanger',
              children: {
                twoDimension: {
                  label: 'controls.toolMenu.zones.twoDimension',
                  children: {
                    [`see_${Zone2DTypeEnum.NFZ_CRITICAL}`]: {
                      label: 'controls.toolMenu.zones.interact.see',
                    },
                    create: {
                      label: 'controls.toolMenu.zones.interact.create',
                      onAction: () => handleZoneCreateClick(Zone2DTypeEnum.NFZ_CRITICAL),
                    },
                  },
                },
                threeDimension: {
                  label: 'controls.toolMenu.zones.threeDimension',
                  children: {
                    [`see_${Zone3DTypeEnum.NFZ_CRITICAL_3D}`]: {
                      label: 'controls.toolMenu.zones.interact.see',
                    },
                    create: {
                      label: 'controls.toolMenu.zones.interact.create',
                      isDisabled: true, // On n'a pas encore le formulaire de zones 3d
                    },
                  },
                },
              },
            },
          },
        },
        system: {
          label: 'controls.toolMenu.zones.system.system',
          children: {
            niz: {
              label: 'controls.toolMenu.zones.system.niz',
              children: {
                [`see_${Zone2DTypeEnum.NIZ}`]: {
                  label: 'controls.toolMenu.zones.interact.see',
                },
                create: {
                  label: 'controls.toolMenu.zones.interact.create',
                  onAction: () => handleZoneCreateClick(Zone2DTypeEnum.NIZ),
                },
              },
            },
            maskingZone: {
              label: 'controls.toolMenu.zones.system.maskingZone',
              children: {
                [`see_${Zone2DTypeEnum.IMZ}`]: {
                  label: 'controls.toolMenu.zones.interact.see',
                },
                create: {
                  label: 'controls.toolMenu.zones.interact.create',
                  onAction: () => handleZoneCreateClick(Zone2DTypeEnum.IMZ),
                },
              },
            },
            /*criticalSites: {
              label: 'controls.toolMenu.zones.system.criticalSites',
              children: {
                see: {
                  label: 'controls.toolMenu.zones.interact.see',
                },
                create: {
                  label: 'controls.toolMenu.zones.interact.create',
                  //onAction: () => handleZoneCreateClick(undefined),
                },
              },
            },*/
          },
        },
        /*personalized: {
          label: 'controls.toolMenu.zones.personalized.personalized',
          children: {
            segments: {
              label: 'controls.toolMenu.zones.personalized.segments',
              children: {
                see: {
                  label: 'controls.toolMenu.zones.interact.see',
                },
                create: {
                  label: 'controls.toolMenu.zones.interact.create',
                  //onAction: () => handleZoneCreateClick(undefined),
                },
              },
            },
            polygon: {
              label: 'controls.toolMenu.zones.personalized.polygon',
              children: {
                see: {
                  label: 'controls.toolMenu.zones.interact.see',
                },
                create: {
                  label: 'controls.toolMenu.zones.interact.create',
                  //onAction: () => handleZoneCreateClick(undefined),
                },
              },
            },
            circle: {
              label: 'controls.toolMenu.zones.personalized.circle',
              children: {
                see: {
                  label: 'controls.toolMenu.zones.interact.see',
                },
                create: {
                  label: 'controls.toolMenu.zones.interact.create',
                  //onAction: () => handleZoneCreateClick(undefined),
                },
              },
            },
          },
        },*/
      },
    },
    /*flightplan: {
      label: 'controls.toolMenu.flightplan.flightplan',
      icon: IcFP,
      children: {
        ongoingFp: {
          label: 'controls.toolMenu.flightplan.ongoingFp',
        },
        plannedFp: {
          label: 'controls.toolMenu.flightplan.plannedFp',
        },
      },
    },*/
    info: {
      label: 'controls.toolMenu.info.info',
      icon: IcZoneInfo,
      children: {
        /*aeronautics: {
          label: 'controls.toolMenu.info.aeronautics',
        },*/
        zoi: {
          label: 'controls.toolMenu.info.zoi',
          isDisabled: true,
        },
        poi: {
          label: 'controls.toolMenu.info.poi',
          children: {
            [SEE_POI]: {
              label: 'controls.toolMenu.info.seePoi',
            },
            create: {
              label: 'controls.toolMenu.info.createPoi',
              onAction: () => handleOnOpen(<PoiFormPopup onClose={handleMenuClose} />),
            },
          },
        },
      },
    },
    /*
    grids: {
      label: 'controls.toolMenu.grids',
      icon: IcConfigInstall,
    },*/
    filters: {
      label: 'controls.toolMenu.filters',
      icon: IcConfigInstall,
      onAction: () => handleOnOpen(<FiltersMenu onClose={handleMenuClose} />),
    },
    measure: {
      label: 'controls.toolMenu.measure.measure',
      icon: IcMeasure,
      children: {
        azimuthAndDistance: {
          label: 'controls.toolMenu.measure.azimuthAndDistance.label',
          onAction: () => enableMeasure(),
        },
        /*angle: {
          label: 'controls.toolMenu.measure.angle',
        },
        area: {
          label: 'controls.toolMenu.measure.area',
        },
        circle: {
          label: 'controls.toolMenu.measure.circle',
        },*/
      },
    },
  };

  return (
    <HStack gap={0} marginTop="76px" alignItems="flex-start">
      <MenuIconBar
        menuItems={menuItems}
        depth={0}
        selectedOptions={selectedOptions}
        handleClick={handleClick}
        isCompOpen={isOpen}
        compToOpen={componentToOpen}
      />
    </HStack>
  );
}

export default memo(ToolMenu);
