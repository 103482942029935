import { ReactComponent as PositionIcon } from '@assets/icons/24x24/ic-site-24.svg';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { Button, Icon, IconButton, Input } from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import GPSCoordinateInput from '@components/common/inputs/coordinatesInputs/GPSCoordinatesInput';
import CustomSelect, { Option } from '@components/common/inputs/CustomStyledSelect';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { useCreatePointsOfInterestMutation } from '@services/config/pointOfInterest.api';
import { eventColor } from '@utils/replay/event.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { Field, Formik } from 'formik';
import { FaCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { poiSchema } from 'src/validations/poi.schema';
import { v4 as uuidv4 } from 'uuid';

import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { EventTypeEnum } from '@/types/replay/replay.types';

import DraggableControl from '../controls/DraggableControl';
import { useMapContext } from '../MapContext';

type Props = {
  onClose: () => void;
};

export default function PoiFormPopup({ onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const { mouseSelectedPosition, isSelectionEnabled, setSelectionOn, setSelectedPosition } = useMapContext();
  const validationSchema = useValidation(poiSchema);
  const activeSite = useAppSelector(selectActiveSite);
  const [createPoi, { isLoading }] = useCreatePointsOfInterestMutation();
  const defaultPoi: Partial<PointOfInterest> = {
    name: '',
    code: uuidv4(),
    nature: '',
    site: activeSite?.code,
    active: true,
    height: 0,
  };

  function handleCreatePoi(poi: Partial<PointOfInterest>) {
    createPoi({ ...poi, position: mouseSelectedPosition })
      .unwrap()
      .then(() => {
        createToast(formatMessage({ id: 'poi.success' }), ToastStatusEnum.SUCCESS);
        onClose();
      });
  }

  return (
    <DraggableControl
      label={formatMessage({ id: 'poi.creation' })}
      width={720}
      height={566}
      top={400}
      left={400}
      onClose={onClose}
    >
      <Formik initialValues={defaultPoi} onSubmit={handleCreatePoi} validationSchema={validationSchema}>
        {({ handleSubmit, handleChange, errors, touched, isValid, dirty, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box padding={3} backgroundColor="neutral.700">
              <HStack gap={3}>
                <VStack alignSelf="flex-start" alignItems="flex-start">
                  <FormControl isRequired>
                    <FormLabel variant="mapFormLabel">
                      <FormattedMessage id="poi.nature" />
                    </FormLabel>
                    <CustomSelect
                      isInvalid={touched.nature && !!errors.nature}
                      isClearable={false}
                      menuPlacement="auto"
                      variant="dark"
                      width={450}
                      options={Object.keys(EventTypeEnum).map((key) => ({
                        label: formatMessage({ id: `live.event.types.${key}` }),
                        value: key as EventTypeEnum,
                      }))}
                      placeholder=""
                      formatOptionLabel={(option: Option) => (
                        <HStack gap={1}>
                          <FaCircle color={eventColor[option.value as EventTypeEnum]} />
                          <Text fontSize="16px">{option.label}</Text>
                        </HStack>
                      )}
                      onChange={handleChange('nature')}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel variant="mapFormLabel">
                      <FormattedMessage id="poi.name" />
                    </FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name="name"
                      type="text"
                      height="48px"
                      maxLength="50"
                      isInvalid={touched.name && !!errors.name}
                    />
                  </FormControl>
                  <HStack paddingY={1}>
                    <FormLabel variant="mapFormLabel">
                      <FormattedMessage id="poi.active" />
                    </FormLabel>
                    <CustomSwitch
                      isChecked={values.active}
                      onClick={() => {
                        setFieldValue('active', !values.active);
                      }}
                    />
                  </HStack>
                  <FormControl isRequired>
                    <FormLabel variant="mapFormLabel">
                      <FormattedMessage id="poi.height" />
                    </FormLabel>
                    <HStack>
                      <Field
                        as={Input}
                        id="height"
                        name="height"
                        type="number"
                        height="48px"
                        width="100px"
                        isInvalid={touched.height && !!errors.height}
                      />
                      <Text color="neutral.300">m</Text>
                    </HStack>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel variant="mapFormLabel">
                      <FormattedMessage id="poi.position" />
                    </FormLabel>
                    <HStack alignItems="start">
                      <GPSCoordinateInput
                        coordinates={mouseSelectedPosition}
                        onLatitudeChange={(latitude) => {
                          setSelectedPosition({ ...mouseSelectedPosition, latitude: latitude ?? NaN });
                        }}
                        onLongitudeChange={(longitude) => {
                          setSelectedPosition({ ...mouseSelectedPosition, longitude: longitude ?? NaN });
                        }}
                      />
                      <IconButton
                        aria-label="Position"
                        variant="positionPicker"
                        isActive={isSelectionEnabled}
                        onClick={() => setSelectionOn(!isSelectionEnabled)}
                        icon={<Icon as={PositionIcon} />}
                      />
                    </HStack>
                  </FormControl>
                  <Box width="654px" height="50px">
                    <ErrorsContainer errors={errors} touched={touched} />
                  </Box>
                  <Box></Box>
                  <Box paddingBottom={0}>
                    <HStack marginLeft={10} gap={3}>
                      <Button type="button" onClick={onClose} variant="formButtonSecondary" width="118px">
                        <FormattedMessage id="global.cancel" />
                      </Button>
                      <Button
                        type="submit"
                        isDisabled={!dirty || !isValid || isLoading}
                        isLoading={isLoading}
                        variant="formButtonPrimary"
                        width="290px"
                      >
                        <FormattedMessage id="poi.submit" />
                      </Button>
                    </HStack>
                  </Box>
                </VStack>
              </HStack>
            </Box>
          </form>
        )}
      </Formik>
    </DraggableControl>
  );
}
