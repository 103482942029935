import { useEffect, useLayoutEffect, useRef } from 'react';

const BUFFERING: number = parseInt(window.env.VITE_SLDP_BUFFERING || '150');
const LATENCY_TOLERANCE: number = parseInt(window.env.VITE_SLDP_LATENCY_TOLERANCE || '450');
const LOG_LEVEL: string = window.env.VITE_SLDP_LOG_LEVEL || 'none';

type Sldp = {
  COMMITHASH: string;
  VERSION: string;
  init: (playerOptions: SldpOptions) => SldpPlayer;
  isMediaSourceSupported: () => boolean;
};

export type SldpPlayer = {
  changeRendition: (rendition: unknown) => unknown;
  changeStream: (stream: unknown) => unknown;
  destroy: (callback?: (args?: unknown) => void) => void;
  getCurrentRendition: () => unknown;
  getCurrentStream: () => unknown;
  getRenditions: () => unknown;
  getStreams: () => unknown;
  getVolume: () => unknown;
  initialize: (init: unknown) => unknown;
  isAbr: () => boolean;
  pause: () => void;
  play: () => void;
  setCallbacks: (callbacks: unknown) => void;
  setStreamURL: (url: string | undefined) => void;
  setVolume: (vol: number) => void;
  startAbr: () => void;
  stop: () => void;
  stopAbr: () => void;
  tgAudio: () => void;
};

type SldpOptions = {
  container: string;
  stream_url: string | undefined;
  autoplay?: boolean;
  muted?: boolean;
  video_only?: boolean;
  muteable?: boolean;
  width: string | number;
  height: string | number;
  reconnects?: number;
  controls?: boolean;
  adaptive_bitrate?: boolean;
  pause_timeout?: number;
  buffering?: number;
  latency_tolerance?: number;
  log_level?: string;
};

type PlayerInfo = {
  htmlPlayer: HTMLElement;
  player: SldpPlayer;
};

let SLDP: Sldp;
if ('SLDP' in window) {
  SLDP = window.SLDP as Sldp;
} else {
  throw new Error('SLDP failed to initialize');
}

//this should stay private and not be exported to avoid breaking
// the behaviour of the pool through external manipulation
const playerPool = {
  inUse: new Map<SldpPlayer, HTMLElement>(),
  available: new Array<PlayerInfo>(),
};

function onError(error: string) {
  console.error('[Player] onError:', error);
}

function initPlayer(containerId: string, streamUrl?: string) {
  try {
    const player = SLDP.init({
      container: containerId,
      stream_url: streamUrl,
      autoplay: true,
      muted: true,
      video_only: true,
      muteable: false,
      width: 'parent',
      height: 'parent',
      reconnects: 10,
      controls: false,
      adaptive_bitrate: true,
      pause_timeout: 60,
      buffering: BUFFERING,
      latency_tolerance: LATENCY_TOLERANCE, // for being non-tolerant to increasing latency
      log_level: LOG_LEVEL,
    });
    player.setCallbacks({ onError });
    return player;
  } catch (e) {
    throw new Error('cannot init sldp player', { cause: e });
  }
}

function getContainerFromId(containerId: string) {
  const parent = document.getElementById(containerId);
  if (parent === null) {
    throw new Error('parent not found while setting up an SLDP player');
  }
  return parent;
}

function removeNoPlayableSourceMessage(parent: HTMLElement) {
  parent.querySelectorAll('.sldp_message_wrp').forEach((element) => {
    element.remove();
  });
}

// this function is an implementation detail and should not be exported
function getPlayer(containerId: string, streamUrl?: string): SldpPlayer {
  const parent = getContainerFromId(containerId);
  let player: SldpPlayer, htmlPlayer: HTMLElement;
  if (playerPool.available.length) {
    ({ player, htmlPlayer } = playerPool.available.pop()!);
    parent.appendChild(htmlPlayer);
    if (streamUrl) {
      player.setStreamURL(streamUrl);
    }
  } else {
    player = initPlayer(containerId, streamUrl);
    htmlPlayer = parent.querySelector<HTMLElement>('.sldp_player_wrp')!;
  }
  playerPool.inUse.set(player, htmlPlayer);
  return player;
}

// this function is an implementation detail and should not be exported
function releasePlayer(player: SldpPlayer) {
  player.stop();
  const htmlPlayer = playerPool.inUse.get(player);
  if (htmlPlayer) {
    htmlPlayer.remove();
    playerPool.inUse.delete(player);
    playerPool.available.push({ player, htmlPlayer });
  }
}

/**
 * Hook to get a player from a pool and release it on unmount
 * @param containerId Id of the HTML element that will contain the player ; only one player per container
 * @param isPaused boolean which will be true if the Timeline panel is paused, and undefined if the user is not using
 * the CameraPerimReplay panel to watch the video
 * @param streamUrl (optional) url of the stream to be played
 */
export function usePlayer(containerId: string, streamUrl?: string, isPaused?: boolean) {
  const player = useRef<SldpPlayer>();
  const firstRender = useRef(true);
  // handles player reservation and release
  // also handles the first setup of the player (container and url)
  useLayoutEffect(() => {
    player.current = getPlayer(containerId, streamUrl);
    return () => {
      releasePlayer(player.current!);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // handles containerId changes
  useLayoutEffect(() => {
    if (!firstRender.current) {
      const parent = getContainerFromId(containerId);
      removeNoPlayableSourceMessage(parent);
      parent.appendChild(playerPool.inUse.get(player.current!)!);
    }
  }, [containerId]);

  //handles streamUrl changes
  useEffect(() => {
    if (!firstRender.current) {
      const parent = getContainerFromId(containerId);
      removeNoPlayableSourceMessage(parent);
      player.current?.setStreamURL(streamUrl);
    }
  }, [streamUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isPaused !== undefined) {
      if (isPaused) {
        player.current?.pause();
      } else {
        player.current?.play();
      }
    }
  }, [isPaused]);

  //tracks first render to avoid executing update hooks on first render
  useEffect(() => {
    firstRender.current = false;
    return () => {
      firstRender.current = true;
    };
  }, []);
}
