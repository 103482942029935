import { HStack, Text, VStack } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectGroupedTargetWithSelectedTarget, selectStrobeById } from '@redux/situation/situation.selectors';
import { formatDate } from '@utils/date.utils';
import { getFrequencyFromRfData, getStrobeIcon } from '@utils/map/strobe.utils';
import { getTargetIdentification } from '@utils/target.utils';
import { useIntl } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { DateTimeEnum } from '@/types/dateTime.types';
import { PopupPositionData, StrobeInfoData } from '@/types/map.types';
import { StrobeLocation } from '@/types/sensor/sensor.types';

import InfoValue from './InfoValue';

function StrobeInfo({ targetId, top, left, onClose }: Readonly<StrobeInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();

  const target: Target<StrobeLocation> | undefined = useSelectorWithReplayMode(selectStrobeById, targetId);
  const groupedTarget = useSelectorWithReplayMode(selectGroupedTargetWithSelectedTarget, target);

  if (!target) {
    onClose();
    return null;
  }
  return (
    <DraggableControl
      top={top}
      left={left}
      offsetY={-133}
      height={608}
      label={formatMessage({ id: 'strobeInfos.strobeInfos' })}
      onClose={onClose}
    >
      <HStack alignItems="start" backgroundColor="neutral.700" width="442" minHeight="100%" gap={0.25}>
        <VStack alignItems="start" flexGrow={1} gap={4} marginLeft={4} marginTop={4}>
          <InfoValue
            label={formatMessage({ id: 'strobeInfos.type' })}
            value={formatMessage({ id: `target.identification.${getTargetIdentification(target)}` })}
          />
          <HStack flexGrow={1} gap={4}>
            <InfoValue
              label={formatMessage({ id: 'strobeInfos.frequency' })}
              value={getFrequencyFromRfData(target.lastPosition?.rfData) ?? 'N/A'}
            />
            <InfoValue
              label={formatMessage({ id: 'strobeInfos.protocol' })}
              value={target.lastPosition?.rfData?.protocol ?? 'N/A'}
            />
            <InfoValue
              label={formatMessage({ id: 'strobeInfos.rssi' })}
              value={target.lastPosition?.rfData?.rssi ?? 'N/A'}
            />
          </HStack>
          <InfoValue
            label={formatMessage({ id: 'strobeInfos.detector' })}
            value={target.trackIds.map((track) => track.sensorCode).join(', ')}
          />
          <InfoValue
            label={formatMessage({ id: 'strobeInfos.droneType' })}
            value={target.lastPosition?.collaborativeData?.model ?? 'N/A'}
          />
          <VStack alignItems="start">
            <Text size="md" color="neutral.300">
              {formatMessage({ id: 'strobeInfos.detection' })}
            </Text>
            <HStack width="100%" gap={2} alignItems="start">
              <VStack alignItems="start" justifyContent="end">
                <HStack width="100%" gap={1} alignItems="start">
                  <Text size="md" width="65px" color="neutral.300" noOfLines={1}>
                    {formatMessage({ id: 'strobeInfos.start' })}
                  </Text>
                  <Text fontSize="18px" fontWeight="medium">
                    {formatDate(target.detectionStart, DateTimeEnum.DATE_TIME)}
                  </Text>
                </HStack>
                <HStack width="100%" gap={1} alignItems="start">
                  <Text size="md" width="65px" color="neutral.300" noOfLines={1}>
                    {formatMessage({ id: 'strobeInfos.end' })}
                  </Text>
                  <Text fontSize="18px" fontWeight="medium">
                    {formatDate(target.detectionEnd, DateTimeEnum.DATE_TIME)}
                  </Text>
                </HStack>

                <HStack width="100%" gap={1} alignItems="start">
                  <Text size="md" width="65px" color="neutral.300" noOfLines={1}>
                    {formatMessage({ id: 'strobeInfos.azimuth' })}
                  </Text>
                  <Text fontSize="18px" fontWeight="medium" noOfLines={1}>
                    {target.lastPosition!.location!.azimuth!.toFixed(0)}°
                  </Text>
                </HStack>
              </VStack>
              <VStack alignItems="start" justifyContent="end"></VStack>
            </HStack>
          </VStack>
          <InfoValue label={formatMessage({ id: 'strobeInfos.linkedTo' })} value={groupedTarget?.id ?? 'N/A'} />
        </VStack>
        <Icon as={getStrobeIcon(target)} width="70px" height="70px" margin={4} />
      </HStack>
    </DraggableControl>
  );
}

export default StrobeInfo;
