import { ReactComponent as LogBookCorner } from '@assets/components/panel/logbook/logbook-corner.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import useIsElementVisible from '@hooks/useIsElementVisible';
import { calculateLinesNeeded } from '@utils/common.utils';
import { formatDate } from '@utils/date.utils';
import { format } from 'date-fns';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { UserLight } from '@/types/authent/login.types';
import { LogTypeEnum } from '@/types/c2/logs.type';
import { AnyDate, DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  date: AnyDate;
  label: string;
  author: UserLight | null;
  type: LogTypeEnum;
};

export const MAX_CHARACTERS_PER_LINE = 43;
export const LINE_HEIGHT = 20;

export default function LogBookMessage({ date, label, author, type }: Readonly<Props>) {
  const elementRef = useRef<HTMLDivElement>(null);

  const { formatMessage } = useIntl();
  const authorLabel = author
    ? formatMessage(
        { id: 'components.logbook.author' },
        {
          isUserAction: type === LogTypeEnum.USER_ACTION,
          name: `${author.name}${author.firstName ? ` ${author.firstName}` : ''}`,
        },
      )
    : '';

  const height = 66 + LINE_HEIGHT * calculateLinesNeeded(label, MAX_CHARACTERS_PER_LINE);
  const isElementVisible = useIsElementVisible(elementRef.current);

  return (
    <HStack gap={0} paddingTop={0.5} cursor="default" width="100%" height={height} minHeight={height} ref={elementRef}>
      {isElementVisible && (
        <>
          <VStack
            padding={2}
            paddingRight={0}
            gap={1}
            width="100%"
            height="100%"
            alignItems="start"
            overflow="hidden"
            backgroundColor="neutral.800-op70"
          >
            <Text fontSize="16px" height="100%" width="100%" wordBreak="break-word">
              {label}
            </Text>
            <HStack gap={1}>
              <Text backgroundColor="neutral.black" paddingY={0.25} paddingX={0.5}>
                {format(new Date(date), "HH'h'mm")}
              </Text>
              <Text>{formatDate(new Date(date), DateTimeEnum.DATE)}</Text>
              {author && (
                <Tooltip isDisabled={authorLabel.length < 36} label={`${author.name} ${author.firstName}`}>
                  <Text noOfLines={1} wordBreak="break-all" marginLeft={2}>
                    {authorLabel}
                  </Text>
                </Tooltip>
              )}
            </HStack>
          </VStack>
          <VStack gap={0} height="100%">
            <Box width="24px" height="calc(100% - 82px)" flexGrow={1} backgroundColor="neutral.800-op70"></Box>
            <Icon as={LogBookCorner} height="82px" width="24px" />
          </VStack>
        </>
      )}
    </HStack>
  );
}
