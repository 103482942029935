import { ReactComponent as SwitchOff } from '@assets/components/button/switch-off.svg';
import { ReactComponent as SwitchOn } from '@assets/components/button/switch-on.svg';
import { IconButton } from '@chakra-ui/react';

type Props = {
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

export default function CustomSwitch({ isChecked, isDisabled = false, onClick }: Readonly<Props>) {
  return (
    <IconButton
      height="28px"
      flexShrink={0}
      aria-label="custom-switch"
      icon={isChecked ? <SwitchOn /> : <SwitchOff />}
      onClick={() => !isDisabled && onClick && onClick()}
      variant="none"
      isDisabled={isDisabled}
      cursor={isDisabled ? 'default !important' : 'pointer'}
    />
  );
}
