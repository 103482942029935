import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontSize: '18px',
    fontFamily: 'roboto',
    fontWeight: 'regular',
    lineHeight: '24px',
    color: 'neutral.white',
    marginLeft: 0,
    _disabled: {
      opacity: 1,
    },
  },
  container: {
    height: '24px',
    display: 'flex',
    gap: 2,
    _disabled: {
      cursor: 'default',
    },
    _checked: {
      cursor: 'default',
    },
  },
  control: {
    width: '24px',
    height: '24px',
    background: 'transparent',
    border: '2px solid',
    borderColor: 'neutral.300',
    position: 'relative',
    _focusVisible: {
      boxShadow: 'none !important',
    },
    _checked: {
      background: 'cyber.600',
      borderColor: 'cyber.500',
      color: 'sky.500',
      _hover: {
        background: 'cyber.600',
        borderColor: 'sky.500',
      },
      _disabled: {
        background: 'transparent',
        borderColor: 'neutral.500',
        color: 'neutral.500',
      },
      _before: {
        position: 'absolute',
        width: '14px',
        height: '14px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    _hover: {
      borderColor: 'sky.500',
    },
    _disabled: {
      background: 'transparent',
      borderColor: 'neutral.500',
      color: 'neutral.500',
      _hover: {
        color: 'neutral.500',
        borderColor: 'neutral.500',
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
});
