import { CONFIG_URL, configApi } from '@services/config/config.api';

import { GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';

export const globalScreenConfigurationApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    createGlobalScreenConfiguration: builder.mutation<GlobalScreenConfiguration, GlobalScreenConfiguration>({
      query: (globalScreenConfiguration: GlobalScreenConfiguration) => ({
        url: `${CONFIG_URL}/global-screen-configurations`,
        method: 'POST',
        body: globalScreenConfiguration,
      }),
    }),
    updateGlobalScreenConfiguration: builder.mutation<
      GlobalScreenConfiguration,
      { id: number; globalScreenConfiguration: GlobalScreenConfiguration }
    >({
      query: ({ id, globalScreenConfiguration }) => ({
        url: `${CONFIG_URL}/global-screen-configurations/${id}`,
        method: 'PUT',
        body: globalScreenConfiguration,
      }),
    }),
    deleteGlobalScreenConfiguration: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/global-screen-configurations/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateGlobalScreenConfigurationMutation,
  useUpdateGlobalScreenConfigurationMutation,
  useDeleteGlobalScreenConfigurationMutation,
} = globalScreenConfigurationApi;
