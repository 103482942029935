import { ReactComponent as NoAlertDroneIcon } from '@assets/components/panel/alert/no-alert-drone-icon.svg';
import { Box, Center, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  width: number;
  header: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  isEmpty?: boolean;
  emptyLabel?: string;
};

export default function Panel({ width, header, children, footer, isEmpty = false, emptyLabel }: Readonly<Props>) {
  return (
    <VStack backgroundColor="transparent" width={width} gap={0} height="100%" alignItems="start">
      {header}
      {children && !isEmpty ? (
        <Box width="100%" flexGrow={1}>
          {children}
        </Box>
      ) : (
        <Center
          width="calc(100% - 16px)"
          height="100%"
          backgroundColor="neutral.800-op70"
          position="relative"
          marginTop={0.5}
        >
          <VStack gap={3}>
            <NoAlertDroneIcon />
            <Text
              fontSize="18px"
              variant="space"
              color="neutral.200"
              maxWidth="230px"
              textAlign="center"
              userSelect="none"
            >
              {emptyLabel}
            </Text>
          </VStack>
        </Center>
      )}
      {footer && (
        <Box width="calc(100% - 16px)" height="fit-content" flexShrink={0} marginTop={0.5}>
          {footer}
        </Box>
      )}
    </VStack>
  );
}
