import HologardeBackground from '@assets/hologarde-background.jpg';
import { Flex, Heading, Stack } from '@chakra-ui/layout';
import { Button, Card, CardBody, CardHeader, Image } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import LogoutButton from '@components/login/LogoutButton';
import { useAppDispatch } from '@hooks/redux.hooks';
import { userLogout } from '@redux/authent/authent.reducer';
import { useUpdateDisplayModeMutation } from '@services/authent/user.api';
import { FormattedMessage } from 'react-intl';

import { DisplayModeEnum } from '@/types/authent/login.types';

export default function DisplayModeConfiguration() {
  const [updateDisplayMode, { isLoading }] = useUpdateDisplayModeMutation();
  const dispatch = useAppDispatch();
  const handleClick = (display: DisplayModeEnum) => () => {
    updateDisplayMode({ displayMode: display }).catch(() => dispatch(userLogout()));
  };

  return (
    <Flex width="100%" height="100%" fontFamily="roboto" alignItems="center">
      <Image src={HologardeBackground} fit="cover" height="100%" width="100%" position="absolute" />
      <Flex position="absolute" top={2} right={2} gap={1}>
        <LogoutButton />
      </Flex>
      <Card borderRadius={0} marginLeft="auto" marginRight="50px">
        <CardHeader padding="20px">
          <Heading size="xl">
            <FormattedMessage id="displayModeChoice.title" />
          </Heading>
        </CardHeader>
        <CardBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="500px"
          padding="20px"
        >
          <FormattedMessage id="displayModeChoice.selectDisplayMode" />
          {isLoading ? (
            <Spinner size="xl" margin={5} />
          ) : (
            <Stack spacing={2} width="80%">
              <Button
                border="1px solid"
                height="150px"
                onClick={handleClick(DisplayModeEnum.MULTI_SCREENS_WITH_COCKPIT)}
              >
                <FormattedMessage id="displayModeChoice.multiScreensMode" />
              </Button>
              <Button border="1px solid" height="150px" onClick={handleClick(DisplayModeEnum.TWO_SCREENS)}>
                <FormattedMessage id="displayModeChoice.twoScreensMode" />
              </Button>
              <Button border="1px solid" height="150px" onClick={handleClick(DisplayModeEnum.MONO_SCREEN)}>
                <FormattedMessage id="displayModeChoice.monoScreenMode" />
              </Button>
            </Stack>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}
