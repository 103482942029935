import { ReactComponent as MinusIcon } from '@assets/icons/24x24/ic-zoom-minus-24.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/24x24/ic-zoom-plus-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, Center, VStack } from '@chakra-ui/layout';
import { IconButton } from '@chakra-ui/react';
import CustomSlider from '@components/common/inputs/CustomSlider';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateZoomByMapId } from '@redux/maps/maps.reducer';
import { selectZoomByMapId } from '@redux/maps/maps.selectors';
import { memo } from 'react';
import { useMap } from 'react-map-gl';

function ZoomControl() {
  const { mapId } = useMapContext();
  const zoom = useAppSelector((state) => selectZoomByMapId(state, mapId)) ?? 13;
  const updateZoom = useWithDispatch(updateZoomByMapId);
  const { current: map } = useMap();

  return (
    <Box position="relative">
      <Center
        flexDirection="column"
        width="72px"
        height="212px"
        backgroundColor="neutral.900"
        border="2px solid"
        borderColor="neutral.800"
      >
        <CustomSlider
          value={zoom}
          delay={0}
          displayedValue={(value) => `${Math.round((value / 22) * 100)}%`}
          onChange={(newZoom: number) => updateZoom({ mapId: mapId, zoom: newZoom })}
          min={0}
          max={22}
        />
      </Center>
      <VStack
        position="absolute"
        top="calc(50% - 62px)"
        right="72px"
        backgroundColor="neutral.900"
        height="124px"
        width="42px"
        gap={0.25}
        clipPath="polygon(42px 0, 42px 124px, 0 98px , 0 25px)"
      >
        <IconButton
          aria-label="Zoom in"
          height="59px"
          marginTop={0.25}
          icon={<Icon as={PlusIcon} marginTop="14px" />}
          color="neutral.300"
          backgroundColor="neutral.800"
          _hover={{
            backgroundColor: 'neutral.600',
            color: 'neutral.200',
          }}
          _active={{
            backgroundColor: 'neutral.black',
            color: 'neutral.200',
          }}
          borderRadius={0}
          clipPath="polygon(40px 0, 40px 59px, 0 59px, 0 24px)"
          transform="translateX(1px)"
          onClick={() => updateZoom({ mapId: mapId, zoom: Math.min(map?.getMaxZoom() ?? 22, zoom! + 1) })}
        />
        <IconButton
          aria-label="Zoom out"
          height="59px"
          icon={<Icon as={MinusIcon} marginBottom="14px" />}
          color="neutral.300"
          backgroundColor="neutral.800"
          _hover={{
            backgroundColor: 'neutral.600',
            color: 'neutral.200',
          }}
          _active={{
            backgroundColor: 'neutral.black',
            color: 'neutral.200',
          }}
          borderRadius={0}
          clipPath="polygon(40px 0, 40px 59px, 0 34px, 0 0)"
          transform="translateX(1px)"
          onClick={() => updateZoom({ mapId: mapId, zoom: Math.max(map?.getMinZoom() ?? 0, zoom! - 1) })}
        />
      </VStack>
    </Box>
  );
}

export default memo(ZoomControl);
