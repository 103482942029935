import { TRUNCATE_SIZE_NAME } from '@components/map/layers/sensors/sensor.mapper';
import { point, Position } from '@turf/helpers';
import { truncateMessage } from '@utils/locale.utils';
import {
  getAutomatonSectionNames,
  getAutomatonSectionSpriteIconName,
  getPerimeterPortalSpriteIconNameFromSectionStatus,
  getSectionCenterCoordinates,
  getSectionIconStatus,
  getSectionLineColor,
  getSectionName,
  getSectionStartAndEndPosition,
} from '@utils/map/automatonSection.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature, Geometry } from 'geojson';

import { FeatureTypeEnum } from '@/types/map.types';
import { AutomatonSectionData, AutomatonSectionStatus } from '@/types/sensor/configuration.types';
import { AutomatonSectionStatusEnum } from '@/types/sensor/status.types';

function toAutomatonSectionIcons(
  sectionData: AutomatonSectionData,
  position: Position,
  iconStatus: AutomatonSectionStatusEnum,
): Feature {
  const automatonsSectionNames = getAutomatonSectionNames(sectionData);
  return {
    type: 'Feature',
    properties: {
      featureType: FeatureTypeEnum.AUTOMATON_SECTION_ICON,
      value: automatonsSectionNames,
      icon: getAutomatonSectionSpriteIconName(iconStatus),
      textField: getSectionName(sectionData.section),
    },
    geometry: {
      type: 'Point',
      coordinates: position,
    },
  };
}

export function toAutomatonSectionLabelFeature(sectionData: AutomatonSectionData, position: Position): Feature {
  const automatonsSectionNames = getAutomatonSectionNames(sectionData);
  return {
    type: 'Feature',
    properties: {
      featureType: FeatureTypeEnum.AUTOMATON_SECTION_LABEL,
      value: automatonsSectionNames,
      textField: truncateMessage(automatonsSectionNames.sectionName, TRUNCATE_SIZE_NAME),
      tooltipAnchor: 'pointer',
      showTooltip: automatonsSectionNames.sectionName.length > TRUNCATE_SIZE_NAME,
    },
    geometry: {
      type: 'Point',
      coordinates: position,
    },
  };
}

export const toAutomatonSectionLineFeatures = (
  automatonSectionData: AutomatonSectionData[],
  statuses: AutomatonSectionStatus[],
): Feature[] =>
  automatonSectionData.flatMap((sectionData) => {
    const automatonsSectionNames = getAutomatonSectionNames(sectionData);
    const { sectionStart, sectionEnd } = getSectionStartAndEndPosition(sectionData.section);

    const sectionStartPoint = point(sectionStart);
    const sectionEndPoint = point(sectionEnd);
    const sectionStatus = statuses.find((status) => status.name === automatonsSectionNames.sectionName);

    const mainLineGeometry: Geometry = {
      type: 'LineString',
      coordinates: [sectionStart, sectionEnd],
    };
    const lineEndsGeometry: Geometry = {
      type: 'GeometryCollection',
      geometries: [
        {
          type: 'Point',
          coordinates: sectionStartPoint.geometry.coordinates,
        },
        {
          type: 'Point',
          coordinates: sectionEndPoint.geometry.coordinates,
        },
      ],
    };

    return [
      {
        type: 'Feature',
        properties: {
          id: automatonsSectionNames.sectionName,
          featureType: FeatureTypeEnum.AUTOMATON_SECTION_LINE,
          value: automatonsSectionNames,
          color: getSectionLineColor(sectionData, sectionStatus, true),
        },
        geometry: mainLineGeometry,
      },
      {
        type: 'Feature',
        properties: {
          id: automatonsSectionNames.sectionName,
          featureType: FeatureTypeEnum.AUTOMATON_SECTION_ENDING,
          value: automatonsSectionNames,
          color: getSectionLineColor(sectionData, sectionStatus, true),
        },
        geometry: lineEndsGeometry,
      },
    ] as Feature[];
  });

export const toPortalFeature = (sectionData: AutomatonSectionData, iconStatus: AutomatonSectionStatusEnum): Feature => {
  const position = sectionData.portal?.position;
  const automatonsSectionNames = getAutomatonSectionNames(sectionData);
  return {
    type: 'Feature',
    properties: {
      featureType: FeatureTypeEnum.PERIMETER_PORTAL,
      value: automatonsSectionNames,
      icon: getPerimeterPortalSpriteIconNameFromSectionStatus(iconStatus),
      textField: sectionData.portal?.name,
      tooltipAnchor: 'pointer',
    },
    geometry: {
      type: 'Point',
      coordinates: toLonLatArray(position),
    },
  } as Feature;
};

export const toAutomatonSectionIconFeatures = (
  sections: AutomatonSectionData[],
  statuses: AutomatonSectionStatus[],
): Feature[] => {
  return sections.flatMap((sectionData) => {
    const sectionCenter = toLonLatArray(getSectionCenterCoordinates(sectionData.section), false)!;
    const sectionStatus = statuses.find((status) => status.name === getSectionName(sectionData.section));
    const iconStatus = getSectionIconStatus(sectionData, sectionStatus?.status ?? null);

    const features = [
      toAutomatonSectionIcons(sectionData, sectionCenter, iconStatus),
      toAutomatonSectionLabelFeature(sectionData, sectionCenter),
    ];

    if (sectionData.portal) {
      features.push(toPortalFeature(sectionData, iconStatus));
    }

    return features;
  });
};
