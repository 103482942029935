import { useMapContext } from '@components/map/MapContext';
import { EventData, MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';

import { useMapLayerMouseEvent } from './useMapLayerMouseEvent';

export const useMapSelectPosition = () => {
  const { isSelectionEnabled, setSelectedPosition } = useMapContext();
  const onClick = (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
    if (isSelectionEnabled) {
      setSelectedPosition({
        latitude: Number(event.lngLat.lat.toFixed(5)),
        longitude: Number(event.lngLat.lng.toFixed(5)),
        type: '2D',
      });
    }
  };

  useMapLayerMouseEvent('click', onClick);
};
