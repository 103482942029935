import { ReactComponent as BeaconIcon } from '@assets/icons/24x24/ic-beacon-24.svg';
import { ReactComponent as WorkIcon } from '@assets/icons/24x24/ic-works-24.svg';
import { ReactComponent as FileConfigIcon } from '@assets/icons/40x40/ic-file-config-40.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/40x40/ic-phoneNegative-40.svg';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import CustomRadioToggle from '@components/common/inputs/CustomRadioToggle';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import DataSheetCardLink from '@components/common/layout/DataSheetCardLink';
import { MapCustomScrollbar } from '@components/common/layout/MapCustomScrollbar';
import DraggableControl from '@components/map/controls/DraggableControl';
import AutomatonSectionIcon from '@components/map/infos/perimeter/AutomatonSectionIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { hasRole } from '@redux/authent/authent.selectors';
import { selectAutomationSectionLadCameraConfigurationByUniqueCode } from '@redux/sensors/sensors.selectors';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import {
  selectAutomatonSectionByName,
  selectAutomatonSegmentStatusByCode,
  selectLowerStatesByName,
  selectSectionStatusByName,
  selectUpperStatesByName,
} from '@redux/situation/monitoring.selectors';
import {
  usePostAutomatonCommandMutation,
  useUpdateSensorActivationMutation,
  useUpdateSensorMaintenanceMutation,
} from '@services/c2/c2.api';
import {
  getSectionCenterCoordinates,
  getSectionIconStatus,
  getSectionName,
  getSectionNameAndSegments,
  getSegmentStatus,
  isSectionActive,
  isSectionInMaintenance,
  isSegmentActive,
  sendRepulsionCommand,
} from '@utils/map/automatonSection.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { formatCoordinates } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { AutomatonCommandEnum, AutomatonSectionDefenceCommand } from '@/types/c2/c2.types';
import { Dictionary } from '@/types/commons/commons.types';
import { AutomatonSectionInfoData, PopupPositionData } from '@/types/map.types';
import { AutomatonSegment, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { AutomatonNodeStateEnum, AutomatonSectionStatusEnum, SensorStatusEnum } from '@/types/sensor/status.types';

import InfoValue from '../InfoValue';

export default function AutomatonSectionInfo({
  featureValue: automatonSectionNames,
  top,
  left,
  onClose,
}: Readonly<AutomatonSectionInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement | null>(null);

  const [patchMaintenance] = useUpdateSensorMaintenanceMutation();
  const [patchActivation] = useUpdateSensorActivationMutation();
  const [postRepulsionCommand, { isLoading: isRepulsionCommandLoading }] = usePostAutomatonCommandMutation();

  const hasRoleEditAutomaton = useAppSelector((state) => hasRole(state, RoleEnum.EDIT_AUTOMATON));
  const canDoCommand = useAppSelector((state) => hasRole(state, RoleEnum.PERI_SENSOR_COMMAND));
  const canDoSensorMaintenance = useAppSelector((state) => hasRole(state, RoleEnum.SENSOR_MAINTENANCE));
  const canSendRepulsion = useAppSelector((state) => hasRole(state, RoleEnum.AUTOMATON_REPULSION_COMMAND));
  const canSendDetection = useAppSelector((state) => hasRole(state, RoleEnum.AUTOMATON_DETECTION_COMMAND));

  const value = useSelectorWithReplayMode(selectAutomatonSectionByName, automatonSectionNames.sectionName)!;

  const { automaton, cabinet, section, perimeterCameraUniqueKeys } = value;

  const sectionName = getSectionName(section);
  const { segments } = getSectionNameAndSegments(section);
  const sectionCenter = getSectionCenterCoordinates(section);

  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const status = useSelectorWithReplayMode(selectSectionStatusByName, sectionName);
  const featureStatus = getSectionIconStatus(value, status);

  const ladCamera = useAppSelector((state) =>
    selectAutomationSectionLadCameraConfigurationByUniqueCode(state, section),
  );

  const upperSegment: AutomatonSegment | null = section.upperSegment ?? null;
  const upperStatus: AutomatonSectionStatusEnum | null = useSelectorWithReplayMode(selectAutomatonSegmentStatusByCode, {
    automaton: automaton.code,
    cabinet: cabinet.code,
    segment: upperSegment?.code,
  });
  const lowerSegment: AutomatonSegment | null = section.lowerSegment ?? null;
  const lowerStatus: AutomatonSectionStatusEnum | null = useSelectorWithReplayMode(selectAutomatonSegmentStatusByCode, {
    automaton: automaton.code,
    cabinet: cabinet.code,
    segment: lowerSegment?.code,
  });
  const lowerStatusValue = lowerSegment && getSegmentStatus(lowerSegment, lowerStatus, automaton, cabinet);
  const upperStatusValue = upperSegment && getSegmentStatus(upperSegment, upperStatus, automaton, cabinet);
  const lowerStates: Dictionary<string[]> = useSelectorWithReplayMode(selectLowerStatesByName, sectionName) || {};
  const upperStates: Dictionary<string[]> = useSelectorWithReplayMode(selectUpperStatesByName, sectionName) || {};

  const isLowerRepulsionOn = !lowerStates[AutomatonNodeStateEnum.BT_DETECTION];
  const isUpperRepulsionOn = !upperStates[AutomatonNodeStateEnum.BT_DETECTION];
  const isRepulsionOn = isLowerRepulsionOn || isUpperRepulsionOn;
  const [cachedIsRepulsionOn, setCachedIsRepulsionOn] = useState<boolean>(isRepulsionOn);

  const isUnderMaintenance: boolean = isSectionInMaintenance(value.automaton, value.cabinet, value.section);
  const notSpecified = formatMessage({ id: 'global.noInformation' });

  const isSegmentCommandable =
    isSegmentActive(automaton, cabinet, lowerSegment) && lowerStatus !== SensorStatusEnum.SLEEP;

  function handleSectionMaintenance() {
    const isMaintenance = segments.some((segment) => segment.maintenance);
    patchMaintenance({
      id: automaton.id,
      site: automaton.site,
      appCode: automaton.appCode,
      maintenance: !isMaintenance,
      type: SubSensorTypeEnum.AUTOMATON_SEGMENT,
      codes: segments.map((segment) => segment.code),
    })
      .unwrap()
      .then(() => {
        const { code: code1, name } = segments.pop()!;
        const code2 = segments.pop()?.code;
        createToast(
          code2
            ? formatMessage(
                { id: 'contextmenu.actions.multipleMaintenanceSuccess' },
                {
                  maintenance: !isMaintenance,
                  code1: code1,
                  code2: code2,
                  section: name,
                },
              )
            : formatMessage(
                { id: 'contextmenu.actions.maintenanceSuccess' },
                {
                  maintenance: !isMaintenance,
                  code: code1,
                  section: name,
                },
              ),
          ToastStatusEnum.SUCCESS,
        );
      });
  }

  function handleSectionActivation() {
    const isActive = automaton.active && cabinet.active && segments.every((segment) => segment.active);
    patchActivation({
      id: automaton.id,
      site: automaton.site,
      appCode: automaton.appCode,
      active: !isActive,
      type: SubSensorTypeEnum.AUTOMATON_SEGMENT,
      codes: segments.map((segment) => segment.code),
    })
      .unwrap()
      .then(() => {
        const { code: code1, name } = segments.pop()!;
        const code2 = segments.pop()?.code;
        createToast(
          code2
            ? formatMessage(
                { id: 'contextmenu.actions.multipleActivationSuccess' },
                {
                  active: !isActive,
                  code1: code1,
                  code2: code2,
                  section: name,
                },
              )
            : formatMessage(
                { id: 'contextmenu.actions.activationSuccess' },
                {
                  active: !isActive,
                  code: code1,
                  section: name,
                },
              ),
          ToastStatusEnum.SUCCESS,
        );
      });
  }

  function handleSectionRepulsion(enableRepulsion: boolean) {
    const command = {
      type: AutomatonCommandEnum.AUTOMATON_SECTION_COMMAND_DEFENCE,
      sensorCode: automaton.code,
      cabinetId: cabinet.subSensorId,
      segmentId: segments[0].subSensorId,
      defence: enableRepulsion,
    } as AutomatonSectionDefenceCommand;

    sendRepulsionCommand(
      command,
      postRepulsionCommand,
      enableRepulsion,
      sectionName,
      false,
      automaton.site,
      automaton.appCode,
    );
  }

  return (
    <DraggableControl
      top={top}
      left={left}
      key={`${upperSegment?.code}-${lowerSegment?.code}`}
      offsetY={-200}
      width={489}
      onClose={onClose}
      label={formatMessage({ id: 'automatonSegments.automatonSegmentInfo' })}
    >
      <Tabs width="100%" height="max-content" variant="styledTab" ref={ref}>
        <TabList>
          <Tab>
            <Text size="md">
              <FormattedMessage id="automatonSegments.tab.description" />
            </Text>
          </Tab>
          {canDoCommand && (canSendDetection || canSendRepulsion) && (
            <Tab>
              <Text fontSize="16px">
                <FormattedMessage id="automatonSegments.tab.commands" />
              </Text>
            </Tab>
          )}
          {canDoSensorMaintenance && (
            <Tab>
              <Text size="md">
                <FormattedMessage id="automatonSegments.tab.maintenance" />
              </Text>
            </Tab>
          )}
        </TabList>

        <TabPanels height="100%">
          <TabPanel>
            <VStack gap={0.25} backgroundColor="neutral.900">
              <HStack width="100%" gap={3} backgroundColor="neutral.700" paddingX={4} paddingY={3}>
                <Box flexShrink={0}>
                  <AutomatonSectionIcon status={featureStatus} />
                </Box>
                <VStack width="100%" gap={0.5} alignItems="start">
                  <Text paddingX={0.5} paddingY={0.25} size="lg" backgroundColor="neutral.black" noOfLines={2}>
                    {sectionName}
                  </Text>
                  <Text fontSize="18px">
                    <FormattedMessage id={`automatonSegments.status.${featureStatus.toLowerCase()}`} />
                  </Text>
                </VStack>
              </HStack>
              <HStack width="100%" gap={4} backgroundColor="neutral.700" padding={4}>
                <VStack gap={1} alignItems="start">
                  <Text size="md" color="neutral.300">
                    <FormattedMessage id="automatonSegments.detailBySection" />
                  </Text>
                  <HStack gap={1}>
                    <Text width="44px" size="md" color="neutral.300">
                      <FormattedMessage id="automatonSegments.upper" />
                    </Text>
                    <Text size="lg">
                      {upperStatusValue ? (
                        <FormattedMessage
                          id={`automatonSegments.status.${
                            isUnderMaintenance ? 'maintenance' : upperStatusValue.toLowerCase()
                          }`}
                        />
                      ) : (
                        <FormattedMessage id="global.noInformation" />
                      )}
                    </Text>
                  </HStack>
                  <HStack gap={1}>
                    <Text width="44px" size="md" color="neutral.300">
                      <FormattedMessage id="automatonSegments.lower" />
                    </Text>
                    <Text size="lg">
                      {lowerStatusValue ? (
                        <FormattedMessage
                          id={`automatonSegments.status.${
                            isUnderMaintenance ? 'maintenance' : lowerStatusValue.toLowerCase()
                          }`}
                        />
                      ) : (
                        <FormattedMessage id="global.noInformation" />
                      )}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
              <VStack width="100%" padding={4} gap={3} backgroundColor="neutral.800">
                <InfoValue label={formatMessage({ id: 'automatonSegments.grids' })} value={notSpecified} />
                <InfoValue
                  label={formatMessage({ id: 'automatonSegments.latLong' })}
                  value={formatCoordinates(
                    new DmsCoordinates(sectionCenter.latitude, sectionCenter.longitude),
                    userSettingsUnit,
                  )}
                />
              </VStack>
              <VStack width="100%" gap={3} padding={4} backgroundColor="neutral.800">
                <HStack width="100%" gap={2} alignItems="start">
                  <VStack gap={3} width="50%" alignItems="start">
                    <InfoValue label={formatMessage({ id: 'automatonSegments.electrifier' })} value={cabinet.name} />
                    <InfoValue label={formatMessage({ id: 'automatonSegments.automaton' })} value={automaton.name} />
                  </VStack>
                  <VStack width="50%" gap={1} alignItems="start">
                    <Text size="md" color="neutral.300">
                      <FormattedMessage id="automatonSegments.attachedPerimeterCameras" />
                    </Text>
                    {perimeterCameraUniqueKeys.length === 0 ? (
                      <Text size="lg" noOfLines={2}>
                        <FormattedMessage id="automatonSegments.doubtCheck.noCameraLinkedToFence" />
                      </Text>
                    ) : (
                      <VStack alignItems="start" gap={1}>
                        {perimeterCameraUniqueKeys.map((uniqueKey) => {
                          return (
                            <Text key={uniqueKey.code} size="lg" noOfLines={1}>
                              {uniqueKey.code}
                            </Text>
                          );
                        })}
                      </VStack>
                    )}
                  </VStack>
                </HStack>
                <InfoValue
                  label={formatMessage({ id: 'automatonSegments.attachedLadCamera' })}
                  value={
                    ladCamera !== null ? ladCamera.name : formatMessage({ id: 'automatonSegments.noAttachedLadCamera' })
                  }
                />
              </VStack>
            </VStack>
          </TabPanel>
          {canDoCommand && (canSendDetection || canSendRepulsion) && (
            <TabPanel>
              <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%">
                <VStack alignItems="start" width="100%" gap={2}>
                  <Text size="md">
                    <FormattedMessage id="sensors.command.section.defence.description" />
                  </Text>
                </VStack>
                <VStack width="100%" gap={2}>
                  <CustomRadioToggle
                    label={formatMessage({ id: 'sensors.command.section.defence.detection' })}
                    isDisabled={!status || !canSendDetection || !isSegmentCommandable}
                    isChecked={!!status && !cachedIsRepulsionOn}
                    onClick={() => setCachedIsRepulsionOn(false)}
                  />
                  <CustomRadioToggle
                    label={formatMessage({ id: 'sensors.command.section.defence.repulsion' })}
                    isDisabled={!status || !canSendRepulsion || !isSegmentCommandable}
                    isChecked={!!status && cachedIsRepulsionOn}
                    onClick={() => setCachedIsRepulsionOn(true)}
                  />
                  <Button
                    isDisabled={cachedIsRepulsionOn === isRepulsionOn || !isSegmentCommandable}
                    isLoading={isRepulsionCommandLoading}
                    variant="formButtonPrimary"
                    height="48px"
                    width="125px"
                    onClick={() => handleSectionRepulsion(cachedIsRepulsionOn)}
                  >
                    <FormattedMessage id="global.validate" />
                  </Button>
                </VStack>
              </VStack>
            </TabPanel>
          )}
          {canDoSensorMaintenance && (
            <TabPanel>
              <VStack padding={2} gap={2} backgroundColor="neutral.900" width="100%" userSelect="none">
                <DataSheetCardLink
                  icon={FileConfigIcon}
                  label={formatMessage({ id: 'automatonSegments.segmentDetails' })}
                >
                  <VStack padding={4} backgroundColor="neutral.800" width="100%" maxHeight="250px">
                    <MapCustomScrollbar marginTop={0}>
                      <VStack gap={3} alignItems="start">
                        <VStack gap={1} alignItems="start">
                          <Text size="md" color="neutral.300">
                            <FormattedMessage id="automatonSegments.upper" />
                          </Text>
                          {Object.keys(upperStates).length ? (
                            <VStack width="100%" gap={0.5} alignItems="start">
                              {Object.entries(upperStates).map(([key, values]) => {
                                return (
                                  <VStack key={key} alignItems="start" marginLeft={2}>
                                    <Text size="md" color="neutral.300">
                                      {key}
                                    </Text>
                                    {values && (
                                      <VStack alignItems="start" gap={0.25} marginLeft={2}>
                                        {values
                                          .toSorted((a, b) => a.localeCompare(b))
                                          .map((value) => (
                                            <Text key={value} wordBreak="break-word">
                                              {value}
                                            </Text>
                                          ))}
                                      </VStack>
                                    )}
                                  </VStack>
                                );
                              })}
                            </VStack>
                          ) : (
                            <Text wordBreak="break-word">
                              <FormattedMessage id="sensors.status.NODATA" />
                            </Text>
                          )}
                        </VStack>
                        <VStack gap={1} alignItems="start">
                          <Text size="md" color="neutral.300">
                            <FormattedMessage id="automatonSegments.lower" />
                          </Text>
                          {Object.keys(lowerStates).length ? (
                            <VStack width="100%" gap={0.5} alignItems="start">
                              {Object.entries(lowerStates).map(([key, values]) => {
                                return (
                                  <VStack key={key} alignItems="start" marginLeft={2}>
                                    <Text size="md" color="neutral.300">
                                      {key}
                                    </Text>
                                    {values && (
                                      <VStack alignItems="start" gap={0.25} marginLeft={2}>
                                        {values
                                          .toSorted((a, b) => a.localeCompare(b))
                                          .map((value) => (
                                            <Text key={value} wordBreak="break-word">
                                              {value}
                                            </Text>
                                          ))}
                                      </VStack>
                                    )}
                                  </VStack>
                                );
                              })}
                            </VStack>
                          ) : (
                            <Text wordBreak="break-word">
                              <FormattedMessage id="sensors.status.NODATA" />
                            </Text>
                          )}
                        </VStack>
                      </VStack>
                    </MapCustomScrollbar>
                  </VStack>
                </DataSheetCardLink>
                <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%">
                  <HStack gap={2}>
                    <CustomSwitch
                      isChecked={!isSectionActive(automaton, cabinet, section)}
                      isDisabled={!hasRoleEditAutomaton}
                      onClick={handleSectionActivation}
                    />
                    <Icon as={BeaconIcon} width="24px" height="24px" color="neutral.white" />
                    <Text size="md">
                      <FormattedMessage id="contextmenu.actions.disconnectSection" />
                    </Text>
                  </HStack>
                  <HStack gap={2}>
                    <CustomSwitch
                      isChecked={isUnderMaintenance}
                      isDisabled={!hasRoleEditAutomaton}
                      onClick={handleSectionMaintenance}
                    />
                    <Icon as={WorkIcon} width="24px" height="24px" color="neutral.white" />
                    <Text size="md">
                      <FormattedMessage id="contextmenu.actions.maintenanceOn" />
                    </Text>
                  </HStack>
                </VStack>
                <HStack backgroundColor="alertHi.900" paddingX={3} paddingY={2} width="100%">
                  <Text whiteSpace="pre-line" fontSize="16px" userSelect="none">
                    <FormattedMessage id="sensors.emergency" />
                  </Text>
                  <Text variant="space" width="100%" textAlign="center" fontSize="24px">
                    <FormattedMessage id="global.adminNumber" />
                  </Text>
                  <Icon as={PhoneIcon} color="alertHi.500" width="40px" height="40px" />
                </HStack>
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </DraggableControl>
  );
}
