import { FormControl } from '@chakra-ui/form-control';
import { HStack, Text } from '@chakra-ui/layout';

import { Coordinates } from '@/types/commons/commons.types';

import CustomInputNumber from '../CustomInputNumber';

type Props = {
  color?: string;
  coordinates: Coordinates;
  onLatitudeChange: (latitude: number | undefined) => void;
  onLongitudeChange: (longitude: number | undefined) => void;
};

export default function DDCoordinatesInput({
  color = 'neutral.black',
  coordinates,
  onLatitudeChange,
  onLongitudeChange,
}: Props) {
  return (
    <HStack>
      <Text color={color}>Lat.</Text>
      <FormControl id="latitude" flexDirection="row">
        <CustomInputNumber
          value={coordinates.latitude.toString()}
          width="185px"
          min={0}
          max={90}
          onChange={onLatitudeChange}
        />
      </FormControl>
      <Text color={color}>Long.</Text>
      <FormControl id="longitude">
        <CustomInputNumber
          value={coordinates.longitude.toString()}
          min={0}
          max={180}
          width="185px"
          onChange={onLongitudeChange}
        />
      </FormControl>
    </HStack>
  );
}
