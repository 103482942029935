import {
  initializationMaskingZoneFillLayer,
  initializationMaskingZoneMarginLayer,
  initializationMaskingZoneMarginOutlineLayer,
  initializationMaskingZoneOutlineLayer,
  noFlyZoneFillLayer,
  noFlyZoneOutlineLayer,
  noInitZoneFillLayer,
  noInitZoneOutlineLayer,
} from '@components/map/layers/zones/zone.layer';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useZoneContextMenu } from '@hooks/useZoneContextMenu';
import { selectFeaturesBySelectedOptions } from '@redux/config/config.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

function ZoneLayer() {
  const { mapId } = useMapContext();
  const zoneFeatures = useAppSelector((state) => selectFeaturesBySelectedOptions(state, mapId));
  useZoneContextMenu();

  if (!isFeatureCollection(zoneFeatures)) return null;

  return (
    <Source id={SourceNameEnum.ZONES} type="geojson" data={zoneFeatures} generateId>
      <Layer key={LayerNameEnum.NO_INIT_ZONE_FILLS} {...noInitZoneFillLayer} />
      <Layer key={LayerNameEnum.NO_INIT_ZONE_OUTLINES} {...noInitZoneOutlineLayer} />
      <Layer key={LayerNameEnum.NO_FLY_ZONE_FILLS} {...noFlyZoneFillLayer} />
      <Layer key={LayerNameEnum.NO_FLY_ZONE_OUTLINES} {...noFlyZoneOutlineLayer} />
      <Layer key={LayerNameEnum.INITIALIZATION_MASKING_ZONE_FILLS} {...initializationMaskingZoneFillLayer} />
      <Layer key={LayerNameEnum.INITIALIZATION_MASKING_ZONE_OUTLINES} {...initializationMaskingZoneOutlineLayer} />
      <Layer key={LayerNameEnum.INITIALIZATION_MASKING_ZONE_MARGINS} {...initializationMaskingZoneMarginLayer} />
      <Layer
        key={LayerNameEnum.INITIALIZATION_MASKING_ZONE_MARGIN_OUTLINES}
        {...initializationMaskingZoneMarginOutlineLayer}
      />
    </Source>
  );
}

export default ZoneLayer;
