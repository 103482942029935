import { AbsoluteCenter, Box } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectReplaySpeed } from '@redux/replay/replay.selectors';
import { PerimeterCameraSelectors } from '@redux/sensors/sensors.selectors';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { useUpdatePerimeterCameraConfigurationMutation } from '@services/c2/c2.api';
import { formatDate } from '@utils/date.utils';
import { addDateToSourceReplayUrl, isValidUrl } from '@utils/replay/replay.utils';
import { getNimbleExternalReplayUrl, getNimbleStreamUrl } from '@utils/sensors/camera/camera.utils';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

import Player from '../../common/video/Player';
import CameraBorder from './CameraBorder';
import CameraControlTitle from './CameraControlTitle';

interface OwnProps {
  selectedPerimeterCameraCode: string | null;
  playerId: string;
}

function CameraPerimReplay({ selectedPerimeterCameraCode, playerId }: Readonly<OwnProps>) {
  const perimeterCamera: PerimeterCamera | undefined = useAppSelector(
    (state) => PerimeterCameraSelectors.selectPerimeterCameraByUniqueCode(state, selectedPerimeterCameraCode ?? ''),
    isEqual,
  );
  const [updatePericam] = useUpdatePerimeterCameraConfigurationMutation();

  const replayDateRaw: string | null = useAppSelector((state) => selectSituationTime(state, true));
  const replayDate: string | null = replayDateRaw
    ? formatDate(new Date(replayDateRaw), DateTimeEnum.DATE_TIME_SECONDS_PERIM_FORMAT)
    : null;
  const replaySpeed = useAppSelector(selectReplaySpeed);

  const [prevIsPaused, setPrevIsPaused] = useState<boolean>(replaySpeed === 0);
  const [prevCameraCode, setPrevCameraCode] = useState<string | null>(null);
  const [prevReplayDate, setPrevReplayDate] = useState<string | null>(null);

  // Quand la frise est en lecture replayDate avance chaque seconde. lastTimeStamp est utilisé pour éviter qu'un PUT
  // parte toutes les secondes
  const [lastTimeStamp, setLastTimeStamp] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      replayDate &&
      (prevCameraCode !== selectedPerimeterCameraCode ||
        prevReplayDate !== replayDate ||
        (prevIsPaused && replaySpeed !== 0)) &&
      perimeterCamera &&
      isValidUrl(perimeterCamera.sourceReplayUrl)
    ) {
      setPrevCameraCode(selectedPerimeterCameraCode);

      let tmpLastTimeStamp = lastTimeStamp;
      if (lastTimeStamp || (replayDate && prevReplayDate)) {
        tmpLastTimeStamp = Math.abs(new Date(replayDate).getTime() - new Date(prevReplayDate ?? 0).getTime());
        setLastTimeStamp(tmpLastTimeStamp);
      }
      setPrevReplayDate(replayDate);

      // 0.2 sec added due to latency
      if ((tmpLastTimeStamp && tmpLastTimeStamp > 1200) || (prevIsPaused && replaySpeed !== 0)) {
        setPrevIsPaused(false);
        updatePericam({
          id: perimeterCamera.id,
          site: perimeterCamera.site,
          pericam: {
            ...perimeterCamera,
            sourceReplayUrl: addDateToSourceReplayUrl(perimeterCamera.sourceReplayUrl!, replayDate),
          },
        });
      }
    }

    if (!prevIsPaused && replaySpeed === 0) {
      // Si on passe de Lecture à Pause
      setPrevIsPaused(true);
    }
  }, [
    perimeterCamera,
    prevCameraCode,
    prevReplayDate,
    replayDate,
    selectedPerimeterCameraCode,
    updatePericam,
    replaySpeed,
    lastTimeStamp,
    prevIsPaused,
  ]);

  const displayPlayer = useCallback(() => {
    if (selectedPerimeterCameraCode && perimeterCamera && replayDateRaw) {
      return (
        <Box width="100%" height="100%">
          <Player
            id={playerId}
            src={getNimbleExternalReplayUrl(perimeterCamera) ?? getNimbleStreamUrl(perimeterCamera, true)}
            type={perimeterCamera.protocol ?? perimeterCamera.sourceProtocol}
            isPaused={replaySpeed === 0}
          />
        </Box>
      );
    }

    const statusMessage = !replayDateRaw ? 'cameras.noReplayTime' : 'cameras.noFlux';

    return (
      <AbsoluteCenter>
        <Text>
          <FormattedMessage id={!selectedPerimeterCameraCode ? 'cameras.noSelectedCamera' : statusMessage} />
        </Text>
      </AbsoluteCenter>
    );
  }, [selectedPerimeterCameraCode, perimeterCamera, playerId, replayDateRaw, replaySpeed]);

  return (
    <>
      <CameraBorder hasControl={false} />
      <CameraControlTitle replayMode />
      {displayPlayer()}
    </>
  );
}

export default CameraPerimReplay;
