import { useAutomatonCommand } from '@hooks/useAutomatonCommand';
import {
  automatonHasOneCabinetPoweredOff,
  automatonHasOneCabinetPoweredOn,
  getAutomatonSegmentStatuses,
  getDefenceState,
} from '@utils/sensors/sensors.utils';

import { MenuItems } from '@/types/menu.types';
import {
  AutomatonConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
} from '@/types/sensor/configuration.types';
import { AutomatonSensorStatus, SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

export function useAutomatonSensorContextMenu(
  configuration: SensorConfiguration | SubSensorConfiguration | null,
  status: SubSensorStatus | SensorStatus | null,
): MenuItems | null {
  const isAutomatonConfiguration = configuration?.type === SensorTypeEnum.AUTOMATON;
  const isAutomatonStatus = !status || status.type === SensorTypeEnum.AUTOMATON;

  const useAutomaton = useAutomatonCommand({
    automatonStatus: status as AutomatonSensorStatus | null,
    automatonConfiguration: configuration as AutomatonConfiguration | null,
  });

  if (!useAutomaton.canCommand || !isAutomatonConfiguration || !isAutomatonStatus) {
    return null;
  }

  const segmentStatuses = getAutomatonSegmentStatuses(status);
  const defenceState = getDefenceState(segmentStatuses);

  return {
    powerOnCabinets: {
      label: `contextmenu.actions.automaton.sleep.on`,
      isLoading: useAutomaton.isSleepAllCommandLoading,
      isDisabled: !status || !automatonHasOneCabinetPoweredOff(status),
      onAction: () => useAutomaton.sleepAll(true),
    },
    powerOffCabinets: {
      label: `contextmenu.actions.automaton.sleep.off`,
      isLoading: useAutomaton.isSleepAllCommandLoading,
      isDisabled: !status || !automatonHasOneCabinetPoweredOn(status),
      onAction: () => useAutomaton.sleepAll(false),
    },
    disableCabinetsRepulsion: {
      label: `contextmenu.actions.automaton.defence.detection`,
      isLoading: useAutomaton.isRepulsionCommandLoading,
      isDisabled: !status || defenceState === 'detection',
      isHidden: !useAutomaton.canDetect,
      onAction: () => useAutomaton.repulse(false),
    },
    enableCabinetsRepulsion: {
      label: `contextmenu.actions.automaton.defence.repulsion`,
      isLoading: useAutomaton.isRepulsionCommandLoading,
      isDisabled: !status || defenceState === 'repulsion',
      isHidden: !useAutomaton.canRepulse,
      onAction: () => useAutomaton.repulse(true),
    },
  };
}
