import { HStack, Text } from '@chakra-ui/layout';
import CustomTitle from '@components/common/CustomTitle';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { MapIdEnum } from '@/types/map.types';

import { useMapContext } from './MapContext';

export default function MapTime() {
  const { mapId } = useMapContext();
  const situationTime = useSelectorWithReplayMode(selectSituationTime);

  return (
    <CustomTitle>
      {situationTime && (
        <>
          <Text
            fontWeight="medium"
            fontSize="16px"
            color={mapId === MapIdEnum.REPLAY ? 'sky.500' : 'alertHi.500'}
            noOfLines={1}
            userSelect="none"
          >
            <FormattedMessage id={`mapLabel.${mapId.toLowerCase()}`} />
          </Text>
          <HStack gap={0}>
            <Text size="md" color="neutral.200">
              {format(new Date(situationTime), 'HH:mm')}
            </Text>
            <Text fontSize="16px" color="neutral.300">
              {format(new Date(situationTime), ':ss')}
            </Text>
          </HStack>
          <Text fontSize="16px" color="neutral.200">
            {format(new Date(situationTime), 'dd/MM/yyyy')}
          </Text>
        </>
      )}
    </CustomTitle>
  );
}
