import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Box, HStack, List, ListItem, Text } from '@chakra-ui/layout';
import { Button, Card, CardBody } from '@chakra-ui/react';
import CustomInputDateTimeRange from '@components/common/inputs/CustomInputDateTimeRange';
import CustomSelect, { Option } from '@components/common/inputs/CustomSelect';
import DraggableControl from '@components/map/controls/DraggableControl';
import CameraInfo from '@components/replay/CameraInfo';
import { useReplayTimelineContext } from '@components/replay/ReplayTimelineContext';
import VideoExportSubmit from '@components/replay/videoExport/VideoExportSubmit';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { closePopupByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { useGetAvailableCamerasQuery } from '@services/replay/replay.api';
import { cameraLiteToOption, isCamInListByCode } from '@utils/sensors/camera/camera.utils';
import { FastField, Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RangeProps } from '@/types/replay/replay.types';
import { CameraLiteConfiguration, SubCameraLiteConfiguration } from '@/types/sensor/configuration.types';

import { dateSchema } from '../../../validations/date.schema';

export default function VideoExport() {
  const validationSchema = useValidation(dateSchema);
  const { mapId, range } = useReplayTimelineContext();
  const { formatMessage } = useIntl();

  const closePopupByMap = useWithDispatch(closePopupByMapId);
  const closePopupControl = useCallback(() => closePopupByMap(mapId), [closePopupByMap, mapId]);
  const popupControl = useAppSelector((state) => selectPopupControlByMapId(state, mapId));
  const { activeSite } = useAppSelector(selectActiveUser);

  const [dateFrom, setDateFrom] = useState<Date>(range.from);
  const [dateTo, setDateTo] = useState<Date>(range.to);
  const [selectedCameras, setSelectedCameras] = useState<CameraLiteConfiguration[]>([]);
  const [selectedCameraOption, setSelectedCameraOption] = useState<CameraLiteConfiguration | null>(null);
  const [selectedSubCameras, setSelectedSubCameras] = useState<SubCameraLiteConfiguration[]>([]);
  const [previousCamerasState, setPreviousCamerasState] = useState<CameraLiteConfiguration[] | undefined>(undefined);

  // Sera utilisé pour mettre en commun et modifier les éléments sélectionnés dans les différentes chipList
  const [selectedSubCamsOrderedByParentCam, setSelectedSubCamsOrderedByParentCam] = useState<
    Map<string, SubCameraLiteConfiguration[]>
  >(new Map<string, SubCameraLiteConfiguration[]>());
  const alternateNameIfDeletedCam = formatMessage({ id: 'videoExport.deletedCam' });

  // Appel au back pour avoir la liste des caméras dispos
  const { data: availableCameras, isFetching } = useGetAvailableCamerasQuery(
    {
      site: activeSite!.code,
      from: dateFrom.getTime(),
      to: dateTo.getTime(),
    },
    {
      skip: !activeSite || dateFrom.getTime() >= dateTo.getTime(),
      refetchOnMountOrArgChange: true,
    },
  );

  const regroupSubCameraSelection = (orderedSubCamMap: Map<string, SubCameraLiteConfiguration[]>) => {
    const allSelectedSubCams: SubCameraLiteConfiguration[] = [];
    orderedSubCamMap.forEach((subCamList) => {
      subCamList.forEach((subCam) => allSelectedSubCams.push(subCam));
    });
    setSelectedSubCameras(allSelectedSubCams);
  };

  const handleSelectSubCamera = (newSubCameraList: SubCameraLiteConfiguration[], parentCameraCode: string) => {
    const newSubCamOrderedMap = selectedSubCamsOrderedByParentCam;
    newSubCamOrderedMap.set(parentCameraCode, newSubCameraList);
    setSelectedSubCamsOrderedByParentCam(newSubCamOrderedMap);

    regroupSubCameraSelection(selectedSubCamsOrderedByParentCam);
  };

  const removeUnavailableCamerasFromSelectedCameraList = (): CameraLiteConfiguration[] => {
    const originalCameraList = selectedCameras.filter(
      (camera) => availableCameras && isCamInListByCode(camera, availableCameras),
    );
    return structuredClone(originalCameraList);
  };

  const removeUnavailableSubCamsFromSelectedSubCamList = () => {
    const previousStateMap: Map<string, SubCameraLiteConfiguration[]> = selectedSubCamsOrderedByParentCam;

    previousStateMap.forEach((subCams, parentCamCode) => {
      previousStateMap.set(
        parentCamCode,
        subCams.filter((subCam) => {
          const foundCam: CameraLiteConfiguration | undefined = (availableCameras as CameraLiteConfiguration[]).find(
            (cam) => cam.code === parentCamCode,
          );
          return foundCam?.subCameras.some((availableSubCam) => availableSubCam.code === subCam.code);
        }),
      );
    });
    regroupSubCameraSelection(previousStateMap);
  };

  const updateAvailableSubCamerasInCamera = (cameraToUpdate: CameraLiteConfiguration): SubCameraLiteConfiguration[] => {
    const newCameraFromBackend: CameraLiteConfiguration | undefined = (
      availableCameras as CameraLiteConfiguration[]
    ).find((cam) => cam.code === cameraToUpdate.code);

    let subCamerasToReturn: SubCameraLiteConfiguration[] = cameraToUpdate.subCameras;
    if (newCameraFromBackend) {
      // Enlève les sous Cam plus dispos, et rajoute les nouvelles
      subCamerasToReturn = newCameraFromBackend.subCameras;
    }

    return subCamerasToReturn;
  };

  if (availableCameras != previousCamerasState) {
    if (availableCameras !== undefined && selectedCameras.length > 0) {
      // A chaque fois que availableCameras change, on garde dans selectedCameras que les cameras qui existent dans
      // la nouvelle liste de caméras actives. Pareil pour les sous cameras

      // Copie locale de selectedCameras pour éviter les problèmes de synchronisation des state (on n'a pas accès
      // à la nouvelle valeur d'un state juste après l'avoir modifié)
      const cameraListToPush: CameraLiteConfiguration[] = removeUnavailableCamerasFromSelectedCameraList();

      // Enlève les sous caméra qui sont plus disponibles du useState des sous caméra cochées ou en rajoute + update la
      // liste affichée
      cameraListToPush.forEach((camera) => (camera.subCameras = [...updateAvailableSubCamerasInCamera(camera)]));

      if (selectedCameraOption !== null) {
        setSelectedCameraOption((prev) => (isCamInListByCode(prev!, availableCameras) ? prev : null));
      }
      //Qd tout est bon, on update la nouvelle liste de cam
      setSelectedCameras(cameraListToPush);

      // Enlève du selecteur selectedSubCam (qui sera envoyé au back) les sous cam qui sont plus dispos
      removeUnavailableSubCamsFromSelectedSubCamList();
    }
    setPreviousCamerasState(availableCameras);
  }

  const handleSelectCamera = (camera: CameraLiteConfiguration) => {
    if (!isCamInListByCode(camera, selectedCameras)) {
      setSelectedCameras((prev) => [...prev, camera]);
      setSelectedCameraOption(null);

      if (camera.subCameras.length > 0) {
        handleSelectSubCamera([camera.subCameras[0]], camera.code);
      }
    }
  };

  const sortedCameraOptions = (): Option[] => {
    if (availableCameras) {
      return availableCameras
        .filter((camera) => !selectedCameras.map((camera) => camera.code).includes(camera.code))
        .sort((a, b) => b.type.localeCompare(a.type))
        .map((camera) => cameraLiteToOption(camera, alternateNameIfDeletedCam));
    } else {
      return [];
    }
  };

  const subTitleMarginLeft = '20px';
  const cameraListMarginLeft = '50px';

  return (
    <DraggableControl
      label={formatMessage({ id: 'videoExport.title' })}
      top={popupControl.position.y}
      left={popupControl.position.x}
      onClose={closePopupControl}
      offsetX={-600}
      offsetY={-630}
      height={530}
      width={600}
    >
      <Card
        variant="unstyled"
        borderRadius={0}
        paddingLeft="24px"
        height="full"
        width="full"
        gap={0}
        backgroundColor="neutral.700"
      >
        <CardBody>
          <Formik initialValues={{ dateRange: range }} validationSchema={validationSchema} onSubmit={() => undefined}>
            {({ errors, touched, setTouched, isValid, values, setFieldValue }) => (
              <Form>
                <FormControl marginTop="20px" isInvalid={touched.dateRange && !!errors.dateRange}>
                  <Text color="neutral.white">
                    <FormattedMessage id="videoExport.confirmExport" />
                  </Text>
                  <br />

                  <Text marginLeft={subTitleMarginLeft}>
                    &#x2022; <FormattedMessage id="videoExport.exportRange" />
                  </Text>
                  <Box marginLeft={cameraListMarginLeft}>
                    <FastField
                      as={CustomInputDateTimeRange}
                      id="dateRange"
                      name="dateRange"
                      range={values.dateRange}
                      onChange={async (range: RangeProps) => {
                        await setTouched({ dateRange: { from: true, to: true } });
                        await setFieldValue('dateRange', range);
                        setDateFrom(range.from);
                        setDateTo(range.to);
                      }}
                      singleLine={false}
                    />
                    <FormErrorMessage>
                      {!!errors.dateRange?.from && <Text>{errors.dateRange.from as string}</Text>}
                      {!!errors.dateRange?.to && <Text>{errors.dateRange.to as string}</Text>}
                    </FormErrorMessage>
                  </Box>

                  <Text marginLeft={subTitleMarginLeft} marginTop="20px">
                    &#x2022; <FormattedMessage id="videoExport.videoStream" />
                  </Text>
                  <Box marginLeft={cameraListMarginLeft}>
                    <Box maxHeight={'110px'} overflowY={'auto'}>
                      <List>
                        {selectedCameras.map((camera) => {
                          return (
                            <ListItem key={camera.code}>
                              <CameraInfo
                                onChange={(newSubCameraList: SubCameraLiteConfiguration[], parentCameraCode: string) =>
                                  handleSelectSubCamera(newSubCameraList, parentCameraCode)
                                }
                                camera={camera}
                                currentSelectedSubCams={selectedSubCameras.filter((subCam) =>
                                  isCamInListByCode(subCam, camera.subCameras),
                                )}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>

                    {!isFetching ? (
                      availableCameras && availableCameras.length != 0 ? (
                        <HStack gap={8} marginTop="15px">
                          <CustomSelect
                            size="sm"
                            options={sortedCameraOptions()}
                            value={selectedCameraOption?.code}
                            isClearable={false}
                            variant="unstyled"
                            menuPortalWidth="95px"
                            onChange={(cameraCode) =>
                              setSelectedCameraOption(
                                availableCameras.find((camera) => camera.code == cameraCode) ?? null,
                              )
                            }
                            style={{
                              width: '172px',
                              height: 42,
                              color: '#2D3748',
                            }}
                            placeholder={formatMessage({ id: 'cameras.cameraWord' })}
                          />

                          <Button
                            type="button"
                            isDisabled={selectedCameraOption == undefined}
                            onClick={() => handleSelectCamera(selectedCameraOption!)}
                          >
                            <FormattedMessage id="videoExport.addVideoStream" />
                          </Button>
                        </HStack>
                      ) : (
                        <Box textColor="white">
                          {' '}
                          <FormattedMessage id="videoExport.noStreamAvailable" />
                          <br />
                          <FormattedMessage id="videoExport.changeDateRange" />{' '}
                        </Box>
                      )
                    ) : (
                      <Text marginLeft="90px">
                        <FormattedMessage id="videoExport.reloading" />
                      </Text>
                    )}
                  </Box>
                </FormControl>
                <br />
                <VideoExportSubmit
                  isFormValid={isValid}
                  activeSite={activeSite}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  subCamerasToSend={selectedSubCameras}
                  isFetching={isFetching}
                  onPopupClose={closePopupControl}
                />
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </DraggableControl>
  );
}
