import './buttonStyle.scss';

import { ReactComponent as Background_48x48 } from '@assets/components/button/button-bar-48x48.svg';
import { ReactComponent as Background_51x59 } from '@assets/components/button/button-bar-51x59.svg';
import { ReactComponent as Background_51x120 } from '@assets/components/button/button-bar-51x120.svg';
import { ReactComponent as Background_72x60 } from '@assets/components/button/button-bar-72x60.svg';
import { ReactComponent as Background_72x72 } from '@assets/components/button/button-bar-72x72.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, Center } from '@chakra-ui/layout';
import { IconButton, Spinner, Text, Tooltip } from '@chakra-ui/react';
import classNames from 'classnames';
import { FunctionComponent, memo, ReactNode, SVGProps } from 'react';

interface OwnProps {
  label: string;
  orientation?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  size?: 'sm' | 'md' | 'weird' | 'tall' | 'flat';
  variant?: 'default' | 'orange';
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  isActive?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  children?: ReactNode;
  isLoading?: boolean;
  hideTooltip?: boolean;
  tooltipPlacement?: 'top' | 'right' | 'left';
  onClick?: () => void;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
}

function ButtonBar({
  label,
  orientation = 'bottom-right',
  icon,
  size = 'md',
  variant = 'default',
  isActive = false,
  isSelected = false,
  isDisabled = false,
  isHidden = false,
  children,
  isLoading = false,
  hideTooltip = false,
  tooltipPlacement = 'top',
  onClick,
  onMouseUp,
  onMouseDown,
}: Readonly<OwnProps>) {
  if (isHidden) {
    return null;
  }

  function getBackground(): FunctionComponent<SVGProps<SVGSVGElement>> {
    switch (size) {
      case 'sm':
        return Background_48x48;
      case 'weird':
        return Background_51x59;
      case 'tall':
        return Background_51x120;
      case 'flat':
        return Background_72x60;
      case 'md':
      default:
        return Background_72x72;
    }
  }

  function getOrientation() {
    switch (orientation) {
      case 'top-right':
        return 'scaleY(-1) scaleX(-1)';
      case 'top-left':
        return 'scaleY(-1)';
      case 'bottom-left':
        return 'scaleX(-1)';
      case 'bottom-right':
      default:
        return undefined;
    }
  }

  const IconOrLabel = icon ? (
    <Icon as={icon} className="icon" position="absolute" />
  ) : (
    <Text position="absolute" className="label">
      {label.slice(0, 3)}
    </Text>
  );

  return (
    <Box position="relative">
      <Tooltip label={label} isDisabled={isDisabled || hideTooltip} placement={tooltipPlacement} openDelay={300}>
        <IconButton
          aria-label={label}
          className={classNames('button-bar', variant, size, { isActive }, { isSelected }, { isDisabled })}
          width="fit-content"
          height="fit-content"
          colorScheme="transparent"
          icon={
            <Center>
              <Icon as={getBackground()} className="background" transform={getOrientation()} />
              {isLoading ? <Spinner position="absolute" size="sm" color="neutral.white" /> : IconOrLabel}
            </Center>
          }
          isActive={isActive}
          onClick={() => !isDisabled && onClick && onClick()}
          onMouseUp={() => !isDisabled && onMouseUp && onMouseUp()}
          onMouseDown={() => !isDisabled && onMouseDown && onMouseDown()}
        />
      </Tooltip>

      {children && (
        <Box position="absolute" top="-8px" left="50%" transform="translate(-50%, -100%)">
          {children}
        </Box>
      )}
    </Box>
  );
}

export default memo(ButtonBar);
