import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { HStack, Text } from '@chakra-ui/layout';
import { Button, Card, CardBody } from '@chakra-ui/react';
import CustomInputDateTimeRange from '@components/common/inputs/CustomInputDateTimeRange';
import DraggableControl from '@components/map/controls/DraggableControl';
import { useReplayTimelineContext } from '@components/replay/ReplayTimelineContext';
import { useAppSelector } from '@hooks/redux.hooks';
import useValidation from '@hooks/useValidation';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { closePopupByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { selectReplayUuid } from '@redux/replay/replay.selectors';
import { useUpdateStreamMutation } from '@services/replay/replay.api';
import { getNewTimelineEndAndTimeScaleFromRange } from '@utils/replay/replay.utils';
import { FastField, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { RangeProps } from '@/types/replay/replay.types';

import { dateSchema } from '../../validations/date.schema';

export default function ReplayDatePopupForm() {
  const validationSchema = useValidation(dateSchema);
  const { formatMessage } = useIntl();
  const { mapId, range, setDateRange, setTimeScale } = useReplayTimelineContext();
  const init = { dateRange: range };
  const closePopupControl = useWithDispatch(closePopupByMapId);
  const popupControl = useAppSelector((state) => selectPopupControlByMapId(state, mapId));
  const replayUuid = useAppSelector(selectReplayUuid);
  const [updateStream] = useUpdateStreamMutation();

  function handleSubmit(values: { dateRange: RangeProps }) {
    const { newEndTime, newTimeScale } = getNewTimelineEndAndTimeScaleFromRange(values.dateRange);
    setTimeScale(newTimeScale);
    setDateRange(values.dateRange.from, newEndTime);
    if (replayUuid) {
      updateStream({ uuid: replayUuid, command: { time: values.dateRange.from, speed: 0 } });
    }
    closePopupControl(mapId);
  }

  return (
    <DraggableControl
      label={formatMessage({ id: 'replay.search.datePopup.title' })}
      top={popupControl.position.y}
      left={popupControl.position.x}
      width={850}
      offsetY={-300}
      offsetX={0}
      height={200}
      onClose={() => closePopupControl(mapId)}
    >
      <Card variant="unstyled" borderRadius={0} height="200px" gap={1} backgroundColor="neutral.700">
        <CardBody padding="20px">
          <Formik initialValues={init} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, touched, setTouched, dirty, isValid, values, setFieldValue }) => (
              <Form>
                <FormControl marginTop={2} isInvalid={touched.dateRange && !!errors.dateRange}>
                  <FormLabel variant="mapFormLabel">
                    <FormattedMessage id="replay.search.datePopup.description" />
                  </FormLabel>
                  <FastField
                    as={CustomInputDateTimeRange}
                    id="dateRange"
                    name="dateRange"
                    range={values.dateRange}
                    onChange={async (range: RangeProps) => {
                      await setTouched({ dateRange: { from: true, to: true } });
                      await setFieldValue('dateRange', range);
                    }}
                  />
                  <FormErrorMessage>
                    {!!errors.dateRange?.from && <Text>{errors.dateRange.from as string}</Text>}
                    {!!errors.dateRange?.to && <Text>{errors.dateRange.to as string}</Text>}
                  </FormErrorMessage>
                </FormControl>

                <HStack alignItems="center" justify="center" margin={1} marginTop={3} gap="20px">
                  <Button
                    type="button"
                    variant="formButtonSecondary"
                    width="fit-content"
                    onClick={() => closePopupControl(mapId)}
                  >
                    <FormattedMessage id="global.cancel" />
                  </Button>
                  <Button type="submit" variant="formButtonPrimary" width="fit-content" isDisabled={!dirty || !isValid}>
                    <FormattedMessage id="replay.search.datePopup.validate" />
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </DraggableControl>
  );
}
