export function getFileNameFromHttpResponse(response: Response, alternateName: string): string {
  let filename = alternateName;
  const contentDisposition = response.headers.get('Content-Disposition');
  if (contentDisposition) {
    const matches = contentDisposition.match(/filename=["']?([^"']+)["']?/);
    if (matches && matches.length > 1) {
      filename = matches[1];
    }
  }
  return filename;
}
