import { Center, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { FormikErrors, FormikTouched } from 'formik';

type Props<T> = {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  apiError?: string;
  width?: string;
  height?: string;
};

export default function ErrorsContainer<T>({
  errors,
  touched,
  apiError,
  width = '100%',
  height = '100%',
}: Readonly<Props<T>>) {
  const values = Array.from(
    new Map(
      Object.entries(errors).filter(([key]) =>
        Object.entries(touched).some(([touchedKey, touchedValue]) => key === touchedKey && touchedValue),
      ),
    ),
  );

  if (values.length === 0 && !apiError) {
    return null;
  }

  return (
    <Center backgroundColor="alertHi.100" width={width} height={height} minHeight="52px" padding={1}>
      <VStack maxHeight="100%" overflow="auto" textAlign="center" gap={0.5}>
        {values.map(([key, value]) => (
          <Text key={key} color="alertHi.500" fontSize="16px">
            {value as string}
          </Text>
        ))}
        {apiError && (
          <Text key="api-error" color="alertHi.500" fontSize="16px">
            {apiError}
          </Text>
        )}
      </VStack>
    </Center>
  );
}
