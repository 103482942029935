import { Layout } from 'react-grid-layout';

import { MapIdEnum } from '@/types/map.types';

import { Abstract } from '../commons/commons.types';

export enum GridItemComponentEnum {
  CAMERA_CONTROL = 'CAMERA_CONTROL',
  DYNAMIC_CAMERA_CONTROL = 'DYNAMIC_CAMERA_CONTROL',
  MAP = 'MAP',
  SEARCH = 'SEARCH',
  CAMERA_REPLAY = 'CAMERA_REPLAY',
  TIME_BAR = 'TIME_BAR',
  LAD_STREAM_GRID = 'LAD_STREAM_GRID',
  PERIMETER_STREAM_GRID = 'PERIMETER_STREAM_GRID',
  PERIMETER_DOUBT_CHECK = 'PERIMETER_DOUBT_CHECK',
  TARGET_DOUBT_CHECK = 'TARGET_DOUBT_CHECK',
}

export type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';

export interface BaseGridItem extends Layout {
  component: GridItemComponentEnum;
  resizeHandles?: ResizeHandle[];
}

export type CameraControlGridItem =
  | {
      component: Exclude<
        GridItemComponentEnum,
        GridItemComponentEnum.CAMERA_CONTROL | GridItemComponentEnum.DYNAMIC_CAMERA_CONTROL
      >;
      selectedCamera?: never;
    }
  | {
      component: GridItemComponentEnum.CAMERA_CONTROL | GridItemComponentEnum.DYNAMIC_CAMERA_CONTROL;
      selectedCamera: string | null;
    };

export type StreamGridItem =
  | {
      component: Exclude<
        GridItemComponentEnum,
        GridItemComponentEnum.LAD_STREAM_GRID | GridItemComponentEnum.PERIMETER_STREAM_GRID
      >;
      innerGridWidth?: never;
      innerGridHeight?: never;
      selectedStreams?: never;
    }
  | {
      component: GridItemComponentEnum.LAD_STREAM_GRID;
      innerGridWidth: number;
      innerGridHeight: number;
      selectedStreams: string[];
    }
  | {
      component: GridItemComponentEnum.PERIMETER_STREAM_GRID;
      innerGridWidth: number;
      innerGridHeight: number;
      selectedStreams?: never;
    };

export type MapGridItem =
  | {
      component: Exclude<GridItemComponentEnum, GridItemComponentEnum.MAP>;
      selectedMap?: never;
    }
  | { component: GridItemComponentEnum.MAP; selectedMap: MapIdEnum };

export type GridItem = BaseGridItem & CameraControlGridItem & StreamGridItem & MapGridItem;

export interface GridLayout extends Abstract {
  id: number;
  code: string;
  site: string;
  name: string;
  nbCol: number;
  nbRow: number;
  resolution: string;
  items: GridItem[];
}

export interface GridConfiguration {
  id: number;
  code: string;
  component: GridItemComponentEnum;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number | null;
  minH: number | null;
  innerGridWidth: number | null;
  innerGridHeight: number | null;
  isResizable: boolean;
}
