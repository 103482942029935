import PerimeterCameraInfo from '@components/map/infos/perimeter/PerimeterCameraInfo';
import SensorInfo from '@components/map/infos/sensors/SensorInfo';
import { useMapContext } from '@components/map/MapContext';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { closePopupByMapId, deselectFeatureByMapId, updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId, selectSelectedTargetIdByMapId } from '@redux/maps/maps.selectors';
import { AUTOMATON_SECTION_LAYER_NAMES } from '@utils/map/map.constants';
import { getFeatureId } from '@utils/map/map.utils';
import { EventData, MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { useCallback } from 'react';
import { useMap } from 'react-map-gl';

import { FeatureTypeEnum, InfoData, LayerNameEnum, PopupDataTypeEnum } from '@/types/map.types';
import { SubSensorTypeEnum } from '@/types/sensor/configuration.types';

import AutomatonSectionInfo from './perimeter/AutomatonSectionInfo';
import StrobeInfo from './StrobeInfo';
import TargetInfo from './targets/TargetInfo';

function Info() {
  const { current: mapbox } = useMap();
  const { mapId } = useMapContext();
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const selectedTargetId = useSelectorWithMapId(selectSelectedTargetIdByMapId);
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const closePopupControl = useWithDispatch(closePopupByMapId);
  const deselectFeature = useWithDispatch(deselectFeatureByMapId);

  const handleCloseAndDeselect = () => {
    closePopupControl(mapId);
    deselectFeature(mapId);
  };

  const isMeasureActive = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const handleMouseClick = useCallback(
    (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
      if (mapbox && !isMeasureActive) {
        const feature = event.features?.find((feature) => !!feature.properties);
        if (feature?.properties) {
          const featureId = getFeatureId(feature);
          const featureValue = JSON.parse(feature.properties.value);
          if (selectedTargetId !== featureId) {
            const controlPosition =
              event.originalEvent instanceof MouseEvent
                ? { x: event.originalEvent.clientX, y: event.originalEvent.clientY }
                : { x: 0, y: 0 };
            updatePopupControl({
              mapId,
              popupControl: {
                type: PopupDataTypeEnum.INFO,
                open: true,
                position: controlPosition,
                data: {
                  type:
                    feature.properties.type && feature.properties.type === SubSensorTypeEnum.AUTOMATON_CABINET
                      ? SubSensorTypeEnum.AUTOMATON_CABINET
                      : feature.properties.featureType,
                  featureValue: featureValue,
                  targetId: featureValue.id ?? null,
                },
              },
            });
          }
        }
      }
    },
    [mapId, mapbox, selectedTargetId, updatePopupControl, isMeasureActive],
  );
  useMapLayerMouseEvent('click', handleMouseClick, [
    LayerNameEnum.PERIMETER_CAMERA_LABELS,
    LayerNameEnum.PERIMETER_CAMERA_SECTOR_FILLS,
    ...AUTOMATON_SECTION_LAYER_NAMES,
    LayerNameEnum.PERIMETER_SENSOR_ICONS,
    LayerNameEnum.PERIMETER_SENSOR_LABELS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.SENSOR_ICONS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.STROBE_SECTORS,
    LayerNameEnum.TARGETS,
  ]);

  if (currentPopupControl.type !== PopupDataTypeEnum.INFO) {
    return null;
  }

  function getInfoComponent() {
    const popupData = currentPopupControl.data as InfoData;
    switch (popupData.type) {
      case FeatureTypeEnum.TARGET_ICON:
        return (
          <TargetInfo
            {...popupData}
            top={currentPopupControl.position.y}
            left={currentPopupControl.position.x}
            onClose={handleCloseAndDeselect}
          />
        );
      case FeatureTypeEnum.AUTOMATON_SECTION_ENDING:
      case FeatureTypeEnum.AUTOMATON_SECTION_ICON:
      case FeatureTypeEnum.AUTOMATON_SECTION_LABEL:
      case FeatureTypeEnum.AUTOMATON_SECTION_LINE:
        return (
          <AutomatonSectionInfo
            {...popupData}
            top={currentPopupControl.position.y}
            left={currentPopupControl.position.x}
            onClose={() => closePopupControl(mapId)}
          />
        );
      case FeatureTypeEnum.PERIMETER_CAMERA_SECTOR:
      case FeatureTypeEnum.PERIMETER_CAMERA_LABEL:
        return (
          <PerimeterCameraInfo
            {...popupData}
            top={currentPopupControl.position.y}
            left={currentPopupControl.position.x}
            onClose={() => closePopupControl(mapId)}
          />
        );
      case FeatureTypeEnum.SENSOR_LABEL:
      case FeatureTypeEnum.SENSOR_ICON:
      case SubSensorTypeEnum.AUTOMATON_CABINET:
        return (
          <SensorInfo
            {...popupData}
            top={currentPopupControl.position.y}
            left={currentPopupControl.position.x}
            onClose={handleCloseAndDeselect}
          />
        );
      case FeatureTypeEnum.STROBE_SECTOR:
        return (
          <StrobeInfo
            {...popupData}
            top={currentPopupControl.position.y}
            left={currentPopupControl.position.x}
            onClose={() => closePopupControl(mapId)}
          />
        );
      default:
        return null;
    }
  }

  return getInfoComponent();
}

export default Info;
