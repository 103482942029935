import { Notification, NotificationCriteria, NotificationFutureEnum } from '@/types/data/data.types';

import { DATA_URL, dataApi } from './data.api';

export const notificationApi = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<Notification[], NotificationCriteria>({
      query: ({ site, to, from, identifier, page, types, unpaginated, notificationStatus, size }) => ({
        url: `${DATA_URL}/notifications`,
        params: {
          sites: [site],
          to,
          from,
          identifier,
          notificationStatus,
          page,
          size,
          types,
          unpaginated,
        },
      }),
    }),
    getNotification: builder.query<Notification, number>({
      query: (id) => ({
        url: `${DATA_URL}/notifications/${id}`,
      }),
    }),
    exportNotifications: builder.mutation<Blob, NotificationCriteria>({
      query: ({ site, to, from, page, size, types, unpaginated }) => ({
        url: `${DATA_URL}/notifications/export`,
        method: 'GET',
        params: {
          sites: [site],
          to,
          from,
          page,
          size,
          types,
          unpaginated,
        },
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    acknowledgeNotification: builder.mutation<Notification, { id: string | number; identifier: string; site: string }>({
      query: ({ id, identifier, site }) => ({
        url: `${DATA_URL}/notifications/${id}/acknowledge`,
        method: 'PATCH',
        params: { site, identifier },
      }),
    }),
    archiveNotification: builder.mutation<Notification, { id: string | number; identifier: string; site: string }>({
      query: ({ id, identifier, site }) => ({
        url: `${DATA_URL}/notifications/${id}/archive`,
        method: 'PATCH',
        params: { site, identifier },
      }),
    }),
    confirmNotification: builder.mutation<Notification, { id: string | number; identifier: string; site: string }>({
      query: ({ id, identifier, site }) => ({
        url: `${DATA_URL}/notifications/${id}/confirm`,
        method: 'PATCH',
        params: { site, identifier },
      }),
    }),
    archiveAllNotifications: builder.mutation<Notification[], Notification[]>({
      query: (notifications: Notification[]) => ({
        url: `${DATA_URL}/notifications`,
        method: 'PUT',
        body: notifications.map((notification) => ({
          ...notification,
          future: NotificationFutureEnum.PATCH_STATUS,
          archivedTime: new Date().toISOString(),
        })),
      }),
    }),
    totalOfNotifications: builder.query<number, NotificationCriteria>({
      query: ({ site, identifier, types, notificationStatus }) => ({
        url: `${DATA_URL}/notifications/count`,
        params: {
          sites: [site],
          identifier: identifier,
          notificationStatus: notificationStatus,
          types: types,
        },
      }),
    }),
  }),
});

export const archiveNotification = notificationApi.endpoints.archiveNotification.initiate;
export const useGetNotificationLazyQuery = notificationApi.endpoints.getNotifications.useLazyQuery;
export const {
  useAcknowledgeNotificationMutation,
  useArchiveNotificationMutation,
  useGetNotificationsQuery,
  useConfirmNotificationMutation,
  useArchiveAllNotificationsMutation,
  useTotalOfNotificationsQuery,
} = notificationApi;
