import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectVersion } from '@redux/situation/monitoring.selectors';

export default function AppVersion() {
  const version = useAppSelector(selectVersion);
  return (
    <Text fontSize="16px" color="neutral.black">
      {version}
    </Text>
  );
}
