import { ReactComponent as IcArrowTriange } from '@assets/icons/24x24/ic-arrowTriangle-24.svg';
import { ReactComponent as IcArrowTriangeFilled } from '@assets/icons/24x24/ic-arrowTriangleFilled-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactElement } from 'react';

interface MenuSubItemProps {
  label: string | ReactElement;
  isSelected: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  hasChildren?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

function MenuSubItem({
  label,
  isSelected,
  isActive = false,
  isDisabled = false,
  hasChildren = false,
  isLoading = false,
  onClick,
}: Readonly<MenuSubItemProps>) {
  return (
    <Button
      className={classNames('button-bar', 'sub-item', { isSelected }, { isActive }, { isDisabled })}
      paddingX={2}
      isDisabled={isDisabled}
      onClick={onClick}
      justifyContent="space-between"
      colorScheme="transparent"
      isLoading={isLoading}
    >
      {typeof label === 'string' ? (
        <Text fontSize="16px" color={isDisabled && !isActive ? 'neutral.500' : 'neutral.white'}>
          {label}
        </Text>
      ) : (
        label
      )}
      {hasChildren && (
        <Icon
          as={isSelected ? IcArrowTriangeFilled : IcArrowTriange}
          width="24px"
          height="24px"
          className="icon"
          marginLeft={2}
        />
      )}
    </Button>
  );
}

export default MenuSubItem;
