import { point, Properties } from '@turf/helpers';
import { lineString, sector, transformTranslate } from '@turf/turf';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature, LineString, Polygon, Position } from 'geojson';

import { Coordinates } from '@/types/commons/commons.types';

export function getSector(
  position: Coordinates | undefined | null,
  bearing1: number,
  bearing2: number,
  range: number,
): Feature<Polygon> | null {
  const coordinateArray = toLonLatArray(position);
  if (!coordinateArray) {
    return null;
  }

  const center = point(coordinateArray);

  //La fonction sector contient un bug qui est fix mais pas encore publié (probablement en 7.0)
  //et qui cause une légère erreur sur le 2eme bearing.
  //Pour contourner le problème une solution est de mettre un nombre premier qui ne déclenche pas le bug (par exemple 101)
  //Source: https://github.com/Turfjs/turf/issues/2283
  return sector(center, range / 1000, bearing1, bearing2, { steps: 101 });
}

export function getSectorAzimuthLine(
  position: Coordinates | undefined | null,
  range: number,
  azimuth: number,
): Feature<LineString, Properties> | null {
  const coordinateArray = toLonLatArray(position);
  if (!coordinateArray) {
    return null;
  }
  const center = point(coordinateArray);
  const endOfLine = transformTranslate(point(coordinateArray), range / 1000, azimuth);

  return lineString([center.geometry.coordinates, endOfLine.geometry.coordinates]);
}

export function getCameraPointPosition(position: Coordinates | undefined | null): Position | null {
  const pointAsLonLat = toLonLatArray(position);
  if (!pointAsLonLat) {
    return null;
  }
  return point(pointAsLonLat).geometry.coordinates;
}

export function getCameraMiddlePointAzimuthLine(
  position: Coordinates | undefined | null,
  range: number,
  azimuth: number,
): Position | null {
  const coordinateArray = toLonLatArray(position);
  if (!coordinateArray) {
    return null;
  }
  const center = point(coordinateArray);
  const endOfLine = transformTranslate(point(coordinateArray), range / 1000, azimuth);

  return [
    (center.geometry.coordinates[0] + endOfLine.geometry.coordinates[0]) / 2,
    (center.geometry.coordinates[1] + endOfLine.geometry.coordinates[1]) / 2,
  ];
}
