import { Box } from '@chakra-ui/react';
import CockpitCustomScrollbar from '@components/common/layout/CockpitCustomScrollbar';
import { CSSProperties } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { Alert } from '@/types/data/data.types';

import BaseAlertCard from './cards/BaseAlertCard';

type Props = {
  alerts: Alert[];
};

function ArchivedList({ alerts }: Readonly<Props>) {
  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    return <BaseAlertCard style={style} alert={alerts[index]} />;
  };

  return (
    <AutoSizer style={{ width: '100%', height: '100%', position: 'relative' }} disableWidth>
      {({ height }) => {
        const totalListHeight = alerts.length * 124;
        const isOverflowing = totalListHeight > height;
        return (
          <>
            <FixedSizeList
              width="100%"
              itemKey={(index) => alerts[index].id}
              height={isOverflowing ? height : totalListHeight}
              itemCount={alerts.length}
              style={{ flexShrink: 0 }}
              itemSize={124}
              outerElementType={CockpitCustomScrollbar}
            >
              {Row}
            </FixedSizeList>
            <Box
              position="absolute"
              backgroundColor="neutral.800-op70"
              top={totalListHeight}
              marginTop={0.5}
              height={height - totalListHeight - 4}
              width="calc(100% - 16px)"
            />
          </>
        );
      }}
    </AutoSizer>
  );
}

export default ArchivedList;
