import { ReactComponent as ArrowRight } from '@assets/icons/cameras/tilt-limit-arrow.svg';
import { Box, HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { modulo } from '@utils/math.utils';
import { computeVerticalStepValues, formatDegree } from '@utils/sensors/camera/gauge.utils';
import classNames from 'classnames';
import { useMemo } from 'react';

type Props = {
  height: number;
  tilt: number;
  min: number;
  max: number;
  numberOfInterval: number;
  multiplierPrecision: number;
};

export default function VerticalGraduation({ height, tilt, min, max, numberOfInterval, multiplierPrecision }: Props) {
  //Contain the list of div we need to display on the gauge
  const computedSteps = useMemo(() => {
    return computeVerticalStepValues(tilt, multiplierPrecision, numberOfInterval).map(
      ({ smallBar, bigBar, index, label, skip }) => {
        const limitMin = index / multiplierPrecision === Math.floor(min) ? min * multiplierPrecision : null;
        const limitMax = index / multiplierPrecision === Math.floor(max) ? max * multiplierPrecision : null;
        const limit = limitMin ?? limitMax;
        return (
          <Box
            className={classNames('step', {
              'step-label': bigBar,
              'step-half': smallBar,
            })}
            key={`slider-li-${index}`}
            marginTop={`${(height / (numberOfInterval * multiplierPrecision)) * skip}px`}
          >
            <>
              {label && limit === null && (
                <Text position="absolute" variant="gauge" size="sm" right="58%" zIndex={2}>
                  {label}
                </Text>
              )}
              {limit !== null && (
                <HStack
                  position="absolute"
                  right="50%"
                  gap="1px"
                  zIndex={1}
                  _hover={{ zIndex: 5 }}
                  marginBottom={`${
                    (modulo(limit, multiplierPrecision) * height) / (numberOfInterval * multiplierPrecision)
                  }px`}
                >
                  <Text variant="gauge" size="sm" color="#BEE3F8">
                    {formatDegree(Number((limit / multiplierPrecision).toFixed(1)))}
                  </Text>
                  <ArrowRight />
                </HStack>
              )}
            </>
          </Box>
        );
      },
    );
  }, [height, max, min, multiplierPrecision, numberOfInterval, tilt]);

  return (
    <Box position="relative" height="100%" width="100%" className="steps">
      {computedSteps}
    </Box>
  );
}
