import {
  SHOW_AUTOMATON_SECTION_MAX_ZOOM,
  SHOW_AUTOMATON_SECTION_MID_ZOOM,
  SHOW_AUTOMATON_SECTION_MIN_ZOOM,
  SHOW_PERIMETER_PORTAL_MIN_ZOOM,
} from '@utils/map/mapZoom.constants';
import { PERIMETER_SECTION_TEXT_OFFSET_L, SENSOR_LABEL_TEXT_SIZE } from '@utils/sensors/configuration.constants';
import { CircleLayer, LineLayer, SymbolLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';
import { SENSOR_LABEL_TEXT_OFFSET_PX_L } from '@/types/sensor/sensor.types';

const segmentMinSize = 4;
const segmentMaxSize = 6;

export const automatonSectionLayer: LineLayer = {
  id: LayerNameEnum.AUTOMATON_SECTIONS,
  type: 'line',
  source: SourceNameEnum.AUTOMATON_SECTIONS,
  minzoom: SHOW_AUTOMATON_SECTION_MIN_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.AUTOMATON_SECTION_LINE],
  paint: {
    'line-color': ['step', ['zoom'], ['get', 'color'], SHOW_AUTOMATON_SECTION_MID_ZOOM, 'white'],
    'line-width': ['step', ['zoom'], segmentMinSize, SHOW_AUTOMATON_SECTION_MAX_ZOOM, segmentMaxSize],
  },
};

export const automatonSectionOutlineLayer: LineLayer = {
  id: LayerNameEnum.AUTOMATON_SECTION_OUTLINES,
  type: 'line',
  source: SourceNameEnum.AUTOMATON_SECTIONS,
  minzoom: SHOW_AUTOMATON_SECTION_MIN_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.AUTOMATON_SECTION_LINE],
  paint: {
    'line-color': 'black',
    'line-width': ['step', ['zoom'], segmentMinSize + 4, SHOW_AUTOMATON_SECTION_MAX_ZOOM, segmentMaxSize + 4],
  },
};

export const automatonSectionExtremityLayer: CircleLayer = {
  id: LayerNameEnum.AUTOMATON_SECTION_EXTREMITIES,
  type: 'circle',
  source: SourceNameEnum.AUTOMATON_SECTIONS,
  minzoom: SHOW_AUTOMATON_SECTION_MIN_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.AUTOMATON_SECTION_ENDING],
  paint: {
    'circle-color': ['step', ['zoom'], ['get', 'color'], SHOW_AUTOMATON_SECTION_MID_ZOOM, 'white'],
    'circle-radius': ['step', ['zoom'], segmentMinSize, SHOW_AUTOMATON_SECTION_MAX_ZOOM, segmentMaxSize],
    'circle-stroke-color': 'black',
    'circle-stroke-width': 2,
  },
};

export const automatonSectionIconLayer: SymbolLayer = {
  id: LayerNameEnum.AUTOMATON_SECTION_ICONS,
  type: 'symbol',
  source: SourceNameEnum.AUTOMATON_SECTIONS,
  minzoom: SHOW_AUTOMATON_SECTION_MID_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.AUTOMATON_SECTION_ICON],
  layout: {
    'icon-image': [
      'step',
      ['zoom'],
      ['concat', ['get', 'icon'], '-S'],
      SHOW_AUTOMATON_SECTION_MAX_ZOOM,
      ['concat', ['get', 'icon'], '-L'],
    ],
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-pitch-alignment': 'viewport',
    'icon-rotation-alignment': 'viewport',
    'symbol-z-order': 'source',
  },
};

export const automatonSectionIconLabelLayer: SymbolLayer = {
  id: LayerNameEnum.AUTOMATON_SECTION_LABELS,
  type: 'symbol',
  source: SourceNameEnum.AUTOMATON_SECTIONS,
  minzoom: SHOW_AUTOMATON_SECTION_MAX_ZOOM,
  filter: ['==', ['get', 'featureType'], FeatureTypeEnum.AUTOMATON_SECTION_LABEL],
  layout: {
    'icon-image': 'icSymb-label',
    'icon-size': 1,
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-offset': PERIMETER_SECTION_TEXT_OFFSET_L,
    'text-font': ['roboto'],
    'text-field': ['get', 'textField'],
    'text-size': SENSOR_LABEL_TEXT_SIZE,
    'text-allow-overlap': true,
    'text-offset': SENSOR_LABEL_TEXT_OFFSET_PX_L,
    'symbol-z-order': 'source',
  },
  paint: {
    'text-color': 'white',
  },
};

export const perimeterPortalLayer: SymbolLayer = {
  id: LayerNameEnum.PERIMETER_PORTALS,
  type: 'symbol',
  source: SourceNameEnum.AUTOMATON_SECTIONS,
  minzoom: SHOW_PERIMETER_PORTAL_MIN_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.PERIMETER_PORTAL],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-anchor': 'center',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-pitch-alignment': 'viewport',
    'icon-rotation-alignment': 'viewport',
    'symbol-z-order': 'source',
  },
};
