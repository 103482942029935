import { VStack } from '@chakra-ui/layout';
import { modulo } from '@utils/math.utils';
import { SIMPLIFIED_SENSOR_STATUS } from '@utils/sensors/monitoring.constants';
import { AngleUnits, formatUnit, OtherUnits } from '@utils/unit.utils';
import { useIntl } from 'react-intl';

import { CameraStatus, FocusModeEnum } from '@/types/sensor/status.types';

import CameraStatusValue from './CameraStatusValue';

type Props = {
  cameraStatus: CameraStatus;
  cameraId?: number;
};

export function CameraStatusInfo({ cameraStatus, cameraId }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const videoStatus = cameraStatus.videoStatuses.find((vs) => vs.id === cameraId);

  return (
    <VStack padding={4} paddingTop={8} gap={1}>
      <CameraStatusValue
        label={formatMessage({ id: 'cameras.status' })}
        value={
          cameraStatus.configuration.maintenance
            ? formatMessage({ id: 'sensors.maintenance.title' })
            : formatMessage({ id: SIMPLIFIED_SENSOR_STATUS[cameraStatus.status] ?? 'sensors.status.DISCONNECTED' })
        }
      />
      <CameraStatusValue
        label={formatMessage({ id: 'cameras.azimuth' })}
        value={formatUnit(AngleUnits.TURN, cameraStatus.pan && modulo(cameraStatus.pan, 360), { precision: 2 })}
      />
      <CameraStatusValue
        label={formatMessage({ id: 'cameras.site' })}
        value={formatUnit(AngleUnits.TURN, cameraStatus.tilt, { showPlusSign: true, precision: 2 })}
      />
      <CameraStatusValue
        label={formatMessage({ id: 'cameras.fov' })}
        value={formatUnit(AngleUnits.TURN, videoStatus?.fov, { precision: 2 })}
      />
      <CameraStatusValue
        label={formatMessage({ id: 'cameras.zoom' })}
        value={formatUnit(OtherUnits.PERCENT, videoStatus?.zoom)}
      />
      <CameraStatusValue
        label={formatMessage({ id: 'cameras.focus' })}
        value={videoStatus?.focusMode === FocusModeEnum.AUTO ? 'AUTOFOCUS' : Math.abs(videoStatus?.focus ?? 0) % 100}
      />
    </VStack>
  );
}
