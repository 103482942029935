import { Role, RoleEnum, RoleTaskCategoryEnum } from '@/types/authent/roles.types';

const minimumRolesEnum: RoleEnum[] = [RoleEnum.ACCESS_SITUATION, RoleEnum.ACCESS_USER, RoleEnum.ACCESS_SENSOR];

export function isBasicRole(role: Role) {
  return minimumRolesEnum.includes(role.roleName);
}

export function getBasicRoles(roles: Role[]): Role[] {
  return roles.filter(isBasicRole);
}

export function isNotTechnical(role: Role) {
  return role.taskCategory !== RoleTaskCategoryEnum.APPLICATION;
}
