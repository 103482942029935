import { GridLayout } from '@/types/config/gridLayout.types';

import { CONFIG_URL, configApi } from './config.api';

export const gridLayoutApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    createGridLayout: builder.mutation<GridLayout, GridLayout>({
      query: (gridLayout: GridLayout) => ({
        url: `${CONFIG_URL}/grid-layouts`,
        method: 'POST',
        body: gridLayout,
      }),
    }),
    updateGridLayout: builder.mutation<GridLayout, { id: number; gridLayout: GridLayout }>({
      query: ({ id, gridLayout }) => ({
        url: `${CONFIG_URL}/grid-layouts/${id}`,
        method: 'PUT',
        body: gridLayout,
      }),
    }),
    deleteGridLayout: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/grid-layouts/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateGridLayoutMutation, useDeleteGridLayoutMutation, useUpdateGridLayoutMutation } = gridLayoutApi;
