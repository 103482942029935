import { POLYGONS_OPACITY, SELECTED_FEATURE_COLOR } from '@utils/map/map.constants';
import { FillLayer, LineLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const strobeSectorLayer: FillLayer = {
  id: LayerNameEnum.STROBE_SECTORS,
  type: 'fill',
  source: SourceNameEnum.STROBES,
  filter: ['==', 'featureType', FeatureTypeEnum.STROBE_SECTOR],
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'isSelected'], false],
      SELECTED_FEATURE_COLOR,
      ['get', 'color'],
    ],
    'fill-outline-color': 'black',
    'fill-opacity': POLYGONS_OPACITY,
  },
};

export const strobeAzimuthLayer: LineLayer = {
  id: LayerNameEnum.STROBE_AZIMUTHS,
  type: 'line',
  source: SourceNameEnum.STROBES,
  filter: ['==', 'featureType', FeatureTypeEnum.STROBE_AZIMUTH],
  paint: {
    'line-color': 'black',
    'line-width': 0.1,
  },
};
