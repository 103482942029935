import { ReactComponent as PlayerPlay } from '@assets/components/button/icPlayer-play.svg';
import { ReactComponent as PlayerStop } from '@assets/components/button/icPlayer-stop.svg';
import { VStack } from '@chakra-ui/layout';
import { Box, IconButton } from '@chakra-ui/react';
import CustomSlider from '@components/common/inputs/CustomSlider';
import { useAppSelector } from '@hooks/redux.hooks';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { selectSensorOrSubSensorStatusByUniqueCodes } from '@redux/situation/monitoring.selectors';
import { useState } from 'react';

import { SensorUniqueCodes } from '@/types/map.types';
import { CameraStatus, SensorStatus } from '@/types/sensor/status.types';

type Props = {
  sensorUniqueCodes: SensorUniqueCodes;
};

export default function ({ sensorUniqueCodes }: Readonly<Props>) {
  const sensorStatus: SensorStatus = useAppSelector((state) =>
    selectSensorOrSubSensorStatusByUniqueCodes(state, false, sensorUniqueCodes),
  ) as CameraStatus;
  const configuration = sensorStatus.configuration;
  const useCamera = useCameraCommand({ cameraStatus: sensorStatus });
  const [simpleRoundSpeed, setSimpleRoundSpeed] = useState<number>(sensorStatus.simpleRoundSpeed ?? 1);

  function startSimpleRound(speed?: number | null) {
    if (speed) {
      useCamera.simpleRound(true, speed);
    }
  }

  function stopSimpleRound() {
    useCamera.simpleRound(false);
  }

  return (
    <VStack width="100%" padding={4} gap={5} userSelect="none">
      <Box width="100%" height="100%">
        <CustomSlider
          value={simpleRoundSpeed}
          displayedValue={(value) => `${value.toFixed(2)}°/s`}
          min={configuration.vxMin}
          max={6}
          minLength="100%"
          step={0.05}
          orientation="horizontal"
          onChange={(speed: number) => {
            setSimpleRoundSpeed(speed);
            if (sensorStatus.roundOn) {
              startSimpleRound(speed);
            }
          }}
        />
      </Box>
      {sensorStatus.roundOn ? (
        <IconButton
          onClick={() => {
            stopSimpleRound();
          }}
          backgroundColor="transparent"
          width="40px"
          height="40px"
          aria-label="stop"
          variant="player"
          icon={<PlayerStop />}
        ></IconButton>
      ) : (
        <IconButton
          onClick={() => {
            startSimpleRound(simpleRoundSpeed);
          }}
          backgroundColor="transparent"
          width="40px"
          height="40px"
          aria-label="play"
          variant="player"
          icon={<PlayerPlay />}
        ></IconButton>
      )}
    </VStack>
  );
}
