import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';

import { messagesAdapter } from './chat.adapters';
import { ChatState } from './chat.reducer';

function selectChatState(state: RootState): ChatState {
  return state.chat;
}

const { selectAll: selectAllMessages } = messagesAdapter.getSelectors((state: RootState) => state.chat.messages);

export const MessagesSelectors = {
  selectAllMessages,
};

export const selectNewMessageEntry = createSelector(selectChatState, (state: ChatState) => state.newMessageEntry);
