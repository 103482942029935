import { ReactComponent as Holosafe } from '@assets/holosafe-logo.svg';
import { ReactComponent as HolosafeFull } from '@assets/holosafe-logo-full.svg';
import { ReactComponent as ArrowIcon24 } from '@assets/icons/24x24/ic-arrow-24.svg';
import { ReactComponent as AlertIcon40 } from '@assets/icons/40x40/ic-alert-40.svg';
import { ReactComponent as ChatIcon40 } from '@assets/icons/40x40/ic-chat-40.svg';
import { ReactComponent as ConfigInstallIcon40 } from '@assets/icons/40x40/ic-configInstall-40.svg';
import { ReactComponent as DirectAccessIcon40 } from '@assets/icons/40x40/ic-directAccess-40.svg';
import { ReactComponent as GroupIcon40 } from '@assets/icons/40x40/ic-group-40.svg';
import { ReactComponent as LogBookIcon40 } from '@assets/icons/40x40/ic-mainCourante-40.svg';
import { ReactComponent as MonitoringIcon40 } from '@assets/icons/40x40/ic-monitoringGTC-40.svg';
import { ReactComponent as ReturningVacationIcon40 } from '@assets/icons/40x40/ic-returningVacation-40.svg';
import { ReactComponent as ProfileIcon40 } from '@assets/icons/40x40/ic-userCircle-40.svg';
import { ReactComponent as WorkStationIcon40 } from '@assets/icons/40x40/ic-workstation-40.svg';
import { useBoolean } from '@chakra-ui/hooks';
import { Icon } from '@chakra-ui/icon';
import { Box, Center, Divider, HStack, VStack } from '@chakra-ui/layout';
import MenuList from '@components/common/menu/MenuList';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateIsChangingShift } from '@redux/authent/authent.reducer';
import {
  hasRoles,
  selectActiveUser,
  selectGlobalScreenConfigurationCode,
  selectIsChangingShift,
} from '@redux/authent/authent.selectors';
import { selectNewMessageEntry } from '@redux/chat/chat.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { selectNewLogBookEntry } from '@redux/global/global.selector';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectOngoingAlertsCount } from '@redux/situation/situation.selectors';
import { RootState } from '@redux/store';
import { usePatchContextMutation } from '@services/config/setting.api';
import { Dispatch } from 'react';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';

import { CockpitPanelIntlKeysEnum, CockpitState, isFullscreen } from '../Cockpit';
import CockpitSidebarButton from './CockpitSidebarButton';

type Props = {
  state: CockpitState;
  setState: Dispatch<CockpitState>;
};

const ROLES = [
  RoleEnum.ACCESS_MSG,
  RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION,
  RoleEnum.ACCESS_NOTIFICATION,
  RoleEnum.ACCESS_LAYOUT,
  RoleEnum.ACCESS_REPLAY,
  RoleEnum.ACCESS_SENSOR,
  RoleEnum.ADMIN,
  RoleEnum.SENSOR_MAINTENANCE,
];

export default function CockpitSidebar({ state, setState }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const roleSet = useAppSelector((state: RootState) => hasRoles(state, ROLES));
  const alertCount = useAppSelector(selectOngoingAlertsCount);
  const newMessageEntry = useAppSelector(selectNewMessageEntry);
  const newLog = useAppSelector(selectNewLogBookEntry);
  const isChangingShift = useAppSelector(selectIsChangingShift);
  const currentContext = useAppSelector(selectContext);
  const { login } = useAppSelector(selectActiveUser);
  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);

  const globalScreenConfiguration =
    useAppSelector((state) =>
      GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
        state,
        currentGlobalScreenConfigurationCode,
      ),
    ) ?? null;

  const updateIschangingShiftWithDispatch = useWithDispatch(updateIsChangingShift);
  const [patchContext] = usePatchContextMutation();

  const [maximised, setMaximised] = useBoolean(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useBoolean(false);

  const isFullScreenPanel = isFullscreen(state.mainPanel);

  function handlePatchContext(context: ContextEnum) {
    patchContext({ login: login, context })
      .unwrap()
      .then(() => setIsContextMenuOpen.off());
  }

  const menuItems = Object.values(ContextEnum)
    .filter((context) =>
      globalScreenConfiguration?.screenConfigurations.some((sc) => sc.context === context && sc.gridLayoutCode),
    )
    .reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: {
          label: `screenConfiguration.context.${currentValue}`,
          isDisabled: currentContext === currentValue,
          isActive: currentContext === currentValue,
          onAction: () => handlePatchContext(currentValue),
        },
      }),
      {},
    );

  return (
    <>
      {maximised && <Box width="132px" position="relative" height="100%" flexShrink={0} />}
      <VStack
        width={maximised ? '338px' : '132px'}
        height="100%"
        alignItems="start"
        justifyContent="space-between"
        padding={3}
        paddingTop={4}
        zIndex={10}
        gap="20px"
        position={maximised ? 'absolute' : 'relative'}
        backgroundColor={maximised ? 'neutral.black' : 'neutral.900'}
      >
        <VStack width="100%">
          <CockpitSidebarButton
            isMaximised={maximised}
            isActive={isContextMenuOpen}
            icon={DirectAccessIcon40}
            label={formatMessage({ id: `components.sidebar.directAccess` })}
            onClick={setIsContextMenuOpen.toggle}
          >
            {isContextMenuOpen && globalScreenConfiguration && (
              <Box position="absolute" left="calc(100% + var(--chakra-space-3))" top="21px">
                <MenuList
                  parentLabel="components.sidebar.directAccess"
                  handleClick={(_, value) => value?.onAction && value.onAction()}
                  menuItems={menuItems}
                  depth={0}
                  selectedOptions={[`${currentContext}`]}
                />
              </Box>
            )}
          </CockpitSidebarButton>
          {roleSet?.has(RoleEnum.ACCESS_NOTIFICATION) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state.mainPanel === CockpitPanelIntlKeysEnum.ALERT}
              badge={alertCount}
              icon={AlertIcon40}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.ALERT}` })}
              onClick={() => setState({ mainPanel: CockpitPanelIntlKeysEnum.ALERT, subPanel: state.subPanel })}
            />
          )}
          {roleSet?.has(RoleEnum.ACCESS_REPLAY) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={!isFullScreenPanel && state.subPanel === CockpitPanelIntlKeysEnum.LOG_BOOK}
              icon={LogBookIcon40}
              badge={newLog}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.LOG_BOOK}` })}
              onClick={() =>
                setState({
                  mainPanel: CockpitPanelIntlKeysEnum.ALERT,
                  subPanel:
                    state.subPanel === CockpitPanelIntlKeysEnum.LOG_BOOK &&
                    state.mainPanel === CockpitPanelIntlKeysEnum.ALERT
                      ? null
                      : CockpitPanelIntlKeysEnum.LOG_BOOK,
                })
              }
            />
          )}
          {roleSet?.has(RoleEnum.ACCESS_MSG) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={!isFullScreenPanel && state.subPanel === CockpitPanelIntlKeysEnum.CHAT}
              icon={ChatIcon40}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.CHAT}` })}
              badge={newMessageEntry}
              onClick={() =>
                setState({
                  mainPanel: CockpitPanelIntlKeysEnum.ALERT,
                  subPanel:
                    state.subPanel === CockpitPanelIntlKeysEnum.CHAT &&
                    state.mainPanel === CockpitPanelIntlKeysEnum.ALERT
                      ? null
                      : CockpitPanelIntlKeysEnum.CHAT,
                })
              }
            />
          )}
          {roleSet?.has(RoleEnum.SENSOR_MAINTENANCE) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state.mainPanel === CockpitPanelIntlKeysEnum.MONITORING}
              icon={MonitoringIcon40}
              label={formatMessage({
                id: `components.sidebar.${CockpitPanelIntlKeysEnum.MONITORING}`,
              })}
              onClick={() =>
                setState({
                  mainPanel:
                    state.mainPanel === CockpitPanelIntlKeysEnum.MONITORING
                      ? CockpitPanelIntlKeysEnum.ALERT
                      : CockpitPanelIntlKeysEnum.MONITORING,
                  subPanel: state.subPanel,
                })
              }
            />
          )}
        </VStack>
        <Center width="100%">
          <Divider borderColor="neutral.700" borderBottomWidth="2px" />
        </Center>
        <VStack width="100%">
          {(roleSet?.has(RoleEnum.ACCESS_LAYOUT) || roleSet?.has(RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION)) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state.mainPanel === CockpitPanelIntlKeysEnum.POST_CONFIGURATION}
              icon={WorkStationIcon40}
              label={formatMessage({
                id: `components.sidebar.${CockpitPanelIntlKeysEnum.POST_CONFIGURATION}`,
              })}
              onClick={() =>
                setState({
                  mainPanel:
                    state.mainPanel === CockpitPanelIntlKeysEnum.POST_CONFIGURATION
                      ? CockpitPanelIntlKeysEnum.ALERT
                      : CockpitPanelIntlKeysEnum.POST_CONFIGURATION,
                  subPanel: state.subPanel,
                })
              }
            />
          )}
          {roleSet?.has(RoleEnum.ADMIN) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state.mainPanel === CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION}
              icon={ConfigInstallIcon40}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION}` })}
              onClick={() =>
                setState({
                  mainPanel:
                    state.mainPanel === CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION
                      ? CockpitPanelIntlKeysEnum.ALERT
                      : CockpitPanelIntlKeysEnum.SYSTEM_CONFIGURATION,
                  subPanel: state.subPanel,
                })
              }
            />
          )}
          {roleSet?.has(RoleEnum.ADMIN) && (
            <CockpitSidebarButton
              isMaximised={maximised}
              isActive={state.mainPanel === CockpitPanelIntlKeysEnum.ADMIN}
              icon={GroupIcon40}
              label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.ADMIN}` })}
              onClick={() =>
                setState({
                  mainPanel:
                    state.mainPanel === CockpitPanelIntlKeysEnum.ADMIN
                      ? CockpitPanelIntlKeysEnum.ALERT
                      : CockpitPanelIntlKeysEnum.ADMIN,
                  subPanel: state.subPanel,
                })
              }
            />
          )}
        </VStack>
        <Center width="100%">
          <Divider borderColor="neutral.700" borderBottomWidth="2px" />
        </Center>
        <VStack gap={1} marginTop="auto" width="100%">
          <CockpitSidebarButton
            isMaximised={maximised}
            isActive={state.mainPanel === CockpitPanelIntlKeysEnum.SETTINGS}
            icon={ProfileIcon40}
            label={formatMessage({ id: `components.sidebar.${CockpitPanelIntlKeysEnum.SETTINGS}` })}
            onClick={() =>
              setState({
                mainPanel:
                  state.mainPanel === CockpitPanelIntlKeysEnum.SETTINGS
                    ? CockpitPanelIntlKeysEnum.ALERT
                    : CockpitPanelIntlKeysEnum.SETTINGS,
                subPanel: state.subPanel,
              })
            }
          />
          <CockpitSidebarButton
            isMaximised={maximised}
            icon={ReturningVacationIcon40}
            isActive={isChangingShift}
            label={formatMessage({ id: `components.sidebar.changeShift` })}
            onClick={() => updateIschangingShiftWithDispatch(true)}
          />
          <Center width="100%">
            <Divider borderColor="neutral.700" borderBottomWidth="2px" />
          </Center>
          {maximised ? (
            <HStack gap={4} width="100%" paddingLeft="22.5px">
              <Icon as={Holosafe} width="39px" height="28px" />
              <Icon as={HolosafeFull} height="28px" width="196px" />
            </HStack>
          ) : (
            <Icon as={Holosafe} width="39px" height="28px" alignSelf="center" />
          )}
          <Center width="84px" alignSelf="start">
            <Icon
              as={ArrowIcon24}
              color="neutral.500"
              justifySelf="center"
              transform={maximised ? 'rotate(180deg)' : undefined}
              width="24px"
              height="24px"
              onClick={setMaximised.toggle}
            />
          </Center>
        </VStack>
      </VStack>
    </>
  );
}
