import { userLogout } from '@redux/authent/authent.reducer';
import { SituationState } from '@redux/situation/situation.reducer';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { ReplaySettings } from '@/types/c2/c2.types';
import { Coordinates } from '@/types/commons/commons.types';
import { Period, ReplayEvent, ReplaySituationInfo, ReplayStorageConfiguration } from '@/types/replay/replay.types';

import { replayEventsAdapter, replayManualPeriodsAdapter } from './replay.adapters';

export type ReplayState = {
  replayEvents: EntityState<ReplayEvent, string>;
  replayManualPeriods: EntityState<Period, number>;
  replayStorageConfiguration: ReplayStorageConfiguration | null;
  replaySettings: ReplaySettings | null;
  replayStartTime: string | null;
  replayStartPosition: Coordinates | null;
  status: ReplaySituationInfo | null;
  situation: SituationState | null;
};

export const REPLAY_INITIAL_STATE: ReplayState = {
  replayEvents: replayEventsAdapter.getInitialState(),
  replayManualPeriods: replayManualPeriodsAdapter.getInitialState(),
  replayStorageConfiguration: null,
  replaySettings: null,
  replayStartTime: null,
  replayStartPosition: null,
  status: null,
  situation: null,
};

const replay = createSlice({
  name: 'replay',
  initialState: REPLAY_INITIAL_STATE,
  reducers: {
    updateReplay(
      state,
      action: PayloadAction<{
        replayEvents: ReplayEvent[];
        replayManualPeriods: Period[];
        replayStorageConfiguration: ReplayStorageConfiguration | null;
        replaySettings: ReplaySettings;
      }>,
    ) {
      const { replayEvents, replayManualPeriods, replayStorageConfiguration, replaySettings } = action.payload;
      replayEventsAdapter.setAll(state.replayEvents, replayEvents);
      replayManualPeriodsAdapter.setAll(state.replayManualPeriods, replayManualPeriods);
      state.replayStorageConfiguration = replayStorageConfiguration;
      state.replaySettings = replaySettings;
    },
    updateStatus: (state, action: PayloadAction<ReplaySituationInfo>) => {
      state.status = action.payload;
    },
    updateReplaySituation: (state, action: PayloadAction<SituationState>) => {
      state.situation = action.payload;
    },
    addReplayEvent: ({ replayEvents }, action: PayloadAction<ReplayEvent>) => {
      replayEventsAdapter.addOne(replayEvents, action.payload);
    },
    updateReplayEvent: ({ replayEvents }, action: PayloadAction<ReplayEvent>) => {
      replayEventsAdapter.upsertOne(replayEvents, action.payload);
    },
    deleteReplayEvent: ({ replayEvents }, action: PayloadAction<ReplayEvent>) => {
      replayEventsAdapter.removeOne(replayEvents, action.payload.code);
    },
    addReplayManualPeriods: ({ replayManualPeriods }, action: PayloadAction<Period>) => {
      replayManualPeriodsAdapter.addOne(replayManualPeriods, action.payload);
    },
    updateReplayManualPeriods: ({ replayManualPeriods }, action: PayloadAction<Period>) => {
      replayManualPeriodsAdapter.upsertOne(replayManualPeriods, action.payload);
    },
    deleteReplayManualPeriods: ({ replayManualPeriods }, action: PayloadAction<Period>) => {
      replayManualPeriodsAdapter.removeOne(replayManualPeriods, action.payload.id);
    },
    updateReplayStorageConfiguration(state, action: PayloadAction<ReplayStorageConfiguration | null>) {
      state.replayStorageConfiguration = action.payload;
    },
    updateReplaySettings(state, action: PayloadAction<ReplaySettings | null>) {
      state.replaySettings = action.payload;
    },
    updateReplayStartTime(state, action: PayloadAction<string | null>) {
      state.replayStartTime = action.payload;
    },
    updateReplayStartPosition(state, action: PayloadAction<Coordinates | null>) {
      state.replayStartPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => REPLAY_INITIAL_STATE);
  },
});

export const {
  updateReplay,
  updateStatus,
  updateReplaySituation,
  addReplayEvent,
  addReplayManualPeriods,
  deleteReplayEvent,
  deleteReplayManualPeriods,
  updateReplayEvent,
  updateReplayManualPeriods,
  updateReplayStorageConfiguration,
  updateReplaySettings,
  updateReplayStartTime,
  updateReplayStartPosition,
} = replay.actions;

export default replay.reducer;
