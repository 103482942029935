import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectAutomatonCabinetSectionsFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import {
  automatonSectionExtremityLayer,
  automatonSectionIconLabelLayer,
  automatonSectionIconLayer,
  automatonSectionLayer,
  automatonSectionOutlineLayer,
  perimeterPortalLayer,
} from './automatonSection.layer';

export default function AutomatonSectionLayer() {
  const segmentFeatures = useSelectorWithReplayMode(selectAutomatonCabinetSectionsFeatures);

  if (!isFeatureCollection(segmentFeatures)) return null;

  return (
    <Source id={SourceNameEnum.AUTOMATON_SECTIONS} type="geojson" data={segmentFeatures} generateId>
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_OUTLINES} {...automatonSectionOutlineLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTIONS} {...automatonSectionLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_EXTREMITIES} {...automatonSectionExtremityLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_LABELS} {...automatonSectionIconLabelLayer} />
      <Layer key={LayerNameEnum.AUTOMATON_SECTION_ICONS} {...automatonSectionIconLayer} />
      <Layer key={LayerNameEnum.PERIMETER_PORTALS} {...perimeterPortalLayer} />
    </Source>
  );
}
