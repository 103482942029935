import { createEntityAdapter } from '@reduxjs/toolkit';
import { stringPropertyComparator } from '@utils/common.utils';

import { NormalizedDrone } from '@/types/utm/drone.types';
import { NormalizedFlightPlan } from '@/types/utm/flightPlan.types';
import { FlyZone } from '@/types/utm/flyZone.types';
import { Pilot } from '@/types/utm/pilot.types';
import { NormalizedFlyRoute, NormalizedFlySection } from '@/types/utm/route.types';

const codeComparator = stringPropertyComparator('code');

export const pilotsAdapter = createEntityAdapter<Pilot, string>({
  selectId: (pilot) => pilot.code,
  sortComparer: codeComparator,
});

export const dronesAdapter = createEntityAdapter<NormalizedDrone, string>({
  selectId: (drone) => drone.code,
  sortComparer: codeComparator,
});

export const flightPlansAdapter = createEntityAdapter<NormalizedFlightPlan, string>({
  selectId: (flightPlan) => flightPlan.code,
  sortComparer: codeComparator,
});

export const flyZonesAdapter = createEntityAdapter<FlyZone, string>({
  selectId: (flyZone) => flyZone.code,
  sortComparer: codeComparator,
});

export const flySectionsAdapter = createEntityAdapter<NormalizedFlySection, string>({
  selectId: (flySection) => flySection.code,
  sortComparer: codeComparator,
});

export const flyRoutesAdapter = createEntityAdapter<NormalizedFlyRoute, string>({
  selectId: (flyRoute) => flyRoute.code,
  sortComparer: codeComparator,
});
