import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { appIntl } from '@components/locale/IntlGlobalProvider';
import { formatDate, formatDuration } from '@utils/date.utils';
import format from 'date-fns/format';

import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  startTime: string;
  endTime: string | null;
  currentDate: Date;
};

export default function AlertTimeToast({ startTime, endTime, currentDate }: Readonly<Props>) {
  const { formatMessage } = appIntl();

  const date = formatDate(startTime, DateTimeEnum.DATE);
  const time = format(new Date(startTime), "HH'h'mm");
  const since = formatMessage(
    { id: `notification.${endTime ? 'durationEnded' : 'durationOngoing'}` },
    {
      duration: formatDuration(startTime, endTime ?? currentDate),
    },
  );

  return (
    <HStack columnGap={2} rowGap={0} flexWrap="wrap">
      <Text fontSize="12px" fontWeight="medium" color="neutral.black" noOfLines={1}>
        {since}
      </Text>
      <HStack gap={1}>
        <Text color="neutral.600" fontSize="12px">
          {date}
        </Text>
        <Text color="neutral.600" fontSize="12px">
          {time}
        </Text>
      </HStack>
    </HStack>
  );
}
