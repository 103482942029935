import { isValidCoordinates } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { FeatureTypeEnum } from '@/types/map.types';
import { ReplayEvent } from '@/types/replay/replay.types';

export const toReplayEventIconFeatures = (events: ReplayEvent[]): Feature[] => {
  return events
    .filter((event) => isValidCoordinates(event.position))
    .map((event) => {
      return {
        type: 'Feature',
        properties: {
          id: event.code,
          featureType: FeatureTypeEnum.EVENT_ICON,
          value: event,
          icon: 'warning_event',
          color: 'maroon',
        },
        geometry: {
          type: 'Point',
          coordinates: [event.position!.longitude, event.position!.latitude],
        },
      } as Feature;
    });
};
