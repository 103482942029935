import { AbsoluteCenter, Center, Grid, GridItem } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { LayoutSelectors } from '@redux/config/config.selectors';
import { FormattedMessage } from 'react-intl';

import { GridItemComponentEnum } from '@/types/config/gridLayout.types';

type Props = {
  gridLayoutCode: string | null;
};

export default function GridLayout({ gridLayoutCode }: Readonly<Props>) {
  const gridLayouts = useAppSelector(LayoutSelectors.selectAllLayouts);
  const gridLayout = gridLayouts.find((grid) => grid.code === gridLayoutCode);

  if (!gridLayout) {
    return (
      <Center width="100%" aspectRatio="16/9" border="1px solid black">
        {gridLayoutCode ? (
          <FormattedMessage id="screenConfiguration.unknownLayout" />
        ) : (
          <FormattedMessage id="screenConfiguration.noLayout" />
        )}
      </Center>
    );
  }

  return (
    <Grid
      templateRows={`repeat(${gridLayout.nbRow}, 1fr)`}
      templateColumns={`repeat(${gridLayout.nbCol}, 1fr)`}
      border="1px solid black"
      maxHeight="100%"
      maxWidth="100%"
      flexGrow={1}
      aspectRatio={gridLayout.resolution}
      position="relative"
      gap={0.5}
    >
      {gridLayout.items.map((item) => (
        <GridItem
          key={item.i}
          backgroundColor="gray.300"
          rowSpan={item.h}
          position="relative"
          colSpan={item.w}
          rowStart={item.y + 1}
          colStart={item.x + 1}
          display="grid"
        >
          <AbsoluteCenter fontSize="1em" textAlign="center">
            <FormattedMessage id={`grid.components.${item.component}`} />
            {item.component === GridItemComponentEnum.MAP && ` ${item.selectedMap}`}
          </AbsoluteCenter>
        </GridItem>
      ))}
    </Grid>
  );
}
