import DraggableControl from '@components/map/controls/DraggableControl';
import { useIntl } from 'react-intl';

import { PopupPositionData, RoundInfoData } from '@/types/map.types';

import RoundContent from './RoundContent';

export function RoundPopup({
  featureValue: sensorUniqueCodes,
  top,
  left,
  onClose,
}: Readonly<RoundInfoData & PopupPositionData>) {
  const { formatMessage } = useIntl();
  return (
    <DraggableControl
      top={top}
      left={left}
      key={sensorUniqueCodes.sensorUniqueCode}
      label={formatMessage({ id: 'rounds.roundPopup' })}
      width={496}
      height={472}
      onClose={onClose}
    >
      <RoundContent sensorUniqueCodes={sensorUniqueCodes} />
    </DraggableControl>
  );
}
