import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { RootState } from '@redux/store';

import { MapIdEnum } from '@/types/map.types';

export function useSelectorWithReplayMode<T>(
  // eslint-disable-next-line
  selector: (state: RootState, isReplay: boolean, params?: any) => T,
  // eslint-disable-next-line
  params?: any,
): T {
  const { mapId } = useMapContext();
  return useAppSelector((state) => selector(state, mapId === MapIdEnum.REPLAY, params));
}
