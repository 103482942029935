import { SHOW_RADAR_COVERAGE_MAX_ZOOM } from '@utils/map/mapZoom.constants';
import { FillLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const sensorRadarCoverageLayer: FillLayer = {
  id: LayerNameEnum.RADAR_COVERAGE,
  type: 'fill',
  source: SourceNameEnum.RADARS,
  maxzoom: SHOW_RADAR_COVERAGE_MAX_ZOOM,
  filter: ['==', 'featureType', FeatureTypeEnum.RADAR_COVERAGE],
  paint: {
    'fill-color': ['get', 'color'],
    'fill-outline-color': 'transparent',
    'fill-opacity': ['coalesce', ['get', 'opacity'], 1],
  },
};
