import { POLYGONS_OPACITY, SELECTED_FEATURE_COLOR } from '@utils/map/map.constants';
import { FillLayer, LineLayer } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, SourceNameEnum } from '@/types/map.types';

export const flightPlanFillLayerByStatus: FillLayer = {
  id: LayerNameEnum.FLIGHT_PLAN_FILLS,
  type: 'fill',
  source: SourceNameEnum.FLIGHT_PLANS,
  filter: ['==', 'featureType', FeatureTypeEnum.FLIGHT_PLAN],
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'isSelected'], false],
      SELECTED_FEATURE_COLOR,
      ['get', 'fillColor'],
    ],
    'fill-opacity': POLYGONS_OPACITY,
  },
};

export const flightPlanOutlineLayerByStatus: LineLayer = {
  id: LayerNameEnum.FLIGHT_PLAN_OUTLINES,
  type: 'line',
  source: SourceNameEnum.FLIGHT_PLANS,
  filter: ['==', 'featureType', FeatureTypeEnum.FLIGHT_PLAN],
  paint: {
    'line-color': ['get', 'outlineColor'],
    'line-opacity': 0.8,
    'line-width': 3,
  },
};
