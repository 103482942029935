import { ReplayEvent } from '@/types/replay/replay.types';

import { REPLAY_URL, replayApi } from './replay.api';

export const eventApi = replayApi.injectEndpoints({
  endpoints: (builder) => ({
    createEvent: builder.mutation<ReplayEvent, ReplayEvent>({
      query: (event: ReplayEvent) => ({
        url: `${REPLAY_URL}/replay-event`,
        method: 'POST',
        body: event,
      }),
    }),
    updateEvent: builder.mutation<ReplayEvent, ReplayEvent>({
      query: (event: ReplayEvent) => ({
        url: `${REPLAY_URL}/replay-event/${event.id}`,
        method: 'PUT',
        body: event,
      }),
    }),
    deleteEvent: builder.mutation<void, number>({
      query: (id) => ({
        url: `${REPLAY_URL}/replay-event/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateEventMutation } = eventApi;
