import { FormatMessageType } from '@hooks/useValidation';
import { REQUIRED_FIELD } from '@utils/validation/validation.constant';
import Yup from '@utils/validation/yup-extended';

export function poiSchema(formatMessage: FormatMessageType) {
  return Yup.object({
    nature: Yup.string().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'poi.nature' }) }),
    ),
    name: Yup.string().required(formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'poi.name' }) })),

    active: Yup.boolean(),

    height: Yup.number().required(
      formatMessage({ id: REQUIRED_FIELD }, { field: formatMessage({ id: 'poi.height' }) }),
    ),
  });
}
