import { selectSelectedDoubtCheckPerimeterCameraUniqueCode } from '@redux/global/global.selector';
import { perimeterCamerasAdapter } from '@redux/sensors/perimeterCameras.adapters';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectParameter } from '@utils/common.utils';
import { isCameraType } from '@utils/sensors/camera/camera.utils';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { isValidCoordinates } from '@utils/validation/coordinates.utils';
import { uniqBy } from 'lodash';

import {
  AutomatonCabinet,
  AutomatonConfiguration,
  AutomatonSection,
  AutomatonSegment,
  AutomatonSegmentData,
  CameraConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
  SubCameraConfiguration,
} from '@/types/sensor/configuration.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';
import { SensorStorageConfiguration } from '@/types/sensor/storage.types';

import { sensorsAdapter } from './sensors.adapters';
import { SensorsState } from './sensors.reducer';

function selectSensorsState(state: RootState): SensorsState {
  return state.sensors;
}

const { selectAll: selectAllSensors } = sensorsAdapter.getSelectors(
  (state: RootState) => selectSensorsState(state).sensors,
);

export const SensorSelectors = {
  selectAllSensors,
};

export const selectAllSensorsWithValidPosition = createSelector(
  selectAllSensors,
  (sensors: SensorConfiguration[]): SensorConfiguration[] =>
    sensors.filter((sensor) => isValidCoordinates(sensor.sensorPosition)),
);

const { selectAll: selectAllPerimeterCameras } = perimeterCamerasAdapter.getSelectors(
  (state: RootState) => selectSensorsState(state).perimeterCameras,
);

const selectPerimeterCameraByUniqueCode = perimeterCamerasAdapter.getSelectors(
  (state: RootState) => selectSensorsState(state).perimeterCameras,
).selectById as (state: RootState, id: string) => PerimeterCamera | undefined;

export const PerimeterCameraSelectors = {
  selectAllPerimeterCameras,
  selectPerimeterCameraByUniqueCode,
};

export const selectSelectedDoubtCheckPerimeterCamera = (state: RootState) => {
  const code = selectSelectedDoubtCheckPerimeterCameraUniqueCode(state);
  return code ? selectPerimeterCameraByUniqueCode(state, code) : null;
};

export const selectAppCodes = createSelector(selectSensorsState, (state: SensorsState): string[] => state.appCodes);

export const selectSensorStorage = createSelector(
  selectSensorsState,
  (state: SensorsState): SensorStorageConfiguration | null => state.sensorStorage,
);

export const selectSensorByCode = createSelector(
  [selectAllSensors, selectParameter<string | null>],
  (sensors: SensorConfiguration[], uniqueCode: string | null): SensorConfiguration | null =>
    sensors.find((sensor) => getSensorUniqueCode(sensor) === uniqueCode) ?? null,
);

export const selectAllCamerasConfiguration = createSelector(
  selectAllSensors,
  (sensors: SensorConfiguration[]): CameraConfiguration[] => {
    return sensors.filter((sensor) => isCameraType(sensor)) as CameraConfiguration[];
  },
);

export const selectAllActiveCamerasConfiguration = createSelector(
  selectAllCamerasConfiguration,
  (cameras: CameraConfiguration[]): CameraConfiguration[] => cameras.filter((camera) => camera.active),
);

export const selectCameraConfigurationByUniqueCode = createSelector(
  [selectAllCamerasConfiguration, selectParameter<string | null>],
  (cameraConfigurations: CameraConfiguration[], uniqueCode: string | null): CameraConfiguration | null =>
    cameraConfigurations.find((cam) => getSensorUniqueCode(cam) === uniqueCode) ?? null,
);

export const selectAllSubCameras = createSelector(
  selectAllCamerasConfiguration,
  (cameras: CameraConfiguration[]): SubCameraConfiguration[] => cameras.flatMap((camera) => camera.subCameras),
);

export const selectAllAutomatons = createSelector(
  selectAllSensors,
  (sensors: SensorConfiguration[]): AutomatonConfiguration[] =>
    sensors.filter(
      (sensor: SensorConfiguration) => sensor.type === SensorTypeEnum.AUTOMATON,
    ) as AutomatonConfiguration[],
);

export const selectSegmentsData = createSelector(
  selectAllAutomatons,
  (automatons: AutomatonConfiguration[]): AutomatonSegmentData[] =>
    automatons.flatMap((automaton) =>
      automaton.cabinets.flatMap((cabinet) =>
        cabinet.segments.map(
          (segment) =>
            ({
              automaton: automaton.code,
              cabinet: cabinet.code,
              segment: segment.code,
              perimeterCameras: segment.perimeterCameraUniqueKeys.map((uniqueCode) => uniqueCode.code).join(' ; '),
              ladCameraCode: segment.ladCameraCode,
              ladCameraAppCode: segment.ladCameraAppCode,
            }) as AutomatonSegmentData,
        ),
      ),
    ),
);

export const selectAutomatonCabinetSegments = createSelector(
  selectAllAutomatons,
  (automatons: AutomatonConfiguration[]): AutomatonSegment[] =>
    automatons.flatMap((automaton: AutomatonConfiguration) =>
      automaton.cabinets.flatMap((cabinet: AutomatonCabinet) => cabinet.segments),
    ),
);

export const selectAutomatonCabinetSegmentsBySegmentName = createSelector(
  [selectAutomatonCabinetSegments, selectParameter<string | null>],
  (segments, segmentName) => segments.filter((segment) => segment.name === segmentName),
);

export const selectPerimeterCamerasBySegmentName = createSelector(
  [selectAutomatonCabinetSegmentsBySegmentName, selectAllPerimeterCameras],
  (segments: AutomatonSegment[], allPerimeterCameras): PerimeterCamera[] => {
    const perimeterCameraUniqueCodes = uniqBy(
      segments.flatMap((s) => s.perimeterCameraUniqueKeys),
      (uc) => uc.code,
    );

    return allPerimeterCameras.filter((pc) =>
      perimeterCameraUniqueCodes.some((uc) => uc.appCode === pc.appCode && uc.code === pc.code && uc.site === pc.site),
    );
  },
);

export const selectAutomationSectionLadCameraConfigurationByUniqueCode = createSelector(
  [selectAllCamerasConfiguration, selectParameter<AutomatonSection>],
  (cameraConfigurations: CameraConfiguration[], section): CameraConfiguration | null => {
    let segment = section.upperSegment;
    let uniqueCode =
      segment?.ladCameraCode && segment.ladCameraAppCode && `${segment.ladCameraAppCode}_${segment.ladCameraCode}`;
    let ladCameraConfig = cameraConfigurations.find((cam) => getSensorUniqueCode(cam) === uniqueCode) ?? null;
    if (ladCameraConfig) {
      return ladCameraConfig;
    }

    segment = section.lowerSegment;
    uniqueCode =
      segment?.ladCameraCode && segment.ladCameraAppCode && `${segment.ladCameraAppCode}_${segment.ladCameraCode}`;
    ladCameraConfig = cameraConfigurations.find((cam) => getSensorUniqueCode(cam) === uniqueCode) ?? null;
    return ladCameraConfig;
  },
);
