import { Icon } from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomSelect, { Option } from '@components/common/inputs/CustomStyledSelect';
import { useSetManualTargetIdentificationMutation } from '@services/c2/track.api';
import { getIdentificationIcon } from '@utils/target.utils';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import { TargetStatusEnum } from '@/types/c2/c2.types';
import { TrackLight } from '@/types/commons/commons.types';
import { Identification, IdentificationEnum } from '@/types/sensor/identification.types';

type Props = {
  identification: Identification;
  site: string;
  trackIds: TrackLight[];
  status: TargetStatusEnum;
};

const AUTOMATIC = 'AUTOMATIC';

function IdentificationSelect({ identification, site, trackIds, status }: Readonly<Props>) {
  const formatMessage = useIntl().formatMessage;
  const [updateManualIdentification] = useSetManualTargetIdentificationMutation();

  const autoIdentificationLabel = formatMessage({
    id: `target.identification.${identification.autoIdentification}`,
  });

  const manualIdentificationOptions = [
    {
      value: AUTOMATIC,
      label: `${autoIdentificationLabel} (auto)`,
    },
  ].concat(
    Object.keys(IdentificationEnum).map((key) => {
      return {
        value: key,
        label: formatMessage({ id: `target.identification.${key}` }),
      };
    }),
  );

  return (
    <CustomSelect
      value={identification.manualIdentification ?? AUTOMATIC}
      options={manualIdentificationOptions}
      isClearable={false}
      isDisabled={status === TargetStatusEnum.LOST}
      width={421}
      variant="dark"
      formatOptionLabel={(option: Option) => (
        <HStack gap={1}>
          <Icon
            width="24px"
            height="24px"
            as={getIdentificationIcon(
              option.value === AUTOMATIC ? identification.autoIdentification : (option.value as IdentificationEnum),
            )}
          />
          <Text fontSize="16px" color="neutral.white">
            {option.label}
          </Text>
        </HStack>
      )}
      onChange={(selectedOption) =>
        selectedOption &&
        updateManualIdentification({
          tracks: trackIds,
          site: site,
          identification: selectedOption !== 'AUTOMATIC' ? (selectedOption as IdentificationEnum) : null,
        })
      }
    />
  );
}

export default memo(IdentificationSelect, isEqual);
