import { ReactComponent as IcInfo } from '@assets/icons/40x40/ic-info-40.svg';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import DraggableControl from '@components/map/controls/DraggableControl';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  onClose: () => void;
};
export default function MeasureInfoPopup({ onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  return (
    <DraggableControl
      label={formatMessage({ id: 'controls.toolMenu.measure.azimuthAndDistance.title' })}
      width={600}
      height={265}
      top={200}
      left={850}
      onClose={onClose}
    >
      <HStack backgroundColor="neutral.800" width="100%" height="100%" padding={2} gap={0} alignItems="start">
        <Icon as={IcInfo} boxSize="40px" color="neutral.white" />
        <VStack width="100%" height="100%" padding={1} gap={4} alignItems="start">
          <Text>
            <FormattedMessage
              id="controls.toolMenu.measure.azimuthAndDistance.instructions"
              values={{
                br: (
                  <>
                    <br />
                    <br />
                  </>
                ),
              }}
            />
          </Text>
        </VStack>
      </HStack>
    </DraggableControl>
  );
}
