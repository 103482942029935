import '@fontsource-variable/roboto-mono';
import '@fontsource/space-mono';

import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

import { colors } from './colors';
import { buttonTheme } from './component/button';
import { checkboxTheme } from './component/checkbox';
import { formLabelTheme } from './component/formLabel';
import { inputTheme } from './component/input';
import { numberInputTheme } from './component/numberInput';
import { radioTheme } from './component/radio';
import { tableTheme } from './component/table';
import { tabsTheme } from './component/tabs';
import { tagTheme } from './component/tag';
import { textTheme } from './component/text';
import { textAreaTheme } from './component/textArea';
import { fonts, fontWeights } from './fonts';
import { space } from './spacing';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  colors,
  fontWeights,
  fonts,
  space,
  config,
  components: {
    Input: inputTheme,
    NumberInput: numberInputTheme,
    FormLabel: formLabelTheme,
    Button: buttonTheme,
    Radio: radioTheme,
    Checkbox: checkboxTheme,
    Text: textTheme,
    Textarea: textAreaTheme,
    Table: tableTheme,
    Tabs: tabsTheme,
    Tag: tagTheme,
    Form: { baseStyle: { requiredIndicator: { marginLeft: 0.5 } } },
    Tooltip: {
      baseStyle: {
        backgroundColor: 'neutral.black',
        lineHeight: '125%',
        fontSize: '14px',
        paddingY: 1,
        paddingX: 1.5,
        fontWeight: 'regular',
        color: 'neutral.white',
        fontFamily: 'roboto',
      },
    },
  },
  sizes: {
    container: {
      uhdW: '3840px',
      uhdH: '2160px',
    },
  },
});

export default theme;
