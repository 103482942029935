import { ReactComponent as AzimuthIcon } from '@assets/components/gauge/azimuth.svg';
import { ReactComponent as CursorIcon } from '@assets/components/gauge/AzmElv-cursor.svg';
import { Box, Center, Icon, Text, VStack } from '@chakra-ui/react';
import { modulo } from '@utils/math.utils';
import { formatDegree, getPositionOnCircle } from '@utils/sensors/camera/gauge.utils';
import { memo } from 'react';

type Props = {
  pan: number;
  fov: number;
};

function AzimuthCompassValue({ pan, fov }: Readonly<Props>) {
  //Mathematical 0 is on the X axis, we want it on the Y axis so we reduce the value by 90
  const adjustedDegree = pan - 90;

  const markerPosition = getPositionOnCircle(adjustedDegree, 90, { x: 90, y: 90 }, 0);

  const startAngle = modulo(pan - fov / 2, 360);

  return (
    <Center position="absolute" width="100%" height="100%" top={0}>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        borderRadius="50%"
        background={`conic-gradient(
          from ${startAngle}deg,
          var(--chakra-colors-sky-500) ${fov}deg,
          transparent ${fov}deg ${startAngle}deg
        )`}
        pointerEvents="none"
        sx={{
          mask: `radial-gradient(circle closest-side,
            transparent 0px,
            transparent calc(100% - 20px),
            black calc(100% - 20px),
            black calc(100% - 2px),
            transparent calc(100% - 2px),
            transparent 100%)
          `,
        }}
      />
      <Box width="100%" height="100%" position="absolute">
        <Icon
          as={CursorIcon}
          position="absolute"
          width="19px"
          height="29px"
          zIndex={1}
          transform={`translate(-50%, -50%) rotate(${pan}deg)`}
          top={`${markerPosition.y}px`}
          left={`${markerPosition.x}px`}
        />
      </Box>
      <VStack gap={0.5}>
        <Icon as={AzimuthIcon} width="34px" height="16px" />
        <Text backgroundColor="neutral.black" fontWeight="medium" padding={0.25}>
          {formatDegree(pan)}
        </Text>
      </VStack>
    </Center>
  );
}

export default memo(AzimuthCompassValue);
