import './tooltip.scss';

import AzimuthAndDistanceTooltip from '@components/map/tooltips/AzimuthAndDistanceTooltip';
import LabelTooltip from '@components/map/tooltips/LabelTooltip';
import { useMapTooltip } from '@hooks/useMapTooltip';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { shouldHideTooltip } from '@utils/map/map.utils';
import classNames from 'classnames';
import { Popup } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, PopupDataTypeEnum } from '@/types/map.types';

import { useMapContext } from '../MapContext';
import EventTooltip from './EventTooltip';
import NoFlyZoneTooltip from './NoFlyZoneTooltip';
import PoiTooltip from './PoiTooltip';
import TargetTooltip from './TargetTooltip';

function Tooltip() {
  const { mousePosition } = useMapContext();
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const showMeasureTooltip = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;
  const [position, featureToDisplay] = useMapTooltip([
    LayerNameEnum.TARGETS,
    LayerNameEnum.EVENTS,
    LayerNameEnum.SENSOR_LABELS,
    LayerNameEnum.PERIMETER_SENSOR_LABELS,
    LayerNameEnum.AUTOMATON_SECTION_LABELS,
    LayerNameEnum.PERIMETER_PORTALS,
    LayerNameEnum.NO_FLY_ZONE_FILLS,
    LayerNameEnum.POI,
  ]);

  return (
    <>
      {position && (
        <Popup
          className={classNames('mapbox-popup', {
            hidden: shouldHideTooltip(currentPopupControl, featureToDisplay) || showMeasureTooltip,
          })}
          offset={25}
          latitude={position.latitude}
          longitude={position.longitude}
          closeButton={false}
          closeOnClick={false}
        >
          {featureToDisplay?.type === FeatureTypeEnum.EVENT_ICON && <EventTooltip {...featureToDisplay} />}
          {featureToDisplay?.type === FeatureTypeEnum.TARGET_ICON && <TargetTooltip {...featureToDisplay} />}
          {(featureToDisplay?.type === FeatureTypeEnum.SENSOR_LABEL ||
            featureToDisplay?.type === FeatureTypeEnum.AUTOMATON_SECTION_LABEL ||
            featureToDisplay?.type === FeatureTypeEnum.PERIMETER_PORTAL) && <LabelTooltip {...featureToDisplay} />}
          {(featureToDisplay?.type === FeatureTypeEnum.NO_FLY_ZONE ||
            featureToDisplay?.type === FeatureTypeEnum.NO_FLY_ZONE_3D) && <NoFlyZoneTooltip {...featureToDisplay} />}
          {featureToDisplay?.type === FeatureTypeEnum.POI_ICON && <PoiTooltip {...featureToDisplay} />}
        </Popup>
      )}
      {mousePosition && (
        <Popup
          className={classNames('mapbox-popup', { hidden: !showMeasureTooltip })}
          offset={25}
          latitude={mousePosition.latitude}
          longitude={mousePosition.longitude}
          closeButton={false}
          closeOnClick={false}
          anchor="left"
        >
          {showMeasureTooltip && <AzimuthAndDistanceTooltip tooltipPosition={mousePosition} />}
        </Popup>
      )}
    </>
  );
}

export default Tooltip;
