import { ReactComponent as HolosafeLogo } from '@assets/holosafe-logo.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, HStack } from '@chakra-ui/layout';
import CenterMapControl from '@components/map/controls/CenterMapControl';
import DimensionControl from '@components/map/controls/DimensionControl';
import MapSidebar from '@components/map/controls/mapSidebar/MapSidebar';
import ToolMenu from '@components/map/controls/menus/ToolMenu';
import NavigationControl from '@components/map/controls/NavigationControl';
import ScreenshotControl from '@components/map/controls/ScreenshotControl';
import ZoomControl from '@components/map/controls/ZoomControl';
import LADMap from '@components/map/LADMap';
import LiveMap from '@components/map/LiveMap';
import { MapContextProvider } from '@components/map/MapContext';
import ReplayMap from '@components/map/ReplayMap';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectGlobalScreenConfigurationCode } from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { centerMapByMapId } from '@redux/maps/maps.reducer';
import { LngLat } from 'mapbox-gl';
import { ReactElement, useEffect, useState } from 'react';

import { LayerNameEnum, MapIdEnum } from '@/types/map.types';

interface MapWrapperProps {
  selectedMap: MapIdEnum;
}

export function MapWrapper({ selectedMap }: Readonly<MapWrapperProps>) {
  const [position, setPosition] = useState<LngLat | null>(null);
  const [menuToDisplay, setMenuToDisplay] = useState<ReactElement | null>(null);

  function getMap() {
    switch (selectedMap) {
      case MapIdEnum.REPLAY:
        return <ReplayMap />;
      case MapIdEnum.LAD:
        return <LADMap />;
      case MapIdEnum.LIVE:
      default:
        return <LiveMap />;
    }
  }

  const activeGlobalScreenConfigCode = useAppSelector(selectGlobalScreenConfigurationCode);
  const activeGlobalScreenConfig = useAppSelector((state) =>
    GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(state, activeGlobalScreenConfigCode),
  );
  const setCenter = useWithDispatch(centerMapByMapId);
  useEffect(() => {
    if (activeGlobalScreenConfig?.position) {
      setCenter({ mapId: selectedMap, position: activeGlobalScreenConfig.position });
    }
  }, [activeGlobalScreenConfig, selectedMap, setCenter]);

  return (
    <MapContextProvider
      mapId={selectedMap}
      contextMenuPosition={position}
      setContextMenuPosition={setPosition}
      menuToDisplay={menuToDisplay}
      setMenuToDisplay={setMenuToDisplay}
      selectableLayers={[
        LayerNameEnum.TARGETS,
        LayerNameEnum.PERIMETER_SENSOR_ICONS,
        LayerNameEnum.PERIMETER_SENSOR_LABELS,
        LayerNameEnum.SENSOR_ICONS,
        LayerNameEnum.SENSOR_LABELS,
      ]}
    >
      <HStack height="100%" width="100%" gap={0} padding={0}>
        <Box height="100%" width="100%" zIndex={1} paddingLeft={7} paddingRight="76px">
          {getMap()}
        </Box>
        <MapSidebar paddingBottom={3}>
          <ToolMenu />
          <Icon as={HolosafeLogo} width="30px" height="26px" justifySelf="flex-end" alignSelf="center" />
        </MapSidebar>
        <MapSidebar position="right" paddingBottom={2}>
          <DimensionControl mapId={selectedMap} />
          <ZoomControl />
          <ScreenshotControl />
          <CenterMapControl />
          <NavigationControl />
        </MapSidebar>
      </HStack>
    </MapContextProvider>
  );
}
