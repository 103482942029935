import { Box } from '@chakra-ui/layout';
import { useMouseFocus } from '@hooks/useMouseFocus';

export default function MouseFocus() {
  const { isDisplayed } = useMouseFocus();

  if (!isDisplayed) {
    return;
  }

  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      border="8px solid"
      borderColor="alertLo.500"
      zIndex={6000}
      opacity={0.85}
      style={{ cursor: "url('/src/assets/icons/100x100/yellowRound.png') 50 50, auto" }}
    />
  );
}
