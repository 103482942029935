export type LocaleType = { [key: string]: LocaleType | string };

export function flattenMessages(nestedMessages: LocaleType, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: Record<string, string>, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

export const truncateMessage = (message: string, maxLength: number) => {
  return message.length > maxLength ? `${message.slice(0, maxLength - 1)}...` : message;
};
