import { HStack, Text, VStack } from '@chakra-ui/layout';

import DMSCoordinateInput from './DMSCoordinateInput';

type Props = {
  color?: string;
  coordinates: { longitude: number; latitude: number };
  onLatitudeChange: (latitude: number) => void;
  onLongitudeChange: (longitude: number) => void;
};

export default function ({
  color = 'neutral.black',
  coordinates,
  onLatitudeChange,
  onLongitudeChange,
}: Readonly<Props>) {
  return (
    <VStack>
      <HStack>
        <Text color={color}>Lat.&nbsp;</Text>
        <DMSCoordinateInput
          color={color}
          onChange={onLatitudeChange}
          coordinate={coordinates.latitude}
          longOrLat="lat"
        />
      </HStack>
      <HStack>
        <Text color={color}>Long.</Text>
        <DMSCoordinateInput
          color={color}
          onChange={onLongitudeChange}
          coordinate={coordinates.longitude}
          longOrLat="long"
        />
      </HStack>
    </VStack>
  );
}
