import { SystemStyleObject } from '@chakra-ui/system';
import { CreatableSelect, MultiValue, Select, SizeProp } from 'chakra-react-select';
import { useIntl } from 'react-intl';

import { Option } from './CustomSelect';

type Creatable =
  | {
      isCreatable?: false;
      onCreate?: never;
    }
  | { isCreatable: true; onCreate: (value: string) => void };

interface BasicProps {
  value: string[];
  options: Option[];
  limit?: number;
  size?: SizeProp;
  isClearable?: boolean;
  menuPortalTarget?: HTMLElement | null;
  onChange: (value: string[]) => void;
}

type OwnProps = BasicProps & Creatable;

export default function CustomMultiSelect({
  value,
  options,
  isCreatable = false,
  limit,
  menuPortalTarget = document.body,
  onChange,
  onCreate,
  ...props
}: OwnProps) {
  const { formatMessage } = useIntl();

  const maxSelectedOptions: Option[] = [
    {
      isDisabled: true,
      value: 'max',
      label: formatMessage({ id: 'form.limitReached' }, { limit: limit }),
    },
  ];

  const chakraStyles = {
    container: (provided: SystemStyleObject) => ({
      ...provided,
      width: '100%',
    }),
    valueContainer: (provided: SystemStyleObject) => ({
      ...provided,
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      overflow: 'hidden',
      display: 'flex',
      whitespace: 'nowrap',
      flexWrap: 'nowrap',
    }),
    multiValue: (provided: SystemStyleObject) => ({
      ...provided,
      minWidth: '60px',
    }),
  };

  return isCreatable ? (
    <CreatableSelect
      hideSelectedOptions={false}
      isMulti
      isSearchable
      menuPlacement="auto"
      options={limit && value.length >= limit ? maxSelectedOptions : options}
      selectedOptionStyle="check"
      useBasicStyles
      chakraStyles={chakraStyles}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 10 }),
      }}
      menuPortalTarget={menuPortalTarget}
      closeMenuOnSelect={false}
      noOptionsMessage={() => formatMessage({ id: 'components.select.noOption' })}
      {...props}
      value={options.filter((option) => value.includes(option.value))}
      formatCreateLabel={(value) => formatMessage({ id: 'components.select.create' }, { value })}
      onChange={(options: MultiValue<Option>) => onChange(options.map((option) => option.value))}
      onCreateOption={onCreate}
    />
  ) : (
    <Select
      hideSelectedOptions={false}
      isMulti
      isSearchable
      menuPlacement="auto"
      options={limit && value.length >= limit ? maxSelectedOptions : options}
      selectedOptionStyle="check"
      useBasicStyles
      chakraStyles={chakraStyles}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 10 }),
      }}
      menuPortalTarget={menuPortalTarget}
      closeMenuOnSelect={false}
      noOptionsMessage={() => formatMessage({ id: 'components.select.noOption' })}
      {...props}
      value={options.filter((option) => value.includes(option.value))}
      onChange={(options: MultiValue<Option>) => onChange(options.map((option) => option.value))}
    />
  );
}
