import { Box } from '@chakra-ui/layout';
import Scrollbars from 'rc-scrollbars';
import { ForwardedRef, forwardRef, MutableRefObject, ReactNode, useCallback } from 'react';

type Props = {
  children: ReactNode;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  viewWidth?: string;
  viewHeight?: string;
  onScroll?: () => void;
  scrollbarRef?: MutableRefObject<Scrollbars | null>;
};

const CockpitCustomScrollbar = forwardRef(
  (
    {
      children,
      onScroll,
      scrollbarRef,
      marginTop = 0.5,
      marginRight = 0,
      marginBottom = 0,
      marginLeft = 1,
      viewWidth,
      viewHeight,
    }: Readonly<Props>,
    ref: ForwardedRef<unknown>,
  ) => {
    const refSetter = useCallback(
      (scrollbars: (Scrollbars & { view: HTMLDivElement }) | null) => {
        const scrollbar = scrollbars ? scrollbars : null;
        if (ref) {
          if (typeof ref === 'function') {
            ref(scrollbar?.view);
          } else {
            ref.current = scrollbar?.view;
          }
        }
        if (scrollbarRef) {
          scrollbarRef.current = scrollbar;
        }
      },
      [ref, scrollbarRef],
    );
    return (
      <Scrollbars
        onScroll={onScroll}
        disableDefaultStyles
        ref={refSetter}
        renderTrackVertical={(props) => (
          <Box
            {...props}
            style={{
              position: 'absolute',
              width: '8px',
              height: `calc(100% - ${(marginTop + marginBottom) * 8}px)`,
              right: 0,
              top: 0,
            }}
            marginLeft={marginLeft}
            marginTop={marginTop}
            marginRight={marginRight}
            backgroundColor="transparent"
          />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <Box style={{ ...style }} width="8px" backgroundColor="neutral.600" {...props} />
        )}
        style={{ flexGrow: 0, overflow: 'hidden', backgroundColor: 'transparent' }}
      >
        <Box
          width={viewWidth ?? `calc(100% - ${(marginRight + marginLeft) * 8 + 8}px)`}
          height={viewHeight ?? `calc(100% - ${(marginTop + marginBottom) * 8}px)`}
          position="relative"
        >
          {children}
        </Box>
      </Scrollbars>
    );
  },
);

export default CockpitCustomScrollbar;

type ScrollbarWrapperProps = {
  children: ReactNode;
  onScroll?: () => void;
};

export const wrapScrollbarWithRef = (scrollbarRef: MutableRefObject<Scrollbars | null>) =>
  forwardRef(function ({ children, onScroll }: Readonly<ScrollbarWrapperProps>, ref: ForwardedRef<unknown>) {
    return (
      <CockpitCustomScrollbar onScroll={onScroll} ref={ref} scrollbarRef={scrollbarRef}>
        {children}
      </CockpitCustomScrollbar>
    );
  });
