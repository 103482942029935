import { HStack } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { GridConfigurationSelectors } from '@redux/config/config.selectors';
import { memo } from 'react';

import { GridItem as GridItemType } from '@/types/config/gridLayout.types';

import GridComponent from './GridComponent';

interface OwnProps {
  onChangeDragging: (value: Partial<GridItemType>) => void;
}

function GridComponentList({ onChangeDragging }: Readonly<OwnProps>) {
  const gridConfigurations = useAppSelector(GridConfigurationSelectors.selectAllGridConfiguration);

  return (
    <HStack gap={1} wrap="wrap" height="100px" width="100%" marginTop={1}>
      {gridConfigurations.map((item) => (
        <GridComponent
          key={item.code}
          component={item.component}
          colSpan={item.w}
          rowSpan={item.h}
          minW={item.minW}
          minH={item.minH}
          innerGridWidth={item.innerGridWidth}
          innerGridHeight={item.innerGridHeight}
          isResizable={item.isResizable}
          onChangeDragging={(value) => onChangeDragging(value)}
        />
      ))}
    </HStack>
  );
}

export default memo(GridComponentList);
