import { updateAppCodes } from '@redux/sensors/sensors.reducer';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';

import {
  AutomatonCommand,
  C2StorageConfiguration,
  CameraCommand,
  EquipmentCommand,
  GlobalConfiguration,
  GroundStationCommand,
  HolodroneCommand,
  XentaCommand,
} from '@/types/c2/c2.types';
import { DurationCriteria, ImportItem } from '@/types/commons/commons.types';
import { CommonConfiguration, SensorConfiguration, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { PerimeterCamera } from '@/types/sensor/perimeterCamera.types';

export const C2_URL = import.meta.env.VITE_C2_URL;

export const c2Api = createApi({
  reducerPath: 'c2Api',
  baseQuery: baseQueryWithReAuth,
  keepUnusedDataFor: 0,
  tagTypes: ['AppCodes', 'StorageConfiguration'],
  endpoints: (builder) => ({
    getAppCodes: builder.query<string[], string>({
      query: (site) => ({
        url: `${C2_URL}/${site}/sensors/appCodes`,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(updateAppCodes(data));
      },
      providesTags: [{ type: 'AppCodes', id: 'APPCODE_LIST' }],
    }),
    getStorageConfiguration: builder.query<C2StorageConfiguration, string>({
      query: (site) => ({
        url: `${C2_URL}/storage-configurations/default`,
        params: {
          site: site,
        },
      }),
      providesTags: [{ type: 'StorageConfiguration', id: 'STORAGE_CONFIGURATION' }],
    }),
    getConfigVersion: builder.query<Map<string, string>, void>({
      query: () => ({
        url: 'export/version',
      }),
    }),
    getSensorRaws: builder.mutation<Blob, DurationCriteria & { sensorCodes: string[] }>({
      query: ({ site, from, to, sensorCodes }) => ({
        url: `${C2_URL}/sensor/${site}/replay`,
        method: 'GET',
        params: {
          from: from,
          to: to,
          sensorsCodes: sensorCodes,
        },
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    resetSituation: builder.mutation<void, string[]>({
      query: (sites) => ({
        url: `${C2_URL}/situations/reset`,
        method: 'POST',
        params: {
          sites: sites,
        },
      }),
    }),
    createSensorConfiguration: builder.mutation<
      SensorConfiguration,
      { site: string; sensorConfiguration: SensorConfiguration }
    >({
      query: ({ site, sensorConfiguration }) => ({
        url: `${C2_URL}/${site}/sensors`,
        method: 'POST',
        body: sensorConfiguration,
      }),
    }),
    createConfiguration: builder.mutation<void, { site: string; configuration: GlobalConfiguration }>({
      query: ({ site, configuration }) => ({
        url: `${C2_URL}/import/configuration`,
        method: 'POST',
        params: {
          site: site,
        },
        body: configuration,
      }),
    }),
    previewImportConfiguration: builder.mutation<ImportItem[], { site: string; configuration: GlobalConfiguration }>({
      query: ({ site, configuration }) => ({
        url: `${C2_URL}/import/configuration/preview`,
        method: 'POST',
        params: {
          site: site,
        },
        body: configuration,
      }),
    }),
    exportConfiguration: builder.mutation<void, { site: string; versions: Map<string, string> }>({
      query: ({ site, versions }) => ({
        url: `${C2_URL}/export/configuration`,
        method: 'POST',
        params: {
          site: site,
        },
        body: Object.fromEntries(versions),
      }),
    }),
    postAutomatonCommand: builder.mutation<void, { site: string; appCode: string; command: AutomatonCommand }>({
      query: ({ site, appCode, command }) => ({
        url: `${C2_URL}/${site}/${appCode}/commands/automaton`,
        method: 'POST',
        body: command,
      }),
    }),
    postCameraCommand: builder.mutation<void, { site: string; appCode: string; command: CameraCommand }>({
      query: ({ site, appCode, command }) => ({
        url: `${C2_URL}/${site}/${appCode}/commands/camera`,
        method: 'POST',
        body: command,
      }),
    }),
    postXentaCommand: builder.mutation<void, { site: string; appCode: string; command: XentaCommand }>({
      query: ({ site, appCode, command }) => ({
        url: `${C2_URL}/${site}/${appCode}/commands/xenta`,
        method: 'POST',
        body: command,
      }),
    }),
    postGroundStationCommand: builder.mutation<void, { site: string; command: GroundStationCommand }>({
      query: ({ site, command }) => ({
        url: `${C2_URL}/${site}/commands/ground-station`,
        method: 'POST',
        body: command,
      }),
    }),
    postHolodroneCommand: builder.mutation<void, { site: string; appCode: string; command: HolodroneCommand }>({
      query: ({ site, appCode, command }) => ({
        url: `${C2_URL}/${site}/${appCode}/commands/holodrone`,
        method: 'POST',
        body: command,
      }),
    }),
    postLaunchBatchMigrateBlobs: builder.mutation<void, void>({
      query: () => ({
        url: `${C2_URL}/batchs/migrateBlobs`,
        method: 'POST',
      }),
    }),
    updateSensorConfiguration: builder.mutation<
      void,
      { id: number; site: string; sensor: SensorConfiguration | CommonConfiguration }
    >({
      query: ({ id, site, sensor }) => ({
        url: `${C2_URL}/${site}/sensors/${id}`,
        method: 'PUT',
        body: sensor,
      }),
    }),
    updateSensorActivation: builder.mutation<
      void,
      { id: number; site: string; appCode: string; active: boolean; type?: SubSensorTypeEnum; codes?: string[] }
    >({
      query: ({ id, site, appCode, active, type, codes }) => ({
        url: `${C2_URL}/${site}/${appCode}/sensors/${id}/active`,
        method: 'PATCH',
        body: active,
        params: {
          type,
          codes,
        },
      }),
    }),
    updateSensorMaintenance: builder.mutation<
      void,
      { id: number; site: string; appCode: string; maintenance: boolean; type?: SubSensorTypeEnum; codes?: string[] }
    >({
      query: ({ id, site, appCode, maintenance, type, codes }) => ({
        url: `${C2_URL}/${site}/${appCode}/sensors/${id}/maintenance`,
        method: 'PATCH',
        body: maintenance,
        params: {
          type,
          codes,
        },
      }),
    }),
    updateStorageConfiguration: builder.mutation<void, { site: string; storageConfiguration: C2StorageConfiguration }>({
      query: ({ site, storageConfiguration }) => ({
        url: `${C2_URL}/storage-configurations/${site}`,
        method: 'PUT',
        body: storageConfiguration,
      }),
      invalidatesTags: [{ type: 'StorageConfiguration', id: 'STORAGE_CONFIGURATION' }],
    }),
    deleteSensorConfiguration: builder.mutation<void, { site: string; appCode: string; id: number }>({
      query: ({ site, appCode, id }) => ({
        url: `${C2_URL}/${site}/${appCode}/sensors/${id}`,
        method: 'DELETE',
      }),
    }),
    getAvailableRawRanges: builder.query<{ from: string; to: string }[], { site: string; from: string; to: string }>({
      query: ({ site, from, to }) => ({
        url: `${C2_URL}/sensor/${site}/replay/available-raw-ranges`,
        method: 'GET',
        params: {
          site,
          from,
          to,
        },
      }),
    }),
    importPerimetersCamera: builder.mutation<void, { site: string; csvFileBytes: Uint8Array }>({
      query: ({ site, csvFileBytes }) => {
        return {
          url: `${C2_URL}/import/perimeter-cameras`,
          method: 'POST',
          params: {
            site: site,
          },
          body: csvFileBytes,
        };
      },
    }),
    importAutomatons: builder.mutation<void, { site: string; csvFileBytes: Uint8Array }>({
      query: ({ site, csvFileBytes }) => {
        return {
          url: `${C2_URL}/import/automatons`,
          method: 'POST',
          params: {
            site: site,
          },
          body: csvFileBytes,
        };
      },
    }),
    updatePerimeterCameraConfiguration: builder.mutation<void, { id: number; site: string; pericam: PerimeterCamera }>({
      query: ({ id, site, pericam }) => ({
        url: `${C2_URL}/${site}/perimeter-cameras/${id}`,
        method: 'PUT',
        body: pericam,
      }),
    }),
    postEquipmentCommand: builder.mutation<void, { site: string; appCode: string; command: EquipmentCommand }>({
      query: ({ site, appCode, command }) => ({
        url: `${C2_URL}/${site}/${appCode}/equipments/commands`,
        method: 'POST',
        body: command,
      }),
    }),
  }),
});

export const {
  useGetAppCodesQuery,
  usePostAutomatonCommandMutation,
  usePostCameraCommandMutation,
  usePostXentaCommandMutation,
  useGetAvailableRawRangesQuery,
  useGetStorageConfigurationQuery,
  useUpdateSensorActivationMutation,
  useUpdateStorageConfigurationMutation,
  useImportPerimetersCameraMutation,
  useImportAutomatonsMutation,
  useUpdateSensorMaintenanceMutation,
  useUpdateSensorConfigurationMutation,
  useResetSituationMutation,
  useUpdatePerimeterCameraConfigurationMutation,
  usePostHolodroneCommandMutation,
  usePostEquipmentCommandMutation,
} = c2Api;
