import { schema } from 'normalizr';

import { Drone } from '@/types/utm/drone.types';
import { FlightPlan } from '@/types/utm/flightPlan.types';
import { FlyZone } from '@/types/utm/flyZone.types';
import { Pilot } from '@/types/utm/pilot.types';
import { FlyRoute, FlySection } from '@/types/utm/route.types';
import Entity = schema.Entity;
import { codeExtractor } from '@utils/schema.utils';

const pilotSchema: Entity<Pilot> = new Entity('pilots', {}, { idAttribute: codeExtractor<Pilot> });
const droneSchema: Entity<Drone> = new Entity('drones', { pilot: pilotSchema }, { idAttribute: codeExtractor<Drone> });
const flightPlanSchema: Entity<FlightPlan> = new Entity(
  'flightPlans',
  { drone: droneSchema },
  { idAttribute: codeExtractor<FlightPlan> },
);

const flyZoneSchema: Entity<FlyZone> = new Entity<FlyZone>('flyZones', {}, { idAttribute: codeExtractor<FlyZone> });

const flySectionSchema: Entity<FlySection> = new Entity(
  'flySections',
  { flyZones: [flyZoneSchema] },
  { idAttribute: codeExtractor<FlySection> },
);

const flyRouteSchema: Entity<FlyRoute> = new Entity(
  'flyRoutes',
  { sections: [flySectionSchema] },
  { idAttribute: codeExtractor<FlyRoute> },
);

const utmSchema = {
  pilots: [pilotSchema],
  drones: [droneSchema],
  flightPlans: [flightPlanSchema],
  flyZones: [flyZoneSchema],
  flySections: [flySectionSchema],
  flyRoutes: [flyRouteSchema],
};

export { pilotSchema, droneSchema, flightPlanSchema, flyZoneSchema, flySectionSchema, flyRouteSchema, utmSchema };
