import { ZoneExportFormat } from '@/types/commons/commons.types';
import { NoInitZone } from '@/types/config/config.types';

import { CONFIG_URL, configApi } from './config.api';

export const noInitZoneApi = configApi.injectEndpoints({
  endpoints: (builder) => ({
    exportNoInitZones: builder.mutation<Blob, { id: number; zoneExportFormat: ZoneExportFormat }>({
      query: ({ id, zoneExportFormat }) => ({
        url: `${CONFIG_URL}/no-init-zones/${id}/export?format=${zoneExportFormat}`,
        method: 'GET',
        responseHandler: (response) => response.text(),
        transformResponse: (response: { data: Blob }) => response.data,
      }),
    }),
    createNoInitZones: builder.mutation<NoInitZone, Partial<NoInitZone>>({
      query: (niz) => ({
        url: `${CONFIG_URL}/no-init-zones`,
        method: 'POST',
        body: niz,
      }),
    }),
    updateNoInitZones: builder.mutation<NoInitZone, { id: number; niz: NoInitZone }>({
      query: ({ id, niz }) => ({
        url: `${CONFIG_URL}/no-init-zones/${id}`,
        method: 'PUT',
        body: niz,
      }),
    }),
    deleteNoInitZone: builder.mutation<void, number>({
      query: (id) => ({
        url: `${CONFIG_URL}/no-init-zones/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateNoInitZonesMutation, useUpdateNoInitZonesMutation, useDeleteNoInitZoneMutation } =
  noInitZoneApi;
