export enum UnitTypes {
  HEIGHT = 'HEIGHT',
  DISTANCE = 'DISTANCE',
  SPEED = 'SPEED',
}

export enum SpeedUnits {
  KILOMETERS_PER_HOUR = 'KILOMETERS_PER_HOUR',
  METERS_PER_SECOND = 'METERS_PER_SECOND',
  MILES_PER_HOUR = 'MILES_PER_HOUR',
  KNOTS = 'KNOTS',
}

export enum HeightUnits {
  METERS = 'METERS',
  FEET = 'FEET',
}

export enum DistanceUnits {
  METERS = 'METERS',
  KILOMETERS = 'KILOMETERS',
  MILES = 'MILES',
  NAUTICAL_MILES = 'NAUTICAL_MILES',
}

export enum AngleUnits {
  TURN = 'TURN',
}

export enum OtherUnits {
  PERCENT = 'PERCENT',
}

export type Units = AngleUnits | DistanceUnits | HeightUnits | SpeedUnits | OtherUnits;

export const unitLabel: { [key in Units]: string } = {
  [AngleUnits.TURN]: '°',
  //Also work for HeightUnits.METERS
  [DistanceUnits.METERS]: 'm',
  [DistanceUnits.KILOMETERS]: 'km',
  [DistanceUnits.MILES]: 'mi',
  [DistanceUnits.NAUTICAL_MILES]: 'NM',
  [HeightUnits.FEET]: 'ft',
  [SpeedUnits.KILOMETERS_PER_HOUR]: 'km/h',
  [SpeedUnits.METERS_PER_SECOND]: 'm/s',
  [SpeedUnits.MILES_PER_HOUR]: 'mph',
  [SpeedUnits.KNOTS]: 'kt',
  [OtherUnits.PERCENT]: '%',
};

export const UnitListByType: { [key in UnitTypes]: { label: string; value: string }[] } = {
  [UnitTypes.DISTANCE]: Object.keys(DistanceUnits).map((key) => ({ label: unitLabel[key as Units], value: key })),
  [UnitTypes.HEIGHT]: Object.keys(HeightUnits).map((key) => ({ label: unitLabel[key as Units], value: key })),
  [UnitTypes.SPEED]: Object.keys(SpeedUnits).map((key) => ({ label: unitLabel[key as Units], value: key })),
};

//Distance conversion constants
const METER_TO_MILE = 0.000621371;
const METER_TO_FEET = 3.28084;
const METER_TO_NAUTICAL_MILE = 0.000539957;

//Speed conversion constants
const MS_TO_KPH = 3.6;
const MS_TO_MPH = 2.23694;
const MS_TO_KNOTS = 1.94384;

export function convertSpeedUnitFromMPerS(toUnit: string, value?: number | null, precision = 5): number {
  if (value === undefined || value === null || isNaN(value)) {
    return NaN;
  }
  switch (toUnit) {
    case SpeedUnits.METERS_PER_SECOND:
      return Number(value.toFixed(precision));
    case SpeedUnits.KNOTS:
      return Number((value * MS_TO_KNOTS).toFixed(precision));
    case SpeedUnits.KILOMETERS_PER_HOUR:
      return Number((value * MS_TO_KPH).toFixed(precision));
    case SpeedUnits.MILES_PER_HOUR:
      return Number((value * MS_TO_MPH).toFixed(precision));
    default:
      return NaN;
  }
}

export function convertSpeedUnitToMPerS(fromUnit: string, value?: number, precision = 5): number {
  if (value === undefined || isNaN(value)) {
    return NaN;
  }
  switch (fromUnit) {
    //Assuming speed is in m/s
    case SpeedUnits.METERS_PER_SECOND:
      return Number(value.toFixed(precision));
    case SpeedUnits.KNOTS:
      return Number((value / MS_TO_KNOTS).toFixed(precision));
    case SpeedUnits.KILOMETERS_PER_HOUR:
      return Number((value / MS_TO_KPH).toFixed(precision));
    case SpeedUnits.MILES_PER_HOUR:
      return Number((value / MS_TO_MPH).toFixed(precision));
    default:
      return NaN;
  }
}

export function convertDistanceUnitFromMeter(toUnit: string, valueInMeter?: number | null, precision = 5): number {
  if (valueInMeter === undefined || valueInMeter === null || isNaN(valueInMeter)) {
    return NaN;
  }

  switch (toUnit) {
    //Assuming distance in meters
    case DistanceUnits.METERS:
      return Number(valueInMeter.toFixed(precision));
    case DistanceUnits.KILOMETERS:
      return Number((valueInMeter / 1000).toFixed(precision));
    case DistanceUnits.MILES:
      return Number((valueInMeter * METER_TO_MILE).toFixed(precision));
    case HeightUnits.FEET:
      return Number((valueInMeter * METER_TO_FEET).toFixed(precision));
    case DistanceUnits.NAUTICAL_MILES:
      return Number((valueInMeter * METER_TO_NAUTICAL_MILE).toFixed(precision));
    default:
      return NaN;
  }
}

export function convertDistanceUnitToMeter(fromUnit: string, value?: number, precision = 5): number {
  if (value === undefined || isNaN(value)) {
    return NaN;
  }

  switch (fromUnit) {
    case DistanceUnits.METERS:
      return Number(value.toFixed(precision));
    case DistanceUnits.KILOMETERS:
      return Number((value * 1000).toFixed(precision));
    case DistanceUnits.MILES:
      return Number((value / METER_TO_MILE).toFixed(precision));
    case HeightUnits.FEET:
      return Number((value / METER_TO_FEET).toFixed(precision));
    case DistanceUnits.NAUTICAL_MILES:
      return Number((value / METER_TO_NAUTICAL_MILE).toFixed(precision));
    default:
      return NaN;
  }
}

export function getConversionFunctions(unitType: UnitTypes): {
  convert: (toUnit: string, valueInMeter?: number, precision?: number) => number;
  convertBack: (fromUnit: string, value?: number, precision?: number) => number;
} {
  switch (unitType) {
    case UnitTypes.SPEED:
      return { convert: convertSpeedUnitFromMPerS, convertBack: convertSpeedUnitToMPerS };
    case UnitTypes.DISTANCE:
    case UnitTypes.HEIGHT:
    default:
      return { convert: convertDistanceUnitFromMeter, convertBack: convertDistanceUnitToMeter };
  }
}

export function formatUnit(
  unit: Units,
  value?: number | null,
  options?: { precision?: number; showPlusSign?: boolean },
) {
  const opt = { precision: 0, showPlusSign: false, ...options };
  if (value === undefined || value === null || isNaN(value)) {
    return 'N/A';
  }
  let sign = '';
  if (opt.showPlusSign) {
    sign = value > 0 ? '+' : '';
  }
  return `${sign}${value.toFixed(opt.precision)}${unitLabel[unit]}`;
}

export function formatFrequencyWithUnit(frequency: number) {
  for (const unit of ['Hz', 'kHz', 'MHz', 'GHz']) {
    if (frequency < 1000) {
      return `${frequency.toFixed(frequency > 10 ? 0 : 1)} ${unit}`;
    }
    frequency = frequency / 1000;
  }
  return `${frequency.toFixed(frequency > 10 ? 0 : 1)} THz`;
}
