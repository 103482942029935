import NiceModal from '@ebay/nice-modal-react';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { RootState } from '@redux/store';
import { PropsWithChildren } from 'react';

export default function ModalsProvider({ children }: Readonly<PropsWithChildren>) {
  const modals = useAppSelector((state: RootState) => state.modals);
  const dispatch = useAppDispatch();

  return (
    <NiceModal.Provider modals={modals} dispatch={dispatch}>
      {children}
    </NiceModal.Provider>
  );
}
