import { createEntityAdapter } from '@reduxjs/toolkit';
import { stringPropertyComparator } from '@utils/common.utils';

import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  NoNeutralizationZone,
  Platform,
  ZoneOfInterest,
} from '@/types/config/config.types';
import { GridConfiguration, GridLayout } from '@/types/config/gridLayout.types';
import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { RoundConfiguration } from '@/types/config/round.types';
import { GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';
import { VideoStream } from '@/types/config/videoStream.types';

const codeComparator = stringPropertyComparator('code');

export const imzAdapter = createEntityAdapter<InitializationMaskingZone, string>({
  selectId: (zone) => zone.code,
  sortComparer: codeComparator,
});

export const nfzAdapter = createEntityAdapter<NoFlyZone, string>({
  selectId: (zone) => zone.code,
  sortComparer: codeComparator,
});

export const nfz3dAdapter = createEntityAdapter<NoFlyZone3d, string>({
  selectId: (zone) => zone.code,
  sortComparer: codeComparator,
});

export const nizAdapter = createEntityAdapter<NoInitZone, string>({
  selectId: (zone) => zone.code,
  sortComparer: codeComparator,
});

export const nnzAdapter = createEntityAdapter<NoNeutralizationZone, string>({
  selectId: (zone) => zone.code,
  sortComparer: codeComparator,
});

export const zoiAdapter = createEntityAdapter<ZoneOfInterest, string>({
  selectId: (zone) => zone.code,
  sortComparer: codeComparator,
});

export const poiAdapter = createEntityAdapter<PointOfInterest, string>({
  selectId: (poi) => poi.code,
  sortComparer: codeComparator,
});

export const layoutsAdapter = createEntityAdapter<GridLayout, string>({
  selectId: (layout) => layout.code,
  sortComparer: codeComparator,
});

export const gridConfigurationsAdapter = createEntityAdapter<GridConfiguration, string>({
  selectId: (gridConfiguration) => gridConfiguration.code,
  sortComparer: codeComparator,
});

export const videoStreamsAdapter = createEntityAdapter<VideoStream, string>({
  selectId: (stream) => stream.code,
  sortComparer: codeComparator,
});

export const globalScreenConfigurationAdapter = createEntityAdapter<GlobalScreenConfiguration, string>({
  selectId: (globalScreenConfiguration) => globalScreenConfiguration.code,
  sortComparer: codeComparator,
});

export const roundConfigurationEntityAdapter = createEntityAdapter<RoundConfiguration, string>({
  selectId: (roundConfiguration) => roundConfiguration.code,
  sortComparer: codeComparator,
});

export const platformsAdapter = createEntityAdapter<Platform, string>({
  selectId: (platform) => platform.code,
  sortComparer: codeComparator,
});
