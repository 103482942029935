import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  marginBottom: 0.5,
  fontSize: '14px',
  fontWeight: 'regular',
});

const mapFormLabel = defineStyle({
  lineHeight: '125%',
  fontSize: '16px',
  fontWeight: 'medium',
  fontFamily: 'roboto',
  color: 'neutral.300',
});

export const formLabelTheme = defineStyleConfig({
  baseStyle,
  variants: { mapFormLabel },
});
