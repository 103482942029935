export const space = {
  px: '1px',
  0.25: '2px',
  0.5: '4px',
  1: '8px',
  1.5: '12px',
  2: '16px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
  8: '64px',
  9: '72px',
  9.5: '76px',
  10: '80px',
  12: '96px',
};
