import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectStrobeFeatures } from '@redux/situation/situation.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import { strobeAzimuthLayer, strobeSectorLayer } from './strobe.layer';

function StrobeLayer() {
  const strobeFeatures = useSelectorWithReplayMode(selectStrobeFeatures);

  if (!isFeatureCollection(strobeFeatures)) return null;

  return (
    <Source id={SourceNameEnum.STROBES} type="geojson" data={strobeFeatures} generateId>
      <Layer key={LayerNameEnum.STROBE_SECTORS} {...strobeSectorLayer} />
      <Layer key={LayerNameEnum.STROBE_AZIMUTHS} {...strobeAzimuthLayer} />
    </Source>
  );
}

export default memo(StrobeLayer);
