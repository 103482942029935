import { useEffect, useState } from 'react';
import { MessageDescriptor, PrimitiveType, useIntl } from 'react-intl';
import { AnyObject, ObjectSchema } from 'yup';

export type FormatMessageType = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

function useValidation(constructSchema: (formatMessage: FormatMessageType) => ObjectSchema<AnyObject>) {
  const { formatMessage } = useIntl();
  const [validationSchema, setValidationSchema] = useState<ObjectSchema<AnyObject>>();

  useEffect(() => {
    setValidationSchema(constructSchema(formatMessage));
  }, [formatMessage, constructSchema]);

  return validationSchema;
}

export default useValidation;
