import { invalidateVersion, updateSituation } from '@redux/situation/situation.reducer';
import { selectSituation } from '@redux/situation/situation.selectors';
import { store } from '@redux/store';
import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQueryWithReAuth';
import { C2_URL } from '@services/c2/c2.api';
import { eventToSituation, modelSseHandler } from '@utils/sse.utils';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { SseActionEnum, SseMessageEvent } from '@/types/c2/sse.types';

const customBackOff = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
};

export const sseApi = createApi({
  reducerPath: 'sseApi',
  baseQuery: retry(baseQueryWithReAuth, {
    backoff: customBackOff,
    retryCondition: (error: unknown) => {
      if (!!error && typeof error === 'object' && 'status' in error) {
        return error.status !== 401 && error.status !== 403;
      }
      return false;
    },
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getSituation: builder.query({
      queryFn: async () => ({
        data: null,
      }),
      onCacheEntryAdded: async ({ site, platforms, token }, { dispatch, cacheDataLoaded, cacheEntryRemoved }) => {
        const platformsString = platforms.join(',');
        const url = `${C2_URL}/situations/stream?sites=${site}&platforms=${platformsString}`;
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: token,
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            'Content-Type': 'text/event-stream',
          },
        });

        try {
          await cacheDataLoaded;

          eventSource.addEventListener('message', (ev: { data: string; lastEventId: string }) => {
            if (ev.lastEventId !== SseActionEnum.HEARTBEAT) {
              dispatch(updateSituation(JSON.parse(ev.data)));
            }
          });
          eventSource.addEventListener('error', (e) => {
            console.error('Error: ', e);
            dispatch(invalidateVersion());
          });
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        eventSource.close();
      },
    }),
    getSituationDiff: builder.query({
      queryFn: async () => ({
        data: null,
      }),
      onCacheEntryAdded: async ({ site, platforms, token }, { dispatch, cacheDataLoaded, cacheEntryRemoved }) => {
        const platformsString = platforms.join(',');
        const url = `${C2_URL}/situations/stream/diff?sites=${site}&platforms=${platformsString}`;
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: token,
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            'Content-Type': 'text/event-stream',
          },
        });

        try {
          await cacheDataLoaded;

          eventSource.addEventListener('message', (ev: { data: string; lastEventId: string }) => {
            if (ev.lastEventId !== SseActionEnum.HEARTBEAT) {
              const currentSituation = selectSituation(store.getState());
              dispatch(updateSituation(eventToSituation(currentSituation, ev.data)));
            }
          });
          eventSource.addEventListener('error', (e) => {
            console.error('Error: ', e);
            sseApi.util.resetApiState();
            dispatch(invalidateVersion());
          });
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        eventSource.close();
      },
    }),
    getModelsStream: builder.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (
        { site, platforms, token }: { site: string; platforms: string[]; token: string },
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) => {
        const platformsString = platforms.join(',');
        const url = `${C2_URL}/models/stream?sites=${site}&platforms=${platformsString}`;
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: token,
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            'Content-Type': 'text/event-stream',
          },
        });
        try {
          await cacheDataLoaded;
          eventSource.addEventListener('message', (event) => {
            const actions = modelSseHandler(event as unknown as SseMessageEvent, site);
            actions.forEach((action) => dispatch(action));
          });
          eventSource.addEventListener('error', (e) => {
            console.error('Error:', e);
          });
        } catch {
          /* empty */
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active and perform cleanup
        await cacheEntryRemoved;
        eventSource.close();
      },
    }),
  }),
});

export const { useGetModelsStreamQuery, useGetSituationDiffQuery } = sseApi;
