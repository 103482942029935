import { FormatMessageType } from '@hooks/useValidation';
import { REQUIRED } from '@utils/validation/validation.constant';
import Yup from '@utils/validation/yup-extended';

export const systemParamSchema = (formatMessage: FormatMessageType) =>
  Yup.object({
    purgeSituation: Yup.number()
      .moreThan(0.1, formatMessage({ id: 'form.min' }, { value: 0 }))
      .max(90, formatMessage({ id: 'form.max' }, { value: 90 }))
      .required(formatMessage({ id: REQUIRED })),
    purgeSensorRaw: Yup.number()
      .moreThan(0, formatMessage({ id: 'form.min' }, { value: 0 }))
      .max(90, formatMessage({ id: 'form.max' }, { value: 90 }))
      .required(formatMessage({ id: REQUIRED })),
    maxStorageAge: Yup.number()
      .moreThan(0, formatMessage({ id: 'form.min' }, { value: 0 }))
      .max(90, formatMessage({ id: 'form.max' }, { value: 90 }))
      .required(formatMessage({ id: REQUIRED })),
  });
