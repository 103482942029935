import { useAppSelector } from '@hooks/redux.hooks';
import { selectHighestAlertType } from '@redux/situation/situation.selectors';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { NotificationType } from '@/types/data/data.types';

export default function AlertSounds() {
  const highestPriorityAlert = useAppSelector(selectHighestAlertType, isEqual);
  const [userInteracted, setUserInteracted] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const getPriorityAlarmSound = useCallback(() => {
    switch (highestPriorityAlert) {
      case NotificationType.SPACE_VIOLATION_ALERT:
        return '/audio/ladAlarm.mp3';
      case NotificationType.PERIMETER_EFFRACTION_ALERT:
        return '/audio/perimAlarm.mp3';
      case NotificationType.SENSOR_FAILURE_ALERT:
      case NotificationType.AUTOMATON_SEGMENT_FAILURE_ALERT:
        return '/audio/technicalAlarm.mp3';
      default:
        return null;
    }
  }, [highestPriorityAlert]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
    };
    const audio = audioRef.current;
    audio.loop = false;
    window.addEventListener('click', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      audio.pause();
      audio.loop = false;
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (highestPriorityAlert && !audio.loop && userInteracted) {
      const alarmSound = getPriorityAlarmSound();
      if (alarmSound) {
        audio.src = alarmSound;
        audio.currentTime = 0;
        audio.muted = false;
        audio.volume = 0.4;
        audio.loop = true;
        audio
          .play()
          .then(() => {
            setUserInteracted(true);
          })
          .catch(() => {
            setUserInteracted(false);
          });
      }
    } else if (!highestPriorityAlert && audio.loop) {
      audio.pause();
      audio.loop = false;
    }
  }, [getPriorityAlarmSound, highestPriorityAlert, userInteracted]);

  return <></>;
}
