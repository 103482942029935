import { Coordinates } from '@/types/commons/commons.types';

export const EARTH_RADIUS_IN_KILOMETERS = 6371;

// This function calculates the distance between two coordinate points using the Haversine formula which accounts for a spherical model of the earth.
// A spherical model should be precise enough for our current purposes. If we need more precise data we'll have to use a specialized library which factors in the 'real' model of the earth.
// https://fr.wikipedia.org/wiki/Formule_de_haversine
export function getHaversineDistance(startPosition: Coordinates, endPosition: Coordinates): number {
  const toRadians = (angle: number): number => (angle * Math.PI) / 180;

  const deltaLat = toRadians(endPosition.latitude - startPosition.latitude);
  const deltaLon = toRadians(endPosition.longitude - startPosition.longitude);

  const haversine =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(toRadians(startPosition.latitude)) *
      Math.cos(toRadians(endPosition.latitude)) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);

  return 2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine)) * EARTH_RADIUS_IN_KILOMETERS;
}
