import { Abstract, Coordinates, Modify, WithHeight } from '../commons/commons.types';
import { Drone } from './drone.types';
import { FlySection } from './route.types';

export enum FlightPlanStatusEnum {
  DONE = 'DONE',
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
}

export enum FlightPlanTypeEnum {
  CLASSIC = 'CLASSIC',
  PRECONFIGURED = 'PRECONFIGURED',
}

export interface FlightPlanPortion {
  section: FlySection;
}

export type FlightPlan = {
  id: number;
  site: string;
  code: string;
  drone?: Drone;
  startPosition: Coordinates | null;
  polygon: Coordinates[];
  flyZoneStart: string;
  flyZoneEnd: string;
  portions: FlightPlanPortion[];
  startTime: string;
  endTime: string;
  origin: FlightPlan | null;
  status: FlightPlanStatusEnum;
  type: FlightPlanTypeEnum;
} & Abstract &
  WithHeight;

export type NormalizedFlightPlan = Modify<
  FlightPlan,
  {
    drone: string | null;
  }
>;
