import HologardeBackground from '@assets/hologarde-background.jpg';
import { Center, Flex, VStack } from '@chakra-ui/layout';
import { Image, Spinner, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateActiveUser, updateHasSelectedGroup, userLogout } from '@redux/authent/authent.reducer';
import { selectActiveUser, selectHasSelectedGroup } from '@redux/authent/authent.selectors';
import { usePatchPersonalActiveGroupMutation } from '@services/authent/user.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupLight } from '@/types/authent/groups.types';

import CustomSelect from './common/inputs/CustomStyledSelect';
import LoginButton from './login/LoginButton';

type Props = {
  children: ReactNode;
};

export default function UserGroupInit({ children }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const hasSelectedGroup = useAppSelector(selectHasSelectedGroup);
  const activeUser = useAppSelector(selectActiveUser);

  const [hasChoosenGroup, setHasChoosenGroup] = useState<boolean>(hasSelectedGroup);
  const [selectedGroup, setSelectedGroup] = useState<GroupLight | null>(activeUser.activeGroup);

  const updateHasSelectedActiveGroup = useWithDispatch(updateHasSelectedGroup);
  const updateActiveUserAction = useWithDispatch(updateActiveUser);
  const [patchPersonalActiveGroup, { isLoading }] = usePatchPersonalActiveGroupMutation();

  if (activeUser.groups.length === 0) {
    dispatch(userLogout());
    createToast(formatMessage({ id: 'authent.userGroup.noGroup' }), ToastStatusEnum.ERROR);
    return null;
  }

  if (activeUser.groups.length === 1 && !hasChoosenGroup && !isLoading) {
    setHasChoosenGroup(true);
    patchPersonalActiveGroup({ newActiveGroupId: activeUser.groups[0].id })
      .unwrap()
      .then((data) => {
        updateActiveUserAction(data);
        setHasChoosenGroup(true);
        updateHasSelectedActiveGroup(true);
      })
      .catch(() => dispatch(userLogout()));
    return null;
  }

  if (!isLoading && hasSelectedGroup && activeUser.activeGroup) {
    return <>{children}</>;
  }

  function handleClick() {
    if (selectedGroup) {
      patchPersonalActiveGroup({ newActiveGroupId: selectedGroup.id })
        .unwrap()
        .then((data) => {
          updateActiveUserAction(data);
          setHasChoosenGroup(true);
          updateHasSelectedActiveGroup(true);
        });
    }
  }

  return (
    <Flex width="100%" height="100%" alignContent="center" justifyContent="center">
      <Image src={HologardeBackground} fit="cover" height="100%" width="100%" position="absolute" />
      {hasChoosenGroup && !hasSelectedGroup ? (
        <Center>
          <Spinner size="xl" color="neutral.white" />
        </Center>
      ) : (
        <VStack
          position="fixed"
          right={0}
          alignItems="start"
          gap={7}
          width="460px"
          height="100%"
          paddingLeft={8}
          paddingRight="104px"
          justifyContent="center"
          backgroundColor="neutral.900-op30"
        >
          <VStack gap={1} alignItems="start">
            <Text fontSize="16px" lineHeight="24px">
              <FormattedMessage id="authent.userGroup.title" />
            </Text>
            <CustomSelect
              value={selectedGroup?.code}
              isClearable={false}
              width={292}
              options={activeUser.groups.map((group) => ({ value: group.code, label: group.name }))}
              onChange={(value) => setSelectedGroup(activeUser.groups.find((group) => group.code === value) ?? null)}
            />
          </VStack>
          <LoginButton
            onClick={handleClick}
            isLoading={isLoading}
            isDisabled={!selectedGroup}
            label="global.validate"
          />
        </VStack>
      )}
    </Flex>
  );
}
