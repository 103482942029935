import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { formatDate, formatDuration } from '@utils/date.utils';
import format from 'date-fns/format';
import { useIntl } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';

type Props = {
  startTime: string;
  endTime: string | null;
  compact: boolean;
};

export default function AlertTime({ startTime, endTime, compact }: Readonly<Props>) {
  const situationTime = useAppSelector(selectSituationTime) ?? Date.now();
  const { formatMessage } = useIntl();

  const date = formatDate(startTime, DateTimeEnum.DATE);
  const time = format(new Date(startTime), "HH'h'mm");
  const since = formatMessage(
    { id: `notification.${endTime ? 'durationEnded' : 'durationOngoing'}` },
    {
      duration: formatDuration(startTime, endTime ?? situationTime),
    },
  );

  return (
    <HStack gap={1} flexGrow={1}>
      <Text
        fontSize="12px"
        color="neutral.200"
        paddingX={0.5}
        paddingY={0.25}
        backgroundColor="neutral.900"
        marginRight="auto"
      >
        {since}
      </Text>
      {!compact && (
        <>
          <Text fontSize="12px" color="neutral.200">
            {time}
          </Text>
          <Text>{date}</Text>
        </>
      )}
    </HStack>
  );
}
