import { ReactComponent as Calendar } from '@assets/icons/24x24/ic-calendar-24.svg';
import { ReactComponent as Time } from '@assets/icons/24x24/ic-time-24.svg';
import { Divider, HStack } from '@chakra-ui/layout';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { format, isValid } from 'date-fns';
import { ChangeEvent, CSSProperties } from 'react';

import { DateTimeEnum } from '@/types/dateTime.types';

interface OwnProps {
  type?: DateTimeEnum.DATE | DateTimeEnum.DATE_TIME;
  value: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  style?: CSSProperties;
  variant?: 'filled';
  showSecond?: boolean;
  onChange: (v: string) => void;
}

function getFormat(type: DateTimeEnum, showSecond: boolean) {
  switch (type) {
    case DateTimeEnum.TIME:
      if (showSecond) {
        return 'HH:mm:ss';
      }
      return 'HH:mm';
    case DateTimeEnum.DATE:
    default:
      return 'yyyy-MM-dd';
  }
}

function hasDate(date: string | undefined): boolean {
  return !!date && new Date(date).getFullYear().toString().length === 4;
}

export default function CustomInputDateTime({
  value,
  type = DateTimeEnum.DATE,
  min,
  max,
  variant,
  showSecond = true,
  ...props
}: Readonly<OwnProps>) {
  const valueDate = isValid(new Date(value)) ? new Date(value) : new Date();

  function handleChange(e: ChangeEvent<HTMLInputElement>, type: DateTimeEnum.DATE | DateTimeEnum.TIME) {
    let date: string;
    let time: string;
    if (type === DateTimeEnum.DATE) {
      date = e.target.value;
      time = format(valueDate, getFormat(DateTimeEnum.TIME, showSecond));
    } else {
      date = format(valueDate, getFormat(DateTimeEnum.DATE, showSecond));
      time = e.target.value;
    }
    const newDateTime = new Date(`${date}T${time}`);

    props.onChange(
      isValid(newDateTime) && newDateTime.getFullYear().toString().length === 4
        ? newDateTime.toISOString()
        : valueDate.toISOString(),
    );
  }

  function getInput(inputType: DateTimeEnum.DATE | DateTimeEnum.TIME = DateTimeEnum.DATE) {
    const timeWidth = showSecond ? '157px' : '129px';
    return (
      <InputGroup width={inputType === DateTimeEnum.DATE ? '177px' : timeWidth}>
        <Input
          data-peer
          value={format(valueDate, getFormat(inputType, showSecond))}
          type={inputType}
          step={inputType === DateTimeEnum.DATE || showSecond ? 1 : 60}
          cursor="text"
          min={min && isValid(new Date(min)) ? format(new Date(min), getFormat(inputType, showSecond)) : undefined}
          max={max && isValid(new Date(max)) ? format(new Date(max), getFormat(inputType, showSecond)) : undefined}
          padding={0}
          paddingLeft={2}
          height="48px"
          backgroundColor={variant ? 'neutral.900' : 'neutral.800'}
          _hover={{ borderColor: 'neutral.black' }}
          _focus={{ borderColor: 'neutral.black' }}
          css={css`
            ::-webkit-calendar-picker-indicator {
              background: transparent;
              z-index: 3;
              cursor: pointer;
              position: absolute;
              width: 48px;
              height: 48px;
              right: 0;
              top: 0;
            }
          `}
          style={props.style}
          disabled={inputType === DateTimeEnum.TIME && !hasDate(value)}
          {...props}
          onChange={(e) => handleChange(e, inputType)}
        />
        <Divider
          width="0px"
          height="46px"
          border="1px solid"
          borderColor="neutral.black"
          position="absolute"
          right="46px"
        />
        <InputRightElement
          height="44px"
          width="44px"
          marginTop={0.25}
          marginRight={0.25}
          padding={0}
          pointerEvents="none"
          border="2px solid"
          borderColor="neutral.800"
          backgroundColor="neutral.black"
          alignItems="center"
          justifyContent="center"
          color="neutral.300"
          _peerHover={{ borderColor: 'sky.500', backgroundColor: 'neutral.800', color: 'neutral.200' }}
          _peerFocusWithin={{ border: '0', backgroundColor: 'cyber.500', color: 'neutral.white' }}
        >
          {inputType === DateTimeEnum.DATE ? <Calendar /> : <Time />}
        </InputRightElement>
      </InputGroup>
    );
  }

  return (
    <HStack gap={2}>
      {getInput()}
      {type === DateTimeEnum.DATE_TIME && getInput(DateTimeEnum.TIME)}
    </HStack>
  );
}
