import { Text, VStack } from '@chakra-ui/layout';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { getCollaborativeData, getTrackUniqueId } from '@utils/target.utils';
import { toLonLatArray } from '@utils/validation/coordinates.utils';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'react-map-gl';

import { Target } from '@/types/c2/c2.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

function AircraftPermanentTooltip({ lastPosition, trackIds }: Readonly<Target<TargetLocation>>) {
  const altitude = lastPosition.location && 'altitude' in lastPosition.location && lastPosition.location.altitude;
  const [long, lat] = toLonLatArray(lastPosition!.location!.position, false)!;

  const tracks = useSelectorWithReplayMode(selectTrackFromRawSituationByTrackLights, trackIds.map(getTrackUniqueId));
  const collaborativeData = getCollaborativeData(tracks);

  const aircraftId = collaborativeData.aircraftId;

  if (!altitude && !aircraftId) {
    return null;
  }

  return (
    <Popup
      className="mapbox-popup"
      offset={25}
      latitude={lat}
      longitude={long}
      closeButton={false}
      closeOnClick={false}
    >
      <VStack gap={0.5} alignItems="flex-start">
        <Text size="md">
          <FormattedMessage id="target.identification.AIRCRAFT" />
        </Text>
        <Text fontSize="16px">{aircraftId}</Text>
        <Text fontSize="16px">{altitude && `${altitude.toFixed(2)}m`}</Text>
      </VStack>
    </Popup>
  );
}

export default AircraftPermanentTooltip;
