import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { RootState } from '@redux/store';

import { MapIdEnum } from '@/types/map.types';

export function useSelectorWithMapId<T>(
  // eslint-disable-next-line
  selector: (state: RootState, mapId: MapIdEnum, params?: any) => T,
  // eslint-disable-next-line
  params?: any,
): T {
  const { mapId } = useMapContext();
  return useAppSelector((state) => selector(state, mapId, params));
}
