import { SystemStyleObject } from '@chakra-ui/system';
import { CreatableSelect, Select, SingleValue, SizeProp } from 'chakra-react-select';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

export interface Option {
  isDisabled?: boolean;
  value: string;
  label: string;
}

type Creatable =
  | {
      isCreatable?: false;
      onCreate?: never;
    }
  | { isCreatable: true; onCreate: (value: string) => void };

interface BasicProps {
  isClearable?: boolean;
  isInvalid?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  key?: string;
  options: Option[];
  placeholder?: string;
  size?: SizeProp;
  value?: string;
  menuPortalTarget?: HTMLElement | null;
  formatOptionLabel?: (value: Option) => ReactElement;
  onChange: (value: string) => void;
  variant?: string;
  menuPortalWidth?: string;
  style?: {
    width?: string;
    color?: string;
    height?: number;
    borderWidth?: number;
    borderRadius?: number | string;
    borderColor?: string;
    backgroundColor?: string;
    cursor?: string;
  };
}

type OwnProps = BasicProps & Creatable;

export default function CustomSelect({
  isCreatable = false,
  isClearable = true,
  isSearchable = false,
  isDisabled = false,
  menuPortalTarget = document.body,
  options,
  value,
  onChange,
  onCreate,
  variant,
  menuPortalWidth = 'auto',
  style,
  ...props
}: OwnProps) {
  const { formatMessage } = useIntl();

  const chakraStyles = {
    control: (provided: SystemStyleObject) => ({
      ...provided,
      fontSize: '14px',
      ...style,
    }),
    dropdownIndicator: (provided: SystemStyleObject) => ({
      ...provided,
      marginX: '6px',
    }),
    valueContainer: (provided: SystemStyleObject) => ({
      ...provided,
      paddingX: 2,
    }),
  };

  return isCreatable ? (
    <CreatableSelect
      closeMenuOnSelect
      isClearable={isClearable}
      isSearchable={isSearchable}
      menuPlacement="auto"
      options={options}
      selectedOptionStyle="check"
      useBasicStyles
      noOptionsMessage={() => formatMessage({ id: 'components.select.noOption' })}
      formatCreateLabel={(value) => formatMessage({ id: 'components.select.create' }, { value })}
      chakraStyles={chakraStyles}
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 10,
          minWidth: menuPortalWidth,
          color: style?.color,
          fontSize: '14px',
          backgroundColor: style?.backgroundColor,
        }),
      }}
      variant={variant}
      menuPortalTarget={menuPortalTarget}
      {...props}
      value={options.find((option) => option.value === value)}
      onCreateOption={onCreate}
      onChange={(option: SingleValue<Option>) => onChange(option?.value ?? '')}
    />
  ) : (
    <Select
      closeMenuOnSelect
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      menuPlacement="auto"
      options={options}
      selectedOptionStyle="check"
      useBasicStyles
      noOptionsMessage={() => formatMessage({ id: 'components.select.noOption' })}
      chakraStyles={chakraStyles}
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 10,
          minWidth: menuPortalWidth,
          color: style?.color,
          fontSize: '14px',
          backgroundColor: style?.backgroundColor,
        }),
      }}
      variant={variant}
      menuPortalTarget={menuPortalTarget}
      {...props}
      value={options.find((option) => option.value === value) ?? null}
      onChange={(option: SingleValue<Option>) => onChange(option?.value ?? '')}
    />
  );
}
