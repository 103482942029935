import { selectActiveUser, selectSessionUuid } from '@redux/authent/authent.selectors';
import { updateVideoTrackerState } from '@redux/global/global.reducer';
import { selectTrackingStateOfCameraFlux } from '@redux/global/global.selector';
import {
  selectAxisInvertedY,
  selectLinearJoystickCoefficient,
  selectZoomJoystickCameraSensitivity,
} from '@redux/settings/settings.selectors';
import { usePostCameraCommandMutation } from '@services/c2/c2.api';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useIntl } from 'react-intl';

import {
  CameraCommand,
  CameraCommandEnum,
  CameraCommandFocus,
  CameraCommandHybridFocus,
  CameraCommandLookAt,
  CameraCommandMove,
  CameraCommandRebootCharm,
  CameraCommandReleaseToken,
  CameraCommandRequestToken,
  CameraCommandSensorTracking,
  CameraCommandSetAt,
  CameraCommandSetIso,
  CameraCommandSetPolarity,
  CameraCommandSetWhiteBalance,
  CameraCommandSimpleRound,
  CameraCommandStopAllTrackings,
  CameraCommandVideoTracking,
  CameraCommandZoom,
  CameraCommandZoomSync,
  FocusDirectionEnum,
  IsoModeEnum,
  Target,
  VideoBox,
  WhiteBalanceModeEnum,
  ZoomDirectionEnum,
} from '@/types/c2/c2.types';
import { Coordinates } from '@/types/commons/commons.types';
import { LocationTypeEnum, SpatialLocation, TargetLocation } from '@/types/sensor/sensor.types';
import { CameraStatus, TrackingStatusEnum } from '@/types/sensor/status.types';

import { useAppSelector } from './redux.hooks';
import { useWithDispatch } from './useWithDispatch';

interface UseCameraCommandParameter {
  openedSubCameraId?: number;
  cameraStatus: CameraStatus | null;
  initCameraOperatorUuid?: string | null;
}

export interface UseCameraCommandFunctions {
  zoomStop: () => void;
  zoomIn: (withStop?: boolean) => void;
  zoomOut: (withStop?: boolean) => void;
  setIso: (iso: IsoModeEnum) => void;
  setWhiteBalance: (whiteBalance: WhiteBalanceModeEnum) => void;
  setPolarity: (black: boolean) => void;
  hybridFocus: (focusLevel: number) => void;
  zoomSync: () => void;
  focusIn: () => void;
  focusOut: () => void;
  focusStop: () => void;
  focusAuto: () => void;
  videoTracking: (box: VideoBox | null, mode: TrackingStatusEnum) => void;
  videoBuffering: () => void;
  move: (speed: { panSpeed: number; tiltSpeed: number }) => void;
  simpleRound: (active: boolean, speed?: number) => void;
  moveJoystick: (dx: number, dy: number) => void;
  isLoading: false | true;
  isError: false | true;
  requestToken: (cameraStatus?: CameraStatus) => Promise<void>;
  sensorTracking: (target: Target<TargetLocation> | null, force: boolean, cameraStatus?: CameraStatus) => void;
  stopAllTrackings: (target: Target<TargetLocation> | null, force: boolean) => void;
  lookAt: (
    position: Coordinates,
    segmentStart: Coordinates | null,
    segmentEnd: Coordinates | null,
    altitude: number | null,
    objectSize: number | null,
    force: boolean,
    camera?: CameraStatus,
  ) => void;
  setAt: (pan: number, tilt: number, fov?: { fluxId: number; fov: number }[], camera?: CameraStatus) => void;
  releaseToken: (cameraStatus?: CameraStatus) => void;
  rebootCharm: () => void;
  canCommand: boolean;
  isSubCameraAvailable: boolean;
}

export const useCameraCommand: (params: UseCameraCommandParameter) => UseCameraCommandFunctions = ({
  cameraStatus,
  openedSubCameraId,
}: UseCameraCommandParameter) => {
  const { formatMessage } = useIntl();
  const [postCameraCommand, { isError, isLoading }] = usePostCameraCommandMutation();

  const user = useAppSelector(selectActiveUser);
  const sessionUuid = useAppSelector(selectSessionUuid);
  const axisInvertedY = useAppSelector(selectAxisInvertedY);
  const linearJoystickCoefficient = useAppSelector(selectLinearJoystickCoefficient);
  const zoomJoystickCameraSensitivity = useAppSelector(selectZoomJoystickCameraSensitivity);
  const hasControl = cameraStatus?.cameraToken?.cameraOperatorUuid === sessionUuid;
  const isSubCameraAvailable = !!cameraStatus?.videoStatuses.some((status) => status.id === openedSubCameraId);
  const site = user.activeSite?.code ?? '';
  const appCode = cameraStatus?.configuration.appCode ?? '';
  const cameraCode = cameraStatus?.configuration.code ?? '';
  const trackingStateOfSelectedFlux = useAppSelector(
    selectTrackingStateOfCameraFlux(cameraStatus ? getSensorUniqueCode(cameraStatus) : null, openedSubCameraId),
  );
  const updateTrackingState = useWithDispatch(updateVideoTrackerState);

  const requestToken = async (cameraStatus?: CameraStatus): Promise<void> => {
    const command: CameraCommandRequestToken = {
      type: CameraCommandEnum.CAMERA_COMMAND_REQUEST_TOKEN,
      sensorCode: cameraStatus?.configuration.code ?? cameraCode,
      cameraOperatorUuid: sessionUuid,
      userName: user.name,
      userLogin: user.login,
    };
    return postCameraCommand({
      command,
      site: cameraStatus?.configuration.site ?? site,
      appCode: cameraStatus?.configuration.appCode ?? appCode,
    })
      .unwrap()
      .catch((error) => {
        if (error.status == 403) {
          createToast(formatMessage({ id: 'cameras.cameraMenu.errorControl' }), ToastStatusEnum.ERROR);
        }
      })
      .then();
  };

  const releaseToken = (cameraStatus?: CameraStatus) => {
    const command: CameraCommandReleaseToken = {
      type: CameraCommandEnum.CAMERA_COMMAND_RELEASE_TOKEN,
      sensorCode: cameraStatus?.configuration.code ?? cameraCode,
      cameraOperatorUuid: sessionUuid,
      userLogin: user.login,
    };
    postCameraCommand({
      command,
      site: cameraStatus?.configuration.site ?? site,
      appCode: cameraStatus?.configuration.appCode ?? appCode,
    })
      .unwrap()
      .catch((error) => {
        if (error.status == 403) {
          createToast(formatMessage({ id: 'cameras.cameraMenu.errorControl' }), ToastStatusEnum.ERROR);
        }
      });
  };

  async function sendCommand(command: CameraCommand, force?: boolean, cameraStatus?: CameraStatus) {
    const canControl = cameraStatus ? cameraStatus.cameraToken?.cameraOperatorUuid === sessionUuid : hasControl;
    const fullCommand = {
      site: cameraStatus?.configuration.site ?? site,
      appCode: cameraStatus?.configuration.appCode ?? appCode,
      command,
    };
    if (force && !canControl) {
      return requestToken(cameraStatus).then(() => postCameraCommand(fullCommand));
    } else if (canControl) {
      return postCameraCommand(fullCommand);
    }
  }

  const adjustSpeedForZoom = (joystickTilt: number) => {
    //TODO get right videoStatus HLF-329
    if (isNaN(joystickTilt)) {
      return 0;
    }
    const fov = cameraStatus?.videoStatuses.find((st) => st.id === openedSubCameraId)?.fov;
    const fovMax = cameraStatus?.configuration.subCameras.find((sc) => sc.cameraId === openedSubCameraId)?.maxViewAngle;
    if (fov && fovMax && joystickTilt && openedSubCameraId !== undefined) {
      //Use max speed and max fov to ensure a constant time to travel current fov
      const maxSpeedForCurrentZoom = fov / fovMax;
      const maxSpeedForCurrentZoomAndSensitivity =
        maxSpeedForCurrentZoom + (1 - zoomJoystickCameraSensitivity) * (1 - maxSpeedForCurrentZoom);
      return joystickTilt * maxSpeedForCurrentZoomAndSensitivity;
    }
    return joystickTilt;
  };

  const simpleRound = (active: boolean, speed?: number): void => {
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_SIMPLE_ROUND,
      cameraOperatorUuid: sessionUuid,
      sensorCode: cameraCode,
      roundOn: active,
      speed: speed,
    } as CameraCommandSimpleRound).then();
  };

  const move = (speed: { panSpeed: number; tiltSpeed: number }) => {
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_MOVE,
      cameraOperatorUuid: sessionUuid,
      sensorCode: cameraCode,
      panSpeed: speed.panSpeed,
      tiltSpeed: axisInvertedY ? -speed.tiltSpeed : speed.tiltSpeed,
    } as CameraCommandMove).then();
  };

  const setIso = (iso: IsoModeEnum) => {
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_ISO_MODE,
      cameraOperatorUuid: sessionUuid,
      sensorCode: cameraCode,
      cameraId: openedSubCameraId,
      iso: iso,
    } as CameraCommandSetIso).then();
  };

  const setWhiteBalance = (whiteBalance: WhiteBalanceModeEnum) => {
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_WHITER_BALANCE,
      cameraOperatorUuid: sessionUuid,
      sensorCode: cameraCode,
      cameraId: openedSubCameraId,
      whiteBalance: whiteBalance,
    } as CameraCommandSetWhiteBalance).then();
  };

  const hybridFocus = (focusLevel: number) => {
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_HYBRID_FOCUS,
      cameraOperatorUuid: sessionUuid,
      sensorCode: cameraCode,
      cameraId: openedSubCameraId,
      focusLevel: focusLevel,
    } as CameraCommandHybridFocus).then();
  };

  const setPolarity = (black: boolean) => {
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_SET_POLARITY,
      cameraOperatorUuid: sessionUuid,
      sensorCode: cameraCode,
      cameraId: openedSubCameraId,
      black: black,
    } as CameraCommandSetPolarity).then();
  };

  function calculateSpeed(dx: number, minSpeed: number, maxSpeed: number): number {
    if (dx === 0) {
      return 0;
    }
    // Configuration of the joystick continuous between linear and cubic
    // depending on the linear joystick coefficient
    // Explanation :
    // a linear joystick coefficient
    // Joystick configuration is corresponding to h(\x) = a*x + (1-a)*x^3
    let x: number = +(linearJoystickCoefficient * dx + (1 - linearJoystickCoefficient) * Math.pow(dx, 3));
    x = +adjustSpeedForZoom(x).toFixed(2);

    return Math.abs(x) < minSpeed ? Math.sign(dx) * minSpeed : x * maxSpeed;
  }

  const moveJoystick = (dx: number, dy: number) => {
    if (!hasControl) {
      return;
    }

    const vxMin = cameraStatus.configuration.vxMin;
    const vxMax = cameraStatus.configuration.vxMax;
    const vyMin = cameraStatus.configuration.vyMin;
    const vyMax = cameraStatus.configuration.vyMax;

    const panSpeed: number = calculateSpeed(dx, vxMin, vxMax);
    const tiltSpeed: number = calculateSpeed(dy, vyMin, vyMax);

    move({ panSpeed: panSpeed, tiltSpeed: tiltSpeed });
  };

  const onZoom = (direction: ZoomDirectionEnum) => (withStop?: boolean) => {
    if (hasControl && openedSubCameraId !== undefined) {
      sendCommand({
        type: CameraCommandEnum.CAMERA_COMMAND_ZOOM,
        cameraOperatorUuid: sessionUuid,
        sensorCode: cameraCode,
        direction: direction,
        cameraId: openedSubCameraId,
      } as CameraCommandZoom).then(() => withStop && onZoom(ZoomDirectionEnum.STOP));
    }
  };

  const zoomSync = () => {
    if (hasControl) {
      const status = cameraStatus.videoStatuses.find((video) => video.id === openedSubCameraId);
      if (status) {
        sendCommand({
          type: CameraCommandEnum.CAMERA_COMMAND_ZOOM_SYNC,
          cameraOperatorUuid: sessionUuid,
          sensorCode: cameraCode,
          zoom: status.zoom,
          fov: status.fov,
          cameraId: openedSubCameraId,
        } as CameraCommandZoomSync).then();
      }
    }
  };
  const onFocus = (direction: FocusDirectionEnum) => () => {
    if (hasControl && openedSubCameraId !== undefined) {
      sendCommand({
        type: CameraCommandEnum.CAMERA_COMMAND_FOCUS,
        sensorCode: cameraCode,
        cameraOperatorUuid: sessionUuid,
        direction,
        cameraId: openedSubCameraId,
      } as CameraCommandFocus).then();
    }
  };

  const sensorTracking = (target: Target<TargetLocation> | null, force: boolean, cameraStatus?: CameraStatus) => {
    const type = target?.lastPosition.location?.type === LocationTypeEnum.SPATIAL ? '3D' : '2D';
    sendCommand(
      {
        type: CameraCommandEnum.CAMERA_COMMAND_SENSOR_TRACKING,
        sensorCode: cameraStatus?.configuration.code ?? cameraCode,
        cameraOperatorUuid: sessionUuid,
        trackedTargetId: target?.id ?? null,
        targetFirstPosition: {
          type: type,
          longitude: target?.lastPosition.location?.position?.longitude,
          latitude: target?.lastPosition.location?.position?.latitude,
          altitude: type === '2D' ? null : (target?.lastPosition.location as SpatialLocation).altitude,
        },
      } as CameraCommandSensorTracking,
      force,
      cameraStatus,
    ).then(() => onFocus(FocusDirectionEnum.AUTO_FOCUS)());
  };

  const stopAllTrackings = (target: Target<TargetLocation> | null, force: boolean) => {
    sendCommand(
      {
        type: CameraCommandEnum.CAMERA_COMMAND_STOP_ALL_TRACKINGS,
        cameraOperatorUuid: sessionUuid,
        trackedTargetId: target?.id ?? null,
      } as CameraCommandStopAllTrackings,
      force,
    ).then();
  };

  const lookAt = (
    position: Coordinates,
    segmentStart: Coordinates | null,
    segmentEnd: Coordinates | null,
    altitude: number | null,
    objectSize: number | null,
    force: boolean,
    cameraStatus?: CameraStatus,
  ) => {
    const segments = segmentStart && segmentEnd ? { segmentStart, segmentEnd } : null;
    sendCommand(
      {
        type: CameraCommandEnum.CAMERA_COMMAND_LOOK_AT,
        sensorCode: cameraStatus?.configuration.code ?? cameraCode,
        cameraOperatorUuid: sessionUuid,
        position: position,
        segmentStart: segments?.segmentStart,
        segmentEnd: segments?.segmentEnd,
        objectSize,
        altitude,
      } as CameraCommandLookAt,
      force,
      cameraStatus,
    ).then(() => onFocus(FocusDirectionEnum.AUTO_FOCUS)());
  };

  const setAt = (pan: number, tilt: number, fov?: { fluxId: number; fov: number }[], cameraStatus?: CameraStatus) => {
    sendCommand(
      {
        type: CameraCommandEnum.CAMERA_COMMAND_SET_AT,
        sensorCode: cameraStatus?.configuration.code ?? cameraCode,
        cameraOperatorUuid: sessionUuid,
        pan: pan,
        tilt: tilt,
        fov: fov,
      } as CameraCommandSetAt,
      false,
      cameraStatus,
    ).then();
  };

  const videoTracking = (box: VideoBox | null, mode: TrackingStatusEnum) => {
    if (!hasControl) {
      return;
    }
    sendCommand({
      type: CameraCommandEnum.CAMERA_COMMAND_VIDEO_TRACKING,
      sensorCode: cameraCode,
      cameraOperatorUuid: sessionUuid,
      box: box,
      mode: mode,
      cameraId: openedSubCameraId,
    } as CameraCommandVideoTracking).then();
  };

  const rebootCharm = () => {
    createToast(formatMessage({ id: 'cameras.charm.rebootCommand' }), ToastStatusEnum.INFO);
    postCameraCommand({
      command: {
        type: CameraCommandEnum.CAMERA_COMMAND_REBOOT_CHARM,
        sensorCode: cameraCode,
        cameraOperatorUuid: sessionUuid,
      } as CameraCommandRebootCharm,
      site,
      appCode,
    }).then((result) => {
      if ('error' in result) {
        createToast(formatMessage({ id: 'cameras.charm.rebootFailed' }), ToastStatusEnum.ERROR);
      } else {
        createToast(formatMessage({ id: 'cameras.charm.rebootSuccess' }), ToastStatusEnum.SUCCESS);
      }
    });
  };

  const videoBuffering = () => {
    const cameraUniqueCode = cameraStatus ? getSensorUniqueCode(cameraStatus.configuration) : null;
    if (!hasControl || openedSubCameraId === undefined || !cameraUniqueCode) {
      return;
    }

    if (trackingStateOfSelectedFlux === null || trackingStateOfSelectedFlux === TrackingStatusEnum.DISABLED) {
      videoTracking(null, TrackingStatusEnum.PENDING);
      updateTrackingState({
        cameraCode: cameraUniqueCode,
        fluxId: openedSubCameraId,
        state: TrackingStatusEnum.PENDING,
      });
    } else {
      videoTracking(null, TrackingStatusEnum.DISABLED);
      updateTrackingState({
        cameraCode: cameraUniqueCode,
        fluxId: openedSubCameraId,
        state: TrackingStatusEnum.DISABLED,
      });
    }
  };

  return {
    zoomIn: onZoom(ZoomDirectionEnum.IN),
    zoomOut: onZoom(ZoomDirectionEnum.OUT),
    zoomStop: onZoom(ZoomDirectionEnum.STOP),
    focusIn: onFocus(FocusDirectionEnum.IN),
    zoomSync,
    focusOut: onFocus(FocusDirectionEnum.OUT),
    focusStop: onFocus(FocusDirectionEnum.STOP),
    focusAuto: onFocus(FocusDirectionEnum.AUTO_FOCUS),
    setIso,
    setWhiteBalance,
    setPolarity,
    hybridFocus,
    move: move,
    simpleRound,
    moveJoystick,
    requestToken,
    sensorTracking,
    videoTracking,
    videoBuffering,
    stopAllTrackings,
    lookAt,
    setAt,
    releaseToken,
    rebootCharm,
    isLoading: isLoading,
    isError: isError,
    canCommand: hasControl,
    isSubCameraAvailable,
  };
};
