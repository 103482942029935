import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useMapSelectPosition } from '@hooks/useMapSelectPosition';
import { usePoiContextMenu } from '@hooks/usePoiContextMenu';
import { selectPoiBySelectedOptions } from '@redux/config/config.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import { poiLayer } from './poi.layer';

function PoiLayer() {
  const { mapId } = useMapContext();
  const poiFeatures = useAppSelector((state) => selectPoiBySelectedOptions(state, mapId));
  usePoiContextMenu();
  useMapSelectPosition();
  if (!isFeatureCollection(poiFeatures)) return null;

  return (
    <Source id={SourceNameEnum.POIS} type="geojson" data={poiFeatures} generateId>
      <Layer key={LayerNameEnum.POI} {...poiLayer} />
    </Source>
  );
}

export default memo(PoiLayer);
