import { ReactComponent as CaptureIcon } from '@assets/icons/32x32/ic-capture-32.svg';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import { useMapContext } from '@components/map/MapContext';
import { useAppStore } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { takeAndDownloadScreenshotFromEntireScreen } from '@utils/sensors/camera/screenshot.utils';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { MapIdEnum } from '@/types/map.types';

function ScreenshotControl() {
  const { mapId } = useMapContext();
  const { formatMessage } = useIntl();
  const store = useAppStore();

  const downloadScreenshot = useCallback(() => {
    const situationTime = selectSituationTime(store.getState(), mapId === MapIdEnum.REPLAY);
    void takeAndDownloadScreenshotFromEntireScreen(`${mapId}_map`, situationTime ?? 'UnknownDate');
  }, [store, mapId]);

  return (
    <ButtonBar
      label={formatMessage({ id: 'cameras.cameraMenu.capture' })}
      icon={CaptureIcon}
      orientation="bottom-left"
      tooltipPlacement="left"
      onClick={downloadScreenshot}
    />
  );
}

export default memo(ScreenshotControl);
