import { ReactComponent as DegreeZeroIcon } from '@assets/components/gauge/degre-0.svg';
import { ReactComponent as Minus43Degree } from '@assets/components/gauge/degre-minus43.svg';
import { ReactComponent as Plus43Degree } from '@assets/components/gauge/degre-plus43.svg';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { formatDegree, getPositionOnCircle } from '@utils/sensors/camera/gauge.utils';
import { memo } from 'react';

function getNumberComponent(value: number) {
  const position = getPositionOnCircle(-value, 88, { x: 94, y: 94 }, 0);
  switch (value) {
    case -43:
    case 43: {
      const icon = value === 43 ? Plus43Degree : Minus43Degree;
      return (
        <Icon
          as={icon}
          transform={value === 43 ? 'translateY(-30px)' : 'translateY(-5px)'}
          position="absolute"
          width="40px"
          height="24px"
          color="neutral.200"
          fontWeight="700"
          fontSize="16px"
          style={{
            WebkitTextStrokeWidth: '0.5px',
            WebkitTextStrokeColor: 'black',
          }}
          top={position.y}
          left={position.x}
        />
      );
    }
    default:
      return (
        <Text
          transform={`translateY(${value > 0 ? '-30px' : undefined})`}
          position="absolute"
          color="neutral.200"
          fontWeight="medium"
          style={{
            WebkitTextStrokeWidth: '0.5px',
            WebkitTextStrokeColor: 'neutral.black',
          }}
          top={position.y}
          left={position.x}
        >
          {formatDegree(value)}
        </Text>
      );
  }
}

type Props = {
  min: number;
  max: number;
};

function ElevationCompassBackground({ min, max }: Readonly<Props>) {
  const minMarkerPosition = getPositionOnCircle(-min, 94, { x: 94, y: 94 }, 0);
  const maxMarkerPosition = getPositionOnCircle(-max, 94, { x: 94, y: 94 }, 0);

  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      borderRadius="50%"
      border="2px solid"
      borderColor="neutral.white"
      boxShadow="inset 0 0 0 18px var(--chakra-colors-neutral-800-op70)"
      zIndex={1}
    >
      <Box
        width="calc(100% + 12px)"
        height="calc(100% + 12px)"
        top="-6px"
        left="-6px"
        position="absolute"
        borderRadius="50%"
        border="2px solid"
        borderColor="neutral.white"
        boxShadow="inset 0 0 0 2px var(--chakra-colors-neutral-800-op70)"
        sx={{
          mask: `conic-gradient(
            transparent ${90 - max - 0.5}deg,
            black ${90 - max - 0.5}deg ${90 - min + 0.5}deg,
            transparent ${90 - min + 0.5}deg
          )`,
        }}
      />
      <HStack position="absolute" right={0} top="50%" transform="translateY(-50%)" gap="17px">
        <Box height="2px" width="5px" backgroundColor="white" />
        <Icon as={DegreeZeroIcon} position="absolute" left="10px" width="20px" height="20px" />
      </HStack>
      {getNumberComponent(max)}
      {getNumberComponent(min)}
      <Box
        width="calc(100% + 12px)"
        height="calc(100% + 12px)"
        top="-6px"
        left="-6px"
        position="absolute"
        sx={{
          mask: 'radial-gradient(circle, black 92px, transparent 93px)',
        }}
      >
        <Box
          key={min}
          position="absolute"
          top={`${minMarkerPosition.y}px`}
          left={`${minMarkerPosition.x}px`}
          transform={`translateX(-50%) translateY(-50%) rotate(${-min}deg)`}
          height="2px"
          width="47px"
          transformOrigin="center"
          backgroundColor="white"
        />
        <Box
          key={max}
          position="absolute"
          top={`${maxMarkerPosition.y}px`}
          left={`${maxMarkerPosition.x}px`}
          transform={`translateX(-50%) translateY(-50%) rotate(${-max}deg)`}
          height="2px"
          width="47px"
          transformOrigin="center"
          backgroundColor="white"
        />
      </Box>
    </Box>
  );
}

export default memo(ElevationCompassBackground);
