import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectReplayEventFeatures } from '@redux/replay/replay.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

import { eventLayer } from './event.layer';

function EventLayer() {
  const replayEventFeatures = useSelectorWithReplayMode(selectReplayEventFeatures);

  if (!isFeatureCollection(replayEventFeatures)) return null;

  return (
    <Source id={SourceNameEnum.EVENTS} type="geojson" data={replayEventFeatures} generateId>
      <Layer key={LayerNameEnum.EVENTS} {...eventLayer} />
    </Source>
  );
}

export default memo(EventLayer);
