import { addLog, updateNewLogBookEntry } from '@redux/global/global.reducer';
import { createListenerMiddleware } from '@reduxjs/toolkit';

export const logbookListenerMiddleware = createListenerMiddleware();

logbookListenerMiddleware.startListening({
  actionCreator: addLog,
  effect: async (_, listenerApi) => {
    listenerApi.dispatch(updateNewLogBookEntry(true));
  },
});
