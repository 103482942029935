import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  background: 'neutral.black',
  color: 'neutral.white',
  fontFamily: 'roboto',
  fontSize: '16px',
  fontWeight: 'regular',
  outline: 'none',
  boxSizing: 'border-box',
  padding: 2,
  border: 'none',
  _focus: { boxShadow: 'none' },
});

const outline = defineStyle({
  rounded: 'none',
  background: 'neutral.900',
  borderColor: 'neutral.black',
  borderRadius: 0,
  border: '2px solid',
  _focus: {
    borderColor: 'sky.500',
  },
  _hover: {
    borderColor: 'sky.500',
  },
  _invalid: {
    borderColor: 'alertHi.500',
  },
});

export const textAreaTheme = defineStyleConfig({
  baseStyle,
  variants: { outline },
  defaultProps: { variant: 'outline' },
});
