import { useEffect, useRef } from 'react';

const MESSAGES = [
  '🪓 All work and no play makes Jack a dull boy!',
  '🚀 To infinity and beyond, Buzz Lightyear!',
  '🍩 Homer Simpson approved!',
  '🕶️ Welcome to the Matrix, Neo!',
  '🦸‍♀️ Wonder Woman would be proud!',
  "🧙‍♂️ You're a wizard, Harry!",
  '🏰 Winter is coming!',
  '💫 May the Force be with you!',
  '🍔 Time for a Krabby Patty!',
  '🕵️‍♂️ Elementary, my dear Watson!',
  '🏎️ Speeding up like Lightning McQueen!',
  '🍿 Let the Hunger Games begin!',
  '🏀 You’ve got game, Michael Jordan style!',
  '🦇 To the Batcave!',
  '🧟‍♂️ Time to fight some zombies!',
  '🦖 Welcome to Jurassic Park!',
  '🕶️ Men in Black ready!',
  '🐉 Unleash the dragon!',
  '🐙 Unleash the kraken!',
  '🕹️ Game on, Player One!',
];

const KONAMI_CODE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
];

type Code = { code: string[]; index: number };
type ActivationCode = Code & {
  subscribed: boolean;
  active: boolean;
  timeout?: NodeJS.Timeout;
};

function checkCode(code: Code, input: string): boolean {
  if (input == code.code[code.index++]) {
    if (code.index == code.code.length) {
      code.index = 0;
      return true;
    }
  } else {
    code.index = 0;
  }

  return false;
}

const activationCode: ActivationCode = {
  subscribed: false,
  code: KONAMI_CODE,
  index: 0,
  active: false,
};

function useCheatCode(name: string, code: string[], callback: () => void) {
  const activationCodeRef = useRef<ActivationCode>(activationCode);

  const codeRef = useRef<Code>({ code, index: 0 });
  const callbackRef = useRef(callback);

  useEffect(() => {
    if (!activationCodeRef.current.subscribed) {
      const activateCode = (code: ActivationCode) => {
        code.active = !code.active;
        console.log(
          `CHEAT CODES MODE ${code.active ? 'activated' : 'deactivated'}!`,
          `\n${MESSAGES[Math.round(Math.random() * code.code.length)]}`,
        );
      };

      const onKeyDown = (e: KeyboardEvent) => {
        if (checkCode(activationCodeRef.current, e.key)) {
          activateCode(activationCodeRef.current);
          if (activationCodeRef.current.active) {
            activationCodeRef.current.timeout = setTimeout(() => activateCode(activationCodeRef.current), 10000);
          } else {
            clearTimeout(activationCodeRef.current.timeout);
            activationCodeRef.current.timeout = undefined;
          }
        }
      };

      document.addEventListener('keydown', onKeyDown);
      activationCodeRef.current.subscribed = true;
      return () => {
        document.removeEventListener('keydown', onKeyDown);
        // eslint-disable-next-line
        activationCodeRef.current.subscribed = false;
      };
    }
  }, [activationCodeRef]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (activationCodeRef.current.active) {
        if (checkCode(codeRef.current, e.key)) {
          console.log(`Cheat code '${name}' activated!`);
          callbackRef.current();
        }
      } else {
        codeRef.current.index = 0;
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [name, activationCodeRef, codeRef, callbackRef]);
}

export default useCheatCode;
