import HologardeBackground from '@assets/hologarde-background.jpg';
import { ReactComponent as EyeIcon } from '@assets/icons/24x24/ic-eye-24.svg';
import { ReactComponent as EyeKoIcon } from '@assets/icons/24x24/ic-eye-ko-24.svg';
import { ReactComponent as LockIcon } from '@assets/icons/24x24/ic-padlock-24.svg';
import { ReactComponent as UserIcon } from '@assets/icons/24x24/ic-user-24.svg';
import { useBoolean } from '@chakra-ui/hooks';
import { Flex, VStack } from '@chakra-ui/layout';
import {
  FormControl,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import ErrorsContainer from '@components/common/form/ErrorsContainer';
import useValidation from '@hooks/useValidation';
import { LoginRequest, useLoginMutation } from '@services/authent/login.api';
import { createErrorMessage } from '@utils/common.utils';
import { Field, Formik } from 'formik';
import { ChangeEvent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { loginSchema } from '../../validations/login.schema';
import LoginButton from './LoginButton';

export default function Login() {
  const validationSchema = useValidation(loginSchema);
  const { formatMessage } = useIntl();

  const [login, { isLoading, isError, error }] = useLoginMutation();
  const [show, setShow] = useBoolean(false);
  const [formState, setFormState] = useState<LoginRequest>({
    login: '',
    password: '',
  });

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev: LoginRequest) => ({ ...prev, [name]: value }));

  const handleLogin = useCallback(async () => {
    await login(formState);
  }, [formState, login]);

  return (
    <Flex width="100%" height="100%" userSelect="none">
      <Image src={HologardeBackground} fit="cover" width="100%" height="100%" position="absolute" />
      <VStack
        position="fixed"
        right={0}
        alignItems="start"
        gap={7}
        width="460px"
        height="100%"
        paddingLeft={8}
        paddingRight="104px"
        justifyContent="center"
        zIndex={3}
        backgroundColor="neutral.900-op30"
      >
        <Text fontSize="32px" variant="space" letterSpacing="4px" fontWeight="400">
          <FormattedMessage id="authent.title" />
        </Text>
        <Formik initialValues={formState} onSubmit={handleLogin} validationSchema={validationSchema}>
          {({ handleSubmit, errors, touched, isSubmitting, submitForm, isValid, dirty }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <VStack gap={7}>
                <VStack gap={3}>
                  <VStack gap={1} width="100%" alignItems="start">
                    <Text fontSize="16px">
                      <FormattedMessage id="authent.user.login" />
                    </Text>
                    <FormControl isRequired isInvalid={touched.login && !!errors.login}>
                      <Field as={InputGroup} id="login" type="text" name="login">
                        <InputLeftElement pointerEvents="none" color="neutral.black">
                          <UserIcon width="24px" height="24px" />
                        </InputLeftElement>
                        <Input
                          id="login"
                          type="text"
                          name="login"
                          paddingRight={3}
                          onChange={handleChange}
                          placeholder={formatMessage({ id: 'authent.user.login' })}
                          variant="login"
                        />
                      </Field>
                    </FormControl>
                  </VStack>
                  <VStack gap={1} width="100%" alignItems="start">
                    <Text fontSize="16px">
                      <FormattedMessage id="authent.user.password" />
                    </Text>
                    <FormControl isRequired isInvalid={touched.password && !!errors.password}>
                      <Field as={InputGroup} id="password" name="password">
                        <InputLeftElement pointerEvents="none" color="neutral.500">
                          <LockIcon width="24px" height="24px" />
                        </InputLeftElement>
                        <Input
                          id="password"
                          name="password"
                          type={show ? 'text' : 'password'}
                          placeholder={formatMessage({ id: 'authent.user.password' })}
                          onChange={handleChange}
                          autoComplete="off"
                          variant="login"
                        />
                        <InputRightElement onClick={setShow.toggle} cursor="pointer">
                          <Icon as={show ? EyeIcon : EyeKoIcon} width="24px" height="24px" color="neutral.300" />
                        </InputRightElement>
                      </Field>
                    </FormControl>
                  </VStack>
                  <ErrorsContainer
                    touched={touched}
                    errors={errors}
                    apiError={isError && error ? createErrorMessage(error) : undefined}
                  />
                </VStack>
                <LoginButton
                  label="authent.connection"
                  onClick={submitForm}
                  isLoading={isLoading}
                  isSubmitting={isSubmitting}
                  isDisabled={!isValid || !dirty}
                />
                <Text width="100%" textAlign="left" color="neutral.200" whiteSpace="pre-line">
                  <FormattedMessage
                    id="authent.help"
                    values={{ number: formatMessage({ id: 'global.adminNumber' }) }}
                  />
                </Text>
              </VStack>
            </form>
          )}
        </Formik>
      </VStack>
    </Flex>
  );
}
