import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { chakra, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

//Ternary operation is the only way to distribute the union
//https://www.youtube.com/watch?v=DNTipH0-MoY
type Columns<O, K> = K extends unknown ? ColumnDef<O, K> : never;

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: Columns<Data, Data[keyof Data]>[];
  defaultSorting?: SortingState;
  containerHeight?: number | string;
};

export function DataTable<Data extends object>({ data, columns, defaultSorting = [] }: Readonly<DataTableProps<Data>>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  function getSortedIcon(column: Column<Data, unknown>) {
    const isSorted = column.getIsSorted();
    if (!isSorted) {
      return null;
    } else if (isSorted === 'desc') {
      return <TriangleDownIcon aria-label="sorted descending" />;
    } else {
      return <TriangleUpIcon aria-label="sorted ascending" />;
    }
  }

  return (
    <TableContainer overflowY="auto">
      <Table>
        <Thead position="sticky" top={0} zIndex={1}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    paddingX={1.5}
                    paddingY={1}
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    width={header.column.columnDef.size}
                    minWidth={header.column.columnDef.minSize}
                    maxWidth={header.column.columnDef.maxSize}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    <chakra.span paddingLeft={2}>{getSortedIcon(header.column)}</chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <Td
                    paddingX={1.5}
                    paddingY={1.5}
                    key={cell.id}
                    maxWidth={cell.column.getSize()}
                    whiteSpace="pre-wrap"
                    overflowWrap="break-word"
                    wordBreak="break-word"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
