import { difference, getCoords } from '@turf/turf';
import { getSector } from '@utils/map/sector.utils';
import { Feature } from 'geojson';

import { filterKeyToFeatureEnum, FilterTypeEnum, MapFilters } from '@/types/filters.types';
import { FeatureTypeEnum } from '@/types/map.types';
import {
  CommonConfiguration,
  CommonRadarConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
} from '@/types/sensor/configuration.types';

const toRadarCoverageFeature = (radar: CommonRadarConfiguration): Feature[] => {
  if (!radar.aperture || !radar.endDetectionRadius) {
    return [];
  }

  const orientation = radar.orientation ?? 0;
  const bearing1 = orientation - radar.aperture / 2;
  const bearing2 = orientation + radar.aperture / 2;
  const center = 'position' in radar ? radar.position : (radar as CommonConfiguration).sensorPosition;
  const code = radar.code;

  const sectorMax = getSector(center, bearing1, bearing2, radar.endDetectionRadius);
  const sectorIntermediate = getSector(center, bearing1, bearing2, radar.intermediateRadius);
  const sectorBlind = getSector(center, bearing1, bearing2, radar.blindRadius);

  if (!sectorMax || !sectorIntermediate || !sectorBlind) {
    return [];
  }
  const features: Feature[] = [];

  const firstSector = difference(sectorIntermediate, sectorBlind);
  const secondSector = difference(sectorMax, sectorIntermediate);

  if (firstSector) {
    features.push({
      type: 'Feature',
      properties: {
        featureType: FeatureTypeEnum.RADAR_COVERAGE,
        id: `${code}_first_sector`,
        value: radar,
        color: radar.sensorColor,
        opacity: 0.4,
      },
      geometry: {
        type: 'Polygon',
        coordinates: getCoords(firstSector),
      },
    });
  }

  if (secondSector) {
    features.push({
      type: 'Feature',
      properties: {
        featureType: FeatureTypeEnum.RADAR_COVERAGE,
        id: `${code}_second_sector`,
        value: radar,
        color: radar.sensorColor,
        opacity: 0.2,
      },
      geometry: {
        type: 'Polygon',
        coordinates: getCoords(secondSector),
      },
    });
  }
  return features;
};

export const toRadarCoverageFeatures = (sensors: SensorConfiguration[], mapFilters: MapFilters): Feature[] =>
  sensors
    .filter((s) =>
      Object.entries(mapFilters)
        .filter(([_, value]) => !value) // eslint-disable-line @typescript-eslint/no-unused-vars
        .map(([keyToHide]) => keyToHide)
        .every(
          (keyToHide) => filterKeyToFeatureEnum(keyToHide as keyof MapFilters, FilterTypeEnum.C_UAS_SENSOR) !== s.type,
        ),
    )
    .flatMap((sensor) =>
      sensor.type === SensorTypeEnum.SPEXER
        ? (sensor.radars as CommonRadarConfiguration[])
        : (sensor as CommonRadarConfiguration),
    )
    .filter((r) => r.active)
    .flatMap((radar) => toRadarCoverageFeature(radar));
