import { Text } from '@chakra-ui/layout';
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import PanelHeader from '@components/common/layout/PanelHeader';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasRoles } from '@redux/authent/authent.selectors';
import { RootState } from '@redux/store';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';

import Panel from '../Panel';
import ScreenConfigurationWrapper from './context/ScreenConfigurationWrapper';
import GridLayoutConfiguration from './screen/GridLayoutConfiguration';

export default function PostConfiguration() {
  const { formatMessage } = useIntl();
  const roleSet = useAppSelector(
    (state: RootState) => hasRoles(state, [RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION, RoleEnum.ACCESS_LAYOUT]),
    isEqual,
  );
  return (
    <Panel width={1440} header={<PanelHeader label={formatMessage({ id: 'components.sidebar.POST_CONFIGURATION' })} />}>
      <Tabs height="100%" width="100%" variant="styledTab">
        <TabList flexShrink={0}>
          {roleSet?.has(RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION) && (
            <Tab>
              <Text size="md">
                <FormattedMessage id="screenConfiguration.title" />
              </Text>
            </Tab>
          )}
          {roleSet?.has(RoleEnum.ACCESS_LAYOUT) && (
            <Tab>
              <Text size="md">
                <FormattedMessage id="grid.title" />
              </Text>
            </Tab>
          )}
        </TabList>

        <TabPanels height="100%">
          {roleSet?.has(RoleEnum.ACCESS_GLOBAL_SCREEN_CONFIGURATION) && <ScreenConfigurationWrapper />}
          {roleSet?.has(RoleEnum.ACCESS_LAYOUT) && <GridLayoutConfiguration />}
        </TabPanels>
      </Tabs>
    </Panel>
  );
}
