import { ReactComponent as Corner } from '@assets/components/button/login-button-corner.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/24x24/ic-arrow-24.svg';
import { Icon } from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/layout';
import { Button, Spinner, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  label: string;
  isLoading: boolean;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

function LoginButton({ isLoading, label, isSubmitting = false, isDisabled = false, onClick }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const buttonIsDisabled = isLoading || isDisabled || isSubmitting;

  return (
    <HStack role="group" gap={0} width="100%" onClick={() => !buttonIsDisabled && onClick && onClick()}>
      <Button
        height="73px"
        width="100%"
        borderRadius={0}
        backgroundColor="cyber.600"
        _groupHover={{
          backgroundColor: 'cyber.500',
          _disabled: {
            opacity: 1,
            backgroundColor: 'cyber.700',
          },
        }}
        _disabled={{
          opacity: 1,
          cursor: 'default',
          backgroundColor: 'cyber.700',
        }}
        type="submit"
        transition="none"
        isLoading={isLoading}
        spinner={<Spinner size="lg" color="neutral.white" />}
        isDisabled={buttonIsDisabled}
      >
        <HStack gap={2}>
          <Text fontSize="20px" color={isDisabled ? 'neutral.200' : 'neutral.white'} variant="space">
            {formatMessage({ id: label })}
          </Text>
          <Icon
            as={ArrowIcon}
            width="24px"
            height="24px"
            color={buttonIsDisabled ? 'neutral.200' : 'sky.500'}
            _groupHover={{
              color: buttonIsDisabled ? 'neutral.200' : 'neutral.white',
            }}
          />
        </HStack>
      </Button>
      <Icon
        as={Corner}
        height="100%"
        width="16px"
        type="submit"
        color={buttonIsDisabled ? 'cyber.700' : 'cyber.600'}
        _groupHover={{
          color: buttonIsDisabled ? 'cyber.700' : 'cyber.500',
          cursor: buttonIsDisabled ? 'default' : 'pointer',
        }}
      />
    </HStack>
  );
}

export default memo(LoginButton);
