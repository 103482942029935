import { Box } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

type Props = {
  error?: string;
  touched?: boolean;
};

export default function ErrorContainer({ error, touched = false }: Readonly<Props>) {
  if (!error || !touched) {
    return null;
  }

  return (
    <Box backgroundColor="alertHi.100" width="100%" padding={2} flexShrink={0}>
      <Text color="alertHi.500" fontSize="16px">
        {error}
      </Text>
    </Box>
  );
}
