import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  NoNeutralizationZone,
  Platform,
  ZoneOfInterest,
} from '@/types/config/config.types';
import { GridConfiguration, GridLayout } from '@/types/config/gridLayout.types';
import { Home } from '@/types/config/home.types';
import { MergingConfiguration } from '@/types/config/merging.types';
import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { RoundConfiguration } from '@/types/config/round.types';
import { GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';
import { NimbleConfiguration } from '@/types/config/videoServer.types';
import { VideoStream } from '@/types/config/videoStream.types';

import {
  globalScreenConfigurationAdapter,
  gridConfigurationsAdapter,
  imzAdapter,
  layoutsAdapter,
  nfz3dAdapter,
  nfzAdapter,
  nizAdapter,
  nnzAdapter,
  platformsAdapter,
  poiAdapter,
  roundConfigurationEntityAdapter,
  videoStreamsAdapter,
  zoiAdapter,
} from './config.adapters';

export type ConfigState = {
  home: Home | null;
  initializationMaskingZones: EntityState<InitializationMaskingZone, string>;
  noFlyZones: EntityState<NoFlyZone, string>;
  noFlyZones3d: EntityState<NoFlyZone3d, string>;
  noInitZones: EntityState<NoInitZone, string>;
  noNeutralizationZones: EntityState<NoNeutralizationZone, string>;
  pointOfInterests: EntityState<PointOfInterest, string>;
  zoneOfInterests: EntityState<ZoneOfInterest, string>;
  layouts: EntityState<GridLayout, string>;
  gridConfigurations: EntityState<GridConfiguration, string>;
  mergingConfiguration: MergingConfiguration | null;
  globalScreenConfigurations: EntityState<GlobalScreenConfiguration, string>;
  roundConfigurations: EntityState<RoundConfiguration, string>;
  videoStreams: EntityState<VideoStream, string>;
  videoConfiguration: NimbleConfiguration | null;
  platforms: EntityState<Platform, string>;
};

export const CONFIG_INITIAL_STATE: ConfigState = {
  home: null,
  initializationMaskingZones: imzAdapter.getInitialState(),
  noFlyZones: nfzAdapter.getInitialState(),
  noFlyZones3d: nfz3dAdapter.getInitialState(),
  noInitZones: nizAdapter.getInitialState(),
  noNeutralizationZones: nnzAdapter.getInitialState(),
  pointOfInterests: poiAdapter.getInitialState(),
  zoneOfInterests: zoiAdapter.getInitialState(),
  layouts: layoutsAdapter.getInitialState(),
  gridConfigurations: gridConfigurationsAdapter.getInitialState(),
  globalScreenConfigurations: globalScreenConfigurationAdapter.getInitialState(),
  roundConfigurations: roundConfigurationEntityAdapter.getInitialState(),
  mergingConfiguration: null,
  videoStreams: videoStreamsAdapter.getInitialState(),
  videoConfiguration: null,
  platforms: platformsAdapter.getInitialState(),
};

const config = createSlice({
  name: 'config',
  initialState: CONFIG_INITIAL_STATE,
  reducers: {
    updateConfiguration(
      state,
      action: PayloadAction<{
        home: Home | null;
        initializationMaskingZones: InitializationMaskingZone[];
        noFlyZones: NoFlyZone[];
        noFlyZones3d: NoFlyZone3d[];
        noInitZones: NoInitZone[];
        noNeutralizationZones: NoNeutralizationZone[];
        pointOfInterests: PointOfInterest[];
        zoneOfInterests: ZoneOfInterest[];
        layouts: GridLayout[];
        gridConfigurations: GridConfiguration[];
        globalScreenConfigurations: GlobalScreenConfiguration[];
        mergingConfiguration: MergingConfiguration | null;
        videoStreams: VideoStream[];
        videoConfiguration: NimbleConfiguration | null;
        roundConfigurations: RoundConfiguration[];
        platforms: Platform[];
      }>,
    ) {
      const {
        initializationMaskingZones,
        gridConfigurations,
        globalScreenConfigurations,
        home,
        layouts,
        mergingConfiguration,
        noFlyZones,
        noFlyZones3d,
        noInitZones,
        noNeutralizationZones,
        pointOfInterests,
        videoConfiguration,
        videoStreams,
        roundConfigurations,
        zoneOfInterests,
        platforms,
      } = action.payload;
      state.initializationMaskingZones = imzAdapter.setAll(
        state.initializationMaskingZones,
        initializationMaskingZones,
      );
      state.gridConfigurations = gridConfigurationsAdapter.setAll(state.gridConfigurations, gridConfigurations);
      state.globalScreenConfigurations = globalScreenConfigurationAdapter.setAll(
        state.globalScreenConfigurations,
        globalScreenConfigurations,
      );
      state.roundConfigurations = roundConfigurationEntityAdapter.setAll(
        state.roundConfigurations,
        roundConfigurations,
      );
      state.layouts = layoutsAdapter.setAll(state.layouts, layouts);
      state.noFlyZones = nfzAdapter.setAll(state.noFlyZones, noFlyZones);
      state.noFlyZones3d = nfz3dAdapter.setAll(state.noFlyZones3d, noFlyZones3d);
      state.noInitZones = nizAdapter.setAll(state.noInitZones, noInitZones);
      state.noNeutralizationZones = nnzAdapter.setAll(state.noNeutralizationZones, noNeutralizationZones);
      state.pointOfInterests = poiAdapter.setAll(state.pointOfInterests, pointOfInterests);
      state.zoneOfInterests = zoiAdapter.setAll(state.zoneOfInterests, zoneOfInterests);
      state.videoStreams = videoStreamsAdapter.setAll(state.videoStreams, videoStreams);
      state.platforms = platformsAdapter.setAll(state.platforms, platforms);
      state.videoConfiguration = videoConfiguration;
      state.home = home;
      state.mergingConfiguration = mergingConfiguration;
    },
    addInitializationMaskingZone: (
      { initializationMaskingZones },
      action: PayloadAction<InitializationMaskingZone>,
    ) => {
      imzAdapter.addOne(initializationMaskingZones, action.payload);
    },
    updateInitializationMaskingZone: (
      { initializationMaskingZones },
      action: PayloadAction<InitializationMaskingZone>,
    ) => {
      imzAdapter.upsertOne(initializationMaskingZones, action.payload);
    },
    deleteInitializationMaskingZone: (
      { initializationMaskingZones },
      action: PayloadAction<InitializationMaskingZone>,
    ) => {
      imzAdapter.removeOne(initializationMaskingZones, action.payload.code);
    },
    addRoundconfiguration: ({ roundConfigurations }, action: PayloadAction<RoundConfiguration>) => {
      roundConfigurationEntityAdapter.addOne(roundConfigurations, action.payload);
    },
    updateRoundconfiguration: ({ roundConfigurations }, action: PayloadAction<RoundConfiguration>) => {
      roundConfigurationEntityAdapter.upsertOne(roundConfigurations, action.payload);
    },
    deleteRoundconfiguration: ({ roundConfigurations }, action: PayloadAction<RoundConfiguration>) => {
      roundConfigurationEntityAdapter.removeOne(roundConfigurations, action.payload.code);
    },
    addNoFlyZone: ({ noFlyZones }, action: PayloadAction<NoFlyZone>) => {
      nfzAdapter.addOne(noFlyZones, action.payload);
    },
    updateNoFlyZone: ({ noFlyZones }, action: PayloadAction<NoFlyZone>) => {
      nfzAdapter.upsertOne(noFlyZones, action.payload);
    },
    deleteNoFlyZone: ({ noFlyZones }, action: PayloadAction<NoFlyZone>) => {
      nfzAdapter.removeOne(noFlyZones, action.payload.code);
    },
    addNoFlyZone3d: ({ noFlyZones3d }, action: PayloadAction<NoFlyZone3d>) => {
      nfz3dAdapter.addOne(noFlyZones3d, action.payload);
    },
    updateNoFlyZone3d: ({ noFlyZones3d }, action: PayloadAction<NoFlyZone3d>) => {
      nfz3dAdapter.upsertOne(noFlyZones3d, action.payload);
    },
    deleteNoFlyZone3d: ({ noFlyZones3d }, action: PayloadAction<NoFlyZone3d>) => {
      nfz3dAdapter.removeOne(noFlyZones3d, action.payload.code);
    },
    addNoInitZone: ({ noInitZones }, action: PayloadAction<NoInitZone>) => {
      nizAdapter.addOne(noInitZones, action.payload);
    },
    updateNoInitZone: ({ noInitZones }, action: PayloadAction<NoInitZone>) => {
      nizAdapter.upsertOne(noInitZones, action.payload);
    },
    deleteNoInitZone: ({ noInitZones }, action: PayloadAction<NoInitZone>) => {
      nizAdapter.removeOne(noInitZones, action.payload.code);
    },
    addNoNeutralizationZone: ({ noNeutralizationZones }, action: PayloadAction<NoNeutralizationZone>) => {
      nnzAdapter.addOne(noNeutralizationZones, action.payload);
    },
    updateNoNeutralizationZone: ({ noNeutralizationZones }, action: PayloadAction<NoNeutralizationZone>) => {
      nnzAdapter.upsertOne(noNeutralizationZones, action.payload);
    },
    deleteNoNeutralizationZone: ({ noNeutralizationZones }, action: PayloadAction<NoNeutralizationZone>) => {
      nnzAdapter.removeOne(noNeutralizationZones, action.payload.code);
    },
    addZoneOfInterest: ({ zoneOfInterests }, action: PayloadAction<ZoneOfInterest>) => {
      zoiAdapter.addOne(zoneOfInterests, action.payload);
    },
    updateZoneOfInterest: ({ zoneOfInterests }, action: PayloadAction<ZoneOfInterest>) => {
      zoiAdapter.upsertOne(zoneOfInterests, action.payload);
    },
    deleteZoneOfInterest: ({ zoneOfInterests }, action: PayloadAction<ZoneOfInterest>) => {
      zoiAdapter.removeOne(zoneOfInterests, action.payload.code);
    },
    addPointOfInterest: ({ pointOfInterests }, action: PayloadAction<PointOfInterest>) => {
      poiAdapter.addOne(pointOfInterests, action.payload);
    },
    updatePointOfInterest: ({ pointOfInterests }, action: PayloadAction<PointOfInterest>) => {
      poiAdapter.upsertOne(pointOfInterests, action.payload);
    },
    deletePointOfInterest: ({ pointOfInterests }, action: PayloadAction<PointOfInterest>) => {
      poiAdapter.removeOne(pointOfInterests, action.payload.code);
    },
    addGridLayout: ({ layouts }, action: PayloadAction<GridLayout>) => {
      layoutsAdapter.addOne(layouts, action.payload);
    },
    updateGridLayout: ({ layouts }, action: PayloadAction<GridLayout>) => {
      layoutsAdapter.upsertOne(layouts, action.payload);
    },
    deleteGridLayout: ({ layouts }, action: PayloadAction<GridLayout>) => {
      layoutsAdapter.removeOne(layouts, action.payload.code);
    },
    addGridConfiguration: ({ gridConfigurations }, action: PayloadAction<GridConfiguration>) => {
      gridConfigurationsAdapter.addOne(gridConfigurations, action.payload);
    },
    updateGridConfiguration: ({ gridConfigurations }, action: PayloadAction<GridConfiguration>) => {
      gridConfigurationsAdapter.upsertOne(gridConfigurations, action.payload);
    },
    deleteGridConfiguration: ({ gridConfigurations }, action: PayloadAction<GridConfiguration>) => {
      gridConfigurationsAdapter.removeOne(gridConfigurations, action.payload.code);
    },
    addVideoStream: ({ videoStreams }, action: PayloadAction<VideoStream>) => {
      videoStreamsAdapter.addOne(videoStreams, action.payload);
    },
    updateVideoStream: ({ videoStreams }, action: PayloadAction<VideoStream>) => {
      videoStreamsAdapter.upsertOne(videoStreams, action.payload);
    },
    deleteVideoStream: ({ videoStreams }, action: PayloadAction<VideoStream>) => {
      videoStreamsAdapter.removeOne(videoStreams, action.payload.code);
    },
    addGlobalScreenConfiguration: (
      { globalScreenConfigurations },
      action: PayloadAction<GlobalScreenConfiguration>,
    ) => {
      globalScreenConfigurationAdapter.addOne(globalScreenConfigurations, action.payload);
    },
    updateGlobalScreenConfiguration: (
      { globalScreenConfigurations },
      action: PayloadAction<GlobalScreenConfiguration>,
    ) => {
      globalScreenConfigurationAdapter.upsertOne(globalScreenConfigurations, action.payload);
    },
    deleteGlobalScreenConfiguration: (
      { globalScreenConfigurations },
      action: PayloadAction<GlobalScreenConfiguration>,
    ) => {
      globalScreenConfigurationAdapter.removeOne(globalScreenConfigurations, action.payload.code);
    },
    updateHome(state, action: PayloadAction<Home>) {
      state.home = action.payload;
    },
    updateMergingConfiguration(state, action: PayloadAction<MergingConfiguration>) {
      state.mergingConfiguration = action.payload;
    },
    updateVideoConfiguration(state, action: PayloadAction<NimbleConfiguration>) {
      state.videoConfiguration = action.payload;
    },
    addPlatform: ({ platforms }, action: PayloadAction<Platform>) => {
      platformsAdapter.addOne(platforms, action.payload);
    },
    updatePlatform: ({ platforms }, action: PayloadAction<Platform>) => {
      platformsAdapter.upsertOne(platforms, action.payload);
    },
    deletePlatform: ({ platforms }, action: PayloadAction<Platform>) => {
      platformsAdapter.removeOne(platforms, action.payload.code);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => CONFIG_INITIAL_STATE);
  },
});

export const {
  addGridConfiguration,
  addGridLayout,
  addInitializationMaskingZone,
  addNoFlyZone,
  addNoFlyZone3d,
  addNoInitZone,
  addNoNeutralizationZone,
  addPointOfInterest,
  addVideoStream,
  addZoneOfInterest,
  addGlobalScreenConfiguration,
  addRoundconfiguration,
  deleteGridConfiguration,
  deleteGridLayout,
  deleteInitializationMaskingZone,
  deleteNoFlyZone,
  deleteNoFlyZone3d,
  deleteNoInitZone,
  deleteNoNeutralizationZone,
  deletePointOfInterest,
  deleteVideoStream,
  deleteZoneOfInterest,
  deleteGlobalScreenConfiguration,
  deleteRoundconfiguration,
  updateConfiguration,
  updateGridConfiguration,
  updateGridLayout,
  updateHome,
  updateInitializationMaskingZone,
  updateMergingConfiguration,
  updateNoFlyZone,
  updateNoFlyZone3d,
  updateNoInitZone,
  updateNoNeutralizationZone,
  updatePointOfInterest,
  updateVideoConfiguration,
  updateVideoStream,
  updateZoneOfInterest,
  updateGlobalScreenConfiguration,
  updateRoundconfiguration,
  addPlatform,
  updatePlatform,
  deletePlatform,
} = config.actions;

export default config.reducer;
