import { Box, Text } from '@chakra-ui/react';

import { Profile as ProfileType } from '@/types/authent/groups.types';

type Props = {
  profile: ProfileType;
  isSelected: boolean;
  onClick: () => void;
};

export default function Profile({ profile, isSelected, onClick }: Readonly<Props>) {
  return (
    <Box key={profile.id} pointerEvents="all" cursor="pointer" onClick={onClick}>
      <Text
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        color="neutral.black"
        fontWeight={isSelected ? '700' : 'regular'}
        paddingLeft={2}
        paddingRight={2}
      >
        {profile.name}
      </Text>
    </Box>
  );
}
