import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = defineStyle({
  field: {
    height: 'auto',
    width: '100%',
    backgroundColor: 'neutral.900',
    rounded: 'none',
    color: 'neutral.white',
    fontFamily: 'roboto',
    fontSize: '16px',
    fontWeight: 'regular',
    boxSizing: 'border-box',
    padding: 2,
    borderRadius: 0,
    minWidth: 0,
    outline: 0,
    position: 'relative',
    _placeholder: {
      color: 'neutral.300',
    },
    border: 'none',
    _autofill: {
      transition: 'background-color 0s 5000s, color 0s 5000S',
    },
  },
  element: {
    height: '100%',
    padding: 2,
  },
});

const filled = definePartsStyle({
  field: {
    background: 'neutral.900',
    color: 'neutral.white',
    border: '2px solid',
    borderColor: 'neutral.black',
    _hover: {
      borderColor: 'sky.500',
      background: 'neutral.800',
      _disabled: {
        borderColor: 'neutral.black',
      },
    },
    _focus: {
      borderColor: 'sky.500',
      background: 'neutral.800',
    },
    _invalid: {
      borderColor: 'alertHi.500',
    },
    _readOnly: {
      cursor: 'default',
      _hover: {
        border: '2px solid',
        borderColor: 'neutral.black',
      },
    },
    _disabled: {
      cursor: 'default',
    },
  },
  element: {
    color: 'neutral.white',
  },
});

const login = defineStyle({
  field: {
    paddingLeft: 7,
    paddingRight: 7,
    background: 'neutral.white',
    color: 'neutral.black',
    border: '2px solid',
    borderColor: 'transparent',
    _hover: {
      borderColor: 'sky.500',
    },
    _focus: {
      borderColor: 'sky.500',
    },
    _invalid: {
      borderColor: 'alertHi.500',
    },
  },
});

const transparent = definePartsStyle({
  field: {
    background: 'transparent',
    color: 'neutral.white',
    border: '2px solid',
    borderColor: 'cyber.600',
    lineHeight: '40px',
    paddingY: 0,
    _placeholder: { color: 'neutral.500', fontWeight: 'medium', fontSize: '14px' },

    _disabled: {
      cursor: 'default',
    },
  },
  element: {
    color: 'neutral.white',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { login, filled, transparent },
  defaultProps: {
    variant: 'filled',
  },
});
