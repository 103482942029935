import { userLogout } from '@redux/authent/authent.reducer';
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { HoloLog } from '@/types/c2/logs.type';
import { Alert } from '@/types/data/data.types';
import { SubCameraConfiguration } from '@/types/sensor/configuration.types';
import { TrackingStatusEnum, VideoTrackerState } from '@/types/sensor/status.types';

import { alertAdapter, logsAdapter } from './global.adapters';

export type ControlledCamera = { cameraUniqueCode: string | null; subCameraId: number | undefined };

export enum AVAILABLE_LANGUAGES {
  en = 'en',
  fr = 'fr',
}

export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES.fr;

export type GlobalState = {
  selectedCameraCode: string | null;
  joystickControlId: string | null;
  joystickControlledCam: ControlledCamera;
  selectedDoubtCheckSegmentName: string | null;
  selectedDoubtCheckPerimeterCameraUniqueCode: string | null;
  alertQueue: EntityState<Alert, number>;
  videoTrackerState: VideoTrackerState[];
  selectedCameraStreams: SubCameraConfiguration[];
  newLogBookEntry: boolean;
  logs: EntityState<HoloLog, string>;
  locale: AVAILABLE_LANGUAGES;
};

export const GLOBAL_INITIAL_STATE: GlobalState = {
  locale: DEFAULT_LANGUAGE,
  selectedCameraCode: null,
  joystickControlId: null,
  joystickControlledCam: { cameraUniqueCode: null, subCameraId: undefined },
  selectedDoubtCheckSegmentName: null,
  selectedDoubtCheckPerimeterCameraUniqueCode: null,
  alertQueue: alertAdapter.getInitialState(),
  videoTrackerState: [],
  selectedCameraStreams: [],
  newLogBookEntry: false,
  logs: logsAdapter.getInitialState(),
};

const global = createSlice({
  name: 'global',
  initialState: GLOBAL_INITIAL_STATE,
  reducers: {
    updateLocale(state: GlobalState, action: PayloadAction<AVAILABLE_LANGUAGES>) {
      if (Object.keys(AVAILABLE_LANGUAGES).includes(action.payload)) {
        state.locale = action.payload;
      }
    },
    updateSelectedCameraCode: (state, action: PayloadAction<string | null>) => {
      state.selectedCameraCode = action.payload;
    },
    updateJoystickControlId: (state, action: PayloadAction<string | null>) => {
      state.joystickControlId = action.payload;
      if (action.payload === null) {
        state.joystickControlledCam = { cameraUniqueCode: null, subCameraId: undefined };
      }
    },
    updateJoystickControlledCam: (state, action: PayloadAction<ControlledCamera>) => {
      state.joystickControlledCam = action.payload;
    },
    updateSelectedDoubtCheckSegmentName: (state, action: PayloadAction<string | null>) => {
      state.selectedDoubtCheckSegmentName = action.payload;
      state.selectedDoubtCheckPerimeterCameraUniqueCode = null;
    },
    updateSelectedDoubtCheckPerimeterCameraUniqueCode: (state, action: PayloadAction<string | null>) => {
      state.selectedDoubtCheckPerimeterCameraUniqueCode = action.payload;
      state.selectedDoubtCheckSegmentName = null;
    },
    addAlert: ({ alertQueue }, action: PayloadAction<Alert>) => {
      alertAdapter.addOne(alertQueue, action.payload);
    },
    deleteAlert: ({ alertQueue }, action: PayloadAction<Alert>) => {
      alertAdapter.removeOne(alertQueue, action.payload.id);
    },
    disableAllVideoTracking: (state, action: PayloadAction<string | null>) => {
      const cameraCode = action.payload;
      const cameraState = state.videoTrackerState.find((value) => value.cameraCode === cameraCode);
      if (cameraState) {
        cameraState.fluxStates.forEach((fluxState) => (fluxState.state = TrackingStatusEnum.DISABLED));
      }
    },
    updateVideoTrackerState: (
      state,
      action: PayloadAction<{
        cameraCode: string;
        fluxId: number;
        state: TrackingStatusEnum;
      }>,
    ) => {
      const { cameraCode, fluxId, state: trackingState } = action.payload;
      const cameraState = state.videoTrackerState.find((value) => value.cameraCode === action.payload.cameraCode);

      if (cameraState) {
        // Ensure there is at most 1 pending flux and 1 active flux at the same time
        if (trackingState === TrackingStatusEnum.PENDING || trackingState === TrackingStatusEnum.ACTIVE) {
          cameraState.fluxStates = cameraState.fluxStates.map((flux) =>
            flux.state === trackingState && flux.fluxId !== fluxId
              ? {
                  ...flux,
                  state: TrackingStatusEnum.DISABLED,
                }
              : flux,
          );
        }
        const fluxState = cameraState.fluxStates.find((value) => value.fluxId === action.payload.fluxId);
        if (fluxState) {
          fluxState.state = trackingState;
        } else {
          cameraState.fluxStates.push({ fluxId, state: trackingState });
        }
      } else {
        state.videoTrackerState.push({
          cameraCode,
          fluxStates: [{ fluxId, state: trackingState }],
        });
      }
    },
    updateNewLogBookEntry(state, action: PayloadAction<boolean>) {
      state.newLogBookEntry = action.payload;
    },
    addSelectedCameraStreams(state, action: PayloadAction<SubCameraConfiguration>) {
      state.selectedCameraStreams = [...state.selectedCameraStreams, action.payload];
    },
    removeSelectedCameraStreams(state, action: PayloadAction<SubCameraConfiguration>) {
      const index = state.selectedCameraStreams.findIndex((camera) => camera.id === action.payload.id);
      if (index > -1) {
        state.selectedCameraStreams = state.selectedCameraStreams.toSpliced(index, 1);
      }
    },
    updateLogs(state, action: PayloadAction<HoloLog[]>) {
      state.logs = logsAdapter.upsertMany(state.logs, action.payload);
    },
    addLog: ({ logs }, action: PayloadAction<HoloLog>) => {
      logsAdapter.addOne(logs, action.payload);
    },
    updateLog: ({ logs }, action: PayloadAction<HoloLog>) => {
      logsAdapter.upsertOne(logs, action.payload);
    },
    deleteLog: ({ logs }, action: PayloadAction<HoloLog>) => {
      logsAdapter.removeOne(logs, action.payload.code);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => GLOBAL_INITIAL_STATE);
  },
});

export const {
  updateSelectedCameraCode,
  updateJoystickControlId,
  updateSelectedDoubtCheckSegmentName,
  updateSelectedDoubtCheckPerimeterCameraUniqueCode,
  updateJoystickControlledCam,
  addAlert,
  deleteAlert,
  disableAllVideoTracking,
  updateVideoTrackerState,
  updateNewLogBookEntry,
  addSelectedCameraStreams,
  removeSelectedCameraStreams,
  updateLogs,
  addLog,
  deleteLog,
  updateLog,
  updateLocale,
} = global.actions;
export default global.reducer;
