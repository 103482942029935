import { ReactComponent as DroneSuspectSureIcon } from '@assets/icons/54x54/target/icSymb-drone-or-M.svg';
import { ReactComponent as DroneSuspectUnsureIcon } from '@assets/icons/54x54/target/icSymb-drone-or-M-notSafe.svg';
import { ReactComponent as DroneEnemySureIcon } from '@assets/icons/54x54/target/icSymb-drone-rd-M.svg';
import { ReactComponent as DroneEnemyUnsureIcon } from '@assets/icons/54x54/target/icSymb-drone-rd-M-notSafe.svg';
import { ReactComponent as DroneWatchSureIcon } from '@assets/icons/54x54/target/icSymb-drone-yl-M.svg';
import { ReactComponent as DroneWatchUnsureIcon } from '@assets/icons/54x54/target/icSymb-drone-yl-M-notSafe.svg';
import { FunctionComponent, SVGProps } from 'react';

import {
  Alert,
  AlertLevelEnum,
  IdentificationNotification,
  Notification,
  NotificationStatus,
  NotificationType,
  PerimeterEffractionAlert,
  SegmentFailureAlert,
  SensorFailureAlert,
  SpaceViolationAlert,
  TargetAlert,
} from '@/types/data/data.types';
import { ClassificationEnum } from '@/types/sensor/classification.types';
import { ReliabilityEnum } from '@/types/sensor/identification.types';

export function isAlertType(withType: Notification): withType is Alert {
  return [
    NotificationType.SPACE_VIOLATION_ALERT,
    //For now, we don't want to display collision alert
    //NotificationType.COLLISION_ALERT,
    NotificationType.SENSOR_FAILURE_ALERT,
    NotificationType.AUTOMATON_SEGMENT_FAILURE_ALERT,
    NotificationType.PERIMETER_EFFRACTION_ALERT,
  ].includes(withType.type);
}

export function isIdentificationNotification(withType: Notification): withType is IdentificationNotification {
  return withType.type === NotificationType.DRONE_IDENTIFICATION;
}

export function isSpaceViolationAlert(withType: Notification): withType is SpaceViolationAlert {
  return withType.type === NotificationType.SPACE_VIOLATION_ALERT;
}

export function isSensorFailureAlert(withType: Notification): withType is SensorFailureAlert {
  return withType.type === NotificationType.SENSOR_FAILURE_ALERT;
}

export function isSegmentFailureAlert(withType: Notification): withType is SegmentFailureAlert {
  return withType.type === NotificationType.AUTOMATON_SEGMENT_FAILURE_ALERT;
}

export function isPerimeterEffractionAlert(withType: Notification): withType is PerimeterEffractionAlert {
  return withType.type === NotificationType.PERIMETER_EFFRACTION_ALERT;
}

export function getAlertColor(level: AlertLevelEnum, value: 500 | 800 = 500) {
  switch (level) {
    case AlertLevelEnum.INFO:
      return `var(--chakra-colors-alertLo-${value})`;
    case AlertLevelEnum.WARNING:
      return `var(--chakra-colors-alertMid-${value})`;
    case AlertLevelEnum.CRITICAL:
      return `var(--chakra-colors-alertHi-${value})`;
  }
}

export function sortAlertByLevel(level1: AlertLevelEnum, level2: AlertLevelEnum) {
  return Object.keys(AlertLevelEnum).indexOf(level2.valueOf()) - Object.keys(AlertLevelEnum).indexOf(level1.valueOf());
}

export function getTargetAlertIcon(
  alert: TargetAlert,
  reliability: ReliabilityEnum,
): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (alert.level) {
    case AlertLevelEnum.INFO:
      return reliability === ReliabilityEnum.SURE ? DroneWatchSureIcon : DroneWatchUnsureIcon;
    case AlertLevelEnum.WARNING:
      return reliability === ReliabilityEnum.SURE ? DroneSuspectSureIcon : DroneSuspectUnsureIcon;
    case AlertLevelEnum.CRITICAL:
    default:
      return reliability === ReliabilityEnum.SURE ? DroneEnemySureIcon : DroneEnemyUnsureIcon;
  }
}

export function getAlertStatusMessageId(alert: Alert): string {
  if (alert.ack) {
    if (alert.notificationStatus === NotificationStatus.ARCHIVED) {
      return 'components.alert.archived';
    } else if (alert.confirmed) {
      return 'components.alert.confirmed';
    } else {
      return 'components.alert.acknowledged';
    }
  } else {
    return 'components.alert.notAcknowledged';
  }
}

export function getIdentificationNotificationColor(classification: ClassificationEnum, dark = false) {
  switch (classification) {
    case ClassificationEnum.ENEMY:
      return `var(--chakra-colors-alertHi-${dark ? 800 : 500})`;
    case ClassificationEnum.SUSPECT:
      return `var(--chakra-colors-alertMid-${dark ? 800 : 500})`;
    case ClassificationEnum.WATCH:
      return `var(--chakra-colors-alertLo-${dark ? 800 : 500})`;
    case ClassificationEnum.FRIEND:
      return `var(--chakra-colors-alertZero-${dark ? 800 : 500})`;
    default:
      return `var(--chakra-colors-neutral-${dark ? 900 : 300})`;
  }
}
