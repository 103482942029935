import { Position } from '@turf/helpers';
import { Feature, FeatureCollection } from 'geojson';

import { FeatureTypeEnum } from '@/types/map.types';

function toMeasurePoint(position: Position, index: number): Feature {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: position,
    },
    properties: {
      featureType: FeatureTypeEnum.MEASURE,
      index: ++index,
    },
  };
}

function toMeasureLines(position: Position[]): Feature {
  return {
    type: 'Feature',
    properties: {
      featureType: FeatureTypeEnum.MEASURE,
    },
    geometry: {
      type: 'LineString',
      coordinates: position,
    },
  };
}

export const toMeasureFeatures = (measurePoints: Position[], mousePosition: Position): FeatureCollection => {
  const pointsFeatures = measurePoints.map((position, index) => toMeasurePoint(position, index));
  const lineFeatures = toMeasureLines([...measurePoints, mousePosition]);
  return {
    type: 'FeatureCollection',
    features: [...pointsFeatures, lineFeatures],
  };
};
