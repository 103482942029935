import { Button } from '@chakra-ui/react';
import { useAuth } from '@hooks/useAuth';
import { useLogoutMutation } from '@services/authent/login.api';
import { FormattedMessage } from 'react-intl';

export default function LogoutButton() {
  const [logoutMutation] = useLogoutMutation();
  const { token } = useAuth();

  return (
    <Button variant="formButtonPrimary" size="s" height="52px" onClick={() => logoutMutation({ token: token })}>
      <FormattedMessage id="authent.logout" />
    </Button>
  );
}
