import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Button, ButtonGroup, Input, TabPanel, Text, useBoolean } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';
import GPSCoordinateInput from '@components/common/inputs/coordinatesInputs/GPSCoordinatesInput';
import CustomSelect from '@components/common/inputs/CustomSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import {
  selectActiveSite,
  selectActiveUser,
  selectGlobalScreenConfigurationCode,
} from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors, LayoutSelectors } from '@redux/config/config.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import {
  useCreateGlobalScreenConfigurationMutation,
  useDeleteGlobalScreenConfigurationMutation,
  useUpdateGlobalScreenConfigurationMutation,
} from '@services/config/globalScreenConfiguration.api';
import { usePatchContextMutation } from '@services/config/setting.api';
import { compareGlobalScreenConfiguration } from '@utils/config/config.utils';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { Site } from '@/types/authent/sites.types';
import { Coordinates } from '@/types/commons/commons.types';
import { ContextEnum, GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';

import GridLayout from './GridLayout';

function generateNewConfiguration(site: Site | null, name: string, position: Coordinates): GlobalScreenConfiguration {
  return {
    id: -1,
    code: uuidv4(),
    name,
    site: site?.code ?? 'local',
    screenConfigurations: Object.values(ContextEnum).flatMap((context) => [
      {
        code: '1',
        gridLayoutCode: null,
        context,
      },
      {
        code: '2',
        gridLayoutCode: null,
        context,
      },
    ]),
    createdBy: '',
    creationTime: '',
    modifiedBy: '',
    modificationTime: '',
    position,
  } as GlobalScreenConfiguration;
}

export default function ScreenConfiguration() {
  const { colorMode } = useColorMode();
  const { formatMessage } = useIntl();

  const activeSite = useAppSelector(selectActiveSite);
  const gridLayouts = useAppSelector(LayoutSelectors.selectAllLayouts);
  const currentContext = useAppSelector(selectContext);
  const globalScreenConfigs: GlobalScreenConfiguration[] = useAppSelector(
    GlobalScreenConfigurationSelectors.selectAllGlobalScreenConfigurations,
  );
  const user = useAppSelector(selectActiveUser);
  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);
  const currentGlobalScreenConfiguration =
    useAppSelector((state) =>
      GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
        state,
        currentGlobalScreenConfigurationCode,
      ),
    ) ?? null;

  const [newConfigName, setNewConfigName] = useState<string>('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [selectedContext, setSelectedContext] = useState<string>(ContextEnum.LIVE);
  const [selectedConfig, setSelectedConfig] = useState<GlobalScreenConfiguration | null>(
    globalScreenConfigs.find((element) => element.code === currentGlobalScreenConfigurationCode) ?? null,
  );
  const [editMode, setEditMode] = useBoolean(false);

  const canDelete = selectedConfig !== null && !editMode;
  const oldConfig = globalScreenConfigs.find((config) => config.code === selectedConfig?.code) ?? null;
  const isEdited = !!oldConfig && !!selectedConfig && !compareGlobalScreenConfiguration(oldConfig, selectedConfig);

  const [createGlobalScreenConfiguration, { isLoading: isLoadingCreate }] =
    useCreateGlobalScreenConfigurationMutation();
  const [deleteGlobalScreenConfiguration, { isLoading: isLoadingDelete }] =
    useDeleteGlobalScreenConfigurationMutation();
  const [updateGlobalScreenConfiguration, { isLoading: isLoadingUpdate }] =
    useUpdateGlobalScreenConfigurationMutation();
  const [patchContext, { isLoading: isLoadingContext }] = usePatchContextMutation();

  function resetForm() {
    setNewConfigName('');
    setLongitude(0);
    setLatitude(0);
  }

  function reset(config: GlobalScreenConfiguration | null) {
    setSelectedConfig(config);
    setEditMode.off();
    resetForm();
  }

  function handleCreate(config: GlobalScreenConfiguration) {
    if (config.name) {
      createGlobalScreenConfiguration(config).then((result) => {
        if (!('error' in result)) {
          resetForm();
        }
      });
    }
  }

  function handleUpdate() {
    if (selectedConfig?.name && isEdited) {
      const updatedConfig = {
        ...selectedConfig,
        position: { latitude, longitude, type: '2D' },
        name: newConfigName,
      };
      updateGlobalScreenConfiguration({
        id: selectedConfig.id,
        globalScreenConfiguration: updatedConfig,
      }).then((result) => {
        if (!('error' in result)) {
          reset(selectedConfig);
        }
      });
    }
  }

  function handleEditClick() {
    if (editMode) {
      handleUpdate();
    } else if (selectedConfig) {
      setEditMode.on();
      setNewConfigName(selectedConfig.name);
      setLongitude(selectedConfig.position.longitude);
      setLatitude(selectedConfig.position.latitude);
    }
  }

  function handleDelete() {
    if (canDelete) {
      deleteGlobalScreenConfiguration(selectedConfig.id);
      reset(null);
    }
  }

  function onLatitudeChange(latitude: number | undefined) {
    setLatitude(latitude ?? NaN);
  }

  function onLongitudeChange(longitude: number | undefined) {
    setLongitude(longitude ?? NaN);
  }

  useEffect(() => {
    setSelectedConfig(globalScreenConfigs.find((element) => element.code === selectedConfig?.code) ?? null);
  }, [globalScreenConfigs, selectedConfig?.code]);

  useEffect(() => {
    if (!editMode) {
      setSelectedContext(currentContext);
    }
  }, [currentContext, editMode]);

  useHotkeys('enter', () => handleUpdate(), { enableOnFormTags: true });

  return (
    <TabPanel height="100%" backgroundColor="white">
      <VStack gap={1} alignItems="start" padding={3} height="100%">
        <Flex gap={1} width="100%" flexShrink={0}>
          <VStack wrap="nowrap" alignItems="flex-start" gap={1}>
            <FormControl isRequired id="name" width="100%">
              <FormLabel>
                {editMode ? (
                  <FormattedMessage id="screenConfiguration.renameConfiguration" />
                ) : (
                  <FormattedMessage id="screenConfiguration.newConfiguration" />
                )}
              </FormLabel>
              <Input
                id="name"
                name="name"
                type="text"
                borderColor="neutral.black"
                placeholder={
                  editMode
                    ? formatMessage({ id: 'screenConfiguration.newName' })
                    : formatMessage({ id: 'screenConfiguration.configurationName' })
                }
                autoComplete="off"
                value={newConfigName}
                onChange={(e) => setNewConfigName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired id="position" width="100%">
              <FormLabel>
                {editMode ? (
                  <FormattedMessage id="screenConfiguration.newPosition" />
                ) : (
                  <FormattedMessage id="screenConfiguration.configurationPosition" />
                )}
              </FormLabel>
              <GPSCoordinateInput
                coordinates={{ longitude, latitude, type: '2D' }}
                onLatitudeChange={onLatitudeChange}
                onLongitudeChange={onLongitudeChange}
              />
            </FormControl>
            {!editMode && (
              <Button
                isLoading={isLoadingCreate}
                isDisabled={isLoadingCreate || !newConfigName}
                onClick={() =>
                  handleCreate(generateNewConfiguration(activeSite, newConfigName, { longitude, latitude, type: '2D' }))
                }
                whiteSpace="wrap"
              >
                <FormattedMessage id="screenConfiguration.addConfiguration" />
              </Button>
            )}
          </VStack>
          <VStack
            width="300px"
            flexShrink={0}
            border="1px solid"
            padding={0.5}
            alignItems="start"
            gap={0.25}
            height="250px"
            overflowY="auto"
          >
            {globalScreenConfigs
              .toSorted((a, b) => a.name.localeCompare(b.name))
              .map((config) => {
                return (
                  <Box
                    key={config.code}
                    pointerEvents="all"
                    backgroundColor={selectedConfig?.code === config.code ? 'gray.300' : colorMode}
                  >
                    <Text
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      color="neutral.black"
                      paddingLeft={2}
                      paddingRight={2}
                      fontWeight={config.code === currentGlobalScreenConfigurationCode ? '700' : 'regular'}
                      onClick={() => reset(selectedConfig === config ? null : config)}
                    >
                      {config.name}
                    </Text>
                  </Box>
                );
              })}
          </VStack>
          <VStack>
            <Button
              width="100%"
              onClick={handleEditClick}
              isDisabled={selectedConfig === null || isLoadingUpdate}
              isLoading={editMode && isLoadingUpdate}
              isActive={editMode}
            >
              {editMode ? (
                <FormattedMessage id="screenConfiguration.validate" />
              ) : (
                <FormattedMessage id="screenConfiguration.editConfiguration" />
              )}
            </Button>
            <Button
              width="100%"
              onClick={() => {
                reset(selectedConfig);
              }}
              isDisabled={!editMode}
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              width="100%"
              onClick={() => {
                if (oldConfig !== null) {
                  const resetedConfig = {
                    ...oldConfig,
                    screenConfigurations: oldConfig.screenConfigurations.map((screenConfig) => ({
                      ...screenConfig,
                      gridLayoutCode: null,
                    })),
                  };
                  updateGlobalScreenConfiguration({
                    id: resetedConfig.id,
                    globalScreenConfiguration: resetedConfig,
                  }).then((result) => {
                    if (!('error' in result)) {
                      setEditMode.off();
                    }
                  });
                }
              }}
              isLoading={isLoadingUpdate}
              isDisabled={oldConfig === null || isLoadingUpdate}
            >
              <FormattedMessage id="screenConfiguration.resetConfiguration" />
            </Button>
            <Button
              width="100%"
              onClick={() => canDelete && handleDelete()}
              isLoading={isLoadingDelete}
              isDisabled={!canDelete || isLoadingDelete}
            >
              <FormattedMessage id="screenConfiguration.deleteConfiguration" />
            </Button>
          </VStack>
        </Flex>
        {currentGlobalScreenConfiguration && currentGlobalScreenConfigurationCode === selectedConfig?.code && (
          <VStack flexShrink={0} alignSelf="center">
            <FormattedMessage id="screenConfiguration.chooseContext" />
            <ButtonGroup alignItems="center" justifyContent="center">
              {Object.values(ContextEnum)
                .filter(
                  (context) =>
                    editMode ||
                    selectedConfig.screenConfigurations.some((sc) => context === sc.context && sc.gridLayoutCode),
                )
                .map((context) => (
                  <Button
                    key={context}
                    isActive={context === selectedContext}
                    isDisabled={context === selectedContext}
                    onClick={() => setSelectedContext(context)}
                  >
                    <FormattedMessage id={`screenConfiguration.context.${context}`} />
                  </Button>
                ))}
            </ButtonGroup>
          </VStack>
        )}
        {selectedConfig && (
          <HStack gap={1} width="100%" flexGrow={1} justifyContent="center" alignSelf="center" alignItems="flex-start">
            {selectedConfig.screenConfigurations
              .filter((config) => config.context === selectedContext)
              .toSorted((config1, config2) => Number(config1.code) - Number(config2.code))
              .map((screenConfig) => {
                const gridLayout = gridLayouts.find((grid) => grid.code === screenConfig.gridLayoutCode);
                return (
                  <VStack flexGrow={1} key={screenConfig.code} height="100%">
                    {editMode && (
                      <Box width="80%" marginTop={1}>
                        <CustomSelect
                          key={screenConfig.context}
                          value={gridLayout?.code}
                          options={gridLayouts.map((grid) => ({ label: grid.name, value: grid.code }))}
                          placeholder={formatMessage({ id: 'screenConfiguration.gridPlaceHolder' })}
                          onChange={(value) =>
                            setSelectedConfig({
                              ...selectedConfig,
                              screenConfigurations: selectedConfig.screenConfigurations
                                .filter(
                                  (config) => config.context !== selectedContext || config.code !== screenConfig.code,
                                )
                                .concat({
                                  ...screenConfig,
                                  gridLayoutCode: value || null,
                                }),
                            })
                          }
                        />
                      </Box>
                    )}
                    <GridLayout gridLayoutCode={screenConfig.gridLayoutCode} />
                  </VStack>
                );
              })}
          </HStack>
        )}
        {currentGlobalScreenConfiguration &&
          currentGlobalScreenConfigurationCode === selectedConfig?.code &&
          !editMode && (
            <Button
              isDisabled={
                currentContext === selectedContext ||
                !currentGlobalScreenConfiguration.screenConfigurations.some(
                  (sc) => sc.context === selectedContext && sc.gridLayoutCode,
                )
              }
              flexShrink={0}
              alignSelf="center"
              isLoading={isLoadingContext}
              onClick={() => {
                if (selectedContext !== currentContext) {
                  patchContext({ login: user.login, context: selectedContext });
                }
              }}
            >
              <FormattedMessage id="screenConfiguration.applyScreens" />
            </Button>
          )}
      </VStack>
    </TabPanel>
  );
}
