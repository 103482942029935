import { HStack, Text, VStack } from '@chakra-ui/layout';
import { Table, TableContainer, TabPanel, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveSite } from '@redux/authent/authent.selectors';
import { selectSegmentsData } from '@redux/sensors/sensors.selectors';
import { useImportAutomatonsMutation, useImportPerimetersCameraMutation } from '@services/c2/c2.api';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

export default function PerimeterSensorsTab() {
  const automatonSegmentsData = useAppSelector(selectSegmentsData);
  const activeSite = useAppSelector(selectActiveSite);
  const [importPerimeterCameras] = useImportPerimetersCameraMutation();
  const [importAutomatons] = useImportAutomatonsMutation();

  const handleImport = (type: 'automatons' | 'perimeterCamera') => (event: ChangeEvent<HTMLInputElement>) => {
    const csvFile = event.target.files?.length && event.target.files[0];
    if (activeSite?.code && csvFile) {
      csvFile.arrayBuffer().then((buffer) => {
        const bytes = new Uint8Array(buffer);
        type === 'automatons'
          ? importAutomatons({
              site: activeSite.code,
              csvFileBytes: bytes,
            })
          : importPerimeterCameras({ site: activeSite.code, csvFileBytes: bytes });
      });
    }
  };

  return (
    <TabPanel height="100%" backgroundColor="white" padding={3}>
      <VStack align="start" gap={2} height="100%">
        <Text color="neutral.black">
          <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.description" />
        </Text>
        <VStack gap={1}>
          <HStack>
            <Text width="300px" color="neutral.black">
              <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.importCameras" />
            </Text>
            <input type="file" onChange={handleImport('perimeterCamera')} />
          </HStack>
          <HStack>
            <Text width="300px" color="neutral.black">
              <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.importSegments" />
            </Text>
            <input type="file" onChange={handleImport('automatons')} />
          </HStack>
        </VStack>
        <TableContainer height="550px" marginBottom={1} overflow="auto">
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th textTransform="lowercase" isNumeric>
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.lineNumber" />
                </Th>
                <Th textTransform="lowercase">
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.automatonCode" />
                </Th>
                <Th textTransform="lowercase">
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.cabinetCode" />
                </Th>
                <Th textTransform="lowercase">
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.segmentCode" />
                </Th>
                <Th textTransform="lowercase">
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.linkedPerimeterCameras" />
                </Th>
                <Th textTransform="lowercase">
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.linkedLADCameraCode" />
                </Th>
                <Th textTransform="lowercase">
                  <FormattedMessage id="components.systemConfiguration.perimeterSensorsTab.linkedLADCameraAppCode" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {automatonSegmentsData.map((automatonSegmentData, key) => (
                <Tr key={`segment-${automatonSegmentData.segment}`}>
                  <Td isNumeric>{key + 1}</Td>
                  <Td>{automatonSegmentData.automaton}</Td>
                  <Td>{automatonSegmentData.cabinet}</Td>
                  <Td>{automatonSegmentData.segment}</Td>
                  <Td>{automatonSegmentData.perimeterCameras}</Td>
                  <Td>{automatonSegmentData.ladCameraCode}</Td>
                  <Td>{automatonSegmentData.ladCameraAppCode}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </TabPanel>
  );
}
