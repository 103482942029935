import { ReactComponent as CursorIcon } from '@assets/components/gauge/AzmElv-cursor.svg';
import { ReactComponent as ElevationIcon } from '@assets/components/gauge/elevation.svg';
import { Box, Center, Icon, Text, VStack } from '@chakra-ui/react';
import { formatDegree, getPositionOnCircle } from '@utils/sensors/camera/gauge.utils';
import { memo } from 'react';

type Props = {
  tilt: number;
  fov: number;
};

function ElevationCompassValue({ tilt, fov }: Readonly<Props>) {
  const markerPosition = getPositionOnCircle(-tilt, 90, { x: 90, y: 90 }, 0);

  const startAngle = -tilt + 90 - fov / 2;

  return (
    <Center position="absolute" width="100%" height="100%" top={0}>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        borderRadius="50%"
        background={`conic-gradient(
          from ${startAngle}deg,
          var(--chakra-colors-sky-500) ${fov}deg,
          transparent ${fov}deg ${startAngle}deg
        )`}
        pointerEvents="none"
        sx={{
          mask: `radial-gradient(circle closest-side,
            transparent 0px,
            transparent calc(100% - 20px),
            black calc(100% - 20px),
            black calc(100% - 2px),
            transparent calc(100% - 2px),
            transparent 100%)
          `,
        }}
        zIndex={2}
      />
      <Icon
        as={CursorIcon}
        position="absolute"
        width="18px"
        height="29px"
        zIndex={3}
        transform={`translate(-50%, -50%) rotate(${90 - tilt}deg)`}
        top={`${markerPosition.y}px`}
        left={`${markerPosition.x}px`}
      />
      <VStack gap={0.5} width="50%" position="absolute" left="50%" alignItems="start">
        <Icon as={ElevationIcon} width="32px" height="16px" />
        <Text backgroundColor="neutral.black" fontWeight="medium" padding={0.25}>
          {formatDegree(tilt)}
        </Text>
      </VStack>
    </Center>
  );
}

export default memo(ElevationCompassValue);
