import { HStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomTitle from '@components/common/CustomTitle';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

type Props = {
  zone: string;
};

export default function PerimeterGridTitle({ zone }: Readonly<Props>) {
  const currentSituationTime = useAppSelector((state) => selectSituationTime(state, false));
  const currentSituationDate = currentSituationTime ? new Date(currentSituationTime) : null;
  return (
    <CustomTitle>
      <Text fontWeight="medium" fontSize="16px" noOfLines={1} userSelect="none">
        {zone === 'default' ? <FormattedMessage id="automatonSegments.zone.default" /> : zone}
      </Text>
      {currentSituationDate && (
        <>
          <HStack gap={0}>
            <Text size="md" color="neutral.200">
              {format(currentSituationDate, 'HH:mm')}
            </Text>
            <Text fontSize="16px" color="neutral.300">
              {format(currentSituationDate, ':ss')}
            </Text>
          </HStack>
          <Text fontSize="16px" color="neutral.200">
            {format(currentSituationDate, 'dd/MM/yyyy')}
          </Text>
        </>
      )}
    </CustomTitle>
  );
}
