import {
  perimeterLinkLayer,
  perimeterLinkOutlineLayer,
  perimeterSensorIconLabelLayer,
  perimeterSensorIconLayer,
} from '@components/map/layers/perimeterSensors/perimeterSensor.layer';
import { useDisplayPerimeterLinksEvent } from '@hooks/useDisplayPerimeterLinksEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectPerimeterLinkFeatures, selectPerimeterSensorFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

function PerimeterSensorLayer() {
  useDisplayPerimeterLinksEvent();

  const perimeterSensorFeatures = useSelectorWithReplayMode(selectPerimeterSensorFeatures);
  const perimeterLinkFeatures = useSelectorWithMapId(selectPerimeterLinkFeatures);

  const mergedFeatures: FeatureCollection<Geometry, GeoJsonProperties> = {
    ...perimeterSensorFeatures,
    features: [...perimeterSensorFeatures.features, ...perimeterLinkFeatures],
  };

  if (!isFeatureCollection(mergedFeatures)) return null;

  return (
    <Source id={SourceNameEnum.PERIMETER_SENSORS} type="geojson" data={mergedFeatures} generateId>
      <Layer key={LayerNameEnum.PERIMETER_LINK_OUTLINES} {...perimeterLinkOutlineLayer} />
      <Layer key={LayerNameEnum.PERIMETER_LINKS} {...perimeterLinkLayer} />
      <Layer key={LayerNameEnum.PERIMETER_SENSOR_LABELS} {...perimeterSensorIconLabelLayer} />
      <Layer key={LayerNameEnum.PERIMETER_SENSOR_ICONS} {...perimeterSensorIconLayer} />
    </Source>
  );
}

export default memo(PerimeterSensorLayer);
