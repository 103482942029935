import { useEffect, useState } from 'react';

export const useDimensions = () => {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const [node, setNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (node) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setRect(entry.contentRect);
        }
      });
      observer.observe(node);
      return () => {
        observer.unobserve(node);
      };
    }
  }, [node]);

  return [rect, setNode] as const;
};
