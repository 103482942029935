import { Icon } from '@chakra-ui/icon';
import { HStack, VStack } from '@chakra-ui/layout';
import { Box, TabPanel, Text, Tooltip } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { selectTargetSensorsByTargetId } from '@redux/situation/monitoring.selectors';
import { selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { DroneSelectors } from '@redux/utm/utm.selectors';
import { getRoundedValue } from '@utils/math.utils';
import {
  getCollaborativeData,
  getTargetIcon,
  getTargetIdentification,
  getTargetReliability,
  getTrackUniqueId,
  isDrone,
  isSpatial,
} from '@utils/target.utils';
import {
  AngleUnits,
  convertDistanceUnitFromMeter,
  convertSpeedUnitFromMPerS,
  formatUnit,
  HeightUnits,
  SpeedUnits,
} from '@utils/unit.utils';
import { getDroneClearanceLabel } from '@utils/utm/drone.utils';
import { formatCoordinates } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage, useIntl } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { TargetLocation } from '@/types/sensor/sensor.types';

import InfoValue from '../InfoValue';
import SensorIcon from '../sensors/SensorIcon';
import ClassificationSelect from './ClassificationSelect';
import IdentificationSelect from './IdentificationSelect';

type Props = {
  target: Target<TargetLocation>;
};
export default function TargetDescription({ target }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const sensorsInfos = useSelectorWithReplayMode(selectTargetSensorsByTargetId, target.id);
  const drone = useAppSelector((state) => DroneSelectors.selectDroneByCode(state, target.droneCode ?? ''));
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const tracks = useSelectorWithReplayMode(
    selectTrackFromRawSituationByTrackLights,
    target.trackIds.map(getTrackUniqueId),
  );
  const collaborativeData = getCollaborativeData(tracks);

  const coordinatesDMS = new DmsCoordinates(
    target.lastPosition.location?.position?.latitude ?? 0,
    target.lastPosition.location?.position?.longitude ?? 0,
  );

  function getTargetId() {
    if (isDrone(target)) {
      return collaborativeData.infodroneId ?? collaborativeData.aeroscopeId ?? target.id;
    }
    if (target.identification.mainIdentification === IdentificationEnum.AIRCRAFT) {
      return collaborativeData.aircraftId ?? target.id;
    }
    if (target.identification.mainIdentification === IdentificationEnum.GROUND_VEHICLE) {
      return collaborativeData.vehicleId ?? target.id;
    }
    return target.id;
  }

  return (
    <TabPanel>
      <VStack gap={0.25} backgroundColor="neutral.900">
        <HStack paddingY={3} paddingX={4} gap={3} backgroundColor="neutral.700" width="100%">
          <Icon alignSelf="start" as={getTargetIcon(target)} width="70px" height="70px" />
          <VStack gap={1}>
            <Text
              backgroundColor="neutral.black"
              size="lg"
              noOfLines={3}
              wordBreak="break-all"
              paddingX={0.5}
              paddingY={0.25}
            >
              {getTargetId()}
            </Text>
          </VStack>
        </HStack>
        <VStack padding={4} gap={3} backgroundColor="neutral.700" width="100%" alignItems="start">
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="targetInfos.identification" />
            </Text>
            <IdentificationSelect
              identification={target.identification}
              site={target.site}
              trackIds={target.trackIds}
              status={target.status}
            />
          </VStack>
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="targetInfos.classification" />
            </Text>
            <ClassificationSelect
              classification={target.classification}
              site={target.site}
              trackIds={target.trackIds}
              identification={getTargetIdentification(target)}
              status={target.status}
            />
          </VStack>
          <VStack gap={1} alignItems="start">
            <Text size="md" color="neutral.300">
              <FormattedMessage id="targetInfos.sensorsList" />
            </Text>
            <HStack flexWrap="wrap" gap={1.5}>
              {sensorsInfos.map((sensorInfos) => (
                <Tooltip key={sensorInfos.code} label={sensorInfos.name} placement="bottom">
                  <Box>
                    <SensorIcon sensorIconName={sensorInfos.iconKey} size="md" />
                  </Box>
                </Tooltip>
              ))}
            </HStack>
          </VStack>
        </VStack>
        <HStack padding={4} gap={4} backgroundColor="neutral.800" width="100%">
          <InfoValue
            label={formatMessage({ id: 'targetInfos.reliability' })}
            value={formatMessage({ id: `target.reliability.${getTargetReliability(target)}` })}
          />
          <InfoValue
            label={formatMessage({ id: 'targetInfos.clearance' })}
            value={formatMessage({ id: `target.clearance.${getDroneClearanceLabel(drone)}` })}
          />
        </HStack>
        <VStack padding={4} gap={3} backgroundColor="neutral.800" width="100%">
          <InfoValue
            label={formatMessage({ id: 'targetInfos.grids' })}
            value={formatMessage({ id: 'global.noInformation' })}
          />
          <InfoValue
            label={formatMessage({ id: 'targetInfos.latLong' })}
            value={formatCoordinates(coordinatesDMS, userSettingsUnit)}
          />
          {isSpatial(target) && target.lastPosition.location && (
            <HStack gap={0} width="100%">
              <InfoValue
                label={formatMessage({ id: 'targetInfos.altitude' })}
                infoLabel={`${formatUnit(HeightUnits.METERS, convertDistanceUnitFromMeter(HeightUnits.METERS, target.lastPosition.location.altitude))}
                (${formatMessage({
                  id: target.lastPosition.location.computedAltitude
                    ? 'targetInfos.computed'
                    : 'targetInfos.transmitted',
                })})`}
                value={formatUnit(
                  HeightUnits.FEET,
                  convertDistanceUnitFromMeter(HeightUnits.FEET, target.lastPosition.location.altitude),
                )}
              />
              <InfoValue
                label={formatMessage({ id: 'targetInfos.height' })}
                infoLabel={`${formatUnit(
                  HeightUnits.METERS,
                  convertDistanceUnitFromMeter(HeightUnits.METERS, target.lastPosition.location.height),
                )} (${formatMessage({
                  id: target.lastPosition.location.computedAltitude
                    ? 'targetInfos.computed'
                    : 'targetInfos.transmitted',
                })})`}
                value={formatUnit(
                  HeightUnits.FEET,
                  convertDistanceUnitFromMeter(HeightUnits.FEET, target.lastPosition.location.height),
                )}
              />
              <InfoValue
                label={formatMessage({ id: 'targetInfos.heading' })}
                value={formatUnit(AngleUnits.TURN, getRoundedValue(target.lastPosition.location.heading))}
              />
              <InfoValue
                label={formatMessage({ id: 'targetInfos.speed' })}
                infoLabel={formatUnit(
                  SpeedUnits.KILOMETERS_PER_HOUR,
                  getRoundedValue(
                    convertSpeedUnitFromMPerS(SpeedUnits.KILOMETERS_PER_HOUR, target.lastPosition.location.speed, 0),
                  ),
                )}
                value={formatUnit(
                  SpeedUnits.KNOTS,
                  getRoundedValue(convertSpeedUnitFromMPerS(SpeedUnits.KNOTS, target.lastPosition.location.speed, 0)),
                )}
              />
            </HStack>
          )}
        </VStack>
      </VStack>
    </TabPanel>
  );
}
