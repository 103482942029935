import { useAppSelector } from '@hooks/redux.hooks';
import { AVAILABLE_LANGUAGES } from '@redux/global/global.reducer';
import { selectLocaleValue } from '@redux/global/global.selector';
import { flattenMessages } from '@utils/locale.utils';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import messages_en from './en.json';
import messages_fr from './fr.json';
import { IntlGlobalProvider } from './IntlGlobalProvider';

type Props = {
  children?: ReactNode;
};

const messages: { [key in AVAILABLE_LANGUAGES]: Record<string, string> } = {
  [AVAILABLE_LANGUAGES.fr]: flattenMessages(messages_fr),
  [AVAILABLE_LANGUAGES.en]: flattenMessages(messages_en),
};

export default function MessagesProvider({ children }: Readonly<Props>) {
  const locale = useAppSelector(selectLocaleValue);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <IntlGlobalProvider>{children}</IntlGlobalProvider>
    </IntlProvider>
  );
}
