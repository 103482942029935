import { isValidCoordinates } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { PointOfInterest } from '@/types/config/pointOfInterest.types';
import { FeatureTypeEnum } from '@/types/map.types';

export function toPoiFeature(poi: PointOfInterest) {
  return {
    type: 'Feature',
    properties: {
      id: poi.id,
      icon: poi.active ? 'icCarto-POI-activated-32' : 'icCarto-POI-deactivated-32',
      featureType: FeatureTypeEnum.POI_ICON,
      value: poi,
    },
    geometry: {
      type: 'Point',
      coordinates: [poi.position!.longitude, poi.position!.latitude],
    },
  } as Feature;
}

export function toActivePoiFeatures(pois: PointOfInterest[]): Feature[] {
  return pois.filter((poi) => isValidCoordinates(poi.position) && poi.active).map(toPoiFeature);
}

export function toEditablePoiFeatures(pois: PointOfInterest[]): Feature[] {
  return pois.filter((poi) => isValidCoordinates(poi.position)).map(toPoiFeature);
}
