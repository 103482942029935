/**
 * Transpose value from [a, b] to value' into [0, upperBound]
 */
export function getScaledValue(min: number, max: number, value: number, upperBound: number) {
  return ((value - min) / (max - min)) * upperBound;
}

/**
 * Transpose value from [0, upperBound] to value' into [a, b]
 */
export function getUnscaledValue(min: number, max: number, scaledValue: number, upperBound: number) {
  return (scaledValue / upperBound) * (max - min) + min;
}

export function getRoundedValue(value: number | null | undefined) {
  return value && Math.round(value);
}

export function modulo(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function toPrecision(value: number, precision: number) {
  const factor = 10 ** precision;
  return Math.round((value + Number.EPSILON) * factor) / factor;
}

export function addLeadingZero(num: number, precision?: number): string {
  if (num >= 0 && num <= 9) {
    return `0${num.toFixed(precision)}`;
  } else {
    return num.toFixed(precision);
  }
}
