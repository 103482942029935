import { toEditablePoiFeatures } from '@components/map/layers/poi/poi.mapper';
import { toRoundSectionLineFeatures } from '@components/map/layers/round/roundSection.mapper';
import {
  toIMZFeatures,
  toIMZMarginFeatures,
  toNFZ3dFeatures,
  toNFZFeatures,
  toNIZFeatures,
} from '@components/map/layers/zones/zone.mapper';
import { selectActiveGroup, selectGlobalScreenConfigurationCode } from '@redux/authent/authent.selectors';
import { selectMapNavigationOptions } from '@redux/maps/maps.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectPointOfInterestFeatures, selectZonesFilteredFeatures } from '@redux/situation/situation.selectors';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectParameter, selectParameterWithReplayMode } from '@utils/common.utils';
import { filterFeaturesByZoneType, isSolid3dPolygonValid } from '@utils/map/zone.utils';
import { isValidPolygon } from '@utils/validation/coordinates.utils';
import { Feature, FeatureCollection } from 'geojson';

import { Group } from '@/types/authent/groups.types';
import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  Platform,
  SEE_POI,
  Zone2DTypeEnum,
  Zone3DTypeEnum,
  ZoneFormTypeEnum,
} from '@/types/config/config.types';
import { GridItemComponentEnum, GridLayout } from '@/types/config/gridLayout.types';
import { Home } from '@/types/config/home.types';
import { MergingConfiguration } from '@/types/config/merging.types';
import { RoundConfiguration, RoundSection } from '@/types/config/round.types';
import { ContextEnum, GlobalScreenConfiguration } from '@/types/config/screenConfiguration.types';
import { NimbleConfiguration } from '@/types/config/videoServer.types';
import { MapIdEnum } from '@/types/map.types';

import {
  globalScreenConfigurationAdapter,
  gridConfigurationsAdapter,
  imzAdapter,
  layoutsAdapter,
  nfz3dAdapter,
  nfzAdapter,
  nizAdapter,
  nnzAdapter,
  platformsAdapter,
  poiAdapter,
  roundConfigurationEntityAdapter,
  videoStreamsAdapter,
  zoiAdapter,
} from './config.adapters';
import { ConfigState } from './config.reducer';

function selectConfiguration(state: RootState): ConfigState {
  return state.config;
}

const { selectAll: selectAllInitializationMaskingZones } = imzAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).initializationMaskingZones,
);

export const InitializationMaskingZoneSelectors = {
  selectAllInitializationMaskingZones,
};

const { selectAll: selectAllNoFlyZones } = nfzAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).noFlyZones,
);

export const NoFlyZoneSelectors = {
  selectAllNoFlyZones,
};

const { selectAll: selectAllNoFlyZones3d } = nfz3dAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).noFlyZones3d,
);

export const NoFlyZone3dSelectors = {
  selectAllNoFlyZones3d,
};

const { selectAll: selectAllNoInitZones } = nizAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).noInitZones,
);

export const NoInitZoneSelectors = {
  selectAllNoInitZones,
};

export const selectNoInitZones = createSelector(selectAllNoInitZones, (nizs): NoInitZone[] =>
  nizs.filter((niz: NoInitZone) => isValidPolygon(niz.polygon)),
);

export const selectNoInitZoneFeatures = createSelector(selectNoInitZones, (nizs: NoInitZone[]): Feature[] =>
  toNIZFeatures(nizs),
);

export const selectNoFlyZones = createSelector(selectAllNoFlyZones, (nfzs): NoFlyZone[] =>
  nfzs.filter((nfz) => isValidPolygon(nfz.polygon)),
);

export const selectNoFlyZones3d = createSelector(selectAllNoFlyZones3d, (nfzs): NoFlyZone3d[] =>
  nfzs.filter((nfz: NoFlyZone3d) => isSolid3dPolygonValid(nfz.solidPolygon)),
);

export const selectNoFlyZoneFeatures = createSelector(selectNoFlyZones, (nfzs): Feature[] => toNFZFeatures(nfzs));

export const selectNoFlyZone3dFeatures = createSelector(selectNoFlyZones3d, (nfzs): Feature[] => toNFZ3dFeatures(nfzs));

export const selectInitMaskingZones = createSelector(
  selectAllInitializationMaskingZones,
  (imzs): InitializationMaskingZone[] => imzs.filter((imz) => isValidPolygon(imz.polygon)),
);

export const selectInitMaskingZoneFeatures = createSelector(selectInitMaskingZones, (imzs): Feature[] => [
  ...toIMZFeatures(imzs),
  ...toIMZMarginFeatures(imzs),
]);

export const selectZonesFeatures = createSelector(
  [selectNoInitZoneFeatures, selectNoFlyZoneFeatures, selectInitMaskingZoneFeatures, selectNoFlyZone3dFeatures],
  (nizFeatures: Feature[], nfzFeatures: Feature[], imzFeatures: Feature[], nfz3dFeatures: Feature[]): Feature[] => [
    ...nizFeatures,
    ...nfzFeatures,
    ...imzFeatures,
    ...nfz3dFeatures,
  ],
);

export const selectFeaturesBySelectedOptions = createSelector(
  [
    (state) => selectZonesFilteredFeatures(state),
    (state, mapId) => selectMapNavigationOptions(state, mapId),
    (state) => selectZonesFeatures(state),
  ],
  (featuresFromSituation, selectedOptions, featuresFromConfig) => {
    let features: Feature[] = [];

    if (selectedOptions?.at(-1)?.includes('see')) {
      const zoneType: ZoneFormTypeEnum | undefined = [
        ...Object.values(Zone2DTypeEnum),
        ...Object.values(Zone3DTypeEnum),
      ].find((value) => value === selectedOptions.at(-1)?.split('_')[1]);

      if (zoneType) {
        features = filterFeaturesByZoneType(featuresFromConfig, zoneType);
      }
    }

    return selectedOptions?.at(-1)?.includes('see')
      ? ({ type: 'FeatureCollection', features } as FeatureCollection)
      : featuresFromSituation;
  },
);

const { selectAll: selectAllNoNeutralizationZones } = nnzAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).noNeutralizationZones,
);

export const NoNeutralizationZoneSelectors = {
  selectAllNoNeutralizationZones,
};

const { selectAll: selectAllZoneOfInterests } = zoiAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).zoneOfInterests,
);

export const ZoneOfInterestSelectors = {
  selectAllZoneOfInterests,
};

const { selectAll: selectAllPointOfInterests } = poiAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).pointOfInterests,
);

export const PointOfInterestSelectors = {
  selectAllPointOfInterests,
};

const { selectAll: selectAllRoundConfigurations } = roundConfigurationEntityAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).roundConfigurations,
);

export const RoundConfigurationSelector = {
  selectAllRoundConfigurations,
};

const { selectAll: selectAllGlobalScreenConfigurations } = globalScreenConfigurationAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).globalScreenConfigurations,
);

const selectGlobalScreenConfigurationByCode = globalScreenConfigurationAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).globalScreenConfigurations,
).selectById as (state: RootState, id: string) => GlobalScreenConfiguration | undefined;

export const GlobalScreenConfigurationSelectors = {
  selectAllGlobalScreenConfigurations,
  selectGlobalScreenConfigurationByCode,
};

const { selectAll: selectAllLayouts } = layoutsAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).layouts,
);

const selectLayoutByCode = layoutsAdapter.getSelectors((state: RootState) => selectConfiguration(state).layouts)
  .selectById as (state: RootState, id: string) => GridLayout | undefined;

export const LayoutSelectors = {
  selectAllLayouts,
  selectLayoutByCode,
};

const { selectAll: selectAllGridConfiguration } = gridConfigurationsAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).gridConfigurations,
);

export const GridConfigurationSelectors = {
  selectAllGridConfiguration,
};

const { selectAll: selectAllVideoStreams } = videoStreamsAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).videoStreams,
);

export const VideoStreamSelectors = {
  selectAllVideoStreams,
};

export const selectCurrentLayout = createSelector(
  [selectAllLayouts, selectGlobalScreenConfigurationCode],
  (layouts: GridLayout[], layout: string): GridLayout | null => layouts.find((item) => item.code === layout) ?? null,
);

export const selectDoesScreenconfigurationContainPerimeterDoubtCheck = createSelector(
  [selectGlobalScreenConfigurationCode, selectContext, selectAllGlobalScreenConfigurations, selectAllLayouts],
  (
    globalScreenConfigurationCode: string,
    context: ContextEnum,
    globalScreenConfigurations: GlobalScreenConfiguration[],
    layouts: GridLayout[],
  ): boolean => {
    const globalConfig = globalScreenConfigurations.find((config) => config.code === globalScreenConfigurationCode);
    const gridLayoutCodes = globalConfig?.screenConfigurations
      .filter((sc) => sc.context === context)
      .map((sc) => sc.gridLayoutCode);
    return layouts
      .filter((layout) => gridLayoutCodes?.some((gl) => gl === layout.code))
      .some((layout) => layout.items.some((item) => item.component === GridItemComponentEnum.PERIMETER_DOUBT_CHECK));
  },
);

export const selectHome = createSelector(selectConfiguration, (state: ConfigState): Home | null => state.home);

export const selectMergingConfiguration = createSelector(
  selectConfiguration,
  (state: ConfigState): MergingConfiguration | null => state.mergingConfiguration,
);

export const selectVideoConfiguration = createSelector(
  selectConfiguration,
  (state: ConfigState): NimbleConfiguration | null => state.videoConfiguration,
);

export const selectActiveRoundConfigurations = createSelector(
  [selectAllRoundConfigurations],
  (roundConfigurations: RoundConfiguration[]): RoundConfiguration[] =>
    roundConfigurations.filter((roundConfig) => roundConfig.active),
);

export const selectRoundSections = createSelector(
  [selectActiveRoundConfigurations],
  (roundConfigurations: RoundConfiguration[]): RoundSection[] =>
    roundConfigurations.flatMap((roundConfig) => roundConfig.sections),
);

export const selectRoundSectionsFeatures = createSelector(
  [selectRoundSections, selectParameterWithReplayMode<number | undefined>],
  (sections, zoom) => {
    const roundSectionsFeatures = toRoundSectionLineFeatures(sections, zoom);

    return {
      type: 'FeatureCollection',
      features: [...roundSectionsFeatures],
    } as FeatureCollection;
  },
);

export const selectPoiBySelectedOptions = createSelector(
  [
    (state, mapId) => selectPointOfInterestFeatures(state, mapId === MapIdEnum.REPLAY),
    selectMapNavigationOptions,
    selectAllPointOfInterests,
  ],
  (mapPoiFeatures, selectedOptions, configPois) => {
    if (selectedOptions?.at(-1) === SEE_POI) {
      return { type: 'FeatureCollection', features: toEditablePoiFeatures(configPois) } as FeatureCollection;
    }
    return mapPoiFeatures;
  },
);

const { selectAll: selectAllPlatforms } = platformsAdapter.getSelectors(
  (state: RootState) => selectConfiguration(state).platforms,
);

export const PlatformSelectors = {
  selectAllPlatforms,
};

export const selectActiveGroupsPlatforms = createSelector(
  [selectAllPlatforms, selectActiveGroup],
  (platforms: Platform[], activeGroup: Group | null): Platform[] => {
    if (!activeGroup) {
      return [];
    }
    return platforms.filter((platform) => activeGroup.platforms.includes(platform.code));
  },
);

export const selectPlatformsNamesByCode = createSelector(
  [selectAllPlatforms, selectParameter<string[]>],
  (platforms, contexts): string[] =>
    contexts.map((context) => platforms.find((platform) => platform.code === context)?.name ?? context),
);
