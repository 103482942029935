import { useEffect, useState } from 'react';

import useForceRerender from './useForceRerender';

const useIsElementVisible = (target: HTMLDivElement | null) => {
  const [isVisible, setIsVisible] = useState(false); // store visibility status

  const forceRerender = useForceRerender();

  useEffect(() => {
    forceRerender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onVisibilityChange: IntersectionObserverCallback = (entries) => setIsVisible(entries[0].isIntersecting);

  useEffect(() => {
    if (target) {
      const observer = new IntersectionObserver(onVisibilityChange);
      observer.observe(target);
    }
  }, [target]);

  return isVisible;
};

export default useIsElementVisible;
