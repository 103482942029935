import { useMapContext } from '@components/map/MapContext';
import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { updateDisplayedPerimeterLinksCodesByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { AUTOMATON_SECTION_FEATURE_TYPES, AUTOMATON_SECTION_LAYER_NAMES } from '@utils/map/map.constants';
import { EventData, MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { useCallback } from 'react';
import { useMap } from 'react-map-gl';

import { FeatureTypeEnum, LayerNameEnum, PopupDataTypeEnum, SensorUniqueCodes } from '@/types/map.types';
import { AutomatonSectionNames, SensorTypeEnum, SubSensorTypeEnum } from '@/types/sensor/configuration.types';

export const useDisplayPerimeterLinksEvent = () => {
  const { current: map } = useMap();
  const { mapId } = useMapContext();

  const layers: LayerNameEnum[] = [
    LayerNameEnum.PERIMETER_SENSOR_LABELS,
    LayerNameEnum.PERIMETER_SENSOR_ICONS,
    ...AUTOMATON_SECTION_LAYER_NAMES,
  ];

  const updateDisplayedPerimeterLinksCodes = useWithDispatch(updateDisplayedPerimeterLinksCodesByMapId);
  const currentPopupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const isMeasureActive = currentPopupControl.type === PopupDataTypeEnum.MEASURE_INFO;

  const handleClickAndContextMenu = useCallback(
    (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
      if (!map || event.features === undefined || isMeasureActive) {
        return;
      }

      const feature = event.features[0];
      const featureType = feature.properties?.featureType;

      if ([FeatureTypeEnum.SENSOR_ICON, FeatureTypeEnum.SENSOR_LABEL].includes(featureType)) {
        if (feature.properties?.type === SensorTypeEnum.AUTOMATON) {
          const automatonUniqueCode = (JSON.parse(feature.properties.value) as SensorUniqueCodes).sensorUniqueCode;
          updateDisplayedPerimeterLinksCodes({
            mapId,
            displayedPerimeterLinksCodes: {
              automatonUniqueCode: automatonUniqueCode,
            },
          });
        }
        if (feature.properties?.type === SubSensorTypeEnum.AUTOMATON_CABINET) {
          const cabinetUniqueCodes = JSON.parse(feature.properties.value) as SensorUniqueCodes;
          updateDisplayedPerimeterLinksCodes({
            mapId,
            displayedPerimeterLinksCodes: {
              automatonUniqueCode: cabinetUniqueCodes.parentUniqueCode!,
              cabinetUniqueCode: cabinetUniqueCodes.sensorUniqueCode,
            },
          });
        }
      } else if (AUTOMATON_SECTION_FEATURE_TYPES.includes(featureType)) {
        const sectionNames = JSON.parse(feature.properties?.value) as AutomatonSectionNames | null;
        if (sectionNames) {
          updateDisplayedPerimeterLinksCodes({
            mapId,
            displayedPerimeterLinksCodes: sectionNames,
          });
        }
      }
    },
    [map, updateDisplayedPerimeterLinksCodes, mapId, isMeasureActive],
  );
  useMapLayerMouseEvent('click', handleClickAndContextMenu, layers);
  useMapLayerMouseEvent('contextmenu', handleClickAndContextMenu, layers);
};
