import { Grid, GridItem } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { VideoStreamSelectors } from '@redux/config/config.selectors';
import { selectAllSubCameras } from '@redux/sensors/sensors.selectors';
import { getNimbleStreamUrl } from '@utils/sensors/camera/camera.utils';

import { VideoStream } from '@/types/config/videoStream.types';
import { SubCameraConfiguration } from '@/types/sensor/configuration.types';

import Player from '../common/video/Player';

interface OwnProps {
  uniqueKey: string;
  innerGridWidth: number;
  innerGridHeight: number;
  values: string[];
}

function StreamGrid({ uniqueKey, innerGridWidth, innerGridHeight, values }: Readonly<OwnProps>) {
  const videoStreams = useAppSelector(VideoStreamSelectors.selectAllVideoStreams);
  const subCameras = useAppSelector(selectAllSubCameras);
  return (
    <Grid
      templateColumns={`repeat(${innerGridWidth}, 1fr)`}
      templateRows={`repeat(${innerGridHeight}, 1fr)`}
      minHeight={0}
      minWidth={0}
      width="100%"
      height="100%"
    >
      {values.map((value) => {
        const [key, ...rest] = value.split('_');
        let flux;
        if (key === 'stream') {
          const code = rest.join('_');
          flux = videoStreams.find((stream: VideoStream) => stream.code === code);
        } else if (key === 'camera') {
          //Remove the sensorId that was added in GridLayoutConfiguration option value
          const code = rest.slice(1).join('_');
          flux = subCameras.find((sub: SubCameraConfiguration) => sub.code === code);
        }
        return (
          <GridItem key={`${uniqueKey}-${value}`} minHeight={0} minWidth={0}>
            {flux && (
              <Player
                src={getNimbleStreamUrl(flux)}
                replayMode={false}
                id={`player-${uniqueKey}-${flux.code}`}
                type={flux.protocol ?? flux.sourceProtocol}
              />
            )}
          </GridItem>
        );
      })}
    </Grid>
  );
}

export default StreamGrid;
