import { ReactComponent as AlertButtonBackgroundBig } from '@assets/components/panel/alert/alert-button-background-big.svg';
import { ReactComponent as ConfirmedIcon32 } from '@assets/icons/32x32/ic-confirmed-32.svg';
import { ReactComponent as ConfirmedDoubleIcon } from '@assets/icons/32x32/ic-confirmedDouble-32.svg';
import { ReactComponent as StopIcon32 } from '@assets/icons/32x32/ic-stop-32.svg';
import { Box, Center, VStack } from '@chakra-ui/layout';
import { Spinner, Text } from '@chakra-ui/react';
import ButtonBar from '@components/common/inputs/buttons/ButtonBar';
import {
  useAcknowledgeNotificationMutation,
  useArchiveNotificationMutation,
  useConfirmNotificationMutation,
} from '@services/data/notification.api';
import { Dispatch, memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertsState, AlertStateType } from '../AlertsPanel';

type Props = {
  id: number;
  identifier: string;
  site: string;
  isAck: boolean;
  isConfirmed: boolean;
  state: AlertsState;
  setState: Dispatch<AlertsState>;
};

function AlertButton({ id, identifier, site, isAck, isConfirmed, state, setState }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const [acknowledgeAlert] = useAcknowledgeNotificationMutation();
  const [confirmAlert] = useConfirmNotificationMutation();
  const [archiveAlert] = useArchiveNotificationMutation();

  const [isLoading, setIsLoading] = useState({ ack: false, confirm: false, archive: false });

  useEffect(() => {
    if (isLoading.ack && isAck) {
      setIsLoading({ ...isLoading, ack: false });
    }
  }, [isAck, isLoading, isLoading.ack]);

  useEffect(() => {
    if (isLoading.confirm && isConfirmed) {
      setIsLoading({ ...isLoading, confirm: false });
    }
  }, [isConfirmed, isLoading, isLoading.confirm]);

  const onClick = (targetState: 'ACK' | AlertStateType | null) => {
    if (targetState === null || isLoading.ack || isLoading.confirm || isLoading.archive) {
      return;
    }
    switch (targetState) {
      case 'ACK':
        setIsLoading({ ...isLoading, ack: true });
        acknowledgeAlert({ id, identifier, site }).catch(() => setIsLoading({ ...isLoading, ack: false }));
        break;
      case 'CONFIRM':
        setIsLoading({ ...isLoading, confirm: true });
        confirmAlert({ id, identifier, site }).catch(() => setIsLoading({ ...isLoading, confirm: false }));
        break;
      case 'ARCHIVE':
        setIsLoading({ ...isLoading, archive: true });
        archiveAlert({ id, identifier, site }).catch(() => setIsLoading({ ...isLoading, archive: false }));
        break;
    }
    setState({ id: null, type: null });
  };

  const isConfirmationOpen = state.id === id;

  if (isConfirmationOpen && state.type !== null) {
    return (
      <Center borderRadius={0} height="120px" width="225px" userSelect="none">
        <AlertButtonBackgroundBig />
        <VStack position="absolute" gap={2}>
          <Center
            width="174px"
            height="34px"
            paddingY={0.5}
            paddingX={1.5}
            border="2px solid #002A7C"
            backgroundColor="cyber.600"
            cursor="pointer"
            onClick={() => onClick(state.type)}
          >
            {isLoading.confirm || isLoading.archive ? (
              <Spinner size="sm" color="neutral.white" />
            ) : (
              <Text variant="space">
                <FormattedMessage id={`components.alert.${state.type.toLocaleLowerCase()}`} />
              </Text>
            )}
          </Center>
          <Center
            width="174px"
            paddingY="6px"
            paddingX="14px"
            border="2px solid"
            borderColor="cyber.600"
            boxSizing="border-box"
            cursor="pointer"
            onClick={() => setState({ id: null, type: null })}
          >
            <Text variant="space">
              <FormattedMessage id="global.cancel" />
            </Text>
          </Center>
        </VStack>
      </Center>
    );
  }

  if (!isAck) {
    return (
      <Box>
        <ButtonBar
          label={formatMessage({ id: 'components.alert.ack' })}
          onClick={() => onClick('ACK')}
          size="tall"
          icon={ConfirmedIcon32}
          isLoading={isLoading.ack}
        />
      </Box>
    );
  }
  if (!isConfirmed) {
    return (
      <VStack gap={0.25}>
        <ButtonBar
          icon={ConfirmedDoubleIcon}
          label={formatMessage({ id: 'components.alert.confirm' })}
          size="weird"
          onClick={() => setState({ id, type: 'CONFIRM' })}
          isLoading={isLoading.confirm}
        />
        <ButtonBar
          icon={StopIcon32}
          label={formatMessage({ id: 'components.alert.archive' })}
          size="weird"
          onClick={() => setState({ id, type: 'ARCHIVE' })}
          isLoading={isLoading.archive}
        />
      </VStack>
    );
  }
  return (
    <Box>
      <ButtonBar
        icon={StopIcon32}
        label={formatMessage({ id: 'components.alert.archive' })}
        size="tall"
        onClick={() => setState({ id, type: 'ARCHIVE' })}
        isLoading={isLoading.archive}
      />
    </Box>
  );
}

export default memo(AlertButton, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
