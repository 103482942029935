import { Properties } from '@turf/helpers';
import { strobeColor } from '@utils/map/map.constants';
import { getSector, getSectorAzimuthLine } from '@utils/map/sector.utils';
import { Feature, LineString, Polygon } from 'geojson';

import { Target } from '@/types/c2/c2.types';
import { FeatureTypeEnum } from '@/types/map.types';
import { StrobeIdentificationEnum } from '@/types/sensor/identification.types';
import { StrobeLocation } from '@/types/sensor/sensor.types';

function computeStrobeSector(location: StrobeLocation): Feature<Polygon> {
  const range = location.azimuthAccuracy === 0 ? 200 : location.range!;
  const bearing1 = location.azimuth! - location.azimuthAccuracy! / 2;
  const bearing2 = location.azimuth! + location.azimuthAccuracy! / 2;

  return getSector(location.center, bearing1, bearing2, range) as Feature<Polygon>;
}

function computeStrobeAzimuthLine(location: StrobeLocation): Feature<LineString, Properties> | null {
  if (location.azimuthAccuracy === 0) {
    return null;
  }

  return getSectorAzimuthLine(location.center, location.range!, location.azimuth!);
}

export const toStrobeSectorFeatures = (strobes: Target<StrobeLocation>[]): Feature[] =>
  strobes.map((strobe) => {
    const sector = computeStrobeSector(strobe.lastPosition!.location!);
    return {
      type: 'Feature',
      properties: {
        id: strobe.id,
        featureType: FeatureTypeEnum.STROBE_SECTOR,
        value: strobe,
        color: strobe.identification.manualIdentification
          ? strobeColor[strobe.identification.manualIdentification as unknown as StrobeIdentificationEnum]
          : strobeColor[strobe.identification.mainIdentification as unknown as StrobeIdentificationEnum],
      },
      geometry: {
        type: 'Polygon',
        coordinates: sector!.geometry.coordinates,
      },
    } as Feature;
  });

export const toStrobeAzimuthLineFeatures = (strobes: Target<StrobeLocation>[]): Feature[] =>
  strobes
    .map((strobe) => computeStrobeAzimuthLine(strobe.lastPosition!.location!))
    .filter((azimuthLine) => !!azimuthLine)
    .map(
      (strobe) =>
        ({
          type: 'Feature',
          properties: {
            featureType: FeatureTypeEnum.STROBE_AZIMUTH,
          },
          geometry: {
            type: 'LineString',
            coordinates: strobe!.geometry.coordinates,
          },
        }) as Feature,
    );
