export const REQUIRED = 'form.required';
export const REQUIRED_FIELD = 'form.requiredField';
export const LATITUDE = { id: 'form.between', values: { min: '-90°', max: '90°' } };
export const LONGITUDE = { id: 'form.between', values: { min: '-180°', max: '180°' } };
export const LIST = 'form.list';
export const LOGIN_REQUIRED = 'authent.required.login';
export const PASSWORD_REQUIRED = 'authent.required.password';
export const MIN = 'form.min';
export const MAX = 'form.max';
export const MIN_FIELD = 'form.minField';
export const MAX_FIELD = 'form.maxField';
export const END = 'form.endDate';
export const BETWEEN = 'form.between';
export const EMAIL = 'form.email';
