import { Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';

import { IdentificationNotification } from '@/types/data/data.types';

type Props = {
  notification: IdentificationNotification;
};
export default function IdentificationCard({ notification }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(notification.marks)),
    isEqual,
  ).join(', ');

  return (
    <>
      <VStack width="232px" alignItems="start" justifyContent="start" paddingY="11px" height="100%" gap={0.25} />
      <VStack alignItems="start" justifyContent="start" paddingY="11px" height="100%" gap={0.25}>
        <Text>{platformsNames || formatMessage({ id: 'components.alert.noPlatform' })}</Text>
      </VStack>
      <VStack alignItems="start" justifyContent="start" paddingY="11px" height="100%" gap={0.25}>
        <Text
          paddingX={0.5}
          paddingBottom="1px"
          backgroundColor="alertHi.900"
          fontSize="12px"
          fontWeight="medium"
          noOfLines={1}
        >
          {formatMessage({ id: 'components.alert.alertCount' }, { alertCount: notification.alertCount })}
        </Text>
      </VStack>
    </>
  );
}
