import { Divider, HStack } from '@chakra-ui/layout';
import { TabPanel } from '@chakra-ui/react';
import { memo, useState } from 'react';

import ManageProfiles from './ManageProfiles';
import ViewProfiles from './ViewProfiles';

function Profiles() {
  const [selectedProfileId, setSelectedProfileId] = useState<number | null>(null);

  return (
    <TabPanel height="100%" backgroundColor="white" padding={3}>
      <HStack alignItems="start" gap={6} style={{ containerType: 'size' }} height="100%">
        <ViewProfiles selectedProfileId={selectedProfileId} setSelectedProfileId={setSelectedProfileId} />
        <Divider orientation="vertical" borderColor="neutral.300" />
        <ManageProfiles initiallySelectedProfileId={selectedProfileId ?? null} />
      </HStack>
    </TabPanel>
  );
}

export default memo(Profiles);
