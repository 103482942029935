import { ToastAlertContainer } from '@components/cockpit/toast/alert/ToastAlertContainer';
import ContextMenu from '@components/map/contextMenu/ContextMenu';
import AutomatonSectionLayer from '@components/map/layers/automatonSections/AutomatonSectionLayer';
import EventLayer from '@components/map/layers/event/EventLayer';
import FlightPlanLayer from '@components/map/layers/flightPlans/FlightPlanLayer';
import LadCameraLayer from '@components/map/layers/ladCameras/LadCameraLayer';
import PerimeterCameraLayer from '@components/map/layers/perimeterCameras/PerimeterCameraLayer';
import PerimeterSensorLayer from '@components/map/layers/perimeterSensors/PerimeterSensorLayer';
import PoiLayer from '@components/map/layers/poi/PoiLayer';
import RadarCoverageLayer from '@components/map/layers/radarCoverage/RadarCoverageLayer';
import RoundLayer from '@components/map/layers/round/RoundLayer';
import SelectionLayer from '@components/map/layers/SelectionLayer';
import SensorLayer from '@components/map/layers/sensors/SensorLayer';
import StrobeLayer from '@components/map/layers/strobes/StrobeLayer';
import TargetLayer from '@components/map/layers/targets/TargetLayer';
import ZoneLayer from '@components/map/layers/zones/ZoneLayer';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { selectMapDrawModeByMapId } from '@redux/maps/maps.selectors';

import { DrawPolygonLayer } from './layers/draw/polygons/DrawPolygonLayer';
import MapboxMap from './MapboxMap';

export default function LiveMap() {
  const isMapDrawModeOn = useSelectorWithMapId(selectMapDrawModeByMapId);

  // Do not add controls here

  return (
    <MapboxMap contextMenu={<ContextMenu />}>
      {isMapDrawModeOn && <DrawPolygonLayer />}
      <ZoneLayer />
      <FlightPlanLayer />
      <RoundLayer />
      <RadarCoverageLayer />
      <PerimeterCameraLayer />
      <LadCameraLayer />
      <StrobeLayer />
      <PerimeterSensorLayer />
      <SensorLayer />
      <AutomatonSectionLayer />
      <PoiLayer />
      <EventLayer />
      <TargetLayer />
      <SelectionLayer />
      <ToastAlertContainer />
    </MapboxMap>
  );
}
