/* eslint-disable @typescript-eslint/no-explicit-any */
import './players.scss';

import { Box } from '@chakra-ui/layout';
import { usePlayer } from '@hooks/usePlayer';
import classNames from 'classnames';

type Props = {
  id: string;
  src?: string;
  isPaused?: boolean;
  className?: string;
};

function SLDPPlayer({ src, id, isPaused, className }: Readonly<Props>) {
  usePlayer(id, src, isPaused);
  return <Box id={id} className={classNames('sldp-container', className)} minHeight="inherit" minWidth="inherit" />;
}

export default SLDPPlayer;
