import { appIntl } from '@components/locale/IntlGlobalProvider';
import * as Yup from 'yup';

import { Error } from '@/types/validation.types';

export const validateEmail: Yup.StringSchema = Yup.string()
  .matches(/@[^.]*\./, 'form.email')
  .email('form.email');

export function formatError(error?: Error): string | null {
  if (error === undefined) {
    return null;
  }
  if (typeof error === 'string') {
    return appIntl().formatMessage({ id: error });
  }
  return appIntl().formatMessage({ id: error.id }, error.values);
}
