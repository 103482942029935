import { useToast } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { deleteAlert } from '@redux/global/global.reducer';
import { selectAlertQueue } from '@redux/global/global.selector';
import { selectAllActiveNoAckAlerts } from '@redux/situation/situation.selectors';
import { alertToastConfig } from '@utils/toast.utils';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import AlertToast from './AlertToast';

export function ToastAlertContainer() {
  const alertQueue = useAppSelector(selectAlertQueue);
  const alerts = useAppSelector(selectAllActiveNoAckAlerts);
  const removeAlertFromQueue = useWithDispatch(deleteAlert);
  const toastIds = useRef<number[]>([]);
  const toast = useToast();
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const createToast = useCallback(
    (id: string | number, element?: ReactNode) => {
      toast({
        ...alertToastConfig,
        id,
        render: () => element,
      });
    },
    [toast],
  );

  useEffect(() => {
    //If queue is not empty and max number of toast is not reached
    //create a toast with the next alert in queue and remove it from the queue
    if (alertQueue.length > 0 && toastIds.current.length < 10) {
      const alert = alertQueue[0];
      createToast(
        alert.id,
        <AlertToast
          alert={alert}
          onClose={() => {
            toast.close(alert.id);
            toastIds.current = toastIds.current.filter((id) => id !== alert.id);
          }}
        />,
      );
      toastIds.current = toastIds.current.concat(alert.id);
      removeAlertFromQueue(alert);
    }
  }, [alertQueue, createToast, removeAlertFromQueue, toast]);

  //If an alert is not found in the situation for more than 2s, close its toast
  useEffect(() => {
    const idsToRemove = toastIds.current.filter((id) => !alerts.some((alert) => alert.id === id));
    if (idsToRemove.length > 0 && timeout.current === undefined) {
      timeout.current = setTimeout(() => {
        idsToRemove.forEach((id) => toast.close(id));
        toastIds.current = toastIds.current.filter((id) => !idsToRemove.includes(id));
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }, 2000);
    } else if (idsToRemove.length === 0 && timeout.current !== undefined) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }, [alerts, toast]);

  useEffect(() => {
    return () => {
      toastIds.current.forEach((id) => toast.close(id));
    };
  }, [toast]);

  return null;
}
