import { sensorIconLabelLayer, sensorIconLayer } from '@components/map/layers/sensors/sensor.layer';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectSensorFeatures } from '@redux/situation/monitoring.selectors';
import { isFeatureCollection } from '@utils/map/map.utils';
import { memo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { LayerNameEnum, SourceNameEnum } from '@/types/map.types';

function SensorLayer() {
  const sensorFeatures = useSelectorWithReplayMode(selectSensorFeatures);

  if (!isFeatureCollection(sensorFeatures)) return null;

  return (
    <Source id={SourceNameEnum.SENSORS} type="geojson" data={sensorFeatures} generateId>
      <Layer key={LayerNameEnum.SENSOR_LABELS} {...sensorIconLabelLayer} />
      <Layer key={LayerNameEnum.SENSOR_ICONS} {...sensorIconLayer} />
    </Source>
  );
}

export default memo(SensorLayer);
