import { difference, getCoords, polygon } from '@turf/turf';
import { toCoordinates, toLonLatArray } from '@utils/validation/coordinates.utils';
import { Feature } from 'geojson';

import { Coordinates, Coordinates3D } from '@/types/commons/commons.types';
import {
  InitializationMaskingZone,
  NoFlyZone,
  NoFlyZone3d,
  NoInitZone,
  PolygonWithAltitude,
  SolidPolygon3d,
  Zone,
  Zone2DTypeEnum,
  Zone3DTypeEnum,
  ZoneCategoryEnum,
  ZoneFormTypeEnum,
  ZoneWithMargin,
} from '@/types/config/config.types';
import { AlertLevelEnum } from '@/types/data/data.types';

export const computeZoneMargin = (zwm: ZoneWithMargin): number[][][] => {
  try {
    const marginPolygon = polygon([zwm.margin.map((value) => toLonLatArray(value, false)!)]);
    const zonePolygon = polygon([zwm.polygon.map((value) => toLonLatArray(value, false)!)]);
    return getCoords(difference(marginPolygon, zonePolygon) || []);
  } catch (e) {
    //TODO https://dev.azure.com/GroupeADP/D1969-HOLOGARDE/_workitems/edit/20398
    return [];
  }
};

export const isNoFlyZone = (zone: Zone): zone is NoFlyZone => {
  return zone.type === ZoneCategoryEnum.NFZ;
};

export const isNoFlyZone3d = (zone: Zone): zone is NoFlyZone3d => {
  return zone.type === ZoneCategoryEnum.NFZ_3D;
};

export const isNoInitZone = (zone: Zone): zone is NoInitZone => {
  return zone.type === ZoneCategoryEnum.NIZ;
};

export const isInitializationMaskingZone = (zone: Zone): zone is InitializationMaskingZone => {
  return zone.type === ZoneCategoryEnum.IMZ;
};

export const filterMapping = {
  [Zone2DTypeEnum.NFZ_INFO]: (zone: Feature) =>
    zone.properties?.value.type === ZoneCategoryEnum.NFZ && zone.properties.value.level === AlertLevelEnum.INFO,
  [Zone2DTypeEnum.NFZ_WARNING]: (zone: Feature) =>
    zone.properties?.value.type === ZoneCategoryEnum.NFZ && zone.properties.value.level === AlertLevelEnum.WARNING,
  [Zone2DTypeEnum.NFZ_CRITICAL]: (zone: Feature) =>
    zone.properties?.value.type === ZoneCategoryEnum.NFZ && zone.properties.value.level === AlertLevelEnum.CRITICAL,

  [Zone3DTypeEnum.NFZ_INFO_3D]: (zone: Feature) =>
    zone.properties?.value.type === ZoneCategoryEnum.NFZ_3D && zone.properties.value.level === AlertLevelEnum.INFO,
  [Zone3DTypeEnum.NFZ_WARNING_3D]: (zone: Feature) =>
    zone.properties?.value.type === ZoneCategoryEnum.NFZ_3D && zone.properties.value.level === AlertLevelEnum.WARNING,
  [Zone3DTypeEnum.NFZ_CRITICAL_3D]: (zone: Feature) =>
    zone.properties?.value.type === ZoneCategoryEnum.NFZ_3D && zone.properties.value.level === AlertLevelEnum.CRITICAL,

  [Zone2DTypeEnum.NIZ]: (zone: Feature) => zone.properties?.value.type === ZoneCategoryEnum.NIZ,
  [Zone2DTypeEnum.IMZ]: (zone: Feature) => zone.properties?.value.type === ZoneCategoryEnum.IMZ,
};

export const filterFeaturesByZoneType = (features: Feature[], zoneType: ZoneFormTypeEnum): Feature[] => {
  const filterFunction = filterMapping[zoneType];
  return features.filter(filterFunction);
};

export const getNoFlyZoneCriticality = (nfz: NoFlyZone | NoFlyZone3d) => {
  switch (nfz.level) {
    case AlertLevelEnum.CRITICAL:
      return 3;
    case AlertLevelEnum.WARNING:
      return 2;
    case AlertLevelEnum.INFO:
      return 1;
  }
};

function pointToString(p: Coordinates3D): string {
  return `${p.longitude},${p.latitude},${p.altitude}`;
}

function createEdgeKey(p1: Coordinates3D, p2: Coordinates3D): string {
  const point1 = pointToString(p1);
  const point2 = pointToString(p2);
  // Ensure consistent ordering
  return point1 < point2 ? `${point1}|${point2}` : `${point2}|${point1}`;
}

function isSolidClosed(faces: PolygonWithAltitude[]): boolean {
  const edgeSet: Set<string> = new Set();

  for (const face of faces.map((f) => f.polygon.slice(0, f.polygon.length - 1))) {
    for (let i = 0; i < face.length; i++) {
      const p1 = face[i];
      const p2 = face[(i + 1) % face.length]; // Wrap around to form a loop

      const edge = createEdgeKey(p1, p2);

      if (edgeSet.has(edge)) {
        edgeSet.delete(edge);
      } else {
        edgeSet.add(edge);
      }
    }
  }

  // If all edges are paired, the set should be empty
  return edgeSet.size === 0;
}

export function isSolid3dPolygonValid(solidPolygon: SolidPolygon3d): boolean {
  return isSolidClosed(solidPolygon.polygonFaces);
}

export function getAllPoints2dOfSolidPolygon(solidPolygon: SolidPolygon3d): Coordinates[] {
  return solidPolygon.polygonFaces
    .map((face) => face.polygon)
    .flatMap((face) => face.map((point3d) => toCoordinates([point3d.longitude, point3d.latitude])!));
}

// Utilise l'algorithme de Chan, de complexité n*log(h) (n = nb de points initial, h = nb de points du résultat final)
// Retourne la figure la plus petite qui contient tous les points de la projection, ce qui enlève les points inutiles
// qui se retrouvent au milieu de la figure après la projection au sol pour garder que les bordures
export function computeConvexHull(points: Coordinates[]): Coordinates[] {
  if (points.length <= 1) return points;

  // Sorting points lexicographically
  points.sort((a, b) => (a.longitude === b.longitude ? a.latitude - b.latitude : a.longitude - b.longitude));

  const cross = (o: Coordinates, a: Coordinates, b: Coordinates): number => {
    return (
      (a.longitude - o.longitude) * (b.latitude - o.latitude) - (a.latitude - o.latitude) * (b.longitude - o.longitude)
    );
  };

  const hull: Coordinates[] = [];
  // Build the lower hull
  for (let i = 0; i < points.length; i++) {
    while (hull.length >= 2 && cross(hull[hull.length - 2], hull[hull.length - 1], points[i]) <= 0) {
      hull.pop();
    }
    hull.push(points[i]);
  }

  // Build the upper hull
  const t = hull.length + 1;
  for (let i = points.length - 1; i >= 0; i--) {
    while (hull.length >= t && cross(hull[hull.length - 2], hull[hull.length - 1], points[i]) <= 0) {
      hull.pop();
    }
    hull.push(points[i]);
  }

  return hull;
}

export function getProjectionOf3dSolid(solidPolygon: SolidPolygon3d): Coordinates[] {
  // Le solide est déjà passé par isSolid3dPolygonValid dans des sélecteurs précédents
  const allPoints2d = getAllPoints2dOfSolidPolygon(solidPolygon);
  return computeConvexHull(allPoints2d);
}
