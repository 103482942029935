import { useMapLayerMouseEvent } from '@hooks/useMapLayerMouseEvent';
import { getAvailableLayers } from '@utils/map/map.utils';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import mapboxgl, { EventData, MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { MapboxGeoJSONFeature, useMap } from 'react-map-gl';

import { Coordinates } from '@/types/commons/commons.types';
import { AnyTooltipData, FeatureTypeEnum, LayerNameEnum } from '@/types/map.types';

export const useMapTooltip = (
  layers: LayerNameEnum | LayerNameEnum[],
): [Coordinates | null, AnyTooltipData | undefined] => {
  const { current: mapbox } = useMap();
  const [position, setPosition] = useState<Coordinates | null>(null);
  const [featureToDisplay, setFeatureToDisplay] = useState<AnyTooltipData>();

  const displayTooltip = (
    feature: MapboxGeoJSONFeature,
    event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData,
  ) => {
    if (feature.geometry.type === 'Point' && feature.properties && feature.properties.showTooltip !== false) {
      const anchorPosition =
        feature.properties.tooltipAnchor === 'pointer' ? event.lngLat : feature.geometry.coordinates;
      setPosition(toCoordinates(anchorPosition));
      setFeatureToDisplay({
        type: feature.properties.featureType,
        data: JSON.parse(feature.properties.value),
      });
    } else if (
      feature.geometry.type === 'Polygon' &&
      (feature.properties?.featureType === FeatureTypeEnum.NO_FLY_ZONE ||
        feature.properties?.featureType === FeatureTypeEnum.NO_FLY_ZONE_3D)
    ) {
      const anchorPosition = event.lngLat;
      setPosition(toCoordinates(anchorPosition));
      setFeatureToDisplay({
        type: feature.properties.featureType,
        data: JSON.parse(feature.properties.value),
      });
    }
  };

  const handleMouseMove = useCallback(
    (event: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData) => {
      if (mapbox) {
        const availableLayers = getAvailableLayers(mapbox.getMap() as mapboxgl.Map, layers) as string[];
        if (availableLayers.length === 0) {
          return;
        }
        const features = mapbox.queryRenderedFeatures(event.point, {
          layers: availableLayers,
        });

        const tooltipFeature =
          features.find(
            (feature) =>
              feature.geometry.type === 'Point' && feature.properties && feature.properties.showTooltip !== false,
          ) ??
          features.find(
            (feature) =>
              feature.geometry.type === 'Polygon' &&
              (feature.properties?.featureType === FeatureTypeEnum.NO_FLY_ZONE ||
                feature.properties?.featureType === FeatureTypeEnum.NO_FLY_ZONE_3D),
          );
        if (tooltipFeature) {
          displayTooltip(tooltipFeature, event);
        } else {
          setPosition(null);
        }
      }
    },
    [layers, mapbox],
  );
  useMapLayerMouseEvent('mousemove', handleMouseMove);
  return [position, featureToDisplay];
};
