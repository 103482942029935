import { Profile } from '@/types/authent/groups.types';

import { AUTHENT_URL, authentApi } from './authent.api';

export const profileApi = authentApi.injectEndpoints({
  endpoints: (builder) => ({
    createProfile: builder.mutation<Profile, Partial<Profile>>({
      query: (profile) => ({
        url: `${AUTHENT_URL}/profiles`,
        method: 'POST',
        body: profile,
      }),
    }),
    updateProfile: builder.mutation<Profile, Profile & Pick<Profile, 'id'>>({
      query: ({ id, ...profile }) => ({
        url: `${AUTHENT_URL}/profiles/${id}`,
        method: 'PUT',
        body: profile,
      }),
    }),
    deleteProfile: builder.mutation<void, number>({
      query: (id) => ({
        url: `${AUTHENT_URL}/profiles/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useCreateProfileMutation, useUpdateProfileMutation, useDeleteProfileMutation } = profileApi;
