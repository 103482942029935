import { useCabinetCommand } from '@hooks/useCabinetCommand';
import { getCabinetSegmentStatuses, getDefenceState } from '@utils/sensors/sensors.utils';

import { MenuItems } from '@/types/menu.types';
import {
  AutomatonCabinet,
  AutomatonConfiguration,
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import {
  AutomatonCabinetStatus,
  SensorStatus,
  SensorStatusEnum,
  SubSensorStatus,
  SubSensorStatusTypeEnum,
} from '@/types/sensor/status.types';

export function useAutomatonCabinetSensorContextMenu(
  configuration: SensorConfiguration | SubSensorConfiguration | null,
  status: SubSensorStatus | SensorStatus | null,
  parentSensorConfiguration: SensorConfiguration | SubSensorConfiguration | null,
): MenuItems | null {
  const isCabinetConfiguration = configuration?.type === SubSensorTypeEnum.AUTOMATON_CABINET;
  const isCabinetStatus = !status || status.type === SubSensorStatusTypeEnum.AUTOMATON_CABINET;
  const hasAutomatonParent = parentSensorConfiguration?.type === SensorTypeEnum.AUTOMATON;

  const useCabinet = useCabinetCommand({
    parentSensorConfiguration: parentSensorConfiguration as AutomatonConfiguration | null,
    cabinetConfiguration: configuration as AutomatonCabinet | null,
  });

  if (!useCabinet.canCommand || !isCabinetConfiguration || !isCabinetStatus || !hasAutomatonParent) {
    return null;
  }

  const cabinetStatus = status as AutomatonCabinetStatus | null;
  const segmentStatuses = getCabinetSegmentStatuses(cabinetStatus);
  const defenceState = getDefenceState(segmentStatuses);

  const alarmCount = cabinetStatus
    ? Object.values(cabinetStatus.segmentStatuses).reduce((acc, curr) => {
        const lowerStatus = curr?.lowerFenceStatusAndState?.status === SensorStatusEnum.EFFRACTION ? 1 : 0;
        const upperStatus = curr?.upperFenceStatusAndState?.status === SensorStatusEnum.EFFRACTION ? 1 : 0;
        return acc + lowerStatus + upperStatus;
      }, 0)
    : 0;

  return {
    ackAlarms: {
      label: 'contextmenu.actions.cabinet.ackAlarms',
      isLoading: useCabinet.isReinitializationCommandLoading,
      isDisabled: alarmCount === 0,
      onAction: useCabinet.reinitialize,
    },
    powerOnCabinet: {
      label: 'contextmenu.actions.cabinet.sleep.on',
      isLoading: useCabinet.isSleepCommandLoading,
      isDisabled: !cabinetStatus || cabinetStatus.status !== SensorStatusEnum.SLEEP,
      onAction: () => useCabinet.sleep(false),
    },
    powerOffCabinet: {
      label: 'contextmenu.actions.cabinet.sleep.off',
      isLoading: useCabinet.isSleepCommandLoading,
      isDisabled: !cabinetStatus || cabinetStatus.status === SensorStatusEnum.SLEEP,
      onAction: () => useCabinet.sleep(true),
    },
    disableSegmentsRepulsion: {
      label: 'contextmenu.actions.cabinet.defence.detection',
      isLoading: useCabinet.isRepulsionCommandLoading,
      isDisabled: !cabinetStatus || defenceState === 'detection',
      isHidden: !useCabinet.canDetect,
      onAction: () => useCabinet.repulse(false),
    },
    enableSegmentsRepulsion: {
      label: 'contextmenu.actions.cabinet.defence.repulsion',
      isLoading: useCabinet.isRepulsionCommandLoading,
      isDisabled: !cabinetStatus || defenceState === 'repulsion',
      isHidden: !useCabinet.canRepulse,
      onAction: () => useCabinet.repulse(true),
    },
  };
}
